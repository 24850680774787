import { Component } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Newsidebar from "./../../Dashboard/Newsidebar.js";
import { formatCurrency } from "./utils.js";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Bar,
  XAxis,
  YAxis,
  BarChart,
} from "recharts";
import { ProgressBar } from "react-bootstrap";
import "../../../Styles/aged.css";
import { ip } from "../../Apis/Api";
import axios from "axios";
import { data } from "jquery";
// npm install --save @fortawesome/free-solid-svg-icons

const COLORS = ["rgb(255,99,132)", "rgb(75,192,192)", "rgb(255,205,86)"];
const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p >
      </div>
    );
  }
  return null;
};
const pieCOLORS = ["rgb(255,99,132)", "rgb(75,192,192)"];
const getPath = (x, y, width, height) => `M${x},${y + height}
  C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
  x + width / 2
}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
  x + width
}, ${y + height}
  Z`;

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};
class AgedAnalysis extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
    client:sessionStorage.getItem("clientid"),
    dataarray:[],
    balancedata:[],
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    console.log("mouse6",this.state);
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getData = async () => {
    try{
    const headers = {
      "Content-Type" : "application/json",
      Authorization : `Bearer ${this.state.accesstoken}`
    };
   
    let url;
    if(this.state.client){
    url = `${ip}/trends/agedpayment?clientId=${this.state.client}`
  }
  else{
    url = `${ip}/trends/agedpayment`
  }
    const res = await axios
    .get(url,{headers : headers})
    this.setState({
      dataarray:res.data.data
    })
  }
  catch(err){
    console.log("err",err)
  }
  }
  getData2 = async () => {
    try{
    const headers = {
      "Content-Type" : "application/json",
      Authorization : `Bearer ${this.state.accesstoken}`
    };
   
    let url;
    if(this.state.client){
    url = `${ip}/trends/chargesandpayment?clientId=${this.state.client}`
  }
  else{
    url = `${ip}/trends/chargesandpayment`
  }
    const res = await axios
    .get(url,{headers : headers})
    this.setState({
       balancedata:res.data.data
    })
  }
  catch(err){
    console.log("err",err)
  }
  }
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getData();
    this.getData2();
  }
  render() {
    return (
      <div>
      <Newsidebar />
      <div className="align-row">
        <div className="align-column">
          <div>
            <div
              style={{
                textAlign: "left",
                marginLeft: "3%",
                marginTop: "2%",
                fontSize: "14px",
              }}
            >
              <p>
                <Link
                  to="/management-trends-patterns"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Trends & Patterns
                </Link>
                &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Aged
                Payment Analysis
              </p>
            </div>
            <br />
            <div class="some-page-wrapper">
              <div class="row">
                <div class="column">
                  <div class="first-column">
                    <ResponsiveContainer width={"99%"} height={400}>
                      <BarChart width={500} height={400} data={this.state.dataarray}>
                        <XAxis
                          dataKey="_id"
                          tickLine={false}
                          axisLine={false}
                          tick={{
                            fontSize: "13",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                            color: "black",
                          }}
                        />
                        <Tooltip cursor={{fill:"transparent"}} content={<ClaimTooltip/>}/>
                        <Bar
                          shape={<TriangleBar />}
                          dataKey="totalbalance"
                          label={{
                            position: "top",
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            fontSize: "14",
                            formatter: formatCurrency,                          }}
                          radius={[16, 16, 16, 16]}
                          fill="#00a0fc"
                        >
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div class="column">
                  <div class="second-column">
                    <ResponsiveContainer width="95%" height="90%">
                      <PieChart width={500} height={400}>
                        <Tooltip />
                        <Pie
                          data={this.state.balancedata}
                          cx={200}
                          cy={200}
                          innerRadius={60}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={5}
                          dataKey="value"
                        >
                        </Pie>
                        <Legend
                          wrapperStyle={{
                            top: 350,
                            left: 40,
                            width: "26vw",
                            fontFamily: "Poppins",
                          }}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>

              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
export default AgedAnalysis;
