import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import { ip } from ".././Apis/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import datafile from "../../Files/DeleteClaims.xlsx";

export default class DataDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      type: "",
      upload: [],
      id: "",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
    };
    this.fileInput = React.createRef();
  }
  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  onFileUpload = async (e) => {
    if (!this.state.file) {
      // Display an error message or handle the validation as needed
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);
    value.append("clientids", this.state.client);
    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    await axios({
      method: "post",
      url: `${ip}/helper/deleteclaimsandcpts`,
      data: value,
      headers: headers,
    })
      .then((response) => {
        //handle success
        if (response.status === 200) {
          toast.success("File Deleted successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        //handle error
        console.log("Failed", err);
        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error("Please include all the fields in excel data", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
  }
  render() {
    return (
      <div>
      <Newsidebar name="Data Migration" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <ToastContainer></ToastContainer>
        <div className="align-column">
          <div className="align-row items">
            <form method="post" style={{ width: "17%" }}>
              <div class="files">
                <input
                  type="file"
                  id="file"
                  required
                  ref={this.fileInput}
                  onChange={this.onFileChange}
                  style={{ display: "none" }}
                  accept=".xlsx"
                />
                <label for="file" className="filelabel">
                  {this.state.file ? (
                    <p align="center" style={{ color: "green" }}>
                      {this.state.file.name}
                    </p>
                  ) : (
                    <a className="dropfile">Drop files here to delete</a>
                  )}
                </label>
              </div>
            </form>
          </div>
          <div className="boxcss">
            <div>
              <Row>
                <Col className="col-4"></Col>
                <Col className="col-4"></Col>
                <Col className="col-1"></Col>
                <Col className="col-1">
                  <div
                    class="btn-group"
                    role="group"
                    style={{ marginLeft: "65%" }}
                  >
                    <button
                      type="button"
                      class="btn"
                      style={{ backgroundColor: "#041c3c", color: "#fff" }}
                    >
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#a8adad",
                        }}
                        to={datafile}
                        download="DataDeletion.xlsx"
                        target="_blank"
                      >
                        {" "}
                        Sample
                      </Link>
                    </button>
                    <button
                      type="button"
                      class="btn"
                      style={{ backgroundColor: "#7DD657", color: "#fff" }}
                      onClick={this.onFileUpload}
                    >
                      Upload
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
