import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import "../../Styles/AuthForm.css";
import { Row, Col, Card, Table } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { Button, Input } from "reactstrap";
import { ip } from "../Apis/Api";
import axios from "axios";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
import moment from "moment-timezone";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { BsArrowsMove, BsArrowsFullscreen } from "react-icons/bs";

export default class AuthForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      payername: sessionStorage.getItem("payername"),
      typeofreq: sessionStorage.getItem("typeofreq"),
      clientid: sessionStorage.getItem("clientid"),
      subscriberopen: false,
      reqorgopen: false,
      reqdetailsopen: false,
      procedurecodeopen: false,
      addrefopen: false,
      billingarray: [],
      facilityarray: [],
      statearray:[],
      cityarray:[],
      payernamearray: [],
      rows: [{}],
      codeinfo: [],
      status: "",
      dataquery1: "",
      dataquery2: "",
      payernamevalue: "",
      providerDetails: [
        {
          refprovider: "",
          firstnameaddprovider: "",
          lastnameaddprovider: "",
          prefixaddprovider: "",
          npiprovider: "",
          taxcode: "",
          addressprovider: "",
          nameprovider: "",
          phoneprovider: "",
          faxprovider: "",
          emailprovider: "",
        },
      ],
      formValues: [
        {
          icd: "",
          icddate: "",
        },
        // {
        //   icd: "",
        //   icddate: "",
        // },
        // {
        //   icd: "",
        //   icddate: "",
        // },
        // {
        //   icd: "",
        //   icddate: "",
        // },
      ],
      redirectmodal: false,
      refdata: [],
      icddata: [],
      cptdata: [],
      refdatadetails: [],
      inscompany: "",
      npivalue: "",
      refname: "",
      claimID: "",
      modifierdata: [],
      subsfirstname: "",
      subslastname: "",
      subsdob: "",
      subsmrn: "",
      subsgender: "",
      subsid: "",
      patientfirstname: "",
      patientlastname: "",
      patientfirstnameErr: "",
      patientlastnameErr: "",
      patientdob: "",
      patientgender: "",
      reqtype: "",
      servicetypes: "Authorization",
      claimtype: "",
      facilitytype: "",
      eventdate1: "",
      eventdate2: "",
      quantity1: "",
      quantity2: "",
      levelofservice: "",
      accidentdate: "",
      accidentcity: "",
      accidentstate: "",
      relatedcause: "",
      subsgenderErr: "",
      subsdobErr: "",
      reqtypeErr: "",
      claimidErr:"",
      patientdobErr: "",
      patientgenderErr: "",
      getpayername: "",
      inputValue: "",
      checkvalue:false,
      suggestions: [],
    };
    // this.handleSubmit1 = this.handleSubmit1.bind(this);
  }
  handleIcdChange = async (e) => {
    try {
      console.log("inside handle input change", e);
      // Set the input value to the current value
      this.setState({ inputValue: e }, () =>
        console.log("e value", this.state.inputValue)
      );

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const response = await fetch(
        `${ip}/ICDmaster/filter?code=${this.state.inputValue}&clientId=${this.state.clientid}`,
        { headers: headers }
      );
      const data = await response.json();
      console.log("data msg", data.icdmaster);
      const data1 = data.icdmaster.map((data, index) => {
        return data.code;
      });
      const suggestions = data1; // Replace "results" with your API response property containing the suggestions
      console.log("suggestions", suggestions);
      this.setState({ suggestions });
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  handleSuggestionClick = (selected) => {
    // Set the selected suggestion as the input value
    const suggestion = selected[0];
    this.setState({ inputValue: suggestion });

    // Clear the suggestions
    this.setState({ suggestions: [] });
  };
  onSearchSubjects = (values) => {
    if (values.length > 0) {
      this.refs.SubjectTypeahead.getInstance().clear();
      this.props.onSearchSubjects(values[0]);
    }
  };
  redirectpage = () => {
    this.setState({
      redirectmodal: !this.state.redirectmodal,
    });
  };
  // add service line informations
  handleChangeservice(i, e) {
    let formValues = this.state.formValues;
    formValues[i][e.target.name] = e.target.value;
    this.setState(
      { formValues },
      () => {
        this.addAuth();
      },
      () => {
        console.log("values code", this.state.formValues);
      }
    );
  }
  addFormFields(e) {
    e.preventDefault();
    this.setState({
      formValues: [...this.state.formValues, { icd: "", icddate: "" }],
    });
  }
  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }

  //add table fields and remove fields
  handleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    rows[idx] = {
      [name]: value,
    };
    console.log("name", [name], rows[idx], idx);
    this.setState(
      {
        rows,
      },
      () => {
        console.log("e row", this.state.rows);
      }
    );
  };
  handleChangeFrom = (idx) => (e) => {
    const { value } = e.target;
    this.setState(
      (prevState) => {
        const rows = [...prevState.rows];
        rows[idx].From = value;
        return { rows };
      },
      () => console.log("value from", this.state.rows)
    );
  };
  handleChangeTo = (idx) => (e) => {
    const { value } = e.target;
    this.setState(
      (prevState) => {
        const rows = [...prevState.rows];
        rows[idx].To = value;
        return { rows };
      },
      () => console.log("value from", this.state.rows)
    );
  };
  handleChangecode = (idx) => (values) => {
    if (values.length > 0) {
      this.refs.SubjectTypeahead.getInstance().clear();
      this.props.onSearchSubjects(values[0]);
    }
    const { value } = values;
    this.setState(
      (prevState) => {
        const rows = [...prevState.rows];
        rows[idx].code = value;
        return { rows };
      },
      () => console.log("value code", this.state.rows)
    );
  };
  handleChangemodifier1 = (idx) => (values) => {
    if (values.length > 0) {
      this.refs.SubjectTypeahead.getInstance().clear();
      this.props.onSearchSubjects(values[0]);
    }
    const { value } = values;
    this.setState(
      (prevState) => {
        const rows = [...prevState.rows];
        rows[idx].modifier1 = value;
        return { rows };
      },
      () => console.log("value modifer1", this.state.rows)
    );
  };
  handleChangemodifier2 = (idx) => (values) => {
    if (values.length > 0) {
      this.refs.SubjectTypeahead.getInstance().clear();
      this.props.onSearchSubjects(values[0]);
    }
    const { value } = values;
    this.setState(
      (prevState) => {
        const rows = [...prevState.rows];
        rows[idx].modifier2 = value;
        return { rows };
      },
      () => console.log("value modifier2", this.state.rows)
    );
  };
  handleChangemodifier3 = (idx) => (values) => {
    if (values.length > 0) {
      this.refs.SubjectTypeahead.getInstance().clear();
      this.props.onSearchSubjects(values[0]);
    }
    const { value } = values;
    this.setState(
      (prevState) => {
        const rows = [...prevState.rows];
        rows[idx].modifier3 = value;
        return { rows };
      },
      () => console.log("value modifier3", this.state.rows)
    );
  };
  handleChangemodifier4 = (idx) => (values) => {
    if (values.length > 0) {
      this.refs.SubjectTypeahead.getInstance().clear();
      this.props.onSearchSubjects(values[0]);
    }
    const { value } = values;
    this.setState(
      (prevState) => {
        const rows = [...prevState.rows];
        rows[idx].modifier4 = value;
        return { rows };
      },
      () => console.log("value modifier4", this.state.rows)
    );
  };
  handleChangeicd1 = (idx) => (e) => {
    const { value } = e.target;
    this.setState(
      (prevState) => {
        const rows = [...prevState.rows];
        rows[idx].icd1 = value;
        return { rows };
      },
      () => console.log("value icd1", this.state.rows)
    );
  };
  handleChangeicd2 = (idx) => (e) => {
    const { value } = e.target;
    this.setState(
      (prevState) => {
        const rows = [...prevState.rows];
        rows[idx].icd2 = value;
        return { rows };
      },
      () => console.log("value icd2", this.state.rows)
    );
  };
  handleChangeicd3 = (idx) => (e) => {
    const { value } = e.target;
    this.setState(
      (prevState) => {
        const rows = [...prevState.rows];
        rows[idx].icd3 = value;
        return { rows };
      },
      () => console.log("value icd1", this.state.rows)
    );
  };
  handleChangeicd4 = (idx) => (e) => {
    const { value } = e.target;
    this.setState(
      (prevState) => {
        const rows = [...prevState.rows];
        rows[idx].icd4 = value;
        return { rows };
      },
      () => console.log("value icd1", this.state.rows)
    );
  };
  handleChangeunits = (idx) => (e) => {
    const { value } = e.target;
    this.setState((prevState) => {
      const rows = [...prevState.rows];
      rows[idx].units = value;
      return { rows };
    });
    this.setState(
      {
        codeinfo: [...this.state.rows],
      }
      // () => this.addAuth()
    );
  };
  handleAddRow = () => {
    const item = {
      From: "",
      To: "",
      code: "",
      modifier1: "",
      modifier2: "",
      modifier3: "",
      modifier4: "",
      icd1: "",
      icd2: "",
      icd3: "",
      icd4: "",
      units: "1",
    };
    this.setState({
      rows: [...this.state.rows, item],
    });
  };
  handleRemoveRow = () => {
    this.setState({
      rows: this.state.rows.slice(0, -1),
    });
  };
  handleRemoveSpecificRow = (idx) => () => {
    const rows = [...this.state.rows];
    rows.splice(idx, 1);
    this.setState({ rows });
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //add provider informations and remove provider informations
  addproviderDetails() {
    this.setState({
      providerDetails: [
        ...this.state.providerDetails,
        {
          refprovider: "",
          firstnameaddprovider: "",
          lastnameaddprovider: "",
          prefixaddprovider: "",
          npiprovider: "",
          taxcode: "",
          addressprovider: "",
          nameprovider: "",
          phoneprovider: "",
          faxprovider: "",
          emailprovider: "",
        },
      ],
    });
  }
  removeproviderDetails(i) {
    let providervalues = this.state.providerDetails;
    providervalues.splice(i, 1);
    this.setState({ providerDetails: this.state.providerDetails });
  }
  resetprovider = () => {
    this.setState({
      providerDetails: [
        {
          refprovider: "",
          firstnameaddprovider: "",
          lastnameaddprovider: "",
          prefixaddprovider: "",
          npiprovider: "",
          taxcode: "",
          addressprovider: "",
          nameprovider: "",
          phoneprovider: "",
          faxprovider: "",
          emailprovider: "",
        },
      ],
    });
  };
  handleaddprovider(i, e) {
    let providervalues = this.state.providerDetails;
    providervalues[i][e.target.name] = e.target.value;
    this.setState({ providervalues });
  }

  //get refferal provider data
  getRef = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/referralprovider/all`, {
        headers: headers,
      })
      .then((res) => {
        let data = res.data.referralprovider.map((data, i) => {
          return data.physicianfirstname + "" + data.physicianlastname;
        });

        this.setState({
          refdata: res.data.referralprovider,
        });
      })
      .catch((err) => {});
  };
  //get referral provider details
  getRefDetails = async (index, ref, e) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    try {
      console.log("value drop", this.state.dataquery1, this.state.dataquery2);
      let url;
      url = `${ip}/referralprovider/getproviderdetails?physicianfirstname=${this.state.dataquery1}&physicianlastname=${this.state.dataquery2}`;
      const res = await axios.get(url, { headers: headers });
      const providerDetailsCopy = [...this.state.providerDetails]; // Create a copy of the array
      providerDetailsCopy[index] = {
        // Update the specific index with the retrieved data
        firstnameaddprovider: res.data.referralprovider[0].physicianfirstname,
        lastnameaddprovider: res.data.referralprovider[0].physicianlastname,
        prefixaddprovider: res.data.referralprovider[0].prefix,
        npiprovider: res.data.referralprovider[0].npi,
        taxcode: res.data.referralprovider[0].taxonomycode,
        addressprovider:
          res.data.referralprovider[0].address +
          ", " +
          res.data.referralprovider[0].city +
          ", " +
          res.data.referralprovider[0].state +
          ", " +
          res.data.referralprovider[0].country +
          ", " +
          res.data.referralprovider[0].zipCode,
        phoneprovider: res.data.referralprovider[0].phone1,
        faxprovider: res.data.referralprovider[0].fax,
        emailprovider: res.data.referralprovider[0].email,
      };
      this.setState(
        {
          providerDetails: providerDetailsCopy, // Update the state with the modified array
        }
        // () => this.addAuth()
      );
    } catch (err) {
      // Handle the error
    }
  };
  //get ICD code
  getICD = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/ICDmaster/all`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          icddata: res.data.ICDmaster,
        });
      })
      .catch((err) => {});
  };
  //get Modifiers
  getmodifier = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/modifier/all`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          modifierdata: res.data.modifier,
        });
      })
      .catch((err) => {});
  };
  //get cpt code
  getcpt = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/cptmaster/all `, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          cptdata: res.data.cpt,
        });
      })
      .catch((err) => {});
  };
  
  //get billing provider
  getbillingname = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.clientid) {
      url = `${ip}/billingprovider/all?clientId=${this.state.clientid}`;
    } else {
      url = `${ip}/billingprovider/all`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        let data = res.data.billingprovider.map((data, index) => {
          return data.Display_name;
        });
        this.setState({
          billingarray: data,
        });
      })

      .catch((err) => {});
  };
  //get facility type
  getfacilitytype = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.clientid) {
      url = `${ip}/facility/all?clientId=${this.state.clientid}`;
    } else {
      url = `${ip}/facility/all`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          facilityarray: res.data.facility,
        });
      })
      .catch((err) => {});
  };
  addAuthValidation = () => {
    let hasErr = true;
    // let subsgenderErr = "";
    // let subsdobErr = "";
    let claimidErr = "";
    let reqtypeErr = "";
    let patientdobErr = "";
    let patientgenderErr = "";
    let patientfirstnameErr="";
    let patientlastnameErr = "";
    // if (this.state.subsgender === "") {
    //   subsgenderErr = "This field is required";
    //   hasErr = false;
    // }

    // if (this.state.subsdob === "") {
    //   subsdobErr = "This field is required";
    //   hasErr = false;
    // }
    if(this.state.claimID === ""){
      claimidErr = "This field is required";
      hasErr = false;
    }
    if (this.state.patientdob === "") {
      patientdobErr = "This field is required";
      hasErr = false;
    }
    if (this.state.patientgender === "") {
      patientgenderErr = "This field is required";
      hasErr = false;
    }
    if (this.state.reqtype === "") {
      reqtypeErr = "This field is required";
      hasErr = false;
    }
    if (this.state.patientfirstname === "") {
      patientfirstnameErr = "This field is required";
      hasErr = false;
    }
    if (this.state.patientlastname === "") {
      patientlastnameErr = "This field is required";
      hasErr = false;
    }
    this.setState({
      // subsgenderErr,
      // subsdobErr,
      claimidErr,
      reqtypeErr,
      patientdobErr,
      patientgenderErr,
      patientfirstnameErr,
      patientlastnameErr,
    });
    return hasErr;
  };
  //get state names
  getstatename = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.clientid) {
      url = `${ip}/zipcodes/getstates?clientId=${this.state.clientid}`;
    } else {
      url = `${ip}/zipcodes/getstates`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          statearray: res.data.zipcodes,
        });
      })

      .catch((err) => {});
  };
//get city names
getcityname = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${this.state.accesstoken}`,
  };
  // eslint-disable-next-line
  let url;
  if (this.state.clientid) {
    url = `${ip}/zipcodes/getcities?state=${this.state.accidentstate}&clientId=${this.state.clientid}`;
  } else {
    url = `${ip}/zipcodes/getcities?state=${this.state.accidentstate}`;
  }
  const res = axios
    .get(url, {
      headers: headers,
    })
    .then((res) => {
      this.setState({
        cityarray: res.data.zipcodes,
      });
    })

    .catch((err) => {});
};
  addAuth = async () => {
    const Validate = this.addAuthValidation();
    if (Validate) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
   
      let addData;
      if(this.state.checkvalue === true) {
        if(this.state.servicetypes === ""){
          this.setState({servicetypes:"Authorization"})
        }
        // if(this.state.relatedcause === "others"){
        //   this.setState({
        //     relatedcause:this.state.othercause
        //   }, ()=> {console.log("other cause", this.state.relatedcause)})
        // }
        let date1;
        let date2;
         date1 = this.state.eventdate1 ? moment(this.state.eventdate1).format("YYYYMMDD") : "";
        
          date2 = this.state.eventdate2 ?  moment(this.state.eventdate2).format("YYYYMMDD") : "";
   
        addData = {
          PayerName: this.state.payername,
          requestType: this.state.typeofreq,
          PatientFirstName: this.state.patientfirstname,
          PatientLastName: this.state.patientlastname,
          PatientDOB: this.state.patientdob,
          Gender: this.state.patientgender,
          subscriber:true,
          organizationName: this.state.reqtype,
          providerDetils: this.state.providerDetails,
          serviceType: this.state.servicetypes,
          ClaimID: this.state.claimID,
          claimType: this.state.claimtype,
          facilityType: this.state.facilitytype,
          eventFromDate: date1,
          eventToDate: date2,
          quantity: this.state.quantity1,
          levelOfService: this.state.levelofservice,
          accidentDate: this.state.accidentdate ? this.state.accidentdate : "",
          accidentCity: this.state.accidentcity,
          accidentState: this.state.accidentstate,
          relatedCause: this.state.relatedcause,
          ICDs: this.state.formValues,
          procedureCodeInformation: this.state.codeinfo,
          clientId: parseInt(this.state.clientid),
          SubscriberId: this.state.subsid,
          SubscriberFirstName: this.state.subsfirstname,
          SubscriberLastName: this.state.subslastname,
          SubscriberGender: this.state.subsgender,
          SubscriberDOB: this.state.subsdob,
          SubscriberMRN: this.state.subsmrn,
          status: this.state.status,
        };
      }
      else{
        if(this.state.servicetypes === ""){
          this.setState({servicetypes:"Authorization"})
        }
        if(this.state.relatedcause === "others"){
          this.setState({
            relatedcause:this.state.othercause
          }, ()=> {console.log("other cause", this.state.relatedcause)})
        }
        let date1 = moment(this.state.eventdate1).format("YYYYMMDD");
        let date2 = moment(this.state.eventdate2).format("YYYYMMDD");
        addData = {
          PayerName: this.state.payername,
          requestType: this.state.typeofreq,
          PatientFirstName: this.state.patientfirstname,
          PatientLastName: this.state.patientlastname,
          PatientDOB: this.state.patientdob,
          Gender: this.state.patientgender,
          organizationName: this.state.reqtype,
          providerDetils: this.state.providerDetails,
          serviceType: this.state.servicetypes,
          ClaimID: this.state.claimID,
          claimType: this.state.claimtype,
          facilityType: this.state.facilitytype,
          eventFromDate: date1,
          eventToDate: date2,
          quantity: this.state.quantity1,
          levelOfService: this.state.levelofservice,
          accidentDate: this.state.accidentdate,
          accidentCity: this.state.accidentcity,
          accidentState: this.state.accidentstate,
          relatedCause: this.state.relatedcause,
          ICDs: this.state.formValues,
          procedureCodeInformation: this.state.codeinfo,
          clientId: parseInt(this.state.clientid),
          status: this.state.status,
        };
      }
     
      const res = await axios
        .post(`${ip}/authorization/addnewauthorization`, addData, {
          headers: headers,
        })
        .then((res) => {
          alert("Details Added Successfully");
          this.redirectpage();
        })
        .catch((err) => {console.log("err", err)});
    }
  };
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getRef();
    this.getICD();
    this.getmodifier();
    this.getcpt();
    this.getbillingname();
    this.getfacilitytype();
    this.getstatename();
  }
  render() {
    const { inputValue, suggestions } = this.state;
    return (
      <div>
      <Newsidebar name="Authorization" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">
          <div className="auth_form">
            <div>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Payer Name
                  </h5>
                </Col>
                <Col md="6">
                  <Typeahead
                  id="typeahed1"
                    clearButton={true}
                    defaultInputValue={this.state.payername || ""}
                    options={this.state.payernamearray}
                    labelKey={(option) =>
                      (this.state.payername = `${option.payerName}`)
                    }
                    onClick={(e) => {
                      this.setState({
                        payername: e.target.value,
                      });
                    }}
                    placeholder="Select Payer Name"
                  />
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Type of Request
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="select"
                    placeholder="Select Request"
                    defaultValue={this.state.typeofreq}
                    onChange={(e) => {
                      this.setState({
                        typeofreq: e.target.value,
                      });
                    }}
                  >
                    <option value={""} hidden>
                      Select Type of Request
                    </option>
                    <option value={"Referral"}>Referral</option>
                    <option value={"Admission for Surgery"}>
                      Admission for Surgery
                    </option>
                    <option
                      value={
                        "Request for Behavioral Health Emergency Admission"
                      }
                    >
                      Request for Behavioral Health Emergency Admission
                    </option>
                    <option value={"Request for Home Health Care"}>
                      Request for Home Health Care
                    </option>
                    <option
                      value={"Request for Non-emergency Transportation Service"}
                    >
                      Request for Non-emergency Transportation Service
                    </option>
                    <option value={"Medical Services Reservation"}>
                      Medical Services Reservation
                    </option>
                  </Input>
                </Col>
              </Row>
              <br />
            </div>
            <hr />
            <div className="heading_auth">
            {this.state.subscriberopen ? (
                  <BsArrowsFullscreen
                    className="closemenu-auth"
                    onClick={() => this.setState({ subscriberopen: false })}
                  />
                ) : (
                  <BsArrowsMove
                    className="openmenu-auth"
                    onClick={() => this.setState({ subscriberopen: true })}
                  />
                )}{" "}&nbsp;&nbsp;
             <span>Patient (check if different from Subscriber)</span>
           </div>
      
            {this.state.subscriberopen === true ? (
              <div>
                <Row>
                <Col>
                    <div>
                        <div>
                        <Row
                        style={{ paddingTop: "4%", justifyContent: "center" }}
                      >
                        <Col md="4">
                          <h5
                            className="label labelfontsize required"
                            style={{ textAlign: "left", whiteSpace: "nowrap" }}
                          >
                            Patient Name
                          </h5>
                        </Col>
                        <Col md="3">
                          <Input
                            type="text"
                            className="dashinputname labelfontsize"
                            placeholder="First Name"
                            value={this.state.patientfirstname}
                            onChange={(e) => {
                              this.setState({
                                patientfirstname: e.target.value,
                                patientfirstnameErr:""
                              });
                            }}
                          />
                            <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.patientfirstnameErr && (
                              <span>{this.state.patientfirstnameErr}</span>
                            )}
                          </div>
                        </Col>
                        <Col md="3">
                          <Input
                            type="text"
                            className="dashinputname labelfontsize"
                            placeholder="Last Name"
                            value={this.state.patientlastname}
                            onChange={(e) => {
                              this.setState({
                                patientlastname: e.target.value,
                                patientlastnameErr:""
                              });
                            }}
                          />
                           <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.patientlastnameErr && (
                              <span>{this.state.patientlastnameErr}</span>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row
                        style={{ paddingTop: "4%", justifyContent: "center" }}
                      >
                        <Col md="4">
                          <h5
                            className="label required labelfontsize"
                            style={{ textAlign: "left" }}
                          >
                            Patient DOB
                          </h5>
                        </Col>
                        <Col md="6">
                          <Input
                            className="labelfontsize"
                            type="date"
                            placeholder=""
                            value={this.state.patientdob}
                            onChange={(e) => {
                              this.setState({
                                patientdob: e.target.value,
                                patientdobErr: "",
                              });
                            }}
                          />
                          <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.patientdobErr && (
                              <span>{this.state.patientdobErr}</span>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row
                        style={{ paddingTop: "4%", justifyContent: "center" }}
                      >
                        <Col md="4">
                          <h5
                            className="label required labelfontsize"
                            style={{ textAlign: "left" }}
                          >
                            Patient Gender
                          </h5>
                        </Col>
                        <Col md="6">
                          <Input
                            className="labelfontsize"
                            type="select"
                            placeholder=""
                            value={this.state.patientgender}
                            onChange={(e) => {
                              this.setState({
                                patientgender: e.target.value,
                                patientgenderErr: "",
                              });
                            }}
                          >
                            <option value="" hidden>
                              Select..
                            </option>
                            <option value="M">M</option>
                            <option value="F">F</option>
                          </Input>
                          <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.patientgenderErr && (
                              <span>{this.state.patientgenderErr}</span>
                            )}
                          </div>
                        </Col>
                      </Row>
                      </div>
                    </div>
                  </Col>
                  <Col>
                  <div className="heading_auth">
            <Input
                        tabindex="14"
                        type="checkbox"
                        checked={this.state.checkvalue}
                        onChange={() => {
                          this.setState({
                            checkvalue: !this.state.checkvalue,
                          });
                        }}
                      /> &nbsp;&nbsp;
              <span>
            
                &nbsp; Subscriber
              </span>
            </div>
                  {this.state.checkvalue === true ?
                      (
                    <div>
                      <Row
                        style={{ paddingTop: "4%", justifyContent: "center" }}
                      >
                        <Col md="4">
                          <h5
                            className="label labelfontsize"
                            style={{ textAlign: "left", whiteSpace: "nowrap" }}
                          >
                            Subscriber Name
                          </h5>
                        </Col>
                        <Col md="3">
                          <Input
                            type="text"
                            className="dashinputname labelfontsize"
                            placeholder="First Name"
                            value={this.state.subsfirstname}
                            onChange={(e) => {
                              this.setState({
                                subsfirstname: e.target.value,
                              });
                            }}
                          />
                        </Col>
                        <Col md="3">
                          <Input
                            type="text"
                            className="dashinputname labelfontsize"
                            placeholder="Last Name"
                            value={this.state.subslastname}
                            onChange={(e) => {
                              this.setState({
                                subslastname: e.target.value,
                              });
                            }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ paddingTop: "4%", justifyContent: "center" }}
                      >
                        <Col md="4">
                          <h5
                            className="label labelfontsize"
                            style={{ textAlign: "left" }}
                          >
                            Subscriber MRN
                          </h5>
                        </Col>
                        <Col md="6">
                          <Input
                            type="text"
                            className="labelfontsize"
                            placeholder=""
                            value={this.state.subsmrn}
                            onChange={(e) => {
                              this.setState({
                                subsmrn: e.target.value,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ paddingTop: "4%", justifyContent: "center" }}
                      >
                        <Col md="4">
                          <h5
                            className="label labelfontsize"
                            style={{ textAlign: "left" }}
                          >
                            Subscriber DOB
                          </h5>
                        </Col>
                        <Col md="6">
                          <Input
                            type="date"
                            className="labelfontsize"
                            placeholder=""
                            value={this.state.subsdob}
                            onChange={(e) => {
                              this.setState({
                                subsdob: e.target.value,
                                // subsdobErr: "",
                              });
                            }}
                          />
                          {/* <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.subsdobErr && (
                              <span>{this.state.subsdobErr}</span>
                            )}
                          </div> */}
                        </Col>
                      </Row>
                      <Row
                        style={{ paddingTop: "4%", justifyContent: "center" }}
                      >
                        <Col md="4">
                          <h5
                            className="label labelfontsize"
                            style={{ textAlign: "left" }}
                          >
                            Subscriber Gender
                          </h5>
                        </Col>
                        <Col md="6">
                          <Input
                            className="labelfontsize"
                            type="select"
                            placeholder=""
                            value={this.state.subsgender}
                            onChange={(e) => {
                              this.setState({
                                subsgender: e.target.value,
                                // subsgenderErr: "",
                              });
                            }}
                          >
                            <option value="" hidden>
                              Select..
                            </option>
                            <option value="M">M</option>
                            <option value="F">F</option>
                          </Input>
                          {/* <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.subsgenderErr && (
                              <span>{this.state.subsgenderErr}</span>
                            )}
                          </div> */}
                        </Col>
                      </Row>
                      <Row
                        style={{ paddingTop: "4%", justifyContent: "center" }}
                      >
                        <Col md="4">
                          <h5
                            className="label labelfontsize"
                            style={{ textAlign: "left" }}
                          >
                            Subscriber id
                          </h5>
                        </Col>
                        <Col md="6">
                          <Input
                            className="labelfontsize"
                            type="text"
                            placeholder=""
                            value={this.state.subsid}
                            onChange={(e) => {
                              this.setState({
                                subsid: e.target.value,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                      <br />
                    </div> )
                      : (
                       null
                      )}
                  </Col>
            
                </Row>
              </div>
            ) : null}
            <hr />
            <div>
              <div className="heading_auth">
                <span>
                  {this.state.reqorgopen ? (
                    <BsArrowsFullscreen
                      className="closemenu-auth"
                      onClick={() => this.setState({ reqorgopen: false })}
                    />
                  ) : (
                    <BsArrowsMove
                      className="openmenu-auth"
                      onClick={() => this.setState({ reqorgopen: true })}
                    />
                  )}{" "}
                  &nbsp; Requesting Organisation
                </span>
              </div>
              <Row>
                {this.state.reqorgopen === true ? (
                  <Row style={{ justifyContent: "center" }}>
                    <Col md="2">
                      <h5
                        className="label required labelfontsize"
                        style={{ textAlign: "left" }}
                      >
                        Requesting type
                      </h5>
                    </Col>
                    <Col md="4">
                      <Typeahead
                        className="labelfontsize"
                        placeholder=""
                        options={this.state.billingarray}
                        selected={
                          this.state.reqtype ? [this.state.reqtype] : []
                        }
                        onChange={(selected) => {
                          this.setState({
                            reqtype: selected[0] || "",
                            reqtypeErr: "",
                          });
                        }}
                      />
                      <div style={{ fontSize: 14, color: "red" }}>
                        {this.state.reqtypeErr && (
                          <span>{this.state.reqtypeErr}</span>
                        )}
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </Row>
            </div>
            <hr />
            <div className="heading_auth">
              <span>
                {this.state.addrefopen ? (
                  <BsArrowsFullscreen
                    className="closemenu-auth"
                    onClick={() => this.setState({ addrefopen: false })}
                  />
                ) : (
                  <BsArrowsMove
                    className="openmenu-auth"
                    onClick={() => this.setState({ addrefopen: true })}
                  />
                )}{" "}
                &nbsp; Add Referral Provider
              </span>
            </div>
            {this.state.addrefopen === true ? (
              <div>
                {this.state.providerDetails.map((ele, index) => (
                  <div>
                    <div className="form-inline" key={index}>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <Row>
                        <Col>
                          <Row>
                            <Col md="6">
                              <label
                                className="labelfontsize"
                                style={{
                                  whiteSpace: "nowrap",
                                  marginLeft: "2%",
                                }}
                              >
                                Referral Provider
                              </label>
                            </Col>
                            <Col md="4">
                              <Typeahead
                              id="typeahed1"
                                style={{ width: "250px" }}
                                name="refprovider"
                                clearButton={true}
                                value={ele.refprovider || ""}
                                options={this.state.refdata}
                                labelKey={(option) =>
                                  (ele.refprovider = `${
                                    option.physicianfirstname +
                                    "" +
                                    option.physicianlastname
                                  }`)
                                }
                                onChange={(e) => {
                                  if (e && e.length > 0) {
                                    this.setState(
                                      {
                                        dataquery1: e[0].physicianfirstname,
                                        dataquery2: e[0].physicianlastname, // Update the selected option in state
                                      },
                                      () => this.getRefDetails(index, e)
                                    );
                                  } else {
                                    this.setState({
                                      dataquery1: "",
                                      dataquery2: "", // Clear the selected option from state
                                    });
                                  }
                                }}
                                placeholder="Select Ref Provider"
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md="2">
                          <label className="labelfontsize">Name</label>
                        </Col>
                        <Col md="2">
                          <input
                            type="text"
                            className="labelfontsize"
                            name="firstnameaddprovider"
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              outline: "none",
                            }}
                            placeholder="Enter Firstname"
                            value={ele.firstnameaddprovider || ""}
                            onChange={(e) => this.handleaddprovider(index, e)}
                          />
                        </Col>{" "}
                        &nbsp;&nbsp;&nbsp;
                        <Col md="2">
                          <input
                            type="text"
                            className="labelfontsize"
                            name="lastnameaddprovider"
                            placeholder="Enter Lastname"
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              outline: "none",
                            }}
                            value={ele.lastnameaddprovider || ""}
                            onChange={(e) => this.handleaddprovider(index, e)}
                          />
                        </Col>
                        <Col md="2">
                          <input
                            type="text"
                            className="labelfontsize"
                            name="prefixaddprovider"
                            placeholder="Prefix"
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              width: "100px",
                              outline: "none",
                            }}
                            value={ele.prefixaddprovider || ""}
                            onChange={(e) => this.handleaddprovider(index, e)}
                          />
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <Row>
                        <Col>
                          <Row>
                            <Col md="4">
                              <label className="labelfontsize">NPI</label>
                            </Col>
                            <Col md="6">
                              <Input
                                className="labelfontsize"
                                type="text"
                                name="npiprovider"
                                value={ele.npiprovider}
                                onChange={(e) =>
                                  this.handleaddprovider(index, e)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col md="4">
                              <label className="labelfontsize">
                                Taxonomy Code
                              </label>
                            </Col>
                            <Col md="6">
                              <input
                                className="labelfontsize"
                                type="text"
                                style={{
                                  outline: "none",
                                }}
                                name="taxcode"
                                value={ele.taxcode || ""}
                                onChange={(e) =>
                                  this.handleaddprovider(index, e)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <br />
                      <Row>
                        <Col md="2">
                          <label className="labelfontsize">Address</label>
                        </Col>
                        <Col md="6">
                          <input
                            className="labelfontsize"
                            type="text"
                            name="addressprovider"
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              width: "150%",
                            }}
                            value={ele.addressprovider || ""}
                            onChange={(e) => this.handleaddprovider(index, e)}
                          />
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <Row>
                        &nbsp;&nbsp;&nbsp;
                        <Col md="2">
                          <label className="labelfontsize">
                            Contact Information
                          </label>
                        </Col>
                        <Col md="2">
                          <input
                            className="labelfontsize"
                            type="text"
                            style={{
                              outline: "none",
                            }}
                            name="nameprovider"
                            placeholder="Enter Name"
                            value={ele.nameprovider || ""}
                            onChange={(e) => this.handleaddprovider(index, e)}
                          />
                        </Col>{" "}
                        &nbsp;&nbsp;&nbsp;
                        <Col md="2">
                          <input
                            className="labelfontsize"
                            type="text"
                            style={{
                              outline: "none",
                            }}
                            name="phoneprovider"
                            placeholder="Enter Phone"
                            value={ele.phoneprovider || ""}
                            onChange={(e) => this.handleaddprovider(index, e)}
                          />
                        </Col>
                        &nbsp;&nbsp;&nbsp;
                        <Col md="2">
                          <input
                            className="labelfontsize"
                            type="text"
                            style={{
                              outline: "none",
                            }}
                            name="faxprovider"
                            placeholder="Enter Fax"
                            value={ele.faxprovider || ""}
                            onChange={(e) => this.handleaddprovider(index, e)}
                          />
                        </Col>
                        &nbsp;&nbsp;&nbsp;
                        <Col md="2">
                          <input
                            className="labelfontsize"
                            type="text"
                            style={{
                              outline: "none",
                            }}
                            name="emailprovider"
                            placeholder="Enter Email"
                            value={ele.emailprovider || ""}
                            onChange={(e) => this.handleaddprovider(index, e)}
                          />
                        </Col>
                        <Col>
                          {index ? (
                            <button
                              type="button"
                              className="button remove labelfontsize"
                              onClick={() => this.removeproviderDetails(index)}
                            >
                              Remove
                            </button>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                    <div
                      className="labelfontsize"
                      style={{ marginLeft: "83%" }}
                    >
                      <button
                        className="button"
                        type="button"
                        onClick={() => this.addproviderDetails()}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            <hr />
            <div className="heading_auth">
              <span>
                {this.state.reqdetailsopen ? (
                  <BsArrowsFullscreen
                    className="closemenu-auth"
                    onClick={() => this.setState({ reqdetailsopen: false })}
                  />
                ) : (
                  <BsArrowsMove
                    className="openmenu-auth"
                    onClick={() => this.setState({ reqdetailsopen: true })}
                  />
                )}{" "}
                &nbsp; Request Details
              </span>
            </div>
            {this.state.reqdetailsopen === true ? (
              <div>
                <Row>
                  <Col>
                    <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                      <Col md="4">
                        <h5
                          className="label labelfontsize required"
                          style={{ textAlign: "left" }}
                        >
                          Claim ID
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input
                          type="text"
                          className="labelfontsize"
                          placeholder=""
                          value={this.state.claimID}
                          onChange={(e) => {
                            this.setState({
                              claimID: e.target.value,
                              claimidErr:""
                            });
                          }}
                        ></Input>
                          <div style={{ fontSize: 14, color: "red" }}>
                        {this.state.claimidErr && (
                          <span>{this.state.claimidErr}</span>
                        )}
                      </div>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                      <Col md="4">
                        <h5
                          className="label labelfontsize"
                          style={{ textAlign: "left", whiteSpace: "nowrap" }}
                        >
                          Service types
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input
                          type="text"
                          className="labelfontsize"
                          placeholder=""
                          defaultValue={
                            "Authorization" || this.state.servicetypes
                          }
                          onChange={(e) => {
                            this.setState({
                              servicetypes: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                      <Col md="4">
                        <h5
                          className="label labelfontsize"
                          style={{ textAlign: "left" }}
                        >
                          Claim Type
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input
                          type="text"
                          placeholder=""
                          className="labelfontsize"
                          value={this.state.claimtype}
                          onChange={(e) => {
                            this.setState({
                              claimtype: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                      <Col md="4">
                        <h5
                          className="label labelfontsize"
                          style={{ textAlign: "left" }}
                        >
                          Facility Type
                        </h5>
                      </Col>
                      <Col md="6">
                        <Typeahead
                          className="labelfontsize"
                          clearButton={true}
                          options={this.state.facilityarray}
                          labelKey={(option) =>
                            (this.state.facilitytype = `${option.code}-${option.description}`)
                          }
                          placeholder="Select..."
                          onChange={(option) =>
                            this.setState({
                              facilitytype: option.code,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <br />
                  </Col>
                  <Col>
                    <br />
                    <br />
                    <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                      <Col md="4">
                        <h5
                          className="label labelfontsize"
                          style={{ textAlign: "left", whiteSpace: "nowrap" }}
                        >
                          Event Date
                        </h5>
                      </Col>
                      <Col md="3">
                        <Input
                          type="date"
                          className="dashinputname labelfontsize"
                          value={this.state.eventdate1}
                          placeholder=""
                          onChange={(e) => {
                            this.setState({
                              eventdate1: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col md="3">
                        <Input
                          type="date"
                          className="dashinputname labelfontsize"
                          value={this.state.eventdate2}
                          placeholder=""
                          onChange={(e) => {
                            this.setState({
                              eventdate2: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                      <Col md="4">
                        <h5
                          className="label labelfontsize"
                          style={{ textAlign: "left", whiteSpace: "nowrap" }}
                        >
                          Quantity
                        </h5>
                      </Col>
                      <Col md="3">
                        <Input
                          className="labelfontsize"
                          type="text"
                          placeholder=""
                          value={this.state.quantity1}
                          onChange={(e) => {
                            this.setState({
                              quantity1: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col md="3">
                        <Input
                          className="labelfontsize"
                          type="text"
                          placeholder=""
                          value={this.state.quantity2}
                          onChange={(e) => {
                            this.setState({
                              quantity2: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                      <Col md="4">
                        <h5
                          className="label labelfontsize"
                          style={{ textAlign: "left", whiteSpace: "nowrap" }}
                        >
                          Level Of Service
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input
                          className="labelfontsize"
                          type="text"
                          placeholder=""
                          value={this.state.levelofservice}
                          onChange={(e) => {
                            this.setState({
                              levelofservice: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                  <Col md="2">
                    <h5
                      className="label labelfontsize"
                      style={{ textAlign: "left", whiteSpace: "nowrap" }}
                    >
                      Accident Date
                    </h5>
                  </Col>
                  <Col md="3">
                    <Input
                      type="date"
                      className="labelfontsize"
                      placeholder=""
                      value={this.state.accidentdate}
                      onChange={(e) => {
                        this.setState({
                          accidentdate: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md="2">
                    <h5
                      className="label labelfontsize"
                      style={{ textAlign: "left", whiteSpace: "nowrap" }}
                    >
                      Accident Location
                    </h5>
                  </Col>
                  <Col md="2">
                  <Typeahead
                          className="labelfontsize"
                          clearButton={true}
                          options={this.state.statearray}
                          labelKey={(option) =>
                            (this.state.accidentstate = `${option._id}`)
                          }
                          placeholder="Select state"
                          onChange={(option) =>
                            this.setState({
                              accidentstate: option._id,
                            }, ()=> {this.getcityname()})
                          }
                        />
                  </Col>
                  <Col md="2">
                  <Typeahead
                          className="labelfontsize"
                          clearButton={true}
                          options={this.state.cityarray}
                          labelKey={(option) =>
                            (this.state.accidentcity = `${option._id}`)
                          }
                          placeholder="Select city"
                          onChange={(option) =>
                            this.setState({
                              accidentcity: option._id,
                            })
                          }
                        />
                  </Col>
           
                </Row>
                <br />
                <br />

                <Row>
                  <Col md="2">
                    <h5
                      className="label labelfontsize"
                      style={{
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        marginLeft: "64px",
                      }}
                    >
                      Related Cause
                    </h5>
                  </Col>
                  <Col md="3" style={{ marginLeft: "4%" }}>
                    <Input
                      className="labelfontsize"
                      type="select"
                      placeholder=""
                      value={this.state.relatedcause}
                      onChange={(e) => {
                        this.setState({
                          relatedcause: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        Select cause..
                      </option>
                      <option value={"Accident"}>Accident</option>
                      <option value={"Pregnancy"}>Pregnancy</option>
                      <option value={"Illness"}>Illness</option>
                      <option value={"Auto Accident"}>Auto Accident</option>
                      <option value={"Employment Accident"}>
                        Employment Accident
                      </option>
                      <option value={"Initial Treatment"}>
                        Initial Treatment
                      </option>
                      <option value={"Not sure"}>Not sure</option>
                      <option value={"others"}>Others</option>
                    </Input>
                  </Col>
                  <Col>
                    {this.state.relatedcause === "others" ? (
                      <Input
                        type="text"
                        className="dashinputname labelfontsize"
                        value={this.state.othercause}
                        placeholder=""
                        onChange={(e) => {
                          this.setState({
                            othercause: e.target.value,
                          });
                        }}
                      />
                    ) : null}
                  </Col>
                </Row>
              </div>
            ) : null}
            <hr />
            <div className="heading_auth">
              <span>
                {this.state.procedurecodeopen ? (
                  <BsArrowsFullscreen
                    className="closemenu-auth"
                    onClick={() => this.setState({ procedurecodeopen: false })}
                  />
                ) : (
                  <BsArrowsMove
                    className="openmenu-auth"
                    onClick={() => this.setState({ procedurecodeopen: true })}
                  />
                )}{" "}
                &nbsp; Procedure Code Information
              </span>
            </div>
            <div>
              <br />
              {this.state.procedurecodeopen === true ? (
                <div className="row clearfix" style={{ marginLeft: "2%" }}>
                  <div className="col-md-11 column">
                    <Table
                      className="table table-bordered table-hover"
                      id="tab_logic"
                    >
                      <thead>
                        <tr>
                          <th
                            className="text-center"
                            style={{
                              backgroundColor: "#041c3c",
                              color: "white",
                              textAlign: "center",
                            }}
                          ></th>
                          <th
                            colSpan={2}
                            style={{
                              backgroundColor: "#041c3c",
                              color: "white",
                              textAlign: "center",
                              fontSize: "12px",
                            }}
                          >
                            Date of Service
                          </th>

                          <th
                            className="text-center"
                            style={{
                              backgroundColor: "#041c3c",
                              color: "white",
                              textAlign: "center",
                              fontSize: "12px",
                            }}
                          >
                            {" "}
                            CPT
                          </th>

                          <th
                            className="text-center"
                            colSpan={4}
                            style={{
                              backgroundColor: "#041c3c",
                              color: "white",
                              textAlign: "center",
                              fontSize: "12px",
                            }}
                          >
                            Modifiers
                          </th>
                          <th
                            className="text-center"
                            colSpan={4}
                            style={{
                              backgroundColor: "#041c3c",
                              color: "white",
                              textAlign: "center",
                              fontSize: "12px",
                            }}
                          >
                            ICDs
                          </th>
                          <th
                            className="text-center"
                            style={{
                              backgroundColor: "#041c3c",
                              color: "white",
                              textAlign: "center",
                              fontSize: "12px",
                            }}
                          >
                            {" "}
                            Units
                          </th>
                          <th
                            className="text-center"
                            style={{
                              backgroundColor: "#041c3c",
                              color: "white",
                              textAlign: "center",
                            }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "center", fontSize: "12px" }}>
                            From
                          </td>
                          <td style={{ textAlign: "center", fontSize: "12px" }}>
                            To
                          </td>
                          <td></td>
                          <td
                            className="text-center"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            1
                          </td>
                          <td
                            className="text-center"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            2
                          </td>
                          <td
                            className="text-center"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            3
                          </td>
                          <td
                            className="text-center"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            4
                          </td>
                          <td
                            className="text-center"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            1
                          </td>
                          <td
                            className="text-center"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            2
                          </td>
                          <td
                            className="text-center"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            3
                          </td>
                          <td
                            className="text-center"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            4
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        {this.state.rows.map((item, idx) => (
                          <tr key={idx}>
                            <td style={{ width: "30px" }}>{idx + 1}</td>
                            <td style={{ width: "122px" }}>
                              <input
                                style={{ width: "122px", fontSize: "12px" }}
                                type="date"
                                name="From"
                                size={"sm"}
                                value={this.state.rows[idx].From}
                                onChange={this.handleChangeFrom(idx)}
                                className="form-control"
                              />
                            </td>
                            <td style={{ width: "122px" }}>
                              <input
                                style={{ width: "122px", fontSize: "12px" }}
                                type="date"
                                name="To"
                                value={this.state.rows[idx].To}
                                onChange={this.handleChangeTo(idx)}
                                className="form-control"
                              />
                            </td>
                            <td style={{ width: "70px" }}>
                              <Typeahead
                              id="typeahed1"
                                style={{ width: "70px" }}
                                maxLength={5}
                                name="code"
                                ref="SubjectTypeahead"
                                size="sm"
                                // clearButton={true}
                                defaultInputValue={this.state.rows[idx].code}
                                options={this.state.cptdata}
                                labelKey={(option) =>
                                  (item.code = `${option.cpt_code}`)
                                }
                                onClick={this.handleChangecode(idx)}
                              />
                            </td>
                            <td style={{ width: "40px" }}>
                              <Typeahead
                              id="typeahed1"
                                style={{ width: "40px", fontSize: "12px" }}
                                maxLength={2}
                                name="modifier1"
                                ref="SubjectTypeahead"
                                size="sm"
                                // clearButton={true}
                                defaultInputValue={
                                  this.state.rows[idx].modifier1
                                }
                                options={this.state.modifierdata}
                                labelKey={(option) =>
                                  (item.modifier1 = `${option.code}`)
                                }
                                onClick={this.handleChangemodifier1(idx)}
                              />
                            </td>
                            <td style={{ width: "40px" }}>
                              <Typeahead
                              id="typeahed1"
                                style={{ width: "40px", fontSize: "12px" }}
                                maxLength={2}
                                size="sm"
                                name="modifier2"
                                ref="SubjectTypeahead"
                                // clearButton={true}
                                defaultInputValue={
                                  this.state.rows[idx].modifier2
                                }
                                options={this.state.modifierdata}
                                labelKey={(option) =>
                                  (item.modifier2 = `${option.code}`)
                                }
                                onClick={this.handleChangemodifier2(idx)}
                              />
                            </td>
                            <td style={{ width: "40px" }}>
                              <Typeahead
                              id="typeahed1"
                                ref="SubjectTypeahead"
                                style={{ width: "40px", fontSize: "12px" }}
                                maxLength={2}
                                name="modifier3"
                                size="sm"
                                // clearButton={true}
                                defaultInputValue={
                                  this.state.rows[idx].modifier3
                                }
                                options={this.state.modifierdata}
                                labelKey={(option) =>
                                  (item.modifier3 = `${option.code}`)
                                }
                                onClick={this.handleChangemodifier3(idx)}
                              />
                            </td>
                            <td style={{ width: "40px" }}>
                              <Typeahead
                              id="typeahed1"
                                ref="SubjectTypeahead"
                                style={{ width: "40px", fontSize: "12px" }}
                                maxLength={2}
                                name="modifier4"
                                size="sm"
                                // clearButton={true}
                                defaultInputValue={
                                  this.state.rows[idx].modifier4
                                }
                                options={this.state.modifierdata}
                                labelKey={(option) =>
                                  (item.modifier4 = `${option.code}`)
                                }
                                onClick={this.handleChangemodifier4(idx)}
                              />
                            </td>
                            <td style={{ width: "60px" }}>
                              <div>
                                <Typeahead
                                  style={{ width: "60px", fontSize: "12px" }}
                                  maxLength={2}
                                  name="icd1"
                                  size="sm"
                                  // clearButton={true}
                                  defaultInputValue={this.state.rows[idx].icd1}
                                  options={this.state.icddata}
                                  labelKey={(option) =>
                                    (item.icd1 = `${option.code}`)
                                  }
                                  onClick={this.handleChangeicd1(idx)}
                                />
                              </div>
                            </td>
                            <td style={{ width: "60px" }}>
                              <Typeahead
                              id="typeahed1"
                                style={{ width: "60px", fontSize: "12px" }}
                                maxLength={2}
                                name="icd2"
                                size="sm"
                                // clearButton={true}
                                defaultInputValue={this.state.rows[idx].icd2}
                                options={this.state.icddata}
                                labelKey={(option) =>
                                  (item.icd2 = `${option.code}`)
                                }
                                onClick={this.handleChangeicd2(idx)}
                              />
                            </td>
                            <td style={{ width: "60px" }}>
                              <Typeahead
                              id="typeahed1"
                                style={{ width: "60px", fontSize: "12px" }}
                                maxLength={2}
                                name="icd3"
                                size="sm"
                                // clearButton={true}
                                defaultInputValue={this.state.rows[idx].icd3}
                                options={this.state.icddata}
                                labelKey={(option) =>
                                  (item.icd3 = `${option.code}`)
                                }
                                onClick={this.handleChangeicd3(idx)}
                              />
                            </td>
                            <td style={{ width: "60px" }}>
                              <Typeahead
                                id="typeahed1"
                                style={{ width: "60px", fontSize: "12px" }}
                                maxLength={2}
                                name="icd4"
                                size="sm"
                                // clearButton={true}
                                defaultInputValue={this.state.rows[idx].icd4}
                                options={this.state.icddata}
                                labelKey={(option) =>
                                  (item.icd4 = `${option.code}`)
                                }
                                onClick={this.handleChangeicd4(idx)}
                              />
                            </td>
                            <td style={{ width: "40px" }}>
                              <input
                                style={{ width: "40px", fontSize: "12px" }}
                                type="text"
                                name="units"
                                size="sm"
                                defaultValue={"1"}
                                value={this.state.rows[idx].units}
                                onChange={this.handleChangeunits(idx)}
                                className="form-control"
                              />
                            </td>
                            <td style={{ width: "40px" }}>
                              <button
                                className="btn btn-outline-danger btn-sm"
                                onClick={this.handleRemoveSpecificRow(idx)}
                              >
                                <AiOutlineDelete />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <br />
                    <Row>
                      <Col>
                        <button
                          onClick={this.handleAddRow}
                          className="btn btn-primary labelfontsize"
                        >
                          Add Row
                        </button>
                      </Col>

                      <Col>
                        <button
                          onClick={this.handleRemoveRow}
                          className="btn btn-danger float-right labelfontsize"
                        >
                          Delete Last Row
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : null}
            </div>
            <br />
            <Row
              style={{
                paddingTop: "4%",
                justifyContent: "center",
                marginLeft: "60%",
              }}
            >
              <Col md="10" style={{ display: "flex" }}>
                <Button
                  className="labelfontsize"
                  style={{ background: "#07326c" }}
                  onClick={() => {
                    this.setState(
                      {
                        status: "Incomplete",
                      },
                      () => {
                        this.addAuth();
                      }
                    );
                  }}
                >
                  Set as Incomplete
                </Button>
                &nbsp;
                <Button
                  className="labelfontsize"
                  style={{ background: "#07326c" }}
                  onClick={() => {
                    this.setState(
                      {
                        status: "Completed",
                      },
                      () => {
                        this.addAuth();
                      }
                    );
                  }}
                >
                  Save
                </Button>
                &nbsp;
                <Button
                  className="labelfontsize"
                  style={{ background: "#07326c" }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <br />
            <br />
          </div>
        </div>
        <Modal
          isOpen={this.state.redirectmodal}
          onHide={this.redirectpage}
          // onExit={this.reloadpage}
          centered
        >
          <ModalBody
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "auto",
            }}
          >
            <Row style={{ margin: "0px" }}>View Status...</Row>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid #268da5",
                color: "#268da5",
                backgroundColor: "#fff",
              }}
              onClick={this.redirectpage}
            >
              Cancel
            </Button>
            <Link to="/eligibility/PriorAuth">
              <Button style={{ backgroundColor: "#268da5" }}>Go</Button>
            </Link>
          </ModalFooter>
        </Modal>
      </div>
      </div>
    );
  }
}
