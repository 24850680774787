import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import { Modal } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MdFilterListAlt } from "react-icons/md";
import Select from "react-dropdown-select";
import { GrNotes } from "react-icons/gr";
import { HiRefresh } from "react-icons/hi";
import { ImCross } from "react-icons/im";
import axios from "axios";
import { ip } from "../../Apis/Api";
import moment from "moment-timezone";
class ActionManagePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      updatemodal: false,
      notestemplate: "",
      commentmodal: false,
      cptvalue: sessionStorage.getItem("ClaimID"),
      AllocatedTo: sessionStorage.getItem("AllocatedTo"),
      addNotes: "",
      totalamt: null,
      cptdata: [],
      loading: true,
      days15: false,
      days30: false,
      days45: false,
      customdate: false,
      allcpt: [],
      selectedcpt: [],
      source: "",
      InsAdj: "",
      mode: "",
      InsPay: "",
      CoPay: "",
      cheque: "",
      sec: "",
      prt: "",
      tb: "",
      provider: "",
      charge: "",
      units: "",
      icd: "",
      cptcode: "",
      cptdate: "",
      saveModal: false,
      change_subamt: "",
      change_prt: "",
      change_sec: "",
      change_CoPay: "",
      change_notes: "",
      change_InsAdj: "",
      change_InsPay: "",
      change_cheque: "",
      change_source: "",
      change_mode: "",
      change_date: "",
      change_cpt: "",
      change_icd: "",
      change_units: "",
      change_amt: "",
      change_provide: "",
      change_sec: "",
      change_bal: "",
      change_charge: "",
      change_amountPaid: "",
      cptmodal: false,
      change_follow: "",
      change_comments: "",
      commentsErr: "",
      addEventdateErr: "",
      postUserId: sessionStorage.getItem("user_id"),
      postClaimId: "",
      postCheckNo: "",
      postNextdate: "",
      postModule: "ManagePayment",
      postComments: "",
      postPayertype: "",
      postEventdate: "",
      postStatus: "",
      addAmount: "",
      addIcd: "",
      addProvider: "",
      addSecondaryResp: "",
      addTotoalBalancedAmount: "",
      addamountPaid: "",
      addDate: "",
      addsubmittedUnits: "",
      addPaymentMode: "",
      addInsuranceAdjustment: "",
      addInsurancePayment: "",
      addSource: "",
      addCoPayment: "",
      addcheck_no: "",
      addClaimID: "",
      addstatusCodeValue: "",
      addstatusCode: "",
      addstatusCategoryCode: "",
      addstatusCategoryCodeValue: "",
      addserviceIdQualifierCode: "",
      addserviceIdQualifier: "",
      addeffectiveDate: "",
      addSource: "",
      addSEC: "",
      addPRT: "",
      addCharge: "",
      addComments: "",

      addModule: "Add Cpt",
      addNextdate: "",
      addPayertype: "",
      addStatus: "Paid",
      addEventdate: "",
      addCheckNo: "",
      addmodal: false,
      postStatus: "Paid",
      addUserId: sessionStorage.getItem("user_id"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      notesErr: "",
      cpterr: "",
    };
  }

  //open/close comment modal
  togglecomment = () => {
    this.setState({
      commentmodal: !this.state.commentmodal,
      saveModal: !this.state.saveModal,
    });
  };

  //open/close add cpt modal
  toggleaddcpt = () => {
    this.setState({
      addmodal: !this.state.addmodal,
      cptmodal: !this.state.cptmodal,
    });
  };

  //validation on adding cpt
  addcptvalidate = () => {
    let hasErr = true;
    let cpterr = "";
    let notesErr = "";
    if (this.state.addNotes === "" || this.state.addNotes === null) {
      notesErr = "This field is required";
      this.setState({ notesErr });
      hasErr = false;
    }
    if (this.state.selectedcpt.length === 0) {
      cpterr = "This field is required";
      this.setState({ cpterr });
      hasErr = false;
    }
    return hasErr;
  };

  addcptfinalvalidate = () => {
    let hasErr = true;
    let addEventdateErr = "";
    if (this.state.addNextdate === "") {
      addEventdateErr = "This field is required";
      this.setState({ addEventdateErr });
      hasErr = false;
    }
    return hasErr;
  };

  handleModal() {
    this.setState({ saveModal: !this.state.saveModal });
  }

  //get cpt details
  getcpt = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/claim/viewclaim?claimid=${this.state.cptvalue}`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          totalamt: res.data.Claim[0].TotalBilledAmount,
          cptdata: res.data.Claim[0].cpt,
          updateClaimID: res.data.Claim[0].ClaimID,
          updateeffectiveDate: res.data.Claim[0].effectiveDate,
          change_cheque: res.data.Claim[0].checkNumber,
          // postStatus:res.data.Claim[0].ClaimStatus,
          updatetype: res.data.Claim[0].type,
          addEventdate: res.data.Claim[0].Date,
          addCheckNo: res.data.Claim[0].checkNumber,
          addClaimID: res.data.Claim[0].ClaimID,
          addPayertype: res.data.Claim[0].type,
          addEventdate: res.data.Claim[0].effectiveDate,
          // addStatus:res.data.Claim[0].ClaimStatus,
        });
      })

      .catch((err) => {});
  };

  //get all cpts for search
  getallcpts = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/cptmaster/all`, { headers: headers })
      .then((res) => {
        this.setState({
          allcpt: res.data.cpt,
          loading: false,
        });
      })
      .catch((err) => {});
  };

  //update cpt call
  editCpt = async () => {
    const isValid = this.validate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        Date: this.state.change_date,
        submittedAmount: this.state.change_subamt,
        amountPaid: this.state.change_charge,
        submittedUnits: this.state.change_units,
        ICD: this.state.change_icd,
        Provider: this.state.change_provide,
        TotalBalanceAmount: this.state.change_bal,
        Source: this.state.change_source,
        PaymentMode: this.state.change_mode,
        check_no: this.state.change_cheque,
        InsurancePayment: this.state.change_InsPay,
        InsuranceAdjustment: this.state.change_InsAdj,
        CoPayment: this.state.change_CoPay,
        Amount: this.state.change_amt,
        Secondary_Resp: this.state.change_sec,
        Charge: this.state.change_charge,
        PRT: this.state.change_prt,
        SEC: this.state.change_sec,
        UserId: this.state.postUserId,
        ClaimID: this.state.updateClaimID,
        Module: this.state.postModule,
        NextFollowupDate: this.state.postNextdate,
        OverallServiceLevelStatus: this.state.postStatus,
        Payertype: this.state.updatetype,
        eventdate: this.state.postEventdate,
        Notes: this.state.change_notes,
        cptNotes: this.state.change_notes,
        Claimunder: this.state.AllocatedTo,
      };

      const res = await axios
        .put(
          `${ip}/claim/update?claimid=${this.state.cptvalue}&cptcode=${this.state.change_cpt}`,
          value,
          { headers: headers }
        )

        // .put(`${ip}/claim/update/${this.state.cptvalue}/${this.state.change_cpt}`,value,{ headers: headers })
        .then((res) => {
          alert("CPT updated successfully");
          this.setState({
            saveModal: !this.state.saveModal,
            postNextdate: "",
            days15: false,
            days30: false,
            days45: false,
            customdate: false,
            postNextdateErr: "",
          });
          this.getcpt();
        })
        .catch((err) => {});
    }
  };

  validate = () => {
    let hasErr = true;
    let postNextdateErr = "";
    let notesErr = "";
    if (this.state.change_notes === "" || this.state.change_notes === null) {
      notesErr = "This field is required";
      this.setState({ notesErr });
      hasErr = false;
    }
    if (this.state.postNextdate === "") {
      postNextdateErr = "This field is required";
      this.setState({ postNextdateErr });
      hasErr = false;
    }
    return hasErr;
  };
  addValidate = () => {
    let hasErr = true;
    let addNextdateErr = "";
    let cpterr = "";
    let notesErr = "";
    if (this.state.addNextdate === "") {
      addNextdateErr = "This field is required";
      this.setState({ addNextdateErr });
      hasErr = false;
    }
    if (this.state.addNotes === "") {
      notesErr = "This field is required";
      this.setState({ notesErr });
      hasErr = false;
    }
    if (this.state.selectedcpt.length === 0) {
      cpterr = "This field is required";
      this.setState({ cpterr });
      hasErr = false;
    }
    return hasErr;
  };
  //add cpt call
  addCpt = async () => {
    const valid = this.addValidate();
    if (valid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const addCptdata = {
        Amount: this.state.addAmount,
        CPTCode: this.state.selectedcpt[0].cpt_code,
        ICD: this.state.addIcd,
        Provider: this.state.addProvider,
        Secondary_Resp: this.state.addSecondaryResp,
        TotalBalanceAmount: this.state.addTotoalBalancedAmount,
        amountPaid: this.state.addamountPaid,
        Date: this.state.addDate,
        submittedUnits: this.state.addsubmittedUnits,
        PaymentMode: this.state.addPaymentMode,
        InsuranceAdjustment: this.state.addInsuranceAdjustment,
        Source: this.state.addSource,
        InsurancePayment: this.state.addInsurancePayment,
        CoPayment: this.state.addCoPayment,
        check_no: this.state.addcheck_no,
        ClaimID: this.state.cptvalue,
        statusCodeValue: this.state.addstatusCodeValue,
        statusCode: this.state.addstatusCode,
        statusCategoryCode: this.state.addstatusCategoryCode,
        statusCategoryCodeValue: this.state.addstatusCategoryCodeValue,
        serviceIdQualifierCode: this.state.addserviceIdQualifierCode,
        serviceIdQualifier: this.state.addserviceIdQualifier,
        effectiveDate: this.state.addeffectiveDate,
        Source: this.state.addSource,
        SEC: this.state.addSEC,
        PRT: this.state.addPRT,
        Charge: this.state.addCharge,
        Claimunder: this.state.AllocatedTo,
        Notes: this.state.addNotes,
        cptNotes: this.state.addNotes,
        UserId: this.state.addUserId,
        Module: this.state.addModule,
        NextFollowupDate: this.state.addNextdate,
        Payertype: this.state.addPayertype,
        OverallServiceLevelStatus: this.state.addStatus,
        Eventdate: this.state.addEventdate,
        CheckNo: this.state.addCheckNo,
      };
      const res = await axios
        .post(`${ip}/claim/addcpt?claimid=${this.state.cptvalue}`, addCptdata, {
          headers: headers,
        })
        .then((res) => {
          this.getcpt();
          alert("CPT added successfully");
          this.cpttoggle();
          this.setState({
            addNextdate: "",
            addNextdateErr: "",
            days15: false,
            days30: false,
            days45: false,
            customdate: false,
          });
        })
        .catch((err) => {});
    }
  };

  //renew access token
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  cpttoggle = () => {
    const { cptmodal } = this.state;
    this.setState({ cptmodal: !cptmodal });
  };

  //initial render
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getcpt();
    this.getallcpts();
  }

  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  updatetoggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  render() {
    //table cell styles
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
        padding: "none",
        fontSize: 14,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));
    return (
      <div className="align-row">
        <div className="align-column">
          <div className="tophead">
          </div>
          <br></br>
          <Row>
            <Col>
              <div>
                {" "}
                <h5
                  style={{
                    textAlign: "left",
                    marginLeft: "3%",
                    fontWeight: "600",
                  }}
                >
                  Charges
                </h5>
              </div>
            </Col>
            <Col>
              <Button
                style={{ marginLeft: "70%", backgroundColor: "#041c3c" }}
                onClick={() => {
                  this.cpttoggle();
                }}
              >
                Add CPT
              </Button>
            </Col>
          </Row>
          <div style={{ height: "100%", width: "100%", marginTop: "2%" }}>
            {/* CPT details first table */}
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>CPT</StyledTableCell>
                    <StyledTableCell>ICD</StyledTableCell>
                    <StyledTableCell>Units</StyledTableCell>
                    <StyledTableCell>Paid Amount</StyledTableCell>
                    <StyledTableCell>Billed Amount</StyledTableCell>
                    <StyledTableCell>Provider</StyledTableCell>
                    <StyledTableCell>Total Bal.</StyledTableCell>
                    {/* <StyledTableCell>SEC</StyledTableCell> */}
                    {/* <StyledTableCell>PAY</StyledTableCell> */}
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.cptdata &&
                    this.state.cptdata.map((data, index) => {
                      return (
                        <StyledTableRow>
                          <StyledTableCell>{data.Date}</StyledTableCell>
                          <StyledTableCell>{data.CPTCode}</StyledTableCell>
                          <StyledTableCell>{data.ICD}</StyledTableCell>
                          <StyledTableCell>
                            {data.submittedUnits}{" "}
                          </StyledTableCell>
                          <StyledTableCell>{data.amountPaid} </StyledTableCell>
                          <StyledTableCell>
                            {data.submittedAmount}
                          </StyledTableCell>
                          <StyledTableCell>{data.Provider}</StyledTableCell>
                          <StyledTableCell>
                            {data.submittedAmount - data.amountPaid}
                          </StyledTableCell>
                          <StyledTableCell>
                            {data.Secondary_Resp}
                          </StyledTableCell>
                          {/* <StyledTableCell>$&nbsp;&nbsp;200.00</StyledTableCell> */}

                          <StyledTableCell>
                            <Button
                              onClick={() => {
                                this.setState({
                                  saveModal: !this.state.saveModal,
                                  change_cpt: data.CPTCode,
                                  change_date: data.Date,
                                  change_icd: data.ICD,
                                  change_amt: data.Amount,
                                  change_bal: data.TotalBalanceAmount,
                                  change_sec: data.Secondary_Resp,
                                  change_provide: data.Provider,
                                  change_units: data.submittedUnits,
                                  change_charge: data.amountPaid,
                                  change_source: data.Source,
                                  change_mode: data.PaymentMode,
                                  change_cheque: data.check_no,
                                  change_CoPay: data.CoPayment,
                                  change_InsAdj: data.InsuranceAdjustment,
                                  change_InsPay: data.InsurancePayment,
                                  change_subamt: data.submittedAmount,
                                  change_prt: data.PRT,
                                  change_sec: data.SEC,
                                  change_notes: data.cptNotes,
                                });
                              }}
                            >
                              Edit
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <HiRefresh size={20} />
                          </StyledTableCell>
                          {/* <StyledTableCell><MdFilterListAlt size={20} /></StyledTableCell> */}
                        </StyledTableRow>

                        // <StyledTableRow>
                        //   <StyledTableCell>Total</StyledTableCell>
                        //   <StyledTableCell></StyledTableCell>
                        //   <StyledTableCell></StyledTableCell>
                        //   <StyledTableCell></StyledTableCell>
                        //   <StyledTableCell></StyledTableCell>
                        //   <StyledTableCell>$&nbsp;&nbsp;400.00</StyledTableCell>
                        //   <StyledTableCell></StyledTableCell>
                        //   <StyledTableCell>$&nbsp;&nbsp;200.00</StyledTableCell>
                        //   <StyledTableCell>$&nbsp;&nbsp;200.00</StyledTableCell>
                        //   <StyledTableCell>$&nbsp;&nbsp;200.00</StyledTableCell>
                        //   {/* <StyledTableCell>$&nbsp;&nbsp;200.00</StyledTableCell> */}
                        //   <StyledTableCell></StyledTableCell>
                        //   <StyledTableCell></StyledTableCell>
                        // </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <div
              style={{ display: "flex", flexDirection: "row", margin: "20px" }}
            >
              <Button
                class="button"
                style={{
                  width: "60%",
                  color: "white",
                  backgroundColor: "tomato",
                  margin: "10px",
                }}
              >
                AR Followup Log
              </Button>{" "}
              <Button
                class="button"
                style={{
                  width: "60%",
                  color: "white",
                  backgroundColor: "tomato",
                  margin: "10px",
                }}
              >
                Manage offset Payment
              </Button>{" "}
              <Button
                style={{
                  width: "60%",
                  color: "white",
                  backgroundColor: "tomato",
                  margin: "10px",
                }}
              >
                A/R Followup Document{" "}
              </Button>
              <Button
                style={{
                  width: "60%",
                  color: "white",
                  backgroundColor: "tomato",
                  margin: "10px",
                }}
              >
                Over Payment
              </Button>{" "}
              <Button
                style={{
                  width: "60%",
                  color: "white",
                  backgroundColor: "tomato",
                  margin: "10px",
                }}
              >
                FA/R Followup Update
              </Button>
            </div> */}
            <br></br>
            <br></br>

            {/* CPT details second table */}
            <div>
              {" "}
              <h5
                style={{
                  textAlign: "left",
                  marginLeft: "1%",
                  fontWeight: "600",
                }}
              >
                Transcation
              </h5>
              <TableContainer style={{ width: "200vh" }}>
                <Table sx={{ minWidth: 800 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell>CPT</StyledTableCell>
                      <StyledTableCell>Source</StyledTableCell>
                      <StyledTableCell>Mode</StyledTableCell>
                      <StyledTableCell>Chk/Tran.no</StyledTableCell>
                      <StyledTableCell>InsurancePayment</StyledTableCell>
                      <StyledTableCell>InsuranceAdjustment</StyledTableCell>
                      <StyledTableCell>CoPayment</StyledTableCell>
                      <StyledTableCell>Amount</StyledTableCell>

                      {/* <StyledTableCell>Secondary Resp.</StyledTableCell>
               <StyledTableCell>OP</StyledTableCell>
               <StyledTableCell>Notes</StyledTableCell>
               <StyledTableCell>View</StyledTableCell> */}
                      <StyledTableCell>Denial</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.cptdata &&
                      this.state.cptdata.map((data, index) => {
                        return (
                          <StyledTableRow>
                            <StyledTableCell>{data.Date}</StyledTableCell>

                            <StyledTableCell>{data.CPTCode}</StyledTableCell>
                            <StyledTableCell>{data.Source}</StyledTableCell>
                            <StyledTableCell>
                              {data.PaymentMode}
                            </StyledTableCell>
                            <StyledTableCell>{data.check_no}</StyledTableCell>
                            <StyledTableCell>
                              {data.amountPaid}{" "}
                            </StyledTableCell>
                            <StyledTableCell>
                              {data.InsuranceAdjustment}{" "}
                            </StyledTableCell>
                            <StyledTableCell>{data.CoPayment} </StyledTableCell>
                            {/* <StyledTableCell>$&nbsp;&nbsp;<Input type="number" style={{marginTop:"-18%",marginLeft:"7%",width:"80%" }}/> </StyledTableCell>
              
               <StyledTableCell>$&nbsp;&nbsp;0.00 <HiRefresh  size={20}/></StyledTableCell>
               <StyledTableCell></StyledTableCell>
               <StyledTableCell><GrNotes size={20}/></StyledTableCell> */}
                            <StyledTableCell>
                              {data.submittedAmount}
                            </StyledTableCell>
                            <StyledTableCell>
                              <ImCross size={20} style={{ color: "red" }} />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            {/* add cpt modal */}
            <Modal
              // style={{ width: "100%", height: "70%" }}
              show={this.state.cptmodal}
              onHide={this.cpttoggle}
              // onExit={this.reset}
              // centered
              animation="false"
              size="lg"
            >
              <Modal.Header closeLabel="" closeButton>
                <Modal.Title>Create CPT</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Date</Label>
                        <Input
                          type="date"
                          value={this.state.addDate}
                          onChange={(e) =>
                            this.setState({ addDate: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>CPT</Label>
                        <Select
                          placeholder="Select CPT"
                          options={this.state.allcpt}
                          values={this.state.selectedcpt}
                          searchable={true}
                          loading={this.state.loading}
                          dropdownHandle={true}
                          searchBy="cpt_code"
                          labelField="cpt_code"
                          noDataLabel="No matches found"
                          required
                          onChange={(values) =>
                            this.setState({
                              selectedcpt: values,
                              cpterr: "",
                            })
                          }
                        />
                      </FormGroup>
                      <div style={{ color: "red" }}>{this.state.cpterr}</div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>ICD</Label>
                        <Input
                          type="text"
                          value={this.state.addIcd}
                          onChange={(e) =>
                            this.setState({ addIcd: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Units</Label>
                        <Input
                          type="text"
                          value={this.state.addsubmittedUnits}
                          onChange={(e) =>
                            this.setState({ addsubmittedUnits: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Charge</Label>
                        <Input
                          type="text"
                          value={this.state.addCharge}
                          onChange={(e) =>
                            this.setState({ addCharge: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Amount</Label>
                        <Input
                          type="text"
                          value={this.state.addAmount}
                          onChange={(e) =>
                            this.setState({ addAmount: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Provider</Label>
                        <Input
                          type="text"
                          value={this.state.addProvider}
                          onChange={(e) =>
                            this.setState({ addProvider: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>
                          Total Balance
                        </Label>
                        <Input
                          type="text"
                          value={this.state.addTotoalBalancedAmount}
                          onChange={(e) =>
                            this.setState({
                              addTotoalBalancedAmount: e.target.value,
                            })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>PRT</Label>
                        <Input
                          type="text"
                          value={this.state.addPRT}
                          onChange={(e) =>
                            this.setState({ addPRT: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>SEC</Label>
                        <Input
                          type="text"
                          value={this.state.addSEC}
                          onChange={(e) =>
                            this.setState({
                              addSEC: e.target.value,
                              firstnameErr: "",
                            })
                          }
                        ></Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.sec}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    {/* <Col>
                        <FormGroup>
                       <Label style={{fontWeight:"600"}}>PRT</Label>
                          <Input type="text"
                           value={this.state.prt}
                           onChange={(e) =>
                             this.setState({ prt: e.target.value })
                           }
                          ></Input>
                        </FormGroup>
                      </Col> */}
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Source</Label>
                        <Input
                          type="text"
                          value={this.state.addSource}
                          onChange={(e) =>
                            this.setState({ addSource: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Mode</Label>
                        <Input
                          type="select"
                          value={this.state.addPaymentMode}
                          onChange={(e) =>
                            this.setState({ addPaymentMode: e.target.value })
                          }
                        >
                          <option value="Cash">Cash</option>
                          <option value="Card">Credit/Debit Card</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>
                          Insurance Adjustment
                        </Label>
                        <Input
                          type="text"
                          value={this.state.addInsuranceAdjustment}
                          onChange={(e) =>
                            this.setState({
                              addInsuranceAdjustment: e.target.value,
                            })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>
                          Insurance Payment
                        </Label>
                        <Input
                          type="text"
                          value={this.state.addInsurancePayment}
                          onChange={(e) =>
                            this.setState({
                              addInsurancePayment: e.target.value,
                            })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Co Payment</Label>
                        <Input
                          type="text"
                          value={this.state.addCoPayment}
                          onChange={(e) =>
                            this.setState({ addCoPayment: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Cheque No</Label>
                        <Input
                          type="text"
                          value={this.state.addcheck_no}
                          onChange={(e) =>
                            this.setState({ addcheck_no: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Notes</Label>
                        <br />
                        <Input
                          type="textarea"
                          style={{ height: "200px" }}
                          value={this.state.addNotes}
                          onChange={(e) =>
                            this.setState({
                              addNotes: e.target.value,
                              notesErr: "",
                            })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <div style={{ color: "red" }}>{this.state.notesErr}</div>
                  </Row>
                  <br />
                  <Row>
                    <Label style={{ fontWeight: "600" }}>Follow Update </Label>
                    <br />
                    <Col>
                      <FormGroup>
                        <Input
                          type="checkbox"
                          checked={this.state.days15}
                          onChange={() => {
                            var date = new Date();
                            var dateformat = moment
                              .tz(date, "Asia/Kolkata")
                              .add(15, "days")
                              .format();
                            this.setState({
                              days15: !this.state.days15,
                              days30: false,
                              days45: false,
                              customdate: false,
                              addNextdate: dateformat,
                              addNextdateErr: "",
                            });
                          }}
                        />
                        &nbsp;15days
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          type="checkbox"
                          checked={this.state.days30}
                          onChange={() => {
                            var date = new Date();
                            var dateformat = moment
                              .tz(date, "Asia/Kolkata")
                              .add(30, "days")
                              .format();
                            this.setState({
                              days30: !this.state.days30,
                              addNextdate: dateformat,
                              days15: false,
                              days45: false,
                              customdate: false,
                              addNextdateErr: "",
                            });
                          }}
                        />
                        &nbsp;30days
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          type="checkbox"
                          checked={this.state.days45}
                          onChange={() => {
                            var date = new Date();
                            var dateformat = moment
                              .tz(date, "Asia/Kolkata")
                              .add(45, "days")
                              .format();
                            this.setState({
                              days45: !this.state.days45,
                              addNextdate: dateformat,
                              days15: false,
                              days30: false,
                              customdate: false,
                              addNextdateErr: "",
                            });
                          }}
                        />
                        &nbsp;45days
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          type="checkbox"
                          checked={this.state.customdate}
                          onChange={() =>
                            this.setState({
                              customdate: !this.state.customdate,
                              days30: false,
                              days45: false,
                              days15: false,
                              addNextdate: "",
                            })
                          }
                        />
                        &nbsp;Custom
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  {this.state.customdate ? (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input
                            type="date"
                            required
                            value={this.state.addNextdate}
                            onChange={(e) =>
                              this.setState({
                                addNextdate: e.target.value,
                                addNextdateErr: "",
                              })
                            }
                          ></Input>
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.addNextdateErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input
                            type="text"
                            value={this.state.addNextdate.substring(0, 10)}
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.addNextdateErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <br />
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.cpttoggle();
                    // this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.addCpt}
                >
                  Add CPT
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={this.state.saveModal}
              onHide={() => this.handleModal()}
              // onExit={this.reset}
              // centered
            >
              <Modal.Header closeLabel="" closeButton>
                <Modal.Title>Edit CPT Details</Modal.Title>
              </Modal.Header>

              <Modal.Body
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Date</Label>
                        <Input
                          type="date"
                          // defaultValue={this.state.change_date}
                          value={this.state.change_date}
                          onChange={(e) =>
                            this.setState({ change_date: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>CPT</Label>
                        <Input value={this.state.change_cpt}></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>ICD</Label>
                        <Input
                          type="text"
                          value={this.state.change_icd}
                          onChange={(e) =>
                            this.setState({ change_icd: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Units</Label>
                        <Input
                          type="text"
                          value={this.state.change_units}
                          onChange={(e) =>
                            this.setState({ change_units: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Charge</Label>
                        <Input
                          type="text"
                          value={this.state.change_charge}
                          onChange={(e) =>
                            this.setState({ change_charge: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Amount</Label>
                        <Input
                          type="text"
                          value={this.state.change_amt}
                          onChange={(e) =>
                            this.setState({ change_amt: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Provider</Label>
                        <Input
                          type="text"
                          value={this.state.change_provide}
                          onChange={(e) =>
                            this.setState({ change_provide: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>
                          Total Balance
                        </Label>
                        <Input
                          type="text"
                          value={this.state.change_bal}
                          onChange={(e) =>
                            this.setState({ change_bal: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>SEC</Label>
                        <Input
                          type="text"
                          value={this.state.change_sec}
                          onChange={(e) =>
                            this.setState({ change_sec: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>PRT</Label>
                        <Input
                          type="text"
                          value={this.state.prt}
                          onChange={(e) =>
                            this.setState({ prt: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Source</Label>
                        <Input
                          type="text"
                          value={this.state.change_source}
                          onChange={(e) =>
                            this.setState({ change_source: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Mode</Label>
                        <Input
                          type="select"
                          value={this.state.change_mode}
                          onChange={(e) =>
                            this.setState({ change_mode: e.target.value })
                          }
                        >
                          <option value="Cash">Cash</option>
                          <option value="Card">Credit/Debit Card</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>
                          Insurance Adjustment
                        </Label>
                        <Input
                          type="text"
                          value={this.state.change_InsAdj}
                          onChange={(e) =>
                            this.setState({ change_InsAdj: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>
                          Insurance Payment
                        </Label>
                        <Input
                          type="text"
                          value={this.state.change_InsPay}
                          onChange={(e) =>
                            this.setState({ change_InsPay: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Co Payment</Label>
                        <Input
                          type="text"
                          value={this.state.change_CoPay}
                          onChange={(e) =>
                            this.setState({ change_CoPay: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Cheque No</Label>
                        <Input
                          type="text"
                          value={this.state.change_cheque}
                          onChange={(e) =>
                            this.setState({ change_cheque: e.target.value })
                          }
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Comments</Label>

                        <br />
                        <Input
                          type="textarea"
                          style={{ resize: "none" }}
                          onChange={(e) =>
                            this.setState({ change_comments: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label style={{ fontWeight: "600" }}>Notes</Label>
                        <Input
                          type="textarea"
                          style={{ height: "200px" }}
                          value={this.state.change_notes}
                          onChange={(e) =>
                            this.setState({
                              change_notes: e.target.value,
                              notesErr: "",
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <div style={{ color: "red" }}>{this.state.notesErr}</div>
                  </Row>
                  <br />
                  <Row>
                    <Label style={{ fontWeight: "600" }}>Follow Update </Label>
                    <br />
                    <Col>
                      <FormGroup>
                        <Input
                          type="checkbox"
                          checked={this.state.days15}
                          onChange={() => {
                            var date = new Date();
                            var dateformat = moment
                              .tz(date, "Asia/Kolkata")
                              .add(15, "days")
                              .format();
                            this.setState({
                              days15: !this.state.days15,
                              days30: false,
                              days45: false,
                              customdate: false,
                              postNextdate: dateformat,
                              postNextdateErr: "",
                            });
                          }}
                        />
                        &nbsp;15days
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          type="checkbox"
                          checked={this.state.days30}
                          onChange={() => {
                            var date = new Date();
                            var dateformat = moment
                              .tz(date, "Asia/Kolkata")
                              .add(30, "days")
                              .format();
                            this.setState({
                              days30: !this.state.days30,
                              postNextdate: dateformat,
                              days15: false,
                              days45: false,
                              customdate: false,
                              postNextdateErr: "",
                            });
                          }}
                        />
                        &nbsp;30days
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          type="checkbox"
                          checked={this.state.days45}
                          onChange={() => {
                            var date = new Date();
                            var dateformat = moment
                              .tz(date, "Asia/Kolkata")
                              .add(45, "days")
                              .format();
                            this.setState({
                              days45: !this.state.days45,
                              postNextdate: dateformat,
                              days15: false,
                              days30: false,
                              customdate: false,
                              postNextdateErr: "",
                            });
                          }}
                        />
                        &nbsp;45days
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          type="checkbox"
                          checked={this.state.customdate}
                          onChange={() =>
                            this.setState({
                              customdate: !this.state.customdate,
                              days30: false,
                              days45: false,
                              days15: false,
                              postNextdate: "",
                            })
                          }
                        />
                        &nbsp;Custom
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  {this.state.customdate ? (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input
                            type="date"
                            required
                            value={this.state.postNextdate}
                            onChange={(e) =>
                              this.setState({
                                postNextdate: e.target.value,
                                postNextdateErr: "",
                              })
                            }
                          ></Input>
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.postNextdateErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input
                            type="text"
                            value={this.state.postNextdate.substring(0, 10)}
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.postNextdateErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <br />
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() =>
                    this.setState({ saveModal: !this.state.saveModal })
                  }
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.editCpt}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
export default ActionManagePayment;
