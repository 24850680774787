import { Component } from "react";
import { Table, Row, Col } from "reactstrap";
import axios from "axios";
import { PieChart, Pie, Sector, Cell } from "recharts";
import { Container } from "reactstrap";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ip } from "../../Apis/Api";
const data1 = [
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },

  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },

  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },

  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },

  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },

  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },

  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },

  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
  {
    day: "",
    denialclaims: 0,
    freshclaims: 0,
  },
];
let tooltip11, tooltip12;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload.day}

        <p style={{ color: "#e60000" }}>
          Duplicate Claims:&nbsp;{payload[0].payload.denialclaims}
          <p style={{ color: "#82ca9d" }}>
            Fresh Claims:&nbsp;{payload[0].payload.freshclaims}
          </p>
        </p>
      </div>
    );
  }
  return null;
};
let d = new Date();
let month = d.getMonth();

class ARbyage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      select: month + 1,
      daywise: [],
      Daywise1: [],
      Daywise2: [],
      Daywise3: [],
      Daywise4: [],
      Daywise5: [],
      Daywise6: [],
      Daywise7: [],
      Daywise8: [],
      Daywise9: [],
      Daywise10: [],
      Daywise11: [],
      Daywise12: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
    };
  }

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getinventory = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.dayStart !== "" && this.props.dayEnd !== "") {
          url = `${ip}/inventory/daywise?start_date=${this.props.dayStart}&end_date=${this.props.dayEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/inventory/daywise?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.dayStart !== "" && this.props.dayEnd !== "") {
          url = `${ip}/inventory/daywise?start_date=${this.props.dayStart}&end_date=${this.props.dayEnd}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/inventory/daywise?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.dayStart !== "" && this.props.dayEnd !== "") {
        url = `${ip}/inventory/daywise?start_date=${this.props.dayStart}&end_date=${this.props.dayEnd}`;
      } else {
        url = `${ip}/inventory/daywise`;
      }
    }

    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        res.data.daywise.map((item1, index) => {
          switch (item1._id) {
            case 1:
              const sorted1 = item1.monthcount.sort((a, b) =>
                a.day > b.day ? 1 : b.day > a.day ? -1 : 0
              );
              return this.setState({ Daywise1: sorted1 });

            case 2:
              const sorted2 = item1.monthcount.sort((a, b) =>
                a.day > b.day ? 1 : b.day > a.day ? -1 : 0
              );
              return this.setState({ Daywise2: sorted2 });

            case 3:
              const sorted3 = item1.monthcount.sort((a, b) =>
                a.day > b.day ? 1 : b.day > a.day ? -1 : 0
              );
              return this.setState({ Daywise3: sorted3 });

            case 4:
              const sorted4 = item1.monthcount.sort((a, b) =>
                a.day > b.day ? 1 : b.day > a.day ? -1 : 0
              );
              return this.setState({ Daywise4: sorted4 });

            case 5:
              const sorted5 = item1.monthcount.sort((a, b) =>
                a.day > b.day ? 1 : b.day > a.day ? -1 : 0
              );
              return this.setState({ Daywise5: sorted5 });

            case 6:
              const sorted = item1.monthcount.sort((a, b) =>
                a.day > b.day ? 1 : b.day > a.day ? -1 : 0
              );
              return this.setState({ Daywise6: sorted });
            case 7:
              const sorted6 = item1.monthcount.sort((a, b) =>
                a.day > b.day ? 1 : b.day > a.day ? -1 : 0
              );
              return this.setState({ Daywise7: sorted6 });

            case 8:
              const sorted7 = item1.monthcount.sort((a, b) =>
                a.day > b.day ? 1 : b.day > a.day ? -1 : 0
              );
              return this.setState({ Daywise8: sorted7 });

            case 9:
              const sorted8 = item1.monthcount.sort((a, b) =>
                a.day > b.day ? 1 : b.day > a.day ? -1 : 0
              );
              return this.setState({ Daywise9: sorted8 });

            case 10:
              const sorted9 = item1.monthcount.sort((a, b) =>
                a.day > b.day ? 1 : b.day > a.day ? -1 : 0
              );
              return this.setState({ Daywise10: sorted9 });

            case 11:
              const sorted10 = item1.monthcount.sort((a, b) =>
                a.day > b.day ? 1 : b.day > a.day ? -1 : 0
              );
              return this.setState({ Daywise11: sorted10 });

            case 12:
              const sorted11 = item1.monthcount.sort((a, b) =>
                a.day > b.day ? 1 : b.day > a.day ? -1 : 0
              );
              return this.setState({ Daywise12: sorted11 });
          }
        });
      })
      .catch((err) => {});
  };

  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getinventory();
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.dayStart === prevProps.dayStart &&
        this.props.dayEnd === prevProps.dayEnd
      )
    ) {
      this.setState({
        daywise: [],
        Daywise1: [],
        Daywise2: [],
        Daywise3: [],
        Daywise4: [],
        Daywise5: [],
        Daywise6: [],
        Daywise7: [],
        Daywise8: [],
        Daywise9: [],
        Daywise10: [],
        Daywise11: [],
        Daywise12: [],
      });

      this.getinventory();
    } else if (this.props.client !== prevProps.client) {
      this.setState({
        daywise: [],
        Daywise1: [],
        Daywise2: [],
        Daywise3: [],
        Daywise4: [],
        Daywise5: [],
        Daywise6: [],
        Daywise7: [],
        Daywise8: [],
        Daywise9: [],
        Daywise10: [],
        Daywise11: [],
        Daywise12: [],
      });
      this.getinventory();
    } else if (this.props.Location !== prevProps.Location) {
      this.setState({
        daywise: [],
        Daywise1: [],
        Daywise2: [],
        Daywise3: [],
        Daywise4: [],
        Daywise5: [],
        Daywise6: [],
        Daywise7: [],
        Daywise8: [],
        Daywise9: [],
        Daywise10: [],
        Daywise11: [],
        Daywise12: [],
      });
      this.getinventory();
    }
  }

  render() {
    return (
      <div>
        <Row style={{ marginLeft: "-10%" }}>
          <div>
            <div className="select-cpt">
              <select
                value={this.state.select}
                onChange={(e) => this.setState({ select: e.target.value })}
                style={{
                  width: "28",
                  height: "30%",
                  borderRadius: "4px",
                  marginTop: "2%",
                  marginRight: "5px",
                  fontWeight: "500",
                }}
              >
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">Mar</option>
                <option value="4">Apr</option>
                <option value="5">May</option>
                <option value="6">Jun</option>
                <option value="7">Jul</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </select>
            </div>

            <hr style={{ width: "109%", marginLeft: "38px" }} />
          </div>
        </Row>

        <div>
          {this.state.select == 1 && (
            <div
              onMouseOver={() => (
                (tooltip11 = "denialclaims"), (tooltip12 = "freshclaims")
              )}
            >
              {this.state.Daywise1.length === 0 &&
                this.setState({ Daywise1: data1 })}
              <ResponsiveContainer width={"99%"} height={300}>
                <AreaChart
                  width={730}
                  height={250}
                  data={this.state.Daywise1}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs></defs>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Legend />
                  <Tooltip content={<CustomTooltip />} />

                  <Area
                    type="monotone"
                    dataKey="denialclaims"
                    name="Duplicate Claims"
                    stroke="#e60000"
                    fillOpacity={1}
                    fill="red"
                  />
                  <Area
                    type="monotone"
                    dataKey="freshclaims"
                    name="Fresh Claims"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="green"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}

          {this.state.select == 2 && (
            <div
              onMouseOver={() => (
                (tooltip11 = "denialclaims"), (tooltip12 = "freshclaims")
              )}
            >
              {this.state.Daywise2.length === 0 &&
                this.setState({ Daywise2: data1 })}
              <ResponsiveContainer width={"99%"} height={300}>
                <AreaChart
                  width={730}
                  height={250}
                  data={this.state.Daywise2}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs></defs>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Legend />
                  <Tooltip content={<CustomTooltip />} />
                  <Area
                    type="monotone"
                    dataKey="denialclaims"
                    name="Duplicate Claims"
                    stroke="#e60000"
                    fillOpacity={1}
                    fill="red"
                  />
                  <Area
                    type="monotone"
                    dataKey="freshclaims"
                    name="Fresh Claims"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="green"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}

          {this.state.select == 3 && (
            <div
              onMouseOver={() => (
                (tooltip11 = "denialclaims"), (tooltip12 = "freshclaims")
              )}
            >
              {this.state.Daywise3.length === 0 &&
                this.setState({ Daywise3: data1 })}
              <ResponsiveContainer width={"99%"} height={300}>
                <AreaChart
                  width={730}
                  height={250}
                  data={this.state.Daywise3}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs></defs>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Legend />
                  <Tooltip content={<CustomTooltip />} />

                  <Area
                    type="monotone"
                    dataKey="denialclaims"
                    name="Duplicate Claims"
                    stroke="#e60000"
                    fillOpacity={1}
                    fill="red"
                  />
                  <Area
                    type="monotone"
                    dataKey="freshclaims"
                    name="Fresh Claims"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="green"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}

          {this.state.select == 4 && (
            <div
              onMouseOver={() => (
                (tooltip11 = "denialclaims"), (tooltip12 = "freshclaims")
              )}
            >
              {this.state.Daywise4.length === 0 &&
                this.setState({ Daywise4: data1 })}
              <ResponsiveContainer width={"99%"} height={300}>
                <AreaChart
                  width={730}
                  height={250}
                  data={this.state.Daywise4}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs></defs>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Legend />
                  <Tooltip content={<CustomTooltip />} />

                  <Area
                    type="monotone"
                    dataKey="denialclaims"
                    name="Duplicate Claims"
                    stroke="#e60000"
                    fillOpacity={1}
                    fill="red"
                  />
                  <Area
                    type="monotone"
                    dataKey="freshclaims"
                    name="Fresh Claims"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="green"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}
          {this.state.select == 5 && (
            <div
              onMouseOver={() => (
                (tooltip11 = "denialclaims"), (tooltip12 = "freshclaims")
              )}
            >
              {this.state.Daywise5.length === 0 &&
                this.setState({ Daywise5: data1 })}
              <ResponsiveContainer width={"99%"} height={300}>
                <AreaChart
                  width={730}
                  height={250}
                  data={this.state.Daywise5}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs></defs>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Legend />
                  <Tooltip content={<CustomTooltip />} />

                  <Area
                    type="monotone"
                    dataKey="denialclaims"
                    name="Duplicate Claims"
                    stroke="#e60000"
                    fillOpacity={1}
                    fill="red"
                  />
                  <Area
                    type="monotone"
                    dataKey="freshclaims"
                    name="Fresh Claims"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="green"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}

          {this.state.select == 6 && (
            <div
              onMouseOver={() => (
                (tooltip11 = "denialclaims"), (tooltip12 = "freshclaims")
              )}
            >
              {this.state.Daywise6.length === 0 &&
                this.setState({ Daywise6: data1 })}
              <ResponsiveContainer width={"99%"} height={300}>
                <AreaChart
                  width={730}
                  height={250}
                  data={this.state.Daywise6}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs></defs>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Legend />
                  <Tooltip content={<CustomTooltip />} />

                  <Area
                    type="monotone"
                    dataKey="denialclaims"
                    name="Duplicate Claims"
                    stroke="#e60000"
                    fillOpacity={1}
                    fill="red"
                  />
                  <Area
                    type="monotone"
                    dataKey="freshclaims"
                    name="Fresh Claims"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="green"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}

          {this.state.select == 7 && (
            <div
              onMouseOver={() => (
                (tooltip11 = "denialclaims"), (tooltip12 = "freshclaims")
              )}
            >
              {this.state.Daywise7.length === 0 &&
                this.setState({ Daywise7: data1 })}
              <ResponsiveContainer width={"99%"} height={300}>
                <AreaChart
                  width={730}
                  height={250}
                  data={this.state.Daywise7}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs></defs>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Legend />
                  <Tooltip content={<CustomTooltip />} />

                  <Area
                    type="monotone"
                    dataKey="denialclaims"
                    name="Duplicate Claims"
                    stroke="#e60000"
                    fillOpacity={1}
                    fill="red"
                  />
                  <Area
                    type="monotone"
                    dataKey="freshclaims"
                    name="Fresh Claims"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="green"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}

          {this.state.select == 8 && (
            <div
              onMouseOver={() => (
                (tooltip11 = "denialclaims"), (tooltip12 = "freshclaims")
              )}
            >
              {this.state.Daywise8.length === 0 &&
                this.setState({ Daywise8: data1 })}
              <ResponsiveContainer width={"99%"} height={300}>
                <AreaChart
                  width={730}
                  height={250}
                  data={this.state.Daywise8}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs></defs>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Legend />
                  <Tooltip content={<CustomTooltip />} />

                  <Area
                    type="monotone"
                    dataKey="denialclaims"
                    name="Duplicate Claims"
                    stroke="#e60000"
                    fillOpacity={1}
                    fill="red"
                  />
                  <Area
                    type="monotone"
                    dataKey="freshclaims"
                    name="Fresh Claims"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="green"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}

          {this.state.select == 9 && (
            <div
              onMouseOver={() => (
                (tooltip11 = "denialclaims"), (tooltip12 = "freshclaims")
              )}
            >
              {this.state.Daywise9.length === 0 &&
                this.setState({ Daywise9: data1 })}
              <ResponsiveContainer width={"99%"} height={300}>
                <AreaChart
                  width={730}
                  height={250}
                  data={this.state.Daywise9}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs></defs>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Legend />
                  <Tooltip content={<CustomTooltip />} />

                  <Area
                    type="monotone"
                    dataKey="denialclaims"
                    name="Duplicate Claims"
                    stroke="#e60000"
                    fillOpacity={1}
                    fill="red"
                  />
                  <Area
                    type="monotone"
                    dataKey="freshclaims"
                    name="Fresh Claims"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="green"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}

          {this.state.select == 10 && (
            <div
              onMouseOver={() => (
                (tooltip11 = "denialclaims"), (tooltip12 = "freshclaims")
              )}
            >
              {this.state.Daywise10.length === 0 &&
                this.setState({ Daywise10: data1 })}
              <ResponsiveContainer width={"99%"} height={300}>
                <AreaChart
                  width={730}
                  height={250}
                  data={this.state.Daywise10}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs></defs>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Legend />
                  <Tooltip content={<CustomTooltip />} />

                  <Area
                    type="monotone"
                    dataKey="denialclaims"
                    name="Duplicate Claims"
                    stroke="#e60000"
                    fillOpacity={1}
                    fill="red"
                  />
                  <Area
                    type="monotone"
                    dataKey="freshclaims"
                    name="Fresh Claims"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="green"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}

          {this.state.select == 11 && (
            <div
              onMouseOver={() => (
                (tooltip11 = "denialclaims"), (tooltip12 = "freshclaims")
              )}
            >
              {this.state.Daywise11.length === 0 &&
                this.setState({ Daywise11: data1 })}
              <ResponsiveContainer width={"99%"} height={300}>
                <AreaChart
                  width={730}
                  height={250}
                  data={this.state.Daywise11}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs></defs>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Legend />
                  <Tooltip content={<CustomTooltip />} />

                  <Area
                    type="monotone"
                    dataKey="denialclaims"
                    name="Duplicate Claims"
                    stroke="#e60000"
                    fillOpacity={1}
                    fill="red"
                  />
                  <Area
                    type="monotone"
                    dataKey="freshclaims"
                    name="Fresh Claims"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="green"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}

          {this.state.select == 12 && (
            <div
              onMouseOver={() => (
                (tooltip11 = "denialclaims"), (tooltip12 = "freshclaims")
              )}
            >
              {this.state.Daywise12.length === 0 &&
                this.setState({ Daywise12: data1 })}
              <ResponsiveContainer width={"99%"} height={300}>
                <AreaChart
                  width={730}
                  height={250}
                  data={this.state.Daywise12}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs></defs>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Legend />
                  <Tooltip content={<CustomTooltip />} />

                  <Area
                    type="monotone"
                    dataKey="denialclaims"
                    name="Duplicate Claims"
                    stroke="#e60000"
                    fillOpacity={1}
                    fill="red"
                  />
                  <Area
                    type="monotone"
                    dataKey="freshclaims"
                    name="Fresh Claims"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="green"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default ARbyage;
