import { Component } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "axios";
import { Cell, ResponsiveContainer } from "recharts";
import { formatCurrency } from "./utils";
import Newsidebar from "../../Dashboard/Newsidebar";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  LabelList,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { ip } from "../../Apis/Api";
const CustomLabel = ({ x, y, width, value }) => (
  <text x={x + width / 2} y={y} fill="#fff" textAnchor="middle" dy={-6}>
    {value}
  </text>
);

const getPath = (x, y, width, height) => `M${x},${y + height}
  C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}Z`;
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;};
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const ClaimTooltip = ({ active, payload }) => {
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          {payload[0].payload.name}
          <br />
          <p style={{ color: payload[0].color }}>
            {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
          </p>
        </div>
      );
    }
    return null;
  };

class TopPayers extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
    dataarray:[],
    client:sessionStorage.getItem("clientid")
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {   
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getData = async () =>{
    const headers = {
      "Content-Type" : "application/json",
      Authorization : `bearer ${this.state.accesstoken}`
    };

    let url;
    if(this.state.client) {
      url = `${ip}/trends/toppayerbalance?clientId=${this.state.client}`
    }  
    else {
      console.log("inside else")
      url = `${ip}/trends/toppayerbalance`
    }
    const res = axios
    .get(url,{headers:headers})
    .then((res) =>{
      this.setState({
        dataarray:res.data.data
      })
    })
    .catch((err)=>{console.log("error", err)})
  }

  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getData()
  };


  render() {
    return (
      <div>
      <Newsidebar />
      <div className="align-row">
        <div className="align-column">

          <div>
            <div
              style={{
                textAlign: "left",
                marginLeft: "3%",
                // marginTop: "2%",
                fontSize: "14px",
              }}
            >
              <p>
                <Link
                  to="/management-trends-patterns"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Trends & Patterns
                </Link>
                &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Top 10
                Payers With Maximum Primary AR With Ageing Greater than 60 Days
              </p>
            </div>
            <br />

            <ResponsiveContainer width={"90%"} height={700}>
              <ComposedChart
                width={500}
                height={400}
                data={this.state.dataarray}
                margin={{
                  top: 0,
                  right: 20,
                  bottom: 0,
                  left: 150,
                }}
              >
                <XAxis
                  dataKey="name"
                  tickLine={false}
                  scale="band"
                  height={160}
                  angle="-45"
                  interval={0}
                  tick={{
                    strokeWidth: 0,
                    fontSize: "12",
                    fontFamily: "Poppins",
                    // top: 400,
                    textAnchor: "end",
                    dy: 6,
                  }}
                />

                <YAxis
                  dataKey="totalamount"
                  type="number"
                  tick={{ fontSize: "15", fontFamily: "Poppins" }}
                  tickLine={false}
                />
                <Tooltip content={<ClaimTooltip/>}/>
                <Bar
                  dataKey="totalamount"
                  shape={<TriangleBar />}
                  
                  // label={{
                  //   position: "top",
                  //   formatter:{formatCurrency},
                  //   fontFamily: "Poppins",
                  //   fontWeight: "400",
                  //   fontSize: "10",
                  //   style: {
                  //     lineHeight: "0.5",
                  //     marginTop: "150%" // Adjust the line-height to align the label height
                  //   }
                  // }}
                  radius={[16, 16, 16, 16]}
                  fill="#00a0fc"
                  
                >
                  <LabelList type="number" position="top" fontFamily = "Poppins" fontSize = "10" fill="blue" formatter={formatCurrency} />
                  
                  {this.state.dataarray.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Bar>
{/* 
                <Line
                  dataKey="totalamount"
                  type="monotone"
                  stroke="green"
                  strokeWidth={2}
                /> */}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
export default TopPayers;
