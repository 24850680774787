import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { curveCardinal } from "d3-shape";
import { ip } from "../../../Apis/Api";
import axios from "axios";

const data2 = [{ _id: "" }, { _id: "" }, { _id: "" }, { _id: "" }, { _id: "" }];
const cardinal = curveCardinal.tension(0.2);
let url = null;
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};
export default class CPTCharts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cpt_wise: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.from && this.props.to) {
          url = `${ip}/outcome/cptwise?start_date=${this.props.from}&end_date=${this.props.to}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/outcome/cptwise?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.from && this.props.to) {
          url = `${ip}/outcome/cptwise?start_date=${this.props.from}&end_date=${this.props.to}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/outcome/cptwise?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.from && this.props.to) {
        url = `${ip}/outcome/cptwise?start_date=${this.props.from}&end_date=${this.props.to}`;
      } else {
        url = `${ip}/outcome/cptwise`;
      }
    }
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        let i;
        let data = [];
        if (req.data.cpt_wise.denied.length !== 0) {
          for (i = 0; i < 5; i++) {
            data.push(req.data.cpt_wise.denied[i]);
          }
          this.setState({ cpt_wise: data });
        } else {
          this.setState({ cpt_wise: data2 });
        }
      })

      .catch((err) => {});
  };
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getDetails();
  }
  componentDidUpdate(prevProps) {
    if (
      !(this.props.from === prevProps.from && this.props.to === prevProps.to)
    ) {
      this.getDetails();
    } else if (this.props.client !== prevProps.client) {
      this.getDetails();
    } else if (this.props.Location !== prevProps.Location) {
      this.getDetails();
    }
  }
  render() {
    return (
      <div>
        {
          this.props.cptwise === "$" && (
            <div onMouseOver={() => (tooltip = "totalAmount")}>
              <AreaChart
                width={500}
                height={400}
                data={this.state.cpt_wise}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis
                  dataKey={"_id"}
                  height={120}
                  interval={0}
                  angle={"-45"}
                  minTickGap={6}
                  tick={{
                    strokeWidth: 0,
                    fontSize: "11",
                    top: 400,
                    textAnchor: "end",
                    dy: 6,
                  }}
                />
                <YAxis dataKey={"totalAmount"} />
                <Tooltip content={<CustomTooltip />} />
                <Area
                  name="Total Amount"
                  type="monotone"
                  dataKey={"totalAmount"}
                  stroke="#61A4BC"
                  fill="#61A4BC"
                />
              </AreaChart>
            </div>
          )
          //    <LineChart
          //    width={500}
          //    height={300}
          //    data={this.state.cpt_wise}
          //    margin={{
          //      top: 5,
          //      right: 30,
          //      left: 20,
          //      bottom: 5
          //    }}
          //  >
          //    {/* <CartesianGrid strokeDasharray="3 3" /> */}
          //    <XAxis dataKey={"_id"} />
          //    <YAxis dataKey={"totalAmount"}/>
          //    <Tooltip />
          //    <Legend />
          //    <Line
          //      type="monotone"
          //      dataKey={"totalAmount"}
          //      stroke="#8884d8"
          //      strokeDasharray="5 5"
          //    />

          //  </LineChart>
        }
        {
          this.props.cptwise === "#" && (
            <div onMouseOver={() => (tooltip = "totalClaims")}>
              <AreaChart
                width={500}
                height={400}
                data={this.state.cpt_wise}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis
                  dataKey={"_id"}
                  height={120}
                  interval={0}
                  angle={"-45"}
                  minTickGap={6}
                  tick={{
                    strokeWidth: 0,
                    fontSize: "11",
                    top: 400,
                    textAnchor: "end",
                    dy: 6,
                  }}
                />
                <YAxis dataKey={"totalClaims"} />
                <Tooltip content={<ClaimTooltip />} />
                <Area
                  name="Total Claims"
                  type="monotone"
                  dataKey={"totalClaims"}
                  stroke="#61A4BC"
                  fill="#61A4BC"
                />
              </AreaChart>
            </div>
          )
          // <LineChart
          //   width={500}
          //   height={300}
          //   data={this.state.cpt_wise}
          //   margin={{
          //     top: 5,
          //     right: 30,
          //     left: 20,
          //     bottom: 5
          //   }}
          // >
          //   {/* <CartesianGrid strokeDasharray="3 3" /> */}
          //   <XAxis dataKey={"_id"} />
          //   <YAxis dataKey={"totalClaims"}/>
          //   <Tooltip />
          //   <Legend />
          //   <Line
          //     type="monotone"
          //     dataKey={"totalClaims"}
          //     stroke="#8884d8"
          //     strokeDasharray="5 5"
          //   />

          // </LineChart>
        }
      </div>
    );
  }
}
