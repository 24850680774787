import { React, Component } from "react";
import "../../../Styles/denial.css";
import "../../../Styles/subdenial.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../../Styles/cardalloc.css";
import { BiDollar } from "react-icons/bi";
import axios from "axios";
import { ip } from "../../Apis/Api";
import DenialAllocation from "../DenialAllocation";
export class DenialBuckets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role: sessionStorage.getItem("role_type"),
      searchid: sessionStorage.getItem("querysearchid"),
      // claimcategory: sessionStorage.getItem("queryclaimiddenial"),
      querytouch: sessionStorage.getItem("querysubcategorytouch"),
      queryagegrp: sessionStorage.getItem("querysubcategoryagegrp"),
      machinecount: "",
      machineamount: "",
      firstcount: "",
      firstamount: "",
      secondcount: "",
      secondamount: "",
      thirdcount: "",
      thirdamount: "",
      fourthcount: "",
      fourthamount: "",
      morethanfourcount: "",
      morethanfouramount: "",
      overallcount: "",
      overallamount: "",
      denialmg: sessionStorage.getItem("denialmg"),
      subcategorydenial: sessionStorage.getItem("subcategorydenial"),
    };
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getMachineDenial = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/machinedenial?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/denial/overall/machinedenial?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/machinedenial?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/denial/overall/machinedenial?clientId=${this.props.client}`;
        }
      }
    } else {
      if (
        this.props.startdate.length !== 0 &&
        this.props.enddate.length !== 0
      ) {
        url = `${ip}/denial/overall/machinedenial?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/denial/overall/machinedenial?`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (Object.keys(res.data.data).length !== 0) {
          this.setState({
            machinecount: res.data.data[0].totalcount,
            machineamount: res.data.data[0].totalamount,
          });
        } else {
          this.setState({ machineamount: 0, machinecount: 0 });
        }
      })
      .catch((err) => {});
  };

  getFirstDenial = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/value?count=1&start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/denial/overall/value?count=1&clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/value?count=1&start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/denial/overall/value?count=1&clientId=${this.props.client}`;
        }
      }
    } else {
      if (
        this.props.startdate.length !== 0 &&
        this.props.enddate.length !== 0
      ) {
        url = `${ip}/denial/overall/value?count=1&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/denial/overall/value?count=1`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (Object.keys(res.data.data).length !== 0) {
          this.setState({
            firstcount: res.data.data.eachsplitup.totatcount,
            firstamount: res.data.data.eachsplitup.totalamount,
          });
        } else {
          this.setState({ firstamount: 0, firstcount: 0 });
        }
      })
      .catch((err) => {});
  };

  getSecondDenial = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/value?count=2&start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/denial/overall/value?count=2&clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/value?count=2&start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/denial/overall/value?count=2&clientId=${this.props.client}`;
        }
      }
    } else {
      if (
        this.props.startdate.length !== 0 &&
        this.props.enddate.length !== 0
      ) {
        url = `${ip}/denial/overall/value?count=2&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/denial/overall/value?count=2`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (Object.keys(res.data.data).length !== 0) {
          this.setState({
            secondcount: res.data.data.eachsplitup.totatcount,
            secondamount: res.data.data.eachsplitup.totalamount,
          });
        } else {
          this.setState({ secondamount: 0, secondcount: 0 });
        }
      })
      .catch((err) => {});
  };

  getThirdDenial = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/value?count=3&start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/denial/overall/value?count=3&clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/value?count=3&start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/denial/overall/value?count=3&clientId=${this.props.client}`;
        }
      }
    } else {
      if (
        this.props.startdate.length !== 0 &&
        this.props.enddate.length !== 0
      ) {
        url = `${ip}/denial/overall/value?count=3&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/denial/overall/value?count=3`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (Object.keys(res.data.data).length !== 0) {
          this.setState({
            thirdcount: res.data.data.eachsplitup.totatcount,
            thirdamount: res.data.data.eachsplitup.totalamount,
          });
        } else {
          this.setState({ thirdamount: 0, thirdcount: 0 });
        }
      })
      .catch((err) => {});
  };

  getFourthDenial = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/value?count=4&start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/denial/overall/value?count=4&clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/value?count=4&start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/denial/overall/value?count=4&clientId=${this.props.client}`;
        }
      }
    } else {
      if (
        this.props.startdate.length !== 0 &&
        this.props.enddate.length !== 0
      ) {
        url = `${ip}/denial/overall/value?count=4&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/denial/overall/value?count=4`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (Object.keys(res.data.data).length !== 0) {
          this.setState({
            fourthcount: res.data.data.eachsplitup.totatcount,
            fourthamount: res.data.data.eachsplitup.totalamount,
          });
        } else {
          this.setState({ fourthamount: 0, fourthcount: 0 });
        }
      })
      .catch((err) => {});
  };

  getFourorMoreDenial = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/value/fourormore?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/denial/overall/value/fourormore?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/value/fourormore?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/denial/overall/value/fourormore?clientId=${this.props.client}`;
        }
      }
    } else {
      if (
        this.props.startdate.length !== 0 &&
        this.props.enddate.length !== 0
      ) {
        url = `${ip}/denial/overall/value/fourormore?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/denial/overall/value/fourormore`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data.morethanfour.length !== 0) {
          this.setState({
            morethanfourcount: res.data.data.morethanfour[0].totalcount,
            morethanfouramount: res.data.data.morethanfour[0].totalamount,
          });
        } else {
          this.setState({ morethanfouramount: 0, morethanfourcount: 0 });
        }
      })
      .catch((err) => {});
  };

  getOverallDenial = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/value/all?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/denial/overall/value/all?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (
          this.props.startdate.length !== 0 &&
          this.props.enddate.length !== 0
        ) {
          url = `${ip}/denial/overall/value/all?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/denial/overall/value/all?clientId=${this.props.client}`;
        }
      }
    } else {
      if (
        this.props.startdate.length !== 0 &&
        this.props.enddate.length !== 0
      ) {
        url = `${ip}/denial/overall/value/all?start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
      } else {
        url = `${ip}/denial/overall/value/all`;
      }
    }
    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data.overallvalue.length !== 0) {
          this.setState({
            overallcount: res.data.data.overallvalue[0].totalcount,
            overallamount: res.data.data.overallvalue[0].totalamount,
          });
        } else {
          this.setState({ overallamount: 0, overallcount: 0 });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    this.getMachineDenial();
    this.getFirstDenial();
    this.getSecondDenial();
    this.getThirdDenial();
    this.getFourthDenial();
    this.getFourorMoreDenial();
    this.getOverallDenial();
  }

  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.enddate === prevProps.enddate
      )
    ) {
      this.getMachineDenial();
      this.getFirstDenial();
      this.getSecondDenial();
      this.getThirdDenial();
      this.getFourthDenial();
      this.getFourorMoreDenial();
      this.getOverallDenial();
    } else if (this.props.client !== prevProps.client) {
      this.getMachineDenial();
      this.getFirstDenial();
      this.getSecondDenial();
      this.getThirdDenial();
      this.getFourthDenial();
      this.getFourorMoreDenial();
      this.getOverallDenial();
    } else if (this.props.Location !== prevProps.Location) {
      this.getMachineDenial();
      this.getFirstDenial();
      this.getSecondDenial();
      this.getThirdDenial();
      this.getFourthDenial();
      this.getFourorMoreDenial();
      this.getOverallDenial();
    }
  }
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div
                className="card-header border-white"
                style={{
                  backgroundColor:
                    this.props.search2 === 0 ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      TEJAS DENIAL
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="machinedenialicon">
                      <BiDollar
                        style={{
                          color: "white",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </BiDollar>
                    </div>

                    {this.state.role === "Admin" && (
                      <DenialAllocation
                        data={7}
                        client={this.props.client}
                        Location={this.props.Location}
                        startdate={this.props.startdate}
                        enddate={this.props.enddate}
                      ></DenialAllocation>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/category",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col
                      xs={6}
                      className="alclaim"
                      onClick={() => {
                        if (this.props.client) {
                          if (this.props.Location) {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=0&abovefour=0&overall=0&clientId=${this.props.client}&Location=${this.props.Location}`
                            );
                            sessionStorage.setItem(
                              "queryclaimiddenial",
                              this.props.claimcategory
                            );
                            sessionStorage.setItem(
                              "querysearchid",
                              this.props.searchid
                            );
                            sessionStorage.setItem(
                              "querysubcategorytouch",
                              this.props.querytouch
                            );
                            sessionStorage.setItem(
                              "querysubcategoryvalue",
                              this.props.subcategorydenial
                            );
                            sessionStorage.setItem(
                              "querysubcategoryagegrp",
                              this.props.queryagegrp
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=0&abovefour=0&overall=0&clientId=${this.props.client}`
                            );
                          }
                        } else {
                          sessionStorage.setItem(
                            "queryurl",
                            `touch=0&abovefour=0&overall=0`
                          );
                        }
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                        sessionStorage.setItem(
                          "queryclaimiddenial",
                          this.props.claimcategory
                        );
                        sessionStorage.setItem(
                          "querysubcategoryagegrp",
                          this.props.queryagegrp
                        );
                        sessionStorage.setItem(
                          "querysearchid",
                          this.props.searchid
                        );
                        sessionStorage.setItem(
                          "querysubcategoryvalue",
                          this.props.subcategorydenial
                        );
                        sessionStorage.setItem(
                          "querysubcategorytouch",
                          this.props.querytouch
                        );
                      }}
                    >
                      Total Claims:
                      <span>
                        <div
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "17px",
                          }}
                        >
                          {" "}
                          {this.state.machinecount}
                        </div>
                      </span>
                    </Col>
                    <Col xs={6} className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.machineamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
          <br />
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div
                className="card-header border-white"
                style={{
                  backgroundColor:
                    this.props.search2 === 1 ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      FIRST DENIAL
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="firstdenialicon">
                      <BiDollar
                        style={{
                          color: "white",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </BiDollar>
                    </div>
                    {this.state.role === "Admin" && (
                      <DenialAllocation
                        data={1}
                        client={this.props.client}
                        Location={this.props.Location}
                        startdate={this.props.startdate}
                        enddate={this.props.enddate}
                      ></DenialAllocation>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/category",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col
                      className="alclaim"
                      onClick={() => {
                        if (this.props.client) {
                          if (this.props.Location) {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=1&abovefour=0&overall=0&clientId=${this.props.client}&Location=${this.props.Location}`
                            );
                            sessionStorage.setItem(
                              "queryclaimiddenial",
                              this.props.claimcategory
                            );
                            sessionStorage.setItem(
                              "querysearchid",
                              this.props.searchid
                            );
                            sessionStorage.setItem(
                              "querysubcategoryvalue",
                              this.props.subcategorydenial
                            );
                            sessionStorage.setItem(
                              "querysubcategorytouch",
                              this.props.querytouch
                            );
                            sessionStorage.setItem(
                              "querysubcategoryagegrp",
                              this.props.queryagegrp
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=1&abovefour=0&overall=0&clientId=${this.props.client}`
                            );
                            sessionStorage.setItem(
                              "queryclaimiddenial",
                              this.props.claimcategory
                            );
                            sessionStorage.setItem(
                              "querysubcategoryvalue",
                              this.props.subcategorydenial
                            );
                            sessionStorage.setItem(
                              "querysearchid",
                              this.props.searchid
                            );
                            sessionStorage.setItem(
                              "querysubcategorytouch",
                              this.props.querytouch
                            );
                            sessionStorage.setItem(
                              "querysubcategoryagegrp",
                              this.props.queryagegrp
                            );
                          }
                        } else {
                          sessionStorage.setItem(
                            "queryurl",
                            `touch=1&abovefour=0&overall=0`
                          );
                          sessionStorage.setItem(
                            "queryclaimiddenial",
                            this.props.claimcategory
                          );
                          sessionStorage.setItem(
                            "querysearchid",
                            this.props.searchid
                          );
                          sessionStorage.setItem(
                            "querysubcategoryvalue",
                            this.props.subcategorydenial
                          );
                          sessionStorage.setItem(
                            "querysubcategorytouch",
                            this.props.querytouch
                          );
                          sessionStorage.setItem(
                            "querysubcategoryagegrp",
                            this.props.queryagegrp
                          );
                        }
                        sessionStorage.setItem(
                          "queryclient",
                          this.props.client
                        );
                      }}
                    >
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "17px",
                          }}
                        >
                          {" "}
                          {this.state.firstcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.firstamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
          <br />
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div
                className="card-header border-white"
                style={{
                  backgroundColor:
                    this.props.search2 === 2 ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      SECOND DENIAL
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="seconddenialicon">
                      <BiDollar
                        style={{
                          color: "white",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </BiDollar>
                    </div>
                    {this.state.role === "Admin" && (
                      <DenialAllocation
                        data={2}
                        client={this.props.client}
                        Location={this.props.Location}
                        startdate={this.props.startdate}
                        enddate={this.props.enddate}
                      ></DenialAllocation>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/category",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col
                      className="alclaim"
                      onClick={() => {
                        if (this.props.client) {
                          if (this.props.Location) {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=2&abovefour=0&overall=0&clientId=${this.props.client}&Location=${this.props.Location}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem(
                              "queryLocation",
                              this.props.Location
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=2&abovefour=0&overall=0&clientId=${this.props.client}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem("queryLocation", "");
                          }
                        } else {
                          sessionStorage.setItem(
                            "queryurl",
                            `touch=2&abovefour=0&overall=0`
                          );
                          sessionStorage.setItem("queryclient", "");
                        }
                      }}
                    >
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "17px",
                          }}
                        >
                          {" "}
                          {this.state.secondcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.secondamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div
                className="card-header border-white"
                style={{
                  backgroundColor:
                    this.props.search2 === 3 ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      THIRD DENIAL
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="thirddenialicon">
                      <BiDollar
                        style={{
                          color: "white",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </BiDollar>
                    </div>

                    {this.state.role === "Admin" && (
                      <DenialAllocation
                        data={3}
                        client={this.props.client}
                        Location={this.props.Location}
                        startdate={this.props.startdate}
                        enddate={this.props.enddate}
                      ></DenialAllocation>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/category",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col
                      className="alclaim"
                      onClick={() => {
                        if (this.props.client) {
                          if (this.props.Location) {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=3&abovefour=0&overall=0&clientId=${this.props.client}&Location=${this.props.Location}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem(
                              "queryLocation",
                              this.props.Location
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=3&abovefour=0&overall=0&clientId=${this.props.client}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem("queryLocation", "");
                          }
                        } else {
                          sessionStorage.setItem(
                            "queryurl",
                            `touch=3&abovefour=0&overall=0`
                          );
                          sessionStorage.setItem("queryclient", "");
                        }
                      }}
                    >
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "17px",
                          }}
                        >
                          {" "}
                          {this.state.thirdcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.thirdamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <br />
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div
                className="card-header border-white"
                style={{
                  backgroundColor:
                    this.props.search2 === 4 ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      FOURTH DENIAL
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="fourthdenialicon">
                      <BiDollar
                        style={{
                          color: "white",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </BiDollar>
                    </div>
                    {this.state.role === "Admin" && (
                      <DenialAllocation
                        data={4}
                        client={this.props.client}
                        Location={this.props.Location}
                        startdate={this.props.startdate}
                        enddate={this.props.enddate}
                      ></DenialAllocation>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/category",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col
                      className="alclaim"
                      onClick={() => {
                        if (this.props.client) {
                          if (this.props.Location) {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=4&abovefour=0&overall=0&clientId=${this.props.client}&Location=${this.props.Location}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem(
                              "queryLocation",
                              this.props.Location
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=4&abovefour=0&overall=0&clientId=${this.props.client}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem("queryLocation", "");
                          }
                        } else {
                          sessionStorage.setItem(
                            "queryurl",
                            `touch=4&abovefour=0&overall=0`
                          );
                          sessionStorage.setItem("queryclient", "");
                        }
                      }}
                    >
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "17px",
                          }}
                        >
                          {" "}
                          {this.state.fourthcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.fourthamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
          <br />
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div
                className="card-header border-white"
                style={{
                  backgroundColor:
                    this.props.search2 > 4 ? "#83a4d4" : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      MORE THAN 4 DENIAL
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="moredenialicon">
                      <BiDollar
                        style={{
                          color: "white",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </BiDollar>
                    </div>
                    {this.state.role === "Admin" && (
                      <DenialAllocation
                        data={5}
                        client={this.props.client}
                        Location={this.props.Location}
                        startdate={this.props.startdate}
                        enddate={this.props.enddate}
                      ></DenialAllocation>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/category",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col
                      className="alclaim"
                      onClick={() => {
                        if (this.props.client) {
                          if (this.props.Location) {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=0&abovefour=1&overall=0&clientId=${this.props.client}&Location=${this.props.Location}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem(
                              "queryLocation",
                              this.props.Location
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=0&abovefour=1&overall=0&clientId=${this.props.client}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem("queryLocation", "");
                          }
                        } else {
                          sessionStorage.setItem(
                            "queryurl",
                            `touch=0&abovefour=1&overall=0`
                          );
                          sessionStorage.setItem("queryclient", "");
                        }
                      }}
                    >
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "17px",
                          }}
                        >
                          {" "}
                          {this.state.morethanfourcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.morethanfouramount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="card">
              <div
                className="card-header border-white"
                style={{
                  backgroundColor:
                    this.props.search2 === 0 ||
                    this.props.search2 === 1 ||
                    this.props.search2 === 2 ||
                    this.props.search2 === 3 ||
                    this.props.search2 === 4 ||
                    this.props.search2 > 4
                      ? "#83a4d4"
                      : "white",
                }}
              >
                <Row>
                  <Col xs={8}>
                    {" "}
                    <Link
                      to={{
                        pathname: "/denial/category",
                      }}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      OVERALL DENIAL
                    </Link>
                  </Col>

                  <Col xs={4} className="d-flex justify-content-end">
                    <div className="overalldenialicon">
                      <BiDollar
                        style={{
                          color: "white",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </BiDollar>
                    </div>

                    {this.state.role === "Admin" && (
                      <DenialAllocation
                        data={6}
                        client={this.props.client}
                        Location={this.props.Location}
                        startdate={this.props.startdate}
                        enddate={this.props.enddate}
                      ></DenialAllocation>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <Link
                  to={{
                    pathname: "/denial/category",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col
                      className="alclaim"
                      onClick={() => {
                        if (this.props.client) {
                          if (this.props.Location) {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=0&abovefour=0&overall=1&clientId=${this.props.client}&Location=${this.props.Location}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem(
                              "queryLocation",
                              this.props.Location
                            );
                            sessionStorage.setItem(
                              "queryclaimiddenial",
                              this.props.claimcategory
                            );
                            sessionStorage.setItem(
                              "querysearchid",
                              this.props.searchid
                            );
                            sessionStorage.setItem(
                              "querysubcategoryvalue",
                              this.props.subcategorydenial
                            );
                            sessionStorage.setItem(
                              "querysubcategoryagegrp",
                              this.props.queryagegrp
                            );
                            sessionStorage.setItem(
                              "querysubcategorytouch",
                              this.props.querytouch
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `touch=0&abovefour=0&overall=1&clientId=${this.props.client}`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.props.client
                            );
                            sessionStorage.setItem(
                              "queryclaimiddenial",
                              this.props.claimcategory
                            );
                            sessionStorage.setItem(
                              "querysearchid",
                              this.props.searchid
                            );
                            sessionStorage.setItem(
                              "querysubcategoryvalue",
                              this.props.subcategorydenial
                            );
                            sessionStorage.setItem(
                              "querysubcategorytouch",
                              this.props.querytouch
                            );
                            sessionStorage.setItem(
                              "querysubcategoryagegrp",
                              this.props.queryagegrp
                            );
                            sessionStorage.setItem("queryLocation", "");
                          }
                        } else {
                          sessionStorage.setItem(
                            "queryurl",
                            `touch=0&abovefour=0&overall=1`
                          );
                          sessionStorage.setItem("queryclaimiddenial", "");
                          sessionStorage.setItem("queryclient", "");
                        }
                      }}
                    >
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "17px",
                          }}
                        >
                          {" "}
                          {this.state.overallcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="altotal">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "17px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.overallamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  }
}

export default DenialBuckets;
