export const ageOptions = [
  { value: "0-30days", label: "0-30days" },
  { value: "31-60days", label: "31-60days" },
  { value: "61-90days", label: "61-90days" },
  { value: "91-120days", label: "91-120days" },
  { value: "121-180days", label: "121-180days" },
  { value: "181-365days", label: "181-365days" },
  { value: "above 1year", label: "above 1year" },
];
export const type = [
  { value: "Web", label: "Web" },
  { value: "IVR", label: "IVR" },
  { value: "Manual", label: "Manual" },
];

export const userstatus = [
  { value: `"Completed"`, label: "Completed" },
  { value: `"TO-DO"`, label: "TO-DO" },
  { value: `"",${null}`, label: "Not Touch" },
];

