import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import { Button } from "reactstrap";
import { Row } from "react-bootstrap";
import { Col, Input } from "reactstrap";
import { Modal } from "react-bootstrap";
import "../../Styles/priorauth.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { VscPreview } from "react-icons/vsc";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { BiEdit } from "react-icons/bi";
import moment from "moment-timezone";
import { AiFillSetting } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import { ip } from "../Apis/Api";
import axios from "axios";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
import AuthForm from "./AuthForm";
import EditForm from "./EditForm";
import SimpleAuth from "./PriorAuth/SimpleAuth";
const options = [
  { value: "2", label: "PatientFirstName" },
  { value: "3", label: "PatientLastName" },
  { value: "4", label: "patientAccountNumber" },
  { value: "5", label: "PatientDOB" },
  { value: "6", label: "PrimaryPolicyNo" },
  { value: "7", label: "InsuranceName" },
];
const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;
  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};
export default class PriorAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      tabindex: 1,
      inputValue: "",
      suggestions: [],
      payernamevalueErr: "",
      typereqErr: "",
      authmodal: false,
      payername: [],
      payernamevalue: "",
      typereq: "",
      activeTab: "tab1", // Set the initial active tab
      pagecount1: "",
      pagecount2: "",
      pagecount3: "",
      options: [
        'Referral',
        'Admission for Surgery',
        'Request for Behavioral Health Emergency Admission',
        'Request for Home Health Care',
        'Request for Non-emergency Transportation Service',
        'Medical Services Reservation'
      ],
      pagecount4: "",
      pagecount5: "",
      pagecounts1: "",
      pagecounts2: "",
      pagecounts3: "",
      pagecounts4: "",
      pagecounts5: "",
      pagecountincomplete: "",
      pagecountcomplete: "",
      pagecountresponse: "",
      pagecountexpired: "",
      searchBy: "",
      pageCount: "",
      searchclaimdata: [],
      searchdataresponse: [],
      searchdataresponse1: [],
      searchdataresponse2: [],
      searchdataresponse3: [],
      searchdataresponse4: [],
      searchdataresponse5: [],
      searchdisplay: false,
      getpayername: "",
      searchtext: "",
      offset: 0,
      currentpage: 0,
      perPage: sessionStorage.getItem("Default_pagination"),
      dataincompleted: [],
      typereq: '',
      typereqErr: '',
      npidata: [],
      datacompleted: [],
      expdata: [],
      responsedata: [],
      isButtonDisabled: false,
      anchorEl: null,
      authmodal: false,
      addmodal: false,
      viewmodal: false,
      editmodal: false,
      classvalue: "",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      payernamearray: [],
      Updatedpayername: "",
      typeofreq: "",
      contactname: "",
      callref: "",
      phone: "",
      effectivedate: "",
      expirationdate: "",
      authid: "",
      type: "",
      noofvisits: "",
      service: "",
      maxamtallow: "",
      status: "",
      notes: "",
      viewpayername: "",
      viewcontactname: "",
      viewphonenumber: "",
      viewcallref: "",
      vieweffectivedate: "",
      viewexpirationdate: "",
      viewauthid: "",
      viewtype: "",
      viewnoofvisits: "",
      viewservice: "",
      viewmaxamtallow: "",
      viewstatus: "",
      viewnotes: "",
      claimID: "",
      payername: "",
      SubsFirstName: "",
      SubsLastName: "",
      SubsID: "",
      MRN: "",
      NPI: [],
      service: "",
      claim: "",
      searchActive: false,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handlePageClickIncomplete = this.handlePageClickIncomplete.bind(this);
    this.handlePageClickCompleted = this.handlePageClickCompleted.bind(this);
    this.handlePageClickResponse = this.handlePageClickResponse.bind(this);
    this.handlePageClickExpired = this.handlePageClickExpired.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
  }

  handleInputChange = async (e) => {
    try {
      console.log("inside handle input change", e);
      // Set the input value to the current value
      this.setState({ inputValue: e }, () =>
        console.log("e value", this.state.inputValue)
      );

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const response = await fetch(
        `${ip}/payermaster/filter?clientId=${this.state.client}&payerName=${this.state.inputValue}`,
        { headers: headers }
      );
      const data = await response.json();
      console.log("data msg", data.payer);
      const data1 = data.payer.map((data, index) => {
        return data.payerName;
      });
      const suggestions = data1; // Replace "results" with your API response property containing the suggestions
      console.log("suggestions", suggestions);
      this.setState({ suggestions });
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  handleSuggestionClick = (selected) => {
    // Set the selected suggestion as the input value
    const suggestion = selected[0];
    this.setState({ inputValue: suggestion, payernamevalueErr: "" });

    // Clear the suggestions
    this.setState({ suggestions: [] });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //add response modal post call
  AddResponse = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      PayerName: this.state.Updatedpayername,
      contactName: this.state.contactname,
      phoneNumber: this.state.phone,
      callReference: this.state.callref,
      effectiveDate: this.state.effectivedate,
      expirationDate: this.state.expirationdate,
      authorizationId: this.state.authid,
      type: this.state.type,
      noOfVisits: this.state.noofvisits,
      service: this.state.service,
      maximumAmountAllowed: this.state.maxamtallow,
      status: this.state.status,
      notes: this.state.notes,
      clientId: this.state.client,
      ClaimID: this.state.claimID,
      SubscriberFirstName: this.state.SubsFirstName,
      SubscriberLastName: this.state.SubsLastName,
      SubscriberId: this.state.SubsID,
      SubscriberMRN: this.state.MRN,
      providerDetils: this.state.NPI,
      serviceType: this.state.service,
      claimType: this.state.claim,
    };
    // eslint-disable-next-line
    const res = await axios
      .post(`${ip}/authorization/addresponse`, value, {
        headers: headers,
      })
      .then((res) => {
        alert("Added sucessfully");
        this.setState(
          {
            addmodal: !this.state.addmodal,
            isButtonDisabled: false,
          },
          () => {
            window.location.reload(); // Reload the screen
            this.getresponsedata();
          }
        );
      })
      .catch((err) => {});
  };
  //get tab1 total count
  getcount1 = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    url = `${ip}/authorization/all?clientId=${this.state.client}&pageno=0&status=Incomplete`;
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          pagecount1: res.data.count,
        });
      })
      .catch((err) => {});
  };
  //get tab2 total count
  getcount2 = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(
        `${ip}/authorization/all?clientId=${this.state.client}&pageno=0&status=Completed`,
        { headers: headers }
      )
      .then((res) => {
        this.setState({
          pagecount2: res.data.count,
        });
      })
      .catch((err) => {});
  };
  //get tab3 total count
  getcount3 = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    url = `${ip}/authorization/all?clientId=${this.state.client}&pageno=0&status=Processed`;
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          pagecount3: res.data.count,
        });
      })
      .catch((err) => {});
  };
  //get tab4 total count
  getcount4 = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    url = `${ip}/authorization/getresponse?clientId=${this.state.client}&pageno=0&status=Response`;

    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          pagecount4: res.data.count,
        });
      })
      .catch((err) => {});
  };
  //get tab5 total count
  getcount5 = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    url = `${ip}/authorization/getresponse?clientId=${this.state.client}&pageno=0&status=Expired`;

    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          pagecount5: res.data.count,
        });
      })
      .catch((err) => {});
  };
  toggleAuth = () => {
    this.setState({
      authmodal: !this.state.authmodal,
      payernamevalueErr: "",
      inputValue: "",
      typereqErr: "",
      payernamevalue: "",
      typereq: "",
    });
  };
  toggleEdit = () => {
    this.setState({
      editmodal: !this.state.editmodal,
    });
  };
  //add response modal open close
  toggleAdd = () => {
    this.setState({
      addmodal: !this.state.addmodal,
      contactname: "",
      phone: "",
      callref: "",
      effectivedate: "",
      expirationdate: "",
      authid: "",
      type: "",
      noofvisits: "",
      service: "",
      maxamtallow: "",
      status: "",
      notes: "",
    });
  };
  //view response modal open close
  toggleView = () => {
    this.setState(
      {
        viewmodal: !this.state.viewmodal,
        viewpayername: "",
        viewcontactname: "",
        viewphonenumber: "",
        viewcallref: "",
        vieweffectivedate: "",
        viewexpirationdate: "",
        viewauthid: "",
        viewtype: "",
        viewnoofvisits: "",
        viewservice: "",
        viewmaxamtallow: "",
        viewstatus: "",
        viewnotes: "",
      },
      () => this.getviewresponse()
    );
  };
  //view response get call
  getviewresponse = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.classvalue === "Response") {
      url = `${ip}/authorization/viewresponse?clientId=${this.state.client}&ClaimID=${this.state.claimID}&status=Response`;
    } else if (this.state.classvalue === "Expired") {
      url = `${ip}/authorization/viewresponse?clientId=${this.state.client}&ClaimID=${this.state.claimID}&status=Expired`;
    }
    const res = await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          viewpayername: res.data.authorization[0].PayerName,
          viewcontactname: res.data.authorization[0].contactName,
          viewphonenumber: res.data.authorization[0].phoneNumber,
          viewcallref: res.data.authorization[0].callReference,
          vieweffectivedate: moment(
            res.data.authorization[0].effectiveDate
          ).format("MM-DD-YYYY"),
          viewexpirationdate: moment(
            res.data.authorization[0].expirationDate
          ).format("MM-DD-YYYY"),
          viewauthid: res.data.authorization[0].authorizationId,
          viewservice: res.data.authorization[0].service,
          viewtype: res.data.authorization[0].type,
          viewnoofvisits: res.data.authorization[0].noOfVisits,
          viewmaxamtallow: res.data.authorization[0].maximumAmountAllowed,
          viewstatus: res.data.authorization[0].Status,
          viewnotes: res.data.authorization[0].notes,
        });
      })
      .catch((err) => {});
  };
  //validation for modal
  formValidate = () => {
    let hasErr = true;
    let payernamevalueErr = "";
    let typereqErr = "";

    if (this.state.inputValue === "") {
      payernamevalueErr = "This field is required";
      hasErr = false;
    }

    if (this.state.typereq === "") {
      typereqErr = "This field is required";
      hasErr = false;
    }

    this.setState({ payernamevalueErr, typereqErr });

    return hasErr;
  };
  reset = () => {
    this.setState({
      searchBy: "",
      searchActive: false,
      querySearch: "",
      searchdataresponse: [],
      searchdataresponse1: [],
      searchdataresponse2: [],
      searchdataresponse3: [],
      searchdataresponse4: [],
      searchdataresponse5: [],
      searchclaimdata: [],
      searchtext: "",
      activeTab: "tab1",
      searchdisplay: false,
    });
    this.setState((prevState) => {
      // Access the previous state value using prevState
      const previousValue = prevState.pagecount2;
      // Reset the value to a new state
    });
    // this.getpayer();
  };
  handleSearch = async () => {
    this.setState({ searchActive: true });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    if (this.state.querySearch === "") {
      toast.error("Please fill the search value!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const res = await axios
        .get(
          `${ip}/authorization/searchfunctionality?${this.state.searchBy}=${this.state.querySearch}&clientId=${this.state.client}&pageno=${this.state.currentpage}`,
          { headers: headers }
        )
        .then((res) => {
          if (res.data.authorization.length === 0) {
            this.setState({ searchdisplay: true });
          } else {
            this.setState({ searchdisplay: false });
          }
          if (res.data.authorization[0].status === "Completed") {
            this.setState(
              {
                activeTab: "tab2",
                searchdataresponse: res.data.authorization,
                pagecounts2: res.data.count,
                pagecounts1: 0,
                pagecounts3: 0,
                pagecounts4: 0,
                pagecounts5: 0,
              },
              () => {
                console.log(
                  "auth",
                  this.state.searchdataresponse2,
                  this.state.activeTab
                );
              }
            );
          } else if (res.data.authorization[0].status === "Incomplete") {
            this.setState(
              {
                activeTab: "tab1",
                searchdataresponse: res.data.authorization,
                pagecounts1: res.data.count,
                pagecounts2: 0,
                pagecounts3: 0,
                pagecounts4: 0,
                pagecounts5: 0,
              },
              () => {
                console.log(
                  "auth",
                  this.state.searchdataresponse1,
                  this.state.activeTab
                );
              }
            );
          } else if (res.data.authorization[0].status === "Processed") {
            this.setState({});
            this.setState(
              {
                activeTab: "tab3",
                searchdataresponse: res.data.authorization,
                pagecounts3: res.data.count,
                pagecounts1: 0,
                pagecounts2: 0,
                pagecounts4: 0,
                pagecounts5: 0,
              },
              () => {
                console.log(
                  "auth",
                  this.state.searchdataresponse1,
                  this.state.activeTab
                );
              }
            );
          } else if (res.data.authorization[0].status === "Response") {
            this.setState(
              {
                activeTab: "tab4",
                searchdataresponse: res.data.authorization,
                pagecounts4: res.data.count,
                pagecounts1: 0,
                pagecounts2: 0,
                pagecounts3: 0,
                pagecounts5: 0,
              },
              () => {
                console.log(
                  "auth",
                  this.state.searchdataresponse4,
                  this.state.activeTab
                );
              }
            );
          } else if (res.data.authorization[0].status === "Expired") {
            this.setState(
              {
                activeTab: "tab5",
                searchdataresponse: res.data.authorization,
                pagecounts5: res.data.count,
                pagecounts1: 0,
                pagecounts2: 0,
                pagecounts3: 0,
                pagecounts4: 0,
              },
              () => {
                console.log(
                  "auth",
                  this.state.searchdataresponse1,
                  this.state.activeTab
                );
              }
            );
          }
          this.setState({ searchtext: 1 });
        })
        .catch((err) => {});
    }
  };
  //pagination and get call for incomplete data
  handlePageClickIncomplete = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getInCompleteddata();
      }
    );
  };
  handleTypeReqChange = (selected) => {
    if (selected.length > 0) {
      this.setState({
        typereq: selected[0],
        typereqErr: ''
      });
    } else {
      this.setState({
        typereq: '',
        typereqErr: ''
      });
    }
  };
  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });
  getInCompleteddata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(
        `${ip}/authorization/all?clientId=${this.state.client}&pageno=${this.state.currentpage}&status=Incomplete`,
        { headers: headers }
      )
      .then((res) => {
        this.setState({
          dataincompleted: res.data.authorization,
          pagecountincomplete: Math.ceil(res.data.count / this.state.perPage),
        });
      })

      .catch((err) => {});
  };
  //pagination and get call for complete data
  handlePageClickCompleted = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getCompleteddata();
      }
    );
  };
  getCompleteddata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(
        `${ip}/authorization/all?clientId=${this.state.client}&pageno=${this.state.currentpage}&status=Completed`,
        { headers: headers }
      )
      .then((res) => {
        this.setState({
          datacompleted: res.data.authorization,
          pagecountcomplete: Math.ceil(res.data.count / this.state.perPage),
        });
      })

      .catch((err) => {});
  };
  //pagination and get call for Response data
  handlePageClickResponse = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getresponsedata();
      }
    );
  };
  getresponsedata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(
        `${ip}/authorization/getresponse?clientId=${this.state.client}&pageno=${this.state.currentpage}&status=Response`,
        { headers: headers }
      )
      .then((res) => {
        this.setState({
          responsedata: res.data.authorization,
          pagecountresponse: Math.ceil(res.data.count / this.state.perPage),
        });
      })
      .catch((err) => {});
  };
  //pagination and get call for expired data
  handlePageClickExpired = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getexpdata();
      }
    );
  };
  getexpdata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(
        `${ip}/authorization/getresponse?clientId=${this.state.client}&pageno=${this.state.currentpage}&status=Expired`,
        { headers: headers }
      )
      .then((res) => {
        this.setState({
          expdata: res.data.authorization,
          pagecountexpired: Math.ceil(res.data.count / this.state.perPage),
        });
      })

      .catch((err) => {});
  };
  //phone number validation
  handleChangePhone({ target: { value } }) {
    this.setState((prevState) => ({
      phone: normalizeInput(value, prevState.phone),
    }));
  }
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    // this.getpayer();
    this.getcount1();
    this.getcount2();
    this.getcount3();
    this.getcount4();
    this.getcount5();
    this.getInCompleteddata();
    this.getCompleteddata();
    this.getexpdata();
    this.getresponsedata();
    // this.getviewresponse();
  }
  render() {
    const { anchorEl } = this.state;
    const { inputValue, suggestions } = this.state;
    const { activeTab, tabValues } = this.state;
    return (
      <div>
      <Newsidebar name="Prior Auth Tracker" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">
          <ToastContainer />
          <div class="parent1">
            <button class="btn-gradient-3" onClick={this.toggleAuth}>
              Add Authorization
            </button>
          </div>
          <div>
            <Row>
              <div
                className="top__search"
                style={{
                  bottom: "50%",
                  marginTop: "45px",
                }}
              >
                <div className="form__group" style={{ fontSize: "14px" }}>
                  <select
                    className="drop-down__one"
                    value={this.state.searchBy}
                    onChange={(e) =>
                      this.setState({ searchBy: e.target.value })
                    }
                  >
                    <option value={""} hidden>
                      Select...
                    </option>
                    <option value="ClaimID">Claim ID</option>
                    <option value="PatientFirstName">Patient Firstname</option>
                    <option value="PatientLastName">Patient Lastname</option>
                    <option value="Insurance Name">Insurance Name</option>
                    <option value="DOS">DOS</option>
                    <option value="MRN">MRN</option>
                    <option value="PolicyNo">Policy No</option>
                    <option value="patientDOB">patient DOB</option>
                  </select>
                  <div className="main__search">
                    <input
                      type="text"
                      className="search__input"
                      value={this.state.querySearch}
                      onChange={(e) => {
                        this.setState({ querySearch: e.target.value });
                      }}
                    ></input>
                  </div>
                  <button
                    class="bn632-hover bn26"
                    onClick={this.handleSearch}
                    style={{ fontSize: "14px" }}
                  >
                    Search
                  </button>
                  <button className="bn-reset" onClick={this.reset}>
                    <IoIosRefresh
                      style={{
                        color: "#041C3C",
                        fontSize: "25px",
                      }}
                    ></IoIosRefresh>
                  </button>
                </div>
              </div>
            </Row>
            <br></br>
          </div>

          {this.state.searchdisplay === true ? (
            <div
              style={{ fontStyle: "italic", fontSize: "20px", marginTop: "3%" }}
            >
              No Data Found
            </div>
          ) : (
            <div>
              <div
                className="tabs_prior"
                activeTab="1"
                style={{ cursor: "pointer" }}
                activityClassName="text-secondary"
                onClick={(event, tab) => {
                  this.setState({ tabindex: tab });
                }}
              >
                {this.state.searchActive === true ? (
                  <button
                    disabled={true}
                    style={{
                      border: "0.5px solid #B2BEB5",
                      padding: "7px",
                      width: "15%",
                      fontSize: "14px",
                    }}
                    className={activeTab === "tab1" ? "active-tab" : ""}
                    onClick={() => this.handleTabChange("tab1")}
                  >
                    Incomplete
                    {this.state.searchdataresponse.length !== 0 ? (
                      <div className="tab-value">
                        {this.state.pagecounts1 ? this.state.pagecounts1 : 0}
                      </div>
                    ) : (
                      <div className="tab-value">
                        {this.state.pagecount1 ? this.state.pagecount1 : 0}
                      </div>
                    )}
                  </button>
                ) : (
                  <button
                    style={{
                      border: "0.5px solid #B2BEB5",
                      padding: "7px",
                      width: "15%",
                      fontSize: "14px",
                    }}
                    className={activeTab === "tab1" ? "active-tab" : ""}
                    onClick={() => this.handleTabChange("tab1")}
                  >
                    Incomplete
                    {this.state.searchdataresponse.length !== 0 ? (
                      <div className="tab-value">
                        {this.state.pagecounts1 ? this.state.pagecounts1 : 0}
                      </div>
                    ) : (
                      <div className="tab-value">
                        {this.state.pagecount1 ? this.state.pagecount1 : 0}
                      </div>
                    )}
                  </button>
                )}
                {this.state.searchActive === true ? (
                  <button
                    disabled={true}
                    style={{
                      border: "0.5px solid #B2BEB5",
                      padding: "7px",
                      width: "15%",
                      fontSize: "14px",
                    }}
                    className={activeTab === "tab2" ? "active-tab" : ""}
                    onClick={() => this.handleTabChange("tab2")}
                  >
                    Complete
                    {this.state.searchdataresponse.length !== 0 ? (
                      <div className="tab-value">
                        {this.state.pagecounts2 ? this.state.pagecounts2 : 0}
                      </div>
                    ) : (
                      <div className="tab-value">
                        {this.state.pagecount2 ? this.state.pagecount2 : 0}
                      </div>
                    )}
                  </button>
                ) : (
                  <button
                    style={{
                      border: "0.5px solid #B2BEB5",
                      padding: "7px",
                      width: "15%",
                      fontSize: "14px",
                    }}
                    className={activeTab === "tab2" ? "active-tab" : ""}
                    onClick={() => this.handleTabChange("tab2")}
                  >
                    Complete
                    {this.state.searchdataresponse.length !== 0 ? (
                      <div className="tab-value">
                        {this.state.pagecounts2 ? this.state.pagecounts2 : 0}
                      </div>
                    ) : (
                      <div className="tab-value">
                        {this.state.pagecount2 ? this.state.pagecount2 : 0}
                      </div>
                    )}
                  </button>
                )}

                {this.state.searchActive === true ? (
                  <button
                    disabled={true}
                    style={{
                      border: "0.5px solid #B2BEB5",
                      padding: "7px",
                      width: "15%",
                      fontSize: "14px",
                    }}
                    className={activeTab === "tab3" ? "active-tab" : ""}
                    onClick={() => this.handleTabChange("tab3")}
                  >
                    Processed
                    {this.state.searchdataresponse.length !== 0 ? (
                      <div className="tab-value">
                        {this.state.pagecounts3 ? this.state.pagecounts3 : 0}
                      </div>
                    ) : (
                      <div className="tab-value">
                        {1}
                      </div>
                    )}
                  </button>
                ) : (
                  <button
                    style={{
                      border: "0.5px solid #B2BEB5",
                      padding: "7px",
                      width: "15%",
                      fontSize: "14px",
                    }}
                    className={activeTab === "tab3" ? "active-tab" : ""}
                    onClick={() => this.handleTabChange("tab3")}
                  >
                    Processed
                    {this.state.searchdataresponse.length !== 0 ? (
                      <div className="tab-value">
                        {this.state.pagecounts3 ? this.state.pagecounts3 : 0}
                      </div>
                    ) : (
                      <div className="tab-value">
                        {1}
                      </div>
                    )}
                  </button>
                )}
                {this.state.searchActive === true ? (
                  <button
                    disabled={true}
                    style={{
                      border: "0.5px solid #B2BEB5",
                      padding: "7px",
                      width: "15%",
                      fontSize: "14px",
                    }}
                    className={activeTab === "tab4" ? "active-tab" : ""}
                    onClick={() => this.handleTabChange("tab4")}
                  >
                    Response
                    {this.state.searchdataresponse.length !== 0 ? (
                      <div className="tab-value">
                        {this.state.pagecounts4 ? this.state.pagecounts4 : 0}
                      </div>
                    ) : (
                      <div className="tab-value">
                        {this.state.pagecount4 ? this.state.pagecount4 : 0}
                      </div>
                    )}
                  </button>
                ) : (
                  <button
                    style={{
                      border: "0.5px solid #B2BEB5",
                      padding: "7px",
                      width: "15%",
                      fontSize: "14px",
                    }}
                    className={activeTab === "tab4" ? "active-tab" : ""}
                    onClick={() => this.handleTabChange("tab4")}
                  >
                    Response
                    {this.state.searchdataresponse.length !== 0 ? (
                      <div className="tab-value">
                        {this.state.pagecounts4 ? this.state.pagecounts4 : 0}
                      </div>
                    ) : (
                      <div className="tab-value">
                        {this.state.pagecount4 ? this.state.pagecount4 : 0}
                      </div>
                    )}
                  </button>
                )}
                {this.state.searchActive === true  ? (
                  <button
                    disabled={true}
                    style={{
                      border: "0.5px solid #B2BEB5",
                      padding: "7px",
                      width: "15%",
                      fontSize: "14px",
                    }}
                    className={activeTab === "tab5" ? "active-tab" : ""}
                    onClick={() => this.handleTabChange("tab5")}
                  >
                    Expired
                    {this.state.searchdataresponse.length !== 0 ? (
                      <div className="tab-value">
                        {this.state.pagecounts5 ? this.state.pagecounts5 : 0}
                      </div>
                    ) : (
                      <div className="tab-value">
                        {this.state.pagecount5 ? this.state.pagecount5 : 0}
                      </div>
                    )}
                  </button>
                ) : (
                  <button
                    style={{
                      border: "0.5px solid #B2BEB5",
                      padding: "7px",
                      width: "15%",
                      fontSize: "14px",
                    }}
                    className={activeTab === "tab5" ? "active-tab" : ""}
                    onClick={() => this.handleTabChange("tab5")}
                  >
                    Expired
                    {this.state.searchdataresponse.length !== 0 ? (
                      <div className="tab-value">
                        {this.state.pagecounts5 ? this.state.pagecounts5 : 0}
                      </div>
                    ) : (
                      <div className="tab-value">
                        {this.state.pagecount5 ? this.state.pagecount5 : 0}
                      </div>
                    )}
                  </button>
                )}
              </div>
              <br />
              <div className="tab-content">
                {activeTab === "tab1" && (
                  <div>
                    {activeTab === "tab1" &&
                    this.state.searchdataresponse.length !== 0 ? (
                      <div style={{ marginRight: "3%", marginLeft: "3%" }}>
                        <Table
                          bordered
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          <thead>
                            <tr
                              className="table_auth"
                              style={{ backgroundColor: "#041c3c" }}
                            >
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                Action
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Claim ID
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                MRN
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Payer Name
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Subscriber ID
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                NPI
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Service Type
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Claim Type
                              </th>

                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                Status
                              </th>
                            </tr>
                          </thead>
                          {this.state.searchdataresponse.map((data, index) => {
                            return (
                              <tbody bordered>
                                <tr>
                                  <td
                                    style={{ alignItems: "center" }}
                                    onClick={() => {
                                      this.setState(
                                        {
                                          Updatedpayername: data.PayerName,
                                          typeofreq: data.requestType,
                                          claimID: data.ClaimID,
                                          classvalue: "Incomplete",
                                        },
                                        () => this.toggleEdit()
                                      );
                                    }}
                                  >
                                    <BiEdit />
                                  </td>
                                  <td>{data.ClaimID}</td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.SubscriberMRN}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.PayerName}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.SubscriberId}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.providerDetils.length !== 0
                                      ? data.providerDetils[0].npiprovider
                                      : ""}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.serviceType}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.claimType}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.status}
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </Table>
                        <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                          <ReactPaginate
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pagecounts1}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        {this.state.dataincompleted.length !== 0 ? (
                          <div style={{ marginRight: "3%", marginLeft: "3%" }}>
                            <Table bordered style={{ fontSize: "14px" }}>
                              <thead>
                                <tr
                                  className="table_auth"
                                  style={{ backgroundColor: "#041c3c" }}
                                >
                                  <th style={{ backgroundColor: "#B6D0E2" }}>
                                    Edit
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Claim ID
                                  </th>
                                  <th style={{ backgroundColor: "#B6D0E2" }}>
                                    MRN
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient First Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient Last Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient DOB
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Payer Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Subscriber ID
                                  </th>
                                  <th style={{ backgroundColor: "#B6D0E2" }}>
                                    NPI
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Service Type
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              {this.state.dataincompleted.map((data, index) => {
                                return (
                                  <tbody bordered>
                                    <tr>
                                      <td
                                        style={{ alignItems: "center" }}
                                        onClick={() => {
                                          this.setState(
                                            {
                                              Updatedpayername: data.PayerName,
                                              typeofreq: data.requestType,
                                              claimID: data.ClaimID,
                                              classvalue: "Incomplete",
                                            },
                                            () => this.toggleEdit()
                                          );
                                        }}
                                      >
                                        <BiEdit />
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.ClaimID}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.SubscriberMRN}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientFirstName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientLastName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientDOB}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PayerName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.SubscriberId}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.providerDetils.length !== 0
                                          ? data.providerDetils[0].npiprovider
                                          : ""}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.serviceType}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.claimType}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </Table>
                            <div
                              style={{ marginLeft: "3%", marginTop: "20px" }}
                            >
                              <ReactPaginate
                                previousLabel={<IoIosArrowBack />}
                                nextLabel={<IoIosArrowForward />}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.state.pagecountincomplete}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClickIncomplete}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              fontStyle: "italic",
                              fontSize: "20px",
                              marginTop: "3%",
                            }}
                          >
                            No Data Found
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {activeTab === "tab2" && (
                  <div>
                    {activeTab === "tab2" &&
                    this.state.searchdataresponse.length !== 0 ? (
                      <div style={{ marginRight: "3%", marginLeft: "3%" }}>
                        <Table bordered style={{ fontSize: "14px" }}>
                          <thead>
                            <tr
                              className="table_auth"
                              style={{ backgroundColor: "#041c3c" }}
                            >
                              <th style={{ backgroundColor: "#B6D0E2" }}></th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Claim ID
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                MRN
                              </th>
                              <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient First Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient Last Name
                                  </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Payer Name
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Subscriber ID
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                NPI
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Service Type
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                Status
                              </th>
                            </tr>
                          </thead>
                          {this.state.searchdataresponse.map((data, index) => {
                            return (
                              <tbody bordered>
                                <tr>
                                  <td
                                    onClick={() => {
                                      this.setState({
                                        Updatedpayername: data.PayerName,
                                        typeofreq: data.requestType,
                                        claimID: data.ClaimID,
                                        classvalue: "Complete",
                                        SubsFirstName: data.SubscriberFirstName,
                                        SubsLastName: data.SubscriberLastName,
                                        SubsID: data.SubscriberId,
                                        MRN: data.SubscriberMRN,
                                        NPI: data.providerDetils,
                                        service: data.serviceType,
                                        claim: data.claimType,
                                      });
                                    }}
                                  >
                                    <button
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                      style={{ border: "none" }}
                                      onClick={this.handleClick}
                                    >
                                      <AiFillSetting
                                        style={{
                                          color: "#57534b",
                                          marginTop: "2%",
                                        }}
                                      ></AiFillSetting>
                                    </button>
                                    <Menu
                                      id="simple-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={Boolean(anchorEl)}
                                      onClose={this.handleClose}
                                      aria-labelledby="composition-button"
                                      style={{ marginTop: "1%" }}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          this.setState(
                                            {
                                              Updatedpayername: data.PayerName,
                                              typeofreq: data.requestType,
                                              claimID: data.ClaimID,
                                              classvalue: "Complete",
                                              SubsFirstName:
                                                data.SubscriberFirstName,
                                              SubsLastName:
                                                data.SubscriberLastName,
                                              SubsID: data.SubscriberId,
                                              MRN: data.SubscriberMRN,
                                              NPI: data.providerDetils,
                                              service: data.serviceType,
                                              claim: data.claimType,
                                            },
                                            () => {
                                              this.toggleEdit();
                                              this.handleClose();
                                            }
                                          );
                                        }}
                                      >
                                        Edit Auth
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          this.setState(
                                            {
                                              Updatedpayername: data.PayerName,
                                              typeofreq: data.requestType,
                                              claimID: data.ClaimID,
                                              classvalue: "Complete",
                                              SubsFirstName:
                                                data.SubscriberFirstName,
                                              SubsLastName:
                                                data.SubscriberLastName,
                                              SubsID: data.SubscriberId,
                                              MRN: data.SubscriberMRN,
                                              NPI: data.providerDetils,
                                              service: data.serviceType,
                                              claim: data.claimType,
                                            },
                                            () => {
                                              this.toggleAdd();
                                              this.handleClose();
                                            }
                                          );
                                        }}
                                      >
                                        Add Response
                                      </MenuItem>
                                    </Menu>
                                  </td>
                                  <td>{data.ClaimID}</td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.SubscriberMRN}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                        {data.PatientFirstName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientLastName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientDOB}
                                      </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.PayerName}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.SubscriberId}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.providerDetils.length !== 0
                                      ? data.providerDetils[0].npiprovider
                                      : ""}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.serviceType}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.status}
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </Table>
                        <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                          <ReactPaginate
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pagecounts2}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        {this.state.datacompleted.length === 0 ? (
                          <div
                            style={{
                              fontStyle: "italic",
                              fontSize: "20px",
                              marginTop: "3%",
                            }}
                          >
                            No Data Found
                          </div>
                        ) : (
                          <div style={{ marginRight: "3%", marginLeft: "3%" }}>
                            <Table bordered style={{ fontSize: "14px" }}>
                              <thead>
                                <tr
                                  className="table_auth"
                                  style={{ backgroundColor: "#041c3c" }}
                                >
                                  <th style={{ backgroundColor: "#B6D0E2" }}>
                                    Action
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Claim ID
                                  </th>
                                  <th style={{ backgroundColor: "#B6D0E2" }}>
                                    MRN
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient First Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient Last Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient DOB
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Payer Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Subscriber ID
                                  </th>
                                  <th style={{ backgroundColor: "#B6D0E2" }}>
                                    NPI
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Service Type
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              {this.state.datacompleted.map((data, index) => {
                                return (
                                  <tbody bordered>
                                    <tr>
                                      <td>
                                        <SimpleAuth
                                          claimID={data.ClaimID}
                                          service={data.serviceType}
                                          payername={data.PayerName}
                                          NPI={data.providerDetils}
                                          MRN={data.SubscriberMRN}
                                          SubsFirstName={
                                            data.SubscriberFirstName
                                          }
                                          SubsLastName={data.SubscriberLastName}
                                          claimtype={data.claimType}
                                          type={data.requestType}
                                        ></SimpleAuth>
                                      </td>
                                      <td>{data.ClaimID}</td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.SubscriberMRN}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientFirstName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientLastName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientDOB}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PayerName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.SubscriberId}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.providerDetils.length !== 0
                                          ? data.providerDetils[0].npiprovider
                                          : ""}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.serviceType}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.Status}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </Table>
                            <div
                              style={{ marginLeft: "3%", marginTop: "20px" }}
                            >
                              <ReactPaginate
                                previousLabel={<IoIosArrowBack />}
                                nextLabel={<IoIosArrowForward />}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.state.pagecountcomplete}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClickCompleted}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {activeTab === "tab3" && (
                  <div>
                    {activeTab === "tab3" &&
                    this.state.searchdataresponse.length !== 0 ? (
                      <div>
                        <Table bordered responsive style={{ fontSize: "14px" }}>
                          <thead>
                            <tr
                              className="table_auth"
                              style={{ backgroundColor: "#041c3c" }}
                            >
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                Action
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Claim ID
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                MRN
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Patient First Name
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Patient Last Name
                              </th>

                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Payer Name
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Subscriber ID
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                NPI
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Service Type
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                Status
                              </th>
                            </tr>
                          </thead>
                          {this.state.searchdataresponse.map((data, index) => {
                            return (
                              <tbody bordered>
                                <tr>
                                  <td></td>
                                  <td>{data.ClaimID}</td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.SubscriberMRN}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.SubscriberFirstName}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.SubscriberLastName}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.PayerName}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.SubscriberId}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.providerDetils.length !== 0
                                      ? data.providerDetils[0].npiprovider
                                      : ""}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.serviceType}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.status}
                                  </td>
                                </tr>{" "}
                              </tbody>
                            );
                          })}
                        </Table>
                        <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                          <ReactPaginate
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pagecounts3}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    ) : 
                    <div style={{ marginRight: "3%", marginLeft: "3%" }}>
                    <Table bordered style={{ fontSize: "14px" }}>
                      <thead>
                        <tr
                          className="table_auth"
                          style={{ backgroundColor: "#041c3c" }}
                        >
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              backgroundColor: "#B6D0E2",
                            }}
                          >
                            Claim ID
                          </th>
                          <th style={{ backgroundColor: "#B6D0E2" }}>
                            MRN
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              backgroundColor: "#B6D0E2",
                            }}
                          >
                            Payer Name
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              backgroundColor: "#B6D0E2",
                            }}
                          >
                            Subscriber ID
                          </th>
                          <th style={{ backgroundColor: "#B6D0E2" }}>
                            NPI
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              backgroundColor: "#B6D0E2",
                            }}
                          >
                            Service Type
                          </th>
                          <th
                            style={{
                              whiteSpace: "nowrap",
                              backgroundColor: "#B6D0E2",
                            }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      {/* {this.state.datacompleted.map((data, index) => {
                        return ( */}
                          <tbody bordered>
                            <tr>
      
                              <td>{"APSA123346"}</td>
                              <td style={{ textAlign: "center" }}>
                                {"123"}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {"Aetna"}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {"1234"}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {"9090"}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {"Authorization"}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {"Acknowledgement"}
                              </td>
                            </tr>

                          </tbody>
                        {/* ); */}
                      {/* })} */}
                    </Table>
                    {/* <div
                      style={{ marginLeft: "3%", marginTop: "20px" }}
                    >
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pagecountcomplete}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClickCompleted}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div> */}
                  </div>
                    
                    }
                  </div>
                )}
                {activeTab === "tab4" && (
                  <div>
                    {activeTab === "tab4" &&
                    this.state.searchdataresponse.length !== 0 ? (
                      <div style={{ marginRight: "3%", marginLeft: "3%" }}>
                        <Table bordered style={{ fontSize: "14px" }}>
                          <thead>
                            <tr
                              className="table_auth"
                              style={{ backgroundColor: "#041c3c" }}
                            >
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                View
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Claim ID
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                MRN
                              </th>
                              <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient First Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient Last Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient DOB
                                  </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Payer Name
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Subscriber ID
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                NPI
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Service Type
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                Status
                              </th>
                            </tr>
                          </thead>
                          {this.state.searchdataresponse.map((data, index) => {
                            return (
                              <tbody bordered>
                                <tr>
                                  <td
                                    style={{
                                      alignItems: "center",
                                      textAlign: "center",
                                    }}
                                    onClick={() => {
                                      this.setState(
                                        {
                                          claimID: data.ClaimID,
                                          classvalue: "Response",
                                          payername: data.PayerName,
                                        },
                                        () => {
                                          this.toggleView();
                                        }
                                      );
                                    }}
                                  >
                                    <VscPreview />
                                  </td>
                                  <td>{data.ClaimID}</td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.SubscriberMRN}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                        {data.PatientFirstName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientLastName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientDOB}
                                      </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.PayerName}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.SubscriberId}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.providerDetils.length !== 0
                                      ? data.providerDetils[0].npiprovider
                                      : ""}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.serviceType}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.claimType}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.status}
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </Table>
                        <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                          <ReactPaginate
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pagecounts4}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        {this.state.responsedata.length !== 0 ? (
                          <div style={{ marginRight: "3%", marginLeft: "3%" }}>
                            <Table bordered style={{ fontSize: "14px" }}>
                              <thead>
                                <tr
                                  className="table_auth"
                                  style={{ backgroundColor: "#041c3c" }}
                                >
                                  <th style={{ backgroundColor: "#B6D0E2" }}>
                                    View
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Claim ID
                                  </th>
                                  <th style={{ backgroundColor: "#B6D0E2" }}>
                                    MRN
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient First Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient Last Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient DOB
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Payer Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Subscriber ID
                                  </th>
                                  <th style={{ backgroundColor: "#B6D0E2" }}>
                                    NPI
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Service Type
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Status
                                  </th>
                                </tr>
                              </thead>

                              {this.state.responsedata.map((data, index) => {
                                return (
                                  <tbody key={index} bordered>
                                    <tr>
                                      <td
                                        style={{
                                          alignItems: "center",
                                          textAlign: "center",
                                        }}
                                        onClick={() => {
                                          this.setState(
                                            {
                                              claimID: data.ClaimID,
                                              classvalue: "Response",
                                              payername: data.PayerName,
                                            },
                                            () => {
                                              this.toggleView();
                                            }
                                          );
                                        }}
                                      >
                                        <VscPreview />
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.ClaimID}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.SubscriberMRN}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientFirstName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientLastName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientDOB}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PayerName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.SubscriberId}
                                      </td>
                                      <td>
                                        {data.providerDetils.length !== 0
                                          ? data.providerDetils[0].npiprovider
                                          : ""}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.serviceType}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.claimType}
                                      </td>
                                    </tr>{" "}
                                  </tbody>
                                );
                              })}
                            </Table>
                            <div
                              style={{ marginLeft: "3%", marginTop: "20px" }}
                            >
                              <ReactPaginate
                                previousLabel={<IoIosArrowBack />}
                                nextLabel={<IoIosArrowForward />}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.state.pagecountresponse}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClickResponse}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              fontStyle: "italic",
                              fontSize: "20px",
                              marginTop: "3%",
                            }}
                          >
                            No Data Found
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {activeTab === "tab5" && (
                  <div>
                    {activeTab === "tab5" &&
                    this.state.searchdataresponse.length !== 0 ? (
                      <div style={{ marginRight: "3%", marginLeft: "3%" }}>
                        <Table bordered style={{ fontSize: "14px" }}>
                          <thead>
                            <tr
                              className="table_auth"
                              style={{ backgroundColor: "#041c3c" }}
                            >
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                View
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Claim ID
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                MRN
                              </th>
                              <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient First Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient Last Name
                                  </th>
                                  
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient DOB
                                  </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Payer Name
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Subscriber ID
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                NPI
                              </th>
                              <th
                                style={{
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B6D0E2",
                                }}
                              >
                                Service Type
                              </th>
                              <th style={{ backgroundColor: "#B6D0E2" }}>
                                Status
                              </th>
                            </tr>
                          </thead>
                          {this.state.searchdataresponse.map((data, index) => {
                            return (
                              <tbody bordered>
                                <tr>
                                  <td
                                    style={{
                                      alignItems: "center",
                                      textAlign: "center",
                                    }}
                                    onClick={() => {
                                      this.setState(
                                        {
                                          claimID: data.ClaimID,
                                          classvalue: "Expired",
                                        },
                                        () => {
                                          this.toggleView();
                                        }
                                      );
                                    }}
                                  >
                                    <VscPreview />
                                  </td>
                                  <td>{data.ClaimID}</td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.SubscriberMRN}
                                  </td>                        
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientFirstName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientLastName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientDOB}
                                      </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.PayerName}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.SubscriberId}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.providerDetils.length !== 0
                                      ? data.providerDetils[0].npiprovider
                                      : ""}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.serviceType}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.status}
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </Table>
                        <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                          <ReactPaginate
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pagecounts5}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClickExpired}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        {this.state.expdata.length !== 0 ? (
                          <div style={{ marginRight: "3%", marginLeft: "3%" }}>
                            <Table bordered style={{ fontSize: "14px" }}>
                              <thead>
                                <tr
                                  className="table_auth"
                                  style={{ backgroundColor: "#041c3c" }}
                                >
                                  <th style={{ backgroundColor: "#B6D0E2" }}>
                                    View
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Claim ID
                                  </th>
                                  <th style={{ backgroundColor: "#B6D0E2" }}>
                                    MRN
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient First Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient Last Name
                                  </th>
                                  
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Patient DOB
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Payer Name
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Subscriber ID
                                  </th>
                                  <th style={{ backgroundColor: "#B6D0E2" }}>
                                    NPI
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Service Type
                                  </th>
                                  <th
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: "#B6D0E2",
                                    }}
                                  >
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              {this.state.expdata.map((data, index) => {
                                return (
                                  <tbody bordered>
                                    <tr>
                                      <td
                                        style={{
                                          alignItems: "center",
                                          textAlign: "center",
                                        }}
                                        onClick={() => {
                                          this.setState(
                                            {
                                              claimID: data.ClaimID,
                                              classvalue: "Expired",
                                              payername: data.PayerName,
                                            },
                                            () => {
                                              this.toggleView();
                                            }
                                          );
                                        }}
                                      >
                                        <VscPreview />
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.ClaimID}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.SubscriberMRN}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientFirstName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientLastName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PatientDOB}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.PayerName}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.SubscriberId}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.providerDetils.length !== 0
                                          ? data.providerDetils[0].npiprovider
                                          : ""}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.serviceType}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {data.claimType}
                                      </td>
                                    </tr>{" "}
                                  </tbody>
                                );
                              })}
                            </Table>
                            <div
                              style={{ marginLeft: "3%", marginTop: "20px" }}
                            >
                              <ReactPaginate
                                previousLabel={<IoIosArrowBack />}
                                nextLabel={<IoIosArrowForward />}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.state.pagecountexpired}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClickExpired}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              fontStyle: "italic",
                              fontSize: "20px",
                              marginTop: "3%",
                            }}
                          >
                            No Data Found
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* Modal for add auth */}
        <Modal
          show={this.state.authmodal}
          onHide={this.toggleAuth}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header toggle={this.toggleAuth} closeButton closeLabel="">
            Add Auth
          </Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "scroll",
            }}
          >
            <div style={{ paddingTop: "1%" }}>
              <div>
                <Row style={{ paddingTop: "2%", justifyContent: "center" }}>
                  <Col md="5">
                    <h5
                      className="label required"
                      style={{ textAlign: "left" }}
                    >
                      Payer Name
                    </h5>
                  </Col>
                  <Col md="6">
                    <div>
                      <Typeahead
                        id="autoSuggestionInput"
                        clearButton={true}
                        labelKey="name"
                        options={suggestions}
                        selected={inputValue ? [inputValue] : []}
                        onChange={this.handleSuggestionClick}
                        onInputChange={this.handleInputChange}
                        placeholder="Search..."
                      />
                    </div>
                    <div style={{ fontSize: 16, color: "red" }}>
                      {this.state.payernamevalueErr ? (
                        <span>{this.state.payernamevalueErr}</span>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                  <Col md="5">
                    <h5
                      className="label required"
                      style={{ textAlign: "left" }}
                    >
                      Type of Request
                    </h5>
                  </Col>
                  <Col md="6">
                  <Typeahead
                  clearButton={true}
        id="typeahead-input"
        options={this.state.options}
        placeholder="Select Request"
        onChange={this.handleTypeReqChange}
      />
                    <div style={{ fontSize: 16, color: "red" }}>
                      {this.state.typereqErr && (
                        <span>{this.state.typereqErr}</span>
                      )}
                    </div>
                  </Col>
                </Row>

                <br />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Row
              style={{
                justifyContent: "center",
                marginLeft: "70%",
              }}
            >
              <Col md="10" style={{ display: "flex" }}>
                <Link
                  to="/eligibility/AuthForm"
                  onClick={(e) => {
                    e.preventDefault();

                    if (this.formValidate()) {
                      sessionStorage.setItem(
                        "payername",
                        this.state.inputValue
                      );
                      sessionStorage.setItem("typeofreq", this.state.typereq);

                      this.props.history.push("/eligibility/AuthForm");
                    }
                  }}
                >
                  <Button style={{ background: "#07326c" }}>Next</Button>
                </Link>
                &nbsp;
                <Button
                  style={{ background: "#07326c" }}
                  onClick={this.toggleAuth}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
        {/* Modal for edit auth */}
        <Modal
          show={this.state.editmodal}
          onHide={this.toggleEdit}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header toggle={this.toggleEdit} closeButton closeLabel="">
            Edit Auth
          </Modal.Header>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <p style={{ color: "blue" }}>
                Claim ID :{" "}
                <span style={{ color: "#041c3c" }}>{this.state.claimID}</span>
              </p>
            </div>
            <div style={{ paddingTop: "1%" }}>
              <div>
                <Row style={{ paddingTop: "1%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5 className="label" style={{ textAlign: "left" }}>
                      Payer Name
                    </h5>
                  </Col>
                  <Col md="6">
                    <Typeahead
                      id="typeahed1"
                      clearButton={true}
                      defaultInputValue={this.state.Updatedpayername}
                      options={this.state.payernamearray}
                      labelKey={(option) =>
                        (this.state.Updatedpayername = `${option.payerName}`)
                      }
                      onClick={(e) => {
                        this.setState({
                          Updatedpayername: e.target.value,
                        });
                      }}
                      placeholder="Select Payer Name"
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "1%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5 className="label" style={{ textAlign: "left" }}>
                      Type of Request
                    </h5>
                  </Col>
                  <Col md="6">
                    <Input
                      type="select"
                      placeholder="Select Request"
                      onChange={(e) => {
                        this.setState({
                          typeofreq: e.target.value,
                        });
                      }}
                    >
                      <option value={""} hidden>
                        Select Type of Request
                      </option>
                      <option value={"Referral"}>Referral</option>
                      <option value={"Admission for Surgery"}>
                        Admission for Surgery
                      </option>
                      <option
                        value={
                          "Request for Behavioral Health Emergency Admission"
                        }
                      >
                        Request for Behavioral Health Emergency Admission
                      </option>
                      <option value={"Request for Home Health Care"}>
                        Request for Home Health Care
                      </option>
                      <option
                        value={
                          "Request for Non-emergency Transportation Service"
                        }
                      >
                        Request for Non-emergency Transportation Service
                      </option>
                      <option value={"Medical Services Reservation"}>
                        Medical Services Reservation
                      </option>
                    </Input>
                  </Col>
                </Row>
                <br />
                <Row
                  style={{
                    paddingTop: "1%",
                    justifyContent: "center",
                    marginLeft: "70%",
                  }}
                >
                  <Col md="10" style={{ display: "flex" }}>
                    <Link
                      to={{
                        pathname: "/eligibility/EditForm",
                        state: {
                          claimvalue: this.state.claimID,
                          classvalue: this.state.classvalue,
                          payername: this.state.Updatedpayername,
                          typeofreq: this.state.typeofreq,
                        },
                      }}
                    >
                      <Button
                        style={{ background: "#07326c", color: "#fff" }}
                        onClick={() => (
                          <EditForm
                            claimvalue={this.state.claimID}
                            classvalue={this.state.classvalue}
                            payername={this.state.Updatedpayername}
                            typeofreq={this.state.typeofreq}
                          />
                        )}
                      >
                        Next
                      </Button>
                    </Link>{" "}
                    &nbsp;
                    <Button
                      style={{ background: "#07326c", color: "#fff" }}
                      onClick={this.toggleEdit}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
                <br />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Modal for Add response */}
        <Modal
          show={this.state.addmodal}
          onHide={this.toggleAdd}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header toggle={this.toggleAdd} closeButton closeLabel="">
            Add Response
          </Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "scroll",
              "overflow-x": "hidden",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <p style={{ color: "blue" }}>
                Claim ID :{" "}
                <span style={{ color: "#041c3c" }}>{this.state.claimID}</span>
              </p>
            </div>
            <div className="auth_form">
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Payer Name
                  </h5>
                </Col>
                <Col md="6">
                  <Typeahead
                    id="typeahed1"
                    clearButton={true}
                    defaultInputValue={this.state.Updatedpayername}
                    options={this.state.payernamearray}
                    labelKey={(option) =>
                      (this.state.payername = `${option.payerName}`)
                    }
                    onChange={(e) => {
                      this.setState({
                        payername: e,
                      });
                    }}
                    placeholder="Select Payer Name"
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Contact Name
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    value={this.state.contactname}
                    onChange={(e) => {
                      this.setState({ contactname: e.target.value });
                    }}
                  ></Input>
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Phone Number
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    name="phone"
                    placeholder="(xxx) xxx-xxxx"
                    value={this.state.phone}
                    onChange={this.handleChangePhone}
                  />
                  {this.state.error && (
                    <p className="error">{this.state.error}</p>
                  )}
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Call reference #
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    value={this.state.callref}
                    onChange={(e) => {
                      this.setState({ callref: e.target.value });
                    }}
                  ></Input>
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Effective Date
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="date"
                    placeholder=""
                    value={this.state.effectivedate}
                    onChange={(e) => {
                      this.setState({ effectivedate: e.target.value });
                    }}
                  ></Input>
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Expiration Date
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="date"
                    placeholder=""
                    value={this.state.expirationdate}
                    onChange={(e) => {
                      this.setState({ expirationdate: e.target.value });
                    }}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Authorization ID
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    value={this.state.authid}
                    onChange={(e) => {
                      this.setState({ authid: e.target.value });
                    }}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Type
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="select"
                    placeholder=""
                    value={this.state.type}
                    onChange={(e) => {
                      this.setState({ type: e.target.value });
                    }}
                  >
                    <option value={""} hidden>
                      Select Type of Request
                    </option>
                    <option value={"Referral"}>Referral</option>
                    <option value={"Admission for Surgery"}>
                      Admission for Surgery
                    </option>
                    <option
                      value={
                        "Request for Behavioral Health Emergency Admission"
                      }
                    >
                      Request for Behavioral Health Emergency Admission
                    </option>
                    <option value={"Request for Home Health Care"}>
                      Request for Home Health Care
                    </option>
                    <option
                      value={"Request for Non-emergency Transportation Service"}
                    >
                      Request for Non-emergency Transportation Service
                    </option>
                    <option value={"Medical Services Reservation"}>
                      Medical Services Reservation
                    </option>
                  </Input>
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Number of Visits
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    value={this.state.noofvisits}
                    onChange={(e) => {
                      this.setState({ noofvisits: e.target.value });
                    }}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Service
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    defaultValue={this.state.service}
                    value={this.state.service}
                    onChange={(e) => {
                      this.setState({ service: e.target.value }, () => {
                        console.log("service type", this.state.service);
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Maximum Amount Allowed
                  </h5>
                </Col>
                <Col md="6">
                  <div class="input-group prefix">
                    <span class="input-group-addon">$</span>
                    <Input
                      type="text"
                      placeholder=""
                      value={this.state.maxamtallow}
                      onChange={(e) => {
                        this.setState({ maxamtallow: e.target.value });
                      }}
                    ></Input>
                  </div>
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Status
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="select"
                    placeholder=""
                    value={this.state.status}
                    onChange={(e) => {
                      this.setState({ status: e.target.value });
                    }}
                  >
                    <option value="" hidden>
                      Select Status...
                    </option>
                    <option value="Active">Active</option>
                    <option value="Expired">Expired</option>
                  </Input>
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Notes
                  </h5>
                </Col>
                <Col md="6">
                  <textarea
                    type="text"
                    style={{ height: "100px", width: "100%", outline: "none" }}
                    placeholder=""
                    value={this.state.notes}
                    onChange={(e) => {
                      this.setState({ notes: e.target.value });
                    }}
                  />
                </Col>
              </Row>
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Row
              style={{
                paddingTop: "4%",
                justifyContent: "center",
                marginLeft: "70%",
              }}
            >
              {this.state.isButtonDisabled === true ? (
                <Col
                  md="10"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    disabled
                    style={{ height: "40px", marginRight: "3%", width: "40%" }}
                    onClick={() =>
                      this.setState(
                        {
                          isButtonDisabled: true,
                        },
                        () => this.AddResponse()
                      )
                    }
                  >
                    Save
                  </Button>
                  &nbsp;
                  <Button
                    style={{ background: "#07326c", color: "white" }}
                    onClick={() => this.toggleAdd()}
                  >
                    Close
                  </Button>
                </Col>
              ) : (
                <Col
                  md="10"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    style={{ background: "#07326c", color: "white" }}
                    onClick={() =>
                      this.setState(
                        {
                          isButtonDisabled: true,
                        },
                        () => this.AddResponse()
                      )
                    }
                  >
                    Save
                  </Button>
                  &nbsp;
                  <Button
                    style={{ background: "#07326c", color: "white" }}
                    onClick={() => this.toggleAdd()}
                  >
                    Close
                  </Button>
                </Col>
              )}
            </Row>
          </Modal.Footer>
        </Modal>
        {/* Modal for view response */}
        <Modal
          show={this.state.viewmodal}
          onHide={this.toggleView}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header toggle={this.toggleView} closeButton closeLabel="">
            View Response
          </Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "scroll",
              "overflow-x": "hidden",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <p style={{ color: "blue" }}>
                Claim ID :{" "}
                <span style={{ color: "#041c3c" }}>{this.state.claimID}</span>
              </p>
            </div>
            <div className="auth_form">
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Payer Name
                  </h5>
                </Col>
                <Col md="6">
                  <Typeahead
                    id="typeahed1"
                    disabled
                    clearButton={true}
                    defaultInputValue={this.state.payername || ""}
                    options={this.state.payernamearray}
                    labelKey={(option) =>
                      (this.state.payername = `${option.payerName}`)
                    }
                    onClick={(e) => {
                      this.setState({
                        payername: e.target.value,
                      });
                    }}
                    placeholder="Select Payer Name"
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Contact Name
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    disabled
                    defaultValue={this.state.viewcontactname}
                    value={this.state.viewcontactname}
                    onChange={(e) => {
                      this.setState({ viewcontactname: e.target.value });
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Phone Number
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    name="phone"
                    disabled
                    placeholder="(xxx) xxx-xxxx"
                    value={this.state.viewphonenumber}
                    // onChange={this.handleChange}
                  />
                  {this.state.error && (
                    <p className="error">{this.state.error}</p>
                  )}
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Call reference #
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    disabled
                    value={this.state.viewcallref}
                    // onChange={(e) => {
                    //   this.setState({ viewcallref: e.target.value });
                    // }}
                  />
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Effective Date
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    disabled
                    defaultValue={this.state.vieweffectivedate}
                    value={this.state.vieweffectivedate}
                    // onChange={(e) => {
                    //   this.setState({ vieweffectivedate: e.target.value });
                    // }}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Expiration Date
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    disabled
                    value={this.state.viewexpirationdate}
                    // onChange={(e) => {
                    //   this.setState({ viewexpirationdate: e.target.value });
                    // }}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Authorization ID
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    value={this.state.viewauthid}
                    disabled
                    // onChange={(e) => {
                    //   this.setState({ viewauthid: e.target.value });
                    // }}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Type
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    value={this.state.viewtype}
                    disabled
                    // onChange={(e) => {
                    //   this.setState({ viewtype: e.target.value });
                    // }}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Number of Visits
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    value={this.state.viewnoofvisits}
                    disabled
                    // onChange={(e) => {
                    //   this.setState({ viewnovisits: e.target.value });
                    // }}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Service
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    value={this.state.viewservice}
                    disabled
                    // onChange={(e) => {
                    //   this.setState({ viewservice: e.target.value });
                    // }}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Maximum Amount Allowed
                  </h5>
                </Col>
                <Col md="6">
                  <div class="input-group prefix">
                    <span class="input-group-addon">$</span>
                    <Input
                      type="text"
                      placeholder=""
                      value={this.state.viewmaxamtallow}
                      disabled
                      // onChange={(e) => {
                      //   this.setState({ viewmaxamtallow: e.target.value });
                      // }}
                    ></Input>
                  </div>
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Status
                  </h5>
                </Col>
                <Col md="6">
                  <Input
                    type="text"
                    placeholder=""
                    value={this.state.viewstatus}
                    disabled
                    defaultValue={this.state.viewstatus}
                    // onChange={(e) => {
                    //   this.setState({ viewstatus: e.target.value });
                    // }}
                  >
                    <option value="" hidden>
                      Select Status...
                    </option>
                    <option value="Active">Active</option>
                    <option value="Expired">Expired</option>
                  </Input>
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Notes
                  </h5>
                </Col>
                <Col md="6">
                  <textarea
                    type="text"
                    style={{ height: "100px", width: "100%", outline: "none" }}
                    placeholder=""
                    value={this.state.viewnotes}
                    defaultValue={this.state.viewnotes}
                    // onChange={(e) => {
                    //   this.setState({ viewnotes: e.target.value });
                    // }}
                  />
                </Col>
              </Row>
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Row
              style={{
                paddingTop: "4%",
                justifyContent: "center",
                marginLeft: "70%",
              }}
            >
              <Col md="10" style={{ display: "flex" }}>
                <Link to="/eligibility/AuthForm">
                  <Button style={{ background: "#07326c", color: "white" }}>
                    Save
                  </Button>
                </Link>{" "}
                &nbsp;
                <Button
                  style={{ background: "#07326c", color: "white" }}
                  onClick={this.toggleView}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
      </div>
    );
  }
}
