import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import { Button } from "reactstrap";
import loading from "../../../Images/loader.gif";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ip } from "../../Apis/Api";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { Modal, ModalHeader, ModalBody, ModalFooter, Row } from "reactstrap";
class ActionCheckAutoClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      ClaimID: sessionStorage.getItem("ClaimID"),
      ChartID: sessionStorage.getItem("ChartID"),
      MRN: sessionStorage.getItem("MRN"),
      Patient: sessionStorage.getItem("Patient"),
      Pripayer: sessionStorage.getItem("Pripayer"),
      DateOfService: sessionStorage.getItem("DateOfService"),
      ClaimDate: sessionStorage.getItem("ClaimDate"),
      PatientDOB: sessionStorage.getItem("PatientDOB"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      updateAge: "",
      updateIcdCode: "",
      updatePayerCategory: "",
      updatePayerId: "",
      updateArClass: "",
      updatePolicyNo: "",
      updateDOS: "",
      updatePripayer: "",
      updateClaimDate: "",
      updatePhysician: "",
      updatePatientDOB: "",
      updateLastName: "",
      updateFirstName: "",
      updateMRN: "",
      updateChartId: "",
      updateClaimID: "",
      updateAgeGroup: "",
      updateTotalBillAmt: "",
      updateTotalBalAmt: "",
      updateTotallPaidAmt: "",
      updateClaimStatus: "",
      updateOverAllClaimStatus: "",
      updateGender: "",
      updateNpi: "",
      updateServiceProvider: "",
      updateServiceProviderType: "",
      updateTaxID: "",
      updatePripayerContact: "",
      updateControlNo: "",
      updatePayerIdentification: "",
      updateTouch: "",
      updateTfl: "",
      updateid: "",
      updatetype: "",
      updateCheckIssueDate: "",
      updateEndDOS: "",
      updateBeginingDOS: "",
      updateBillingProviderType: "",
      updateFollowUpdate: "",
      updateCreatedAT: "",
      updateCPTS: "",
      updateClaimCategory: "",
      updateClaimSubCategory: "",
      updateSubmittedAmount: "",
      TrackingNumber: "",
      EndDOS: "",
      BeginingProviderType: "",
      Gender: "",
      BillingProviderType: "",
      BillingProvider: "",
      ServiceProviderType: "",
      Npi: "",
      ServiceProvider: "",
      TaxID: "",
      PayerIdentification: "",
      TradingPartnerDeviceID: "",
      ControlNumber: "",
      PrimaryPolicyNo: "",
      PatientDOB: "",
      PatientLastName: "",
      action: "",
      ActionStatuscode: "",
      Notes: "",
      PatientFirstName: "",
      BeginingDOS: "",
      ChartID: "",
      MRN: "",
      PriPayer: "",
      Phone: "",
      Address: "",
      ClaimDate: "",
      DateOfService: "",
      ProcedureCode: "",
      PrimaryDiagnosis: "",
      Unit: "",
      AmountCharges: "",
      BilledAmount: "",
      ServiceProvider: "",
      updatePartnerId: "",
      loadingModal: false,
      loadModal: false,
    };
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  cpttoggle = () => {
    this.setState({ loadingModal: !this.state.loadingModal });
  };
  UpdateDetails = async () => {
    this.setState({ loadingModal: !this.state.loadingModal });

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    const value = {
      cpt: this.state.cptdata,
      CPTS: this.state.updateCPTS,
      trackingNumber: this.state.updateTrackingNumber,
      EndDOS: this.state.updateEndDOS,
      BeginingDOS: this.state.updateBeginingDOS,
      Gender: this.state.updateGender,
      BillingProviderType: this.state.updateBillingProviderType,
      ServiceProviderType: this.state.updateServiceProviderType,
      Npi: this.state.updateNpi,
      ServiceProvider: this.state.updateServiceProvider,
      TaxID: this.state.updateTaxID,
      payerIdentification: this.state.updatePayerIdentification,
      tradingPartnerServiceId: this.state.updatePartnerId,
      controlNumber: this.state.updateControlNo,
      PrimaryPolicyNo: this.state.updatePolicyNo,
      PatientDOB: this.state.PatientDOB,
      PatientLastName: this.state.updateLastName,
      PatientFirstName: this.state.updateFirstName,
      ClaimID: this.state.ClaimID,
      ActionCode: this.state.action,
      ActionStatuscode: this.state.ActionStatuscode,
      Notes: this.state.Notes,
      claimSubCategory: this.state.updateClaimSubCategory,
      ClaimCategory: this.state.updateClaimCategory,
      TotalBilledAmount: this.state.updateTotalBilledAmount,
      ClaimStatus: this.state.updateClaimStatus,
      TotalAmountpaid: this.state.updateTotallPaidAmt,
    };

    const res = await axios
      .put(`${ip}/claim/autocheck?claimid=${this.state.ClaimID}`, value, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ loadingModal: !this.state.loadingModal });

        this.getclaims();
        alert("Claims Autocheck Completed");
        this.cpttoggle();
      })
      .catch((err) => {
        this.setState({ loadingModal: !this.state.loadingModal });
      });
  };
  getclaims = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = await axios
      .get(`${ip}/claim/viewclaim?claimid=${this.state.ClaimID}`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          updatePartnerId: res.data.Claim[0].tradingPartnerServiceId,
          updateBillingProviderType: res.data.Claim[0].BillingProviderType,
          updateEndDOS: res.data.Claim[0].EndDOS,
          updatePayerId: res.data.Claim[0].PayerID,
          updatePripayer: res.data.Claim[0].Pripayer,
          updateClaimDate: res.data.Claim[0].ClaimDate,
          updatePhysician: res.data.Claim[0].Physician,
          updatePatientDOB: res.data.Claim[0].PatientDOB,
          updateLastName: res.data.Claim[0].PatientLastName,
          updateFirstName: res.data.Claim[0].PatientFirstName,
          updateMRN: res.data.Claim[0].MRN,
          updatePolicyNo: res.data.Claim[0].PrimaryPolicyNo,
          updateServieProvider: res.data.Claim[0].ServiceProvider,
          updateChartId: res.data.Claim[0].ChartID,
          updateClaimID: res.data.Claim[0].ClaimID,
          updateTotalBillAmt: res.data.Claim[0].TotalBilledAmount,
          updateTotalBalAmt: res.data.Claim[0].TotalBalanceAmount,
          updateTotallPaidAmt: res.data.Claim[0].TotalAmountpaid,
          updateGender: res.data.Claim[0].Gender,
          updateNpi: res.data.Claim[0].Npi,
          updateServieProvider: res.data.Claim[0].ServiceProvider,
          updateServiceProviderType: res.data.Claim[0].ServiceProviderType,
          updateTaxID: res.data.Claim[0].TaxID,
          updatePripayerContact: res.data.Claim[0].Pripayerphone,
          updateControlNo: res.data.Claim[0].controlNumber,
          updatePayerIdentification: res.data.Claim[0].payerIdentification,
          updateTouch: res.data.Claim[0].Touch,
          updateAmountPaid: res.data.Claim[0].amountPaid,
          updateTrackingNumber: res.data.Claim[0].trackingNumber,
          updateBeginingDOS: res.data.Claim[0].BeginingDOS,
          updateSubmittedAmount: res.data.Claim[0].submittedAmount,
          updateTotalBilledAmount: res.data.Claim[0].TotalBilledAmount,
          updateClaimStatus: res.data.Claim[0].ClaimStatus,
          updateClaimSubCategory: res.data.Claim[0].ClaimSubCategory,
          updateClaimCategory: res.data.Claim[0].ClaimCategory,
          updateTotallPaidAmt: res.data.Claim[0].TotalAmountpaid,
          action: res.data.Claim[0].ActionCode,
          ActionStatuscode: res.data.Claim[0].ActionStatuscode,
          Notes: res.data.Claim[0].Notes,
        });
      })
      .catch((err) => {});
  };
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getclaims();
  }

  toggle = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:last-child td, &:last-child th": {
        border: 1,
      },
    }));
    return (
      <div className="align-row">
        {this.state.role_type === "Admin" ? (
          <Newsidebar name="Actions" />
        ) : null}
        <div className={this.state.css_name}>
          <br></br>
          <br></br>
          <div
            style={{ textAlign: "left", marginLeft: "2%", fontSize: "14px" }}
          >
            <p>
              <Link
                to="/allocation-buckets"
                style={{ textDecoration: "none", color: "black" }}
              >
                Work Queue
              </Link>
              {/* &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp; */}
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Check
              Auto Claim
            </p>
          </div>
          <br></br>
          <div>
            {" "}
            <h5
              style={{ textAlign: "left", marginLeft: "2%", fontWeight: "600" }}
            >
              Check Auto Claim
            </h5>
          </div>
          <br></br>
          <div>
            <Button
              style={{
                marginLeft: "80%",
                marginTop: "-6%",
                marginBottom: "1%",
                textAlign: "left",
                fontSize: "20px",
                fontWeight: "500",
                borderRadius: "6px",
                color: "#ffff",
                backgroundColor: "#041c3c",
              }}
              onClick={this.cpttoggle}
            >
              Check Claim
            </Button>
          </div>

          <div>
            <table className="viewtablename">
              <tr>
                <th colSpan={2} className="dataheading">
                  Patient
                </th>
                <th colSpan={2} className="dataheading">
                  Provider
                </th>
                <th colSpan={2} className="dataheading">
                  Payer
                </th>
              </tr>

              <tr className="datatablevalue">
                <td className="viewdata">ClaimID</td>
                <td className="viewdata">{this.state.updateClaimID}</td>
                <td className="viewdata">Pripayer</td>
                <td className="viewdata">{this.state.updatePripayer}</td>
                <td className="viewdata">Physian</td>

                <td className="viewdata">{this.state.updatePhysician}</td>
              </tr>

              <tr className="datatablevalue">
                <td className="viewdata">FirstName</td>
                <td className="viewdata"> {this.state.updateFirstName}</td>
                <td className="viewdata">PayerID</td>
                <td className="viewdata">{this.state.updatePayerId}</td>
                <td className="viewdata">Tax ID</td>
                <td className="viewdata">{this.state.updateTaxID}</td>
              </tr>

              <tr className="datatablevalue">
                <td className="viewdata">Lastname</td>
                <td className="viewdata">{this.state.updateLastName}</td>
                <td className="viewdata">Pripayer Contact</td>
                <td className="viewdata">{this.state.updatePripayerContact}</td>
                <td className="viewdata">NPI</td>
                <td className="viewdata">{this.state.updateNpi}</td>
              </tr>

              <tr className="datatablevalue">
                <td className="viewdata">MRN/Account Number</td>
                <td className="viewdata">{this.state.updateMRN}</td>
                <td className="viewdata"></td>
                <td className="viewdata"></td>
                <td className="viewdata">Service Provider</td>
                <td className="viewdata">{this.state.updateServieProvider}</td>
              </tr>

              <tr className="datatablevalue">
                <td className="viewdata">PrimaryPolicyNo</td>
                <td className="viewdata">{this.state.updatePolicyNo}</td>
                <td className="viewdata"></td>
                <td className="viewdata"></td>
                <td className="viewdata">Service Provider Type</td>
                <td className="viewdata">
                  {this.state.updateServiceProviderType}
                </td>
              </tr>

              <tr className="datatablevalue">
                <td className="viewdata">DOB</td>
                <td className="viewdata">{this.state.updatePatientDOB}</td>
                <td className="viewdata"></td>
                <td className="viewdata"></td>
                <td className="viewdata">Billing Provider type</td>
                <td className="viewdata">
                  {this.state.updateServiceProviderType}
                </td>
              </tr>

              <tr className="datatablevalue">
                <td className="viewdata">Gender</td>
                <td className="viewdata">{this.state.updateGender}</td>
                <td className="viewdata"></td>
                <td className="viewdata"></td>
                <td className="viewdata"></td>
                <td className="viewdata"></td>
              </tr>
            </table>
          </div>
          <br></br>
          <div>
            <div style={{ height: "100%", width: "100%" }}>
              <div>
                {" "}
                <h5
                  style={{
                    textAlign: "left",
                    marginLeft: "2%",
                    fontWeight: "600",
                  }}
                >
                  CPT details
                </h5>
              </div>

              <table className="tablename">
                <tr>
                  <th className="dataheading">CPT CODE</th>
                  <th className="dataheading">Submitted Amount</th>
                  <th className="dataheading">cpt Paid Amount</th>
                  <th className="dataheading">cpt status</th>
                </tr>
                {this.state.cptdata &&
                  this.state.cptdata.map((data, index) => {
                    return (
                      <tr>
                        <td className="viewdata">{data.CPTCode}</td>
                        <td className="viewdata">{data.submittedAmount}</td>
                        <td className="viewdata">{data.amountPaid}</td>
                        <td className="viewdata">{data.ClaimStatus}</td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div>
            <div style={{ height: "100%", width: "100%" }}>
              <div>
                {" "}
                <h5
                  style={{
                    textAlign: "left",
                    marginLeft: "2%",
                    fontWeight: "600",
                  }}
                >
                  CLAIM Status
                </h5>
              </div>
              <table className="tablename">
                <tr>
                  <th className="dataheading">Claim Status</th>
                  <th className="dataheading">Claim SubCategory</th>
                  <th className="dataheading">Claim Category</th>
                  <th className="dataheading">Total Billed Amount</th>
                  <th className="dataheading"> Total Paid Amount</th>
                </tr>
                <tr>
                  <td className="viewdata">{this.state.updateClaimStatus}</td>
                  <td className="viewdata">
                    {this.state.updateClaimSubCategory}
                  </td>
                  <td className="viewdata">{this.state.updateClaimCategory}</td>
                  <td className="viewdata">
                    {this.state.updateTotalBilledAmount}
                  </td>
                  <td className="viewdata">{this.state.updateTotallPaidAmt}</td>
                </tr>
              </table>
            </div>
          </div>
          <br />
          <br />
          <div>
            <div style={{ height: "100%", width: "100%" }}>
              <div>
                {" "}
                <h5
                  style={{
                    textAlign: "left",
                    marginLeft: "2%",
                    fontWeight: "600",
                  }}
                >
                  Action
                </h5>
              </div>
              <table className="tablename">
                <tr>
                  <th className="dataheading">Action Code</th>
                  <th className="dataheading">Action StatusCode</th>
                  <th className="dataheading">Notes</th>
                </tr>
                <tr>
                  <td className="viewdata">{this.state.action}</td>
                  <td className="viewdata">{this.state.ActionStatuscode}</td>
                  <td className="viewdata">{this.state.Notes}</td>
                </tr>
              </table>
            </div>
          </div>
          <Modal
            // style={{ width: "100%", height: "70%" }}
            show={this.state.loadingModal}
            onHide={this.cpttoggle}
            // onExit={this.reset}
            // centered
            animation="false"
            size="lg"
            centered
          >
            <Modal.Header closeLabel="" closeButton>
              <Modal.Title>Check Status</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <h5 style={{ marginLeft: "26%", fontStyle: "bold" }}>
                Do You Want to Check the Claim Status
              </h5>
            </Modal.Body>
            <Modal.Footer>
              <button
                class="button-34"
                role="button"
                onClick={this.UpdateDetails}
              >
                Yes
              </button>
              <button
                class="button-35"
                role="button"
                onClick={() => {
                  this.cpttoggle();
                  // this.reset();
                }}
              >
                No
              </button>
            </Modal.Footer>
          </Modal>
          <Modal isOpen={this.state.loadingModal} centered>
            <ModalBody>
              <img
                src={loading}
                style={{ width: "200px", height: "200px", marginLeft: "30%" }}
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}
export default ActionCheckAutoClaim;
