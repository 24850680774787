import { React, Component } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { AiFillSetting } from "react-icons/ai";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Row, Col, Label } from "reactstrap";
import { Input } from "reactstrap";
import "../../../Styles/arfollowup.css";
import Select from "react-dropdown-select";
import { styled } from "@mui/material/styles";
// import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { ip } from "../../Apis/Api";
// export default class SimpleMenu extends React.Component
class SimpleMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AllocatedTo: sessionStorage.getItem("AllocatedTo"),
      historydata: [],
      cptdata: [],
      Actiondata: [],
      allocatemodal: false,
      reallocmodal: false,
      anchorEl: null,
      show: false,
      show2: false,
      activeTab: "tab1", // Set the initial active tab
      PatientFirstName: "",
      PatientLastName: "",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      clientid: sessionStorage.getItem("clientid"),
      role: sessionStorage.getItem("role_type"),
      offset: 0,
      currentpage: 0,
      perPage: sessionStorage.getItem("Default_pagination"),
      ClaimID: "",
      users: [],
      selecteduser: [],
      selectuserErr: "",
      Arfollowdata: [],
      searcharfollowdata: [],
      searched: false,
      currentpagesearch: 0,
    };
  }
  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  };
  handlePageSearchClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpagesearch: selectedPage,
        offset: offset,
      },
      () => this.searchbyCPT()
    );
  };

  searchbyCPT = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(
        `${ip}/arfollowuplog/advancedmultiplefilter?CPTcode=${this.state.searchbycptvalue}&ClaimID=${this.state.ClaimID}&pageno=${this.state.currentpagesearch}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          pageCountSearch: Math.ceil(res.data.claimscount / this.state.perPage),
          searcharfollowdata: res.data.claims,
          searched: true,
        });
      })
      .catch((err) => {});
  };

  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => this.getDetails()
    );
  };

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getClaimdata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(
        `${ip}/arfollowuplog/arlog/claimlog?claimid=${this.state.ClaimID}&clientId=${this.state.clientid}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          historydata: res.data.arfollowuplog,
        });
      })
      .catch((err) => {});
  };
  getCPTdata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(
        `${ip}/arfollowuplog/arlog/cptlog?claimid=${this.state.ClaimID}&clientId=${this.state.clientid}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          cptdata: res.data.arfollowuplog,
        });
      })
      .catch((err) => {});
  };
  getHistorydata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(
        `${ip}/arfollowuplog/arlog/claimhistory?claimid=${this.state.ClaimID}&clientId=${this.state.clientid}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const sorter = (a, b) => {
          var s1 = new Date(a.workedDate).toLocaleString(undefined, {
            timeZone: "Asia/Kolkata",
          });
          var s2 = new Date(b.workedDate).toLocaleString(undefined, {
            timeZone: "Asia/Kolkata",
          });
          return (
            new Date(s2) - new Date(s1) &&
            new Date(s2).getTime() - new Date(s1).getTime()
          );
        };
        const sort__value = res.data.arfollowuplog.sort(sorter);
        const slice = sort__value.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          Actiondata: slice,
        });
      })
      .catch((err) => {});
  };

  getusers = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/users/all?clientId=${this.state.clientid}`, {
        headers: headers,
      })
      .then((res) => {
        let data = res.data.user;
        if (this.state.clientid) {
          data = data.filter((item) => {
            return item.role === "Team Member";
          });
        } else {
          data = data.filter((item) => {
            return item.role === "Admin" || item.role === "SuperAdmin";
          });
        }
        this.setState({ users: data });
      })
      .catch((err) => {});
  };
  validate = () => {
    let hasErr = "true";
    let selectuserErr = "";

    if (this.state.selecteduser.length === 0) {
      selectuserErr = "This field is required";
      this.setState({ selectuserErr });
      hasErr = false;
    }
    return hasErr;
  };
  allocateclaims = () => {
    const isValid = this.validate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        ids: sessionStorage.getItem("ClaimDBID"),
      };
       axios
        .put(
          `${ip}/allocation/allocate/${this.state.selecteduser[0]._id}`,
          value,
          {
            headers: headers,
          }
        )
        .then((res) => {
          alert(
            `claims allocated to user ${this.state.selecteduser[0].first_name}`
          );
          this.toggle4();
          window.location.reload();
        })
        .catch((err) => {});
    }
  };

  toggle = async () => {
    const { show } = this.state;
    this.setState({ show: !show });
    await this.setState({
      ClaimID: sessionStorage.getItem("ClaimID"),
      PatientFirstName: sessionStorage.getItem("PatientFirstName"),
      PatientLastName: sessionStorage.getItem("PatientLastName"),
    });
    this.getClaimdata();
    this.getCPTdata();
    this.getHistorydata();
  };

  toggle2 = () => {
    const { show2 } = this.state;
    this.setState({ show2: !show2 });
  };

  toggle4 = () => {
    this.setState(() => {
      this.setState({ allocatemodal: !this.state.allocatemodal });
    });
  };

  toggle3 = () => {
    if (JSON.stringify(sessionStorage.getItem("AllocatedTo")) === null) {
      this.toggle4();
    } else if (JSON.stringify(sessionStorage.getItem("AllocatedTo")) !== null) {
      this.setState({ reallocmodal: !this.state.reallocmodal });
    }
  };

  reset = () => {
    this.setState({
      selecteduser: [],
      selectuserErr: "",
    });
  };

  componentDidMount() {
    
    this.getusers();
    this.getClaimdata();
    this.getCPTdata();
    this.getHistorydata();
  }

  render() {
    const { activeTab, tabValues } = this.state;
    const { anchorEl } = this.state;
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:last-child td, &:last-child th": {
        border: 1,
      },
    }));
    return (
      <div>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <AiFillSetting
            style={{ color: "#57534b", marginTop: "2%" }}
          ></AiFillSetting>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          aria-labelledby="composition-button"
          style={{ marginTop: "1%" }}
        >
          <Link
            to="/view-claim"
            style={{ textDecoration: "none", color: "black" }}
          >
            <MenuItem>View Claim</MenuItem>
          </Link>
          {/* <Link
            to="/denial-payment"
            style={{ textDecoration: "none", color: "black" }}
          >
            <MenuItem>Manage Payment</MenuItem>
          </Link> */}
          {/* <MenuItem
            onClick={() => {
              this.toggle2();
              this.handleClose();
            }}
          >
            Mark Balance
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              this.toggle();
              this.handleClose();

              // this.getDetails();
            }}
          >
            AR Follow Up Log
          </MenuItem>
          <Link
            to="/viewmoreeligibility"
            style={{ textDecoration: "none", color: "black" }}
          >
            <MenuItem>Check Eligibility Report</MenuItem>
          </Link>
   
          {this.state.role === "Admin" && (
            <MenuItem
              onClick={() => {
                this.toggle3();
                this.handleClose();
              }}
            >
              Allocate
            </MenuItem>
          )}
        </Menu>

        {this.state.show && (
          <Modal
            isOpen={this.state.show}
            onHide={this.toggle}
            size="xl"
            centered
          >
            <ModalHeader toggle={this.toggle}>AR Follow up Log</ModalHeader>
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <div
                className="tabs_prior"
                activeTab="1"
                style={{ cursor: "pointer" }}
                activityClassName="text-secondary"
                onClick={(event, tab) => {
                  this.setState({ tabindex: tab });
                }}
              >
                <button
                  style={{
                    border: "0.5px solid #B2BEB5",
                    padding: "7px",
                    width: "15%",
                    fontSize: "13px",
                  }}
                  className={activeTab === "tab1" ? "active-tab" : ""}
                  onClick={() => this.handleTabChange("tab1")}
                >
                  CPT Update
                </button>
                <button
                  style={{
                    border: "0.5px solid #B2BEB5",
                    padding: "7px",
                    width: "15%",
                    fontSize: "14px",
                  }}
                  className={activeTab === "tab2" ? "active-tab" : ""}
                  onClick={() => this.handleTabChange("tab2")}
                >
                  Claim Update
                </button>
                <button
                  style={{
                    border: "0.5px solid #B2BEB5",
                    padding: "7px",
                    width: "15%",
                    fontSize: "14px",
                  }}
                  className={activeTab === "tab3" ? "active-tab" : ""}
                  onClick={() => this.handleTabChange("tab3")}
                >
                  History
                </button>
              </div>
              <br />
              <div className="tab-content">
                {activeTab === "tab1" && (
                     <div>
                     {this.state.cptdata.length !== 0 ?
                     (
                       <Table bordered>
                       <thead>
                         <tr>
                           <th
                             style={{
                               backgroundColor: "#041c3c",
                               color: "white",
                             }}
                           >
                             Worked
                           </th>
                           <th
                             style={{
                               backgroundColor: "#041c3c",
                               color: "white",
                             }}
                           >
                             User
                           </th>      
                              <th
                             style={{
                               backgroundColor: "#041c3c",
                               color: "white",
                             }}
                           >
                             CPT
                           </th>
                           <th
                             style={{
                               backgroundColor: "#041c3c",
                               color: "white",
                             }}
                           >
                             Notes
                           </th>
                           <th
                             style={{
                               backgroundColor: "#041c3c",
                               color: "white",
                             }}
                           >
                             Next F/U 
                           </th>
                           <th
                             style={{
                               backgroundColor: "#041c3c",
                               color: "white",
                             }}
                           >
                             Claim Status
                           </th>
                           <th
                             style={{
                               backgroundColor: "#041c3c",
                               color: "white",
                             }}
                           >
                             Action Status Code
                           </th>
                           <th
                             style={{
                               backgroundColor: "#041c3c",
                               color: "white",
                             }}
                           >
                             Action Code
                           </th>
                           <th
                             style={{
                               backgroundColor: "#041c3c",
                               color: "white",
                             }}
                           >
                             Total Billed Amount
                           </th>
                           <th
                                        style={{
                                          backgroundColor: "#041c3c",
                                          color: "white",
                                        }}
                                      >
                                        Overall ClaimStatus
                                      </th>
                         </tr>
                       </thead>
                       {this.state.cptdata.map((data, index) => {
                         return (
                           <tbody>
                             <tr>
                               <td>
                               {data.workedDate ? moment(data.workedDate.substring(0,10)).format("MM/DD/YYYY") : "-"}
                               </td>
                               <td>{data.UserId}</td>
                               <td>{data.CPTcode}</td>
                               <td style={{ whiteSpace: "normal" }}>
                                 {data.Notes}
                               </td>
                               <td>
                                 {moment(data.NextFollowupDate).format(
                                   "MM/DD/YYYY"
                                 )}
                               </td>
                               <td>{data.claimStatus}</td>
                               <td style={{ whiteSpace: "normal" }}>
                                 {data.Statuscode}
                               </td>
                               <td style={{ whiteSpace: "normal" }}>
                                 {data.Actioncode}
                               </td>
                               <td>{data.TotalBilledAmount}</td>
                               <td>{data.OverallServiceLevelStatus}</td>
                             </tr>
                           </tbody>
                         );
                       })}
                     </Table>
                     ): (
                       <div style={{fontStyle:"italic", textAlign:"center"}}>No results found!</div>
                     )}               
                   </div>
                )}
                {activeTab === "tab2" && (       
                      
                             <div style={{ marginLeft: "1%" }}>
                             {this.state.historydata.length !== 0 ? (
                                  <Table bordered>
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          backgroundColor: "#041c3c",
                                          color: "white",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Worked date
                                      </th>
                                      <th
                                        style={{
                                          backgroundColor: "#041c3c",
                                          color: "white",
                                        }}
                                      >
                                        User
                                      </th>
                             
                                      <th
                                        style={{
                                          backgroundColor: "#041c3c",
                                          color: "white",
                                        }}
                                      >
                                        Notes
                                      </th>
                                      <th
                                        style={{
                                          backgroundColor: "#041c3c",
                                          color: "white",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Next F/U Date
                                      </th>
                                      <th
                                        style={{
                                          backgroundColor: "#041c3c",
                                          color: "white",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Claim Status
                                      </th>
                                      <th
                                        style={{
                                          backgroundColor: "#041c3c",
                                          color: "white",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Action Status Code
                                      </th>
                                      <th
                                        style={{
                                          backgroundColor: "#041c3c",
                                          color: "white",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Action Code
                                      </th>
                                      <th
                                        style={{
                                          backgroundColor: "#041c3c",
                                          color: "white",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Total Billed Amount
                                      </th>
                                    </tr>
                                  </thead>
                              {this.state.historydata.map((data, index) => {
                                 return (
                                   <tbody>
                                     <tr>
                                       <td>
                                         {moment(data.workedDate).format("MM/DD/YYYY")}
                                       </td>
                                       <td>{data.UserId}</td>
                                       <td style={{ whiteSpace: "normal" }}>
                                         {data.Notes}
                                       </td>
                                       <td>
                                         {moment(data.NextFollowupDate).format(
                                           "MM/DD/YYYY"
                                         )}
                                       </td>
                                       <td>{data.claimStatus}</td>
                                       <td style={{ whiteSpace: "normal" }}>
                                         {data.Statuscode}
                                       </td>
                                       <td style={{ whiteSpace: "normal" }}>
                                         {data.Actioncode}
                                       </td>
                                       <td>{data.TotalBilledAmount}</td>
                                     </tr>
                                   </tbody>
                                 );
                               })}
                           </Table>
                             ) : (
                               <div style={{fontStyle:"italic", textAlign:"center"}}>No results found!</div>
                             )}
                         </div>
                )}
                {activeTab === "tab3" && (
                <div>
                {this.state.Actiondata.length !== 0 ?
                (
                  <Table bordered>
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                        }}
                      >
                        Worked date
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                        }}
                      >
                        Status
                      </th>
                      <th
                        style={{
                          backgroundColor: "#041c3c",
                          color: "white",
                        }}
                      >
                        User
                      </th>
                    </tr>
                  </thead>
                  {this.state.Actiondata.map((data, index) => {
                    return (
                      <tbody>
                        <tr>
                          <td>
                            {moment(data.workedDate).format("MM/DD/YYYY")}
                          </td>
                          <td>{data.claimStatus}</td>
                          <td>{data.UserId}</td>
                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
                ):(
                  <div style={{fontStyle:"italic", textAlign:"center"}}>No results found!</div>
                )}
              </div>
                )}
              </div>
            </ModalBody>
          </Modal>
        )}

        {this.state.show2 && (
          <Modal
            isOpen={this.state.show2}
            onHide={this.toggle2}
            size="lg"
            centered
          >
            <ModalHeader toggle={this.toggle2}>
              Transfer to Responsibility
            </ModalHeader>
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <Row style={{ width: "100%" }}>
                <Col style={{ marginLeft: "7px" }}>
                  <Label style={{ marginLeft: "7px" }}>
                    Assign Responsibility
                  </Label>
                </Col>
                <Col>
                  <Input type={"select"}>
                    <option value="" disabled selected>
                      {" "}
                      Select..
                    </option>
                    <option value="0-10days">Copayment</option>
                    <option value="11-30days">Deductible</option>
                    <option value="31-60days">CoInsurance</option>
                    <option value="61-90days">Others</option>
                  </Input>
                </Col>
                <Col style={{ marginLeft: "7%" }}>
                  <Label>Claim Balance($): $ 0.00</Label>
                </Col>
              </Row>
              <br></br>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                  marginLeft: "70%",
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: "black",
                  color: "white",
                  marginLeft: "4px",
                }}
              >
                Save
              </Button>
              <Table
                className="table"
                style={{ marginLeft: "1%", width: "99%", marginTop: "2%" }}
              >
                <thead>
                  <tr align="center">
                    <th>DOS Form</th>
                    <th>CPT Code</th>
                    <th>CPT Charges</th>
                    <th>Copayment Balance($)</th>
                    <th>Denied Code</th>
                  </tr>
                </thead>
              </Table>
            </ModalBody>
          </Modal>
        )}

        {this.state.allocatemodal && (
          <Modal
            style={{ width: "30%" }}
            isOpen={this.state.allocatemodal}
            onHide={this.toggle4}
            onExit={this.reset}
            size="md"
            centered
          >
            <ModalHeader toggle={this.toggle4}>Create Allocation</ModalHeader>
            <ModalBody>
              <Row style={{ margin: "0px" }}>
                <Col>
                  <Label>Agent/Team Member Name</Label>
                  <Select
                    options={this.state.users}
                    values={this.state.selecteduser}
                    searchable={true}
                    dropdownHandle={true}
                    searchBy="first_name"
                    labelField="first_name"
                    noDataLabel="No matches found"
                    onChange={(values) =>
                      this.setState({
                        selecteduser: values,
                        selectuserErr: "",
                      })
                    }
                    required
                  />
                </Col>
                <div style={{ fontSize: 16, color: "red" }}>
                  {this.state.selectuserErr}
                </div>
              </Row>
              <br />
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.toggle4();
                }}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                style={{ backgroundColor: "black", color: "white" }}
                onClick={() => {
                  this.allocateclaims();
                }}
              >
                Allocate
              </Button>
            </ModalFooter>
          </Modal>
        )}

        {this.state.reallocmodal && (
          <Modal
            style={{ width: "30%" }}
            isOpen={this.state.reallocmodal}
            onHide={this.toggle3}
            onExit={this.reset}
            size="md"
            centered
          >
            <ModalHeader toggle={this.toggle3}>Reallocate</ModalHeader>
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <Row style={{ margin: "0px" }}>
                Do you want to Reallocate this claim ?
              </Row>

              <br />
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.toggle3();
                }}
              >
                NO
              </Button>
              &nbsp;&nbsp;
              <Button
                onClick={() => {
                  this.toggle3();
                  this.toggle4();
                }}
                style={{ backgroundColor: "black", color: "white" }}
              >
                YES
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}
export default SimpleMenu;
