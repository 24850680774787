import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar"; 
import "../../../Styles/subclaim.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { ip } from "../.././Apis/Api";


class Acknowledgements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: sessionStorage.getItem("queryurl"),
      searchidkeyindicator: sessionStorage.getItem("searchidkeyindicator"),
      client: sessionStorage.getItem("queryclient"),
      Location: sessionStorage.getItem("queryLocation"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      subcategoryvalue: null,
      ForwardedTotalCount: "",
      show2: sessionStorage.getItem("queryclaimid"),
      ForwardedTotalAmount: "",
      ReceiptTotalCount: "",
      ReceiptTotalAmount: "",
      AASTotalCount: "",
      AASTotalAmount: "",
      RUCTotalCount: "",
      RUCTotalAmount: "",
      NFTotalCount: "",
      NFTotalAmount: "",
      SCTotalCount: "",
      SCTotalAmount: "",
      RMITotalCount: "",
      RMITotalAmount: "",
      RIITotalCount: "",
      RIITotalAmount: "",
      RRETotalCount: "",
      RRETotalAMount: "",
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getacknowledgementdata = () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.queryenddate && this.state.querystartdate) {
      url = `${ip}/claimstatus/subcategory?${this.state.value}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
    } else {
      url = `${ip}/claimstatus/subcategory?${this.state.value}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ subcategoryvalue: res.data });
        if (res.data.data.length !== 0) {
          res.data.data.map((data, index) => {
            switch (data._id) {
              case "A0":
                return this.setState({
                  ForwardedTotalAmount: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                  ForwardedTotalCount: data.totalcount,
                });
              case "A1":
                return this.setState({
                  ReceiptTotalAmount: Math.round(
                    data.totalamount
                  ).toLocaleString(),
                  ReceiptTotalCount: data.totalcount,
                });
              case "A2":
                return this.setState({
                  AASTotalAmount: Math.round(data.totalamount).toLocaleString(),
                  AASTotalCount: data.totalcount,
                });
              case "A3":
                return this.setState({
                  RUCTotalAmount: Math.round(data.totalamount).toLocaleString(),
                  RUCTotalCount: data.totalcount,
                });
              case "A4":
                return this.setState({
                  NFTotalAmount: Math.round(data.totalamount).toLocaleString(),
                  NFTotalCount: data.totalcount,
                });
              case "A5":
                return this.setState({
                  SCTotalAmount: Math.round(data.totalamount).toLocaleString(),
                  SCTotalCount: data.totalcount,
                });
              case "A6":
                return this.setState({
                  RMITotalAmount: Math.round(data.totalamount).toLocaleString(),
                  RMITotalCount: data.totalcount,
                });
              case "A7":
                return this.setState({
                  RIITotalAmount: Math.round(data.totalamount).toLocaleString(),
                  RIITotalCount: data.totalcount,
                });
              case "A8":
                return this.setState({
                  RRETotalAMount: Math.round(data.totalamount).toLocaleString(),
                  RRETotalCount: data.totalcount,
                });
            }
          });
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    this.getacknowledgementdata();
  }

  render() {
    return (
      <div
        className="align-row"
        style={{ overflow: "hidden", fontSize: "12px" }}
      >
      <Newsidebar>
      <p>
        <Link
          to="/claim-management"
          style={{ textDecoration: "none", color:"white" ,fontSize: "15px",
          fontWeight: 500}}
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight />
        &nbsp;Acknowledgements
      </p>
    </Newsidebar>
<br/>
<br/>
          <div class="row" style={{ marginLeft: "1%" }}>
            <div class="col-md-4 mb-3">
              <div
              className="card"
                // forwarded card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A0");
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("querysub", "Forwarded");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A0&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa0"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A0");
                        sessionStorage.setItem("querysub", "Forwarded");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A0&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa0"
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A0");
                        sessionStorage.setItem("querysub", "Forwarded");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A0&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa0"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A0");
                        sessionStorage.setItem("querysub", "Forwarded");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A0&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa0"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A0");
                      sessionStorage.setItem("querysub", "Forwarded");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A0&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa0"
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A0");
                      sessionStorage.setItem("querysub", "Forwarded");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A0`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa0"
                      );
                    }
                  }
                }}
              >
                <div
                  class="card-header bg-white"
                >
                <div
                style={{
                  background: this.state.show2 === "A0" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
                }}>
                  <span class="d-inline-block" 
                  style={{fontWeight:"bold"}}
                  >
                    Forwarded
                  </span>
                  </div>
                  <br/>
                  <div class="card-text">
                  <Link
                    to={{
                      pathname: "/claim/sub-category-details",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Row>
                      <Col className="subclaim-description">
                        The claim/encounter has been forwarded to another
                        entity
                      </Col>
                    </Row>
                    <Row>
                      <Col className="sub-totalclaim">
                        Total Claims:
                        <span>
                          <div
                            className="to"
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "13px",
                            }}
                          >
                            {" "}
                            {this.state.ForwardedTotalCount}
                          </div>
                        </span>
                      </Col>
                      <Col className="sub-total">
                        Grand Total:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontSize: "13px",
                              fontWeight: "600",
                            }}
                          >
                            $ {this.state.ForwardedTotalAmount}
                          </div>
                        </span>
                      </Col>
                    </Row>
                  </Link>
                </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Receipt card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A1");
                        sessionStorage.setItem("querysub", "Receipt");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A1&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa1"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A1");
                        sessionStorage.setItem("querysub", "Receipt");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A1&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa1"
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A1");
                        sessionStorage.setItem("querysub", "Receipt");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A1&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa1"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A1");
                        sessionStorage.setItem("querysub", "Receipt");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A1&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa1"
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A1");
                      sessionStorage.setItem("querysub", "Receipt");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A1&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa1"
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A1");
                      sessionStorage.setItem("querysub", "Receipt");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A1`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa1"
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "A1" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{fontWeight:"bold"}}>Receipt</span>
                  </div>
          
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter has been received. This does not
                          mean that the claim has been accepted for
                          adjudication.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {this.state.ReceiptTotalCount}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.ReceiptTotalAmount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Acceotance into adjustant system card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A2");
                        sessionStorage.setItem(
                          "querysub",
                          "Acceptance into Adjudication System"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A2&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa2"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A2");
                        sessionStorage.setItem(
                          "querysub",
                          "Acceptance into Adjudication System"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A2&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa2"
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A2");
                        sessionStorage.setItem(
                          "querysub",
                          "Acceptance into Adjudication System"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A2&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa2"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A2");
                        sessionStorage.setItem(
                          "querysub",
                          "Acceptance into Adjudication System"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A2&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa2"
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A2");
                      sessionStorage.setItem(
                        "querysub",
                        "Acceptance into Adjudication System"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A2&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa2"
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A2");
                      sessionStorage.setItem(
                        "querysub",
                        "Acceptance into Adjudication System"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A2`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa2"
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "A2" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{fontWeight:"bold"}}>
                    Acceptance into Adjudication System
                  </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter has been accepted into the
                          adjudication system.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {this.state.AASTotalCount}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.AASTotalAmount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Returned as Unprocessable Claim card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.queryenddate && this.state.querystartdate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Returned as Unprocessable Claim"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A3");
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa3"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A3&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Returned as Unprocessable Claim"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A3");
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa3"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A3&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Returned as Unprocessable Claim"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A3");
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa3"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A3&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A3");
                        sessionStorage.setItem(
                          "querysub",
                          "Returned as Unprocessable Claim"
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa3"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A3&clientId=${this.state.client}`
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A3");
                      sessionStorage.setItem(
                        "querysub",
                        "Returned as Unprocessable Claim"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa3"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A3&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A3");
                      sessionStorage.setItem(
                        "querysub",
                        "Returned as Unprocessable Claim"
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa3"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A3`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "A3" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :  "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{fontWeight:"bold"}}>Returned as Unprocessable Claim</span>
                  </div>
          
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter has been rejected and has not been
                          entered into the system.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {this.state.RUCTotalCount}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.RUCTotalAmount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Not found card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A4");
                        sessionStorage.setItem("querysub", "Not Found");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A4&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa4"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A4");
                        sessionStorage.setItem("querysub", "Not Found");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A4&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa4"
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A4");
                        sessionStorage.setItem("querysub", "Not Found");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A4&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa4"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );

                        sessionStorage.setItem("queryCategoryCode", "A4");
                        sessionStorage.setItem("querysub", "Not Found");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A4&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa4"
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A4");
                      sessionStorage.setItem("querysub", "Not Found");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A4&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa4"
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A4");
                      sessionStorage.setItem("querysub", "Not Found");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A4`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa4"
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "A4" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{fontWeight:"bold"}}>Not Found</span>
                  </div>
            
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter can not be found in the
                          adjudication system.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {this.state.NFTotalCount}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.NFTotalAmount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Split claim card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem("querysub", "Split Claim");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A5&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A5");
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa5"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A5");
                        sessionStorage.setItem("querysub", "Split Claim");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A5&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa5"
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A5");
                        sessionStorage.setItem("querysub", "Split Claim");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A5&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa5"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A5");
                        sessionStorage.setItem("querysub", "Split Claim");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A5&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa5"
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A5");
                      sessionStorage.setItem("querysub", "Split Claim");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A5&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa5"
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A5");
                      sessionStorage.setItem("querysub", "Split Claim");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A5`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa5"
                      );
                    }
                  }
                }}
              >
              <div
                  class="card-header bg-white"
                >
                <div
                style={{
                  background: this.state.show2 === "A5" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
                }}>
                  <span class="d-inline" style={{fontWeight:"bold"}}>Split Claim</span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter has been split upon acceptance
                          into the adjudication system.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {this.state.SCTotalCount}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.SCTotalAmount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Rejected missing information card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A6");
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Missing Information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A6&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa6"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A6");
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Missing Information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A6&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa6"
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A6");
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Missing Information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A6&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa6"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A6");
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Missing Information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A6&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa6"
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A6");
                      sessionStorage.setItem(
                        "querysub",
                        "Rejected for Missing Information"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A6&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa6"
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A6");
                      sessionStorage.setItem(
                        "querysub",
                        "Rejected for Missing Information"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A6`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa6"
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "A6" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{fontWeight:"bold"}}>Rejected for Missing Information</span>
                  </div>
              
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter is missing the information
                          specified in the Status details and has been rejected.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {this.state.RMITotalCount}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.RMITotalAmount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Rejected  for invalid information card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Invalid Information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A7&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A7");
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa7"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A7");
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Invalid Information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A7&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa7"
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A7");
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Invalid Information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A7&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa7"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A7");
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Invalid Information"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A7&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa7"
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A7");
                      sessionStorage.setItem(
                        "querysub",
                        "Rejected for Invalid Information"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A7&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa7"
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A7");
                      sessionStorage.setItem(
                        "querysub",
                        "Rejected for Invalid Information"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A7`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa7"
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "A7" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{fontWeight:"bold"}}>Rejected for Invalid Information</span>
                  </div>
            
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          The claim/encounter has invalid information as
                          specified in the Status details and has been rejected.
                        </Col>
                      </Row>
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {this.state.RIITotalCount}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.RIITotalAmount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                // Rejected  for ralational field error card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Relational Field in Error"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A8");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A8&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa8"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A8");
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Relational Field in Error"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A8&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa8"
                        );
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A8");
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Relational Field in Error"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A8&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa8"
                        );
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Acknowledgements"
                        );
                        sessionStorage.setItem(
                          "queryCategoryname",
                          "Acknowledgement"
                        );
                        sessionStorage.setItem("queryCategoryCode", "A8");
                        sessionStorage.setItem(
                          "querysub",
                          "Rejected for Relational Field in Error"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=Acknowledgement&statusCategoryCode=A8&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem(
                          "subclaimname",
                          "Acknowledgementa8"
                        );
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A8");
                      sessionStorage.setItem(
                        "querysub",
                        "Rejected for Relational Field in Error"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A8&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa8"
                      );
                    } else {
                      sessionStorage.setItem(
                        "querycategory",
                        "Acknowledgements"
                      );
                      sessionStorage.setItem(
                        "queryCategoryname",
                        "Acknowledgement"
                      );
                      sessionStorage.setItem("queryCategoryCode", "A8");
                      sessionStorage.setItem(
                        "querysub",
                        "Rejected for Relational Field in Error"
                      );
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=Acknowledgement&statusCategoryCode=A8`
                      );
                      sessionStorage.setItem(
                        "subclaimname",
                        "Acknowledgementa8"
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{
              background: this.state.show2 === "A8" ?"radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" :   "linear-gradient(to right, #002755 65%, #EB1A23)",  padding:"3px",color:"white"
            }}>
                  <span class="d-inline" style={{fontWeight:"bold"}}>
                    Rejected for Relational Field in Error
                  </span>
                  </div>
          
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <br />
                      <br />
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {this.state.RRETotalCount}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.RRETotalAMount}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}
export default Acknowledgements;
