import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import "../../../Styles/subclaim.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { ip } from "../../Apis/Api";
class Machine2Error extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: sessionStorage.getItem("queryurl"),
      Location: sessionStorage.getItem("queryLocation"),
      client: sessionStorage.getItem("queryclient"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      subcategoryvalue: null,
      show2: sessionStorage.getItem("queryclaimid"),
      subcategoryvalue: null,
      reserrortotalamt: "",
      reserrortotalcnt: "",
      ressystotalamt: "",
      ressystotalcnt: "",
      ihrtotalamt: "",
      ihrtotalcnt: "",
      correctionreqamt: "",
      correctionreqcnt: "",
      tpatotalamt: "",
      tpatotalcnt: "",
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  geterrorsdata = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryenddate && this.state.querystartdate) {
      url = `${ip}/claimstatus/subcategory?${this.state.value}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
    } else {
      url = `${ip}/claimstatus/subcategory?${this.state.value}`;
    }
    const res = await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ subcategoryvalue: res.data.data });
        if (res.data.data.length !== 0) {
          res.data.data.map((data, index) => {
            switch (data._id) {
              case "E0":
                return this.setState({
                  reserrortotalamt: data.totalamount.toLocaleString(),
                  reserrortotalcnt: data.totalcount,
                });
              case "E1":
                return this.setState({
                  ressystotalamt: data.totalamount.toLocaleString(),
                  ressystotalcnt: data.totalcount,
                });
              case "E2":
                return this.setState({
                  ihrtotalamt: data.totalamount.toLocaleString(),
                  ihrtotalcnt: data.totalcount,
                });
              case "E3":
                return this.setState({
                  correctionreqamt: data.totalamount.toLocaleString(),
                  correctionreqcnt: data.totalcount,
                });
              case "E4":
                return this.setState({
                  tpatotalamt: data.totalamount.toLocaleString(),
                  tpatotalcnt: data.totalcount,
                });
            }
          });
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    
    this.geterrorsdata();
  }
  render() {
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar>
        <p>
        <Link
          to="/claim-management"
          style={{ textDecoration: "none", color: "white",fontSize: "15px",
          fontWeight: 500 }}
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Machine 2
        Error
      </p>
        </Newsidebar>

        <div className="align-column">
          <br />
          <br />
          <div
            style={{ textAlign: "left", marginLeft: "5%", fontSize: "13px" }}
          >
       
          </div>
          <div className="row" style={{ marginLeft: "1%" }}>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //Response not possible error on this client  card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          " Response not Possible"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E0&Machine=Availity&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "E0");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem("subclaimname", "errore0");
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          " Response not Possible"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E0");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E0&Machine=Availity&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("subclaimname", "errore0");
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          " Response not Possible"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E0");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E0&Machine=Availity&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem("subclaimname", "errore0");
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          " Response not Possible"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E0");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E0&Machine=Availity&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem("subclaimname", "errore0");
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Machine2 Error");
                      sessionStorage.setItem(
                        "querysub",
                        "Response not Possible"
                      );
                      sessionStorage.setItem("queryCategoryCode", "E0");
                      sessionStorage.setItem("queryCategoryname", "error");
                      sessionStorage.setItem("subclaimname", "errore0");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=error&statusCategoryCode=E0&Machine=Availity&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem("querycategory", "Machine2 Error");
                      sessionStorage.setItem(
                        "querysub",
                        " Response not Possible"
                      );
                      sessionStorage.setItem("queryCategoryCode", "E0");
                      sessionStorage.setItem("queryCategoryname", "error");
                      sessionStorage.setItem("subclaimname", "errore0");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=error&statusCategoryCode=E0`
                      );
                    }
                  }
                }}
              >
                <div
                  class="card-header bg-white"
                >
                <div
                style={{background: this.state.show2 === "E0" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)": "linear-gradient(to right, #002755 65%, #EB1A23)"}}
                >
                <span class="d-inline" style={{ fontWeight: "bold", color:"white" }}>
                Response not Possible - Error on Submitted Data{" "}
              </span>
                </div>
                <div class="card-text">
                <Link
                  to={{
                    pathname: "/claim/sub-category-details",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row>
                    <Col className="subclaim-description">
                      An Error on submitted request data
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col className="sub-totalclaim">
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "19px",
                          }}
                        >
                          {this.state.reserrortotalcnt}
                        </div>
                      </span>
                    </Col>
                    <Col className="sub-total">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "19px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.reserrortotalamt}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //Response not possible SYSTEM ERrOR  card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          " Response not Possible"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E1");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E1&Machine=Availity&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("subclaimname", "errore1");
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          " Response not Possible"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E1");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E1&Machine=Availity&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("subclaimname", "errore1");
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          " Response not Possible"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E1");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E1&Machine=Availity&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem("subclaimname", "errore1");
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          " Response not Possible"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E1");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E1&Machine=Availity&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem("subclaimname", "errore1");
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Machine2 Error");
                      sessionStorage.setItem(
                        "querysub",
                        "Response not Possible"
                      );
                      sessionStorage.setItem("queryCategoryCode", "E1");
                      sessionStorage.setItem("queryCategoryname", "error");
                      sessionStorage.setItem("subclaimname", "errore1");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=error&statusCategoryCode=E1&Machine=Availity&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem("querycategory", "Machine2 Error");
                      sessionStorage.setItem(
                        "querysub",
                        " Response not Possible"
                      );
                      sessionStorage.setItem("queryCategoryCode", "E1");
                      sessionStorage.setItem("queryCategoryname", "error");
                      sessionStorage.setItem("subclaimname", "errore1");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=error&statusCategoryCode=E1`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{background: this.state.show2 === "E1" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)": "linear-gradient(to right, #002755 65%, #EB1A23)"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold", color:"white" }}>
                    Response not Possible - System Status{" "}
                  </span>
                  </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          System Status
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {this.state.ressystotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.ressystotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //Information holder is not responding card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Information Holder is not Responding"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E2&Machine=Availity&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "E2");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem("subclaimname", "errore2");
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Information Holder is not Responding"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E2");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E2&Machine=Availity&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("subclaimname", "errore2");
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Information Holder is not Responding"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E2");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E2&Machine=Availity&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem("subclaimname", "errore2");
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Information Holder is not Responding"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E2");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E2&Machine=Availity&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem("subclaimname", "errore2");
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Machine2 Error");
                      sessionStorage.setItem(
                        "querysub",
                        "Information Holder is not Responding"
                      );
                      sessionStorage.setItem("queryCategoryCode", "E2");
                      sessionStorage.setItem("queryCategoryname", "error");
                      sessionStorage.setItem("subclaimname", "errore2");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=error&statusCategoryCode=E2&Machine=Availity&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem("querycategory", "Machine2 Error");
                      sessionStorage.setItem(
                        "querysub",
                        "Information Holder is not Responding"
                      );
                      sessionStorage.setItem("queryCategoryCode", "E2");
                      sessionStorage.setItem("queryCategoryname", "error");
                      sessionStorage.setItem("subclaimname", "errore2");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=error&statusCategoryCode=E2`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{background: this.state.show2 === "E2" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)": "linear-gradient(to right, #002755 65%, #EB1A23)"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold", color:"white" }}>
                    Information Holder is not Responding{" "}
                  </span>
                </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          Resubmit at a later time.
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {this.state.ihrtotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.ihrtotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //correction required card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Correction Required"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E3");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E3&Machine=Availity&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("subclaimname", "errore3");
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Correction Required"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E3");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E3&Machine=Availity&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("subclaimname", "errore3");
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Correction Required"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E3");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E3&Machine=Availity&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem("subclaimname", "errore3");
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Correction Required"
                        );
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E3&Machine=Availity&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem("queryCategoryCode", "E3");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem("subclaimname", "errore3");
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Machine2 Error");
                      sessionStorage.setItem("querysub", "Correction Required");
                      sessionStorage.setItem("subclaimname", "errore3");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=error&statusCategoryCode=E3&Machine=Availity&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                      sessionStorage.setItem("queryCategoryCode", "E3");
                      sessionStorage.setItem("queryCategoryname", "error");
                    } else {
                      sessionStorage.setItem("querycategory", "Machine2 Error");
                      sessionStorage.setItem("querysub", "Correction Required");
                      sessionStorage.setItem("subclaimname", "errore3");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=error&statusCategoryCode=E3`
                      );
                      sessionStorage.setItem("queryCategoryCode", "E3");
                      sessionStorage.setItem("queryCategoryname", "error");
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white"
            >
            <div
            style={{background: this.state.show2 === "E3" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)": "linear-gradient(to right, #002755 65%, #EB1A23)"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold", color:"white" }}>
                    Correction Required{" "}
                  </span>
         </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          Relational fields in error
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {this.state.correctionreqcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.correctionreqamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div
                class="card"
                //Trading partner agreement card
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Trading Partner Agreement Specific Requirement not Met"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E4");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E4&Machine=Availity&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("subclaimname", "errore4");
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Trading Partner Agreement Specific Requirement not Met"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E4");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E4&Machine=Availity&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                        );
                        sessionStorage.setItem("subclaimname", "errore4");
                      }
                    } else {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Trading Partner Agreement Specific Requirement not Met"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E4");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E4&Machine=Availity&clientId=${this.state.client}&Location=${this.state.Location}`
                        );
                        sessionStorage.setItem("subclaimname", "errore4");
                      } else {
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem(
                          "querysub",
                          "Trading Partner Agreement Specific Requirement not Met"
                        );
                        sessionStorage.setItem("queryCategoryCode", "E4");
                        sessionStorage.setItem("queryCategoryname", "error");
                        sessionStorage.setItem(
                          "claimurl",
                          `ClaimCategory=error&statusCategoryCode=E4&Machine=Availity&clientId=${this.state.client}`
                        );
                        sessionStorage.setItem("subclaimname", "errore4");
                      }
                    }
                  } else {
                    if (this.state.querystartdate && this.state.queryenddate) {
                      sessionStorage.setItem("querycategory", "Machine2 Error");
                      sessionStorage.setItem(
                        "querysub",
                        "Trading Partner Agreement Specific Requirement not Met"
                      );
                      sessionStorage.setItem("queryCategoryCode", "E4");
                      sessionStorage.setItem("queryCategoryname", "error");
                      sessionStorage.setItem("subclaimname", "errore4");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=error&statusCategoryCode=E4&Machine=Availity&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                      );
                    } else {
                      sessionStorage.setItem("querycategory", "Machine2 Error");
                      sessionStorage.setItem(
                        "querysub",
                        "Trading Partner Agreement Specific Requirement not Met"
                      );
                      sessionStorage.setItem("queryCategoryCode", "E4");
                      sessionStorage.setItem("queryCategoryname", "error");
                      sessionStorage.setItem("subclaimname", "errore4");
                      sessionStorage.setItem(
                        "claimurl",
                        `ClaimCategory=error&statusCategoryCode=E4`
                      );
                    }
                  }
                }}
              >
              <div
              class="card-header bg-white">
            <div
            style={{background: this.state.show2 === "E4" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)": "linear-gradient(to right, #002755 65%, #EB1A23)"}}
            >
                  <span class="d-inline" style={{ fontWeight: "bold", color:"white" }}>
                    Trading Partner Agreement Specific Requirement not Met{" "}
                  </span>
                </div>
                  <div class="card-text">
                    <Link
                      to={{
                        pathname: "/claim/sub-category-details",
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Row>
                        <Col className="subclaim-description">
                          Data correction required.
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col className="sub-totalclaim">
                          Total Claims:
                          <span>
                            <div
                              className="to"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                fontSize: "19px",
                              }}
                            >
                              {this.state.tpatotalcnt}
                            </div>
                          </span>
                        </Col>
                        <Col className="sub-total">
                          Grand Total:
                          <span>
                            {" "}
                            <div
                              style={{
                                color: "black",
                                fontSize: "19px",
                                fontWeight: "600",
                              }}
                            >
                              $ {this.state.tpatotalamt}
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Machine2Error;
