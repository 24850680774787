import React, { Component } from 'react'
import Newsidebar from "../Dashboard/Newsidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ip } from "../Apis/Api";
import axios from "axios";
import loading from "../../Images/loader.gif";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { styled } from "@mui/material/styles";
import { Row, Col, Toast, Table } from "reactstrap";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";

export default class Updates extends Component {
    constructor(props) {
        super(props);
        this.state = {
          refreshtoken: sessionStorage.getItem("refreshtoken"),
          accesstoken: sessionStorage.getItem("accesstoken"),
          updatedata: [],
          dataforupdate: null,
          modal: false,
          updatemodal: false,
          deletemodal: false,
          singleid: "",
          offset: 0,
          currentpage: 0,
          perPage: 10,
          createmodal: false,
          medicalbillingdata:"",
          status:"",
          updatemedicalbillingdata:"",
          updatestatus:"",
        };
      }
      toggle = () => {
        const { modal } = this.state;
        this.setState({ modal: !modal });
      };
      // toogle modal for delete
      toggledelete = () => {
        this.setState({ deletemodal: !this.state.deletemodal });
      };
      //toggle modal for update
      toggleupdate = () => {
        this.setState({ updatemodal: !this.state.updatemodal });
      };
      handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
    
        this.setState(
          {
            currentpage: selectedPage,
            offset: offset,
          },
          () => {
            this.getupdatedata();
          }
        );
      };
      renewaccesstoken = async () => {
        const renewheaders = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.state.refreshtoken}`,
        };
        // eslint-disable-next-line
         await axios
          .get(`${ip}/auth/renewAccessToken`, {
            headers: renewheaders,
          })
          .then((renew) => {
            sessionStorage.setItem("accesstoken", renew.data.accessToken);
          })
          .catch((err) => {});
      };
      getupdatedata= async () => {
        //api call to get all users
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.state.accesstoken}`,
        };
        // eslint-disable-next-line
        const res = axios
          .get(`${ip}/medicalbillingupdates/all?pageno=${this.state.currentpage}`, {
            headers: headers,
          })
          .then((res) => {
            this.setState({
              pageCount: Math.ceil(res.data.count/ this.state.perPage),
              updatedata: res.data.medicalbillingupdates,
            });
          })
          .catch((err) => {});
      };
      settingupdatestate = function () {
        this.setState({
          updatemedicalbillingdata:this.state.dataforupdate.medicalbillingupdates,
          updatestatus: this.state.dataforupdate.status,
          singleid: this.state.dataforupdate._id,
        });
      };
      putrequests = async (dataid) => {
          this.setState({ createmodal: !this.state.createmodal });
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.accesstoken}`,
          };
          const value = {
            medicalbillingupdates: this.state.updatemedicalbillingdata,
            status:this.state.updatestatus,
          };
          // eslint-disable-next-line
          const res = await axios
            .put(`${ip}/medicalbillingupdates/${dataid}`, value, {
              headers: headers,
            })
            .then((res) => {
              this.toggleupdate();
              this.getupdatedata();
              this.setState({ createmodal: !this.state.createmodal });
              toast.info("Updated", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              if (err.response) {
                this.getupdatedata();
                this.toggleupdate();
                this.setState({ createmodal: !this.state.createmodal });
                if (err.response.data.statusCode === 500) {
                  toast.error("Server Error", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                  });
                } else if (err.response.data.statusCode === 401) {
                  toast.error("Please reload and try again", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                  });
                } else {
                  toast.error("An unknown error occured", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                  });
                }
              }
            });
        
      };
      deleterequests = async (DataID) => {
        //api call to delete requests
        this.setState({ createmodal: !this.state.createmodal });
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.state.accesstoken}`,
        };
        // eslint-disable-next-line
        const req = axios
          .delete(`${ip}/medicalbillingupdates/${DataID}`, {
            headers: headers,
          })
          .then((req) => {
            this.toggledelete();
            this.getupdatedata();
            this.setState({ createmodal: !this.state.createmodal });
            toast.error("Update deleted", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          })
          .catch((err) => {
            if (err.response) {
              this.getupdatedata();
              this.toggledelete();
              this.setState({ createmodal: !this.state.createmodal });
              if (err.response.data.statusCode === 500) {
                toast.error("Server Error", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  progress: undefined,
                });
              } else if (err.response.data.statusCode === 401) {
                toast.error("Please reload and try again", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  progress: undefined,
                });
              } else {
                toast.error("An unknown error occured", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  progress: undefined,
                });
              }
            }
          });
      };
      handleSubmit = async () => {
          this.setState({ createmodal: !this.state.createmodal });
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.accesstoken}`,
          };
          const value = {
            // physician: this.state.physician,
            status: this.state.status,
            medicalbillingupdates: this.state.medicalbillingdata, 
          };
          // eslint-disable-next-line
          const res = await axios
            .post(`${ip}/medicalbillingupdates/create`, value, {
              headers: headers,
            })
            .then((res) => {
              this.toggle();
              this.getupdatedata();
              this.setState({ createmodal: !this.state.createmodal });
              toast.success("New Update created", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              if (err.response) {
                this.getupdatedata();
                this.toggle();
                this.setState({ createmodal: !this.state.createmodal });
                if (err.response.data.statusCode === 500) {
                  toast.error("Server Error", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                  });
                } else if (err.response.data.statusCode === 401) {
                  toast.error("Please reload and try again", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                  });
                } else {
                  toast.error("An unknown error occured", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                  });
                }
              }
            });
      };
      componentDidMount() {
        
        this.getupdatedata();
      }
  render() {
    return (
      <div>
      <Newsidebar name="Updates" />
      <div className="align-row" style={{overflow:"hidden"}}>
      <div className="align-column">
          <ToastContainer />
      </div>
      <div className="align-row items">
      <button className="addNew" onClick={this.toggle}>
        Add New Update
      </button>
    </div>
    <div class="claimstatus-table">
      <Table striped className="table">
        <thead>
          <tr>
            <td>Medical Billing Data</td>
            <td>Status</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {this.state.updatedata &&
            this.state.updatedata.map((data, index) => (
              <tr key={data._id}>
                <td component="th" scope="row">
                  {data.medicalbillingupdates}
                </td>
                <td component="th" scope="row">
                {data.status}
              </td>
                
                <td>
                  <button
                    className="Edit"
                    onClick={() => {
                      this.setState({ dataforupdate: data }, () => {
                        this.settingupdatestate();
                        this.toggleupdate();
                      });
                    }}
                  >
                    Edit
                  </button>
                  &nbsp;
                  <button
                    className="Delete"
                    onClick={() => {
                      this.setState({ singleid: data._id }, () => {
                        this.toggledelete();
                      });
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
              // ))}
            ))}
        </tbody>
      </Table>
    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
      <ReactPaginate
        previousLabel={<IoIosArrowBack />}
        nextLabel={<IoIosArrowForward />}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.state.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
    </div>
    {this.state.modal && (
        <Modal
          style={{ width: "50%" }}
          isOpen={this.state.modal}
          onHide={this.toggle}
          onExit={this.reset}
          centered
          size="xl"
        >
          <ModalHeader toggle={this.toggle}>Create New Data</ModalHeader>
          <ModalBody
          style={{
            "max-height": "calc(100vh - 210px)",
            "overflow-y": "auto",
          }}
          >
          <Form>
          <Row>
                  <FormGroup>
                    <Label style={{whiteSpace:"nowrap"}}>Medical Billing Data</Label>
                    <Input
                      type="textarea"
                      value={this.state.medicalbillingdata}
                      onChange={(e) =>
                        this.setState({
                            medicalbillingdata: e.target.value,
                        })
                      }
                    >
                    </Input>
                  </FormGroup>
                
             </Row>
             <br/>
             <Row>
             <FormGroup>
               <Label style={{whiteSpace:"nowrap"}}>Status</Label>
               <Input
                 type="select"
                 value={this.state.status}
                 onChange={(e) =>
                   this.setState({
                    status: e.target.value,
                   })
                 }
               >
               <option value={""} hidden>Select...</option>
               <option value={"Active"}>Active</option>
               <option value={"Inactive"}>InActive</option>
               </Input>
             </FormGroup>
           
        </Row>
       <br/>
        </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.toggle();
                this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={this.handleSubmit}
            >
              Create
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {this.state.updatemodal && (
        <Modal
          style={{ width: "60%" }}
          isOpen={this.state.updatemodal}
          onHide={this.toggleupdate}
          onExit={this.reset}
          centered
          size="xl"
        >
          <ModalHeader toggle={this.toggleupdate}>Update Data</ModalHeader>
          <ModalBody
          style={{
            "max-height": "calc(100vh - 210px)",
            "overflow-y": "auto",
          }}
          >
          <Form>
          <Row>
                  <FormGroup>
                    <Label style={{whiteSpace:"nowrap"}}>Medical Billing Data</Label>
                    <Input
                      type="textarea"
                      value={this.state.updatemedicalbillingdata}
                      onChange={(e) =>
                        this.setState({
                            updatemedicalbillingdata: e.target.value,
                        })
                      }
                    >
                    </Input>
                  </FormGroup>
                
             </Row>
             <Row>
             <FormGroup>
               <Label style={{whiteSpace:"nowrap"}}>Status</Label>
               <Input
                 type="select"
                 value={this.state.updatestatus}
                 onChange={(e) =>
                   this.setState({
                    updatestatus: e.target.value,
                   })
                 }
               >
               <option value={""} hidden>Select...</option>
               <option value={"Active"}>Active</option>
               <option value={"Inactive"}>InActive</option>
               </Input>
             </FormGroup>
           
        </Row>
       <br/>
        </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.toggleupdate();
                this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={() => {
                this.putrequests(this.state.singleid);
              }}
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {this.state.deletemodal && (
        <Modal
          isOpen={this.state.deletemodal}
          onHide={this.toggledelete}
          centered
        >
          <ModalHeader toggle={this.toggledelete}>
            Delete Referral Provider
          </ModalHeader>
          <ModalBody
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "auto",
            }}
          >
            <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={this.toggledelete}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={() => {
                this.deleterequests(this.state.singleid);
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Modal isOpen={this.state.createmodal} centered>
      <ModalBody>
        <img
          src={loading}
          style={{ width: "200px", height: "200px", marginLeft: "30%" }}
        />
      </ModalBody>
    </Modal>
      </div>
      </div>
    )
  }
}
