import { React, Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import workgif from "./../../Images/workingdoc.gif";
import GaugeChart from "react-gauge-chart";
import { Row, Col } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import { AiFillInfoCircle } from "react-icons/ai";

import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";
import {
  CircularGaugeComponent,
  AnnotationsDirective,
  PointerDirective,
  AxesDirective,
  Gradient,
  Annotations,
  RangeDirective,
  RangesDirective,
  Inject,
  PointersDirective,
  AxisDirective,
  AnnotationDirective,
} from "@syncfusion/ej2-react-circulargauge";
import { LinearGaugeComponent } from "@syncfusion/ej2-react-lineargauge";
import Tabs, { Tab } from "react-best-tabs";
import {
  ProgressBarAnnotationsDirective,
  ProgressBarAnnotationDirective,
  ProgressAnnotation,
} from "@syncfusion/ej2-react-progressbar";
import LiquidFillGauge from "react-liquid-gauge";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "react-best-tabs/dist/index.css";
import "../../Styles/healthscorecard.css";
import { CircularProgressBar } from "@tomik23/react-circular-progress-bar";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
const data = [
  {
    name: "0-30",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "31-60",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "61-90",
    uv: 2000,
    pv: 8800,
    amt: 2290,
  },
  {
    name: "90-120",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Above 120",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
];
class HealthScorecard extends Component {
  state = {
    role_type: sessionStorage.getItem("role_type"),
    css_col: "",
    css_row: "",
    value: 50,
  };
  getcss = () => {
    if (this.state.role_type === "Team Member") {
      this.setState({ css_col: "align-col-actions" });
      this.setState({ css_row: "align-row-actions" });
    } else {
      this.setState({ css_col: "align-column" });
      this.setState({ css_row: "align-row" });
    }
  };
  componentDidMount() {
    this.getcss();
  }

  render() {
    const radius = 100;
    return (
      <div>
      <Newsidebar name="Industry Standards" />
      <div className={this.state.css_row}>   
        {this.state.role_type === "Team Member" ? (
          <div className={this.state.css_col}>
         

            <div style={{ marginTop: "1%" }}>
              <img src={workgif} alt="Animation" width="700px" height="700px" />
              <h3
                style={{
                  color: "#00008B",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-11%",
                }}
              >
                Great things are coming soon
              </h3>
            </div>
          </div>
        ) : (
          <div className="align-column">
   
            <div
              className="align-row items industry__align"
              style={{ marginTop: "5%", fontFamily: "Poppins" }}
            >
              <Tabs activeTab="1" activityClassName="bg-info">
                <Tab width="100%" title="Denial">
                  <br />
                  <Row style={{ width: "100%" }}>
                    <div class="health-container">
                      <div className="grid-cell-1">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              Initial Zero Paid Denial Rate{" "}
                              <span className="i-icon">
                                <AiFillInfoCircle></AiFillInfoCircle>
                                <span
                                  style={{ left: "6%", top: "5.5%" }}
                                  class="tooltiptext"
                                >
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: "grey",
                                    }}
                                  >
                                    The denial rate represents the percentage of
                                    claims denied by payers during a given
                                    period. This metric quantifies the
                                    effectiveness of your revenue cycle
                                    management processes. A low denial rate
                                    indicates cash flow is healthy, and fewer
                                    staff members are needed to maintain that
                                    cash flow.
                                  </p>
                                </span>
                              </span>{" "}
                            </h6>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingTop: "9%",
                            marginLeft: "25%",
                            width: 250,
                            height: 250,
                            fontWeight: "600",
                          }}
                        >
                          <CircularProgressbar
                            value={77}
                            text={`77%`}
                            background
                            backgroundPadding={6}
                            styles={buildStyles({
                              backgroundColor: "#80ff80",
                              textColor: "#004d00",
                              textSize: "22",
                              fontFamily: "Poppins",
                              pathColor: "#00b300",
                              trailColor: "transparent",
                            })}
                          />
                        </Row>
                        <Row
                          style={{
                            color: "grey",
                            paddingTop: "12%",
                            marginLeft: "30%",
                          }}
                        >
                          {" "}
                          Target Range: &nbsp;
                          <div
                            className="isquare2"
                            style={{
                              width: "74px",
                              height: "24px",
                              background: "rgb(241, 241, 241)",
                              color: "rgb(84, 205, 26)",
                              fontWeight: "bold",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {"<="}4%
                          </div>
                        </Row>
                      </div>

                      <div class="grid-cell-2">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              {" "}
                              Initial Partial Paid Denial Rate{" "}
                              <span className="i-icon">
                                <AiFillInfoCircle></AiFillInfoCircle>
                                <span
                                  style={{ left: "56%", top: "5.5%" }}
                                  class="tooltiptext"
                                >
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: "grey",
                                    }}
                                  >
                                    The denial rate represents the percentage of
                                    claims denied by payers during a given
                                    period. This metric quantifies the
                                    effectiveness of your revenue cycle
                                    management processes. A low denial rate
                                    indicates cash flow is healthy, and fewer
                                    staff members are needed to maintain that
                                    cash flow.
                                  </p>
                                </span>
                              </span>
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ marginTop: "-1%", fontWeight: "600" }}>
                            <CircularGaugeComponent
                              id="partial-paid"
                              width="400"
                              height="370"
                            >
                              <Inject services={[Annotations]} />
                              <AxesDirective>
                                <AxisDirective
                                  radius="80%"
                                  minimum={0}
                                  maximum={100}
                                  majorTicks={{
                                    width: 0,
                                    interval: 10,
                                  }}
                                  lineStyle={{ width: 0, color: "transparent" }}
                                  minorTicks={{
                                    width: 0,
                                  }}
                                  labelStyle={{
                                    font: {
                                      size: "12px",
                                      fontFamily: "Poppins",
                                      fontWeight: "Regular",
                                    },
                                    offset: 10,
                                  }}
                                >
                                  <RangesDirective>
                                    <RangeDirective
                                      start={0}
                                      end={15}
                                      radius="90%"
                                      color="#15616d"
                                      opacity={1}
                                    ></RangeDirective>
                                    <RangeDirective
                                      start={16}
                                      end={25}
                                      radius="90%"
                                      color="#78290f"
                                      opacity={1}
                                    ></RangeDirective>
                                    <RangeDirective
                                      start={26}
                                      end={100}
                                      radius="90%"
                                      color=" #ff7d00"
                                      opacity={1}
                                    ></RangeDirective>
                                  </RangesDirective>
                                  <PointersDirective>
                                    <PointerDirective
                                      value={25}
                                      type="Marker"
                                      markerShape="InvertedTriangle"
                                      radius="110%"
                                      markerHeight={20}
                                      color="#001524"
                                      markerWidth={20}
                                    />
                                  </PointersDirective>
                                  <AnnotationsDirective>
                                    <AnnotationDirective
                                      content='<div id="content" style="color:#3d1010;font-size:28px;font-family:Poppins; font-weight:semibold;">25%</div>'
                                      angle={90}
                                      zIndex="none"
                                      radius="0"
                                    ></AnnotationDirective>
                                  </AnnotationsDirective>
                                </AxisDirective>
                              </AxesDirective>
                            </CircularGaugeComponent>
                          </Col>
                          <Col style={{ paddingTop: "14%" }}>
                            <Row style={{ color: "grey" }}>
                              Good: &nbsp;&nbsp;
                              <span
                                className="isquare2"
                                style={{
                                  width: "80px",
                                  height: "24px",
                                  background: "#eafaf9",
                                  color: "#229089",
                                  fontWeight: "bold",
                                  borderRadius: "7px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                5-15%
                              </span>
                            </Row>
                            <Row style={{ color: "grey", paddingTop: "10%" }}>
                              Average: &nbsp; &nbsp;
                              <span
                                style={{
                                  width: "80px",
                                  height: "24px",
                                  background: "#fde8ea",
                                  color: "#d31225",
                                  fontWeight: "bold",
                                  borderRadius: "7px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                16-25%
                              </span>
                            </Row>
                            <Row style={{ color: "grey", paddingTop: "10%" }}>
                              Poor: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              &nbsp;&nbsp;
                              <span
                                style={{
                                  width: "80px",
                                  height: "24px",
                                  background: "#fff4e5",
                                  color: "#ff9e1a",
                                  fontWeight: "bold",
                                  borderRadius: "7px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {">"} 26%
                              </span>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      <div
                        class="grid-cell-3"
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "22px",
                          textAlign: "center",
                          background: "rgb(5, 101, 191)",
                          paddingTop: "50%",
                        }}
                      >
                        <div>% of Denial or Denial Amount By Age ​</div>
                      </div>
                      <div class="grid-cell-4">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                                color: "black",
                              }}
                            >
                              Total Denial <span> % </span>as a <span> % </span>
                              of Net Revenue
                              {/* <span className="i-icon" ><AiFillInfoCircle></AiFillInfoCircle><span style={{ top:'25%' }} class="tooltiptext" >
                        
                        <p style={{ fontSize:'13px', fontWeight:'500', color:'grey', }}>
                  
                        </p>
  </span></span> */}
                            </h6>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: "8%", fontWeight: "600" }}>
                          <CircularGaugeComponent
                            id="total-denial"
                            width="350"
                            height="350"
                            color="#e63462"
                          >
                            <AxesDirective>
                              <AxisDirective
                                labelStyle={{
                                  font: {
                                    size: "12px",
                                    fontFamily: "Poppins",
                                    fontWeight: "Regular",
                                  },
                                }}
                              >
                                <PointersDirective>
                                  <PointerDirective
                                    value={90}
                                    type="RangeBar"
                                    color="#99154E"
                                    radius="60%"
                                    pointerWidth={10}
                                  ></PointerDirective>
                                  <PointerDirective
                                    value={90}
                                    radius="70%"
                                    cap={{
                                      radius: 15,
                                      color: "white",
                                      border: {
                                        color: "#99154E",
                                        width: 5,
                                      },
                                    }}
                                    needleTail={{
                                      length: "22%",
                                      color: "#99154E",
                                    }}
                                    color="#99154E"
                                    pointerWidth={25}
                                  ></PointerDirective>
                                </PointersDirective>
                              </AxisDirective>
                            </AxesDirective>
                          </CircularGaugeComponent>
                        </Row>
                        <Row
                          style={{
                            color: "grey",
                            paddingTop: "8%",
                            textAlign: "center",
                            marginLeft: "33%",
                          }}
                        >
                          {" "}
                          Target Range: &nbsp;
                          <div
                            style={{
                              width: "74px",
                              height: "24px",
                              background: "#ffe5eb",
                              color: "red",
                              fontWeight: "bold",
                              borderRadius: "7px",
                              justifyContent: "center",
                            }}
                          >
                            {"<"}8%
                          </div>
                        </Row>
                      </div>
                      <div class="grid-cell-5">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              Denial by CPT and DRG{" "}
                              <span className="i-icon">
                                <AiFillInfoCircle></AiFillInfoCircle>
                                <span
                                  style={{ top: "25%", right: "2%" }}
                                  class="tooltiptext"
                                >
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: "grey",
                                    }}
                                  >
                                    Total number of zero pay claims received
                                    from 3rd party payers with a denial code on
                                    the remittance advice.{" "}
                                  </p>
                                </span>
                              </span>{" "}
                            </h6>
                          </Col>
                        </Row>
                        <Row style={{ fontWeight: "600" }}>
                          <LinearGaugeComponent
                            id="cpt-drg"
                            container={{
                              width: 30,
                              type: "Thermometer",
                              roundedCornerRadius: 9,
                            }}
                          >
                            <AxesDirective>
                              <AxisDirective
                                minimum={0}
                                maximum={100}
                                line={{ width: 0 }}
                                minorTicks={{ color: "#9e9e9e" }}
                                majorTicks={{ interval: 20, color: "#9e9e9e" }}
                                labelStyle={{
                                  font: {
                                    size: "12px",
                                    fontFamily: "Poppins",
                                    fontWeight: "Regular",
                                  },
                                }}
                              >
                                <PointersDirective>
                                  <PointerDirective
                                    value={50}
                                    height={13}
                                    width={13}
                                    type="Bar"
                                    roundedCornerRadius={9}
                                    color="#9A0680"
                                  ></PointerDirective>
                                </PointersDirective>
                              </AxisDirective>
                              <AxisDirective
                                minimum={0}
                                maximum={100}
                                opposedPosition={true}
                                line={{ width: 0 }}
                                majorTicks={{ interval: 20 }}
                                labelStyle={{
                                  font: {
                                    size: "12px",
                                    fontFamily: "Poppins",
                                    fontWeight: "Regular",
                                  },
                                }}
                              >
                                <PointersDirective>
                                  <PointerDirective
                                    width={0}
                                  ></PointerDirective>
                                </PointersDirective>
                              </AxisDirective>
                            </AxesDirective>
                          </LinearGaugeComponent>
                        </Row>
                        <Row style={{ color: "grey", marginLeft: "23%" }}>
                          {" "}
                          Target Range: &nbsp;
                          <div
                            className="isquare2"
                            style={{
                              width: "74px",
                              height: "24px",
                              background: "rgb(241, 241, 241)",
                              color: "purple",
                              fontWeight: "bold",
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            {"<="}4%
                          </div>
                        </Row>
                      </div>
                      <div class="grid-cell-6">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              % Denial Due To TFL
                              {/* <span className="i-icon" ><AiFillInfoCircle></AiFillInfoCircle><span style={{ top:'48%', }} class="tooltiptext-tfl">
 <p style={{ fontSize:'13px', fontWeight:'500', color:'grey', }}>
                        Should include all appealed claims (in response to a
denial or take-back) that were closed/finalized within
the month due to a receipt of payment.


                        </p>
  </span></span>  */}
                            </h6>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingTop: "5%",
                            alignContent: "center",
                            fontWeight: "600",
                          }}
                        >
                          <ProgressBarComponent
                            id="pie-1"
                            type="Circular"
                            width="280px"
                            height="280px"
                            value={70}
                            enablePieProgress={true}
                            animation={{
                              enable: true,
                              duration: 2000,
                              delay: 0,
                            }}
                          ></ProgressBarComponent>
                          <Inject services={[ProgressAnnotation]} />
                          <ProgressBarAnnotationsDirective>
                            <ProgressBarAnnotationDirective content='<div id="content-1" style="color:#3d1010;font-size:28px;font-family:Poppins; font-weight:semibold;">25%</div>'></ProgressBarAnnotationDirective>
                          </ProgressBarAnnotationsDirective>
                        </Row>

                        <Row
                          style={{
                            color: "grey",
                            paddingTop: "10%",
                            marginLeft: "30%",
                          }}
                        >
                          {" "}
                          Target Range: &nbsp;
                          <div
                            className="isquare2"
                            style={{
                              width: "74px",
                              height: "24px",
                              background: "rgb(241, 241, 241)",
                              color: "blue",
                              fontWeight: "bold",
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            {"<"}1%
                          </div>
                        </Row>
                      </div>
                      <div class="grid-cell-7">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              Overturned Denial Rate/Denial Recovery rate{" "}
                              <span className="i-icon">
                                <AiFillInfoCircle></AiFillInfoCircle>
                                <span
                                  style={{ left: "56%", top: "48%" }}
                                  class="tooltiptext"
                                >
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: "grey",
                                    }}
                                  >
                                    Should include all appealed claims (in
                                    response to a denial or take-back) that were
                                    closed/finalized within the month due to a
                                    receipt of payment.
                                  </p>
                                </span>
                              </span>
                            </h6>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: "1%", fontWeight: "600" }}>
                          <LinearGaugeComponent
                            id="overtuned-denial"
                            orientation="Horizontal"
                            width=""
                            height="350"
                          >
                            <AxesDirective>
                              <AxisDirective
                                minimum={0}
                                maximum={100}
                                line={{ width: 0 }}
                                minorTicks={{
                                  height: 7,
                                  width: 0,
                                  interval: 4,
                                }}
                                majorTicks={{
                                  height: 0,
                                  width: 0,
                                  interval: 20,
                                }}
                                labelStyle={{
                                  position: "Outside",
                                  font: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    fontWeight: "Regular",
                                    color: "black",
                                  },
                                  offset: 4,
                                }}
                              >
                                <RangesDirective>
                                  <RangeDirective
                                    start={0}
                                    end={20}
                                    startWidth={15}
                                    endWidth={25}
                                    color="#82b944"
                                  ></RangeDirective>
                                  <RangeDirective
                                    start={20}
                                    end={40}
                                    startWidth={25}
                                    endWidth={35}
                                    color="#a1cb43"
                                  ></RangeDirective>
                                  <RangeDirective
                                    start={40}
                                    end={60}
                                    startWidth={35}
                                    endWidth={45}
                                    color="#ddec12"
                                  ></RangeDirective>
                                  <RangeDirective
                                    start={60}
                                    end={80}
                                    startWidth={45}
                                    endWidth={55}
                                    color="#ffbc00"
                                  ></RangeDirective>
                                  <RangeDirective
                                    start={80}
                                    end={100}
                                    startWidth={55}
                                    endWidth={65}
                                    color="#ff6000"
                                  ></RangeDirective>
                                </RangesDirective>

                                <PointersDirective>
                                  <PointerDirective
                                    value={90}
                                    height={25}
                                    width={35}
                                    offset={-55}
                                    markerType="Triangle"
                                    animation={{
                                      enable: true,
                                      duration: "1500",
                                    }}
                                    border={{ width: 1, color: "white" }}
                                  ></PointerDirective>
                                </PointersDirective>
                              </AxisDirective>
                            </AxesDirective>
                          </LinearGaugeComponent>
                        </Row>

                        <Row style={{ color: "grey", marginLeft: "38%" }}>
                          {" "}
                          Target Range: &nbsp;
                          <div
                            className="isquare2"
                            style={{
                              width: "90px",
                              height: "24px",
                              background: "rgb(241, 241, 241)",
                              color: "purple",
                              fontWeight: "bold",
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            40-60%
                          </div>
                        </Row>
                      </div>
                      <div class="grid-cell-8">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              Average Outstanding by Denial Class&nbsp;
                              <span className="i-icon">
                                <AiFillInfoCircle></AiFillInfoCircle>
                                <span
                                  style={{ left: "20%", top: "68%" }}
                                  class="tooltiptext"
                                >
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: "grey",
                                    }}
                                  >
                                    It is a metrics to analyze the denial amount
                                    by categories of denial. Helps denial to
                                    make right strategies.
                                  </p>
                                </span>
                              </span>
                            </h6>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: "7%", fontWeight: "600" }}>
                          <LinearGaugeComponent
                            style={{ height: "150px" }}
                            id="gauge3"
                            orientation="Horizontal"
                          >
                            <AxesDirective>
                              <AxisDirective
                                majorTicks={{ interval: 10, color: "#9E9E9E" }}
                                labelStyle={{
                                  font: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    fontWeight: "Regular",
                                    color: "black",
                                  },
                                  offset: 4,
                                }}
                                minorTicks={{ color: "#9E9E9E" }}
                                line={{ offset: -20, color: "#9E9E9E" }}
                              >
                                <PointersDirective>
                                  <PointerDirective
                                    value={70}
                                    offset={30}
                                    height={16}
                                    width={16}
                                  ></PointerDirective>
                                  <PointerDirective
                                    value={70}
                                    type="Bar"
                                    height={20}
                                    color="#FE7E6D"
                                  ></PointerDirective>
                                </PointersDirective>
                              </AxisDirective>
                            </AxesDirective>
                          </LinearGaugeComponent>
                        </Row>
                        <br />
                      </div>
                      <div
                        class="grid-cell-9"
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "23px",
                          textAlign: "center",
                          background: "rgb(255, 99, 132)",
                          paddingTop: "31%",
                        }}
                      >
                        <div>% of Denial Due to Lack of Document ​</div>
                      </div>
                      <div class="grid-cell-10">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              Appeal on Denial{" "}
                            </h6>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: "30%", fontWeight: "600" }}>
                          <GaugeChart
                            id="gauge-chart3"
                            nrOfLevels={30}
                            textColor={"black"}
                            colors={["rgb(5, 101, 191)", "rgb(248, 92, 92)"]}
                            arcWidth={0.3}
                            percent={0.78}
                          />
                        </Row>
                      </div>
                      <div class="grid-cell-11">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              UpCoding Denial{" "}
                            </h6>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingTop: "7%",
                            fontWeight: "600",
                            width: "450px",
                            height: "450px",
                            marginLeft: "12%",
                          }}
                        >
                          <CircularGaugeComponent id="up-coding">
                            <Inject services={[Annotations]} />
                            <AxesDirective>
                              <AxisDirective
                                radius="90%"
                                startAngle={270}
                                endAngle={90}
                                minimum={0}
                                maximum={100}
                                lineStyle={{ width: 0 }}
                                labelStyle={{
                                  font: {
                                    size: "13px",
                                    fontWeight: "Regular",
                                    fontFamily: "Poppins",
                                  },
                                  position: "Outside",
                                  autoAngle: true,
                                  useRangeColor: false,
                                }}
                                majorTicks={{ height: 0 }}
                                minorTicks={{ height: 0 }}
                              >
                                <PointersDirective>
                                  <PointerDirective
                                    animation={{ enable: true, duration: 900 }}
                                    value={60}
                                    radius="80%"
                                    color="#8f3985"
                                    fontFamily="Poppins"
                                    pointerWidth={7}
                                    cap={{
                                      radius: 8,

                                      color: "#8f3985",
                                      border: { width: 0 },
                                    }}
                                    needleTail={{
                                      color: "#8f3985",
                                      length: "15%",
                                    }}
                                  />
                                </PointersDirective>
                                <AnnotationsDirective
                                  style={{ fontWeight: "600" }}
                                >
                                  <AnnotationDirective
                                    content='<div style="width:90px;text-align:center;font-size:20px; color:#273815; font-family:Poppins">${pointers[0].value}%</div>'
                                    angle={0}
                                    zIndex="none"
                                    radius="-25%"
                                  />
                                </AnnotationsDirective>
                                <RangesDirective>
                                  <RangeDirective
                                    start={0}
                                    end={100}
                                    startWidth={5}
                                    endWidth={20}
                                    radius="102%"
                                    color="#82b944"
                                  />
                                </RangesDirective>
                              </AxisDirective>
                            </AxesDirective>
                          </CircularGaugeComponent>
                        </Row>
                      </div>
                      <div class="grid-cell-12">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              Down coding Denial{" "}
                            </h6>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingTop: "20%",
                            marginLeft: "16%",
                            fontWeight: "600",
                          }}
                        >
                          <CircularProgressBar
                            percent={75}
                            round
                            stroke={3}
                            strokeBottom={10}
                            strokeDasharray="10, 1"
                            style={{ width: "100%" }}
                          />
                        </Row>
                      </div>
                    </div>
                  </Row>
                </Tab>

                <Tab width="100%" title="AR Management">
                  <br />
                  <Row style={{ width: "100%" }}>
                    <div className="ar-container">
                      <div className="ar-cell1">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              {" "}
                              A/R OVER 90 DAYS &nbsp;
                              <span className="i-icon">
                                <AiFillInfoCircle></AiFillInfoCircle>
                                <span
                                  style={{ top: "6%", left: "0%" }}
                                  class="tooltiptext"
                                >
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: "grey",
                                    }}
                                  >
                                    A/R{">"}90 days is a clear indicator of how
                                    effective your practice is at securing
                                    reimbursements in a timely manner. High or
                                    rising percentages are red flags alerting
                                    you of issues with your practice's revenue
                                    cycle management (e.g., your staff may not
                                    be acting quickly enough on denials or aged
                                    claims) that need to be be addressed
                                    promptly.
                                  </p>
                                </span>
                              </span>
                            </h6>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingTop: "9%",
                            width: 350,
                            marginLeft: "15.5%",
                            height: 230,
                            fontWeight: "600",
                          }}
                        >
                          <div>
                            <LiquidFillGauge
                              width={200}
                              height={200}
                              value={50}
                              percent="%"
                              textSize={1}
                              riseAnimation
                              waveAnimation
                              waveFrequency={2}
                              waveAmplitude={1}
                            />
                          </div>
                        </Row>
                        <Row
                          style={{
                            color: "grey",
                            paddingTop: "7%",
                            marginLeft: "19.5%",
                          }}
                        >
                          {" "}
                          Target Range: &nbsp;
                          <div
                            className="isquare2"
                            style={{
                              width: "74px",
                              height: "24px",
                              background: "rgb(241, 241, 241)",
                              color: "blue",
                              fontWeight: "bold",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {"<"}20%
                          </div>
                        </Row>
                      </div>
                      <div className="ar-cell2">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              % of A/R OVER 120 DAYS &nbsp;
                              <span className="i-icon">
                                <AiFillInfoCircle></AiFillInfoCircle>
                                <span
                                  style={{ top: "6%", left: "35%" }}
                                  class="tooltiptext"
                                >
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: "grey",
                                    }}
                                  >
                                    A/R {">"}120 days is a clear indicator of
                                    how effective your practice is at securing
                                    reimbursements in a timely manner. High or
                                    rising percentages are red flags alerting
                                    you of issues with your practice’s revenue
                                    cycle management (e.g., your staff may not
                                    be acting quickly enough on denials or aged
                                    claims) that need to be addressed promptly.
                                  </p>
                                </span>
                              </span>{" "}
                            </h6>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: "3%", fontWeight: "600" }}>
                          <CircularGaugeComponent
                            id="pointer-gauge"
                            width="260"
                            height="260"
                            color="rgb(221, 161, 59)"
                          >
                            <Inject services={[Annotations]} />
                            <AxesDirective>
                              <AxisDirective
                                labelStyle={{
                                  font: {
                                    size: "12px",
                                    fontFamily: "Poppins",
                                    fontWeight: "Regular",
                                  },
                                }}
                              >
                                {/* <AnnotationsDirective>
                       
       <AnnotationDirective content='<div id="content" style="color:#424242;font-size:23px;font-family:Poppins; font-weight:semibold;">90%</div>'
                                        angle={130} radius='50%' zIndex='2'>
           </AnnotationDirective>
          
          </AnnotationsDirective>                */}
                                <PointersDirective>
                                  <PointerDirective
                                    value={90}
                                    type="Marker"
                                    markerShape="InvertedTriangle"
                                    color="rgb(71, 158, 106)"
                                    radius="100%"
                                    markerHeight={15}
                                    markerWidth={15}
                                  ></PointerDirective>
                                  <PointerDirective
                                    value={90}
                                    type="Marker"
                                    markerShape="InvertedTriangle"
                                    radius="10%"
                                    markerHeight={15}
                                    markerWidth={15}
                                  ></PointerDirective>
                                  <PointerDirective
                                    value={90}
                                    type="RangeBar"
                                    color="rgb(71, 158, 106)"
                                    radius="60%"
                                    pointerWidth={10}
                                  ></PointerDirective>
                                  <PointerDirective
                                    value={90}
                                    radius="50%"
                                    cap={{
                                      radius: 15,
                                      color: "white",
                                      border: {
                                        color: "rgb(71, 158, 106)",
                                        width: 5,
                                      },
                                    }}
                                    needleTail={{
                                      length: "22%",
                                      color: "black",
                                    }}
                                    color="rgb(71, 158, 106)"
                                    pointerWidth={25}
                                  ></PointerDirective>
                                </PointersDirective>
                              </AxisDirective>
                            </AxesDirective>
                          </CircularGaugeComponent>
                        </Row>
                        <Row
                          style={{
                            color: "grey",
                            paddingTop: "6%",
                            marginLeft: "30%",
                          }}
                        >
                          {" "}
                          Target Range: &nbsp;
                          <div
                            className="isquare2"
                            style={{
                              width: "75px",
                              height: "24px",
                              background: "#edf7f1",
                              color: "#28583b",
                              fontWeight: "bold",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            10-12%
                          </div>
                        </Row>
                      </div>
                      <div classname="ar-cell3">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              A/R OVER 1 YEAR &nbsp;
                              <span className="i-icon">
                                <AiFillInfoCircle></AiFillInfoCircle>
                                <span
                                  style={{ left: "70%", top: "6%" }}
                                  class="tooltiptext"
                                >
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: "grey",
                                    }}
                                  >
                                    A/R {">"}365days is a clear indicator of how
                                    effective your practice is at securing
                                    reimbursements in a timely manner. High or
                                    rising percentages are red flags alerting
                                    you of issues with your practice’s revenue
                                    cycle management (e.g., your staff may not
                                    be acting quickly enough on denials or aged
                                    claims) that need to be addressed promptly.
                                  </p>
                                </span>
                              </span>{" "}
                            </h6>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingTop: "9%",
                            width: "70%",
                            height: "70%",
                            marginLeft: "17%",
                            fontWeight: "600",
                          }}
                        >
                          <div>
                            <CircularProgressbar
                              value={60}
                              text={`60%`}
                              width={700}
                              height={250}
                              // circleRatio={0.75}
                              styles={buildStyles({
                                rotation: 1 / 2 + 1 / 8,
                                textColor: "rgb(77, 0, 76)",
                                strokeLinecap: "butt",
                                trailColor: "grey",
                                pathColor: "rgb(105,43,84)",
                              })}
                            />
                          </div>
                        </Row>
                        <Row
                          style={{
                            color: "grey",
                            paddingTop: "6%",
                            marginLeft: "21%",
                          }}
                        >
                          {" "}
                          Target Range: &nbsp;
                          <div
                            className="isquare2"
                            style={{
                              width: "74px",
                              height: "24px",
                              background: "#f8edf4",
                              color: "purple",
                              fontWeight: "bold",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {"<"}2%
                          </div>
                        </Row>
                      </div>
                      <div className="ar-cell4">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              AGEING
                            </h6>
                          </Col>
                        </Row>
                        <Row style={{ marginLeft: "1%", fontWeight: "600" }}>
                          {/* <ResponsiveContainer width="100%" height="100%"> */}
                          <ComposedChart width={550} height={420} data={data}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            {/* <Legend /> */}
                            {/* <CartesianGrid stroke="#f5f5f5" /> */}
                            <Area
                              type="monotone"
                              dataKey="amt"
                              fill="#8884d8"
                              stroke="#8884d8"
                            />
                            <Bar dataKey="pv" barSize={20} fill="#413ea0" />
                            <Line
                              type="monotone"
                              dataKey="uv"
                              stroke="#ff7300"
                            />
                          </ComposedChart>
                          {/* </ResponsiveContainer> */}
                        </Row>
                      </div>
                      <div className="ar-cell5">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              HIGH-DOLLAR UNPAID ACCOUNTS WORKED &nbsp;
                              <span className="i-icon">
                                <AiFillInfoCircle></AiFillInfoCircle>
                                <span
                                  style={{
                                    top: "28%",
                                    float: "left",
                                    right: "14%",
                                  }}
                                  class="tooltiptext"
                                >
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: "grey",
                                    }}
                                  >
                                    This metrics help operation team to
                                    strategize their daily production and
                                    control the movement of high unpaid claims
                                    from lower age bucket to higher age buckets.
                                    Hence resolve the high unpaid claim first
                                    and it should not cross the best practice of
                                    the given target
                                  </p>
                                </span>
                              </span>{" "}
                            </h6>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingTop: "3%",
                            marginLeft: "1%",
                            fontWeight: "600",
                          }}
                        >
                          <CircularGaugeComponent
                            id="gauge-gradient"
                            width="470"
                            height="450"
                            ref={(circulargauge) =>
                              (this.circulargauge = circulargauge)
                            }
                          >
                            <Inject services={([Gradient], [Annotations])} />
                            <AxesDirective>
                              <AxisDirective
                                radius="80%"
                                startAngle={210}
                                endAngle={150}
                                minimum={0}
                                maximum={100}
                                majorTicks={{
                                  width: 0,
                                  interval: 10,
                                }}
                                lineStyle={{ width: 0, color: "transparent" }}
                                minorTicks={{
                                  width: 0,
                                }}
                                labelStyle={{
                                  font: {
                                    size: "12px",
                                    fontFamily: "Poppins",
                                    fontWeight: "Regular",
                                  },
                                  offset: 10,
                                }}
                              >
                                <PointersDirective>
                                  <PointerDirective
                                    value={65}
                                    radius="85%"
                                    color="#E63B86"
                                    pointerWidth={12}
                                    cap={{
                                      radius: 12,
                                      border: { color: "#E63B86", width: 2.5 },
                                      color: "white",
                                    }}
                                    needleTail={{ length: "18%" }}
                                    needleStartWidth={3}
                                    animation={{
                                      enable: true,
                                      duration: "1500",
                                    }}
                                  ></PointerDirective>
                                </PointersDirective>
                                <RangesDirective>
                                  <RangeDirective
                                    start={0}
                                    end={120}
                                    startWidth={18}
                                    endWidth={18}
                                    color={"#E63B86"}
                                    linearGradient={{
                                      startValue: "100%",
                                      endValue: "0%",
                                      colorStop: [
                                        {
                                          color: "#9E40DC",
                                          offset: "0%",
                                          opacity: 0.9,
                                        },
                                        {
                                          color: "#E63B86",
                                          offset: "70%",
                                          opacity: 0.9,
                                        },
                                      ],
                                    }}
                                    roundedCornerRadius={10}
                                  />
                                </RangesDirective>
                                <AnnotationsDirective>
                                  <AnnotationDirective
                                    content='<div id="content" style="color:#3d1010;font-size:28px;font-family:Poppins; font-weight:semibold;">65%</div>'
                                    angle={-40}
                                    radius="-70%"
                                    zIndex="none"
                                  ></AnnotationDirective>
                                </AnnotationsDirective>
                              </AxisDirective>
                            </AxesDirective>
                          </CircularGaugeComponent>
                        </Row>
                        <Row
                          style={{
                            color: "grey",
                            paddingTop: "3%",
                            marginLeft: "34%",
                          }}
                        >
                          {" "}
                          Target Range: &nbsp;
                          <div
                            className="isquare2"
                            style={{
                              width: "75px",
                              height: "27px",
                              background: "#fce8f1",
                              color: "#E63B86",
                              fontWeight: "bold",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {"<"}5%
                          </div>
                        </Row>
                      </div>
                      <div classname="ar-cell6">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              LOW-DOLLAR UNPAID ACCOUNTS WORKED
                            </h6>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingTop: "9%",
                            marginLeft: "1%",
                            fontWeight: "600",
                          }}
                        >
                          <div>
                            <CircularGaugeComponent
                              id="color"
                              width="300"
                              height="300"
                            >
                              <Inject services={[Annotations]} />
                              <AxesDirective>
                                <AxisDirective>
                                  <AnnotationsDirective>
                                    <AnnotationDirective
                                      content='<div id="content" style="color:#3d1010;font-size:28px;font-family:Poppins; font-weight:semibold;">80%</div>'
                                      angle={0}
                                      radius="0%"
                                      zIndex="none"
                                    ></AnnotationDirective>
                                  </AnnotationsDirective>
                                  <PointersDirective>
                                    <PointerDirective
                                      value={80}
                                      type="RangeBar"
                                      pointerWidth={15}
                                      radius="65%"
                                      color="green"
                                      border={{
                                        width: 0,
                                        color: "transparent",
                                      }}
                                      animation={{ enable: false }}
                                      type="RangeBar"
                                      roundedCornerRadius={10}
                                      cap={{
                                        radius: 0,
                                        border: {
                                          width: 0,
                                        },
                                      }}
                                    ></PointerDirective>
                                  </PointersDirective>
                                </AxisDirective>
                              </AxesDirective>
                            </CircularGaugeComponent>
                          </div>
                        </Row>
                        <Row
                          style={{
                            color: "grey",
                            paddingTop: "6%",
                            marginLeft: "21%",
                          }}
                        >
                          {" "}
                          Target Range: &nbsp;
                          <div
                            className="isquare2"
                            style={{
                              width: "74px",
                              height: "24px",
                              background: "#e5ffe5",
                              color: "green",
                              fontWeight: "bold",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {"<"}10%
                          </div>
                        </Row>
                      </div>
                      <div className="ar-cell7">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              AVERAGE # OF TOUCHES PER CLAIMS (BY AGE AND
                              CHARGED BUCKETS) &nbsp;
                              <span className="i-icon">
                                <AiFillInfoCircle></AiFillInfoCircle>
                                <span
                                  style={{ top: "57.5%", left: "33%" }}
                                  class="tooltiptext"
                                >
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      color: "grey",
                                    }}
                                  >
                                    It is an average # of touches to resolve the
                                    claims.
                                  </p>
                                </span>
                              </span>
                            </h6>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            marginLeft: "24%",
                            paddingTop: "8%",
                            width: "40%",
                            paddingTop: "12%",
                            height: "50",
                            fontWeight: "600",
                          }}
                        >
                          <CircularProgressbar
                            value={60}
                            text={`#60`}
                            // circleRatio={0.75}
                            styles={buildStyles({
                              rotation: 1 / 2 + 1 / 8,
                              strokeLinecap: "butt",
                              textColor: "#160f04",
                              trailColor: "#eee",
                              pathColor: "rgb(221, 161, 59)",
                            })}
                          />
                        </Row>
                      </div>
                      <div className="ar-cell8">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              DENIAL WRITE-OFFS AS % OF GPR
                            </h6>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            marginLeft: "1%",
                            paddingTop: "7%",
                            fontWeight: "600",
                          }}
                        >
                          <CircularGaugeComponent
                            width="330"
                            height="330"
                            id="pointer2-container"
                          >
                            <Inject services={[Annotations]} />
                            <AxesDirective>
                              <AxisDirective
                                startAngle={0}
                                endAngle={0}
                                radius="90%"
                                minimum={0}
                                maximum={100}
                                lineStyle={{
                                  width: 8,
                                  color: "#3A5DC8",
                                }}
                                majorTicks={{
                                  color: "white",
                                  offset: -8,
                                  height: 18,
                                }}
                                minorTicks={{
                                  width: 0,
                                }}
                                labelStyle={{
                                  position: "Outside",
                                  useRangeColor: true,
                                  font: {
                                    color: "#3A5DC8",
                                    size: "14px",
                                    fontFamily: "Roboto",
                                    fontWeight: "Regular",
                                  },
                                }}
                              >
                                <PointersDirective>
                                  <PointerDirective
                                    value={66}
                                    radius="90%"
                                    color="#33BCBD"
                                    type="RangeBar"
                                    pointerWidth={10}
                                  />
                                  <PointerDirective
                                    value={66}
                                    radius="70%"
                                    cap={{
                                      radius: 10,
                                      border: { width: 5, color: "#33BCBD" },
                                    }}
                                    needleTail={{
                                      length: "0%",
                                      color: "#33BCBD",
                                    }}
                                    color="#33BCBD"
                                  />
                                </PointersDirective>
                                <AnnotationsDirective>
                                  <AnnotationDirective
                                    content='<div style="color:black; font-family:Roboto; font-size:25px;">66%</div>'
                                    angle={180}
                                    zIndex="none"
                                    radius="-27%"
                                  ></AnnotationDirective>
                                </AnnotationsDirective>
                              </AxisDirective>
                            </AxesDirective>
                          </CircularGaugeComponent>
                        </Row>
                      </div>
                      <div className="ar-cell9">
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              % OF COLLECTIONS FROM WORKED CLAIMS
                            </h6>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: "12%", fontWeight: "600" }}>
                          <CircularGaugeComponent
                            width="300"
                            height="300"
                            id="pointer1-container"
                          >
                            <Inject services={[Annotations]} />
                            <AxesDirective>
                              <AxisDirective
                                startAngle={270}
                                endAngle={90}
                                radius="90%"
                                minimum={0}
                                maximum={100}
                                lineStyle={{
                                  width: 0,
                                }}
                                majorTicks={{
                                  width: 1,
                                  height: 20,
                                  interval: 10,
                                  position: "Outside",
                                  useRangeColor: true,
                                }}
                                minorTicks={{
                                  width: 1,
                                  height: 8,
                                  interval: 2,
                                  position: "Outside",
                                  useRangeColor: true,
                                }}
                                labelStyle={{
                                  useRangeColor: true,
                                  position: "Outside",
                                  font: {
                                    size: "12px",
                                    fontFamily: "Roboto",
                                    fontWeight: "Regular",
                                  },
                                }}
                              >
                                <PointersDirective>
                                  <PointerDirective
                                    value={66}
                                    radius="90%"
                                    color="#F8A197"
                                    type="RangeBar"
                                    pointerWidth={13}
                                  />
                                </PointersDirective>
                                <AnnotationsDirective>
                                  <AnnotationDirective
                                    content='<div style="color:black; font-family:Roboto; font-size:27px;">66%</div>'
                                    angle={80}
                                    zIndex="none"
                                    radius="20%"
                                  ></AnnotationDirective>
                                </AnnotationsDirective>
                              </AxisDirective>
                            </AxesDirective>
                          </CircularGaugeComponent>
                        </Row>
                      </div>
                      <div className="ar-cell10">
                        {" "}
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              AR RESOLUTION RATE
                            </h6>
                          </Col>
                        </Row>
                        <Row style={{ fontWeight: "600" }}>
                          <CircularGaugeComponent
                            id="average-gauge"
                            width="400"
                            height="400"
                          >
                            <Inject services={[Annotations]} />
                            <AxesDirective>
                              <AxisDirective
                                radius="80%"
                                startAngle={0}
                                endAngle={0}
                                direction={"AntiClockWise"}
                                majorTicks={{
                                  width: 1,
                                  height: 15,
                                  interval: 10,
                                  position: "Outside",
                                  useRangeColor: true,
                                }}
                                lineStyle={{ width: 0 }}
                                minorTicks={{
                                  width: 1,
                                  height: 8,
                                  interval: 2,
                                  position: "Outside",
                                  useRangeColor: true,
                                }}
                                labelStyle={{
                                  hiddenLabel: "Last",
                                  font: {
                                    size: "12px",
                                    fontFamily: "Roboto",
                                    color: "#424242",
                                    fontWeight: "Regular",
                                  },
                                  position: "Outside",
                                  useRangeColor: true,
                                }}
                              >
                                <RangesDirective>
                                  <RangeDirective
                                    start={0}
                                    value={40}
                                    end={40}
                                    radius="90%"
                                    startWidth={10}
                                    endWidth={35}
                                    color="#C45072"
                                    opacity={1}
                                  ></RangeDirective>
                                </RangesDirective>
                                <PointersDirective>
                                  <PointerDirective
                                    radius="0%"
                                    type="RangeBar"
                                    pointerWidth={0}
                                  />
                                </PointersDirective>
                                <AnnotationsDirective>
                                  <AnnotationDirective
                                    content='<div id="content" style="color:#3d1010;font-size:28px;font-family:Poppins; font-weight:semibold;">40%</div>'
                                    angle={90}
                                    zIndex="none"
                                    radius="50%"
                                  ></AnnotationDirective>
                                </AnnotationsDirective>
                              </AxisDirective>
                            </AxesDirective>
                          </CircularGaugeComponent>
                        </Row>
                      </div>
                      <div className="ar-cell11">
                        {" "}
                        <Row>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                paddingTop: "1%",
                              }}
                            >
                              DAYS IN ACCOUNTS RECEIVABLE
                            </h6>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: "40%", fontWeight: "600" }}>
                          <ProgressBar completed="60"> days</ProgressBar>
                        </Row>
                        <Row>
                          <Col
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "600",
                              paddingTop: "4%",
                            }}
                          >
                            60 days
                          </Col>
                        </Row>
                        <Row
                          style={{
                            color: "grey",
                            paddingTop: "12%",
                            marginLeft: "15%",
                          }}
                        >
                          {" "}
                          Target Range: &nbsp;
                          <div
                            className="isquare2"
                            style={{
                              width: "100px",
                              height: "24px",
                              background: "rgb(241, 241, 241)",
                              color: "rgb(84, 205, 26)",
                              fontWeight: "bold",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {"<"}50 Days
                          </div>
                        </Row>
                      </div>
                    </div>
                  </Row>
                </Tab>
              </Tabs>
            </div>
          </div>
        )}
      </div>
      </div>
    );
  }
}
export default HealthScorecard;
