import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
 
import { Table, Card } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
import Notes1 from "../../Files/Notes1.csv";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Styles/clientNotes.css";
import axios from "axios";
import { ip } from ".././Apis/Api";
import { ToastContainer, toast } from "react-toastify";
import loading from "../../Images/loader.gif";
import ReactPaginate from "react-paginate";
import { Row, Col, Form, Label, Input, Button, FormGroup } from "reactstrap";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
  IoIosCloudUpload,
} from "react-icons/io";
export class clientNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      editmodal: false,
      deletemodal: false,
      Notes: [],
      id: "",
      query1: [],
      updateNotes: "",
      bulkmodal: false,
      updateStatusCode: "",
      currentpages: 0,
      perPage: 10,
      offsetNotes: 0,
      search__pg__count: 0,
      offsetSearch: 0,
      search__status__code: "",
      searchdataresponse1: [],
      searchclaimdata1: [],
      searchtext1: "",
      opendropdown: false,
      clientslist: [],
      role_type: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
    };
    this.fileInput = React.createRef();
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  openeditmodal = () => {
    this.setState({ editmodal: !this.state.editmodal });
  };
  opendeletemodal = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  toggledropdown = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };
  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          this.setState({
            clientslist: res.data.clientonboard,
            // clientname: res.data.clientonboard[0].client,
          });
        } else {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        }
      })
      .catch((err) => {});
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpages: selectedPage,
        offsetNotes: offset,
      },
      () => this.getNotes()
    );
  };
  bulkmodal = () => {
    this.setState({
      bulkmodal: !this.state.bulkmodal,
    });
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        search__pg__count: selectedPage,
        offsetSearch: offset,
      },
      () => this.filtersearch1()
    );
  };
  reset = (e) => {
    e.preventDefault();
    this.setState({
      search__status__code: "",
      searchdataresponse1: [],
      searchclaimdata1: [],
      searchtext1: "",
    });
    this.getNotes();
  };

  filtersearch1 = (e) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.search__status__code.length !== 0) {
      let url;
      if (this.state.client) {
        url = `${ip}/notes/advancedmultiplefilter?statusCode=${this.state.search__status__code}&pageno=${this.state.search__pg__count}&clientId=${this.state.client}`;
      } else {
        url = `${ip}/notes/advancedmultiplefilter?statusCode=${this.state.search__status__code}&pageno=${this.state.search__pg__count}`;
      }
      const res = axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          let filtereddata = res.data.Notes;
          if (filtereddata.length !== 0) {
            this.setState({ searchtext1: 1 });
            this.setState({
              searchdataresponse1: filtereddata,
              pageCount: Math.ceil(res.data.notescount / this.state.perPage),
            });
          } else if (filtereddata.length === 0) {
            this.setState({
              searchtext1: 1,
              searchdataresponse1: [],
              searchclaimdata1: [],
            });
          }
        })
        .catch((err) => {});
    } else {
      alert("Select data to search");
    }
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  deleteNotes = async () => {
    this.opendeletemodal();
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = await axios
      .delete(`${ip}/notes/${this.state.id}`, { headers: headers })
      .then((res) => {
        if (res) {
          this.setState({ createmodal: !this.state.createmodal });
          // this.opendeletemodal();
          this.getNotes();
          toast.error("Notes Successfully deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          // this.opendeletemodal();
          this.getNotes();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };
  onFileUpload = async (e) => {
    if (!this.state.file) {
      // Display an error message or handle the validation as needed
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    this.setState({ fileUploaded: !this.state.fileUploaded });
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);
    value.append("clientids", JSON.stringify(this.state.client));
    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    await axios({
      method: "post",
      url: `${ip}/notes/bulk/upload`,
      data: value,
      headers: headers,
    })
      .then((response) => {
        //handle success
        if (response.status === 200) {
          this.setState({ fileUploaded: !this.state.fileUploaded });
          toast.success("File uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          this.getNotes();
        }
      })
      .catch((err) => {
        //handle error
        this.setState({ fileUploaded: !this.state.fileUploaded });
        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error("Please include all the fields in excel data", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  updateNotes = async () => {
    this.opendeletemodal();
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      statusCode: this.state.updateStatusCode,
      notes: this.state.updateNotes,
    };
    const res = await axios
      .put(`${ip}/notes/${this.state.id}`, value, { headers: headers })
      .then((res) => {
        if (res) {
          this.setState({ createmodal: !this.state.createmodal });
          // this.openeditmodal();
          this.getNotes();
          toast.info("Notes updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          // this.openeditmodal();
          this.getNotes();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  getNotes = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.client) {
      url = `${ip}/notes/all?pageno=${this.state.currentpages}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/notes/all?pageno=${this.state.currentpages}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          Notes: res.data.Notes,
          pageCount: Math.ceil(res.data.notescount / this.state.perPage),
        });
      })
      .catch((err) => {});
  };

  componentDidMount() {
    
    this.getNotes();
    this.getclients();
  }
  render() {
    return (
      <div>
      <Newsidebar name="Client Notes" />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div className="align-column">
          <br />
          {this.state.role_type === "SuperAdmin" ? (
            <ButtonDropdown
              isOpen={this.state.opendropdown}
              toggle={this.toggledropdown}
              style={{ width: "12%", marginLeft: "4%", position: "inherit" }}
            >
              <DropdownToggle caret>
                {this.state.clientname ? this.state.clientname : "All"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() =>
                    this.setState({ client: "", clientname: "" }, () =>
                      this.getNotes()
                    )
                  }
                >
                  All
                </DropdownItem>
                {this.state.clientslist.map((e) => {
                  return (
                    <DropdownItem
                      id={e.clientId}
                      key={e.clientId}
                      onClick={(e) =>
                        this.setState(
                          {
                            client: e.target.id,
                            clientname: e.target.innerText,
                          },
                          () => this.getNotes()
                        )
                      }
                    >
                      {e.client}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </ButtonDropdown>
          ) : null}

          <ToastContainer></ToastContainer>
          <div
            class="btn-group"
            role="group"
            style={{  marginRight: "5%", marginLeft: "75%" }}
          >
            <button
              type="button"
              class="btn"
              style={{ backgroundColor: "#7DD657", color: "#fff", fontSize:"13px" }}
              onClick={this.bulkmodal}
              size=""
            >
              Bulk Upload
            </button>

            <button
              type="button"
              class="btn"
              style={{ backgroundColor: "#041c3c", color: "#fff" }}
            >
              <Link
                style={{ textDecoration: "none", color: "#fff", fontSize:"13px" }}
                to={Notes1}
                download="notes.csv"
                target="_blank"
              >
                {" "}
                Sample File
              </Link>
            </button>
          </div>
          <br/>
          <div>
            <Card
              style={{
                background: "whitesmoke",
                width: "60%",
                height: "103px",
                marginLeft: "25%",
              }}
            >
              <Row style={{ height: "100%", bottom: "10%" }}>
                <Form>
                  <Row style={{ marginTop: "2%" }}>
                    <Col style={{ marginLeft: "10%" }}>
                      <FormGroup>
                        <Label>Status Code</Label>
                        <Input
                          value={this.state.search__status__code}
                          onChange={(e) => {
                            this.setState({
                              search__status__code: e.target.value,
                            });
                          }}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <div
                        class="btn-group"
                        role="group"
                        style={{ marginRight: "220px" }}
                      >
                        <Button
                          type="button"
                          class="btn"
                          style={{
                            width: "90%",
                            height: "59%",
                            marginTop: "23.3px",
                            borderRadius: "8px",
                            color: "white",
                            background: "#041C3C",
                            fontWeight: "bold",
                            marginRight: "10%",
                          }}
                          onClick={(e) => {
                            this.filtersearch1(e);
                          }}
                        >
                          Search
                        </Button>
                        <button
                          type="button"
                          class="btn"
                          style={{
                            width: "60%",
                            height: "59%",
                            marginTop: "23.3px",
                            border: "none",
                            color: "white",
                            background: "#7DD657",
                            fontWeight: "bold",
                            marginRight: "100%",
                          }}
                          onClick={this.reset}
                        >
                          <IoIosRefresh
                            style={{
                              color: "white",
                              fontSize: "25px",
                            }}
                          ></IoIosRefresh>
                        </button>
                      </div>
                    </Col>
                    {/* <Col>
                    <Button
                      style={{
                        width: "50%",
                        height: "59%",
                        marginTop: "23.3px",
                        borderRadius: "8px",
                        color: "white",
                        background: "#041c3c",
                        fontWeight: "bold",
                        marginRight:"40%"
                      }}
                      // onClick={(e) => {
                      //   this.setState({ tabname: "web" }, (e) => {
                      //     this.filtersearch1(e);
                      //   });
                      // }}
                    >
                      Search
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{
                        width: "40%",
                        height: "59%",
                        marginTop: "23.3px",
                        border: "none",
                        color: "white",
                        background: "#041c3c",
                        fontWeight: "bold",
                        marginRight:"180%"
                      }}
                      onClick={(e) => {
                        this.reset(e);
                      }}
                    >
                      <IoIosRefresh
                        style={{
                          color: "white",
                          fontSize: "25px",
                        }}
                      ></IoIosRefresh>
                    </Button>
                  </Col> */}
                  </Row>
                </Form>
              </Row>
            </Card>
          </div>

          {this.state.Notes.length === 0 ? (
            <div class="bot-ui">
              <div class="bot-head">
                <div class="eyes-container">
                  <div class="to-left eye"></div>
                  <div class="to-right eye"></div>
                </div>
              </div>
              <div class="bot-body">
                <div class="analyzer"></div>
              </div>
              <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                No Data Found
              </p>
            </div>
          ) : (
            <div>
              {this.state.searchtext1 === "" ? (
                <div className="client__notes">
                  <Table responsive="md" className="StandardTable" bordered>
                    <thead>
                      <tr>
                        <th
                          style={{
                            textAlign: "center",
                            background: "#041c3c",
                            color: "white",
                          }}
                        >
                          Status Code
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            background: "#041c3c",
                            color: "white",
                          }}
                        >
                          Client Id
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            background: "#041c3c",
                            color: "white",
                          }}
                        >
                          Notes
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            background: "#041c3c",
                            color: "white",
                          }}
                        >
                          Edit
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            background: "#041c3c",
                            color: "white",
                          }}
                        >
                          Delete
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.Notes.map((data, index) => (
                        <tr>
                          <td
                            style={{
                              textAlign: "center",
                              background: "whitesmoke",
                              width: "250px",
                            }}
                          >
                            {data.statusCode}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              background: "whitesmoke",
                              width: "150px",
                            }}
                          >
                            {data.clientId}
                          </td>
                          <td
                            style={{
                              textAlign: "justify",
                              background: "whitesmoke",
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}
                          >
                            {data.notes}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "20px",
                              background: "whitesmoke",
                              width: "15px",
                            }}
                          >
                            <button
                              style={{ border: "none" }}
                              onClick={() => {
                                this.setState({
                                  id: data._id,
                                  updateStatusCode: data.statusCode,
                                  updateNotes: data.notes,
                                });
                                this.openeditmodal();
                              }}
                            >
                              <FaRegEdit />
                            </button>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontSize: "20px",
                              background: "whitesmoke",
                              width: "15px",
                            }}
                          >
                            <button
                              style={{ border: "none" }}
                              onClick={() => {
                                this.setState({
                                  id: data._id,
                                });
                                this.opendeletemodal();
                              }}
                            >
                              <MdOutlineDelete />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  {this.state.searchdataresponse1.length === 0 ? (
                    <div class="bot-ui">
                      <div class="bot-head">
                        <div class="eyes-container">
                          <div class="to-left eye"></div>
                          <div class="to-right eye"></div>
                        </div>
                      </div>
                      <div class="bot-body">
                        <div class="analyzer"></div>
                      </div>
                      <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                        No Data Found
                      </p>
                    </div>
                  ) : (
                    <div className="client__notes">
                      <Table responsive="md" className="StandardTable" bordered>
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "center",
                                background: "#041c3c",
                                color: "white",
                              }}
                            >
                              Status Code
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                background: "#041c3c",
                                color: "white",
                              }}
                            >
                              Notes
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                background: "#041c3c",
                                color: "white",
                              }}
                            >
                              Edit
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                background: "#041c3c",
                                color: "white",
                              }}
                            >
                              Delete
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.searchdataresponse1.map((data, index) => (
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                  background: "whitesmoke",
                                  width: "250px",
                                }}
                              >
                                {data.statusCode}
                              </td>
                              <td
                                style={{
                                  textAlign: "justify",
                                  background: "whitesmoke",
                                  whiteSpace: "pre-wrap",
                                  wordWrap: "break-word",
                                }}
                              >
                                {data.notes}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontSize: "20px",
                                  background: "whitesmoke",
                                  width: "15px",
                                }}
                              >
                                <button
                                  style={{ border: "none" }}
                                  onClick={() => {
                                    this.setState({
                                      id: data._id,
                                      updateStatusCode: data.statusCode,
                                      updateNotes: data.notes,
                                    });
                                    this.openeditmodal();
                                  }}
                                >
                                  <FaRegEdit />
                                </button>
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontSize: "20px",
                                  background: "whitesmoke",
                                  width: "15px",
                                }}
                              >
                                <button
                                  style={{ border: "none" }}
                                  onClick={() => {
                                    this.setState({
                                      id: data._id,
                                    });
                                    this.opendeletemodal();
                                  }}
                                >
                                  <MdOutlineDelete />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick1}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
                              
          <Modal
            show={this.state.editmodal}
            onHide={this.openeditmodal}
            centered
          >
            <Modal.Header closeButton closeLabel="">
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <FormGroup>
                    <Label>Notes</Label>
                    <Input
                      type="textarea"
                      style={{ height: "200px" }}
                      required
                      value={this.state.updateNotes}
                      onChange={(e) =>
                        this.setState({
                          updateNotes: e.target.value,
                        })
                      }
                    >
                      {this.state.updateNotes}
                    </Input>
                  </FormGroup>
                </Row>
                <br />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.openeditmodal();
                  //   this.reset();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "#041c3c", color: "white" }}
                onClick={this.updateNotes}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.deletemodal}
            onHide={this.opendeletemodal}
            centered
          >
            <Modal.Header closeButton closeLabel="">
              <Modal.Title>Delete Notes</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to delete this notes?</Modal.Body>
            <Modal.Footer>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.opendeletemodal();
                  //   this.reset();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "#041c3c", color: "white" }}
                onClick={this.deleteNotes}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.createmodal} centered>
            <Modal.Body>
              <img
                src={loading}
                style={{ width: "200px", height: "200px", marginLeft: "30%" }}
              />
            </Modal.Body>
          </Modal>
        </div>

        {this.state.bulkmodal && (
          <Modal
            show={this.state.bulkmodal}
            onHide={this.bulkmodal}
            // onExit={this.reloadpage}
            centered
            size="xl"
          >
            <Modal.Header closeLabel="" closeButton onClick={this.bulkmodal}>
              Bulk Upload
            </Modal.Header>
            <Modal.Body
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <div className="align-row items">
                <form method="post" style={{ width: "17%" }}>
                  <div class="files">
                    <input
                      type="file"
                      id="file"
                      required
                      ref={this.fileInput}
                      onChange={this.onFileChange}
                      style={{ display: "none" }}
                      accept=".xlsx"
                    />
                    <label for="file" className="filelabel">
                      {this.state.file ? (
                        <p align="center" style={{ color: "green" }}>
                          {this.state.file.name}
                        </p>
                      ) : (
                        <a className="dropfile">Drop files here or browse</a>
                      )}
                    </label>
                  </div>
                </form>
              </div>
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{
                  border: "1px solid gray",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={this.bulkmodal}
              >
                Cancel
              </Button>
              <Button
                style={{
                  border: "1px solid gray",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={this.onFileUpload}
                className="uploadcss"
              >
                Upload
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
      </div>
    );
  }
}
