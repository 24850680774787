import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import Claim from "./Reports/Claim";
import Cpt from "./Reports/Cpt";
import Arlog from "./Reports/Arlogs";
import { DatePicker } from "antd";
import moment from "moment-timezone";
import DailyReport from "./Reports/DailyReport";
import OpenedClaims from "./Reports/OpenedClaims";
import { IoIosRefresh } from "react-icons/io";
import Tabs, { Tab } from "react-best-tabs";
import { Input } from "reactstrap";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl, Button, Row, Col } from "react-bootstrap";
import ClaimwiseProduction from "./Reports/ClaimwiseProduction";
import RPA from "./Reports/RPA";
import ClosedCpt from "./Reports/ClosedCpt";
const { RangePicker } = DatePicker;

export class Reports extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let start1 = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end1 = moment(start).add(1, "days").subtract(1, "seconds");
    this.state = {
      start: start,
      end: end,
      start1: start1,
      end1: end1,
      startdate: "",
      enddate: "",
      filterstatus: "custom",
      tabindex: 1,
      startdatetime: "",
      enddatetime: "",
      startdatetime1: "",
      enddatetime1: "",
    };
    this.applyCallback = this.applyCallback.bind(this);
  }
  applyCallback = (startDate, endDate) => {
    let start = moment(startDate._d).format().substring(0, 16);
    let end = moment(endDate._d).format().substring(0, 16);
    this.setState({
      startdatetime: start,
      enddatetime: end,
    });
  };
  applyCallback1 = (startDate, endDate) => {
    let start = moment(startDate._d).format().substring(0, 16);
    let end = moment(endDate._d).format().substring(0, 16);
    this.setState({
      startdatetime1: start,
      enddatetime1: end,
    });
  };
  valuedate = (option, e) => {
    if (option === "yesterday") {
      this.setState({
        startdate: e.target.value,
        enddate: moment().subtract(1, "days").format("MM-DD-YYYY"),
        filterstatus: option,
      });
    } else {
      this.setState({
        startdate: e.target.value,
        enddate: moment().format("MM-DD-YYYY"),
        filterstatus: option,
      });
    }
  };
  render() {
    let value;
    let date1 = moment().subtract(7, "days").format().substring(0, 16);
    let date2 = moment().format().substring(0, 16);
    if (this.state.startdatetime && this.state.enddatetime) {
      value = `${this.state.startdatetime.substring(
        0,
        16
      )} ~ ${this.state.enddatetime.substring(0, 16)}`;
    } else {
      value = `${date1} ~ ${date2}`;
    }
    let value1;
    if (this.state.startdatetime1 && this.state.enddatetime1) {
      value1 = `${this.state.startdatetime1.substring(
        0,
        16
      )} ~ ${this.state.enddatetime1.substring(0, 16)}`;
    } else {
      value1 = "Start Date ~ End Date";
    }
    let disabled = true;
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let start1 = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end1 = moment(start).add(1, "days").subtract(1, "seconds");
    let local = {
      format: "DD-MM-YYYY HH:mm",
      sundayFirst: false,
    };
    let local1 = {
      format: "DD-MM-YYYY HH:mm",
      sundayFirst: false,
    };

    return (
      <div>
      <Newsidebar name="Generate Reports" />
      <div className="align-row">
        <div className="align-column">

          {this.state.tabindex !== 4 && this.state.tabindex !== 6 && (
            <div style={{}}>
              <Input
                type="select"
                style={{ width: "20%", float: "right", margin: "2%" }}
                className="form-control form-select"
                onChange={(e) => {
                  const index = e.target.selectedIndex;
                  const el = e.target.childNodes[index];
                  const option = el.getAttribute("title");
                  this.valuedate(option, e);
                }}
              >
                <option value="" title="custom">
                  Custom
                </option>
                <option value={moment().format("MM-DD-YYYY")} title="">
                  Today
                </option>
                <option
                  value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                  title="yesterday"
                >
                  Yesterday
                </option>
                <option
                  value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                  title=""
                >
                  Last 3 days
                </option>
                <option
                  value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                  title=""
                >
                  Last 7 days
                </option>
                <option
                  value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                  title=""
                >
                  Last 30 days
                </option>
                <option
                  value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                  title=""
                >
                  Last 365 days
                </option>
              </Input>
              {this.state.filterstatus === "custom" && (
                <RangePicker
                  format="MM-DD-YYYY"
                  onChange={(dates, datestring) => {
                    this.setState({
                      startdate: datestring[0],
                      enddate: datestring[1],
                    });
                  }}
                  height="50%"
                  width="200%"
                  style={{ borderColor: "gray", float: "right", margin: "2%" }}
                />
              )}
            </div>
          )}
          {this.state.tabindex === 4 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  marginLeft: "6%",
                  marginTop: "5%",
                  width: "40%",
                  position: "inherit",
                }}
              >
                <DateTimeRangeContainer
                  start={this.state.start}
                  end={this.state.end}
                  applyCallback={this.applyCallback}
                  local={local}
                >
                  <FormControl
                    id="formControlsTextB"
                    type="text"
                    label="Text"
                    placeholder="Select Date Range"
                    // disabled={disabled}
                    value={value}
                  />
                </DateTimeRangeContainer>
              </div>

              <IoIosRefresh
                style={{
                  color: "blue",
                  marginTop: "5%",
                  marginLeft: "2%",
                  width: "48px",
                  height: "48px",
                  padding: "10px 12px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                }}
                onClick={(e) => {
                  this.setState({ startdatetime: "", enddatetime: "" });
                }}
              ></IoIosRefresh>
            </div>
          )}
          {this.state.tabindex === 6 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  marginLeft: "6%",
                  marginTop: "5%",
                  width: "40%",
                  position: "inherit",
                }}
              >
                <DateTimeRangeContainer
                  start={this.state.start1}
                  end={this.state.end1}
                  applyCallback={this.applyCallback1}
                  local={local1}
                >
                  <FormControl
                    id="formControlsTextB"
                    type="text"
                    label="Text"
                    placeholder="Select Date Range"
                    // disabled={disabled}
                    value={value1}
                  />
                </DateTimeRangeContainer>
              </div>

              <IoIosRefresh
                style={{
                  color: "blue",
                  marginTop: "5%",
                  marginLeft: "2%",
                  width: "48px",
                  height: "48px",
                  padding: "10px 12px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                }}
                onClick={(e) => {
                  this.setState({ startdatetime1: "", enddatetime1: "" });
                }}
              ></IoIosRefresh>
            </div>
          )}
          <div style={{ margin: "5%", marginTop: "2%" }}>
            <Tabs
              activeTab="1"
              activityClassName="text-secondary"
              style={{ margin: "5%" }}
              onClick={(event, tab) => {
                this.setState({ tabindex: tab });
              }}
            >
              <Tab title="CLAIM WISE">
                <Claim
                  startdate={this.state.startdate}
                  enddate={this.state.enddate}
                />
              </Tab>
              <Tab title="CPT WISE">
                <Cpt
                  startdate={this.state.startdate}
                  enddate={this.state.enddate}
                />
              </Tab>

              <Tab title="AR LOG WISE">
                <Arlog
                  startdate={this.state.startdate}
                  enddate={this.state.enddate}
                />
              </Tab>

              <Tab title="DAILY PRODUCTION REPORT">
                <DailyReport
                  startdate={this.state.startdatetime}
                  enddate={this.state.enddatetime}
                />
              </Tab>
              <Tab title="OPEN CLAIMS">
                <OpenedClaims
                  startdate={this.state.startdate}
                  enddate={this.state.enddate}
                />
              </Tab>
              <Tab title="CLAIMWISE PRODUCTION REPORT">
                <ClaimwiseProduction
                  startdate={this.state.startdatetime1}
                  enddate={this.state.enddatetime1}
                />
              </Tab>
              {/* <Tab title="RPA REPORT">
                <RPA
                  startdate={this.state.startdate}
                  enddate={this.state.enddate}
                />
              </Tab> */}
              <Tab title="CLOSED CPT">
              <ClosedCpt
                startdate={this.state.startdate}
                enddate={this.state.enddate}
              />
            </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default Reports;
