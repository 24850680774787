import React, { Component } from "react";

import "../../Styles/DataConersionOCR.css";
import { Button, Table } from "reactstrap";
import Newsidebar from "../Dashboard/Newsidebar";
import { AiOutlineTrophy, AiOutlineLock, AiOutlineStar } from "react-icons/ai";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import { BsTools } from "react-icons/bs";
import { Row, Col, Spinner } from "react-bootstrap";
import CsvDownload from "react-json-to-csv";
import Tabs, { Tab } from "react-best-tabs";
import loading from "../../Images/loader.gif";
import Aging from "../../Files/Aging.PDF";
import summary from "../../Files/summary.PDF";
import { Link } from "react-router-dom";
import { ip } from "../Apis/ocrApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { RowingSharp } from "@mui/icons-material";
import { RiH1 } from "react-icons/ri";
class DataConversionOCR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      file1: null,
      fetchingdailyreport: true,
      fileUploaded: false,
      fileUploaded1: false,
      upload: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      loading: true,
      loading1: true,
      reports: [],
      reports1: [],
    };
    this.fileInput = React.createRef();
    // this.fileInput1 = React.createRef();
  }
  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };
  onFileChange1 = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  onFileUpload = async (e) => {
    if (!this.state.file) {
      // Display an error message or handle the validation as needed
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    this.setState({ loading: true });
    this.setState({ fileUploaded: !this.state.fileUploaded });
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);

    await axios({
      method: "post",
      url: `${ip}/aging`,
      data: value,
    })
      .then((response) => {
        //handle success
        console.log(response, "Response");

        if (response.status == 200) {
          // let array1 = response.data.text[0];

          this.setState({
            fileUploaded: !this.state.fileUploaded,
            reports: response.data.aging,
            loading: false,
            fetchingdailyreport: false,
          });
          toast.success("File uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          // console.log(this.state.reports, "Files");
        }
        console.log(this.state.reports, "Response");
      })
      .catch((err) => {
        //handle error
        console.log("Failed", err);
        this.setState({ fileUploaded: !this.state.fileUploaded });
        // this.getfiles();
        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error(
              "Please include all the fields in correct data format",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              }
            );
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  onFileUpload1 = async (e) => {
    this.setState({ loading1: true });
    this.setState({ fileUploaded: !this.state.fileUploaded });
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);

    await axios({
      method: "post",
      url: `${ip}/patientsummary`,
      data: value,
    })
      .then((response) => {
        //handle success
        console.log(response, "summary");
        // const [isLoading, setIsLoading] = useState(false);

        if (response) {
          // let array1 = response.data.text[0];

          this.setState({
            fileUploaded: !this.state.fileUploaded,
            reports1: response.data.patientsummary,
            loading1: false,
            fetchingdailyreport: false,
          });
          toast.success("File uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
        console.log(this.state.reports1, "Response");
      })
      .catch((err) => {
        //handle error
        console.log("Failed", err);
        this.setState({ fileUploaded: !this.state.fileUploaded });
        // this.getfiles();
        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error(
              "Please include all the fields in correct data format",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              }
            );
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>

          <p>File Name: {this.state.selectedFile.name}</p>

          <p>File Type: {this.state.selectedFile.type}</p>

          <p>
            Last Modified:{" "}
            {this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Choose file before Pressing the download button</h4>
        </div>
      );
    }
  };

  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.enddate === prevProps.enddate
      )
    ) {
    }
  }

  render() {
    return (
      <div>
      <Newsidebar name="Data Conversion OCR" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">

          <div style={{ marginLeft: "55px", marginTop: "10px" }}>
            <ToastContainer></ToastContainer>
            <Tabs activityClassName="text-secondary" style={{ margin: "5%" }}>
              <Tab title="Aging">
                <div className="align-row items">
                  <div
                  //  style={{ height: "130%", width: "100%" }}
                  >
                    <div className="ocr__file">
                      <div className="align-row items">
                        <form
                          method="post"
                          style={{ width: "13%", height: "15%" }}
                        >
                          <div class="files_ocr">
                            <input
                              type="file"
                              id="file"
                              required
                              ref={this.fileInput}
                              onChange={this.onFileChange}
                              style={{ display: "none" }}
                              accept=".pdf,.csv,.xlsx,.doc,.docx,.txt,"
                            />
                            <label for="file" className="filelabel">
                              {this.state.file ? (
                                <p align="center" style={{ color: "green" }}>
                                  {this.state.file.name}
                                </p>
                              ) : (
                                <a className="dropfile">
                                  Drop files here or browse
                                </a>
                              )}
                            </label>
                          </div>
                        </form>
                      </div>

                      <div class="filesofbutton" style={{ marginLeft: "55px" }}>
                        <Row>
                          <Col className="col-1">
                            <Button
                              outline
                              color="secondary"
                              size="sm"
                              className="exportcss"
                            >
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "#a8adad",
                                }}
                                to={Aging}
                                download="Aging.pdf"
                                target="_blank"
                              >
                                {" "}
                                Sample
                              </Link>
                            </Button>
                          </Col>
                          <Col className="col-1">
                            <Button
                              outline
                              color="secondary"
                              size="sm"
                              onClick={this.onFileUpload}
                              className="uploadcss"
                            >
                              Upload
                            </Button>
                          </Col>
                          <Col>
                            <div class="fileu">
                              {this.state.fileUploaded ? (
                                <div>
                                  <Button
                                    style={{
                                      boxShadow:
                                        "inset 0px 1px 0px 0px #e184f3",
                                      background: "black",
                                      backgroundColor: "green",
                                      borderRadius: "6px",
                                      border: "1px solid green",
                                      display: "inline-block",
                                      color: "#ffffff",
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                      padding: "6px 24px",
                                      textDecoration: "none",
                                      float: "right",
                                      textShadow: "0px 1px 0px #9b14b3",
                                      cursor: "none",
                                      marginRight: "450px",
                                    }}
                                  >
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    &nbsp;Loading data...
                                  </Button>
                                </div>
                              ) : (
                                <CsvDownload
                                  data={this.state.reports}
                                  filename="Aging.csv"
                                  style={{
                                    //pass other props, like styles
                                    boxShadow: "inset 0px 1px 0px 0px #e184f3",
                                    background: "black",
                                    backgroundColor: "black",
                                    borderRadius: "6px",
                                    border: "1px solid black",
                                    display: "inline-block",
                                    cursor: "pointer",
                                    color: "#ffffff",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    padding: "6px 24px",
                                    textDecoration: "none",
                                    float: "right",
                                    textShadow: "0px 1px 0px #9b14b3",
                                    marginLeft: "05px",
                                  }}
                                >
                                  Download file
                                </CsvDownload>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="body__main">
                      <main>
                        <Row>
                          <Col>
                            <div class="section">
                              <div class="card__view card-karma">
                                <div class="card-body">
                                  <Row>
                                    <Col>
                                      <div class="card-title text-bold">
                                        Automatic deletion of your files
                                      </div>
                                    </Col>
                                    <Col style={{ fontSize: "30px" }}>
                                      <AiOutlineStar />
                                    </Col>
                                  </Row>
                                  <div class="card-text text-muted">
                                    After you convert a document to PDF, you’ll
                                    be able to download and delete your files
                                    from our servers. If you happen to forget
                                    about deleting your files, they will be
                                    deleted from our server automatically after
                                    three hours to ensure your information is
                                    secure.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div class="section">
                              <div class="card__view card-team_builder">
                                <div class="card-body">
                                  <Row>
                                    <Col>
                                      <div class="card-title text-bold">
                                        Encrypted files
                                      </div>
                                    </Col>
                                    <Col style={{ fontSize: "30px" }}>
                                      <AiOutlineLock />
                                    </Col>
                                  </Row>
                                  <div class="card-text text-muted">
                                    We care about the privacy of your data.
                                    256-bit SSL Encryption of all your files
                                    means that your files, documents, and data
                                    are secure. We also won’t give or share any
                                    of your data with other parties.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div class="section">
                          <div class="card__view card-supervisor">
                            <div class="card-body">
                              <Row>
                                <Col>
                                  <div class="card-title text-bold">
                                    The Best Free PDF Converter
                                  </div>
                                </Col>
                                <Col style={{ fontSize: "30px" }}>
                                  <AiOutlineTrophy />
                                </Col>
                              </Row>

                              <div class="card-text text-muted">
                                No matter what types of files you need to
                                convert, our online file converter is more than
                                just a PDF file converter. It’s the go-to
                                solution for all of your file conversion needs.
                              </div>
                            </div>
                          </div>
                        </div>

                        <Row>
                          <Col>
                            <div class="section">
                              <div class="card__view card-calculator">
                                <div class="card-body">
                                  <Row>
                                    <Col>
                                      <div class="card-title text-bold">
                                        Universal conversion
                                      </div>
                                    </Col>
                                    <Col style={{ fontSize: "30px" }}>
                                      <MdOutlinePublishedWithChanges />
                                    </Col>
                                  </Row>
                                  <div class="card-text text-muted">
                                    Our free file converter works on any OS,
                                    including Windows, Mac, and Linux. Because
                                    of this, you can convert files to PDF from
                                    any OS or device as long as you have an
                                    Internet connection.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div class="section">
                              <div class="card__view card-five">
                                <div class="card-body">
                                  <Row>
                                    <Col>
                                      <div class="card-title text-bold">
                                        A suite of helpful file conversion tools
                                      </div>
                                    </Col>
                                    <Col style={{ fontSize: "30px" }}>
                                      <BsTools />
                                    </Col>
                                  </Row>
                                  <div class="card-text text-muted">
                                    Our PDF file converter does more than
                                    convert files to PDF. From compression and
                                    rotation to merging two different PDFs and
                                    splitting one PDF into two, you can easily
                                    edit your PDF files with our suite of tools
                                    in the blink of an eye.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </main>
                    </div>
                  </div>
                </div>
              </Tab>
              <br />
              <Tab title="Patientsummary">
                <div className="align-row items">
                  <div style={{ height: "130%", width: "100%" }}>
                    <div className="ocr__file">
                      <div className="align-row items">
                        <form
                          method="post"
                          style={{ width: "13%", height: "15%" }}
                        >
                          <div class="files_ocr">
                            <input
                              type="file"
                              id="file"
                              required
                              ref={this.fileInput1}
                              onChange={this.onFileChange1}
                              style={{ display: "none" }}
                              accept=".pdf,.csv,.xlsx,.doc,.docx,.txt,"
                            />
                            <label for="file" className="filelabel">
                              {this.state.file ? (
                                <p align="center" style={{ color: "green" }}>
                                  {this.state.file.name}
                                </p>
                              ) : (
                                <a className="dropfile">
                                  Drop files here or browse
                                </a>
                              )}
                            </label>
                          </div>
                        </form>
                      </div>

                      <div style={{ marginLeft: "55px" }}>
                        <Row>
                          <Col className="col-1">
                            <Button
                              outline
                              color="secondary"
                              size="sm"
                              className="exportcss"
                            >
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "#a8adad",
                                }}
                                to={summary}
                                download="patientsummary.pdf"
                                target="_blank"
                              >
                                {" "}
                                Sample
                              </Link>
                            </Button>
                          </Col>
                          <Col className="col-1">
                            <Button
                              outline
                              color="secondary"
                              size="sm"
                              onClick={this.onFileUpload1}
                              className="uploadcss"
                            >
                              Upload
                            </Button>
                          </Col>
                          <Col>
                            <div>
                              {this.state.fileUploaded ? (
                                <div>
                                  <Button
                                    style={{
                                      boxShadow:
                                        "inset 0px 1px 0px 0px #e184f3",
                                      background: "black",
                                      backgroundColor: "green",
                                      borderRadius: "6px",
                                      border: "1px solid green",
                                      display: "inline-block",
                                      color: "#ffffff",
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                      padding: "6px 24px",
                                      textDecoration: "none",
                                      float: "right",
                                      textShadow: "0px 1px 0px #9b14b3",
                                      cursor: "none",
                                      marginRight: "450px",
                                    }}
                                  >
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    &nbsp;&nbsp;Loading data...
                                  </Button>
                                </div>
                              ) : (
                                <CsvDownload
                                  data={this.state.reports1}
                                  filename="patient.csv"
                                  style={{
                                    //pass other props, like styles
                                    boxShadow: "inset 0px 1px 0px 0px #e184f3",
                                    background: "black",
                                    backgroundColor: "black",
                                    borderRadius: "6px",
                                    border: "1px solid black",
                                    display: "inline-block",
                                    cursor: "pointer",
                                    color: "#ffffff",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    padding: "6px 24px",
                                    textDecoration: "none",
                                    float: "right",
                                    textShadow: "0px 1px 0px #9b14b3",
                                    marginLeft: "05px",
                                  }}
                                >
                                  Download File
                                </CsvDownload>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="body__main">
                      <main>
                        <Row>
                          <Col>
                            <div class="section">
                              <div class="card__view card-karma">
                                <div class="card-body">
                                  <Row>
                                    <Col>
                                      <div class="card-title text-bold">
                                        Automatic deletion of your files
                                      </div>
                                    </Col>
                                    <Col style={{ fontSize: "30px" }}>
                                      <AiOutlineStar />
                                    </Col>
                                  </Row>
                                  <div class="card-text text-muted">
                                    After you convert a document to PDF, you’ll
                                    be able to download and delete your files
                                    from our servers. If you happen to forget
                                    about deleting your files, they will be
                                    deleted from our server automatically after
                                    three hours to ensure your information is
                                    secure.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div class="section">
                              <div class="card__view card-team_builder">
                                <div class="card-body">
                                  <Row>
                                    <Col>
                                      <div class="card-title text-bold">
                                        Encrypted files
                                      </div>
                                    </Col>
                                    <Col style={{ fontSize: "30px" }}>
                                      <AiOutlineLock />
                                    </Col>
                                  </Row>
                                  <div class="card-text text-muted">
                                    We care about the privacy of your data.
                                    256-bit SSL Encryption of all your files
                                    means that your files, documents, and data
                                    are secure. We also won’t give or share any
                                    of your data with other parties.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div class="section">
                          <div class="card__view card-supervisor">
                            <div class="card-body">
                              <Row>
                                <Col>
                                  <div class="card-title text-bold">
                                    The Best Free PDF Converter
                                  </div>
                                </Col>
                                <Col style={{ fontSize: "30px" }}>
                                  <AiOutlineTrophy />
                                </Col>
                              </Row>

                              <div class="card-text text-muted">
                                No matter what types of files you need to
                                convert, our online file converter is more than
                                just a PDF file converter. It’s the go-to
                                solution for all of your file conversion needs.
                              </div>
                            </div>
                          </div>
                        </div>

                        <Row>
                          <Col>
                            <div class="section">
                              <div class="card__view card-calculator">
                                <div class="card-body">
                                  <Row>
                                    <Col>
                                      <div class="card-title text-bold">
                                        Universal conversion
                                      </div>
                                    </Col>
                                    <Col style={{ fontSize: "30px" }}>
                                      <MdOutlinePublishedWithChanges />
                                    </Col>
                                  </Row>
                                  <div class="card-text text-muted">
                                    Our free file converter works on any OS,
                                    including Windows, Mac, and Linux. Because
                                    of this, you can convert files to PDF from
                                    any OS or device as long as you have an
                                    Internet connection.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div class="section">
                              <div class="card__view card-five">
                                <div class="card-body">
                                  <Row>
                                    <Col>
                                      <div class="card-title text-bold">
                                        A suite of helpful file conversion tools
                                      </div>
                                    </Col>
                                    <Col style={{ fontSize: "30px" }}>
                                      <BsTools />
                                    </Col>
                                  </Row>
                                  <div class="card-text text-muted">
                                    Our PDF file converter does more than
                                    convert files to PDF. From compression and
                                    rotation to merging two different PDFs and
                                    splitting one PDF into two, you can easily
                                    edit your PDF files with our suite of tools
                                    in the blink of an eye.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </main>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
export default DataConversionOCR;
