import { Component } from "react";
import axios from "axios";
import { ResponsiveContainer } from "recharts";
import Newsidebar from "../../Dashboard/Newsidebar";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { formatCurrency } from "./utils";
import {
  ComposedChart,
  Bar,
  XAxis,
  Area,
  LabelList,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { ip } from "../../Apis/Api";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const ClaimTooltip = ({ active, payload }) => {
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          {payload[0].payload.name}
          <br />
          <p style={{ color: payload[0].color }}>
            {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
          </p >
        </div>
      );
    }
    return null;
  };

class TopCptPerformance extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
    dataarray:[],
    client:sessionStorage.getItem("clientid"),
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
   getData = async () =>{
    const headers = {
      "Content-Type" : "application/json",
      Authorization : `bearer ${this.state.accesstoken}`,
    };
    let url;
    if(this.state.client){
      url = `${ip}/trends/topcptper?clientId=${this.state.client}`
    }
    else {
      url = `${ip}/trends/topcptper`
    }
    const res = axios
    .get(url,{ headers : headers})
    .then((res) => {
      this.setState({
        dataarray : res.data.data  
      })
    })
    .catch((err)=>{console.log("err",err)})
    
   }

  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getData();
  }

  render() {
    return (
      <div>
      <Newsidebar />
      <div className="align-row">
        <div className="align-column">
          <br />
          <p style={{ textAlign: "left", marginLeft: "3%", fontSize: "14px" }}>
            <Link
              to="/management-trends-patterns"
              style={{ textDecoration: "none", color: "black" }}
            >
              Trends & Patterns
            </Link>
            &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Top CPT
            Performance{" "}
          </p>
          <div>
            <ResponsiveContainer width={"100%"} height={600}>
              <ComposedChart
                width={400}
                height={400}
                data={this.state.dataarray}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 5,
                  left: 20,
                }}
              >
                <defs>
                  <linearGradient
                    id="colorUv"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100%"
                    spreadMethod="reflect"
                  >
                    <stop offset="0" stopColor="#f5234a" />
                    <stop offset="1" stopColor="#fa859a" />
                  </linearGradient>
                </defs>
                {/* <CartesianGrid   strokeDasharray="9" stroke="#f5f5f5" /> */}

                <XAxis
                  dataKey="name"
                  tickLine={false}
                  axisLine={false}
                  type="category"
                  scale="band"
                  height={160}
                  angle="-45"
                  interval={0}
                  tick={{
                    strokeWidth: 0,
                    fontSize: "12",
                    fontFamily: "Poppins",
                    top: 400,
                    textAnchor: "end",
                    dy: 6,
                  }}
                />
                <YAxis
                  dataKey="totalpaidamount"
                  type="number"
                  hide
                  interval={0}
                  minTickGap="1"
                  tick={{ fontSize: "15", fontFamily: "Poppins" }}
                />
                <Tooltip content={<ClaimTooltip/>}/>
                <Area
                  dataKey="totalpaidamount"
                  type="monotone"
                  fill="url(#colorUv)"
                  strokeWidth="2"
                  stroke="#dc0a30"
                />
                <Bar
                  dataKey="totalpaidamount"
                  radius={[10, 10, 0, 0]}
                  barSize={90}
                  fill="url(#colorUv)"
                >
                  <LabelList
                    type="number"
                    position="top"
                    fill="#7a051b"
                    fontWeight="600"
                    fontFamily="Poppins"
                    formatter={formatCurrency}
                  />
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
export default TopCptPerformance;
