import { React, Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import workgif from "./../../Images/workingdoc.gif";
class Collection extends Component {
  render() {
    return (
      <div className="align-row">
        <Newsidebar />
        {/* <div className="align-column">
                <div className="tophead">
                  <Tophead name="Primary Payer" /> 
                </div>
                
                <div className="align-row items">
               
                </div> */}
        <div style={{ width: "100%" }}>
          <img src={workgif} alt="Animation" width="700px" height="700px" />
          <h3
            style={{
              color: "#00008B",
              display: "flex",
              justifyContent: "center",
              marginTop: "-11%",
            }}
          >
            Great things are coming soon
          </h3>
        </div>

        {/* </div> */}
      </div>
    );
  }
}
export default Collection;
