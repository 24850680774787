import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { FiTrash } from "react-icons/fi";
import "../../../Styles/tflexceed.css";
import { BsPlusLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Label, Input } from "reactstrap";
import Select from "react-dropdown-select";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
 
import { ip } from "../../Apis/Api";
class ManagePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      ClaimID: sessionStorage.getItem("ClaimID"),
      ChartID: sessionStorage.getItem("ChartID"),
      MRN: sessionStorage.getItem("MRN"),
      Patient: sessionStorage.getItem("Patient"),
      Pripayer: sessionStorage.getItem("Pripayer"),
      DateOfService: sessionStorage.getItem("DateOfService"),
      ClaimDate: sessionStorage.getItem("ClaimDate"),
      PatientDOB: sessionStorage.getItem("PatientDOB"),
    };
  }
  toggle = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:last-child td, &:last-child th": {
        border: 1,
      },
    }));
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar name="Allocation" />
        <div className="align-column">
          <br></br>
          <div
            style={{ textAlign: "left", marginLeft: "1%", fontSize: "14px" }}
          >
            <p>
              <Link
                to="/allocation-buckets"
                style={{ textDecoration: "none", color: "black" }}
              >
                Allocation
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
              <Link
                to="/TFLexceed"
                style={{ textDecoration: "none", color: "black" }}
              >
                Bucket 1
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Payment
              Management
            </p>
          </div>
          <br></br>
          <div>
            {" "}
            <h5
              style={{ textAlign: "left", marginLeft: "1%", fontWeight: "600" }}
            >
              Payment Management
            </h5>
          </div>
          <div style={{ height: "100%", width: "100%", marginTop: "2%" }}>
            <TableContainer>
              <Table
                sx={{ minWidth: "fit-content" }}
                aria-label="customized table"
                style={{ width: "fit-content" }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ClaimID</StyledTableCell>
                    <StyledTableCell>ChartID</StyledTableCell>
                    <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                      MRN/Account Number
                    </StyledTableCell>
                    <StyledTableCell>PatientName</StyledTableCell>
                    <StyledTableCell>PatientDOB</StyledTableCell>
                    <StyledTableCell>Pripayer</StyledTableCell>
                    <StyledTableCell>Phone#</StyledTableCell>
                    <StyledTableCell>Address</StyledTableCell>
                    <StyledTableCell>ClaimDate</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                    <StyledTableCell>{this.state.ChartID}</StyledTableCell>
                    <StyledTableCell>{this.state.MRN}</StyledTableCell>
                    <StyledTableCell>{this.state.Patient}</StyledTableCell>
                    <StyledTableCell>{this.state.PatientDOB}</StyledTableCell>
                    <StyledTableCell>{this.state.Pripayer}</StyledTableCell>
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <br />
          <br></br>
          <div>
            {" "}
            <h5 style={{ float: "left", paddingLeft: "2%", fontWeight: "600" }}>
              List Of Claim
            </h5>
            &nbsp;{" "}
            <Link
              to="/ar-follow-up-update"
              style={{ textDecoration: "none", color: "black" }}
            >
              <button className="ar-follow-up-btn">
                {" "}
                AR Follow Up Update{" "}
              </button>
            </Link>
            &nbsp;{" "}
            <button className="add-payment" onClick={this.toggle}>
              {" "}
              <BsPlusLg></BsPlusLg>&nbsp;&nbsp;Add Payment{" "}
            </button>
          </div>
          <div style={{ height: "100%", width: "100%", marginTop: "2%" }}>
            <TableContainer>
              <Table
                sx={{ minWidth: "fit-content" }}
                aria-label="customized table"
                style={{ width: "fit-content" }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>CPT Code</StyledTableCell>
                    <StyledTableCell>Payment Type</StyledTableCell>
                    <StyledTableCell>Payment Mode</StyledTableCell>
                    <StyledTableCell>Unit</StyledTableCell>
                    <StyledTableCell>Amount Charges</StyledTableCell>
                    <StyledTableCell>Billed Amount</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>
                      {this.state.DateOfService}
                    </StyledTableCell>
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                    <StyledTableCell>
                      <button
                        style={{
                          backgroundColor: "white",
                          border: "none",
                          padding: "3%",
                          marginLeft: "20%",
                        }}
                      >
                        <FiTrash></FiTrash>
                      </button>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div>
            {" "}
            <p
              style={{
                float: "left",
                paddingLeft: "70%",
                fontWeight: "400",
                marginLeft: "10%",
              }}
            >
              Total Amount:
            </p>{" "}
            &nbsp;
            <p style={{ float: "left", paddingLeft: "1%", fontWeight: "600" }}>
              $ 1,500.00
            </p>
          </div>

          {this.state.show && (
            <Modal
              style={{ width: "30%" }}
              isOpen={this.state.show}
              onHide={this.toggle}
              size="md"
              centered
            >
              <ModalHeader toggle={this.toggle}>Add New Payment</ModalHeader>
              <ModalBody>
                <Row style={{ margin: "0px" }}>
                  <Col>
                    <Label>Date</Label>
                    <Input
                      type="date"
                      style={{
                        fontSize: "13px",
                        width: "154px",
                        height: "38px",
                      }}
                      required
                      onChange={(e) =>
                        this.setState({
                          deliveryagent: e.target.value,
                        })
                      }
                    />
                  </Col>
                  &nbsp;
                  <Col>
                    <Label>CPT Code</Label>
                    <Input
                      style={{
                        width: "83px",
                      }}
                      type="text"
                      className="textbox"
                      required
                      onChange={(e) =>
                        this.setState({
                          deliveryagent: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <Label>Unit</Label>
                    <Input
                      type="text"
                      className="textbox"
                      required
                      onChange={(e) => this.setState({})}
                    />
                  </Col>
                </Row>
                <br />
                <Row style={{ margin: "0px" }}>
                  <Col>
                    <Label>Payment Type</Label>
                    <Select
                      placeholder="Select Payment Type"
                      searchable={true}
                      dropdownHandle={true}
                      searchBy="code"
                      labelField="code"
                      noDataLabel="No matches found"
                      onChange={(values) => this.setState({})}
                    />
                  </Col>
                </Row>
                <br />
                <Row style={{ margin: "0px" }}>
                  <Col>
                    <Label>Payment Mode</Label>
                    <Select
                      placeholder="Select Mode"
                      searchable={true}
                      dropdownHandle={true}
                      searchBy="code"
                      labelField="code"
                      noDataLabel="No matches found"
                      onChange={(values) => this.setState({})}
                    />
                  </Col>
                  <Col>
                    <Label>Amount Charges</Label>
                    <Input
                      type="text"
                      className="textbox"
                      required
                      onChange={(e) => this.setState({})}
                    />
                  </Col>
                </Row>
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  Cancel
                </Button>
                <Button style={{ backgroundColor: "black", color: "white" }}>
                  Add
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}
export default ManagePayment;
