import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import "../../../Styles/subclaim.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { ip } from "../.././Apis/Api";
import ClaimAAllocation from "../ClaimAllocation";
class Errors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: sessionStorage.getItem("queryurl"),
      Location: sessionStorage.getItem("queryLocation"),
      client: sessionStorage.getItem("queryclient"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      subcategoryvalue: null,
      reserrortotalamt: "",
      show2: sessionStorage.getItem("queryclaimid"),
      search: sessionStorage.getItem("searchid"),
      machinetype:sessionStorage.getItem("machinetype"),
      uploaderroramount: "",
      uploaderrorcount: "",
      searchesaamount:"",
      searchesacount:"",
      erroraamount:"",
      erroracount:"",
      systemerrortotalamount:"",
      systemerrortotalcount:"",
      machine2systemerrortotalamount:"",
      machine2systemerrortotalcount:"",
      machine1searchesamount:"",
      machine1searchescount:"",
      machine1erroramount:"",
      machine1errorcount:""
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  
  //upload error count
  getUploadErrorCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.state.client) {
      if (this.state.Location) {
        if (
          this.state.startdate &&
          this.state.enddate
        ) {
          url = `${ip}/claimstatus/claimcategory/uploaderror?start_date=${this.state.startdate}&end_date=${this.state.enddate}&clientId=${this.state.client}&Location=${this.state.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/uploaderror?clientId=${this.state.client}&Location=${this.state.Location}`;
        }
      } else {
        if (
          this.state.startdate &&
          this.state.enddate
        ) {
          url = `${ip}/claimstatus/claimcategory/uploaderror?start_date=${this.state.startdate}&end_date=${this.state.enddate}&clientId=${this.state.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/uploaderror?clientId=${this.state.client}`;
        }
      }
    } else {
      if (this.state.startdate && this.state.enddate) {
        url = `${ip}/claimstatus/claimcategory/uploaderror?start_date=${this.state.startdate}&end_date=${this.state.enddate}`;
      } else {
    url = `${ip}/claimstatus/claimcategory/uploaderror`;
      }}

    await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data.length !== 0) {
          this.setState({
            uploaderroramount: res.data.data[0].totalamount,
            uploaderrorcount: res.data.data[0].totalcount,
          });
        } else {
          this.setState({
            uploaderroramount: 0,
            uploaderrorcount: 0,
          });
        }
      })
      .catch((err) => {});
  };

  //machine 1 error count
  getmachine1ErrorCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.state.client) {
      if (this.state.Location) {
        if (
          this.state.startdate &&
          this.state.enddate
        ) {
          url = `${ip}/claimstatus/claimcategory/error?start_date=${this.state.startdate}&end_date=${this.state.enddate}&clientId=${this.state.client}&Location=${this.state.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/error?clientId=${this.state.client}&Location=${this.state.Location}`;
        }
      } else {
        if (
          this.state.startdate &&
          this.state.enddate
        ) {
          url = `${ip}/claimstatus/claimcategory/error?start_date=${this.state.startdate}&end_date=${this.state.enddate}&clientId=${this.state.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/error?clientId=${this.state.client}`;
        }
      }
    } else {
      if (this.state.startdate && this.state.enddate) {
        url = `${ip}/claimstatus/claimcategory/error?start_date=${this.state.startdate}&end_date=${this.state.enddate}`;
      } else {
    url = `${ip}/claimstatus/claimcategory/error`;
      }
    }

     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data[0].length !==0) {
          this.setState({
            machine1erroramount: res.data.data[0].totalamount,
            machine1errorcount: res.data.data[0].totalcount,
          });
        } else {
          this.setState({
            machine1erroramount: 0,
            machine1errorcount: 0,
          });
        }
      })
      .catch((err) => {});
  };

  //avality error count
  getErrorACount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.state.client) {
      if (this.state.Location) {
        if (
          this.state.startdate &&
          this.state.enddate
        ) {
          url = `${ip}/claimstatus/claimcategory/availityerror?start_date=${this.state.startdate}&end_date=${this.state.enddate}&clientId=${this.state.client}&Location=${this.state.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/availityerror?clientId=${this.state.client}&Location=${this.state.Location}`;
        }
      } else {
        if (
          this.state.startdate &&
          this.state.enddate
        ) {
          url = `${ip}/claimstatus/claimcategory/availityerror?start_date=${this.state.startdate}&end_date=${this.state.enddate}&clientId=${this.state.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/availityerror?clientId=${this.state.client}`;
        }
      }
    } else {
      if (this.state.startdate && this.state.enddate) {
        url = `${ip}/claimstatus/claimcategory/availityerror?start_date=${this.state.startdate}&end_date=${this.state.enddate}`;
      } else {
    url = `${ip}/claimstatus/claimcategory/availityerror`;
      }
    }

     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data.length !== 0) {
          this.setState({
            erroraamount: res.data.data[0].totalamount,
            erroracount: res.data.data[0].totalcount,
          });
        } else {
          this.setState({
            erroraamount: 0,
            erroracount: 0,
          });
        }
      })
      .catch((err) => {});
  };

  //Machine 1 system error count
  getSystemerrorCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.state.client) {
      if (this.state.Location) {
        if (
          this.state.startdate &&
          this.state.enddate
        ) {
          url = `${ip}/claimstatus/claimcategory/systemerror?start_date=${this.state.startdate}&end_date=${this.state.enddate}&clientId=${this.state.client}&Location=${this.state.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/systemerror?clientId=${this.state.client}&Location=${this.state.Location}`;
        }
      } else {
        if (
          this.state.startdate &&
          this.state.enddate
        ) {
          url = `${ip}/claimstatus/claimcategory/systemerror?start_date=${this.state.startdate}&end_date=${this.state.enddate}&clientId=${this.state.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/systemerror?clientId=${this.state.client}`;
        }
      }
    } else {
      if (this.state.startdate && this.state.enddate) {
        url = `${ip}/claimstatus/claimcategory/systemerror?start_date=${this.state.startdate}&end_date=${this.state.enddate}`;
      } else {
    url = `${ip}/claimstatus/claimcategory/systemerror`;
      }
    }

     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data.length !== 0) {
          this.setState({
            systemerrortotalamount: res.data.data[0].totalamount,
            systemerrortotalcount: res.data.data[0].totalcount,
          });
        } else {
          this.setState({
            systemerrortotalamount: 0,
            systemerrortotalcount: 0,
          });
        }
      })
      .catch((err) => {});
  };

  //Machine 2 system error count (availity)
  getMachine2SystemerrorCount = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.state.client) {
      if (this.state.Location) {
        if (
          this.state.startdate &&
          this.state.enddate
        ) {
          url = `${ip}/claimstatus/claimcategory/availityempty?start_date=${this.state.startdate}&end_date=${this.state.enddate}&clientId=${this.state.client}&Location=${this.state.Location}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/availityempty?clientId=${this.state.client}&Location=${this.state.Location}`;
        }
      } else {
        if (
          this.state.startdate &&
          this.state.enddate
        ) {
          url = `${ip}/claimstatus/claimcategory/availityempty?start_date=${this.state.startdate}&end_date=${this.state.enddate}&clientId=${this.state.client}`;
        } else {
          url = `${ip}/claimstatus/claimcategory/availityempty?clientId=${this.state.client}`;
        }
      }
    } else {
      if (this.state.startdate && this.state.enddate) {
        url = `${ip}/claimstatus/claimcategory/availityempty?start_date=${this.state.startdate}&end_date=${this.state.enddate}`;
      } else {
        url = `${ip}/claimstatus/claimcategory/availityempty`;
      }
    }

     await axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.data.length !== 0) {
          this.setState({
            machine2systemerrortotalamount: res.data.data[0].totalamount,
            machine2systemerrortotalcount: res.data.data[0].totalcount,
          });
        } else {
          this.setState({
            machine2systemerrortotalamount: 0,
            machine2systemerrortotalcount: 0,
          });
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    
    // this.getUploadErrorCount();
    this.getErrorACount();
    this.getSystemerrorCount();
    this.getMachine2SystemerrorCount(); 
    this.getmachine1ErrorCount(); 
  }
  render() {
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar>
        <p>
        <Link
          to="/claim-management"
          style={{ textDecoration: "none", color: "white" }}
        >
          Claim Status Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>
        &nbsp;Errors
      </p>
        </Newsidebar>
        <div className="align-column">
          <br />
          <br />
          <div class="row" style={{ marginLeft: "1%" }}>
            <div class="col-md-4 mb-3">
              <div class="card">
                <div class="card-header bg-white">
                  <div
                    style={{
                       background: this.state.search === "System Error" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}>
                       <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                     MACHINE 1 SYSTEM ERROR
                    </span>
                  </div>
                  <div class="card-text">
                  <Link
                    to={{
                      pathname: "/claim/sub-category-details",
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Row
                      onClick={() => {
                        if (this.state.client) {
                          if (this.state.Location) {
                            sessionStorage.setItem(
                              "queryclient",
                              this.state.client
                            );
                            sessionStorage.setItem(
                              "queryLocation",
                              this.state.Location
                            );
                            sessionStorage.setItem(
                              "querycategory",
                              "System Error"
                            );
                            sessionStorage.setItem("subclaimname", "systemerr");
                            sessionStorage.setItem(
                              "searchid",
                              this.state.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.state.show2
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryclient",
                              this.state.client
                            );
                            sessionStorage.setItem("queryLocation", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "System Error"
                            );
                            sessionStorage.setItem("subclaimname", "systemerr");
                            sessionStorage.setItem(
                              "searchid",
                              this.state.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.state.show2
                            );
                          }
                        } else {
                          sessionStorage.setItem("queryclient", "");
                          sessionStorage.setItem(
                            "querycategory",
                            "System Error"
                          );
                          sessionStorage.setItem("subclaimname", "systemerr");
                          sessionStorage.setItem(
                            "searchid",
                            this.state.search
                          );
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.state.show2
                          );
                        }
                      }}
                    >
                      <Col
                        className="claim"
                        onClick={() => {
                          if (this.state.client) {
                            if (this.state.Location) {
                              sessionStorage.setItem(
                                "queryclient",
                                this.state.client
                              );
                              sessionStorage.setItem(
                                "queryLocation",
                                this.state.Location
                              );
                              sessionStorage.setItem(
                                "querycategory",
                                "System Error"
                              );
                              sessionStorage.setItem("subclaimname", "systemerr");
                              sessionStorage.setItem(
                                "searchid",
                                this.state.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.state.show2
                              );
                            } else {
                              sessionStorage.setItem(
                                "queryclient",
                                this.state.client
                              );
                              sessionStorage.setItem("queryLocation", "");
                              sessionStorage.setItem(
                                "querycategory",
                                "System Error"
                              );
                              sessionStorage.setItem("subclaimname", "systemerr");
                              sessionStorage.setItem(
                                "searchid",
                                this.state.search
                              );
                              sessionStorage.setItem(
                                "queryclaimid",
                                this.state.show2
                              );
                            }
                          } else {
                            sessionStorage.setItem("queryclient", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "System Error"
                            );
                            sessionStorage.setItem("subclaimname", "systemerr");
                            sessionStorage.setItem(
                              "searchid",
                              this.state.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.state.show2
                            );
                          }
                        }}
                      >
                        Total Claims:
                        <span>
                          <div
                            className="to"
                            style={{
                              color: "black",
                              fontWeight: "600",
                              fontSize: "13px",
                            }}
                          >
                            {" "}
                            {this.state.systemerrortotalcount}
                          </div>
                        </span>
                      </Col>
                      <Col className="total">
                        Grand Total:
                        <span>
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontSize: "13px",
                              fontWeight: "600",
                            }}
                          >
                            $ {this.state.systemerrortotalamount}
                          </div>
                        </span>
                      </Col>
                    </Row>
                  </Link>
                </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
            <div class="card">
              <div class="card-header bg-white">                 
                <div
                style={{background: this.state.search === "AvailityEmpty" ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}>
                
                  <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                   MACHINE 2 SYSTEM ERROR
                  </span>
                </div>
                <div class="card-text">
                <Link
                  to={{
                    pathname: "/claim/sub-category-details",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row
                    onClick={() => {
                      if (this.state.client) {
                        if (this.state.Location) {
                          sessionStorage.setItem(
                            "queryclient",
                            this.state.client
                          );
                          sessionStorage.setItem(
                            "queryLocation",
                            this.state.Location
                          );
                          sessionStorage.setItem(
                            "querycategory",
                            "Machine2 System Error"
                          );
                          sessionStorage.setItem("subclaimname", "machine2systemerr");
                          sessionStorage.setItem(
                            "searchid",
                            this.state.search
                          );
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.state.show2
                          );
                        } else {
                          sessionStorage.setItem(
                            "queryclient",
                            this.state.client
                          );
                          sessionStorage.setItem("queryLocation", "");
                          sessionStorage.setItem(
                            "querycategory",
                            "Machine2 System Error"
                          );
                          sessionStorage.setItem("subclaimname", "machine2systemerr");
                          sessionStorage.setItem(
                            "searchid",
                            this.state.search
                          );
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.state.show2
                          );
                        }
                      } else {
                        sessionStorage.setItem("queryclient", "");
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 System Error"
                        );
                        sessionStorage.setItem("subclaimname", "machine2systemerr");
                        sessionStorage.setItem(
                          "searchid",
                          this.state.search
                        );
                        sessionStorage.setItem(
                          "queryclaimid",
                          this.state.show2
                        );
                      }
                    }}
                  >
                    <Col
                      className="claim"
                      onClick={() => {
                        if (this.state.client) {
                          if (this.state.Location) {
                            sessionStorage.setItem(
                              "queryclient",
                              this.state.client
                            );
                            sessionStorage.setItem(
                              "queryLocation",
                              this.state.Location
                            );
                            sessionStorage.setItem(
                              "querycategory",
                              "Machine2 System Error"
                            );
                            sessionStorage.setItem("subclaimname", "machine2systemerr");
                            sessionStorage.setItem(
                              "searchid",
                              this.state.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.state.show2
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryclient",
                              this.state.client
                            );
                            sessionStorage.setItem("queryLocation", "");
                            sessionStorage.setItem(
                              "querycategory",
                              "Machine2 System Error"
                            );
                            sessionStorage.setItem("subclaimname", "machine2systemerr");
                            sessionStorage.setItem(
                              "searchid",
                              this.state.search
                            );
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.state.show2
                            );
                          }
                        } else {
                          sessionStorage.setItem("queryclient", "");
                          sessionStorage.setItem(
                            "querycategory",
                            "Machine2 System Error"
                          );
                          sessionStorage.setItem("subclaimname", "machine2systemerr");
                          sessionStorage.setItem(
                            "searchid",
                            this.state.search
                          );
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.state.show2
                          );
                        }
                      }}
                    >
                      Total Claims:
                      <span>
                        <div
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "13px",
                          }}
                        >
                          {" "}
                          {this.state.machine2systemerrortotalcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="total">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "13px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.machine2systemerrortotalamount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
              </div>
              </div>
            </div>
          </div>
            <div class="col-md-4 mb-3">
              <div class="card">
                <div class="card-header bg-white">
                  <div
            style={{background: (this.state.search === "error" && this.state.machinetype === "Changehealthcare") ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
                  >
                  <span class="d-inline-block" style={{ fontWeight: "bold" }}>
                  MACHINE 1 ERROR
                </span>
                  </div>
                  <div class="card-text">
                  <Link
                  to={{
                    pathname: "/error/machine1 error",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Row
                    onClick={() => {
                      if (this.state.client) {
                        if (this.state.Location) {
                          sessionStorage.setItem(
                            "queryurl",
                            `ClaimCategory=error&clientId=${this.state.client}&Location=${this.state.Location}&Machine=Changehealthcare`
                          );
                          sessionStorage.setItem(
                            "queryclient",
                            this.state.client
                          );
                          sessionStorage.setItem(
                            "queryLocation",
                            this.state.Location
                          );
                          sessionStorage.setItem("searchid", this.state.search);
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.state.show2
                          );
                        } else {
                          sessionStorage.setItem(
                            "queryurl",
                            `ClaimCategory=error&clientId=${this.state.client}&Machine=Changehealthcare`
                          );
                          sessionStorage.setItem(
                            "queryclient",
                            this.state.client
                          );
                          sessionStorage.setItem("queryLocation", "");
                          sessionStorage.setItem("searchid", this.state.search);
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.state.show2
                          );
                        }
                      } else {
                        sessionStorage.setItem("queryurl", `ClaimCategory=error&Machine=Changehealthcare`);
                        sessionStorage.setItem("queryclient", "");
                        sessionStorage.setItem("searchid", this.state.search);
                        sessionStorage.setItem("queryclaimid", this.state.show2);
                      }
                    }}
                  >
                    <Col
                      className="claim"
                      onClick={() => {
                        if (this.state.client) {
                          if (this.state.Location) {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=error&clientId=${this.state.client}&Location=${this.state.Location}&Machine=Changehealthcare`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.state.client
                            );
                            sessionStorage.setItem(
                              "queryLocation",
                              this.state.Location
                            );
                            sessionStorage.setItem("searchid", this.state.search);
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.state.show2
                            );
                          } else {
                            sessionStorage.setItem(
                              "queryurl",
                              `ClaimCategory=error&clientId=${this.state.client}&Machine=Changehealthcare`
                            );
                            sessionStorage.setItem(
                              "queryclient",
                              this.state.client
                            );
                            sessionStorage.setItem("queryLocation", "");
                            sessionStorage.setItem("searchid", this.state.search);
                            sessionStorage.setItem(
                              "queryclaimid",
                              this.state.show2
                            );
                          }
                        } else {
                          sessionStorage.setItem(
                            "queryurl",
                            `ClaimCategory=error&Machine=Changehealthcare`
                          );
                          sessionStorage.setItem("queryclient", "");
                          sessionStorage.setItem("searchid", this.state.search);
                          sessionStorage.setItem(
                            "queryclaimid",
                            this.state.show2
                          );
                        }
                      }}
                    >
                      Total Claims:
                      <span>
                        <div
                          className="to"
                          style={{
                            color: "black",
                            fontWeight: "600",
                            fontSize: "13px",
                          }}
                        >
                          {" "}
                          {this.state.machine1errorcount}
                        </div>
                      </span>
                    </Col>
                    <Col className="total">
                      Grand Total:
                      <span>
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontSize: "13px",
                            fontWeight: "600",
                          }}
                        >
                          $ {this.state.machine1erroramount}
                        </div>
                      </span>
                    </Col>
                  </Row>
                </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginLeft: "1%" }}>
          <div class="col-md-4 mb-3">
          <div class="card">
            <div class="card-header bg-white">
            <div
            style={{background: (this.state.search === "error" && this.state.machinetype === "Availity") ? "radial-gradient(circle at 10% 20%, rgb(0, 52, 89) 0%, rgb(0, 168, 232) 90%)" : "linear-gradient(to right, #002755 65%, #EB1A23)", color:"white", padding:"3px"}}
            >
            <span class="d-inline-block" style={{ fontWeight: "bold" }}>
            MACHINE 2 ERROR
          </span>
            </div>

            <div class="card-text">
            <Link
              to={{
                pathname: "/error/machine2 Error",
              }}
              style={{ textDecoration: "none" }}
            >
              <Row
                onClick={() => {
                  if (this.state.client) {
                    if (this.state.Location) {
                      sessionStorage.setItem(
                        "queryclient",
                        this.state.client
                      );
                      sessionStorage.setItem(
                        "queryurl",
                        `ClaimCategory=error&clientId=${this.state.client}&Location=${this.state.Location}&Machine=Availity`
                      );
                      sessionStorage.setItem(
                        "queryLocation",
                        this.state.Location
                      );
                      sessionStorage.setItem(
                        "querycategory",
                        "Machine2 Error"
                      );
                      sessionStorage.setItem("subclaimname", "avalityerr");
                      sessionStorage.setItem(
                        "searchid",
                        this.state.search
                      );
                      sessionStorage.setItem(
                        "queryclaimid",
                        this.state.show2
                      );
                    } else {
                      sessionStorage.setItem(
                        "queryclient",
                        this.state.client
                      );
                      sessionStorage.setItem(
                        "queryurl",
                        `ClaimCategory=error&clientId=${this.state.client}&Machine=Availity`
                      );
                      sessionStorage.setItem("queryLocation", "");
                      sessionStorage.setItem(
                        "querycategory",
                        "Machine2 Error"
                      );
                      sessionStorage.setItem("subclaimname", "avalityerr");
                      sessionStorage.setItem(
                        "searchid",
                        this.state.search
                      );
                      sessionStorage.setItem(
                        "queryclaimid",
                        this.state.show2
                      );
                    }
                  } else {
                    sessionStorage.setItem("queryclient", "");
                    sessionStorage.setItem(
                      "querycategory",
                      "Machine2 Error"
                    );
                    sessionStorage.setItem(
                      "queryurl",
                      `ClaimCategory=error&Machine=Availity`
                    );
                    sessionStorage.setItem("subclaimname", "avalityerr");
                    sessionStorage.setItem(
                      "searchid",
                      this.state.search
                    );
                    sessionStorage.setItem(
                      "queryclaimid",
                      this.state.show2
                    );
                  }
                }}
              >
                <Col
                  className="claim"
                  onClick={() => {
                    if (this.state.client) {
                      if (this.state.Location) {
                        sessionStorage.setItem(
                          "queryclient",
                          this.state.client
                        );
                        sessionStorage.setItem(
                          "queryurl",
                          `ClaimCategory=error&clientId=${this.state.client}&Location=${this.state.Location}&Machine=Availity`
                        );
                        sessionStorage.setItem(
                          "queryLocation",
                          this.state.Location
                        );
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem("subclaimname", "avalityerr");
                        sessionStorage.setItem(
                          "searchid",
                          this.state.search
                        );
                        sessionStorage.setItem(
                          "queryclaimid",
                          this.state.show2
                        );
                      } else {
                        sessionStorage.setItem(
                          "queryclient",
                          this.state.client
                        );
                        sessionStorage.setItem(
                          "queryurl",
                          `ClaimCategory=error&clientId=${this.state.client}&Machine=Availity`
                        );
                        sessionStorage.setItem("queryLocation", "");
                        sessionStorage.setItem(
                          "querycategory",
                          "Machine2 Error"
                        );
                        sessionStorage.setItem("subclaimname", "avalityerr");
                        sessionStorage.setItem(
                          "searchid",
                          this.state.search
                        );
                        sessionStorage.setItem(
                          "queryclaimid",
                          this.state.show2
                        );
                      }
                    } else {
                      sessionStorage.setItem("queryclient", "");
                      sessionStorage.setItem(
                        "querycategory",
                        "Machine2 Error"
                      );
                      sessionStorage.setItem(
                        "queryurl",
                        `ClaimCategory=error&Machine=Availity`
                      );
                      sessionStorage.setItem("subclaimname", "avalityerr");
                      sessionStorage.setItem(
                        "searchid",
                        this.state.search
                      );
                      sessionStorage.setItem(
                        "queryclaimid",
                        this.state.show2
                      );
                    }
                  }}
                >
                  Total Claims:
                  <span>
                    <div
                      className="to"
                      style={{
                        color: "black",
                        fontWeight: "600",
                        fontSize: "13px",
                      }}
                    >
                      {" "}
                      {this.state.erroracount}
                    </div>
                  </span>
                </Col>
                <Col className="total">
                  Grand Total:
                  <span>
                    {" "}
                    <div
                      style={{
                        color: "black",
                        fontSize: "13px",
                        fontWeight: "600",
                      }}
                    >
                      $ {this.state.erroraamount}
                    </div>
                  </span>
                </Col>
              </Row>
            </Link>
          </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
   
        </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Errors;
