import React, { Component } from "react";
import axios from "axios";
import Newsidebar from "../Dashboard/Newsidebar";
import { ip } from ".././Apis/Api";
import Tabs, { Tab } from "react-best-tabs";
import Select from "react-dropdown-select";
import "../../Styles/databasesync.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Row, Col, Card } from "react-bootstrap";
class ApiSync extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
    };
  }

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
  }
  render() {
    return (
      <div>
      <Newsidebar name="API Sync" />
      <div className="align-row">
        <div className="align-column">
          <div style={{ paddingTop: "4%" }} className="nosql-container">
            <div className="nosql1">
              <Card
                style={{
                  background: "#07326c",
                  height: "50px",
                  color: "white",
                }}
              >
                <Col
                  style={{
                    textAlign: "left",
                    paddingTop: "0.5%",
                    fontSize: "25px",
                    fontWeight: "500",
                    marginLeft: "2%",
                  }}
                >
                  API Connect
                </Col>
              </Card>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Auth Service Endpoint
                  </h5>
                </Col>
                <Col md="6">
                  <Input type="text" placeholder="" />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Get Service Endpoint
                  </h5>
                </Col>
                <Col md="6">
                  <Input type="text" placeholder="" />
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Consumer Key
                  </h5>
                </Col>
                <Col md="6">
                  <Input type="text" placeholder="" />
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Consumer Secret
                  </h5>
                </Col>
                <Col md="6">
                  <Input type="text" placeholder="" />
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col
                  style={{
                    fontSize: "18px",
                    textAlign: "Left",
                    color: "#07326c",
                    fontWeight: "600",
                    marginLeft: "8%",
                  }}
                >
                  Optional Headers
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Header Key
                  </h5>
                </Col>
                <Col md="6">
                  <Input type="text" placeholder="" />
                </Col>
              </Row>
              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="4">
                  <h5 className="label" style={{ textAlign: "left" }}>
                    Header Token
                  </h5>
                </Col>
                <Col md="6">
                  <Input type="text" placeholder="" />
                </Col>
              </Row>

              <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                <Col md="10" style={{ display: "flex" }}>
                  <Button style={{ background: "#07326c" }}>Save</Button> &nbsp;
                  <Button style={{ background: "#07326c" }}>Connect</Button>
                  &nbsp;
                  <Button style={{ background: "#07326c" }}>
                    Test Connection
                  </Button>
                </Col>
              </Row>
              <br />
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
export default ApiSync;
