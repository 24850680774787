import { Component } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { ip } from "../../../Apis/Api";
const data2 = [
  {
    _id: "",
    totalamt: 800,
    totalclaims: 30,
    percent: 82,
  },
  {
    _id: "",
    totalamt: 800,
    totalclaims: 20,
    percent: 59,
  },
  {
    _id: "",
    totalamt: 400,
    totalclaims: 10,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 10,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 5,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 5,
    percent: 27,
  },
  {
    _id: "",
    totalamt: 200,
    totalclaims: 5,
    percent: 27,
  },
];
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};
class WebCharts__Grid2_cell2 extends Component {
  state = {
    payer_age_wise: [],
    _id: "",
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.from != 0 && this.props.to != 0) {
          url = `${ip}/outcome/agewise?start_date=${this.props.from}&end_date=${this.props.to}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/outcome/agewise?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.from != 0 && this.props.to != 0) {
          url = `${ip}/outcome/agewise?start_date=${this.props.from}&end_date=${this.props.to}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/outcome/agewise?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.from != 0 && this.props.to != 0) {
        url = `${ip}/outcome/agewise?start_date=${this.props.from}&end_date=${this.props.to}`;
      } else {
        url = `${ip}/outcome/agewise`;
      }
    }
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        let i;
        let data = [];
        if (req.data.age_wise.nis.length !== 0) {
          for (i = 0; i < 5; i++) {
            data.push(req.data.age_wise.nis[i]);
          }
          this.setState({ payer_age_wise: req.data.age_wise.nis });
        } else {
          this.setState({
            payer_age_wise: data2,
          });
        }
      })
      .catch((err) => {});
  };
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getDetails();
  }
  componentDidUpdate(prevProps) {
    if (
      !(this.props.from === prevProps.from && this.props.to === prevProps.to)
    ) {
      this.getDetails();
    } else if (this.props.client !== prevProps.client) {
      this.getDetails();
    } else if (this.props.Location !== prevProps.Location) {
      this.getDetails();
    }
  }
  render() {
    return (
      <div>
        {this.props.agegrpwise__one === "$" && (
          <div onMouseOver={() => (tooltip = "totalAmount")}>
            <BarChart
              width={1100}
              height={500}
              data={this.state.payer_age_wise}
              margin={{
                top: 5,
                right: 10,
                left: 100,
                bottom: 5,
              }}
            >
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis
                dataKey={"_id"}
                height={120}
                interval={0}
                angle={"-45"}
                minTickGap={6}
                tick={{
                  strokeWidth: 0,
                  fontSize: "11",
                  top: 400,
                  textAnchor: "end",
                  dy: 6,
                }}
              />
              <YAxis dataKey={"totalAmount"} />
              <Tooltip
                cursor={{ fill: "transparent" }}
                content={<CustomTooltip />}
              />
              <Legend />
              <Bar
                dataKey={"totalAmount"}
                fill="#8884d8"
                barSize={35}
                name="Total Amount"
              />
            </BarChart>
          </div>
        )}
        {this.props.agegrpwise__one === "#" && (
          <div onMouseOver={() => (tooltip = "totalClaims")}>
            <BarChart
              width={1100}
              height={500}
              data={this.state.payer_age_wise}
              margin={{
                top: 5,
                right: 10,
                left: 100,
                bottom: 5,
              }}
            >
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis
                dataKey="_id"
                height={120}
                interval={0}
                angle={"-45"}
                minTickGap={6}
                tick={{
                  strokeWidth: 0,
                  fontSize: "11",
                  top: 400,
                  textAnchor: "end",
                  dy: 6,
                }}
              />
              <YAxis dataKey={"totalClaims"} />
              <Tooltip
                cursor={{ fill: "transparent" }}
                content={<ClaimTooltip />}
              />
              <Legend />
              <Bar
                dataKey="totalClaims"
                fill="#8884d8"
                barSize={35}
                name="Total Claims"
              />
            </BarChart>
          </div>
        )}
      </div>
    );
  }
}
export default WebCharts__Grid2_cell2;
