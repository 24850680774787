import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col, Card } from "react-bootstrap";
import "../../Styles/Eligibilitycheck.css";
import moment from "moment-timezone";
import { ip } from "../Apis/Api";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { FormGroup, Input } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Viewmoreeligibility from "./Viewmoreeligibility";
import { Link } from "react-router-dom";

class EligibilityCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      checkvalue: false,
      servicetypelist: [],
      servicetypename: "Plan Coverage and General Benefits",
      ClaimID: "",
      firstname: "",
      lastname: "",
      middlename: "",
      gender: "",
      dob: "",
      dos: "",
      memberID: "",
      payername: "",
      payerid: "",
      payeridarray:[],
      payeridload:true,
      orgname: "",
      npi: "",
      depfirstname: "",
      deplastname: "",
      depmiddlename: "",
      depgender: "",
      depdob: "",
      accno: "",
      payernameload: true,
      reqservicecode: "",
    };
  }

  getOrgname = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = await axios
      .get(
        `${ip}/neweligibility/getorganizationname?clientId=${this.state.client}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          orgname: res.data.eligibility.Display_name,
          npi: res.data.eligibility.NPI,
        });
      })
      .catch((err) => {});
  };
  getPayerInfo = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = await axios
      .get(`${ip}/payermaster/all?clientId=${this.state.client}`, {
        headers: headers,
      })
      .then((res) => {
        let data = res.data.payer;
        console.log("payer id values", data)
        let data1 = data.map((e) => ({
          value: e.payerName,
          label: e.payerName,
        }));
        this.setState(
          {
            payernamearray: res.data.payer,
            payernameload: false,
          },
          () => {
            console.log("data payer", this.state.payernamearray);
          }
        );
      })
      .catch((err) => {});
  };
  getidinfo = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = await axios
      .get(`${ip}/neweligibility/payeridlist?clientId=${this.state.client}`, {
        headers: headers,
      })
      .then((res) => {
        this.setState(
          {
            payeridarray: res.data.payer,
            payeridload:false,
          }
        );
      })
      .catch((err) => {});
  };
  getPayerid = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = await axios
      .get(
        `${ip}/neweligibility/getpayerid?clientId=${this.state.client}&payername=${this.state.payername}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          payerid: res.data.eligibility.payer_id,
        });
      })
      .catch((err) => {});
  };
  getPayerName = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = await axios
      .get(
        `${ip}/neweligibility/getpayerid?clientId=${this.state.client}&payerid=${this.state.payerid}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          payername: res.data.eligibility.payerName,
        });
      })
      .catch((err) => {});
  };
  getServiceInfo = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = await axios
      .get(`${ip}/servicetype/all`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          servicetypelist: res.data.ServiceType,
        });
      })
      .catch((err) => {});
  };
  addDetails = async () => {
    let headers;
    let data;
    let dosvalue = moment(this.state.dos).format("MM-DD-YYYY");
    let dobvalue = moment(this.state.dob).format("YYYYMMDD");
    let dosvalue1 = moment(this.state.dos).format("YYYYMMDD");
    // let servicetypevalue = this.state.servicetypename.replaceAll(/\[.+?\]/, "")
    if (this.state.checkvalue === false) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      data = {
        PayerID: this.state.payerid,
        organizationName: this.state.orgname,
        Npi: this.state.npi,
        MemberID: this.state.memberID,
        FirstName: this.state.firstname,
        LastName: this.state.lastname,
        Gender: this.state.gender,
        DOB: dobvalue,
        DOS: dosvalue,
        BeginingDOS: dosvalue1,
        EndDOS: dosvalue1,
        clientId: parseInt(this.state.client),
        InsuranceName: this.state.payername,
        ClaimID: this.state.ClaimID,
        Patientnumber: this.state.accno,
        serviceType: this.state.servicetypename,
      };
    } else if (this.state.checkvalue === true) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      data = {
        subscriber: this.state.checkvalue,
        PayerID: this.state.payerid,
        organizationName: this.state.orgname,
        Npi: this.state.npi,
        MemberID: this.state.memberID,
        FirstName: this.state.firstname,
        LastName: this.state.lastname,
        Gender: this.state.gender,
        DOB: dobvalue,
        DOS: dosvalue,
        BeginingDOS: dosvalue1,
        EndDOS: dosvalue1,
        clientId: parseInt(this.state.client),
        InsuranceName: this.state.payername,
        ClaimID: this.state.ClaimID,
        SubscriberFirstName: this.state.depfirstname,
        SubscriberLastName: this.state.deplastname,
        SubscriberGender: this.state.depgender,
        SubscriberDOB: this.state.depdob,
        Patientnumber: this.state.accno,
        serviceType: this.state.servicetypename,
        serviceCode: this.state.reqservicecode,
      };
    }
    const res = await axios
      .post(`${ip}/neweligibility/neweligibilitycheck`, data, {
        headers: headers,
      })
      .then((res) => {
        toast.success("Details Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // Set the eligibility claim ID in the session storage
        sessionStorage.setItem("Eligibilityclaimid", this.state.ClaimID);

        // Trigger navigation to the "Viewmoreeligibility" link
        this.props.history.push("/viewmoreeligibility");
      })
      .catch((err) => {});
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  reset = () => {
    this.setState({
      checkvalue: false,
      ClaimID: "",
      firstname: "",
      lastname: "",
      middlename: "",
      gender: "",
      dob: "",
      dos: "",
      memberID: "",
      payername: "",
      payerid: "",
      orgname: "",
      npi: "",
      depfirstname: "",
      deplastname: "",
      depmiddlename: "",
      depgender: "",
      depdob: "",
      accno: "",
    });
  };
  handleChange = (payerName) => {
    this.setState({
      payername: payerName,
    });
  };
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getOrgname();
    this.getPayerInfo();
    this.getServiceInfo();
    this.getidinfo();
    // this.getPayerid();
  }
  render() {
    return (
      <div>
      <Newsidebar name="Eligibility Check" />
      <div
        className="align-row"
        style={{ overflow: "hidden", fontSize: "13px" }}
      >
        <div className="align-column">
          <Card style={{ marginTop: "4%", margin: "20px", fontSize: "13px" }}>
            <ToastContainer></ToastContainer>
            <div className="eligibility_grid" style={{ fontSize: "13px" }}>
              <div className="nosql1">
                <Card
                  style={{
                    background: "#07326c",
                    height: "35px",
                    color: "white",
                    fontSize: "13px",
                  }}
                >
                  <Col
                    style={{
                      textAlign: "left",
                      paddingTop: "1.1%",
                      fontSize: "15px",
                      fontWeight: "500",
                      marginLeft: "2%",
                    }}
                  >
                    Patient Information
                  </Col>
                </Card>
                <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5
                      className="label"
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      Claim ID
                    </h5>
                  </Col>
                  <Col md="6">
                    <Input
                      type="text"
                      placeholder=""
                      value={this.state.ClaimID}
                      style={{ fontSize: "13px" }}
                      onChange={(e) =>
                        this.setState({
                          ClaimID: e.target.value,
                        })
                      }
                      tabindex="1"
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5
                      className="label"
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      Patient Account Number
                    </h5>
                  </Col>
                  <Col md="6">
                    <Input
                      type="text"
                      placeholder=""
                      value={this.state.accno}
                      style={{ fontSize: "13px" }}
                      onChange={(e) =>
                        this.setState({
                          accno: e.target.value,
                        })
                      }
                      tabindex="2"
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5
                      className="label"
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      First Name
                    </h5>
                  </Col>
                  <Col md="6">
                    <Input
                      type="text"
                      placeholder=""
                      value={this.state.firstname}
                      style={{ fontSize: "13px" }}
                      onChange={(e) =>
                        this.setState({
                          firstname: e.target.value,
                        })
                      }
                      tabindex="3"
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5
                      className="label"
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      Last Name
                    </h5>
                  </Col>
                  <Col md="6">
                    <Input
                      type="text"
                      placeholder=""
                      value={this.state.lastname}
                      style={{ fontSize: "13px" }}
                      onChange={(e) =>
                        this.setState({
                          lastname: e.target.value,
                        })
                      }
                      tabindex="4"
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5
                      className="label"
                      style={{
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        fontSize: "13px",
                      }}
                    >
                      Middle Name
                    </h5>
                  </Col>
                  <Col md="2">
                    <Input
                      type="text"
                      placeholder=""
                      value={this.state.middlename}
                      style={{ fontSize: "13px" }}
                      onChange={(e) =>
                        this.setState({
                          middlename: e.target.value,
                        })
                      }
                      tabindex="5"
                    />
                  </Col>
                  <Col md="2">
                    <h5
                      className="label"
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      Gender
                    </h5>
                  </Col>
                  <Col md="2">
                    <Input
                      type="select"
                      placeholder=""
                      value={this.state.gender}
                      style={{ fontSize: "13px" }}
                      onChange={(e) =>
                        this.setState({
                          gender: e.target.value,
                        })
                      }
                      tabindex="6"
                    >
                      <option value="" hidden>
                        Select..
                      </option>
                      <option value="M">M</option>
                      <option value="F">F</option>
                    </Input>
                  </Col>
                </Row>
                <Row
                  style={{
                    paddingTop: "4%",
                    justifyContent: "center",
                    fontSize: "13px",
                  }}
                >
                  <Col md="4">
                    <h5
                      className="label"
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      DOB
                    </h5>
                  </Col>
                  <Col md="6">
                    <Input
                      type="date"
                      placeholder=""
                      value={this.state.dob}
                      style={{ fontSize: "13px" }}
                      onChange={(e) =>
                        this.setState({
                          dob: e.target.value,
                        })
                      }
                      tabindex="6"
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    paddingTop: "4%",
                    justifyContent: "center",
                    fontSize: "13px",
                  }}
                >
                  <Col md="4">
                    <h5
                      className="label"
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      Member ID
                    </h5>
                  </Col>
                  <Col md="6">
                    <Input
                      type="text"
                      placeholder=""
                      value={this.state.memberID}
                      style={{ fontSize: "13px" }}
                      onChange={(e) =>
                        this.setState({
                          memberID: e.target.value,
                        })
                      }
                      tabindex="7"
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5
                      className="label"
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      DOS
                    </h5>
                  </Col>
                  <Col md="6">
                    <Input
                      type="date"
                      placeholder=""
                      style={{ fontSize: "13px" }}
                      value={this.state.dos}
                      onChange={(e) =>
                        this.setState({
                          dos: e.target.value,
                        })
                      }
                      tabindex="8"
                    />
                  </Col>
                </Row>
                <br />
              </div>
              <div>
                <Card
                  style={{
                    background: "#07326c",
                    height: "35px",
                    color: "white",
                    fontSize: "13px",
                  }}
                >
                  <Col
                    style={{
                      textAlign: "left",
                      paddingTop: "1.1%",
                      fontSize: "15px",
                      fontWeight: "500",
                      marginLeft: "2%",
                    }}
                  >
                    Payer Information
                  </Col>
                </Card>
                <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5
                      className="label"
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      Payer Name
                    </h5>
                  </Col>
                  <Col md="6">
                    {this.state.payerid ?
                    (
                      <Input
                        type="text"
                        placeholder=""
                        value={this.state.payername}
                        style={{ fontSize: "13px" }}
                        onChange={(e) =>
                          this.setState({
                            payername: e.target.value,
                          })
                        }
                        tabindex="10"
                      />
                    ) : (
                      <Typeahead
                      id="typeahed1"
                      style={{ fontSize: "13px" }}
                      size="13px"
                      isLoading={this.state.payernameload}
                      clearButton={true}
                      options={this.state.payernamearray}
                      labelKey={(option) =>
                        (this.state.payername = `${option.payerName}`)
                      }
                      placeholder="Select..."
                      onChange={(option) =>
                        this.setState(
                          {
                            payername: option.payerName,
                            payerid: "",
                          },
                          () => {
                            this.getPayerid();
                          }
                        )
                      }
                      tabindex="9"
                    />
                    )

                    }
                
                  </Col>
                </Row>
                <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5
                      className="label"
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      Payer Id
                    </h5>
                  </Col>
                  <Col md="6">
                    {this.state.payername ? (
                      <Input
                        type="text"
                        placeholder=""
                        value={this.state.payerid}
                        style={{ fontSize: "13px" }}
                        onChange={(e) =>
                          this.setState({
                            payerid: e.target.value,
                          })
                        }
                        tabindex="10"
                      />
                    ) : (
                      <Typeahead
                        id="typeahed1"
                        style={{ fontSize: "13px" }}
                        size="13px"
                        isLoading={this.state.payeridload}
                        clearButton={true}
                        defaultInputValue={this.state.payerid}
                        options={this.state.payeridarray}
                        labelKey={(option) =>
                          (this.state.payerid = `${option._id}`)
                        }
                        placeholder="Select..."
                        onChange={(option) =>
                          this.setState(
                            {
                              payerid: option.payerId,
                              payername:""
                            },
                            () => {
                              this.getPayerName();
                            }
                          )
                        }
                        tabindex="10"
                      />
                    )}
                  </Col>
                </Row>
                <br />
                <br />
                <Card
                  style={{
                    background: "#07326c",
                    height: "35px",
                    color: "white",
                    fontSize: "13px",
                  }}
                >
                  <Col
                    style={{
                      textAlign: "left",
                      paddingTop: "1.1%",
                      fontSize: "15px",
                      fontWeight: "500",
                      marginLeft: "2%",
                    }}
                  >
                    Billing Provider Information
                  </Col>
                </Card>
                <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5
                      className="label"
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      Organization Name
                    </h5>
                  </Col>
                  <Col md="6">
                    <Input
                      type="text"
                      placeholder=""
                      value={this.state.orgname}
                      style={{ fontSize: "13px" }}
                      onChange={(e) =>
                        this.setState({
                          orgname: e.target.value,
                        })
                      }
                      tabindex="11"
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5
                      className="label"
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      NPI
                    </h5>
                  </Col>
                  <Col md="6">
                    <Input
                      type="text"
                      placeholder=""
                      value={this.state.npi}
                      style={{ fontSize: "13px" }}
                      onChange={(e) =>
                        this.setState({
                          npi: e.target.value,
                        })
                      }
                      tabindex="12"
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                  <Col md="4">
                    <h5
                      className="label"
                      style={{ textAlign: "left", fontSize: "13px" }}
                    >
                      Service Type
                    </h5>
                  </Col>
                  <Col md="6">
                    <Typeahead
                      id="typeahed1"
                      size="13"
                      style={{ fontSize: "13px" }}
                      clearButton={true}
                      options={this.state.servicetypelist}
                      defaultInputValue={this.state.servicetypename}
                      labelKey={(option) =>
                        (this.state.servicetypename = `${option.serviceType}`) +
                        "(" +
                        option.code +
                        ")"
                      }
                      placeholder="Select..."
                      onChange={(option) =>
                        this.setState({
                          servicetypename: option.serviceType,
                          reqservicecode: option.code,
                        })
                      }
                      tabindex="13"
                    />
                  </Col>
                </Row>
              </div>

              <div>
                <Card
                  style={{
                    background: "#07326c",
                    height: "35px",
                    color: "white",
                    fontSize: "13px",
                  }}
                >
                  <Col
                    style={{
                      textAlign: "left",
                      paddingTop: "1.1%",
                      fontSize: "15px",
                      fontWeight: "500",
                      marginLeft: "2%",
                    }}
                  >
                    <FormGroup>
                      <Input
                        tabindex="14"
                        type="checkbox"
                        checked={this.state.checkvalue}
                        onChange={() => {
                          this.setState({
                            checkvalue: !this.state.checkvalue,
                          });
                        }}
                        style={{ fontSize: "13px" }}
                      />
                      &nbsp;Check if patient is subscriber
                    </FormGroup>
                  </Col>
                </Card>
                {this.state.checkvalue === true ? (
                  <div>
                    <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                      <Col md="4">
                        <h5
                          className="label"
                          style={{ textAlign: "left", fontSize: "13px" }}
                        >
                          First Name
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input
                          type="text"
                          placeholder=""
                          value={this.state.depfirstname}
                          style={{ fontSize: "13px" }}
                          onChange={(e) =>
                            this.setState({
                              depfirstname: e.target.value,
                            })
                          }
                          tabindex="15"
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                      <Col md="4">
                        <h5
                          className="label"
                          style={{ textAlign: "left", fontSize: "13px" }}
                        >
                          Last Name
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input
                          type="text"
                          placeholder=""
                          value={this.state.deplastname}
                          style={{ fontSize: "13px" }}
                          onChange={(e) =>
                            this.setState({
                              deplastname: e.target.value,
                            })
                          }
                          tabindex="16"
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                      <Col md="4">
                        <h5
                          className="label"
                          style={{
                            textAlign: "left",
                            whiteSpace: "nowrap",
                            fontSize: "13px",
                          }}
                        >
                          Middle Name
                        </h5>
                      </Col>
                      <Col md="2">
                        <Input
                          type="text"
                          placeholder=""
                          style={{ fontSize: "13px" }}
                          value={this.state.depmiddlename}
                          onChange={(e) =>
                            this.setState({
                              depmiddlename: e.target.value,
                            })
                          }
                          tabindex="17"
                        />
                      </Col>
                      <Col md="2">
                        <h5
                          className="label"
                          style={{ textAlign: "left", fontSize: "13px" }}
                        >
                          Gender
                        </h5>
                      </Col>
                      <Col md="2">
                        <Input
                          type="select"
                          placeholder=""
                          value={this.state.depgender}
                          style={{ fontSize: "13px" }}
                          onChange={(e) =>
                            this.setState({
                              depgender: e.target.value,
                            })
                          }
                          tabindex="18"
                        >
                          <option value="" hidden>
                            Select...
                          </option>
                          <option value="M">M</option>
                          <option value="F">F</option>
                        </Input>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "4%", justifyContent: "center" }}>
                      <Col md="4">
                        <h5
                          className="label"
                          style={{ textAlign: "left", fontSize: "13px" }}
                        >
                          DOB
                        </h5>
                      </Col>
                      <Col md="6">
                        <Input
                          type="date"
                          placeholder=""
                          value={this.state.depdob}
                          style={{ fontSize: "13px" }}
                          onChange={(e) =>
                            this.setState({
                              depdob: e.target.value,
                            })
                          }
                          tabindex="19"
                        />
                      </Col>
                    </Row>
                  </div>
                ) : null}
              </div>
              {this.state.checkvalue === false ? (
                <div
                  class="btn-group"
                  role="group"
                  style={{ height: "70px", width: "200px", marginLeft: "50%" }}
                >
                  <button
                    type="button"
                    class="btn"
                    style={{
                      backgroundColor: "#7DD657",
                      color: "#000",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      this.addDetails();
                    }}
                  >
                    Save & View Eligibility
                  </button>
                  <button
                    type="button"
                    class="btn"
                    style={{ backgroundColor: "#041c3c", color: "#fff" }}
                    onClick={this.reset}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div
                  class="btn-group"
                  role="group"
                  style={{
                    marginTop: "47%",
                    height: "70px",
                    width: "200px",
                    marginLeft: "58%",
                  }}
                >
                  <button
                    type="button"
                    class="btn"
                    style={{
                      backgroundColor: "#7DD657",
                      color: "#000",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      this.addDetails();
                    }}
                  >
                    Save & View Eligibility
                  </button>
                  <button
                    type="button"
                    class="btn"
                    style={{ backgroundColor: "#041c3c", color: "#fff" }}
                    onClick={this.reset}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
      </div>
    );
  }
}
export default EligibilityCheck;
