import { Component } from "react";
import {
  ResponsiveContainer,
  Legend,
  RadialBar,
  RadialBarChart,
  PolarAngleAxis,
} from "recharts";

const circleSize = 200;

class ProductUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{ name: "Productivity", value: this.props.productivityperc }],
    };
  }
  render() {
    return (
      <div>
        <ResponsiveContainer width={"59%"} height={130}>
          <RadialBarChart
            width={circleSize}
            height={circleSize}
            cx={50}
            cy={60}
            innerRadius={40}
            outerRadius={50}
            barSize={10}
            data={this.state.data}
            startAngle={90}
            endAngle={-270}
          >
            <PolarAngleAxis
              type="number"
              domain={[0, 100]}
              angleAxisId={0}
              tick={false}
            />
            <defs>
              <linearGradient
                id="colorUv"
                x1="0"
                y1="0"
                x2="0"
                y2="100%"
                spreadMethod="reflect"
              >
                <stop offset="0" stopColor="#f5234a" />
                <stop offset="1" stopColor="#fa859a" />
              </linearGradient>
            </defs>
            <RadialBar
              background
              clockWise
              dataKey={this.props.productivityperc}
              cornerRadius={circleSize / 2}
              fill="rgb(255, 128, 0)"
              stroke="	rgb(255, 128, 0)"
            />
            <text
              x={50}
              y={60}
              textAnchor="middle"
              dominantBaseline="middle"
              className="progress-label"
            >
              {this.props.productivityperc}
            </text>
            <Legend
              iconSize={0}
              width={120}
              height={140}
              layout="horizontal"
              verticalAlign="bottom"
              align="left"
              wrapperStyle={{ top: 120, left: 33, fontWeight: 600 }}
            />
          </RadialBarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
export default ProductUser;
