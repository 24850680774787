import { Component } from "react";
import { Row, Col } from "reactstrap";
import Newsidebar from "../../Dashboard/Newsidebar";
import "../../../Styles/AgedChartTrends.css";
import axios from "axios";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";
import { ip } from "../../Apis/Api";

const COLORS = ["rgb(247, 73, 105)", "rgb(5, 101, 191)"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class AgedPaymentCharts extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
    client:sessionStorage.getItem("clientid"),
    dataarray1 : [],
    dataarray2 : [],
    dataarray3 : [],
    dataarray4 : [],
    dataarray5 : [],
    dataarray6 : [],
    head1:"",
    head2:"",
    head3:"",
    head4:"",
    head5:"",
    head6:"",

  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getData = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    
    let url;
    if (this.state.client) {
      url = `${ip}/trends/lastsixmonth?clientId=${this.state.client}`;
    } else {
      url = `${ip}/trends/lastsixmonth`;
    }
    
    try {
      const response = await axios.get(url, { headers: headers });
      const data = response.data.data;
      const data1 = []; // Declare data1 as an array
  
      let databalance1 = data[0].totalBalance;
      let datacharges1 = data[0].totalCharges;
      data1.push(databalance1, datacharges1);
      const dataArray = data1.map((item, index) => {
        return {
           name: `Total Charges${index + 1}`, value: item };
      })
      const data2 = [];
      let databalance2 = data[1].totalBalance;
      let datacharges2 = data[1].totalCharges;
      data2.push(databalance2,datacharges2);
      const dataArray1 = data2.map((item,index) => {
        return {
          name: `Total Charges${index + 1}`, value: item };
        })
        const data3 = [];
      let databalance3 = data[2].totalBalance;
      let datacharges3 = data[2].totalCharges;
      data3.push(databalance3,datacharges3);
      const dataArray2 = data2.map((item,index) => {
        return {
          name: `Total Charges${index + 1}`, value: item };
        })
        const data4 = [];
      let databalance4 = data[3].totalBalance;
      let datacharges4 = data[3].totalCharges;
      data4.push(databalance4,datacharges4);
      const dataArray3 = data4.map((item,index) => {
        return {
          name: `Total Charges${index + 1}`, value: item };
        })
        const data5 = [];
      let databalance5 = data[4].totalBalance;
      let datacharges5 = data[4].totalCharges;
      data5.push(databalance5,datacharges5);
      const dataArray4 = data5.map((item,index) => {
        return {
          name: `Total Charges${index + 1}`, value: item };
        })
        const data6 = [];
        let databalance6 = data[5].totalBalance;
        let datacharges6 = data[5].totalCharges;
        data6.push(databalance6,datacharges6);
        const dataArray5 = data6.map((item,index) => {
          return {
            name: `Total Charges${index + 1}`, value: item };
          })
  
      this.setState({
        dataarray1: dataArray,
        dataarray2: dataArray1,
        dataarray3: dataArray2,
        dataarray4: dataArray3,
        dataarray5: dataArray4,
        dataarray6: dataArray5,
        head1:response.data.data[0]._id,
        head2:response.data.data[1]._id,
        head3:response.data.data[2]._id,
        head4:response.data.data[3]._id,
        head5:response.data.data[4]._id,
        head6:response.data.data[5]._id,

      });
  
      console.log("sss", this.state.dataarray1);
    } catch (err) {
      console.log("Error:", err);
    }
  };
  

  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getData(); 
  }

  render()
   {
    return (
      <div>
      <Newsidebar />
      <div className="align-row">
        <div className="align-column">

          <div
            style={{
              textAlign: "left",
              marginLeft: "3%",
              marginTop: "2%",
              fontSize: "14px",
            }}
          >
            <p>
              <Link
                to="/management-trends-patterns"
                style={{ textDecoration: "none", color: "black" }}
              >
                Trends & Patterns
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Aged
              Payment Analysis (Last 6 Months)
            </p>
            <div className="headbox">
            <div class="Legendbox">
            <span className="legendcheckbox"/>
            <p>Total Balance</p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="Legendbox">
            <span className="legendcheckbox1"/>
            <p>Total Charges</p>
             </div>
             </div>
        
             </div>
          </div>
          <br />
          {/* <div className="aged-card-container"> */}

          <div className="agedlastmonth-container">
            <div className="lastmonth1">
              <Row>
                <Col className="agedtrends-text">{this.state.head1}</Col>
              </Row>

              <hr />

              <Row>
                <Col style={{ marginTop: "-11%" }}>
                  <div>
                    <ResponsiveContainer width={"99%"} height={300}>
                      <PieChart>
                        <Pie
                          data={this.state.dataarray1}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {this.state.dataarray1.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="lastmonth2">
              <Row>
                <Col className="agedtrends-text">{this.state.head2}</Col>
              </Row>
              <hr />

              <Row>
                <Col style={{ marginTop: "-11%" }}>
                  <div>
                    <ResponsiveContainer width={"99%"} height={300}>
                      <PieChart>
                        <Pie
                          data={this.state.dataarray2}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {this.state.dataarray2.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="lastmonth3">
              <Row>
                <Col className="agedtrends-text">{this.state.head3}</Col>
              </Row>
              <hr />

              <Row>
                <Col style={{ marginTop: "-11%" }}>
                  <div>
                    <ResponsiveContainer width={"99%"} height={300}>
                      <PieChart width={450} height={325}>
                        <Pie
                          data={this.state.dataarray3}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {this.state.dataarray3.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="lastmonth4">
              <Row>
                <Col className="agedtrends-text">{this.state.head4}</Col>
              </Row>
              <hr />

              <Row>
                <Col style={{ marginTop: "-11%" }}>
                  <div>
                    <ResponsiveContainer width={"99%"} height={300}>
                      <PieChart width={450} height={325}>
                        <Pie
                          data={this.state.dataarray4}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {this.state.dataarray4.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="lastmonth5">
              <Row>
                <Col className="agedtrends-text">{this.state.head5}</Col>
              </Row>
              <hr />

              <Row>
                <Col style={{ marginTop: "-11%" }}>
                  <div>
                    <ResponsiveContainer width={"99%"} height={300}>
                      <PieChart width={450} height={325}>
                        <Pie
                          data={this.state.dataarray5}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {this.state.dataarray5.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="lastmonth6">
              <Row>
                <Col className="agedtrends-text">{this.state.head6}</Col>
              </Row>
              <hr />
              <Row>
                <Col style={{ marginTop: "-11%" }}>
                  <div>
                    <ResponsiveContainer width={"99%"} height={300}>
                      <PieChart width={450} height={325}>
                        <Pie
                          data={this.state.dataarray6}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {this.state.dataarray6.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
export default AgedPaymentCharts;
