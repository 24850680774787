import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import "../../../Styles/subclaim.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { ip } from "../../Apis/Api";
class Machine1Searches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: sessionStorage.getItem("queryurl"),
      client: sessionStorage.getItem("queryclient"),
      Location: sessionStorage.getItem("queryLocation"),
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      querystartdate: sessionStorage.getItem("querystartdate"),
      queryenddate: sessionStorage.getItem("queryenddate"),
      show2: sessionStorage.getItem("queryclaimid"),
      subcategoryvalue: null,
      subcategoryvalue: null,
      dsutotalcnt: "",
      dsutotalamt: "",
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getsearchesdata = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryenddate && this.state.querystartdate) {
      url = `${ip}/claimstatus/subcategory?${this.state.value}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
    } else {
      url = `${ip}/claimstatus/subcategory?${this.state.value}`;
    }
    const res = await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ subcategoryvalue: res.data.data });
        if (res.data.data.length !== 0) {
          res.data.data.map((data, index) => {
            switch (data._id) {
              case "D0":
                return this.setState({
                  dsutotalamt: data.totalamount.toLocaleString(),
                  dsutotalcnt: data.totalcount,
                });
            }
          });
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    
    this.getsearchesdata();
  }
  render() {
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar name="Claim Management - Machine 1 Searches" />
        <div className="align-column">
          <br />
          <br />
          <div
            style={{ textAlign: "left", marginLeft: "5%", fontSize: "13px" }}
          >
            <p>
              <Link
                to="/claim-management"
                style={{ textDecoration: "none", color: "black",fontSize: "15px",
                fontWeight: 500 }}
              >
                Claim Status Management
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Machine 1
              Searches
            </p>
          </div>
          <div class="col-md-4 mb-3" style={{marginLeft:"3%"}}>
          <div
            class="card"
            //Data search unscuessful card
            onClick={() => {
              if (this.state.client) {
                if (this.state.querystartdate && this.state.queryenddate) {
                  if (this.state.Location) {
                    sessionStorage.setItem("querycategory", "Machine1 Searches");
                    sessionStorage.setItem(
                      "querysub",
                      "Data Search Unsuccessful "
                    );
                    sessionStorage.setItem("queryCategoryCode", "D0");
                    sessionStorage.setItem("queryCategoryname", "Searches");
                    sessionStorage.setItem("subclaimname", "searches");
                    sessionStorage.setItem(
                      "claimurl",
                      `ClaimCategory=Searches&statusCategoryCode=D0&Machine=Changehealthcare&clientId=${this.state.client}&Location=${this.state.Location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                    );
                  } else {
                    sessionStorage.setItem("querycategory", "Searches");
                    sessionStorage.setItem(
                      "querysub",
                      "Data Search Unsuccessful "
                    );
                    sessionStorage.setItem("queryCategoryCode", "D0");
                    sessionStorage.setItem("queryCategoryname", "Searches");
                    sessionStorage.setItem("subclaimname", "searches");
                    sessionStorage.setItem(
                      "claimurl",
                      `ClaimCategory=Searches&statusCategoryCode=D0&Machine=Changehealthcare&clientId=${this.state.client}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                    );
                  }
                } else {
                  if (this.state.Location) {
                    sessionStorage.setItem("querycategory", "Machine1 Searches");
                    sessionStorage.setItem(
                      "querysub",
                      "Data Search Unsuccessful "
                    );
                    sessionStorage.setItem("queryCategoryCode", "D0");
                    sessionStorage.setItem("queryCategoryname", "Searches");
                    sessionStorage.setItem("subclaimname", "searches");
                    sessionStorage.setItem(
                      "claimurl",
                      `ClaimCategory=Searches&statusCategoryCode=D0&Machine=Changehealthcare&clientId=${this.state.client}&Location=${this.state.Location}`
                    );
                  } else {
                    sessionStorage.setItem("querycategory", "Machine1 Searches");
                    sessionStorage.setItem(
                      "querysub",
                      "Data Search Unsuccessful "
                    );
                    sessionStorage.setItem("queryCategoryCode", "D0");
                    sessionStorage.setItem("queryCategoryname", "Searches");
                    sessionStorage.setItem("subclaimname", "searches");
                    sessionStorage.setItem(
                      "claimurl",
                      `ClaimCategory=Searches&statusCategoryCode=D0&Machine=Changehealthcare&clientId=${this.state.client}`
                    );
                  }
                }
              } else {
                if (this.state.querystartdate && this.state.queryenddate) {
                  sessionStorage.setItem("querycategory", "Machine1 Searches");
                  sessionStorage.setItem("subclaimname", "searches");
                  sessionStorage.setItem(
                    "querysub",
                    "Data Search Unsuccessful "
                  );
                  sessionStorage.setItem("queryCategoryCode", "D0");
                  sessionStorage.setItem("queryCategoryname", "Searches");
                  sessionStorage.setItem(
                    "claimurl",
                    `ClaimCategory=Searches&statusCategoryCode=D0&Machine=Changehealthcare&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`
                  );
                } else {
                  sessionStorage.setItem("querycategory", "Machine1 Searches");
                  sessionStorage.setItem("subclaimname", "searches");
                  sessionStorage.setItem(
                    "querysub",
                    "Data Search Unsuccessful "
                  );
                  sessionStorage.setItem("queryCategoryCode", "D0");
                  sessionStorage.setItem("queryCategoryname", "Machine1 Searches");
                  sessionStorage.setItem(
                    "claimurl",
                    `ClaimCategory=Searches&statusCategoryCode=D0&Machine=Changehealthcare`
                  );
                }
              }
            }}
          >
            <div
              class="card-header"
              style={{backgroundColor: this.state.show2 === "D0" ? "#83a4d4" : "#e1dfba"}}
            >
              <span class="d-inline" style={{ fontWeight: "bold" }}>
              Data Search Unsuccessful
              </span>
            </div>
            <div class="card-body">
              <div class="card-text">
              <Link
              to={{
                pathname: "/claim/sub-category-details",
              }}
              style={{ textDecoration: "none" }}
            >
              <Row>
                <Col className="subclaim-description">
                  The payer is unable to return status on the requested
                  claim(s) based on the submitted search criteria.
                </Col>
              </Row>
              <Row>
                <Col className="sub-totalclaim">
                  Total Claims:
                  <span>
                    <div
                      className="to"
                      style={{
                        color: "black",
                        fontWeight: "600",
                        fontSize: "19px",
                      }}
                    >
                      {" "}
                      {this.state.dsutotalcnt}
                    </div>
                  </span>
                </Col>
                <Col className="sub-total">
                  Grand Total:
                  <span>
                    {" "}
                    <div
                      style={{
                        color: "black",
                        fontSize: "19px",
                        fontWeight: "600",
                      }}
                    >
                      ${" "}
                      {this.state.dsutotalamt}
                    </div>
                  </span>
                </Col>
              </Row>
            </Link>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}
export default Machine1Searches;
