import React, { Component } from "react";
import axios from "axios";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col, Toast } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import loading from "../../Images/loader.gif";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Select from "react-dropdown-select";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Card } from "react-bootstrap";
import { ip } from "../Apis/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Icd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      icddata: [],
      modal: false,
      updatemodal: false,
      deletemodal: false,
      singleid: "",
      offset: 0,
      currentpage: 0,
      perPage: 10,
      createmodal: false,
      icdcode: "",
      desc: "",
      update_icdcode: "",
      update_desc: "",
      codeErr: "",
      descErr: "",
      dataforupdate: null,
    };
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  // toogle modal for delete
  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  //toggle modal for update
  toggleupdate = () => {
    this.setState({ updatemodal: !this.state.updatemodal });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getICD();
      }
    );
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getICD = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/ICDmaster/all?pageno=${this.state.currentpage}`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount: Math.ceil(res.data.count/ this.state.perPage),
          icddata: res.data.ICDmaster,
        });
      })
      .catch((err) => {});
  };
  settingupdatestate = function () {
    this.setState({
      update_icdcode: this.state.dataforupdate.code,
      update_desc: this.state.dataforupdate.description,
      singleid: this.state.dataforupdate._id,
    });
  };

  putrequests = async (dataid) => {
    const isValid = this.updatevalidate();

    if (isValid) {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        code: this.state.update_icdcode,
        description: this.state.update_desc,
      };
      // eslint-disable-next-line
      const res = await axios
        .put(`${ip}/ICDmaster/${dataid}`, value, {
          headers: headers,
        })
        .then((res) => {
          this.toggleupdate();
          this.getICD();
          this.setState({ createmodal: !this.state.createmodal });
          toast.info("ICD updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          if (err.response) {
            this.getICD();
            this.toggleupdate();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };

  deleterequests = async (DataID) => {
    //api call to delete requests
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/ICDmaster/${DataID}`, {
        headers: headers,
      })
      .then((req) => {
        this.toggledelete();
        this.getICD();
        this.setState({ createmodal: !this.state.createmodal });
        toast.error("ICD deleted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response) {
          this.getICD();
          this.toggledelete();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  handleSubmit = async () => {
    const isValid = this.validate();
    if (isValid) {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        code: this.state.icdcode,
        description: this.state.desc,
      };
      // eslint-disable-next-line
      const res = await axios
        .post(`${ip}/ICDmaster/create`, value, {
          headers: headers,
        })
        .then((res) => {
          this.toggle();
          this.getICD();
          this.setState({ createmodal: !this.state.createmodal });
          toast.success("ICD created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          if (err.response) {
            this.getICD();
            this.toggle();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };
  validate = () => {
    let hasErr = "true";
    let codeErr = "";
    let descErr = "";

    if (this.state.icdcode === "") {
      codeErr = "This field is required";
      this.setState({ codeErr });
      hasErr = false;
    }
    if (this.state.desc === "") {
      descErr = "This field is required";
      this.setState({ descErr });
      hasErr = false;
    }

    return hasErr;
  };

  updatevalidate = () => {
    let hasErr = "true";
    let codeErr = "";
    let descErr = "";

    if (this.state.update_icdcode === "") {
      codeErr = "This field is required";
      this.setState({ codeErr });
      hasErr = false;
    }
    if (this.state.update_desc === "") {
      descErr = "This field is required";
      this.setState({ descErr });
      hasErr = false;
    }

    return hasErr;
  };

  reset = () => {
    this.setState({
      icdcode: "",
      desc: "",
      codeErr: "",
      descErr: "",
    });
  };
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getICD();
  }
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));
    return (
      <div className="align-row">
        <Newsidebar name="ICD" />
        <div className="align-column">
    
          <br></br>
          <ToastContainer />

          <br></br>
          <div className="align-row items" style={{ marginTop: "2%" }}>
            <button className="addNew" onClick={this.toggle}>
              Add New ICD
            </button>
          </div>
          <div style={{ height: "100%", width: "100%", marginTop: "2%" }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 700, marginLeft: "20px" }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Code</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.icddata &&
                    this.state.icddata.map((data, index) => (
                      <StyledTableRow key={data._id}>
                        <StyledTableCell component="th" scope="row">
                          {data.code}
                        </StyledTableCell>
                        <StyledTableCell>{data.description}</StyledTableCell>
                        <StyledTableCell>
                          <button
                            className="Edit"
                            onClick={() => {
                              this.setState({ dataforupdate: data }, () => {
                                this.settingupdatestate();
                                this.toggleupdate();
                              });
                            }}
                          >
                            Edit
                          </button>
                          &nbsp;
                          <button
                            className="Delete"
                            onClick={() => {
                              this.setState({ singleid: data._id }, () => {
                                this.toggledelete();
                              });
                            }}
                          >
                            Delete
                          </button>
                        </StyledTableCell>
                      </StyledTableRow>
                      // ))}
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
          {this.state.modal && (
            <Modal
              style={{ width: "30%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              onExit={this.reset}
              centered
            >
              <ModalHeader toggle={this.toggle}>Create New ICD</ModalHeader>
              <ModalBody>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>ICD</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.icdcode}
                          onChange={(e) =>
                            this.setState({
                              icdcode: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Description</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.desc}
                          onChange={(e) =>
                            this.setState({ desc: e.target.value, descErr: "" })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.descErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.handleSubmit}
                >
                  Create
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {this.state.updatemodal && (
            <Modal
              style={{ width: "30%" }}
              isOpen={this.state.updatemodal}
              onHide={this.toggleupdate}
              onExit={this.reset}
              centered
            >
              <ModalHeader toggle={this.toggleupdate}>Update ICD</ModalHeader>
              <ModalBody>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>ICD</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.update_icdcode}
                          onChange={(e) =>
                            this.setState({
                              update_icdcode: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Description</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.update_desc}
                          onChange={(e) =>
                            this.setState({
                              update_desc: e.target.value,
                              descErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.descErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggleupdate();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.putrequests(this.state.singleid);
                  }}
                >
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {this.state.deletemodal && (
            <Modal
              isOpen={this.state.deletemodal}
              onHide={this.toggledelete}
              centered
            >
              <ModalHeader toggle={this.toggledelete}>
                Delete modifier
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledelete}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.deleterequests(this.state.singleid);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
          <Modal isOpen={this.state.createmodal} centered>
            <ModalBody>
              <img
                src={loading}
                style={{ width: "200px", height: "200px", marginLeft: "30%" }}
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}
