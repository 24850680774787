import React, { Component } from "react";
import axios from "axios";
import { ip } from "../../Apis/ReportApi";
import ReactPaginate from "react-paginate";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { Spinner } from "react-bootstrap";
import { Table, Row, Col, Input } from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment-timezone";

export class OpenedClaims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingclaims: true,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      openclaim: [],
      allclaims: [],
      notesdata: [],
      currentpage1: 0,
      download: false,
      perPage1: sessionStorage.getItem("Default_pagination"),
      loading: true,
      buttonsize: 0,
      countofarlog: "",
    };
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage1;

    this.setState(
      {
        currentpage1: selectedPage,
        offset1: offset,
      },
      () => {
        this.getopenclaimreports();
      }
    );
  };

  getdownloadbutton = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    let filename;
    if (this.props.startdate && this.props.enddate) {
      filename = `Opened_Claims_${this.props.startdate}_${this.props.enddate}`;
      url = `${ip}/report/openclaims?clientId=${this.state.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}&button=${this.state.buttonsize}`;
    } else {
      filename = `Opened_Claims`;
      url = `${ip}/report/openclaims?clientId=${this.state.client}&button=${this.state.buttonsize}`;
    }
    fetch(url, { headers: headers }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        a.click();
        URL.revokeObjectURL(a);
        this.setState({
          loading: false,
        });
      });
    });
  };
  increment = () => {
    if (this.state.buttonsize < this.state.countofarlog - 1) {
      this.setState({ buttonsize: this.state.buttonsize + 1 });

      if (this.state.buttonsize - 1 < 0) {
        alert("No data here so please click next arrow");
        this.state.buttonsize = 0;
      }
    }

    console.log(this.state.buttonsize, "incr");
  };

  decrement = () => {
    this.setState({ buttonsize: this.state.buttonsize - 1 });

    if (this.state.buttonsize - 1 < 0) {
      alert("No data here so please click next arrow");
      this.state.buttonsize = 0;
    }

    console.log(this.state.buttonsize, "decr");
  };
  getcount = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/report/getcounts?clientId=${this.state.client}&feature=openclaims&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      url = `${ip}/report/getcounts?clientId=${this.state.client}&feature=openclaims`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          countofarlog: Math.ceil(res.data.count / 20000),
        });
        console.log(this.state.countofarlog, res.data.count, "count");
      });
  };
  downloaddata = () => {
    const { download } = this.state;
    this.setState({ download: !download });
  };

  getopenclaimreports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/report/openclaims?clientId=${this.state.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}&pageno=${this.state.currentpage1}`;
    } else {
      url = `${ip}/report/openclaims?clientId=${this.state.client}&pageno=${this.state.currentpage1}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount1: Math.ceil(res.data.count / this.state.perPage1),
          openclaim: res.data.data,
          fetchingclaims: false,
        });
        this.getcount();
      });
  };

  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    // this.getclaims();
    this.getopenclaimreports();
    this.getcount();
  }

  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.enddate === prevProps.enddate
      )
    ) {
      // this.getclaims();
      this.getopenclaimreports();
    }
  }
  render() {
    return this.state.fetchingclaims ? (
      <div>
        <p
          style={{
            fontSize: "23px",
            marginTop: "10px",
            color: "#000",
          }}
        >
          <span>Loading...</span>
          <span>Please Wait...</span>
        </p>
        <div class="boxes body__loader dribbble">
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    ) : this.state.openclaim.length === 0 ? (
      <div class="bot-ui">
        <div class="bot-head">
          <div class="eyes-container">
            <div class="to-left eye"></div>
            <div class="to-right eye"></div>
          </div>
        </div>
        <div class="bot-body">
          <div class="analyzer"></div>
        </div>
        <p style={{ fontSize: "20px", fontStyle: "italic" }}>No Data Found</p>
      </div>
    ) : (
      <div>
        <br />
        <div>
          <button
            style={{
              boxShadow: "inset 0px 1px 0px 0px #E184F3",
              background: "black",
              backgroundColor: "black",
              borderRadius: "6px",
              border: "1px solid black",
              display: "inline-block",
              cursor: "pointer",
              color: "#FFFFFF",
              fontSize: "15px",
              fontWeight: "bold",
              padding: "6px 24px",
              textDecoration: "none",
              textShadow: "0px 1px 0px #9B14B3",
              float: "right",
            }}
            onClick={(e) => {
              this.downloaddata();
              this.getdownloadbutton();
            }}
          >
            Generate Report
          </button>
        </div>
        {this.state.download && (
          <Modal
            style={{ width: "30%" }}
            isOpen={this.state.download}
            onHide={this.downloaddata}
            onExit={this.reset}
            size="md"
            centered
          >
            <ModalHeader toggle={this.downloaddata}>Download</ModalHeader>
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <Row style={{ margin: "0px" }}>
                <Col>
                  <div id="container">
                    {this.state.loading ? (
                      <BsFillArrowLeftCircleFill
                        style={{ fontSize: "183%" }}
                        disabled
                        onClick={(e) => {
                          this.decrement();
                        }}
                      >
                        left
                      </BsFillArrowLeftCircleFill>
                    ) : (
                      <BsFillArrowLeftCircleFill
                        style={{ fontSize: "183%" }}
                        onClick={(e) => {
                          this.decrement();
                        }}
                      >
                        left
                      </BsFillArrowLeftCircleFill>
                    )}
                    {this.state.loading ? (
                      <button
                        style={{
                          // boxShadow: "inset 0px 1px 0px 0px #e184f3",
                          background: "green",
                          backgroundColor: "green",
                          borderRadius: "6px",
                          border: "3px solid green",
                          display: "inline-block",
                          cursor: "pointer",
                          color: "#ffffff",
                          fontSize: "15px",
                          fontWeight: "bold",
                          padding: "-4px 24px",
                          textDecoration: "none",
                          width: "45%",
                          whiteSpace: "nowrap",
                          marginLeft: "20%",
                          textShadow: "0px 1px 0px #9b14b3",
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp;&nbsp;Loading data...{this.state.buttonsize + 1}
                      </button>
                    ) : (
                      <button
                        style={{
                          // boxShadow: "inset 0px 1px 0px 0px #e184f3",
                          background: "Black",
                          backgroundColor: "Black",
                          borderRadius: "6px",
                          border: "3px solid Black",
                          display: "inline-block",
                          cursor: "pointer",
                          color: "#ffffff",
                          fontSize: "15px",
                          fontWeight: "bold",
                          padding: "-4px 24px",
                          textDecoration: "none",
                          width: "42%",
                          marginLeft: "20%",
                          textShadow: "0px 1px 0px #9b14b3",
                        }}
                        onClick={(e) => {
                          this.getdownloadbutton();
                        }}
                      >
                        Report {this.state.buttonsize + 1}
                      </button>
                    )}

                    {this.state.loading ? (
                      <BsFillArrowRightCircleFill
                        disabled
                        style={{ marginLeft: "49px", fontSize: "183%" }}
                        onClick={(e) => {
                          this.increment();
                        }}
                      >
                        right
                      </BsFillArrowRightCircleFill>
                    ) : (
                      <BsFillArrowRightCircleFill
                        style={{ marginLeft: "49px", fontSize: "183%" }}
                        onClick={(e) => {
                          this.increment();
                        }}
                      >
                        right
                      </BsFillArrowRightCircleFill>
                    )}
                  </div>
                </Col>
                <br />
                <br />
              </Row>

              <br />
            </ModalBody>
          </Modal>
        )}
        <br />
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Table
            className="table"
            bordered
            style={{
              marginLeft: "3%",
              width: "99%",
              marginTop: "4%",
            }}
          >
            <thead style={{ backgroundColor: "#041c3c", color: "white" }}>
              <tr align="center">
                <th>ClaimID</th>
                <th>Billing Organization Name</th>
                <th>Physician</th>
                <th>Patient Name</th>
                <th>Patient DOB</th>
                <th>Plan Code</th>
                <th>Pri Payer</th>
                <th>Primary PolicyNo</th>
                <th>Trading Partner ServiceId</th>
                <th>Reason</th>
                <th>Claim Status</th>
                <th>Status Code</th>
                <th>Status Code Value</th>
                <th>Status Category Code</th>
                <th>Status Category Code Value</th>
                <th>Type</th>
                <th>Touch</th>
                <th>Created At</th>
                <th>Billed Amount</th>
                <th>DOS</th>
                <th>Followup Date</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {this.state.openclaim &&
                this.state.openclaim.map((data, index) => {
                  return (
                    <tr>
                      <td>{data.ClaimID}</td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {data.BillingOrganizationName}
                      </td>
                      <td>{data.Physician}</td>
                      <td>
                        {data.FirstName} {data.LastName}
                      </td>
                      <td>{data.PatientDOB}</td>
                      <td>{data.PlanCode}</td>
                      <td
                        style={{
                          whiteSpace: "normal",
                          textAlign: "justify",
                        }}
                      >
                        {data.Pripayer}
                      </td>
                      <td>{data.PrimaryPolicyNo}</td>
                      <td>{data.tradingPartnerServiceId}</td>

                      <td
                        style={{
                          whiteSpace: "normal",
                          textAlign: "justify",
                        }}
                      >
                        {data.Reason}
                      </td>
                      <td>{data.ClaimStatus}</td>
                      <td>{data.statusCode}</td>
                      <td>{data.statusCodeValue}</td>
                      <td
                        style={{
                          whiteSpace: "normal",
                          textAlign: "justify",
                        }}
                      >
                        {data.statusCategoryCode}
                      </td>
                      <td
                        style={{
                          whiteSpace: "normal",
                          textAlign: "justify",
                        }}
                      >
                        {data.statusCategoryCodeValue}
                      </td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {data.type}
                      </td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {data.Touch}
                      </td>
                      <td>{moment(data.CreatedAt).format("YYYY-MM-DD")}</td>
                      <td>{data.TotalBilledAmount}</td>
                      <td>{data.DateOfService}</td>
                      <td>{moment(data.Followup_date).format("YYYY-MM-DD")}</td>
                      <td
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {data.Notes}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <br />
        <div>
          <ReactPaginate
            previousLabel={<IoIosArrowBack />}
            nextLabel={<IoIosArrowForward />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick1}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    );
  }
}

export default OpenedClaims;
