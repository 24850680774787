import { Component } from "react";
import axios from "axios";
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { ip } from "../../Apis/Api";
const month1 = [
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },

  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },

  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
];

let feb1 = [
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
];
let mar1 = [
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
];
let apr1 = [
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
];
let may1 = [
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
];
let jun1 = [
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
];
let jul1 = [
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
];
let aug1 = [
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
];
let sep1 = [
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
];
let oct1 = [
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
];
let nov1 = [
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
];
let dec1 = [
  {
    month: "",
    amount: 0,
    totalclaim: 0,
  },
];
let jan1 = [{ month: "1", amount: 0, totalclaim: 0 }];
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload.month}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

class RUVCharts2 extends Component {
  state = {
    chargesCollection: [],
    chargesamount: [],
    chargescount: [],
    today: new Date(),

    month: "$",

    totalclaim: "",
    amount: "",
    jan: [],
    feb: [],
    mar: [],
    apr: [],
    may: [],
    jun: [],
    jul: [],
    aug: [],
    sep: [],
    oct: [],
    nov: [],
    dec: [],

    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getinventory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.monthlyStart !== "" && this.props.monthlyEnd !== "") {
          url = `${ip}/inventory/monthlycharges?start_date=${this.props.monthlyStart}&end_date=${this.props.monthlyEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/inventory/monthlycharges?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.monthlyStart !== "" && this.props.monthlyEnd !== "") {
          url = `${ip}/inventory/monthlycharges?start_date=${this.props.monthlyStart}&end_date=${this.props.monthlyEnd}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/inventory/monthlycharges?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.monthlyStart !== "" && this.props.monthlyEnd !== "") {
        url = `${ip}/inventory/monthlycharges?start_date=${this.props.monthlyStart}&end_date=${this.props.monthlyEnd}`;
      } else {
        url = `${ip}/inventory/monthlycharges`;
      }
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.data.monthlycharges.length !== 0) {
          res.data.monthlycharges.map((data1, index) => {
            switch (data1._id) {
              case 1:
                return this.setState({ jan: data1.monthcount[0] });
                break;
              case 2:
                this.setState({ feb: data1.monthcount[0] });
                break;
              case 3:
                this.setState({ mar: data1.monthcount[0] });
                break;
              case 4:
                this.setState({ apr: data1.monthcount[0] });
                break;
              case 5:
                this.setState({ may: data1.monthcount[0] });
                break;
              case 6:
                this.setState({ jun: data1.monthcount[0] });
                break;
              case 7:
                this.setState({ jul: data1.monthcount[0] });
                break;
              case 8:
                this.setState({ aug: data1.monthcount[0] });
                break;
              case 9:
                this.setState({ sep: data1.monthcount[0] });
              case 10:
                this.setState({ oct: data1.monthcount[0] });
                break;
              case 11:
                this.setState({ nov: data1.monthcount[0] });
                break;
              case 12:
                this.setState({ dec: data1.monthcount[0] });
                break;
            }
          });
          if (this.state.jan.length === 0) {
            this.setState({ jan: jan1[0] });
          }
          if (this.state.feb.length === 0) {
            this.setState({ feb: feb1[0] });
          }
          if (this.state.mar.length === 0) {
            this.setState({ mar: mar1[0] });
          }
          if (this.state.apr.length === 0) {
            this.setState({ apr: apr1[0] });
          }
          if (this.state.may.length === 0) {
            this.setState({ may: may1[0] });
          }
          if (this.state.jun.length === 0) {
            this.setState({ jun: jun1[0] });
          }
          if (this.state.jul.length === 0) {
            this.setState({ jul: jul1[0] });
          }
          if (this.state.aug.length === 0) {
            this.setState({ aug: aug1[0] });
          }
          if (this.state.sep.length === 0) {
            this.setState({ sep: sep1[0] });
          }
          if (this.state.oct.length === 0) {
            this.setState({ oct: oct1[0] });
          }
          if (this.state.nov.length === 0) {
            this.setState({ nov: nov1[0] });
          }
          if (this.state.dec.length === 0) {
            this.setState({ dec: dec1[0] });
          }
          {
            const chargesamount = [
              this.state.jan,
              this.state.feb,
              this.state.mar,
              this.state.apr,
              this.state.may,
              this.state.jun,
              this.state.jul,
              this.state.aug,
              this.state.sep,
              this.state.oct,
              this.state.nov,
              this.state.dec,
            ];
            this.setState({
              chargesamount,
            });
          }
        } else {
          this.setState({ e: month1 });
        }
      })

      .catch((err) => {});
  };
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getinventory();
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.monthlyStart === prevProps.monthlyStart &&
        this.props.monthlyEnd === prevProps.monthlyEnd
      )
    ) {
      this.setState({
        chargesamount: [],
      });
      this.getinventory();
    } else if (this.props.client !== prevProps.client) {
      this.setState({
        chargesamount: [],
      });
      this.getinventory();
    } else if (this.props.Location !== prevProps.Location) {
      this.setState({
        chargesamount: [],
      });
      this.getinventory();
    }
  }

  render() {
    return (
      <div>
        {this.props.charges === "$" && (
          <div>
            <ResponsiveContainer width={"99%"} height={300}>
              <BarChart
                width={600}
                height={300}
                data={this.state.chargesamount}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis interval={0} dataKey={"month"} />
                <YAxis dataKey={"amount"} />
                <Tooltip
                  cursor={{ fill: "transparent" }}
                  content={<CustomTooltip />}
                />

                <Line
                  type="monotone"
                  dataKey={"amount"}
                  stroke="rgb(247, 73, 105)"
                  activeDot={{ r: 8 }}
                />
                <Bar
                  name="Total Charges"
                  dataKey={"amount"}
                  barSize={30}
                  fill="#30E8bF"
                  //radius={[-10, -10, 0, 0]}
                  onMouseOver={() => (tooltip = "amount")}
                >
                  {/* <LabelList dataKey="totalamt" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                </Bar>
                {/* <Line type="monotone" dataKey="Totalcount" stroke="rgb(5, 101, 191)" /> */}
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
        {this.props.charges === "#" &&
          this.state.chargesamount.length !== 0 && (
            <ResponsiveContainer width={"99%"} height={300}>
              <BarChart
                width={600}
                height={300}
                data={this.state.chargesamount}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis interval={0} dataKey="month" />
                <YAxis dataKey="totalclaim" />
                <Tooltip
                  cursor={{ fill: "transparent" }}
                  content={<CustomTooltip />}
                />
                <Line
                  type="monotone"
                  dataKey="totalclaim"
                  stroke="rgb(247, 73, 105)"
                  activeDot={{ r: 8 }}
                />
                <Bar
                  name="Total Collection"
                  dataKey="totalclaim"
                  barSize={30}
                  fill="#FF8235"
                  //radius={[-10, -10, 0, 0]}
                  onMouseOver={() => (tooltip = "totalclaim")}
                >
                  {/* <LabelList dataKey="totalamt" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                </Bar>
                {/* <Line type="monotone" dataKey="Totalcount" stroke="rgb(5, 101, 191)" /> */}
              </BarChart>
            </ResponsiveContainer>
          )}
      </div>
    );
  }
}
export default RUVCharts2;
