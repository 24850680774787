import React, { Component} from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Row, Col, Label } from "reactstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import MultiSelect from "multiselect-react-dropdown";
import { ip } from "../../Apis/Api";
// export default class SimpleMenu extends React.Component
// let claimid=[];
// let userid=[];
class SubDenialAllocation extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.state = {
      AllocatedTo: sessionStorage.getItem("AllocatedTo"),
      allocatemodal: false,

      bucketId: "",
      reallocmodal: false,
      anchorEl: null,
      allocateuser: "",
      show: false,
      show2: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      queryclient: sessionStorage.getItem("queryclient"),
      offset: 0,
      currentpage: 0,
      perPage: 10,
      users: [],
      selecteduser: [],
      selectuserErr: "",
      objectid: [],
      userid: [],
      UserAllocate:
      sessionStorage.getItem("firstname") +
      " " +
      sessionStorage.getItem("lastname"),
      claimid: [],
      usersErr: "",
    };
  }

  bulkallocatevalidate = () => {
    let hasErr = "true";
    let usersErr = "";
    if (this.multiselectRef.current.state.selectedValues.length === 0) {
      usersErr = "This field is required";
      this.setState({ usersErr });
      hasErr = false;
    }
    return hasErr;
  };
  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentpage: selectedPage,
      offset: offset,
    });
  };

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };

    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getusers = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/users/all?clientId=${this.state.queryclient}`, { headers: headers })
      .then((res) => {
        let data = res.data.user;
        if (this.state.queryclient) {
          data = data.filter((item) => {
            return (
              item.role === "Team Member" 
            );
          });
        } else {
          data = data.filter((item) => {
            return item.role === "Admin" || item.role ==="SuperAdmin";
          });
        }
        this.setState({ users: data });
      })

      .catch((err) => {});
  };

  getbucketclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    axios
      .get(`${ip}/denial/denialclaims?allocation_bucket=${this.props.data}`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ objectid: res.data.lowfollowup });
      })

      .catch((err) => {});
  };

  validate = () => {
    let hasErr = "true";
    let selectuserErr = "";
    if (this.state.selecteduser.length === 0) {
      selectuserErr = "This field is required";
      this.setState({ selectuserErr });
      hasErr = false;
    }
    return hasErr;
  };
  allocateclaims = () => {
    const isValid = this.validate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        ids: sessionStorage.getItem("ClaimDBID"),
        logdetails:{
          // newUser:this.state.username,
          admin:this.state.UserAllocate,
          module:"Denial Management Allocation",
          clientId:this.state.client,

          // ClaimID:["11751701/25/2022INS-206"],
          // admin:"demoImagnum",
          // module:"Allocation",
          // clientId":2004
          }
      };
       axios
        .put(
          `${ip}/allocation/allocate/${this.state.selecteduser[0]._id}`,
          value,
          {
            headers: headers,
          }
        )
        .then((res) => {
          alert(
            `claims allocated to user ${this.state.selecteduser[0].first_name}`
          );
          this.toggle4();
          window.location.reload();
        })

        .catch((err) => {});
    }
  };

  allocateusers = async () => {
    const isValid = this.bulkallocatevalidate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      this.state.objectid.map((data, index) =>
        this.state.claimid.push(data._id)
      );
      this.multiselectRef.current.state.selectedValues.map((data, index) =>
        this.state.userid.push(data._id)
      );
      const value = {
        claimid: this.state.claimid,
        userid: this.state.userid,
        logdetails:{
          // newUser:this.state.username,
          admin:this.state.UserAllocate,
          module:"Denial Management Allocation",
          clientId:this.state.client,

          // ClaimID:["11751701/25/2022INS-206"],
          // admin:"demoImagnum",
          // module:"Allocation",
          // clientId":2004
          }
      };
      if (this.state.claimid.length !== 0) {
         await axios
          .put(`${ip}/denial/allocatemultipleuser`, value, {
            headers: headers,
          })
          .then((res) => {
            alert("Claims allocated successfully!");
            this.toggle4();
          })

          .catch((err) => {});
      } else {
        alert("There are no claims to allocate!");
        this.toggle4();
      }
    }
  };

  filterdata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .put(
        `${ip}/allocation/bucketallocate?allocation_bucket=${this.state.bucketId}&userid=${this.state.selecteduser[0]._id}`,
        { headers: headers }
      )

      .then((res) => {
        alert(`claims allocated to userid ${this.state.selecteduser[0]._id}`);
        this.toggle4();
        window.location.reload();
      })
      .catch((err) => {});
  };

  toggle = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  toggle2 = () => {
    const { show2 } = this.state;
    this.setState({ show2: !show2 });
  };

  toggle4 = () => {
    this.setState(() => {
      this.setState({ allocatemodal: !this.state.allocatemodal });
    });
  };

  toggle3 = () => {
    this.setState(() => {
      this.setState({ reallocmodal: !this.state.reallocmodal });
    });
  };

  reset = () => {
    this.setState({
      selecteduser: [],
      selectuserErr: "",
    });
  };

  componentDidMount() {
    
    this.getusers();
    this.getbucketclaims();
  }

  render() {
    const { anchorEl } = this.state;

    return (
      <div>
        <BsThreeDotsVertical
          style={{
            color: "#57534b",
            paddingTop: "1%",
            marginTop: "17%",
            fontSize: "1.5rem",
            marginLeft: "91%",
          }}
          onClick={this.handleClick}
        ></BsThreeDotsVertical>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          aria-labelledby="composition-button"
          style={{ marginTop: "2.5%" }}
        >
          <MenuItem
            onClick={() => {
              this.toggle3();
              this.setState({ bucketId: this.props.data });
              this.handleClose();
            }}
          >
            Allocate
          </MenuItem>
        </Menu>
        {this.state.allocatemodal && (
          <Modal
            style={{ width: "30%" }}
            isOpen={this.state.allocatemodal}
            onHide={this.toggle4}
            onExit={this.reset}
            size="md"
            centered
          >
            <ModalHeader toggle={this.toggle4}>Create Allocation</ModalHeader>
            <ModalBody>
              <Row style={{ margin: "0px" }}>
                <Col>
                  <Label>Agent/Team Member Name</Label>
                  <MultiSelect
                    options={this.state.users}
                    displayValue="first_name"
                    ref={this.multiselectRef}
                    onSelect={(event) => {
                      this.setState({ usersErr: "" });
                    }}
                    // onSearch={true}
                  />
                </Col>
                <div style={{ fontSize: 16, color: "red" }}>
                  {this.state.usersErr}
                </div>
              </Row>
              <br />

              <br />
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.toggle4();
                }}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                style={{ backgroundColor: "black", color: "white" }}
                onClick={() => {
                  this.allocateusers();
                }}
              >
                Allocate
              </Button>
            </ModalFooter>
          </Modal>
        )}

        {this.state.reallocmodal && (
          <Modal
            style={{ width: "30%" }}
            isOpen={this.state.reallocmodal}
            onHide={this.toggle3}
            onExit={this.reset}
            size="md"
            centered
          >
            <ModalHeader toggle={this.toggle3}>Allocate</ModalHeader>
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <Row style={{ margin: "0px" }}>
                Do you want to Allocate this claim ?
              </Row>

              <br />
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.toggle3();
                }}
              >
                NO
              </Button>
              &nbsp;&nbsp;
              <Button
                onClick={() => {
                  this.toggle3();
                  this.toggle4();
                }}
                style={{ backgroundColor: "black", color: "white" }}
              >
                YES
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}
export default SubDenialAllocation;
