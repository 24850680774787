import React, { Component } from "react";
import axios from "axios";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col, Toast } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import loading from "../../Images/loader.gif";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
 
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { styled } from "@mui/material/styles";
import {Table} from "react-bootstrap";
import Select from "react-dropdown-select";
import { Card } from "react-bootstrap";
import { ip } from ".././Apis/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class Modifiers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      updatemodal: false,
      deletemodal: false,
      dataforupdate: null,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      modifierdata: [],
      modifiercode: "",
      claim__id: "",
      desc: "",
      status: "Enabled",
      update_modifiercode: "",
      update_desc: "",
      update_status: "Enabled",
      codeErr: "",
      descErr: "",
      singleid: "",
      offset: 0,
      currentpage: 0,
      perPage: 10,
      createmodal: false,
      statuslist: [],
      payercategories: [],
      payervalue1: [],
      searchClaimId: "",
      searchClaimStatus: [],
      serachCategory: [],
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  // toogle modal for delete
  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  //toggle modal for update
  toggleupdate = () => {
    this.setState({ updatemodal: !this.state.updatemodal });
  };

  getpayercategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
        let other = {
          category_name: "Others",
        };
        let data = res.data.payer;
        data.push(other);
        this.setState({ payercategories: data }, () => {});
      })

      .catch((err) => {});
  };
  getClaimStatus = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    const res = axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        this.setState({
          statuslist: res.data.claimstatus,
        });
      })
      .catch((err) => {});
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getmodifier();
      }
    );
  };

  handleSubmit = async () => {
    const isValid = this.validate();
    if (isValid) {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        code: this.state.modifiercode,
        description: this.state.desc,
        status: this.state.status,
      };
      // eslint-disable-next-line
      const res = await axios
        .post(`${ip}/modifier/create`, value, {
          headers: headers,
        })
        .then((res) => {
          this.toggle();
          this.getmodifier();
          this.setState({ createmodal: !this.state.createmodal });
          toast.success("Modifier created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          if (err.response) {
            this.getmodifier();
            this.toggle();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
      // }
    }
  };

  // fuction for prepopulate the particular data
  settingupdatestate = function () {
    this.setState({
      update_modifiercode: this.state.dataforupdate.code,
      update_desc: this.state.dataforupdate.description,
      update_status: this.state.dataforupdate.status,
      singleid: this.state.dataforupdate._id,
    });
  };

  putrequests = async (dataid) => {
    const isValid = this.updatevalidate();

    if (isValid) {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        code: this.state.update_modifiercode,
        description: this.state.update_desc,
        status: this.state.update_status,
      };
      // eslint-disable-next-line
      const res = await axios
        .put(`${ip}/modifier/${dataid}`, value, {
          headers: headers,
        })
        .then((res) => {
          this.toggleupdate();
          this.getmodifier();
          this.setState({ createmodal: !this.state.createmodal });
          toast.info("Modifier updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          if (err.response) {
            this.getmodifier();
            this.toggleupdate();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };

  deleterequests = async (DataID) => {
    //api call to delete requests
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/modifier/${DataID}`, {
        headers: headers,
      })
      .then((req) => {
        this.toggledelete();
        this.getmodifier();
        this.setState({ createmodal: !this.state.createmodal });
        toast.error("Modifier deleted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response) {
          this.getmodifier();
          this.toggledelete();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //getusers
  getmodifier = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/modifier/all`, {
        headers: headers,
      })
      .then((res) => {
        const slice = res.data.modifier.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(res.data.modifier.length / this.state.perPage),
          modifierdata: slice,
        });
      })
      .catch((err) => {});
  };

  validate = () => {
    let hasErr = "true";
    let codeErr = "";
    let descErr = "";

    if (this.state.modifiercode === "") {
      codeErr = "This field is required";
      this.setState({ codeErr });
      hasErr = false;
    }
    if (this.state.desc === "") {
      descErr = "This field is required";
      this.setState({ descErr });
      hasErr = false;
    }

    return hasErr;
  };

  updatevalidate = () => {
    let hasErr = "true";
    let codeErr = "";
    let descErr = "";

    if (this.state.update_modifiercode === "") {
      codeErr = "This field is required";
      this.setState({ codeErr });
      hasErr = false;
    }
    if (this.state.update_desc === "") {
      descErr = "This field is required";
      this.setState({ descErr });
      hasErr = false;
    }

    return hasErr;
  };

  componentDidMount() {
    
    this.getmodifier();
    this.getClaimStatus();
    this.getpayercategory();
  }
  reset__one = (e) => {
    this.setState({
      claim__id: "",
      status: [],
      payervalue1: [],
      searchClaimId: "",
      searchClaimStatus: [],
      serachCategory: [],
    });
  };
  reset = () => {
    this.setState({
      modifiercode: "",
      desc: "",
      status: "Enabled",
      codeErr: "",
      descErr: "",
    });
  };
  render() {
    return (
      <div>
      <Newsidebar name="Modifiers" />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div className="align-column">
          <ToastContainer />
          <div>
            <button className="addNew" onClick={this.toggle}>
              Add New modifie
            </button>
          </div>
          <br/>
          <div>
              <Table bordered  className="StandardTable">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.modifierdata &&
                    this.state.modifierdata.map((data, index) => (
                      <tr key={data._id}>
                        <td component="th" scope="row">
                          {data.code}
                        </td>
                        <td
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                        >{data.description}</td>
                        <td>{data.status}</td>
                        <td>
                          <button
                            className="Edit"
                            onClick={() => {
                              this.setState({ dataforupdate: data }, () => {
                                this.settingupdatestate();
                                this.toggleupdate();
                              });
                            }}
                          >
                            Edit
                          </button>
                          &nbsp;
                          <button
                            className="Delete"
                            onClick={() => {
                              this.setState({ singleid: data._id }, () => {
                                this.toggledelete();
                              });
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                      // ))}
                    ))}
                </tbody>
              </Table>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
          {this.state.modal && (
            <Modal
              style={{ width: "30%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              onExit={this.reset}
              centered
            >
              <ModalHeader toggle={this.toggle}>
                Create New modifier
              </ModalHeader>
              <ModalBody>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Modifier code</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.modifiercode}
                          onChange={(e) =>
                            this.setState({
                              modifiercode: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Status</Label>
                        <Input
                          type="select"
                          className="form-control form-select"
                          required
                          value={this.state.status}
                          onChange={(e) =>
                            this.setState({ status: e.target.value })
                          }
                        >
                          <option value="Enabled">Enabled</option>
                          <option value="Disabled">Disabled</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Description</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.desc}
                          onChange={(e) =>
                            this.setState({ desc: e.target.value, descErr: "" })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.descErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.handleSubmit}
                >
                  Create
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {this.state.updatemodal && (
            <Modal
              style={{ width: "30%" }}
              isOpen={this.state.updatemodal}
              onHide={this.toggleupdate}
              onExit={this.reset}
              centered
            >
              <ModalHeader toggle={this.toggleupdate}>
                Update modifier
              </ModalHeader>
              <ModalBody>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Modifier code</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.update_modifiercode}
                          onChange={(e) =>
                            this.setState({
                              update_modifiercode: e.target.value,
                              codeErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Status</Label>
                        <Input
                          type="select"
                          className="form-control form-select"
                          required
                          value={this.state.update_status}
                          onChange={(e) =>
                            this.setState({ update_status: e.target.value })
                          }
                        >
                          <option value="Enabled">Enabled</option>
                          <option value="Disabled">Disabled</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Description</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.update_desc}
                          onChange={(e) =>
                            this.setState({
                              update_desc: e.target.value,
                              descErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.descErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggleupdate();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.putrequests(this.state.singleid);
                  }}
                >
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {this.state.deletemodal && (
            <Modal
              isOpen={this.state.deletemodal}
              onHide={this.toggledelete}
              // onExit={this.reloadpage}
              centered
            >
              <ModalHeader toggle={this.toggledelete}>
                Delete modifier
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledelete}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.deleterequests(this.state.singleid);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
          <Modal isOpen={this.state.createmodal} centered>
            <ModalBody>
              <img
                src={loading}
                style={{ width: "200px", height: "200px", marginLeft: "30%" }}
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
      </div>
    );
  }
}

export default Modifiers;
