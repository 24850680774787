import { Component } from "react";
import {
  ResponsiveContainer,
  Legend,
  RadialBar,
  RadialBarChart,
  PolarAngleAxis,
} from "recharts";
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import RadialSeparators from "./RadialSeparators";

const circleSize = 200;

class Denied extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // data: [{ name: "Denied", value: parseInt(this.props.deniedamt) }],
      data:this.props.deniedamt,
    };
  }
  render() {
    return (
      <div>
                 <CircularProgressbarWithChildren
  value={this.state.data}
  text={`${this.state.data}%`}
  strokeWidth={5}

  styles={buildStyles({
    // Rotation of path and trail, in number of turns (0-1)
    rotation: 0.25,

    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    strokeLinecap: 'butt',
    // Text size
    textSize: '18px',

    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 0.5,

    // Can specify path transition in more detail, or remove it entirely
    // pathTransition: 'none',

    // Colors
    pathColor: `rgba(255, 13, 0, 0.8)`,
    textColor: '#041c3c',
    trailColor: '#d6d6d6',
    backgroundColor: '#3e98c7',
  })}
>
<RadialSeparators
          count={12}
          style={{
            background: "#fff",
            width: "2px",
            // This needs to be equal to props.strokeWidth
            height: `${10}%`
          }}
        />
      </CircularProgressbarWithChildren>
        {/* <ResponsiveContainer width={"99%"} height={200}>
          <RadialBarChart
            width={circleSize}
            height={circleSize}
            cx={50}
            cy={60}
            innerRadius={40}
            outerRadius={50}
            barSize={10}
            data={this.state.data}
            startAngle={90}
            endAngle={-270}
          >
            <PolarAngleAxis
              type="number"
              domain={[0, 100]}
              angleAxisId={0}
              tick={false}
            />
            <defs>
              <linearGradient
                id="colorUv"
                x1="0"
                y1="0"
                x2="0"
                y2="100%"
                spreadMethod="reflect"
              >
                <stop offset="0" stopColor="#33ccff" />
                <stop offset="1" stopColor="#0033cc" />
              </linearGradient>
            </defs>
            <RadialBar
              background
              clockWise
              dataKey="value"
              cornerRadius={circleSize / 2}
              fill="rgb(255, 0, 0)"
              stroke="rgb(255, 0, 0)"
            />
            <text
              x={50}
              y={60}
              textAnchor="middle"
              dominantBaseline="middle"
              className="progress-label"
            >
              {this.props.deniedamt}
            </text>
            <Legend
              iconSize={0}
              width={120}
              height={140}
              layout="horizontal"
              verticalAlign="bottom"
              align="left"
              wrapperStyle={{ top: 120, left: 33, fontWeight: 600 }}
            />
          </RadialBarChart>
        </ResponsiveContainer> */}
      </div>
    );
  }
}
export default Denied;
