import React, { Component } from "react";
import { ip } from ".././Apis/Api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import Badge from "@mui/material/Badge";
import Newsidebar from "../Dashboard/Newsidebar";
 
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Card } from "react-bootstrap";
import Twilio from "../Transaction/Twilio";
import { default as ReactSelect } from "react-select";
import { Typeahead } from "react-bootstrap-typeahead";
import SimpleMenu from "../Transaction/AllocationCardComponents/MenuList";
import ViewClaim from "../Transaction/AllocationCardComponents/ViewClaim";
import nodata from "../../Images/no_result_found.png";
import searchresult from "../../Images/searchresult.jpg";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import moment from "moment";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../Styles/loader.scss";
import {
  Row,
  Col,
  Form,
  Label,
  Input,
  Button,
  Table,
  FormGroup,
} from "reactstrap";
import Select from "react-dropdown-select";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import WebclaimAllocate from "./WebclaimAllocate";
import IvrclaimAllocate from "./IvrclaimAllocate";
import ManualclaimAllocate from "./ManualclaimAllocate";
import Unallocatedclaim from "./Unallocateclaim";
import { components } from "react-select";

import Viewmoreeligibility from "../Eligibility/Viewmoreeligibility";
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
export class IVRClaims extends Component {
  constructor(props) {
    super(props);
    this.typeaheadRef = React.createRef();
    this.state = {
      searchCompleted: false,
      optionsarray: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("queryclient"),
      offset: 0,
      formValues: [
        {
          claimfield1: "",
          claimvalue: "",
        },
      ],
      currentpagesort: 0,
      sorttable: false,
      sortdata: [],
      perPagesort: 10,
      sortvalue: "",
      fieldvalue: "",
      currentpage: 0,
      searching: false,
      perPage: sessionStorage.getItem("Default_pagination"),
      soffset: 0,
      scurrentpage: 0,
      sperPage: sessionStorage.getItem("Default_pagination"),
      claims: [],
      statuslist: [],
      status: "",
      currentpageaction: 0,
      perPageaction: sessionStorage.getItem("Default_pagination"),
      offsetaction: 0,
      filtereddata: [],
      payercategories: [],
      allocatemodal1: "",
      payervalue: [],
      allclaims: [],
      claimfield1: "",
      claimvalue1: "",
      searched: false,
      fetching: true,
      query: [],
      allocated: 0,
      unallocated: 0,
      sortstate: "ASC",
      openicon: false,
    };
    this.fileInput = React.createRef();
  }
  getpayercategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({ payercategories: res.data.payer }, () => {});
      })
      .catch((err) => {});
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  handleStatusChange = (selected) => {
    if (selected.length > 0) {
      this.setState({ status: selected[0] });
    } else {
      this.setState({ status: "" });
    }
  };
  getsorting = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryclient) {
      if (this.state.querystartdate && this.state.queryenddate) {
        if (this.state.queryLocation) {
          url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&type=IVR&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpagesort}&Location=${this.state.queryLocation}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagesort}`;
        } else {
          url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&type=IVR&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagesort}`;
        }
      } else {
        if (this.state.queryLocation) {
          url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&type=IVR&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpagesort}&Location=${this.state.queryLocation}&count=${this.state.perPagesort}&count=${this.state.perPagesort}`;
        } else {
          url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&type=IVR&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpagesort}&count=${this.state.perPagesort}`;
        }
      }
    } else {
      if (this.state.querystartdate && this.state.queryenddate)
        url = `${ip}/outcome/allocate/sorting?type=IVR&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&count=${this.state.perPagesort}`;
      else
        url = `${ip}/outcome/allocate/sorting?type=IVR&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&pageno=${this.state.currentpagesort}&count=${this.state.perPagesort}`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          pageCountsort: Math.ceil(
            res.data.claimcount / this.state.perPagesort
          ),
          sortdata: res.data.claims,
        });
      })

      .catch((err) => {});
  };
  handlePagesort = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagesort;

    this.setState(
      {
        currentpagesort: selectedPage,
        offsetsort: offset,
      },
      () => {
        this.getsorting();
      }
    );
  };
  gettableaction = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.queryclient) {
      if (this.state.querystartdate && this.state.queryenddate) {
        if (this.state.queryLocation) {
          url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&type=IVR&field=ClaimID&sorting=ascending&allocatedpageno=${this.state.currentpageactionivr}&Location=${this.state.queryLocation}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&type=IVR&field=ClaimID&sorting=ascending&allocatedpageno=${this.state.currentpageactionivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        }
      } else {
        if (this.state.queryLocation) {
          url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&type=IVR&field=ClaimID&sorting=ascending&allocatedpageno=${this.state.currentpageactionivr}&Location=${this.state.queryLocation}`;
        } else {
          url = `${ip}/outcome/allocate/sorting?clientId=${this.state.client}&type=IVR&field=ClaimID&sorting=ascending&allocatedpageno=${this.state.currentpageactionivr}`;
        }
      }
    } else {
      if (this.state.querystartdate && this.state.queryenddate)
        url = `${ip}/outcome/allocate/sorting?type=IVR&field=ClaimID&sorting=ascending&allocatedpageno=${this.state.currentpageactionivr}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
      else
        url = `${ip}/outcome/allocate/sorting?type=IVR&field=ClaimID&sorting=ascending&allocatedpageno=${this.state.currentpageactionivr}`;
    }
     axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          pageCountactionivr: Math.ceil(
            res.data.usercount / this.state.perPageactionivr
          ),

          tableaction: res.data.claimdetails,
        });
      })

      .catch((err) => {});
  };

  getclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      if (this.props.location.Location) {
        if (this.props.location.startdate && this.props.location.enddate) {
          url = `${ip}/outcome/typewise?start_date=${this.props.location.ate}&end_date=${this.props.location.enddate}&clientId=${this.state.client}&pageno=${this.state.currentpage}&count=${this.state.perPage}&Location=${this.props.location.Location}`;
        } else {
          url = `${ip}/outcome/typewise?clientId=${this.state.client}&pageno=${this.state.currentpage}&count=${this.state.perPage}&Location=${this.props.location.Location}`;
        }
      } else {
        if (this.props.location.startdate && this.props.location.enddate) {
          url = `${ip}/outcome/typewise?start_date=${this.props.location.startdate}&end_date=${this.props.location.enddate}&clientId=${this.state.client}&pageno=${this.state.currentpage}&count=${this.state.perPage}`;
        } else {
          url = `${ip}/outcome/typewise?clientId=${this.state.client}&pageno=${this.state.currentpage}&count=${this.state.perPage}`;
        }
      }
    } else {
      if (this.props.location.startdate && this.props.location.enddate) {
        url = `${ip}/outcome/typewise?start_date=${this.props.location.startdate}&end_date=${this.props.location.enddate}&pageno=${this.state.currentpage}&count=${this.state.perPage}`;
      } else {
        url = `${ip}/outcome/typewise?pageno=${this.state.currentpage}&count=${this.state.perPage}`;
      }
    }

     axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          allclaims: res.data.Ivr,
          pageCount: Math.ceil(res.data.Ivrcount / this.state.perPage),
          allocated: res.data.Ivrallocatedcount,
          unallocated: res.data.Ivrunallocatedcount,
          claims: res.data.Ivr,
          fetching: false,
        });
      })
      .catch((err) => {});
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getclaims();
      }
    );
  };
  handlePageSearchClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        scurrentpage: selectedPage,
        soffset: offset,
      },
      () => {
        this.filtersearch();
      }
    );
  };
  getClaimStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

     await axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        var data = res.data.claimstatus.map((e) => ({
          value: e.claimStatus,
          label: e.claimStatus,
        }));
        this.setState({
          statuslist: data,
        });
      })
      .catch((err) => {});
  };
  reset = () => {
    if (this.state.searched === true) {
      toast.info("All values displayed now!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    if (this.typeaheadRef.current) {
      this.typeaheadRef.current.clear(); // Use the ref to clear the Typeahead input
    }
    this.setState(
      {
        searchCompleted: false,
        payervalue: [],
        status: "",
        searched: false,
        filtereddata: [],
        claimfield1: "",
        claimvalue1: "",
        currentpage: 0,
        scurrentpage: 0,
        sperPage: 10,
        formValues: [],
      },
      () => {
        this.addFormField();
        this.getclaims();
      }
    );
  };
  toggle1 = () => {
    this.setState(() => {
      this.setState({ allocatemodal1: !this.state.allocatemodal1 });
    });
  };
  handleClose = () => this.setState({ anchorEl: null });
  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  // This is not used
  // getquery = () => {
  //   var query_o;
  //   let query = [];
  //   if (this.state.payervalue.length !== 0) {
  //     if (this.state.payervalue[0].category_name !== "") {
  //       query_o = `Payercategory=${this.state.payervalue[0].category_name}`;
  //       query.push(query_o);
  //     }
  //   }

  //   if (this.state.status !== "") {
  //     query_o = `ClaimStatus=${this.state.status}`;
  //     query.push(query_o);
  //   }

  //   if (this.state.claimfield1 && this.state.claimvalue1 !== "") {
  //     query_o = `${this.state.claimfield1}=${this.state.claimvalue1}`;
  //     query.push(query_o);
  //   }
  //   if (query.length === 1) this.state.query.push(`${query[0]}`);
  //   if (query.length === 2) this.state.query.push(`${query[0]}&${query[1]}`);
  //   if (query.length === 3) {
  //     this.state.query.push(`${query[0]}&${query[1]}&${query[2]}`);
  //   }
  //   if (query.length === 4) {
  //     this.state.query.push(`${query[0]}&${query[1]}&${query[2]}&${query[3]}`);
  //   }
  // };
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.setState({ tabname: "web", firstsearch1: true }, () => {
        this.filtersearch(e);
      });
    }
  };
  filtersearch = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (
      this.state.formValues.some(
        (formValue) =>
          formValue.claimfield1 !== "" && formValue.claimvalue !== ""
      )
    ) {
      let url;
      if (this.state.client) {
        //   if (this.props.location.Location) {
        //     if (this.props.location.startdate && this.props.location.enddate) {
        //       url = `${ip}/allocation/web/advancedmultiplefilter?pageno=${this.state.scurrentpage}&count=${this.state.sperPage}&clientId=${this.state.client}&${this.state.query}&start_date=${this.props.location.startdate}&end_date=${this.props.location.enddate}&Location=${this.props.location.Location}`;
        //     }
        //      else {
        //       url = `${ip}/allocation/web/advancedmultiplefilter?pageno=${this.state.scurrentpage}&count=${this.state.sperPage}&clientId=${this.state.client}&${this.state.query}&Location=${this.props.location.Location}`;
        //     }
        //   } else {
        //     if (this.props.location.startdate && this.props.location.enddate) {
        //       url = `${ip}/allocation/web/advancedmultiplefilter?pageno=${this.state.scurrentpage}&count=${this.state.sperPage}&clientId=${this.state.client}&${this.state.query}&start_date=${this.props.location.startdate}&end_date=${this.props.location.enddate}`;
        //     }
        //      else {
        //       url = `${ip}/allocation/web/advancedmultiplefilter?pageno=${this.state.scurrentpage}&count=${this.state.sperPage}&clientId=${this.state.client}&${this.state.query}`;
        //     }
        //   }
        // } else {
        //   if (this.props.location.startdate && this.props.location.enddate) {
        //     url = `${ip}/allocation/web/advancedmultiplefilter?pageno=${this.state.scurrentpage}&count=${this.state.sperPage}&${this.state.query}&start_date=${this.props.location.startdate}&end_date=${this.props.location.enddate}`;
        //   }
        //   else {
        url = `${ip}/allocation/ivr/advancedmultiplefilter?pageno=${this.state.scurrentpage}&countval=${this.state.perPage}&clientId=${this.state.client}`;
      } else {
        url = `${ip}/allocation/ivr/advancedmultiplefilter?pageno=${this.state.scurrentpage}&countval=${this.state.perPage}`;
      }
      const searchCriteria = {};
      this.state.formValues.forEach((formValue) => {
        if (formValue.claimfield1 && formValue.claimvalue) {
          searchCriteria[formValue.claimfield1] = formValue.claimvalue;
        }
      });
      this.state.formValues.forEach((formValue) => {
        if (formValue.claimfield1 === "ClaimStatus") {
          const userValues = formValue.claimvalue.map((option) => option.value);
          let statuslist;
          statuslist = userValues.map((str) => '"' + str + '"');
          searchCriteria[formValue.claimfield1] = `[${statuslist.join(", ")}]`;
        }
      });
      const searchCriteriaQueryString = Object.keys(searchCriteria)
        .map((key) => `${key}=${searchCriteria[key]}`)
        .join("&");

      if (searchCriteriaQueryString) {
        url += `&${searchCriteriaQueryString}`;
      }
       axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          if (res && !this.state.searchCompleted) {
            toast.success("Searching completed!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
          this.setState({
            filtereddata: res.data.claims,
            spagecount: Math.ceil(res.data.claimcount / this.state.sperPage),
            searched: true,
          });
          this.setState({
            searchCompleted: true,
          });
        });
    } else {
      alert("Select data to search");
    }
  };
  addFormField() {
    this.setState({
      formValues: [
        ...this.state.formValues,
        { claimfield1: "", claimvalue: "" },
      ],
    });
  }
  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }
  handlePageClickaction = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPageaction;

    this.setState(
      {
        currentpageaction: selectedPage,
        offsetaction: offset,
      },
      () => {
        this.gettableaction();
      }
    );
  };
  resetcount = () => {
    this.setState(
      {
        perPage: 10,
      },
      () => this.getclaims()
    );
  };
  componentDidMount() {
    
    this.getclaims();
    this.getClaimStatus();
    this.getpayercategory();
    this.gettableaction();
    const options = [
      { value: "Location", label: "Location" },
      { value: "ClaimID", label: "ClaimID" },
      { value: "MRN", label: "MRN" },
      { value: "PatientFirstName", label: "FirstName" },
      { value: "PatientLastName", label: "LastName" },
      { value: "Physician", label: "Physician" },
      { value: "PayerID", label: "PayerID" },
      { value: "Age", label: "Age" },
      { value: "PrimaryPolicyNo", label: "PolicyNo" },
      { value: "PatientDOB", label: "DOB" },
      { value: "ClaimStatus", label: "ClaimStatus" },
    ];
    this.setState({
      optionsarray: options,
    });
    let storedSearchivrInput = localStorage.getItem("storedClaimIvrSearch");
    let storedivrSearchvalue = localStorage.getItem("storedivrSearchvalue");
    let val = JSON.parse(storedivrSearchvalue);
    if (val === true) {
      this.setState(
        {
          formValues: JSON.parse(storedSearchivrInput),
          searched: JSON.parse(storedivrSearchvalue),
        },
        () => {
          this.filtersearch();
        }
      );
    } else {
      this.getclaims();
    }
  }
  componentWillUnmount() {
    let ivrinput = JSON.stringify(this.state.formValues);
    localStorage.setItem("storedClaimIvrSearch", ivrinput);
    let ivrinput1 = JSON.stringify(this.state.searched);
    localStorage.setItem("storedivrSearchvalue", ivrinput1);
  }
  render() {
    return (
      <div>
      <Newsidebar name="IVR Claims" />
      <div className="align-row" style={{overflow:"hidden", paddingTop:"13px"}}>
        <ToastContainer></ToastContainer>
        <div className="align-column">
          <div style={{ display: "flex" }}>
            <div class="select_drop">
              <select
                style={{ float: "left", marginLeft: "30px", height: "35px" }}
                value={this.state.perPage}
                onChange={(e) =>
                  this.setState(
                    {
                      perPage: e.target.value,
                      sperPage: e.target.value,
                      scurrentpage: 0,
                      currentpage: 0,
                      perPagesort: e.target.value,
                      currentpagesort: 0,
                    },
                    () => {
                      this.getclaims();
                      this.getsorting();
                      // this.filtersearch();
                    }
                  )
                }
              >
                <option value="select" hidden selected>
                  Select page count
                </option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </select>
            </div>

            <div>
              <Button
                style={{
                  border: "none",
                  color: "white",
                  background: "white",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  this.resetcount();
                }}
              >
                <IoIosRefresh
                  style={{
                    color: "blue",
                    fontSize: "23px",
                  }}
                ></IoIosRefresh>
              </Button>
            </div>
          </div>
          {!this.state.searched && (
            <div style={{ marginLeft: "70%" }}>
              <Badge
                badgeContent={this.state.allocated}
                color="primary"
                showZero
                max={this.state.pageCount * this.state.perPage}
              >
                <Button
                  style={{ backgroundColor: "#2e5a88", color: "white" }}
                  onClick={() => {
                    this.toggle1();
                    this.handleClose();
                  }}
                >
                  Allocated
                </Button>
              </Badge>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Badge
                badgeContent={this.state.unallocated}
                color="primary"
                showZero
                max={this.state.pageCount * this.state.perPage}
              >
                <Button>
                  <Link
                    to="/unallocatedclaims"
                    style={{
                      textDecoration: "none",
                      color: "whitesmoke",
                    }}
                    onClick={() => (
                      <Unallocatedclaim
                        value={
                          sessionStorage.setItem("keytype", "IVR") ||
                          sessionStorage.setItem("claimcategory", "ivrclaim")
                        }
                      />
                    )}
                  >
                    Unallocated
                  </Link>
                </Button>
              </Badge>
            </div>
          )}
          <Form
            style={{
              border: "3px solid #FBFBFB",
              borderRadius: "16px",
              padding: "4px",
              background: "#FBFBFB",
            }}
          >
            <div>
              <div class="btn-group" role="group" style={{ float: "right" }}>
                <button
                  type="button"
                  class="btn"
                  style={{ backgroundColor: "#89CFF0", color: "#fff" }}
                  onClick={(e) => {
                    this.filtersearch();
                  }}
                >
                  <AiOutlineSearch />
                </button>
                <button
                  type="button"
                  class="btn"
                  style={{ backgroundColor: "#7DD657", color: "#fff" }}
                  onClick={() => {
                    this.addFormField();
                  }}
                >
                  <AiOutlinePlusCircle />
                </button>
                <button
                  style={{ backgroundColor: "#041c3c", color: "#fff" }}
                  type="button"
                  class="btn"
                  onClick={() => {
                    this.reset();
                  }}
                >
                  <IoIosRefresh
                    style={{
                      color: "white",
                    }}
                  ></IoIosRefresh>
                </button>
              </div>
              {this.state.formValues.map((formValue, index) => (
                <div key={index} className="search-field">
                  <Row>
                    <Col md="4" className="d-flex justify-content-end">
                      <Typeahead
                        ref={this.typeaheadRef}
                        id={`typeahead${index}`}
                        style={{ width: "70%" }}
                        options={this.state.optionsarray}
                        value={formValue.claimfield1}
                        selected={this.state.optionsarray.filter(
                          (option) => option.value === formValue.claimfield1
                        )}
                        labelKey={(option) => `${option.label}`}
                        placeholder="What are you looking for?..."
                        onChange={(selected) => {
                          const updatedFormValues = [...this.state.formValues];
                          if (
                            selected &&
                            selected.length > 0 &&
                            selected[0] &&
                            selected[0].value
                          ) {
                            updatedFormValues[index].claimfield1 =
                              selected[0].value;
                          } else {
                            updatedFormValues[index].claimfield1 = "Default";
                          }
                          this.setState({ formValues: updatedFormValues });
                        }}
                      />
                    </Col>
                    <Col>
                      {formValue.claimfield1 === "ClaimStatus" ? (
                        <ReactSelect
                          onKeyPress={this.handleKeyPress}
                          options={this.state.statuslist}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={(selected) => {
                            const updatedFormValues = [
                              ...this.state.formValues,
                            ];
                            updatedFormValues[index].claimvalue =
                              selected || [];
                            this.setState({ formValues: updatedFormValues });
                          }}
                          allowSelectAll={true}
                          value={formValue.claimvalue}
                        />
                      ) : (
                        <Input
                          onKeyPress={this.handleKeyPress}
                          className="header__searchInput"
                          type="text"
                          placeholder="Enter text"
                          value={formValue.claimvalue}
                          onChange={(e) => {
                            const updatedFormValues = [
                              ...this.state.formValues,
                            ];
                            updatedFormValues[index].claimvalue =
                              e.target.value;
                            this.setState({ formValues: updatedFormValues });
                          }}
                        />
                      )}
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <div className="button-group">
                        {index > 0 && (
                          <Button
                            className="btn btn-danger float-right labelfontsize"
                            onClick={() => {
                              this.removeFormFields(index);
                            }}
                          >
                            <AiOutlineCloseCircle />
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <br />
                </div>
              ))}
            </div>
          </Form>
          {this.state.allocatemodal1 && (
            <Modal
              // // style={{ width: "30%" }}
              isOpen={this.state.allocatemodal1}
              onHide={this.toggle1}
              onClose={this.handleClose}
              size="lg"
              centered
            >
              <ModalHeader toggle={this.toggle1}>Allocated</ModalHeader>
              <ModalBody>
                <Row style={{ margin: "0px" }}>
                  <div style={{ width: "100%" }}>
                    <Table
                      className="StandardTable"
                      bordered
                    >
                      <thead>
                        <tr align="center">
                          <th>USERNAME</th>
                          <th>NO OF CLAIMS</th>
                          <th>COMPLETED</th>
                          <th>NOT TOUCHED</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "14px", fontFamily: "Popins" }}>
                        {this.state.tableaction &&
                          this.state.tableaction.map((data, index) => {
                            return (
                              <tr>
                                <td>{data.username}</td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/Web/Allocate",
                                    }}
                                    onClick={() => (
                                      <WebclaimAllocate
                                        value={
                                          sessionStorage.setItem(
                                            "usernamekey",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimdetailskey",
                                            data.filteredClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "checkfiltertable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "keytype",
                                            "IVR"
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimcategory",
                                            "ivrclaim"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keypageno",
                                            this.state.currentpageactionivr
                                          ) ||
                                          sessionStorage.setItem(
                                            "Claimstatus",
                                            "zero"
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.filteredClaimscount}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/IVR/Allocate",
                                    }}
                                    onClick={() => (
                                      <IvrclaimAllocate
                                        value={
                                          sessionStorage.setItem(
                                            "usernamekey",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimdetailskey",
                                            data.filteredClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "checkcompletetable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "keytype",
                                            "IVR"
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimcategory",
                                            "ivrclaim"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keypageno",
                                            this.state.currentpageactionivr
                                          ) ||
                                          sessionStorage.setItem(
                                            "Claimstatus",
                                            "Completed"
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.completedClaimscount}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: "/Manual/Allocate",
                                    }}
                                    onClick={() => (
                                      <ManualclaimAllocate
                                        value={
                                          sessionStorage.setItem(
                                            "usernamekey",
                                            data.username
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimdetailskey",
                                            data.filteredClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "completedclaims",
                                            data.completedClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouch",
                                            data.nottouchedClaimscount
                                          ) ||
                                          sessionStorage.setItem(
                                            "nottouchtable",
                                            true
                                          ) ||
                                          sessionStorage.setItem(
                                            "keytype",
                                            "IVR"
                                          ) ||
                                          sessionStorage.setItem(
                                            "claimcategory",
                                            "ivrclaim"
                                          ) ||
                                          sessionStorage.setItem(
                                            "keypageno",
                                            this.state.currentpageactionivr
                                          ) ||
                                          sessionStorage.setItem(
                                            "Claimstatus",
                                            "null"
                                          )
                                        }
                                      />
                                    )}
                                  >
                                    {data.nottouchedClaimscount}
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Row>
              </ModalBody>
              <ModalFooter>
                <div style={{ marginRight: "368px" }}>
                  <ReactPaginate
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCountaction}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClickaction}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </ModalFooter>
            </Modal>
          )}
          {this.state.fetching ? (
            <div>
              <p style={{ fontSize: "23px", marginTop: "25px", color: "#000" }}>
                <span>Loading...</span>
                <span>Please Wait...</span>
              </p>
              <div class="boxes body__loader dribbble">
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          ) : this.state.claims.length === 0 ? (
            <div class="bot-ui">
              <div class="bot-head">
                <div class="eyes-container">
                  <div class="to-left eye"></div>
                  <div class="to-right eye"></div>
                </div>
              </div>
              <div class="bot-body">
                <div class="analyzer"></div>
              </div>
              <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                No Data Found
              </p>
            </div>
          ) : (
            <div>
              {!this.state.searched ? (
                <div style={{ paddingLeft: "10px" }}>
                  <div class="table-responsive" style={{ padding: "2%" }}>
                    {this.state.sorttable === true ? (
                      <div style={{ marginLeft: "3%" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCountsort}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePagesort}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.currentpagesort}
                        />
                      </div>
                    ) : (
                      <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.currentpage}
                        />
                      </div>
                    )}
                    <div style={{marginLeft:"3%", marginRight:"3%"}}>
                    <Table
                     responsive
                     bordered
                     className="StandardTable"
                    >
                      <thead
                      >
                        <tr align="center">
                          <th>ACTION</th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "ClaimID",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "ClaimID",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {" "}
                            Claim ID
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "ClaimID",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "ClaimID",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Location",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "Location",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {" "}
                            LOCATION
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Location",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "Location",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        fieldvalue: "patientAccountNumber",
                                        sortvalue: "ascending",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        fieldvalue: "patientAccountNumber",
                                        sortvalue: "descending",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            ACCOUNT NO
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      fieldvalue: "patientAccountNumber",
                                      sortvalue: "ascending",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      fieldvalue: "patientAccountNumber",
                                      sortvalue: "descending",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "MRN",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "ascending",
                                        fieldvalue: "MRN",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            MRN
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "MRN",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "ascending",
                                      fieldvalue: "MRN",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "DateOfService",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalu: "descending",
                                        fieldvalue: "DateOfService",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "DateOfService",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalu: "descending",
                                      fieldvalue: "DateOfService",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PatientFirstName",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "PatientFirstName",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PATIENT NAME
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PatientFirstName",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "PatientFirstName",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        fieldvalue: "PatientDOB",
                                        sortvalue: "ascending",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        fieldvalue: "PatientDOB",
                                        sortvalue: "descending",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PATIENT DOB
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      fieldvalue: "PatientDOB",
                                      sortvalue: "ascending",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      fieldvalue: "PatientDOB",
                                      sortvalue: "descending",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "PlanCode",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "PlanCode",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PLAN CODE
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "PlanCode",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "PlanCode",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Pripayer",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "Pripayer",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            INSURANCE NAME
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Pripayer",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "Pripayer",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Pripayerphone",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "Pripayerphone",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            INSURANCE NO
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Pripayerphone",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "Pripayerphone",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        fieldvalue: "PrimaryPolicyNo",
                                        sortvalue: "ascending",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        fieldvalue: "PrimaryPolicyNo",
                                        sortvalue: "descending",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            POLICY NO
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      fieldvalue: "PrimaryPolicyNo",
                                      sortvalue: "ascending",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      fieldvalue: "PrimaryPolicyNo",
                                      sortvalue: "descending",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        fieldvalue: "Physician",
                                        sortvalue: "ascending",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        fieldvalue: "Physician",
                                        sortvalue: "descending",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            PHYSICIAN NAME
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      fieldvalue: "Physician",
                                      sortvalue: "ascending",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      fieldvalue: "Physician",
                                      sortvalue: "descending",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Age",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "Age",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            AGE
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Age",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "Age",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        fieldvalue: "type",
                                        sortvalue: "ascending",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        fieldvalue: "type",
                                        sortvalue: "descending",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            TYPE
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      fieldvalue: "type",
                                      sortvalue: "ascending",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      fieldvalue: "type",
                                      sortvalue: "descending",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sortvalue: "ascending",
                                        fieldvalue: "Claimunder",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sortvalue: "descending",
                                        fieldvalue: "Claimunder",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            ALLOCATED TO
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      sortvalue: "ascending",
                                      fieldvalue: "Claimunder",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      sortvalue: "descending",
                                      fieldvalue: "Claimunder",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            )}
                          </th>
                          <th
                            onClick={() => {
                              this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        fieldvalue: "ClaimStatus",
                                        sortvalue: "ascending",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        fieldvalue: "ClaimStatus",
                                        sortvalue: "descending",
                                        sorttable: true,
                                      },
                                      () => this.getsorting
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            CLAIM STATUS
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: false,
                                      fieldvalue: "ClaimStatus",
                                      sortvalue: "ascending",
                                      sorttable: true,
                                    },
                                    () => this.getsorting()
                                  )
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState(
                                    {
                                      openicon: true,
                                      fieldvalue: "ClaimStatus",
                                      sortvalue: "descending",
                                      sorttable: true,
                                    },
                                    () => this.getsorting
                                  )
                                }
                              />
                            )}
                          </th>
                        </tr>
                      </thead>
                      {this.state.sorttable === true ? (
                        <tbody>
                          {this.state.sortdata &&
                            this.state.sortdata.map((data, index) => {
                              return (
                                <tr>
                                  <td
                                    onClick={() => (
                                      (
                                        <ViewClaim
                                          value={
                                            sessionStorage.setItem(
                                              "Module",
                                              "IVR Claims"
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "ChartID",
                                              data.ChartID
                                            ) ||
                                            sessionStorage.setItem(
                                              "MRN",
                                              data.MRN
                                            ) ||
                                            sessionStorage.setItem(
                                              "Patient",
                                              data.Patient
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientDOB",
                                              data.PatientDOB
                                            ) ||
                                            sessionStorage.setItem(
                                              "Physician",
                                              data.Physician
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "Pripayer",
                                              data.Pripayer
                                            ) ||
                                            sessionStorage.setItem(
                                              "DateOfService",
                                              data.DateOfService
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDBID",
                                              data._id
                                            ) ||
                                            sessionStorage.setItem(
                                              "AllocatedTo",
                                              data.Claimunder
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <Viewmoreeligibility
                                          value={
                                            sessionStorage.setItem(
                                              "modulename",
                                              "IVR Claims"
                                            ) ||
                                            sessionStorage.setItem(
                                              "Eligibilityclaimid",
                                              data.ClaimID
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <SimpleMenu
                                          value={
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientFirstName",
                                              data.PatientFirstName
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientLastName",
                                              data.PatientLastName
                                            )
                                          }
                                        ></SimpleMenu>
                                      )
                                    )}
                                  >
                                    {" "}
                                    <SimpleMenu></SimpleMenu>
                                  </td>
                                  <td>{data.ClaimID}</td>
                                  <td>{data.Location}</td>
                                  <td>{data.patientAccountNumber}</td>
                                  <td>{data.MRN}</td>
                                  <td>
                                    {data.DateOfService
                                      ? data.DateOfService
                                      : "-"}
                                  </td>
                                  <td>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </td>
                                  <td>
                                    {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                                  </td>
                                  <td>{data.PlanCode ? data.PlanCode : "-"}</td>
                                  <td>{data.Pripayer}</td>
                                  <td>
                                    <Row>
                                      <Col md="4">
                                        <Twilio value11={data.Pripayerphone} />
                                      </Col>
                                      <Col md="2">{data.Pripayerphone}</Col>
                                    </Row>
                                  </td>
                                  <td>{data.PrimaryPolicyNo}</td>
                                  <td>{data.Physician}</td>
                                  <td>{data.Age}</td>
                                  <td>{data.type}</td>
                                  <td>{data.Claimunder}</td>
                                  <td>{data.ClaimStatus}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      ) : (
                        <tbody
                        >
                          {this.state.claims &&
                            this.state.claims.map((data, index) => {
                              return (
                                <tr>
                                  <td
                                    onClick={() => (
                                      (
                                        <ViewClaim
                                          value={
                                            sessionStorage.setItem(
                                              "Module",
                                              "IVR Claims"
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "ChartID",
                                              data.ChartID
                                            ) ||
                                            sessionStorage.setItem(
                                              "MRN",
                                              data.MRN
                                            ) ||
                                            sessionStorage.setItem(
                                              "Patient",
                                              data.Patient
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientDOB",
                                              data.PatientDOB
                                            ) ||
                                            sessionStorage.setItem(
                                              "Physician",
                                              data.Physician
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "Pripayer",
                                              data.Pripayer
                                            ) ||
                                            sessionStorage.setItem(
                                              "DateOfService",
                                              data.DateOfService
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDBID",
                                              data._id
                                            ) ||
                                            sessionStorage.setItem(
                                              "AllocatedTo",
                                              data.Claimunder
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <Viewmoreeligibility
                                          value={
                                            sessionStorage.setItem(
                                              "modulename",
                                              "IVR Claims"
                                            ) ||
                                            sessionStorage.setItem(
                                              "Eligibilityclaimid",
                                              data.ClaimID
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <SimpleMenu
                                          value={
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientFirstName",
                                              data.PatientFirstName
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientLastName",
                                              data.PatientLastName
                                            )
                                          }
                                        ></SimpleMenu>
                                      )
                                    )}
                                  >
                                    {" "}
                                    <SimpleMenu></SimpleMenu>
                                  </td>
                                  <td>{data.ClaimID}</td>
                                  <td>{data.Location}</td>
                                  <td>{data.patientAccountNumber}</td>
                                  <td>{data.MRN}</td>
                                  <td>
                                    {data.DateOfService
                                      ? data.DateOfService
                                      : "-"}
                                  </td>
                                  <td>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </td>
                                  <td>
                                    {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                                  </td>
                                  <td>{data.PlanCode ? data.PlanCode : "-"}</td>
                                  <td>{data.Pripayer}</td>
                                  <td>
                                    <Row>
                                      <Col md="4">
                                        <Twilio value11={data.Pripayerphone} />
                                      </Col>
                                      <Col md="2">{data.Pripayerphone}</Col>
                                    </Row>
                                  </td>
                                  <td>{data.PrimaryPolicyNo}</td>
                                  <td>{data.Physician}</td>
                                  <td>{data.Age}</td>
                                  <td>{data.type}</td>
                                  <td>{data.Claimunder}</td>
                                  <td>{data.ClaimStatus}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      )}
                    </Table>
                    </div>
                    {this.state.sorttable === true ? (
                      <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCountsort}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePagesort}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.currentpagesort}
                        />
                      </div>
                    ) : (
                      <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.currentpage}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  {this.state.searching ? (
                    <div>
                      <img src="https://i.pinimg.com/originals/f5/27/0a/f5270acbc4b98112fcd520d2eea023de.gif" />
                    </div>
                  ) : this.state.filtereddata.length === 0 ? (
                    <div style={{ paddingLeft: "10px" }}>
                      <p>
                        <img src={searchresult} />
                      </p>
                    </div>
                  ) : (
                    <div style={{ paddingLeft: "10px" }}>
                      <div class="table-responsive">
                        <div style={{ marginLeft: "3%" }}>
                          <ReactPaginate
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.spagecount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageSearchClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            forcePage={this.state.scurrentpage}
                          />
                        </div>
                        <Table
                        responsive
                        bordered
                          style={{
                            marginLeft: "3%",
                          }}
                          className="StandardTable"
                        >
                          <thead>
                            <tr>
                              <th>ACTION</th>
                              <th
                                onClick={() => this.sortingfilter("ClaimID")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {" "}
                                Claim ID
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sortingfilter("Location")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {" "}
                                LOCATION
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() =>
                                  this.sortingfilter("patientAccountNumber")
                                }
                                style={{ whiteSpace: "nowrap" }}
                              >
                                ACCOUNT NO
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sortingfilter("MRN")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {" "}
                                MRN
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() =>
                                  this.sortingfilter("DateOfService")
                                }
                                style={{ whiteSpace: "nowrap" }}
                              >
                                DOS
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() =>
                                  this.sortingfilter("PatientFirstName")
                                }
                                style={{ whiteSpace: "nowrap" }}
                              >
                                PATIENT NAME
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sortingfilter("PatientDOB")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                PATIENT DOB
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sortingfilter("PlanCode")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                PLAN CODE
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sortingfilter("Pripayer")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                INSURANCE NAME
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() =>
                                  this.sortingfilter("Pripayerphone")
                                }
                                style={{ whiteSpace: "nowrap" }}
                              >
                                INSURANCE NO
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() =>
                                  this.sortingfilter("PrimaryPolicyNo")
                                }
                                style={{ whiteSpace: "nowrap" }}
                              >
                                POLICY NO
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sortingfilter("Physician")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                PHYSICIAN NAME
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sortingfilter("Age")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                AGE
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon11: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sortingfilter("type")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                TYPE
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon12: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() => this.sortingfilter("Claimunder")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                ALLOCATED TO
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                              <th
                                onClick={() =>
                                  this.sortingfilter("ClaimStatus")
                                }
                                style={{ whiteSpace: "nowrap" }}
                              >
                                CLAIM STATUS
                                {this.state.openicon ? (
                                  <BiUpArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: false })
                                    }
                                  />
                                ) : (
                                  <BiDownArrowAlt
                                    onClick={() =>
                                      this.setState({ openicon: true })
                                    }
                                  />
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody
                          >
                            {this.state.filtereddata &&
                              this.state.filtereddata.map((data, index) => {
                                return (
                                  <tr>
                                    <td
                                      onClick={() => (
                                        (
                                          <ViewClaim
                                            value={
                                              sessionStorage.setItem(
                                                "Module",
                                                "IVR Claims"
                                              ) ||
                                              sessionStorage.setItem(
                                                "ClaimID",
                                                data.ClaimID
                                              ) ||
                                              sessionStorage.setItem(
                                                "ChartID",
                                                data.ChartID
                                              ) ||
                                              sessionStorage.setItem(
                                                "MRN",
                                                data.MRN
                                              ) ||
                                              sessionStorage.setItem(
                                                "Patient",
                                                data.Patient
                                              ) ||
                                              sessionStorage.setItem(
                                                "PatientDOB",
                                                data.PatientDOB
                                              ) ||
                                              sessionStorage.setItem(
                                                "Physician",
                                                data.Physician
                                              ) ||
                                              sessionStorage.setItem(
                                                "ClaimDate",
                                                data.ClaimDate
                                              ) ||
                                              sessionStorage.setItem(
                                                "Pripayer",
                                                data.Pripayer
                                              ) ||
                                              sessionStorage.setItem(
                                                "DateOfService",
                                                data.DateOfService
                                              ) ||
                                              sessionStorage.setItem(
                                                "ClaimDate",
                                                data.ClaimDate
                                              ) ||
                                              sessionStorage.setItem(
                                                "ClaimDBID",
                                                data._id
                                              ) ||
                                              sessionStorage.setItem(
                                                "AllocatedTo",
                                                data.Claimunder
                                              )
                                            }
                                          />
                                        ),
                                        (
                                          <Viewmoreeligibility
                                            value={
                                              sessionStorage.setItem(
                                                "modulename",
                                                "IVR Claims"
                                              ) ||
                                              sessionStorage.setItem(
                                                "Eligibilityclaimid",
                                                data.ClaimID
                                              )
                                            }
                                          />
                                        ),
                                        (
                                          <SimpleMenu
                                            value={
                                              sessionStorage.setItem(
                                                "ClaimID",
                                                data.ClaimID
                                              ) ||
                                              sessionStorage.setItem(
                                                "PatientFirstName",
                                                data.PatientFirstName
                                              ) ||
                                              sessionStorage.setItem(
                                                "PatientLastName",
                                                data.PatientLastName
                                              )
                                            }
                                          ></SimpleMenu>
                                        )
                                      )}
                                    >
                                      {" "}
                                      <SimpleMenu></SimpleMenu>
                                    </td>
                                    <td>{data.ClaimID}</td>
                                    <td>{data.Location}</td>
                                    <td>{data.patientAccountNumber}</td>
                                    <td>{data.MRN}</td>
                                    <td>
                                      {data.DateOfService
                                        ? data.DateOfService
                                        : "-"}
                                    </td>
                                    <td>
                                      {data.PatientFirstName}&nbsp;
                                      {data.PatientLastName}
                                    </td>
                                    <td>
                                      {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                                    </td>
                                    <td>
                                      {data.PlanCode ? data.PlanCode : "-"}
                                    </td>
                                    <td>{data.Pripayer}</td>
                                    <td>
                                      <Row>
                                        <Col md="4">
                                          <Twilio
                                            value11={data.Pripayerphone}
                                          />
                                        </Col>
                                        <Col md="2">{data.Pripayerphone}</Col>
                                      </Row>
                                    </td>
                                    <td>{data.PrimaryPolicyNo}</td>
                                    <td>{data.Physician}</td>
                                    <td>{data.Age}</td>
                                    <td>{data.type}</td>
                                    <td>{data.Claimunder}</td>
                                    <td>{data.ClaimStatus}</td>
                                  </tr>
                                );
                                //  }
                              })}
                          </tbody>
                        </Table>
                        <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                          <ReactPaginate
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.spagecount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageSearchClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            forcePage={this.state.scurrentpage}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      </div>
    );
  }
}

export default IVRClaims;
