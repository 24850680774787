import { React, Component } from "react";
import Newsidebar from "../../Dashboard/Newsidebar";
import "../../../Styles/subdenial.css";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Input } from "reactstrap";
import moment from "moment";
import { DatePicker } from "antd";
import SubdenialBuckets from "./SubdenialBuckets";
const { RangePicker } = DatePicker;
class SubDenial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterstatus: "custom",
      valueOfInput1: "",
      valueOfInput2: "",
      denialmg: sessionStorage.getItem("denialmg"),
      searchid: sessionStorage.getItem("searchid"),
      claimcategory: sessionStorage.getItem("queryclaimiddenial"),
      querytouch: sessionStorage.getItem("querysubcategorytouch"),
      queryagegrp: sessionStorage.getItem("querysubcategoryagegrp"),
      subcategorydenial: sessionStorage.getItem("querysubcategoryvalue"),
      querysearchid: sessionStorage.getItem("querysearchid"),
    };
  }
  valuedate = (option, e) => {
    if (option === "yesterday") {
      this.setState({
        valueOfInput1: e.target.value,
        valueOfInput2: moment().subtract(1, "days").format("MM-DD-YYYY"),
        filterstatus: option,
      });
    } else {
      this.setState({
        valueOfInput1: e.target.value,
        valueOfInput2: moment().format("MM-DD-YYYY"),
        filterstatus: option,
      });
    }
  };
  render() {
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar>
        <p>
        <Link
          to="/denial-management"
          style={{ textDecoration: "none", color: "white",fontSize: "15px",
          fontWeight: 500 }}
        >
          Denial Management
        </Link>
        &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>
        &nbsp;Category
      </p>
        </Newsidebar>
        <br />
        <div className="align-column">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Input
                type="select"
                style={{ margin: "2%", width: "100%", fontSize: "13px" }}
                className="form-control form-select"
                onChange={(e) => {
                  const index = e.target.selectedIndex;
                  const el = e.target.childNodes[index];
                  const option = el.getAttribute("title");
                  this.valuedate(option, e);
                  this.setState(() => {
                    sessionStorage.setItem(
                      "querystartdate",
                      this.state.valueOfInput1
                    );
                    sessionStorage.setItem(
                      "queryenddate",
                      this.state.valueOfInput2
                    );
                  });
                }}
              >
                <option value="" title="custom">
                  Custom
                </option>
                <option value={moment().format("MM-DD-YYYY")} title="">
                  Today
                </option>
                <option
                  value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                  title="yesterday"
                >
                  Yesterday
                </option>
                <option
                  value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                  title=""
                >
                  Last 3 days
                </option>
                <option
                  value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                  title=""
                >
                  Last 7 days
                </option>
                <option
                  value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                  title=""
                >
                  Last 30 days
                </option>
                <option
                  value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                  title=""
                >
                  Last 365 days
                </option>
              </Input>
              {this.state.filterstatus === "custom" && (
                <RangePicker
                  format="MM-DD-YYYY"
                  onChange={(dates, datestring) => {
                    this.setState(
                      {
                        valueOfInput1: datestring[0],
                        valueOfInput2: datestring[1],
                      },
                      () => {
                        sessionStorage.setItem(
                          "querystartdate",
                          this.state.valueOfInput1
                        );
                        sessionStorage.setItem(
                          "queryenddate",
                          this.state.valueOfInput2
                        );
                      }
                    );
                  }}
                  style={{
                    borderColor: "gray",
                    width: "200%",
                    height: "60%",
                    float: "right",
                    margin: "2%",
                  }}
                />
              )}
            </div>
          </div>
          <br />
          <SubdenialBuckets
            startdate={this.state.valueOfInput1}
            enddate={this.state.valueOfInput2}
            querytouch={this.state.querytouch}
            queryagegrp={this.state.queryagegrp}
            claimcategory={this.state.claimcategory}
            searchid={this.state.searchid}
            subcategorydenial={this.state.subcategorydenial}
          />
        </div>
      </div>
    );
  }
}
export default SubDenial;
