import { Component } from "react";
import axios from "axios";
import { ResponsiveContainer } from "recharts";
import Newsidebar from "../../Dashboard/Newsidebar";
import { MdKeyboardArrowRight } from "react-icons/md";
import { formatCurrency } from "./utils";
import { Link } from "react-router-dom";
import { ip } from "../../Apis/Api";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  LabelList,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const ClaimTooltip = ({ active, payload }) => {
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          {payload[0].payload.name}
          <br />
          <p style={{ color: payload[0].color }}>
            {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
          </p >
        </div>
      );
    }
    return null;
  };
class TopCpt extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
    dataarray:[],
    client:sessionStorage.getItem("clientid"),
  };
  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getData = async () => {
    try{
    const headers = {
      "Content-Type" : "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if(this.state.client) {
      url = `${ip}/trends/topcptper?clientId=${this.state.client}`
    }
    else {
      url = `${ip}/trends/topcptper`
    }
    const res = await axios
    .get(url,{headers:headers})
    console.log(res.data.data,"SATHISH")
    this.setState({
          dataarray : res.data.data
        })
    }
    catch(err)
    {
      console.log("errr",err)
    }
    // .then((res) => {
    //   this.setState({
    //     dataarray : res.data.data
    //   },()=>{console.log("star",this.state.dataarray)})
    // })
    // .catch((err) =>{})
  }
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getData();
  }
  render() {
    return (
      <div>
      <Newsidebar />
      <div className="align-row">
        <div className="align-column">

          <div>
            <div
              style={{
                textAlign: "left",
                marginLeft: "3%",
                marginTop: "2%",
                fontSize: "14px",
              }}
            >
              <p>
                <Link
                  to="/management-trends-patterns"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Trends & Patterns
                </Link>
                &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Top 10
                CPT with Maximum AR With Ageing Greater than 60 Days
              </p>
            </div>
            <br />
            <ResponsiveContainer width={"96%"} height={600}>
              <ComposedChart
                layout="vertical"
                width={500}
                height={400}
                data={this.state.dataarray}
                margin={{
                  top: 10,
                  right: 20,
                  bottom: 20,
                  left: 5,
                }}
              >
                <defs>
                  <linearGradient
                    id="colorUv"
                    x1="100%"
                    y1="0"
                    x2="0"
                    y2="0"
                    spreadMethod="reflect"
                  >
                    <stop offset="0" stopColor="#F85472" />
                    <stop offset="1" stopColor="#FA859A" />
                  </linearGradient>
                </defs>
                <XAxis
                dataKey="totalpaidamount"
                  type="number"
                  orientation="top"
                  tickLine={false}
                  axisLine={false}
                  tick={{
                    fontSize: "15",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fill: "black",
                  }}
                />
                <YAxis
                  dataKey="name"
                  type="category"
                  tickLine={false}
                  axisLine={false}
                  scale="band"
                  tick={{
                    fontSize: "15",
                    fontWeight: "500",
                    fill: "black",
                    fontFamily: "Poppins",
                  }}
                  height={300}
                  width={370}
                />
                <Tooltip content={<ClaimTooltip/>}/>
                <Legend />
                <Bar
                  dataKey="totalpaidamount"
                  radius={[0, 10, 10, 0]}
                  strokeWidth="2"
                  barSize={40}
                  fill="url(#colorUv)"
                >
                  <LabelList type="number" position="insideEnd" fill="white" formatter={formatCurrency} />
                </Bar>
                <Line
                  dataKey="totalpaidamount"
                  type="monotone"
                  stroke="#DC0930"
                  strokeWidth="1"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
export default TopCpt;







