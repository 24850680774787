import React, { Component } from "react";
import axios from "axios";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import loading from "../../Images/loader.gif";
import "../../Styles/loader.scss";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { ageOptions, type, userstatus } from "./data.js";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Spinner } from "react-bootstrap";
import CsvDownload from "react-json-to-csv";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { GrRevert } from "react-icons/gr";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import searchresult from "../../Images/searchresult.jpg";
import { styled } from "@mui/material/styles";
import Checkbox from "./Checkbox.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Col, Input } from "reactstrap";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { Card, Row } from "react-bootstrap";
import Select from "react-dropdown-select";
import { ip } from "../Apis/Api";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
import ViewClaim from "../Transaction/AllocationCardComponents/ViewClaim";
import SimpleMenu from "../Transaction/AllocationCardComponents/MenuList";
import Viewmoreeligibility from "../Eligibility/Viewmoreeligibility";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
class Allocated extends Component {
  constructor(props) {
    super(props);
    this.typeaheadRef = React.createRef();
    this.state = {
      searchCompleted:false,
      optionsarray: [],
      modal: false,
      typeselect: [],
      modalunallocate: false,
      doioptionSelected: [],
      claimstatusclaim: [],
      modalunallocate1: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      claimsdata: [],
      reports: [],
      newuser: [],
      olduser: [],
      ClaiMid: [],
      fetching: true,
      ar: [],
      loading: true,
      any: [],
      statuslist: [],
      claimstatussearch: [],
      firstlast: [],
      PatientFirstName: "",
      PatientLastName: "",
      patientAccountNumber: "",
      doioptionstatus: [],
      doioptionstatus3: [],
      searchtext: "",
      searchclaimdata: [],
      payercategories: [],
      cptdata: [],
      status: [],
      optionSelected: [],
      UserAllocate:
        sessionStorage.getItem("firstname") +
        " " +
        sessionStorage.getItem("lastname"),
      username: "",
      claimfield: "",
      user: "",
      claimvalue: "",
      claimtype: "",
      payervalue: [],
      cptvalue: [],
      userdetails: [],
      query: [],
      ids: [],
      offset: 0,
      currentpage: 0,
      currentpagesearch: 0,
      perPage: sessionStorage.getItem("Default_pagination"),
      checkedAll: false,
      alldataresponse: [],
      searchdataresponse: [],
      selecteduser: [],
      users: [],
      user_id: sessionStorage.getItem("user_id"),
      role: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
      clientslist: [],
      opendropdown: false,
      Location: "",
      locationslist: [],
      username: [],
      count: 10,
      agegrplist: [
        "0-30days",
        "31-60days",
        "61-90days",
        "91-120days",
        "121-180days",
        "181-365days",
        "above 1year",
      ],
      users1: [],
      doiagegrplist: [
        "0-30days",
        "31-60days",
        "61-90days",
        "91-120days",
        "121-180days",
        "181-365days",
        "above 1year",
      ],
      userstatuslist: ["Completed", "TO-DO", "Not Touch"],
      userstatus: "",
      userstatusselect: [],
      bulkunallocated: false,
      agegrp: "",
      doiagegrp: "",
      unallocateid: "",
      sortstate: "ASC",
      openicon: false,
      claimids: [],
      formValues: [
        {
          claimfield1: "",
          claimvalue: "",
        },
      ],
      sperPage: sessionStorage.getItem("Default_pagination"),
      claimstatuslist: [],
      userid: [],
      count: [],
      status1: [],
      status2: [],
      status3: [],
      status4: [],
      status5: [],
      agegrp1: "",
      agegrp2: "",
      agegrp3: "",
      agegrp4: "",
      agegrp5: "",
      allocatecount: [
        "25",
        "50",
        "75",
        "100",
        "125",
        "150",
        "200",
        "250",
        "300",
        "350",
        "400",
        "450",
        "500",
      ],
      sort: "",
      fieldsort: "",
      sorttable: "false",
      sortdata: [],
      gettype: "",
      perPagesort: sessionStorage.getItem("Default_pagination"),
      offsetsort: 0,
      currentpagesort: 0,
      pageCountsort: "",
      searchfilterdata: [],
      searchfiltertable: "false",
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  toggle1 = () => {
    const { modalunallocate } = this.state;
    this.setState({ modalunallocate: !modalunallocate });
  };
  toggle2 = () => {
    const { modalunallocate1 } = this.state;
    this.setState({ modalunallocate1: !modalunallocate1 });
  };
  handleChange = (selected) => {
    this.setState({
      optionSelected: selected,
    });
  };
  handleChange3 = (Claimstatus) => {
    this.setState({
      doioptionstatus: Claimstatus,
    });
  };
  doihandleChange = (selected) => {
    this.setState({
      doioptionSelected: selected,
    });
  };
  toggle3 = () => {
    const { bulkunallocated } = this.state;
    this.setState({
      bulkunallocated: !bulkunallocated,
    });
  };
  handleChangetype = (selected) => {
    this.setState({
      typeselect: selected,
    });
  };
  handleChangeuser = (selected) => {
    this.setState({
      userstatusselect: selected,
    });
  };
  handleclaim1 = (select) => {
    this.setState({
      claimstatusclaim: select,
    });
  };
  checkboxsearchall = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.searchdataresponse.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle2();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };
  allocateclaims = () => {
    const isValid = this.validate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        ids: this.state.ids,
      };
      const res = axios
        .put(
          `${ip}/allocation/allocate/${this.state.selecteduser[0]._id}`,
          value,
          {
            headers: headers,
          }
        )
        .then((res) => {
          alert(
            `claims allocated to user ${this.state.selecteduser[0].first_name}`
          );
          if (this.state.searchtext === 1) {
            this.filtersearch();
          } else {
            this.getallocatedclaims();
          }
          this.toggle();
          this.toggle2();
          // window.location.reload();
        })

        .catch((err) => {});
    }
  };
  handleChangeLocation = (selectedOptions) => {
    let Location = selectedOptions.map((data) => {
      return data.Location;
    });
    this.setState(
      {
        Location: Location[0],
      },
      () => {
        this.getclients();
        this.getLocation();
        this.getallocatedclaims();
        this.getcpt();
        this.getclaims();
        this.getpayercategory();
        this.getClaimStatus();
        this.getusers();
      }
    );
  };
  bulkunallocatedclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      ids: this.state.ids,
      logdetails: {
        // newUser:this.state.username,
        admin: this.state.UserAllocate,
        module: "Unallocation",
        clientId: this.state.client,

        // ClaimID:["11751701/25/2022INS-206"],
        // admin:"demoImagnum",
        // module:"Allocation",
        // clientId":2004
      },
    };
    const res = axios
      .put(`${ip}/claim/updatemultipleclaimunder`, value, {
        headers: headers,
      })
      .then((res) => {
        alert(`claim unallocated successfully`);
        if (this.state.searchtext === 1) {
          this.filtersearch();
        } else {
          this.getallocatedclaims();
        }
        this.toggle2();
        // window.location.reload();
      })

      .catch((err) => {});
  };
  allocateusers = async () => {
    const isValid = true;
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      console.log("status1", this.state.status1);
      if (this.state.status1.length !== 0) {
        this.state.userid.push(this.state.status1);
        this.state.count.push(this.state.agegrp1);
      }
      this.state.users.map((data, index) => {
        if (this.state.status1 === data._id) {
          // this.state.username.push(data.first_name + " " + data.last_name)
        }
      });
      if (this.state.status2.length !== 0) {
        this.state.userid.push(this.state.status2);
        this.state.count.push(this.state.agegrp2);
      }
      this.state.users.map((data, index) => {
        if (this.state.status2 === data._id) {
          // this.state.username.push(data.first_name + " " + data.last_name)
        }
      });
      if (this.state.status3.length !== 0) {
        this.state.userid.push(this.state.status3);
        this.state.count.push(this.state.agegrp3);
      }
      this.state.users.map((data, index) => {
        if (this.state.status3 === data._id) {
          // this.state.username.push(data.first_name + " " + data.last_name)
        }
      });
      if (this.state.status4.length !== 0) {
        this.state.userid.push(this.state.status4);
        this.state.count.push(this.state.agegrp4);
      }
      this.state.users.map((data, index) => {
        if (this.state.status4 === data._id) {
          // this.state.username.push(data.first_name + " " + data.last_name)
        }
      });
      if (this.state.status5.length !== 0) {
        this.state.userid.push(this.state.status5);
        this.state.count.push(this.state.agegrp5);
      }
      this.state.users.map((data, index) => {
        if (this.state.status5 === data._id) {
          // this.state.username.push(data.first_name + " " + data.last_name)
        }
      });
      console.log("user name array", this.state.username);
      const value = {
        claimid: this.state.ids,
        userid: this.state.userid,
        allocatecount: this.state.count,
        logdetails: {
          // newUser:this.state.username,
          admin: this.state.UserAllocate,
          module: "Reallocation",
          clientId: this.state.client,

          // ClaimID:["11751701/25/2022INS-206"],
          // admin:"demoImagnum",
          // module:"Allocation",
          // clientId":2004
        },
      };
      console.log("hiteam", this.state.ClaiMid);
      if (this.state.ids.length !== 0) {
        const res = await axios
          .put(`${ip}/claimstatus/allocatemultipleuser`, value, {
            headers: headers,
          })
          .then((res) => {
            alert("Claims allocated successfully!");
            // this.setState({ userid: [], count: [] });
            // this.toggle();
            if (this.state.searchtext === 1) {
              this.filtersearch();
            } else {
              // window.location.reload();
              this.getallocatedclaims();
            }
            this.toggle2();
          })

          .catch((err) => {});
      } else {
        alert("There are no claims to allocate!");
        // this.toggle();
      }
    }
  };

  unallocateclaims = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      id: this.state.unallocateid,
      logdetails: {
        // newUser:this.state.username,
        admin: this.state.UserAllocate,
      },
    };
    const res = axios
      .put(`${ip}/claim/updateclaimunder`, value, {
        headers: headers,
      })
      .then((res) => {
        alert(`claim unallocated successfully`);
        if (this.state.searchtext === 1) {
          this.filtersearch();
        } else {
          this.getallocatedclaims();
        }

        this.toggle1();
        // window.location.reload();
      })

      .catch((err) => {});
  };
  toggledropdown = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };
  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          this.setState({
            clientslist: res.data.clientonboard,
            // clientname: res.data.clientonboard[0].client,
          });
        } else {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        }
      })
      .catch((err) => {});
  };
  getClaimStatus = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    const res = await axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        var data = res.data.claimstatus.map((e) => ({
          value: e.claimStatus,
          label: e.claimStatus,
        }));
        this.setState({
          statuslist: data,
          claimstatuslist: res.data.claimstatus,
        });
        console.log(this.state.claimstatussearch, "claim");
      })
      .catch((err) => {});
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getallocatedclaims();
      }
    );
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpagesearch: selectedPage,
        offset: offset,
      },
      () => {
        this.filtersearch();
      }
    );
  };
  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //get Location
  getLocation = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      url = `${ip}/location/all/list?clientId=${this.state.client}`;
    } else {
      url = `${ip}/location/all/list`;
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        let data = res.data.location;
        data = data.filter((item) => {
          return item.clientId == this.state.client;
        });
        data.sort((a, b) => {
          return a.Location < b.Location ? -1 : 1;
        });

        this.setState({ locationslist: data });
      })

      .catch((err) => {});
  };

  getdownloadfile = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    if (
      this.state.role === "Admin" ||
      this.state.role === "SuperAdmin" ||
      this.state.role === "Team Manager"
    ) {
      let url;
      if (this.state.client) {
        if (this.state.Location) {
          url = `${ip}/allocation/allocated/all?clientId=${this.state.client}&Location=${this.state.Location}`;
        } else {
          url = `${ip}/allocation/allocated/all?clientId=${this.state.client}`;
        }
      } else {
        url = `${ip}/allocation/allocated/all`;
      }
      fetch(url, { headers: headers }).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", "Allocated");
          a.click();
          URL.revokeObjectURL(a);
        });
      });
    }
  };
  handleChange4 = (OverallClaimStatus) => {
    this.setState({
      doioptionstatus3: OverallClaimStatus,
    });
  };
  //getusers
  getallocatedclaims = async () => {
    //this.setState({ fetching: true });
    //api call to get all users

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    if (
      this.state.role === "Admin" ||
      this.state.role === "SuperAdmin" ||
      this.state.role === "Team Manager"
    ) {
      let url;
      if (this.state.client) {
        if (this.state.Location) {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.client
          }&pageno=${this.state.currentpage + 1}&count=${
            this.state.perPage
          }&Location=${this.state.Location}`;
        } else {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.client
          }&pageno=${this.state.currentpage + 1}&count=${this.state.perPage}`;
        }
      } else {
        url = `${ip}/allocation/allocated/all?pageno=${
          this.state.currentpage + 1
        }&count=${this.state.perPage}`;
      }
      const res = axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          this.setState({
            alldataresponse: res.data.claims,
            pageCount: Math.ceil(res.data.count / this.state.perPage),
            claimsdata: res.data.claims,
            fetching: false,
            // any: res.data.claims,
          });
        })
        .catch((err) => {});
    } else if (this.state.role === "Team Member") {
      const res = axios
        .get(`${ip}/claim/user?userid=${this.state.user_id}`, {
          headers: headers,
        })
        .then((res) => {
          const slice = res.data.Claims.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          this.setState({
            alldataresponse: res.data.Claims,
            pageCount: Math.ceil(res.data.Claims.length / this.state.perPage),
            claimsdata: slice,
          });
        })
        .catch((err) => {});
    }
  };
  getclaims = async () => {
    //this.setState({ fetching: true });
    //api call to get all users

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    if (
      this.state.role === "Admin" ||
      this.state.role === "SuperAdmin" ||
      this.state.role === "Team Manager"
    ) {
      let url;
      if (this.state.client) {
        if (this.state.Location) {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.client
          }&pageno=${this.state.currentpage + 1}&count=${
            this.state.perPage
          }&Location=${this.state.Location}`;
        } else {
          url = `${ip}/allocation/allocated/all?clientId=${
            this.state.client
          }&pageno=${this.state.currentpage + 1}&count=${this.state.perPage}`;
        }
      } else {
        url = `${ip}/allocation/allocated/all?pageno=${
          this.state.currentpage + 1
        }&count=${this.state.perPage}`;
      }
      const res = axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          this.setState({
            alldataresponse: res.data.claims,
            pageCount: Math.ceil(res.data.count / this.state.perPage),
            claimsdata: res.data.claims,
            fetching: false,
            // any: res.data.claims,
          });
        })
        .catch((err) => {});
    } else if (this.state.role === "Team Member") {
      const res = axios
        .get(`${ip}/claim/user?userid=${this.state.user_id}`, {
          headers: headers,
        })
        .then((res) => {
          const slice = res.data.Claims.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
          );
          this.setState({
            alldataresponse: res.data.Claims,
            pageCount: Math.ceil(res.data.Claims.length / this.state.perPage),
            claimsdata: slice,
          });
        })
        .catch((err) => {});
    }
  };
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.filtersearch();
    }
  };
  getcpt = async () => {
    //api call to get all cpt data
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/cptmaster/all`, { headers: headers })
      .then((res) => {
        this.setState({
          cptdata: res.data.cpt,
        });
      })
      .catch((err) => {});
  };
  getpayercategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
        let other = {
          category_name: "Others",
        };
        let data = res.data.payer;
        data.push(other);
        this.setState({ payercategories: data }, () => {});
        // this.setState({payerselect:this.state.payercategories})
      })

      .catch((err) => {});
  };
  // getquery = () => {
  //   var query_o;
  //   // var query_final;
  //   let query = [];

  //   if (this.state.payervalue.length !== 0) {
  //     if (this.state.payervalue[0].category_name !== "") {
  //       query_o = `Payercategory=${this.state.payervalue[0].category_name}`;
  //       query.push(query_o);
  //     }
  //   }
  //   if (this.state.claimids.length !== 0) {
  //     let claimlist = [];
  //     let output = this.state.claimids.split(",");
  //     claimlist = output.map((str) => '"' + str + '"');
  //     const withCommasInBetween = claimlist.join(",");
  //     query_o = `ClaimID=[${withCommasInBetween}]`;
  //     query.push(query_o);
  //   }

  //   if (this.state.claimtype) {
  //     query_o = `type=${this.state.claimtype}`;
  //     query.push(query_o);
  //   }
  //   if (this.state.optionSelected.length !== 0) {
  //     let agegrp = [];
  //     let temp;
  //     let agelist;
  //     this.state.optionSelected.map((ele) => {
  //       agegrp.push(ele.value);
  //     });
  //     agelist = agegrp.map((str) => '"' + str + '"');
  //     query_o = `AgeGrp=[${agelist}]`;
  //     query.push(query_o);
  //   }
  //   if (this.state.doioptionstatus.length !== 0) {
  //     let agegrp = [];
  //     let temp;
  //     let agelist;
  //     this.state.doioptionstatus.map((ele) => {
  //       agegrp.push(ele.value);
  //     });
  //     agelist = agegrp.map((str) => '"' + str + '"');
  //     query_o = `ClaimStatus=[${agelist}]`;
  //     query.push(query_o);
  //   }
  //   if (this.state.doioptionstatus3.length !== 0) {
  //     let agegrp = [];
  //     let temp;
  //     let agelist;
  //     this.state.doioptionstatus3.map((ele) => {
  //       agegrp.push(ele.value);
  //     });
  //     agelist = agegrp.map((str) => '"' + str + '"');
  //     query_o = `Claimunder=[${agelist}]`;
  //     query.push(query_o);
  //   }
  //   if (this.state.doioptionSelected.length !== 0) {
  //     let agegrp = [];
  //     let temp;
  //     let agelist;
  //     this.state.doioptionSelected.map((ele) => {
  //       agegrp.push(ele.value);
  //     });
  //     agelist = agegrp.map((str) => '"' + str + '"');
  //     query_o = `DOIAgeGrp=[${agelist}]`;
  //     query.push(query_o);
  //   }

  //   if (this.state.userstatusselect.length !== 0) {
  //     let agegrp = [];
  //     let temp;
  //     let agelist;
  //     this.state.userstatusselect.map((ele) => {
  //       agegrp.push(ele.value);
  //     });
  //     agelist = agegrp.map((str) => '"' + str + '"');
  //     let data = agelist.filter((item) => {
  //       return item == '""';
  //     });

  //     console.log(data, "print");
  //     let data2;

  //     if (data.length !== 0) {
  //       data2 = 1;
  //     }

  //     if (data2 === 1) {
  //       agelist.push("null");
  //     }
  //     query_o = `OverallClaimStatus=[${agelist}]`;
  //     query.push(query_o);
  //   }

  //   if (this.state.typeselect.length !== 0) {
  //     let agegrp = [];
  //     let temp;
  //     let agelist;
  //     this.state.typeselect.map((ele) => {
  //       agegrp.push(ele.value);
  //     });
  //     agelist = agegrp.map((str) => '"' + str + '"');
  //     query_o = `type=[${agelist}]`;
  //     query.push(query_o);
  //   }
  //   if (this.state.userstatus.length !== 0) {
  //     query_o = `OverallClaimStatus=${this.state.userstatus}`;
  //     query.push(query_o);
  //   }
  //   if (this.state.status.length !== 0) {
  //     query_o = `ClaimStatus=${this.state.status}`;
  //     query_o.toLowerCase();
  //     query.push(query_o);
  //   }
  //   if (this.state.claimfield && this.state.claimvalue !== "") {
  //     query_o = `${this.state.claimfield}=${this.state.claimvalue}`;
  //     query.push(query_o);
  //   }
  //   if (query.length === 1) {
  //     this.state.query.push(`${query[0]}`);
  //   }
  //   if (query.length === 2) {
  //     this.state.query.push(`${query[0]}&${query[1]}`);
  //   }
  //   if (query.length === 3) {
  //     this.state.query.push(`${query[0]}&${query[1]}&${query[2]}`);
  //   }
  //   if (query.length === 4) {
  //     this.state.query.push(`${query[0]}&${query[1]}&${query[2]}&${query[3]}`);
  //   }
  //   if (query.length === 5) {
  //     this.state.query.push(
  //       `${query[0]}&${query[1]}&${query[2]}&${query[3]}&${query[4]}`
  //     );
  //   }
  //   if (query.length === 6) {
  //     this.state.query.push(
  //       `${query[0]}&${query[1]}&${query[2]}&${query[3]}&${query[4]}&${query[5]}`
  //     );
  //   }
  //   if (query.length === 7) {
  //     this.state.query.push(
  //       `${query[0]}&${query[1]}&${query[2]}&${query[3]}&${query[4]}&${query[5]}&${query[6]}`
  //     );
  //   }
  // };
  filtersearch = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.formValues.some(formValue => formValue.claimfield1 !== "" && formValue.claimvalue !== "")) {
      let url;
      if (this.state.client) {
        if (this.state.Location) {
          url = `${ip}/allocation/allocated/advancedmultiplefilter?pageno=${this.state.currentpagesearch}&count=${this.state.perPage}&clientId=${this.state.client}&Location=${this.state.Location}`;
        } else {
          url = `${ip}/allocation/allocated/advancedmultiplefilter?pageno=${this.state.currentpagesearch}&count=${this.state.perPage}&clientId=${this.state.client}`;
        }
      } else {
        url = `${ip}/allocation/allocated/advancedmultiplefilter?pageno=${this.state.currentpagesearch}&count=${this.state.perPage}`;
      }
      const searchCriteria = {};
      this.state.formValues.forEach((formValue) => {
        if (formValue.claimfield1 && formValue.claimvalue) {
          searchCriteria[formValue.claimfield1] = formValue.claimvalue;
        }
      });
      this.state.formValues.forEach((formValue) => {
        if (formValue.claimfield1 === "ClaimID") {
          let output = formValue.claimvalue.split(",");
          let claimlist = output.map((str) => '"' + str + '"');
          const withCommasInBetween = claimlist.join(",");
          searchCriteria[formValue.claimfield1] = `[${withCommasInBetween}]`;
        }
      });
      this.state.formValues.forEach((formValue) => {
        if (formValue.claimfield1 === "ClaimStatus") {
          const userValues = formValue.claimvalue.map((option) => option.value);
          let statuslist;
          statuslist = userValues.map((str) => '"' + str + '"');
          searchCriteria[formValue.claimfield1] = `[${statuslist.join(", ")}]`;     
           }
      });
      this.state.formValues.forEach((formValue) => {
        if (
          formValue.claimfield1 === "type" &&
          formValue.claimvalue.length > 0
        ) {
          const typeValues = formValue.claimvalue.map((option) => option.value);
          let agelist;
          agelist = typeValues.map((str) => '"' + str + '"');
          searchCriteria[formValue.claimfield1] = `[${agelist.join(", ")}]`;
        }
      });
      this.state.formValues.forEach((formValue) => {
        if (
          formValue.claimfield1 === "Claimunder" &&
          formValue.claimvalue.length > 0
        ) {
          const userValues = formValue.claimvalue.map((option) => option.value);
          let userlist;
          userlist = userValues.map((str) => '"' + str + '"');
          searchCriteria[formValue.claimfield1] = `[${userlist.join(", ")}]`;
        }
      });
      this.state.formValues.forEach((formValue) => {
        if (
          formValue.claimfield1 === "OverallClaimStatus" &&
          formValue.claimvalue.length > 0
        ) {
          const userValues = formValue.claimvalue.map((option) => option.value);
          let userlist;
          userlist = userValues.map((str) =>  str );
          searchCriteria[formValue.claimfield1] = `[${userlist.join(", ")}]`;
        }
      });
      this.state.formValues.forEach((formValue) => {
        if (
          formValue.claimfield1 === "AgeGrp" &&
          formValue.claimvalue.length > 0
        ) {
          const userValues = formValue.claimvalue.map((option) => option.value);
          let agegrplist;
          agegrplist = userValues.map((str) => '"' + str + '"');
          searchCriteria[formValue.claimfield1] = `[${agegrplist.join(", ")}]`;
        }
      });
      this.state.formValues.forEach((formValue) => {
        if (
          formValue.claimfield1 === "DOIAgeGrp" &&
          formValue.claimvalue.length > 0
        ) {
          const userValues = formValue.claimvalue.map((option) => option.value);
          let doiagegrplist;
          doiagegrplist = userValues.map((str) => '"' + str + '"');
          searchCriteria[formValue.claimfield1] = `[${doiagegrplist.join(
            ", "
          )}]`;
        }
      });
      const searchCriteriaQueryString = Object.keys(searchCriteria)
        .map((key) => `${key}=${searchCriteria[key]}`)
        .join("&");

      if (searchCriteriaQueryString) {
        url += `&${searchCriteriaQueryString}`;
      }
      const res = axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          if (res && !this.state.searchCompleted) {
            toast.success("Searching completed!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } 
          this.setState({ searchtext: 1 });
          this.setState({
            searchdataresponse: res.data.claims,
            spageCount: Math.ceil(res.data.claimcount / this.state.perPage),
            searchclaimdata: res.data.claims,
          });
          this.setState({
            searchCompleted: true,
          });
        });
    } else {
      alert("Select data to search");
    }
  };
  addFormField() {
    this.setState({
      formValues: [
        ...this.state.formValues,
        { claimfield1: "", claimvalue: "" },
      ],
    });
  }
  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }
  getusers = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/users/all?clientId=${this.state.client}`, {
        headers: headers,
      })
      .then((res) => {
        let data = res.data.user;
        // this.setState({ userdetails: res.data.user });

        if (this.state.client) {
          data = data.filter((item) => {
            return item.role === "Team Member";
          });
        } else {
          data = data.filter((item) => {
            return item.role === "Admin" || item.role === "SuperAdmin";
          });
        }
        var data1 = data.map((e) => ({
          value: e.first_name + " " + e.last_name,
          label: e.first_name + " " + e.last_name,
        }));
        var data2 = data.map((e) => ({
          value: e.first_name + " " + e.last_name,
        }));

        this.setState({ users: data, users1: data1 });
      })
      .catch((err) => {});
  };
  resetuser = () => {
    this.setState(
      {
        ids: [],
        count: [],
        userid: [],
        agegrp1: "",
        bulkunallocated: "",
        agegrp2: "",
        agegrp3: "",
        agegrp4: "",
        agegrp5: "",
        status1: "",
        status2: "",
        status3: "",
        status4: "",
        status5: "",
      },
      () => {
        this.getallocatedclaims();
      }
    );
  };
  resetcount = () => {
    this.setState(
      {
        perPage: 10,
      },
      () => {
        this.getallocatedclaims();
        // this.filtersearch();
      }
    );
  };
  reset = () => {
    if(this.state.searchtext === 1){
      toast.info("All values displayed now!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    if (this.typeaheadRef.current) {
      this.typeaheadRef.current.clear(); // Use the ref to clear the Typeahead input
    }
    this.setState(
      {
        searchCompleted:false,
        formValues: [],
        claimfield: "",
        claimids: [],
        claimvalue: "",
        username: "",
        doioptionstatus: [],
        doioptionstatus3: [],
        userstatus: [],
        user: "",
        ar: [],
        claimtype: "",
        status: [],
        agegrp: "",
        payervalue: [],
        cptvalue: [],
        searchdataresponse: [],
        searchclaimdata: [],
        searchtext: "",
        currentpage: 0,
        currentpagesearch: 0,
        checkedAll: false,
        sperPage: 10,
        doiagegrp: "",
        optionSelected: [],
        doioptionSelected: [],
        userstatusselect: [],
        claimstatusclaim: [],
        typeselect: [],
        searchfilterdata: [],
        searchfiltertable: false,
      },
      () => {
        this.addFormField()
        this.getallocatedclaims()
      }
    );
  };
  getsorting = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      if (this.state.Location) {
        url = `${ip}/allocation/sorting?clientId=${this.state.client}&pageno=${this.state.currentpagesort}&bucketname=allocated&sorting=${this.state.sort}&field=${this.state.fieldsort}&count=${this.state.perPagesort}&Location=${this.state.Location}`;
      } else {
        url = `${ip}/allocation/sorting?clientId=${this.state.client}&pageno=${this.state.currentpagesort}&bucketname=allocated&sorting=${this.state.sort}&field=${this.state.fieldsort}&count=${this.state.perPagesort}`;
      }
    } else {
      url = `${ip}/allocation/sorting?pageno=${this.state.currentpagesort}&bucketname=allocated&sorting=${this.state.sort}&field=${this.state.fieldsort}&count=${this.state.perPagesort}`;
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          sortdata: res.data.subcategoryclaim,
          pageCountsort: Math.ceil(res.data.claim / this.state.perPagesort),
        });
      })
      .catch((err) => {});
  };
  handlePagesort = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagesort;
    this.setState(
      {
        currentpagesort: selectedPage,
        offsetsort: offset,
      },
      () => {
        this.getsorting();
      }
    );
  };
  getsortingfilter = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      if (this.state.Location) {
        url = `${ip}/allocation/allocated/advancedmultiplefilter?pageno=${this.state.currentpagesort}&count=${this.state.perPagesort}&clientId=${this.state.client}&${this.state.query}&sorting=${this.state.sort}&field=${this.state.fieldsort}&Location=${this.state.Location}`;
      } else {
        url = `${ip}/allocation/allocated/advancedmultiplefilter?pageno=${this.state.currentpagesort}&count=${this.state.perPagesort}&clientId=${this.state.client}&${this.state.query}&sorting=${this.state.sort}&field=${this.state.fieldsort}`;
      }
    } else {
      url = `${ip}/allocation/allocated/advancedmultiplefilter?pageno=${this.state.currentpagesort}&count=${this.state.perPagesort}&${this.state.query}&sorting=${this.state.sort}&field=${this.state.fieldsort}`;
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        this.setState({
          searchfilterdata: res.data.claims,
          pageCountsort: Math.ceil(
            res.data.claimcount / this.state.perPagesort
          ),
        });
      })

      .catch((err) => {});
  };
  handlePagesortfilter = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagesort;

    this.setState(
      {
        currentpagesort: selectedPage,
        offsetsort: offset,
      },
      () => {
        this.getsortingfilter();
      }
    );
  };
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getallocatedclaims();
    this.getcpt();
    this.getclaims();
    this.getpayercategory();
    this.getClaimStatus();
    this.getclients();
    this.getusers();
    this.getLocation();
    const options = [
      { value: "Location", label: "Location" },
      { value: "ClaimID", label: "Claim Id" },
      { value: "patientAccountNumber", label: "Account Number" },
      { value: "MRN", label: "MRN" },
      { value: "PatientFirstName", label: "FirstName" },
      { value: "PatientLastName", label: "LastName" },
      { value: "Physician", label: "Physician" },
      { value: "Age", label: "Age" },
      { value: "PrimaryPolicyNo", label: "Policy No" },
      { value: "PatientDOB", label: "DOB" },
      { value: "PlanCode", label: "Plan Code" },
      { value: "DateOfService", label: "DOS" },
      { value: "InsuranceName", label: "Insurance Name" },
      { value: "ClaimStatus", label: "Claim Status" },
      { value: "type", label: "Type" },
      { value: "Claimunder", label: "User" },
      { value: "OverallClaimStatus", label: "User Status" },
      { value: "AgeGrp", label: "AgeGroup" },
      { value: "DOIAgeGrp", label: "DoiAgeGroup" },
    ];
    this.setState({
      optionsarray: options,
    });
    let storedSearchAllocInput = localStorage.getItem("storedClaimAllocSearch");
    let storedSearchvalue = localStorage.getItem("storedAllocSearchvalue");
    let val = JSON.parse(storedSearchvalue)
    if (val === 1) {
        let storedSearchAllocInput1 = JSON.parse(storedSearchAllocInput);
        this.setState({
        formValues: storedSearchAllocInput1,
          }, () => {
            this.filtersearch();
          })
    }
    else{
      this.getclaims()
    }
  }
componentWillUnmount() {
    let allocinput = JSON.stringify(this.state.formValues);
    localStorage.setItem("storedClaimAllocSearch", allocinput);
    let allocinput1 = JSON.stringify(this.state.searchtext);
    localStorage.setItem("storedAllocSearchvalue", allocinput1);
  }
  addsinglecheckboxid = (data, check) => {
    if (check) {
      this.setState({ ids: [...this.state.ids, data] }, () => this.toggle2());
    } else {
      let arr = this.state.ids.slice();
      let val = arr.filter((id) => {
        return id != data;
      });
      this.setState(
        { ids: val }
        //   , () => {
        //   this.toggle2();
        // }
      );
    }
  };
  allclaimsunallocate = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      clientId: this.state.client,
      logdetails: {
        admin: this.state.UserAllocate,
      },
    };
    const res = axios
      .put(`${ip}/allocation/bulkunallocate`, value, {
        headers: headers,
      })
      .then((res) => {
        alert("Bulk Unallocated Successfully");
        this.getallocatedclaims();
      })
      .catch((err) => {});
  };
  checkBoxAll = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.alldataresponse.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle2();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };

  validate = () => {
    let hasErr = "true";
    let selectuserErr = "";
    if (this.state.selecteduser.length === 0) {
      selectuserErr = "This field is required";
      this.setState({ selectuserErr });
      hasErr = false;
    }
    return hasErr;
  };
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: 600,
        fontFamily: "Montserrat, sans-serif", // Add your chosen font family here
        fontSize: 13,
      },
      [`&.${tableCellClasses.body}`]: {
        fontFamily: "Poppins, sans-serif", // Add your chosen font family here
        // fontFamily: 'Josefin Sans, sans-serif', // Add your chosen font family here
        fontSize: 13,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    const checkboxstyle = {
      marginTop: "15px",
    };
    return (
      <div>
      <Newsidebar name="Allocated Claims" />
      <div className="align-row">
        <ToastContainer></ToastContainer>
        <div className="align-column">

          <br />
          <div style={{ display: "flex", flexDirection: "row" , marginLeft:"3%"}}>
            {this.state.role === "SuperAdmin" ? (
              <ButtonDropdown
                isOpen={this.state.opendropdown}
                toggle={this.toggledropdown}
                style={{ width: "12%", marginLeft: "4%", position: "inherit" }}
              >
                <DropdownToggle caret>
                  {this.state.clientname ? this.state.clientname : "All"}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() =>
                      this.setState(
                        { client: "", clientname: "" },
                        () => this.getallocatedclaims(),
                        this.getLocation()
                      )
                    }
                  >
                    All
                  </DropdownItem>
                  {this.state.clientslist.map((e) => {
                    return (
                      <DropdownItem
                        id={e.clientId}
                        key={e.clientId}
                        onClick={(e) =>
                          this.setState(
                            {
                              client: e.target.id,
                              clientname: e.target.innerText,
                            },
                            () => this.getallocatedclaims(),
                            this.getLocation()
                          )
                        }
                      >
                        {e.client}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </ButtonDropdown>
            ) : null}
            {this.state.client && (
              // <Input
              //   type="select"
              //   className="form-control form-select"
              //   onChange={(e) =>
              //     this.setState(
              //       {
              //         Location: e.target.value,
              //       },
              //       () => this.getallocatedclaims(),
              //       this.getLocation()
              //     )
              //   }
              //   style={{ width: "12%", marginLeft: "4%", position: "inherit" }}
              // >
              //   <option value="">All Locations</option>
              //   {this.state.locationslist.map((e) => (
              //     <option value={e.Location}>{e.Location}</option>
              //   ))}
              // </Input>
              <Typeahead
                clearButton={true}
                onChange={this.handleChangeLocation}
                options={this.state.locationslist}
                labelKey={(option) => `${option.Location}`}
                placeholder="All Location"
                selected={this.state.selected}
              />
            )}
            <div style={{ display: "flex" }}>
              <div class="select_drop">
                <select
                  style={{ float: "left", marginLeft: "30px", height: "35px" }}
                  value={this.state.perPage}
                  onChange={(e) =>
                    this.setState(
                      {
                        perPage: e.target.value,
                        currentpage: 0,
                        currentpagesearch: 0,
                      },
                      () => {
                        this.getallocatedclaims();
                        // this.filtersearch();
                      }
                    )
                  }
                >
                  <option value="select" hidden selected>
                    Select page count
                  </option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                </select>
              </div>

              <div>
                <Button
                  style={{
                    border: "none",
                    color: "white",
                    background: "white",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    this.resetcount();
                  }}
                >
                  <IoIosRefresh
                    style={{
                      color: "blue",
                      fontSize: "23px",
                    }}
                  ></IoIosRefresh>
                </Button>
              </div>
            </div>
            <div>
              <button
                style={{
                  boxShadow: "inset 0px 1px 0px 0px #E184F3",
                  background: "black",
                  backgroundColor: "black",
                  borderRadius: "6px",
                  border: "1px solid black",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "#FFFFFF",
                  fontSize: "15px",
                  fontWeight: "bold",
                  padding: "6px 24px",
                  textDecoration: "none",
                  textShadow: "0px 1px 0px #9B14B3",
                  float: "right",
                }}
                onClick={this.getdownloadfile}
              >
                Generate Report
              </button>
            </div>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <div>
              <button
                style={{
                  boxShadow: "inset 0px 1px 0px 0px #E184F3",
                  background: "black",
                  backgroundColor: "black",
                  borderRadius: "6px",
                  border: "1px solid black",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "#FFFFFF",
                  fontSize: "15px",
                  fontWeight: "bold",
                  padding: "6px 24px",
                  textDecoration: "none",
                  textShadow: "0px 1px 0px #9B14B3",
                  float: "right",
                }}
                onClick={this.toggle3}
              >
                Bulk UnAllocate
              </button>
            </div>
          </div>
          <Form
            style={{
              border: "3px solid #FBFBFB",
              borderRadius: "16px",
              padding: "4px",
              background: "#FBFBFB",
            }}
          >
            <div>
              <div style={{ marginLeft: "23%" }}>
                <Button
                  style={{
                    width: "3%",
                    height: "59%",
                    cursor: "pointer",
                    borderRadius: "4px",
                    color: "white",
                    padding: "4px",
                    background: "#041c3c",
                    fontWeight: "bold",
                  }}
                  onClick={(e) => {
                    this.filtersearch();
                  }}
                >
                  <AiOutlineSearch />
                </Button>
                <Button
                  style={{
                    width: "3%",
                    height: "59%",
                    cursor: "pointer",
                    borderRadius: "4px",
                    color: "white",
                    padding: "4px",
                    background: "#00853E",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    this.addFormField();
                  }}
                >
                  <AiOutlinePlusCircle />
                </Button>
                <Button
                  style={{
                    height: "59%",
                    border: "none",
                    color: "white",
                    background: "white",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    this.reset();
                  }}
                >
                  <IoIosRefresh
                    style={{
                      color: "blue",
                      fontSize: "25px",
                    }}
                  ></IoIosRefresh>
                </Button>
              </div>
              {this.state.formValues.map((formValue, index) => (
                <div key={index} className="search-field">
                  <Row>
                    <Col md="4" className="d-flex justify-content-end">
                       <Typeahead
                      ref={this.typeaheadRef}
                      // clearButton={true}
                      id={`typeahead${index}`}
                      style={{ width: "70%" }}
                      options={this.state.optionsarray}
                      value={formValue.claimfield1}
                      selected={this.state.optionsarray.filter(option => option.value === formValue.claimfield1)}
                      labelKey={(option) => `${option.label}`}
                      placeholder="What are you looking for?..."
                      onChange={(selected) => {
                        const updatedFormValues = [...this.state.formValues];
                        if (selected && selected.length > 0 && selected[0] && selected[0].value) {
                          updatedFormValues[index].claimfield1 = selected[0].value;
                        } else {
                          updatedFormValues[index].claimfield1 = "Default";
                        }
                        this.setState({ formValues: updatedFormValues });
                      }}
                    />
                    </Col>
                    <Col md="4">
                      {formValue.claimfield1 === "ClaimStatus" ? (
                        <ReactSelect
                                      options={this.state.statuslist}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      components={{
                                        Option,
                                      }}
                                      onChange={(selected) => {
                                        const updatedFormValues = [
                                          ...this.state.formValues,
                                        ];
                                        updatedFormValues[index].claimvalue =
                                          selected || [];
                                        this.setState({ formValues: updatedFormValues });
                                      }}
                                      allowSelectAll={true}
                                      value={formValue.claimvalue}
                                    />
                        
                      ) : formValue.claimfield1 === "type" ? (
                        <ReactSelect
                          style={{ width: "80%" }}
                          options={type}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={(selected) => {
                            const updatedFormValues = [
                              ...this.state.formValues,
                            ];
                            updatedFormValues[index].claimvalue =
                              selected || [];
                            this.setState({ formValues: updatedFormValues });
                          }}
                          allowSelectAll={true}
                          value={formValue.claimvalue}
                        />
                      ) : formValue.claimfield1 === "Claimunder" ? (
                        <ReactSelect
                          options={this.state.users1}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={(selected) => {
                            const updatedFormValues = [
                              ...this.state.formValues,
                            ];
                            updatedFormValues[index].claimvalue =
                              selected || [];
                            this.setState({ formValues: updatedFormValues });
                          }}
                          allowSelectAll={true}
                          value={formValue.claimvalue}
                        />
                      ) : formValue.claimfield1 === "OverallClaimStatus" ? (
                        <ReactSelect
                          options={userstatus}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={(selected) => {
                            const updatedFormValues = [
                              ...this.state.formValues,
                            ];
                            updatedFormValues[index].claimvalue =
                              selected || [];
                            this.setState({ formValues: updatedFormValues });
                          }}
                          allowSelectAll={true}
                          value={formValue.claimvalue}
                        />
                      ) : formValue.claimfield1 === "AgeGrp" ? (
                        <ReactSelect
                          options={ageOptions}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={(selected) => {
                            const updatedFormValues = [
                              ...this.state.formValues,
                            ];
                            updatedFormValues[index].claimvalue =
                              selected || [];
                            this.setState({ formValues: updatedFormValues });
                          }}
                          allowSelectAll={true}
                          value={formValue.claimvalue}
                        />
                      ) : formValue.claimfield1 === "DOIAgeGrp" ? (
                        <ReactSelect
                          options={ageOptions}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={(selected) => {
                            const updatedFormValues = [
                              ...this.state.formValues,
                            ];
                            updatedFormValues[index].claimvalue =
                              selected || [];
                            this.setState({ formValues: updatedFormValues });
                          }}
                          allowSelectAll={true}
                          value={formValue.claimvalue}
                        />
                      ) : formValue.claimfield1 === "ClaimID" ? (
                        <Input
                          type="text"
                          value={formValue.claimvalue}
                          placeholder="Enter Claim Id"
                          onChange={(e) => {
                            const updatedFormValues = [
                              ...this.state.formValues,
                            ];
                            updatedFormValues[index].claimvalue =
                              e.target.value;
                            this.setState({
                              formValues: updatedFormValues,
                            });
                          }}
                        />
                      ) : (
                        <Input
                          className="header__searchInput"
                          type="text"
                          placeholder="Enter text"
                          value={formValue.claimvalue}
                          onChange={(e) => {
                            const updatedFormValues = [
                              ...this.state.formValues,
                            ];
                            updatedFormValues[index].claimvalue =
                              e.target.value;
                            this.setState({ formValues: updatedFormValues });
                          }}
                        />
                      )}
                    </Col>
                    <Col style={{ marginRight: "23%" }}>
                      <div className="button-group">
                        {index > 0 && (
                          <Button
                            className="btn btn-danger float-right labelfontsize"
                            onClick={() => {
                              this.removeFormFields(index);
                            }}
                          >
                            <AiOutlineCloseCircle />
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <br />
                </div>
              ))}
            </div>
          </Form>
          <div style={{ height: "100%", width: "100%" }}>
            {this.state.fetching ? (
              <div>
                <p
                  style={{ fontSize: "23px", marginTop: "10px", color: "#000" }}
                >
                  <span>Loading...</span>
                  <span>Please Wait...</span>
                </p>
                <div class="boxes body__loader dribbble">
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            ) : this.state.claimsdata.length === 0 ? (
              <div class="bot-ui">
                <div class="bot-head">
                  <div class="eyes-container">
                    <div class="to-left eye"></div>
                    <div class="to-right eye"></div>
                  </div>
                </div>
                <div class="bot-body">
                  <div class="analyzer"></div>
                </div>
                <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                  No Data Found
                </p>
              </div>
            ) : (
              <div>
                {this.state.searchtext === "" ? (
                  <div>
                  {this.state.sorttable === true ? (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCountsort}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePagesort}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpagesort}
                      />
                    </div>
                  ) : (
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={this.state.currentpage}
                      />
                    </div>
                  )}
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 700 }}
                        className="table__card"
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            {this.state.role === "Admin" && (
                              <StyledTableCell>
                                <input
                                  type="checkbox"
                                  checked={this.state.checkedAll}
                                  onChange={this.checkBoxAll}
                                />
                              </StyledTableCell>
                            )}
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              ACTION{" "}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              CLAIM ID{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "ClaimID",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "ClaimID",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              LOCATION{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "Location",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "Location",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              ACCOUNT NO{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "patientAccountNumber",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "patientAccountNumber",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              MRN{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "MRN",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "MRN",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              DOS{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "DOS",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "DOS",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              PATIENT NAME{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "PatientFirstName",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "PatientFirstName",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              PATIENT DOB{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "PatientDOB",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "PatientDOB",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              PLAN CODE{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "PlanCode",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "PlanCode",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              INSURANCE NAME{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "InsuranceName",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "InsuranceName",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              POLICY NO{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "PrimaryPolicyNo",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "PrimaryPolicyNo",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              PHYSICIAN NAME{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "Physician",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "Physician",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              AGE{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "Age",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "Age",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              AGE GRP{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "AgeGrp",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "AgeGrp",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              DOI AGE
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "DOIAge",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "DOIAge",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              DOI AGEGRP
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "DOIAgeGrp",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "DOIAgeGrp",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              TYPE{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "type",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "type",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              ALLOCATED TO{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "Claimunder",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "Claimunder",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              USER STATUS{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "OverallClaimStatus",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "OverallClaimStatus",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              CLAIM STATUS{" "}
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: false,
                                        sort: "ascending",
                                        fieldsort: "ClaimStatus",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState(
                                      {
                                        openicon: true,
                                        sort: "descending",
                                        fieldsort: "ClaimStatus",
                                        sorttable: true,
                                      },
                                      () => this.getsorting()
                                    )
                                  }
                                />
                              )}
                            </StyledTableCell>
                            {this.state.role === "Admin" && (
                              <StyledTableCell>UNALLOCATE</StyledTableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        {this.state.sorttable === true ? (
                          <TableBody style={{ fontSize: "15px" }}>
                            {this.state.sortdata &&
                              this.state.sortdata.map((data, index) => (
                                <TableRow key={data._id}>
                                  {this.state.role === "Admin" &&
                                    (this.state.checkedAll === true ? (
                                      <div style={checkboxstyle}>
                                        <input
                                          type="checkbox"
                                          checked={this.state.checkedAll}
                                        />
                                      </div>
                                    ) : (
                                      <div style={checkboxstyle}>
                                        <Checkbox
                                          handleidpush={
                                            this.addsinglecheckboxid
                                          }
                                          number={data._id}
                                          key={index}
                                          onClick={() =>
                                            this.setState({
                                              ClaiMid: data[index].ClaimID,
                                            })
                                          }
                                        />
                                      </div>
                                    ))}
                                  <StyledTableCell
                                    onClick={() => (
                                      (
                                        <ViewClaim
                                          value={
                                            sessionStorage.setItem(
                                              "Module",
                                              "Allocated"
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "ChartID",
                                              data.ChartID
                                            ) ||
                                            sessionStorage.setItem(
                                              "MRN",
                                              data.MRN
                                            ) ||
                                            sessionStorage.setItem(
                                              "Patient",
                                              data.Patient
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientDOB",
                                              data.PatientDOB
                                            ) ||
                                            sessionStorage.setItem(
                                              "Physician",
                                              data.Physician
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "Pripayer",
                                              data.Pripayer
                                            ) ||
                                            sessionStorage.setItem(
                                              "DateOfService",
                                              data.DateOfService
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDBID",
                                              data._id
                                            ) ||
                                            sessionStorage.setItem(
                                              "AllocatedTo",
                                              data.Claimunder
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <Viewmoreeligibility
                                          value={
                                            sessionStorage.setItem(
                                              "modulename",
                                              "Allocated"
                                            ) ||
                                            sessionStorage.setItem(
                                              "Eligibilityclaimid",
                                              data.ClaimID
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <SimpleMenu
                                          value={
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientFirstName",
                                              data.PatientFirstName
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientLastName",
                                              data.PatientLastName
                                            )
                                          }
                                        ></SimpleMenu>
                                      )
                                    )}
                                  >
                                    {" "}
                                    <SimpleMenu></SimpleMenu>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.ClaimID}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.Location}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.patientAccountNumber
                                      ? data.patientAccountNumber
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>{data.MRN}</StyledTableCell>
                                  <StyledTableCell>
                                    {data.DateOfService
                                      ? data.DateOfService
                                      : "-"}
                                  </StyledTableCell>

                                  <StyledTableCell>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                  {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.PlanCode ? data.PlanCode : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.InsuranceName
                                      ? data.InsuranceName
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.PrimaryPolicyNo}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.Physician}
                                  </StyledTableCell>

                                  <StyledTableCell>{data.Age}</StyledTableCell>
                                  <StyledTableCell>
                                    {data.AgeGrp}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.DOIAge}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.DOIAgeGrp}
                                  </StyledTableCell>
                                  <StyledTableCell>{data.type}</StyledTableCell>
                                  <StyledTableCell>
                                    {data.Claimunder}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.OverallClaimStatus}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.ClaimStatus}
                                  </StyledTableCell>
                                  {this.state.role === "Admin" && (
                                    <StyledTableCell>
                                      <Button
                                        onClick={() =>
                                          this.setState(
                                            {
                                              unallocateid: data._id,
                                              newuser:
                                                data.PatientFirstName +
                                                data.PatientLastName,
                                              // olduser : data.Claimunder,
                                              // ClaiMid : data.ClaimID,
                                            },
                                            () => {
                                              this.toggle1();
                                            }
                                          )
                                        }
                                      >
                                        Unallocate
                                      </Button>
                                    </StyledTableCell>
                                  )}
                                </TableRow>
                              ))}
                          </TableBody>
                        ) : (
                          <TableBody style={{ fontSize: "15px" }}>
                            {this.state.claimsdata &&
                              this.state.claimsdata.map((data, index) => (
                                <TableRow key={data._id}>
                                  {this.state.role === "Admin" &&
                                    (this.state.checkedAll === true ? (
                                      <div style={checkboxstyle}>
                                        <input
                                          type="checkbox"
                                          checked={this.state.checkedAll}
                                        />
                                      </div>
                                    ) : (
                                      <div style={checkboxstyle}>
                                        <Checkbox
                                          handleidpush={
                                            this.addsinglecheckboxid
                                          }
                                          number={data._id}
                                          key={index}
                                          onClick={() => (
                                            this.setState({
                                              ClaiMid: data[index].ClaimID,
                                            }),
                                            console.log(
                                              "example",
                                              data[index].ClaimID
                                            )
                                          )}
                                        />
                                      </div>
                                    ))}
                                  <StyledTableCell
                                    onClick={() => (
                                      (
                                        <ViewClaim
                                          value={
                                            sessionStorage.setItem(
                                              "Module",
                                              "Allocated"
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "ChartID",
                                              data.ChartID
                                            ) ||
                                            sessionStorage.setItem(
                                              "MRN",
                                              data.MRN
                                            ) ||
                                            sessionStorage.setItem(
                                              "Patient",
                                              data.Patient
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientDOB",
                                              data.PatientDOB
                                            ) ||
                                            sessionStorage.setItem(
                                              "Physician",
                                              data.Physician
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "Pripayer",
                                              data.Pripayer
                                            ) ||
                                            sessionStorage.setItem(
                                              "DateOfService",
                                              data.DateOfService
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDate",
                                              data.ClaimDate
                                            ) ||
                                            sessionStorage.setItem(
                                              "ClaimDBID",
                                              data._id
                                            ) ||
                                            sessionStorage.setItem(
                                              "AllocatedTo",
                                              data.Claimunder
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <Viewmoreeligibility
                                          value={
                                            sessionStorage.setItem(
                                              "modulename",
                                              "Allocated"
                                            ) ||
                                            sessionStorage.setItem(
                                              "Eligibilityclaimid",
                                              data.ClaimID
                                            )
                                          }
                                        />
                                      ),
                                      (
                                        <SimpleMenu
                                          value={
                                            sessionStorage.setItem(
                                              "ClaimID",
                                              data.ClaimID
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientFirstName",
                                              data.PatientFirstName
                                            ) ||
                                            sessionStorage.setItem(
                                              "PatientLastName",
                                              data.PatientLastName
                                            )
                                          }
                                        ></SimpleMenu>
                                      )
                                    )}
                                  >
                                    {" "}
                                    <SimpleMenu></SimpleMenu>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.ClaimID}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.Location}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.patientAccountNumber
                                      ? data.patientAccountNumber
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>{data.MRN}</StyledTableCell>
                                  <StyledTableCell>
                                    {data.DateOfService
                                      ? data.DateOfService
                                      : "-"}
                                    {/* {data.DateOfService
                                    ? moment(
                                        data.DateOfService.substring(0, 10)
                                      ).format("MM/DD/YYYY")
                                    : "-"} */}
                                  </StyledTableCell>

                                  <StyledTableCell>
                                    {data.PatientFirstName}&nbsp;
                                    {data.PatientLastName}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                  {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.PlanCode ? data.PlanCode : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.InsuranceName
                                      ? data.InsuranceName
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.PrimaryPolicyNo}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.Physician}
                                  </StyledTableCell>
                                  <StyledTableCell>{data.Age}</StyledTableCell>
                                  <StyledTableCell>
                                    {data.AgeGrp}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.DOIAge}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.DOIAgeGrp}
                                  </StyledTableCell>
                                  <StyledTableCell>{data.type}</StyledTableCell>
                                  <StyledTableCell>
                                    {data.Claimunder}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.OverallClaimStatus}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {data.ClaimStatus}
                                  </StyledTableCell>
                                  {this.state.role === "Admin" && (
                                    <StyledTableCell>
                                      <Button
                                        onClick={() =>
                                          this.setState(
                                            { unallocateid: data._id },
                                            () => {
                                              this.toggle1();
                                            }
                                          )
                                        }
                                      >
                                        Unallocate
                                      </Button>
                                    </StyledTableCell>
                                  )}
                                </TableRow>
                              ))}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                    {this.state.sorttable === true ? (
                      <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCountsort}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePagesort}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.currentpagesort}
                        />
                      </div>
                    ) : (
                      <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                        <ReactPaginate
                          previousLabel={<IoIosArrowBack />}
                          nextLabel={<IoIosArrowForward />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.currentpage}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {this.state.searchdataresponse.length === 0 ? (
                      <div>
                        <p>
                          <img src={searchresult} />
                        </p>
                      </div>
                    ) : (
                      <div>
                      {this.state.searchfiltertable === true ? (
                        <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                          <ReactPaginate
                            forcePage={this.state.currentpagesort}
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pageCountsort}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePagesortfilter}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      ) : (
                        <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                          <ReactPaginate
                            forcePage={this.state.currentpagesearch}
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.spageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick1}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      )}
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                            style={{ padding: "3%" }}
                          >
                            <TableHead>
                              <TableRow>
                                {this.state.role === "Admin" && (
                                  <StyledTableCell>
                                    <input
                                      type="checkbox"
                                      checked={this.state.checkedAll}
                                      onChange={this.checkboxsearchall}
                                    />
                                  </StyledTableCell>
                                )}
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  ACTION{" "}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  CLAIM ID{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "ClaimID",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "ClaimID",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  LOCATION{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "Location",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "Location",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  ACCOUNT NO{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "patientAccountNumber",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "patientAccountNumber",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  MRN
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "MRN",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "MRN",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  DOS{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "DOS",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "DOS",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  PATIENT NAME{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "PatientFirstName",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "PatientFirstName",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  PATIENT DOB{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "PatientDOB",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "PatientDOB",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  PLAN CODE{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "PlanCode",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "PlanCode",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  INSURANCE NAME{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "InsuranceName",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "InsuranceName",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  POLICY NO{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "PrimaryPolicyNo",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "PrimaryPolicyNo",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  PHYSICIAN NAME{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "Physician",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "Physician",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  AGE{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "Age",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "Age",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  AGE GRP{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "AgeGrp",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "AgeGrp",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  DOI AGE
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "DOIAge",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "DOIAge",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  DOI AGEGRP
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "DOIAgeGrp",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "DOIAgeGrp",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  TYPE{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "type",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "type",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  Allocated To{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "Claimunder",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "Claimunder",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  USER STATUS{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "OverallClaimStatus",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "OverallClaimStatus",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  CLAIM STATUS{" "}
                                  {this.state.openicon ? (
                                    <BiUpArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: false,
                                            sort: "ascending",
                                            fieldsort: "ClaimStatus",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  ) : (
                                    <BiDownArrowAlt
                                      onClick={() =>
                                        this.setState(
                                          {
                                            openicon: true,
                                            sort: "descending",
                                            fieldsort: "ClaimStatus",
                                            searchfiltertable: true,
                                          },
                                          () => this.getsortingfilter()
                                        )
                                      }
                                    />
                                  )}
                                </StyledTableCell>
                                {this.state.role === "Admin" && (
                                  <StyledTableCell>Unallocate</StyledTableCell>
                                )}
                              </TableRow>
                            </TableHead>
                            {this.state.searchfiltertable === true ? (
                              <TableBody style={{ fontSize: "15px" }}>
                                {this.state.searchfilterdata &&
                                  this.state.searchfilterdata.map(
                                    (data, index) => (
                                      <TableRow key={data._id}>
                                        {this.state.role === "Admin" &&
                                          (this.state.checkedAll === true ? (
                                            <div style={checkboxstyle}>
                                              <input
                                                type="checkbox"
                                                checked={this.state.checkedAll}
                                              />
                                            </div>
                                          ) : (
                                            <div style={checkboxstyle}>
                                              <Checkbox
                                                handleidpush={
                                                  this.addsinglecheckboxid
                                                }
                                                number={data._id}
                                                key={index}
                                                onClick={() => (
                                                  this.setState({
                                                    ClaiMid:
                                                      data[index].ClaimID,
                                                  }),
                                                  console.log(
                                                    "example",
                                                    data[index].ClaimID
                                                  )
                                                )}
                                              />
                                            </div>
                                          ))}
                                        <StyledTableCell
                                          onClick={() => (
                                            (
                                              <ViewClaim
                                                value={
                                                  sessionStorage.setItem(
                                                    "Module",
                                                    "Allocated"
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "ClaimID",
                                                    data.ClaimID
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "ChartID",
                                                    data.ChartID
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "MRN",
                                                    data.MRN
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "Patient",
                                                    data.Patient
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "PatientDOB",
                                                    data.PatientDOB
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "Physician",
                                                    data.Physician
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "ClaimDate",
                                                    data.ClaimDate
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "Pripayer",
                                                    data.Pripayer
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "DateOfService",
                                                    data.DateOfService
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "ClaimDate",
                                                    data.ClaimDate
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "ClaimDBID",
                                                    data._id
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "AllocatedTo",
                                                    data.Claimunder
                                                  )
                                                }
                                              />
                                            ),
                                            (
                                              <Viewmoreeligibility
                                                value={
                                                  sessionStorage.setItem(
                                                    "modulename",
                                                    "Allocated"
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "Eligibilityclaimid",
                                                    data.ClaimID
                                                  )
                                                }
                                              />
                                            ),
                                            (
                                              <SimpleMenu
                                                value={
                                                  sessionStorage.setItem(
                                                    "ClaimID",
                                                    data.ClaimID
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "PatientFirstName",
                                                    data.PatientFirstName
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "PatientLastName",
                                                    data.PatientLastName
                                                  )
                                                }
                                              ></SimpleMenu>
                                            )
                                          )}
                                        >
                                          {" "}
                                          <SimpleMenu></SimpleMenu>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.ClaimID}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.Location}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.patientAccountNumber
                                            ? data.patientAccountNumber
                                            : "-"}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.MRN}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.DateOfService
                                            ? data.DateOfService
                                            : "-"}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                          {data.PatientFirstName}&nbsp;
                                          {data.PatientLastName}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                        {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.PlanCode ? data.PlanCode : "-"}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.InsuranceName
                                            ? data.InsuranceName
                                            : "-"}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.PrimaryPolicyNo}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.Physician}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                          {data.Age}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.AgeGrp}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.DOIAge}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.DOIAgeGrp}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.type}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.Claimunder}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.OverallClaimStatus}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.ClaimStatus}
                                        </StyledTableCell>
                                        {this.state.role === "Admin" && (
                                          <StyledTableCell>
                                            <Button
                                              onClick={() =>
                                                this.setState(
                                                  { unallocateid: data._id },
                                                  () => {
                                                    this.toggle1();
                                                  }
                                                )
                                              }
                                            >
                                              Unallocate
                                            </Button>
                                          </StyledTableCell>
                                        )}
                                      </TableRow>
                                    )
                                  )}
                              </TableBody>
                            ) : (
                              <TableBody style={{ fontSize: "15px" }}>
                                {this.state.searchclaimdata &&
                                  this.state.searchclaimdata.map(
                                    (data, index) => (
                                      <TableRow key={data._id}>
                                        {this.state.role === "Admin" &&
                                          (this.state.checkedAll === true ? (
                                            <div style={checkboxstyle}>
                                              <input
                                                type="checkbox"
                                                checked={this.state.checkedAll}
                                              />
                                            </div>
                                          ) : (
                                            <div style={checkboxstyle}>
                                              <Checkbox
                                                handleidpush={
                                                  this.addsinglecheckboxid
                                                }
                                                number={data._id}
                                                key={index}
                                                onClick={() => (
                                                  this.setState({
                                                    ClaiMid:
                                                      data[index].ClaimID,
                                                  }),
                                                  console.log(
                                                    "example",
                                                    data[index].ClaimID
                                                  )
                                                )}
                                              />
                                            </div>
                                          ))}
                                        <StyledTableCell
                                          onClick={() => (
                                            (
                                              <ViewClaim
                                                value={
                                                  sessionStorage.setItem(
                                                    "Module",
                                                    "Allocated"
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "ClaimID",
                                                    data.ClaimID
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "ChartID",
                                                    data.ChartID
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "MRN",
                                                    data.MRN
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "Patient",
                                                    data.Patient
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "PatientDOB",
                                                    data.PatientDOB
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "Physician",
                                                    data.Physician
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "ClaimDate",
                                                    data.ClaimDate
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "Pripayer",
                                                    data.Pripayer
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "DateOfService",
                                                    data.DateOfService
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "ClaimDate",
                                                    data.ClaimDate
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "ClaimDBID",
                                                    data._id
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "AllocatedTo",
                                                    data.Claimunder
                                                  )
                                                }
                                              />
                                            ),
                                            (
                                              <SimpleMenu
                                                value={
                                                  sessionStorage.setItem(
                                                    "modulename",
                                                    "Allocated"
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "ClaimID",
                                                    data.ClaimID
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "PatientFirstName",
                                                    data.PatientFirstName
                                                  ) ||
                                                  sessionStorage.setItem(
                                                    "PatientLastName",
                                                    data.PatientLastName
                                                  )
                                                }
                                              ></SimpleMenu>
                                            )
                                          )}
                                        >
                                          {" "}
                                          <SimpleMenu></SimpleMenu>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.ClaimID}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.Location}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.patientAccountNumber
                                            ? data.patientAccountNumber
                                            : "-"}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.MRN}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.DateOfService
                                            ? data.DateOfService
                                            : "-"}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                          {data.PatientFirstName}&nbsp;
                                          {data.PatientLastName}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                        {data.PatientDOB ? moment(data.PatientDOB).format("MM/DD/YYYY") : "-"}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.PlanCode ? data.PlanCode : "-"}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.InsuranceName
                                            ? data.InsuranceName
                                            : "-"}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.PrimaryPolicyNo}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.Physician}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                          {data.Age}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.AgeGrp}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.DOIAge}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.DOIAgeGrp}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.type}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.Claimunder}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.OverallClaimStatus}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {data.ClaimStatus}
                                        </StyledTableCell>
                                        {this.state.role === "Admin" && (
                                          <StyledTableCell>
                                            <Button
                                              onClick={() =>
                                                this.setState(
                                                  { unallocateid: data._id },
                                                  () => {
                                                    this.toggle1();
                                                  }
                                                )
                                              }
                                            >
                                              Unallocate
                                            </Button>
                                          </StyledTableCell>
                                        )}
                                      </TableRow>
                                    )
                                  )}
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                        {this.state.searchfiltertable === true ? (
                          <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                            <ReactPaginate
                              forcePage={this.state.currentpagesort}
                              previousLabel={<IoIosArrowBack />}
                              nextLabel={<IoIosArrowForward />}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCountsort}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePagesortfilter}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </div>
                        ) : (
                          <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                            <ReactPaginate
                              forcePage={this.state.currentpagesearch}
                              previousLabel={<IoIosArrowBack />}
                              nextLabel={<IoIosArrowForward />}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.spageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick1}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {this.state.ids.length !== 0 && this.state.modal && (
          <Modal
            style={{ width: "50%" }}
            isOpen={this.state.modal}
            onHide={this.toggle}
            size="lg"
            centered
          >
            <ModalHeader toggle={this.toggle}>Reallocate</ModalHeader>
            <ModalBody>
              <Row style={{ margin: "0px" }}>
                <Col>
                  <Label>Agent/Team Member Name</Label>
                </Col>

                {/* <div style={{ fontSize: 16, color: "red" }}>
              {this.state.usersErr}
            </div> */}
              </Row>
              <br />
              <Row>
                <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.status1}
                      onChange={(e) => {
                        this.setState({ status1: e.target.value });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select User
                      </option>
                      {this.state.users.map((data, index) => (
                        <option value={data._id}>
                          {data.first_name + data.last_name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.agegrp1}
                      onChange={(e) => {
                        this.setState({
                          agegrp1: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select the value
                      </option>

                      {this.state.allocatecount.map((data, index) => (
                        <option value={data}>{data}</option>
                      ))}
                    </Input>
                  </Col>
                  <br />
                </Row>

                <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.status2}
                      onChange={(e) => {
                        this.setState({ status2: e.target.value });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select User
                      </option>
                      {this.state.users.map((data, index) => (
                        <option value={data._id}>
                          {data.first_name + data.last_name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.agegrp2}
                      onChange={(e) => {
                        this.setState({
                          agegrp2: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select the Value
                      </option>

                      {this.state.allocatecount.map((data, index) => (
                        <option value={data}>{data}</option>
                      ))}
                    </Input>
                  </Col>
                </Row>

                <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.status3}
                      onChange={(e) => {
                        this.setState({ status3: e.target.value });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select User
                      </option>
                      {this.state.users.map((data, index) => (
                        <option value={data._id}>
                          {data.first_name + data.last_name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.agegrp3}
                      onChange={(e) => {
                        this.setState({
                          agegrp3: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select the Value
                      </option>

                      {this.state.allocatecount.map((data, index) => (
                        <option value={data}>{data}</option>
                      ))}
                    </Input>
                  </Col>
                </Row>

                <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.status4}
                      onChange={(e) => {
                        this.setState({ status4: e.target.value });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select User
                      </option>
                      {this.state.users.map((data, index) => (
                        <option value={data._id}>
                          {data.first_name + data.last_name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.agegrp4}
                      onChange={(e) => {
                        this.setState({
                          agegrp4: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select the Value
                      </option>

                      {this.state.allocatecount.map((data, index) => (
                        <option value={data}>{data}</option>
                      ))}
                    </Input>
                  </Col>
                </Row>

                <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.status5}
                      onChange={(e) => {
                        this.setState({ status5: e.target.value });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select User
                      </option>
                      {this.state.users.map((data, index) => (
                        <option value={data._id}>
                          {data.first_name + data.last_name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      className="form-control form-select"
                      type="select"
                      value={this.state.agegrp5}
                      onChange={(e) => {
                        this.setState({
                          agegrp5: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select the Value
                      </option>

                      {this.state.allocatecount.map((data, index) => (
                        <option value={data}>{data}</option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </Row>
              <br />
            </ModalBody>
            <ModalFooter>
              <Button
                style={{
                  border: "1px solid grey",
                  color: "black",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  this.toggle();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.allocateusers();
                  this.resetuser();
                }}
                style={{ backgroundColor: "grey", color: "#fff" }}
              >
                Reallocate
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <Modal
          style={{ width: "30%" }}
          isOpen={this.state.modalunallocate}
          onHide={this.toggle1}
          size="md"
          centered
        >
          <ModalHeader toggle={this.toggle1}>Unallocate</ModalHeader>
          <ModalBody>Do you want to unallocate this claim..?</ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.toggle1();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.unallocateclaims();
              }}
              style={{ backgroundColor: "grey", color: "#fff" }}
            >
              Unallocate
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          style={{ width: "30%" }}
          isOpen={this.state.modalunallocate1}
          onHide={this.toggle2}
          size="md"
          centered
        >
          <ModalHeader toggle={this.toggle2}>Reallocate/Unallocate</ModalHeader>
          <ModalBody>
            <span
              style={{
                fontSize: "20px",
                color: "#041c3c",
                textAlign: "center",
              }}
            >
              Do you want to..?
            </span>
            <br />
            <div className="multi-button">
              <button
                className="reunalloc"
                onClick={() => {
                  this.toggle();
                }}
              >
                Reallocate
              </button>
              <button
                className="reunalloc"
                onClick={() => {
                  this.bulkunallocatedclaims();
                  this.resetuser();
                }}
              >
                Unallocate
              </button>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.toggle2();
              }}
            >
              Cancel
            </Button>
            {/* <Button
              onClick={() => {
                this.unallocateclaims();
              }}
              style={{ backgroundColor: "grey", color: "#fff" }}
            >
              Unallocate
            </Button> */}
          </ModalFooter>
        </Modal>
        <Modal
          centered
          isOpen={this.state.bulkunallocated}
          toggle={this.toggle3}
        >
          <ModalHeader
            toggle={this.toggle3}
            style={{ fontWeight: "700", fontSize: "30px" }}
          >
            Bulk Unallocated
          </ModalHeader>
          <ModalBody>
            <span
              style={{
                fontSize: "20px",
                color: "#ff0000",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              Do you want to unallocate all claims..?
            </span>
          </ModalBody>
          <ModalFooter>
            <div className="multi-button">
              <Button
                className="reunalloc"
                onClick={() => {
                  this.allclaimsunallocate();
                  this.resetuser();
                }}
              >
                Yes
              </Button>
              <Button
                className="reunalloc"
                onClick={() => {
                  this.toggle3();
                }}
              >
                No
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
      </div>
    );
  }
}

export default Allocated;
