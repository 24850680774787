import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./Components/App";
import reportWebVitals from "./reportWebVitals";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {Provider} from "react-redux";
import {createStore,applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
import rootReducer from "./Redux/UploadFile/root-reducer";


const middleware = [thunk]
const composeEnchancers = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE_ || compose
const store = createStore(rootReducer,composeEnchancers(applyMiddleware(...middleware)))

ReactDOM.render(
  <Provider store={store}>
    <Router>
    <Route path="/" component={App} />
    </Router>
  </Provider>,
  document.getElementById("root")
);
reportWebVitals();
