import { React, Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import "../../Styles/TeamPerformances.css";
import "../../Styles/performances.css";
import { Input } from "reactstrap";
import moment from "moment";
import PerformanceComponent from "./PerfomanceComponent";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

class Performances extends Component {
  constructor(props) {
    super(props);

    this.state = {
      role_type: sessionStorage.getItem("role_type"),
      css_name: "",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      UserId: sessionStorage.getItem("user_id"),
      clientname: sessionStorage.getItem("clientname"),
      touchwise: [],
      name__user: "$",
      date: "Oct 13",
      start: "",
      end: "",
      filterstatus: "custom",
      range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      outcomes__alloc: [],
      outcomes__notwork: [],
      outcomes__total: [],
      outcomes__value: [],
      outcomes__worked: "",
      outcomes__role: [],
      paid: "",
      paidamount: "",
      deniedamount: "",
      denied: "",
      nis: [],
      inprocess: [],
      valueOfInput1: "",
      valueOfInput2: "",
      paidperc: "",
      deniedperc: "",
      allocated: "",
      alloworked: "",
      productivity: "",
      target: "",
      clientid: sessionStorage.getItem("clientid"),
      target: "",
      check: false,
    };
  }

  //to renew accesstoken

  toggleCheck = () => {
    this.setState({ check: !this.state.check });
  };
  valuedate = (option, e) => {
    if(option === "yesterday") {
    this.setState({
      valueOfInput1: e.target.value,
      valueOfInput2: moment().subtract(1, "days").format("MM-DD-YYYY"),
      filterstatus: option,
    });
  }
    else{
      this.setState({
        valueOfInput1: e.target.value,
        valueOfInput2: moment().format("MM-DD-YYYY"),
        filterstatus: option,
      });
    }
  }
  render() {
    const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    return (
      <div>
      <Newsidebar name="Performance" />
      <div className="align-row" style={{ overflowY: "hidden" }}>
        
        <div className={this.state.css_name}>
          <br />

          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Input
              type="select"
              style={{ width: "20%", margin: "2%" }}
              className="form-control form-select"
              onChange={(e) => {
                const index = e.target.selectedIndex;
                const el = e.target.childNodes[index];
                const option = el.getAttribute("title");
                this.valuedate(option,e)
              }}
            >
              <option value="" title="custom">
                Custom
              </option>
              <option value={moment().format("MM-DD-YYYY")} title="">
                Today
              </option>
              <option
                value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                title="yesterday"
              >
                Yesterday
              </option>
              <option
                value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 3 days
              </option>
              <option
                value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 7 days
              </option>
              <option
                value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 30 days
              </option>
              <option
                value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                title=""
              >
                Last 365 days
              </option>
            </Input>
            {this.state.filterstatus === "custom" && (
              <RangePicker
                format="MM-DD-YYYY"
                onChange={(dates, datestring) => {
                  this.setState({
                    valueOfInput1: datestring[0],
                    valueOfInput2: datestring[1],
                  });
                }}
                height="50%"
                width="200%"
                style={{ borderColor: "gray", float: "right", margin: "2%" }}
              />
            )}
          </div>
          <PerformanceComponent
            startdate={this.state.valueOfInput1}
            enddate={this.state.valueOfInput2}
            client={this.state.clientid}
          />
          <br />
        </div>
      </div>
      </div>
    );
  }
}
export default Performances;
