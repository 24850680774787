import React, { Component } from "react";
import axios from "axios";
import "../../Styles/admin.css";
import "../../Styles/Category.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import Tabs, { Tab } from "react-best-tabs";
import Select from "react-dropdown-select";
import ReactPaginate from "react-paginate";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import { styled } from "@mui/material/styles";
import {Table} from "react-bootstrap";
import { Card } from "react-bootstrap";
 
import { ip } from ".././Apis/Api";
import { ToastContainer, toast } from "react-toastify";
import loading from "../../Images/loader.gif";
class AdvBillingProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Advanced_billingprovider: [],
      id: "",
      updateid: null,
      modal: false,
      createmodal: false,
      updatemodal: false,
      deletemodal: false,
      dataforupdate: null,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      physcian: "",
      type: "",
      serviceloc: "",
      payercategory: "",
      billingprovider: "",
      updatephyscian: "",
      updatetype: "",
      updateserviceloc: "",
      updatepayercategory: "",
      updatebillingprovider: "",
      code: "",
      zipcodeErr: "",
      billingmethodErr: "",
      categoryErr: "",
      recvnameErr: "",
      offset: 0,
      currentpage: 0,
      perPage: 10,
      singleid: "",
      payercategories: [],
      payervalue1: [],
      statuslist: [],
      searchClaimId: "",
      searchClaimStatus: [],
      serachCategory: [],
      claim__id: "",
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  toggle2 = () => {
    const { modal2 } = this.state;
    this.setState({ modal2: !modal2 });
  };
  // toogle modal for delete
  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  //toggle modal for update
  toggleupdate = () => {
    this.setState({ updatemodal: !this.state.updatemodal });
  };

  getpayercategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
        let other = {
          category_name: "Others",
        };
        let data = res.data.payer;
        data.push(other);
        this.setState({ payercategories: data }, () => {});
      })

      .catch((err) => {});
  };
  getClaimStatus = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

     axios
      .get(`${ip}/claimstatus/all`, { headers: headers })
      .then((res) => {
        this.setState({
          statuslist: res.data.claimstatus,
        });
      })
      .catch((err) => {});
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getadvancedbillingprovider();
      }
    );
  };

  handleSubmit = async () => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      Physician: this.state.physcian,
      Type: this.state.type,
      Service_Location: this.state.serviceloc,
      Payer_Category: this.state.payercategory,
      Billing_Provider: this.state.billingprovider,
    };
    // eslint-disable-next-line
     await axios
      .post(`${ip}/advancebillingprovider/create`, value, {
        headers: headers,
      })
      .then((res) => {
        //this.getusers();
        if (res) {
          this.getadvancedbillingprovider();
          this.toggle2();
          this.setState({ createmodal: !this.state.createmodal });
          toast.success("Advanced Billing Provider created", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.getadvancedbillingprovider();
          this.toggle2();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  settingupdatestate = function () {
    this.setState(
      {
        singleid: this.state.dataforupdate._id,
        updatephyscian: this.state.dataforupdate.Physician,
        updatetype: this.state.dataforupdate.Type,
        updateserviceloc: this.state.dataforupdate.Service_Location,
        updatepayercategory: this.state.dataforupdate.Payer_Category,
        updatebillingprovider: this.state.dataforupdate.Billing_Provider,
      },
      (res) => {}
    );
  };
  reset__one = (e) => {
    this.setState({
      claim__id: "",
      status: [],
      payervalue1: [],
      searchClaimId: "",
      searchClaimStatus: [],
      serachCategory: [],
    });
  };
  putrequests = async (dataid) => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const data = {
      Physician: this.state.updatephyscian,
      Type: this.state.updatetype,
      Service_Location: this.state.updateserviceloc,
      Payer_Category: this.state.updatepayercategory,
      Billing_Provider: this.state.updatebillingprovider,
    };
    // eslint-disable-next-line
     await axios
      .put(`${ip}/advancebillingprovider/${dataid}`, data, {
        headers: headers,
      })

      .then((res) => {
        if (res) {
          this.toggleupdate();
          this.getadvancedbillingprovider();
          this.setState({ createmodal: !this.state.createmodal });
          toast.info("Advanced Billing Provider updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.getadvancedbillingprovider();
          this.toggleupdate();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  deleterequests = async (DataID) => {
    //api call to delete requests
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/advancebillingprovider/${DataID}`, {
        headers: headers,
      })

      .then((req) => {
        if (req) {
          this.toggledelete();
          this.getadvancedbillingprovider();
          this.setState({ createmodal: !this.state.createmodal });
          toast.error("Advanced Billing Provider deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.getadvancedbillingprovider();
          this.toggledelete();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //getusers

  getadvancedbillingprovider = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
     axios
      .get(`${ip}/advancebillingprovider/all`, {
        headers: headers,
      })
      .then((res) => {
        const slice = res.data.Advanced_billingprovider.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(
            res.data.Advanced_billingprovider.length / this.state.perPage
          ),
          Advanced_billingprovider: slice,
        });
      })
      .catch((err) => {});
  };

  validate = () => {
    let hasErr = "true";
    let zipcodeErr = "";
    let billingmethodErr = "";
    let categoryErr = [];
    let recvnameErr = "";

    if (this.state.zipcode === "") {
      zipcodeErr = "This field is required";
      this.setState({ zipcodeErr });
      hasErr = false;
    }
    if (this.state.billingmethod.length === 0) {
      billingmethodErr = "This field is required";
      this.setState({ billingmethodErr });
      hasErr = false;
    }

    if (this.state.category.length === 0) {
      categoryErr = "This field is required";
      this.setState({ categoryErr });
      hasErr = false;
    }
    if (this.state.recvname.length === 0) {
      recvnameErr = "This field is required";
      this.setState({ recvnameErr });
      hasErr = false;
    }
    return hasErr;
  };

  updatevalidate = () => {
    let hasErr = "true";
    let firstnameErr = "";
    let lastnameErr = "";
    let phnoErr = "";
    let emailErr = "";

    if (this.state.updatefirstname === "") {
      firstnameErr = "This field is required";
      this.setState({ firstnameErr });
      hasErr = false;
    }
    if (this.state.updatelastname === "") {
      lastnameErr = "This field is required";
      this.setState({ lastnameErr });
      hasErr = false;
    }
    if (this.state.updatephno === "") {
      phnoErr = "This field is required";
      this.setState({ phnoErr });
      hasErr = false;
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
        this.state.updatephno
      )
    ) {
      phnoErr = "Invalid phone number";
      this.setState({ phnoErr });
      hasErr = false;
    }
    if (this.state.updateemail === "") {
      emailErr = "This field is required";
      this.setState({ emailErr });
      hasErr = false;
    } else if (
      // eslint-disable-next-line
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
        this.state.updateemail
      )
    ) {
      //var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      emailErr = "Invalid email address";
      this.setState({ emailErr });
      hasErr = false;
    }
    return hasErr;
  };

  reset = () => {
    this.setState({
      physcian: "",
      type: "",
      serviceloc: "",
      payercategory: "",
      billingprovider: "",
    });
  };

  componentDidMount() {
    
    this.getadvancedbillingprovider();
    this.getClaimStatus();
    this.getpayercategory();
  }
  render() {
    return (
      <div>
      <Newsidebar name="Advance Billing provider" />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div className="align-column">
          <ToastContainer />
          <div>
            <button
              className="addNew"
              style={{ width: "fit-content" }}
              onClick={this.toggle2}
            >
              Add Advance Billing Provider
            </button>
          </div>
          <br/>
          <div style={{ marginLeft:"2%" }}>
              <Table bordered className="StandardTable">
                <thead>
                  <tr>
                    <th>
                      Physician
                    </th>
                    <th>
                      Type
                    </th>
                    <th>
                      Service Location
                    </th>
                    <th>
                      Payer Category
                    </th>
                    <th>
                      Billing Provider
                    </th>
                    <th>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody >
                  {this.state.Advanced_billingprovider &&
                    this.state.Advanced_billingprovider.map((data, index) => (
                      <tr key={data._id}>
                        <td component="th" scope="row">
                          {data.Physician}
                        </td>
                        <td>{data.Type}</td>
                        <td>
                          {data.Service_Location}
                        </td>
                        <td>{data.Payer_Category}</td>
                        <td>
                          {data.Billing_Provider}
                        </td>
                        <td>
                          <button
                            className="Edit"
                            onClick={() => {
                              this.setState({ dataforupdate: data }, () => {
                                this.settingupdatestate();
                                this.toggleupdate();
                              });
                            }}
                          >
                            Edit
                          </button>
                          &nbsp;
                          <button
                            className="Delete"
                            onClick={() => {
                              this.setState({ singleid: data._id }, () => {
                                this.toggledelete();
                              });
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                      // ))}
                    ))}
                </tbody>
              </Table>
            <div style={{ marginLeft: "3%", marginTop: "20px" }}>
              <ReactPaginate
                previousLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
          {this.state.modal && (
            <Modal
              style={{ width: "100%", height: "90%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              onExit={this.reset}
              centered
              animation="false"
              size="lg"
            >
              <ModalHeader toggle={this.toggle}>
                Create Insurance Company
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Insurance/Payer Name</Label>
                        <Input
                          type="text"
                          value={this.state.payername}
                          onChange={(e) =>
                            this.setState({
                              payername: e.target.value,
                              firstnameErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.firstnameErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>EDI Payer Id</Label>
                        <Input
                          type="number"
                          value={this.state.payerid}
                          onChange={(e) =>
                            this.setState({
                              payerid: e.target.value,
                              firstnameErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.firstnameErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Type</Label>
                        <Input
                          type="select"
                          value={this.state.type}
                          onChange={(e) =>
                            this.setState({ type: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Contact</Label>
                        <Input
                          type="number"
                          value={this.state.contactno}
                          onChange={(e) =>
                            this.setState({
                              contactno: e.target.value,
                              firstnameErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.firstnameErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Category</Label>
                        <Input
                          type="select"
                          placeholder="Select category"
                          value={this.state.cat1}
                          onChange={(e) =>
                            this.setState({
                              cat1: e.target.value,
                              categoryErr: "",
                            })
                          }
                        >
                          <option>Select...</option>
                          {this.state.categorydata.length > 0 &&
                            this.state.categorydata.map((data, i) => {
                              return (
                                <option key={i} value={data.category_name}>
                                  {data.category_name}
                                </option>
                              );
                            })}
                        </Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.categoryErr}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Workers comp Carrier Code #</Label>
                        <Input
                          type="number"
                          value={this.state.carriercode}
                          onChange={(e) =>
                            this.setState({
                              carriercode: e.target.value,
                              firstnameErr: "",
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.firstnameErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <br />
                <Tabs
                  activeTab="1"
                  className="tabs"
                  ulClassName=""
                  activityClassName="bg-info"
                >
                  <Tab title="Address" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Address 1</Label>
                            <Input
                              type="text"
                              value={this.state.address1}
                              onChange={(e) =>
                                this.setState({
                                  address1: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Address 2</Label>
                            <Input
                              type="text"
                              value={this.state.address2}
                              onChange={(e) =>
                                this.setState({
                                  address2: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>City</Label>
                            <Input
                              type="text"
                              value={this.state.city}
                              onChange={(e) =>
                                this.setState({
                                  city: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>State</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select State"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Country</Label>
                            <Input
                              type="text"
                              value={this.state.country}
                              onChange={(e) =>
                                this.setState({
                                  country: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label className="heading required">Zip Code</Label>
                            <Input
                              type="number"
                              value={this.state.zipcode}
                              onChange={(e) =>
                                this.setState({
                                  zipcode: e.target.value,
                                  zipcodeErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label></Label>
                            <Input type="number" />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.zipcodeErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Telephone 1</Label>
                            <Input
                              type="number"
                              value={this.state.telph1}
                              onChange={(e) =>
                                this.setState({
                                  telph1: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Telephone 2</Label>
                            <Input
                              type="number"
                              value={this.state.telph2}
                              onChange={(e) =>
                                this.setState({
                                  telph2: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Fax</Label>
                            <Input
                              type="number"
                              value={this.state.fax}
                              onChange={(e) =>
                                this.setState({
                                  fax: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Email</Label>
                            <Input
                              type="text"
                              value={this.state.email}
                              onChange={(e) =>
                                this.setState({
                                  email: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab title="EDI/Claim Settings" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label className="heading required">
                              Default Billing Method
                            </Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Default Billing Method"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  billingmethodErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.billingmethodErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col></Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label className="heading required">
                              Receiver Name
                            </Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Receiver Name"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  recvnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.recvnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Claim Filing Indicator</Label>
                            <Select
                              placeholder="Select Claim Filing Indicator"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Program Plan Type</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Program Plan Type"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Incentive Category</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Incentive Category"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Timely Filing Limit (Original Claims)</Label>
                            <Input
                              type="number"
                              value={this.state.timelyfilinglimit1}
                              onChange={(e) =>
                                this.setState({
                                  timelyfilinglimit1: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>
                              Timely Filing Limit (Resubmitted Claims)
                            </Label>
                            <Input
                              type="number"
                              value={this.state.timelyfilinglimit2}
                              onChange={(e) =>
                                this.setState({
                                  timelyfilinglimit2: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>No.of Days</Label>
                            <Input
                              type="number"
                              value={this.state.noofdays}
                              onChange={(e) =>
                                this.setState({
                                  noofdays: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Part A Claims filing Indicator</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Part A Claims filing Indicator"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Second Billing Provider <br /> Taxonomy Code
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.taxonomycode}
                                  onChange={(e) =>
                                    this.setState({
                                      taxonomycode: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <br />
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>Status </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.status}
                                  onChange={(e) =>
                                    this.setState({
                                      status: e.target.value,
                                    })
                                  }
                                >
                                  <option value="Disabled">Disabled</option>
                                  <option value="Enabled">Enabled</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Provider Service
                                  <br />
                                  Location Provider ID{" "}
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.providerid}
                                  onChange={(e) =>
                                    this.setState({
                                      providerid: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Send Default <br /> Referring Provider
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.sendreferringprovider}
                                  onChange={(e) =>
                                    this.setState({
                                      sendreferringprovider: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Billed By EHI For
                                  <br />
                                  RCM
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.billedbyEHI}
                                  onChange={(e) =>
                                    this.setState({
                                      billedbyEHI: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Resend Unapplied
                                  <br />
                                  Claims Automatically
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.resendunappliedclaims}
                                  onChange={(e) =>
                                    this.setState({
                                      resendunappliedclaims: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>
                                  Send Patient Address for Home Service Location
                                </Label>
                              </Col>
                              <Col md="6">
                                <Input
                                  type="select"
                                  value={this.state.sendpatientAddress}
                                  onChange={(e) =>
                                    this.setState({
                                      sendpatientAddress: e.target.value,
                                    })
                                  }
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Input>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Billing on minutes</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="billingonmins"
                              id="billingonmins"
                              value={this.state.billingonmins}
                              onChange={() => {
                                this.setState({
                                  billingonmins: !this.state.billingonmins,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Send CRNA Id</Label> &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="sendCRNAID"
                              id="sendCRNAID"
                              value={this.state.sendCRNAID}
                              onChange={() => {
                                this.setState({
                                  sendCRNAID: !this.state.sendCRNAID,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Taxonomy Code</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="checktaxonomyCode"
                              id="checktaxonomyCode"
                              value={this.state.checktaxonomyCode}
                              onChange={() => {
                                this.setState({
                                  checktaxonomyCode:
                                    !this.state.checktaxonomyCode,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Participating Provider</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="participatingprovider"
                              id="participatingprovider"
                              value={this.state.participatingprovider}
                              onChange={() => {
                                this.setState({
                                  participatingprovider:
                                    !this.state.participatingprovider,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <br />
                            <Label>Appeal Claim Address</Label>{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                              type="checkbox"
                              name="appealclaimaddress"
                              id="appealclaimaddress"
                              value={this.state.appealclaimaddress}
                              onChange={() => {
                                this.setState({
                                  appealclaimaddress:
                                    !this.state.appealclaimaddress,
                                  roleErr: "",
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab title="Lab Setup" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Default Electronic Lab</Label>
                            <Select
                              // className=" depselect"
                              placeholder="Select Default Electronic Lab"
                              options={this.state.categorydata}
                              values={this.state.category}
                              searchable={true}
                              dropdownHandle={true}
                              searchBy="name"
                              labelField="name"
                              noDataLabel="No matches found"
                              onChange={(values) =>
                                this.setState({
                                  category: values,
                                  medicationErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col></Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>LabCorp ID</Label>
                            <Input
                              type="number"
                              value={this.state.labcorpid}
                              onChange={(e) =>
                                this.setState({
                                  labcorpid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>LabCorp Plan Type</Label>
                            <Input
                              type="text"
                              value={this.state.plantype}
                              onChange={(e) =>
                                this.setState({
                                  plantype: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Medical Lab ID</Label>
                            <Input
                              type="number"
                              required
                              value={this.state.medicallabid}
                              onChange={(e) =>
                                this.setState({
                                  medicallabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Quest ID</Label>
                            <Input
                              type="number"
                              required
                              value={this.state.questid}
                              onChange={(e) =>
                                this.setState({
                                  questid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Enzoid</Label>
                            <Input
                              type="number"
                              value={this.state.enzoid}
                              onChange={(e) =>
                                this.setState({
                                  enzoid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Pro Path</Label>
                            <Input
                              type="number"
                              value={this.state.propathid}
                              onChange={(e) =>
                                this.setState({
                                  propathid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Bio Ref ID</Label>
                            <Input
                              type="number"
                              value={this.state.biorefid}
                              onChange={(e) =>
                                this.setState({
                                  biorefid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Phelps ID</Label>
                            <Input
                              type="number"
                              value={this.state.phelpsid}
                              onChange={(e) =>
                                this.setState({
                                  phelpsid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>HTL ID</Label>
                            <Input
                              type="number"
                              value={this.state.hldid}
                              onChange={(e) =>
                                this.setState({
                                  hldid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Accurate ID</Label>
                            <Input
                              type="number"
                              value={this.state.accurateid}
                              onChange={(e) =>
                                this.setState({
                                  accurateid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>CH Group ID</Label>
                            <Input
                              type="number"
                              value={this.state.chid}
                              onChange={(e) =>
                                this.setState({
                                  chid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Lencoid</Label>
                            <Input
                              type="number"
                              value={this.state.lencoid}
                              onChange={(e) =>
                                this.setState({
                                  lencoid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Apple Lab ID</Label>
                            <Input
                              type="number"
                              value={this.state.applelabid}
                              onChange={(e) =>
                                this.setState({
                                  applelabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Empire Lab ID</Label>
                            <Input
                              type="number"
                              value={this.state.empirelabid}
                              onChange={(e) =>
                                this.setState({
                                  empirelabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Enigma ID</Label>
                            <Input
                              type="number"
                              value={this.state.enigmaid}
                              onChange={(e) =>
                                this.setState({
                                  enigmaid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Shiel Lab ID</Label>
                            <Input
                              type="number"
                              value={this.state.sheillabid}
                              onChange={(e) =>
                                this.setState({
                                  sheillabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Fusion ID</Label>
                            <Input
                              type="number"
                              value={this.state.fusionid}
                              onChange={(e) =>
                                this.setState({
                                  fusionid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Mercy ID</Label>
                            <Input
                              type="number"
                              value={this.state.mercyid}
                              onChange={(e) =>
                                this.setState({
                                  mercyid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>SMA Lab ID</Label>
                            <Input
                              type="number"
                              value={this.state.smalabid}
                              onChange={(e) =>
                                this.setState({
                                  smalabid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>EZ ID</Label>
                            <Input
                              type="number"
                              value={this.state.ezid}
                              onChange={(e) =>
                                this.setState({
                                  ezid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Solstas ID</Label>
                            <Input
                              type="number"
                              value={this.state.solstasid}
                              onChange={(e) =>
                                this.setState({
                                  solstasid: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab title="Associate Plans" className="mr-3">
                    <br />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            {/* <Label>Insurance/Payer Name</Label> */}
                            <Input
                              type="text"
                              placeholder="Plan Name"
                              value={this.state.planname}
                              onChange={(e) =>
                                this.setState({
                                  planname: e.target.value,
                                  firstnameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.firstnameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                </Tabs>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.handleSubmit}
                >
                  Create
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {this.state.modal2 && (
            <Modal
              style={{ width: "100%", height: "90%" }}
              isOpen={this.state.modal2}
              onHide={this.toggle2}
              //onExit={this.reset}
              centered
              animation="false"
              size="lg"
            >
              <ModalHeader toggle={this.toggle2}>
                Advance Billing Provider Setup
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Form>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Physician</Label>
                        <Input
                          type="text"
                          value={this.state.physcian}
                          onChange={(e) =>
                            this.setState({
                              physcian: e.target.value,
                              codeErr: "",
                            })
                          }
                        ></Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Type</Label>
                        <Input
                          type="text"
                          value={this.state.type}
                          onChange={(e) =>
                            this.setState({ type: e.target.value, codeErr: "" })
                          }
                        ></Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Service Location</Label>
                        <Input
                          type="text"
                          value={this.state.serviceloc}
                          onChange={(e) =>
                            this.setState({
                              serviceloc: e.target.value,
                              codeErr: "",
                            })
                          }
                        ></Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Payer Category</Label>
                        <Input
                          type="text"
                          value={this.state.payercategory}
                          onChange={(e) =>
                            this.setState({
                              payercategory: e.target.value,
                              codeErr: "",
                            })
                          }
                        ></Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Billing Provider</Label>
                        <Input
                          type="text"
                          value={this.state.billingprovider}
                          onChange={(e) =>
                            this.setState({
                              billingprovider: e.target.value,
                              codeErr: "",
                            })
                          }
                        ></Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                </Form>
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle2();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={this.handleSubmit}
                >
                  Create
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.updatemodal && this.state.dataforupdate && (
            <Modal
              classsName="insuranceedit"
              isOpen={this.state.updatemodal}
              onHide={this.toggleupdate}
              onExit={this.reset}
              centered
              animation="false"
              size="lg"
            >
              <ModalHeader toggle={this.toggleupdate}>
                Edit Payer Master
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Form>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Physician</Label>
                        <Input
                          type="text"
                          value={this.state.updatephyscian}
                          onChange={(e) =>
                            this.setState({
                              updatephyscian: e.target.value,
                              codeErr: "",
                            })
                          }
                        ></Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Type</Label>
                        <Input
                          type="text"
                          value={this.state.updatetype}
                          onChange={(e) =>
                            this.setState({
                              updatetype: e.target.value,
                              codeErr: "",
                            })
                          }
                        ></Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Service Location</Label>
                        <Input
                          type="text"
                          value={this.state.updateserviceloc}
                          onChange={(e) =>
                            this.setState({
                              updateserviceloc: e.target.value,
                              codeErr: "",
                            })
                          }
                        ></Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Payer Category</Label>
                        <Input
                          type="text"
                          value={this.state.updatepayercategory}
                          onChange={(e) =>
                            this.setState({
                              updatepayercategory: e.target.value,
                              codeErr: "",
                            })
                          }
                        ></Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Billing Provider</Label>
                        <Input
                          type="text"
                          value={this.state.updatebillingprovider}
                          onChange={(e) =>
                            this.setState({
                              updatebillingprovider: e.target.value,
                              codeErr: "",
                            })
                          }
                        ></Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.codeErr}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggleupdate();
                    this.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "black", color: "white" }}
                  onClick={() => {
                    this.putrequests(this.state.singleid);
                  }}
                >
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.deletemodal && (
            <Modal
              isOpen={this.state.deletemodal}
              onHide={this.toggledelete}
              // onExit={this.reloadpage}
              centered
            >
              <ModalHeader toggle={this.toggledelete}>
                Delete Master
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid gray",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledelete}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "rgb(25,25,25)" }}
                  onClick={() => {
                    this.deleterequests(this.state.singleid);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
          <Modal isOpen={this.state.createmodal} centered>
            <ModalBody>
              <img
                src={loading}
                style={{ width: "200px", height: "200px", marginLeft: "30%" }}
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
      </div>
    );
  }
}
export default AdvBillingProvider;
