import { Component } from "react";
import { Row, Col, Input } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import moment from "moment";
import { Button } from "reactstrap";
import TableRow from "@mui/material/TableRow";
import Newsidebar from "../Dashboard/Newsidebar";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { BsArrowsMove, BsArrowsFullscreen } from "react-icons/bs";
import "../../Styles/eligibility.css";
import { ip } from "../Apis/Api";
import axios from "axios";
import Card from "react-bootstrap/Card";
import { ToastContainer } from "react-toastify";
import EditForm from "./EditForm";

class Viewmoreeligibility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ChartID: sessionStorage.getItem("ChartID"),
      MRN: sessionStorage.getItem("MRN"),
      Patient: sessionStorage.getItem("Patient"),
      Pripayer: sessionStorage.getItem("Pripayer"),
      DateOfService: sessionStorage.getItem("DateOfService"),
      ClaimDate: sessionStorage.getItem("ClaimDate"),
      PatientDOB: sessionStorage.getItem("PatientDOB"),
      Eligibilityclaimid: sessionStorage.getItem("Eligibilityclaimid"),
      modulename: sessionStorage.getItem("modulename"),
      reportmodal: false,
      Eligibilitydata: [],
      getpayername: "",
      Viewmore: false,
      inputValue: "",
      subscriberFirstname: "",
      subscriberLastname: "",
      subscriberTraceNumberstypecode: "",
      subscriberTraceNumberstype: "",
      subscriberTraceNumbersreference: "",
      subscriberTraceNumbersorganization: "",
      subscriberGender: "",
      new: [],
      remainingamt: "",
      remainingamountothers: [],
      subscriberDOB: "",
      subscriberMemberId: "",
      subscriberAddress: "",
      subscriberGroup: "",
      subscriberType: "",
      submitterNpi: "",
      reqcodevalue: "",
      benefit: [],
      claimarray: [],
      submittername: "",
      submittertype: "",
      planNumber: "",
      planNumberothers: "",
      planStatus: "",
      benefitamount: "",
      benefitamountco: "",
      senderId: "",
      submitterId: "",
      billerId: "",
      applicationMode: "",
      traceId: "",
      remaining: "",
      planGroupNumber: "",
      Patientservicename: "",
      insurancetype: "",
      coverge: "",
      status: "",
      servicetypes: "",
      planDate: "",
      PlanEligibility: "",
      planServiceType: "",
      planCoverageLevel: "",
      planService: "",
      memberId: "",
      firstName: "",
      lastName: "",
      middleName: "",
      entityIdentifier: "",
      entityType: "",
      groupNumber: "",
      entitytypeph: "",
      dependentsfirstname: "",
      dependentslastname: "",
      dependentsmiddlename: "",
      dependentsgender: "",
      dependentsentitytype: "",
      dependentsdob: "",
      dependentsplanno: "",
      dependentsrelationToSubscriber: "",
      dependentsrelationToSubscribercode: "",
      dependentsinsuredIndicator: "",
      dependentsaddress: "",
      payerentityIdentifier: "",
      payerentityType: "",
      payername: "",
      payerpayorIdentification: "",
      remainingqual: "",
      remainingdeduct : "",
      planInformationplanNumber: "",
      planInformationgroupNumber: "",
      planDateInformationplan: "",
      planDateInformationeligibilityBegin: "",
      planDateInformationservice: "",
      planStatusplanDetails: "",
      planStatusstatus: "",
      planStatusstatus: "",
      planStatusserviceTypeCodes: "",
      benefitsInformationcode: "",
      benefitsInformationname: "",
      benefitsInformationserviceTypeCodes: "",
      benefitsInformationserviceTypes: "",
      benefitsInformationheaderLoopIdentifierCode: "",
      benefitsInformationtrailerLoopIdentifierCode: "",
      benefitsRelatedEntityentityIdentifier: "",
      benefitsRelatedEntityentityType: "",
      benefitsRelatedEntityentityName: "",
      benefitsRelatedEntityaddress: "",
      benefitsRelatedEntityplanCoverage: "",
      servicetypecode: [],
      objectID: "",
      plan: "",
      name: "",
      entitytype: "",
      identifiercode: "",
      address1: "",
      telephone: "",
      url: "",
      benefit: "",
      providerName: "",
      entityIdentifier: "",
      entityType: "",
      npi: "",
      updateEndDOS: "",
      updatePatientDOB: "",
      updateLastName: "",
      updateFirstName: "",
      updateBeginingDOS: "",
      updateControlNo: "",
      updateTrackingNumber: "",
      updateGender: "",
      updateNpi: "",
      updateType: "",
      planbegin: "",
      updateServiceOrganizationName: "",
      tradingPartnerServiceId: "",
      updatePrimaryPolicyNo: "",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      subscriberAddress: "",
      subscriberCity: "",
      subscriberState: "",
      subscriberPostalcode: "",
      subscriberdob: "",
      typevalue: "",
      subscribergroup: "",
      subscribergender: "",
      subscriberrelation: "",
      coverage: "",
      othersfirtsname: "",
      otherslastname: "",
      othersType: "",
      othersdob: "",
      othersAddress: "",
      othersCity: "",
      othersPostalcode: "",
      othersState: "",
      othersgender: "",
      othersrelation: "",
      othersservicename: "",
      pasteliothersfirtsname: "",
      pasteliotherslastname: "",
      pasteliothersType: "",
      pasteliothersdob: "",
      pasteliothersAddress: "",
      pasteliothersCity: "",
      pasteliothersPostalcode: "",
      pasteliothersState: "",
      pasteliothersgender: "",
      pasteliothersrelation: "",
      pasteliothersservicename: "",
      groupnumberplan: "",
      groupnumberdesc: "",
      outcomedata: false,
      servicecodevalues1: [],
      suggestions: [],
      servicecodevalues2: [],
      codevalue1: [],
      otherscode: [],
      codevalue2: [],
      itemsToShow: 5,
      expanded: false,
      Deductiblevalue: [],
      Deductiblevaluecode: [],
      timequal1: "",
      timequal1others: [],
      Deductiblevaluecodeothers: [],
      Deductiblevalueothers: [],
      valueothers: [],
      pasteliDeductiblevalueothers: [],
      pasteliDeductiblevaluecodeothers: [],
      pastelitimequal1others: [],
      pastelibenefitamountothers: [],
      pasteliremainingamountothers: [],
      benefitamountothers: [],
      servicecodededuct1: [],
      coservicecode: "",
      coservicetype: [],
      cotimequal: "",
      Limitservicetype: [],
      pasteliLimitservicetype: [],
      expandIcon: false,
      toggletable2: true,
      limitmodal: false,
      namelimit: "",
      limitmodal1: false,
      namelimit1: "",
      servicetypenames: [],
      viewcoverageleveldect: [],
      viewtimeQualifierdect: [],
      viewbenefitAmountdect: [],
      viewauthdect: [],
      viewinplanetdect: [],
      Authvalue: [],
      totalvisit: "",
      calyr: "",
      exceeded: "",
      notexc: "",
      desclimit: "",
      pastelitotalvisit: "",
      payernamevalueErr: "",
      typereqErr: "",
      calyr1: "",
      exceeded1: "",
      notexc1: "",
      desclimit1: "",
      coverageLeveldeduct: [],
      classvalue:"Incomplete",
      timededuct: "",
      benefitamtdeduct: "",
      codededuct: "",
      coverageLevelcoins: [],
      timecoins: "",
      benefitamtcoins: "",
      codecoins: "",
      coverageLevelcopay: [],
      timecopay: "",
      benefitamtcopay: "",
      codecopay: "",
      coverageLeveldeductout: [],
      timedeductout: "",
      benefitamtdeductout: "",
      codedeductout: "",
      coverageLevelcoinsout: [],
      timecoinsout: "",
      benefitamtcoinsout: "",
      codecoinsout: "",
      coverageLevelcopayout: [],
      timecopayout: "",
      benefitamtcopayout: "",
      codecopayout: "",
      benefitamountcoPay: "",
      servicetypecodearrayvalues: [],
      reqServicecode: "",
      otherservicecodearray: [],
      patientfirstname: "",
      patientlastname: "",
      patientdobvalue: "",
      patientgender: "",
      patientaddress: "",
      patienttype: "",
      patientserviceenrollment: "",
      client: sessionStorage.getItem("clientid"),
      patientdobnew: moment(),
      insurname: "",
      servicetypevalue: "Plan Coverage and General Benefits",
      tabindex: 1,
      ServiceTypearray: [],
      loadingservicetype: true,
      mrn: "",
      patientno: "",
      pasteligibility: [],
      loadingpast: true,
      pasteligibilityworked: "",
      payeridnewcheck: "",
      orgnamenewcheck: "",
      npinewcheck: "",
      memberidnewcheck: "",
      firstnamenewcheck: "",
      lastnamenewcheck: "",
      gendernewcheck: "",
      dobnewcheck: "",
      timededuct1:"",
      dosnewcheck: "",
      beginingdosnewcheck: "",
      endingdosnewcheck: "",
      clientidnewcheck: "",
      insnamenewcheck: "",
      physiciannewcheck: "",
      patientnumbernewcheck: "",
      pasteligibilitymodal: false,
      pasteligibilitySubspatientfirstname: "",
      pasteligibilitySubspatientlastname: "",
      pasteligibilitypatientfirstname: "",
      pasteligibilitypatientlastname: "",
      pasteligibilitypatienttype: "",
      pasteligibilitymemberid: "",
      pasteligibilitygroup: "",
      pasteligibilitysubsaddress: "",
      pasteligibilitycity: "",
      pasteligibilitypostalcode: "",
      pasteligibilitystate: "",
      pasteligibilitydob: "",
      pasteligibilitygender: "",
      pasteligibilityrelationtosubscriber: "",
      pasteligibilityservicecontroller: "",
      pasteligibilitysubmitter: "",
      pasteligibilityservicetype: "",
      pasteligibilityserviceprovider: "",
      pasteligibilityserviceprovidernpi: "",
      pasteligibilityplanstatus: "",
      pasteligibilitycoveragelvl: "",
      pasteligibilityservicetype: "",
      pasteligibilityplan: "",
      pasteligibilitygroup: "",
      pasteligibilityauthreq: "",
      pasteligibilityplanbegin: "",
      pasteligibilityplanend: "",
      pasteligibilitybegin: "",
      pasteligibilityinstypecode: "",
      pasteligibilityplan: "",
      pasteligibilityname: "",
      pasteligibilityentitytype: "",
      pasteligibilityidentifiercode: "",
      pasteligibilityaddress: "",
      pasteligibilitytelephone: "",
      pasteligibilityurl: "",
      pasteligibilitybenefit: "",
      pasteligibilitygroupnumber: "",
      pasteligibilitygroupnumberdesc: "",
      pasteligibilityentityType: "",
      pasteligibilityreqservicetype: "",
      pasteligibilityplanNumber: "",
      pasteligibilityplanNumberothers: "",
      pasteligibilityAuthvalue: "",
      pasteligibilityplanDetails: "",
      pasteliDeductiblevalue: "",
      pasteliDeductiblevaluecode: "",
      pastelitimequal1: "",
      pastelibenefitamount: "",
      pasteliremainingamt: "",
      pasteliservicetypecodearrayvalues: [],
      pasteligibilityreqservicetypecode: "",
      pasteliotherservicecodearray: [],
      pasteliservicecodevalues1: [],
      pasteliservicecodevalues2: [],
      pasteligibilityreqservicecode: "",
      pasteliservicecodededuct1: "",
      pastelivalueothers: [],
      errornotify: false,
      errornotifyvalue: "",
      innetworkopen: false,
      outnetworkopen: false,
      benefitperccoins: "",
      benefitperccopay: "",
      benefitpercdeduct: "",
      benefitperccoins1: "",
      benefitperccopay1: "",
      benefitpercdeduct1: "",
      timedeductout1:"",
      remainingqual1:"",
      remainingdeduct1:"",
    };
    this.showMore = this.showMore.bind(this);
  }

  handleInputChange = async (e) => {
    try {
      console.log("inside handle input change", e);
      // Set the input value to the current value
      this.setState({ inputValue: e }, () =>
        console.log("e value", this.state.inputValue)
      );

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const response = await fetch(
        `${ip}/payermaster/filter?clientId=${this.state.client}&payerName=${this.state.inputValue}`,
        { headers: headers }
      );
      const data = await response.json();
      console.log("data msg", data.payer);
      const data1 = data.payer.map((data, index) => {
        return data.payerName;
      });
      const suggestions = data1; // Replace "results" with your API response property containing the suggestions
      console.log("suggestions", suggestions);
      this.setState({ suggestions });
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };
  handleSuggestionClick = (selected) => {
    // Set the selected suggestion as the input value
    const suggestion = selected[0];
    this.setState({ inputValue: suggestion });

    // Clear the suggestions
    this.setState({ suggestions: [] });
  };
  onSearchSubjects = (values) => {
    if (values.length > 0) {
      this.refs.SubjectTypeahead.getInstance().clear();
      this.props.onSearchSubjects(values[0]);
    }
  };
  //Aging eligibility check when click inside button
  checkeligibility = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let data = moment(this.state.patientdobnew);
    let data1 = moment(this.state.beginingdosnewcheck).format("YYYYMMDD");
    let data2 = moment(this.state.endingdosnewcheck).format("YYYYMMDD");
    const Checkdata = {
      PayerID: this.state.payeridnewcheck,
      organizationName: this.state.orgnamenewcheck,
      Npi: this.state.npinewcheck,
      MemberID: this.state.subscriberMemberId,
      FirstName: this.state.firstnamenewcheck,
      LastName: this.state.lastnamenewcheck,
      Gender: this.state.gendernewcheck,
      DOB: this.state.dobnewcheck,
      DOS: data,
      BeginingDOS: data1,
      EndDOS: data2,
      clientId: this.state.clientidnewcheck,
      InsuranceName: this.state.insnamenewcheck,
      Physician: this.state.physiciannewcheck,
      Patientnumber: this.state.patientnumbernewcheck,
      serviceType: this.state.servicetypevalue,
      ClaimID: this.state.Eligibilityclaimid,
    };
    const res = await axios
      .post(`${ip}/neweligibility/neweligibilitycheck`, Checkdata, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          alert(" Eligibility Successfully Checked");
          this.setState(
            {
              outcomedata: false,
            },
            () => {
              this.getpasteligibility();
              this.getselfdetails();
              this.getfamdetails();
              this.getCoInsself();
              this.getCoPaySelf();
              this.getDeductibleself();
              this.getDeductibleothers();
              this.getLimitations();
              this.getServiceTypesname();
              this.getLimitationsDetails();
              this.getserviceid();
            }
          );
        }
      })

      .catch((err) => {});
  };
  //past eligibility table for modal
  getpasteligibility = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // let name = this.state.firstname + " " + this.state.lastname;
    const res = axios
      .get(
        `${ip}/neweligibility/pasteligibility?PatientLastName=${this.state.lastnamenewcheck}&PatientFirstName=${this.state.firstnamenewcheck}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState(
          {
            pasteligibility: res.data.eligibility,
            pageCount: Math.ceil(res.data.count / this.state.perPage),
            loadingpast: false,
          },
          () => {
            console.log("res past", this.state.pasteligibility);
          }
        );
        if (res.data.eligibility[0].data.benefitsInformation[0].length !== 0) {
          this.setState({
            pasteligibilityworked:
              res.data.eligibility[0].data.benefitsInformation[0]
                .serviceTypeCodes,
          });
        } else {
          this.setState({ pasteligibilityworked: 0 });
        }

        if (res.data.eligibility[0].data.benefitsInformation[0].length !== 0) {
          this.setState({
            pasteligistatus:
              res.data.eligibility[0].data.benefitsInformation[0].serviceTypes,
          });
        } else {
          this.setState({ pasteligistatus: 0 });
        }
      })
      .catch((err) => {});
  };
  showMore() {
    this.state.itemsToShow === 5
      ? this.setState({
          itemsToShow: this.state.codevalue1.length,
          expanded: true,
        })
      : this.setState({ itemsToShow: 5, expanded: false });
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  toggle1 = () => {
    this.setState({ Viewmore: !this.state.Viewmore });
  };
  toggle3 = () => {
    this.setState(() => {
      this.setState(
        {
          outcomedata: !this.state.outcomedata,
          typereqErr: "",
          payernamevalueErr: "",
        },
        () => this.getcheckdetails()
      );
    });
  };
  togglepastmodal = () => {
    this.setState({
      pasteligibilitymodal: !this.state.pasteligibilitymodal,
      pasteliDeductiblevalueothers: [],
      pasteliDeductiblevaluecodeothers: [],
      pastelitimequal1others: [],
      pastelibenefitamountothers: [],
      pasteliremainingamountothers: [],
      pastelivalueothers: [],
    });
  };
  togglelimit = () => {
    this.setState({ limitmodal: !this.state.limitmodal });
  };
  togglelimit1 = () => {
    this.setState({ limitmodal1: !this.state.limitmodal1 });
  };
  getcheckdetails = async () => {
    // api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(
        `${ip}/neweligibility/all?ClaimID=${this.state.Eligibilityclaimid}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        //eligibility new check data for post call
        // get payer id
        this.setState(
          {
            payeridnewcheck: res.data.eligibility[0].PayerID,
            orgnamenewcheck: res.data.eligibility[0].BillingOrganizationName,
            npinewcheck: res.data.eligibility[0].Npi,
            gendernewcheck: res.data.eligibility[0].Gender,
            dobnewcheck: res.data.eligibility[0].dob,
            dosnewcheck: res.data.eligibility[0].DateOfService,
            beginingdosnewcheck: res.data.eligibility[0].BeginingDOS,
            endingdosnewcheck: res.data.eligibility[0].EndDOS,
            clientidnewcheck: res.data.eligibility[0].clientId,
            insnamenewcheck: res.data.eligibility[0].InsuranceName,
            physiciannewcheck: res.data.eligibility[0].Physician,
            patientnumbernewcheck: res.data.eligibility[0].patientAccountNumber,
          },
          () => {
            console.log(
              "values eli",
              this.state.payeridnewcheck,
              this.state.dobnewcheck,
              this.state.gendernewcheck
            );
          }
        );
        this.setState(
          {
            firstnamenewcheck: res.data.eligibility[0].PatientFirstName,
            lastnamenewcheck: res.data.eligibility[0].PatientLastName,
          },
          () => this.getpasteligibility()
        );
      });
  };
  getDetails = async () => {
    // api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(
        `${ip}/neweligibility/all?ClaimID=${this.state.Eligibilityclaimid}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.eligibility[0].data.errors.length !== 0) {
          this.setState({
            errornotify: true,
            errornotifyvalue:
              res.data.eligibility[0].data.errors[0].description,
          });
        } else {
          this.setState({
            errornotify: false,
            errornotifyvalue: "",
          });
          if (res.data.eligibility[0].data.dependents.length !== 0) {
            this.getfamdetails();
          } else {
            this.getselfdetails();
          }
        }
        
      });
  };
  getselfdetails = async () => {
    // api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(
        `${ip}/neweligibility/all?ClaimID=${this.state.Eligibilityclaimid}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        //error notification
        // console.log("status value", res.data.eligibility[0].data.errors[0].description);
        // if (res.data.eligibility[0].data.errors !== 0) {
        //   this.setState({
        //     errornotify: true,
        //     errornotifyvalue: res.data.eligibility[0].data.errors[0].description
        //   });
        // }
        //patient details firstname
        if (res.data.eligibility[0].data.subscriber.firstName !== "") {
          this.setState({
            subscriberFirstname:
              res.data.eligibility[0].data.subscriber.firstName,
          });
        } else {
          this.setState({
            subscriberFirstname: "No data",
          });
        }
        if (res.data.eligibility[0].PatientFirstName !== "") {
          this.setState({
            patientfirstname: res.data.eligibility[0].PatientFirstName,
          });
        } else {
          this.setState({
            patientfirstname: "No Data",
          });
        }
        //patient details lastname
        if (res.data.eligibility[0].data.subscriber.lastName !== 0) {
          this.setState({
            subscriberLastname:
              res.data.eligibility[0].data.subscriber.lastName,
          });
        } else {
          this.setState({
            subscriberLastname: "No data",
          });
        }
        //patient details type
        if (res.data.eligibility[0].data.subscriber.entityIdentifier !== 0) {
          this.setState({
            subscriberType:
              res.data.eligibility[0].data.subscriber.entityIdentifier,
          });
        } else {
          this.setState({
            subscriberType: "No data",
          });
        }
        //patient details member id
        if (res.data.eligibility[0].data.subscriber.memberId !== 0) {
          this.setState({
            subscriberMemberId:
              res.data.eligibility[0].data.subscriber.memberId,
          });
        } else {
          this.setState({
            subscriberMemberId: "No data",
          });
        }
        //patient details group number
        if (res.data.eligibility[0].data.subscriber.groupNumber !== 0) {
          this.setState({
            subscribergroup:
              res.data.eligibility[0].data.subscriber.groupNumber,
          });
        } else {
          this.setState({
            subscribergroup: "No data",
          });
        }
        //patient details address
        if (res.data.eligibility[0].data.subscriber.address.length !== 0) {
          let postalcode1;
          let postalcode2;
          if (
            res.data.eligibility[0].data.subscriber.address.postalCode
              .length === 9
          ) {
            postalcode1 =
              res.data.eligibility[0].data.subscriber.address.postalCode.slice(
                0,
                5
              );
            postalcode2 =
              res.data.eligibility[0].data.subscriber.address.postalCode.slice(
                5
              );
          } else {
            postalcode1 =
              res.data.eligibility[0].data.subscriber.address.postalCode.slice(
                0,
                5
              );
          }
          this.setState({
            subscriberAddress:
              res.data.eligibility[0].data.subscriber.address.address1,
            subscriberCity:
              res.data.eligibility[0].data.subscriber.address.city,
            subscriberState:
              res.data.eligibility[0].data.subscriber.address.state,
            subscriberPostalcode: postalcode1
              ? postalcode1
              : "" + "-" + postalcode2
              ? postalcode2
              : "",
          });
        } else {
          this.setState({
            subscriberAddress: "-",
            subscriberCity: "-",
            subscriberState: "-",
            subscriberPostalcode: "-",
          });
        }
        //patient details dob
        if (res.data.eligibility[0].data.subscriber.dateOfBirth !== 0) {
          this.setState({
            subscriberdob: res.data.eligibility[0].data.subscriber.dateOfBirth,
          });
        } else {
          this.setState({
            subscriberdob: "No data",
          });
        }
        //patient details gender
        if (res.data.eligibility[0].data.subscriber.gender !== 0) {
          this.setState({
            subscribergender: res.data.eligibility[0].data.subscriber.gender,
          });
        } else {
          this.setState({
            subscribergender: "No data",
          });
        }
        //patient details relation to subscriber
        console.log("relation to subscriber", res.data.eligibility[0].data.subscriber.relationToSubscriber)
        if (
          res.data.eligibility[0].data.subscriber.relationToSubscriber !==
          undefined
        ) {
          this.setState({
            subscriberrelation: res.data.eligibility[0].data.subscriber
              .relationToSubscriber
              ? res.data.eligibility[0].data.subscriber.relationToSubscriber
              : "Self",
          } , () => {
            console.log("pass first test case", this.state.subscriberrelation)
            this.getDeductibleself();
            this.getDeductibleothers();
            this.getCoInsself();
            this.getCoPaySelf();
            this.getLimitations();
            this.getLimitationsDetails();
            this.getCoPaySelf();
            // this.getInNetworkDeductibleInfo();
          }
          
          );
        } else if (
          res.data.eligibility[0].data.subscriber.relationToSubscriber ===
          undefined
        ) {
          this.setState(
            {
              subscriberrelation: "Self",
            },
            () => {
              console.log("pass first test case1", this.state.subscriberrelation)
              this.getDeductibleself();
              this.getDeductibleothers();
              this.getCoInsself();
              this.getCoPaySelf();
              this.getLimitations();
              this.getLimitationsDetails();
              this.getCoPaySelf();
              // this.getInNetworkDeductibleInfo();
            }
          );
        }
        //patient details service
        if (res.data.eligibility[0].data.tradingPartnerServiceId !== 0) {
          this.setState({
            Patientservicename:
              res.data.eligibility[0].data.tradingPartnerServiceId,
          });
        } else {
          this.setState({
            Patientservicename: "No data",
          });
        }
        //submitted submitter name
        if (res.data.eligibility[0].data.provider.providerName !== 0) {
          this.setState({
            submittername: res.data.eligibility[0].data.provider.providerName,
          });
        } else {
          this.setState({
            submittername: "No data",
          });
        }
        //submitted submitter type
        if (res.data.eligibility[0].data.provider.entityIdentifier !== 0) {
          this.setState({
            submittertype:
              res.data.eligibility[0].data.provider.entityIdentifier,
          });
        } else {
          this.setState({
            submittertype: "No data",
          });
        }
        //submitted service provider
        if (res.data.eligibility[0].data.provider.npi !== 0) {
          this.setState({
            submitterNpi: res.data.eligibility[0].data.provider.npi,
          });
        } else {
          this.setState({
            submitterNpi: "No data",
          });
        }

        //plan status array extract of service types
        res.data.eligibility[0].data.planStatus.map((data) => {
          data.serviceTypeCodes.map((ele) => {
            this.state.servicetypecodearrayvalues.push(ele);
          });
          //plan details req plan status
          if (res.data.eligibility[0].data.planStatus.length !== 0) {
            this.setState({
              planNumber: res.data.eligibility[0].data.planStatus[0].status,
            });
          } else {
            this.setState({
              planNumber: "No Data",
            });
          }
          //plan details others plan status
          if (res.data.eligibility[0].data.planStatus.length !== 0) {
            this.setState({
              planNumberothers:
                res.data.eligibility[0].data.planStatus[0].status,
            });
          } else {
            this.setState({
              planNumberothers: "No Data",
            });
          }

          //plan details others plan code
          let plandetailsvalue = res.data.eligibility[0].data.planStatus.map(
            (data) => {
              return data.planDetails;
            }
          );
          this.setState({
            planStatusplanDetails: plandetailsvalue,
          });

          //requested service type
          this.setState({
            reqServicecode: res.data.eligibility[0].serviceType,
          });
          //requeted service code
          this.setState({
            reqcodevalue: res.data.eligibility[0].serviceCode,
          });
        });
        //separate other service codes from the array
        for (let i = 0; i < this.state.servicetypecodearrayvalues.length; i++) {
          if (
            this.state.reqcodevalue !== this.state.servicetypecodearrayvalues[i]
          ) {
            this.state.otherservicecodearray.push(
              this.state.servicetypecodearrayvalues[i]
            );
          }
        }

        //plan details auth value
        let authcb = res.data.eligibility[0].data.benefitsInformation.map(
          (data, index) => {
            if ("CB" === data.code) {
              return data.serviceTypeCodes;
            }
          }
        );
        if (this.state.reqcodevalue === authcb) {
          this.setState({
            Authvalue:
              res.data.eligibility[0].data.benefitsInformation
                .authOrCertIndicator,
          });
        } else {
          this.setState({
            Authvalue: "No",
          });
        }
        //plan date information plan begin
        if (res.data.eligibility[0].data.planDateInformation.planBegin !== "") {
          this.setState(
            {
              planDateInformationplan:
                res.data.eligibility[0].data.planDateInformation.planBegin,
            },
            () => {
              console.log("plan date", this.state.planDateInformationplan);
            }
          );
        } else {
          this.setState({
            planDateInformationplan: "No data",
          });
        }
        //plan date information plan end
        if (res.data.eligibility[0].data.planDateInformation.planEnd !== 0) {
          this.setState(
            {
              planDateInformationservice:
                res.data.eligibility[0].data.planDateInformation.planEnd,
            },
            () => {
              console.log("plan end", this.state.planDateInformationservice);
            }
          );
        } else {
          this.setState({
            planDateInformationservice: "No data",
          });
        }
        //plan dete information eligibility begin
        if (
          res.data.eligibility[0].data.planDateInformation.eligibilityBegin !==
          0
        ) {
          this.setState({
            planDateInformationeligibilityBegin:
              res.data.eligibility[0].data.planDateInformation.eligibilityBegin,
          });
        } else {
          this.setState({
            planDateInformationeligibilityBegin: "No data",
          });
        }
        //subscriber first name
        if (res.data.eligibility[0].data.subscriber.firstName !== "") {
          this.setState({
            firstName: res.data.eligibility[0].data.subscriber.firstName,
          });
        } else {
          this.setState({
            firstName: "No data",
          });
        }
        //subscriber last name
        if (res.data.eligibility[0].data.subscriber.lastName !== 0) {
          this.setState({
            lastName: res.data.eligibility[0].data.subscriber.lastName,
          });
        } else {
          this.setState({
            lastName: "No data",
          });
        }
        //subscriber gender
        if (res.data.eligibility[0].data.subscriber.lastName !== 0) {
          this.setState({
            lastName: res.data.eligibility[0].data.subscriber.lastName,
          });
        } else {
          this.setState({
            lastName: "No data",
          });
        }
        //subscriber EntityIdentifier
        if (res.data.eligibility[0].data.subscriber.entityIdentifier !== 0) {
          this.setState({
            entityIdentifier:
              res.data.eligibility[0].data.subscriber.entityIdentifier,
          });
        } else {
          this.setState({
            entityIdentifier: "No data",
          });
        }
        //subscriber entityType
        if (res.data.eligibility[0].data.subscriber.entityType !== 0) {
          this.setState({
            entityType: res.data.eligibility[0].data.subscriber.entityType,
          });
        } else {
          this.setState({
            entityType: "No data",
          });
        }
        //plan information group number
        if (res.data.eligibility[0].data.planInformation.groupNumber !== "") {
          this.setState({
            groupnumberplan:
              res.data.eligibility[0].data.planInformation.groupNumber,
          });
        } else {
          this.setState({
            groupnumberplan: "No data",
          });
        }
        //plan information group description
        if (
          res.data.eligibility[0].data.planInformation.groupDescription !== ""
        ) {
          this.setState(
            {
              groupnumberdesc:
                res.data.eligibility[0].data.planInformation.groupDescription,
            },
            () => {
              console.log("value", this.state.groupnumberdesc);
            }
          );
        } else {
          this.setState({
            groupnumberdesc: "No data",
          });
        }
        //get service types by other service codes
        if (this.state.otherservicecodearray.length !== 0) {
          let data;
          data = JSON.stringify(this.state.otherservicecodearray);
          data = data.split(",");
          console.log("service code", data);
          this.setState(
            {
              servicecodevalues1: data,
            },
            () => {
              this.getServiceTypes();
            }
          );
        } else {
          this.setState({
            servicecodevalues1: "No data",
          });
        }

        //get service types by service codes
        if (
          this.state.otherservicecodearray.length !== 0 ||
          this.state.reqServicecode !== ""
        ) {
          let data;
          data = JSON.stringify(this.state.otherservicecodearray);
          data = data.split(",");
          console.log("others service code", data)
          this.setState(
            {
              servicecodevalues2: data,
              servicecodededuct1: this.state.reqServicecode
                ? this.state.reqServicecode
                : "",
            },
            () => {
              console.log("service code others", this.state.servicecodevalues2);
              this.getServiceTypes1();
              this.getDeductibleself();
              this.getDeductibleothers();
              this.getCoInsself();
              this.getCoPaySelf();
              // this.getLimitations();
              // this.getLimitationsDetails();
              // this.getInNetworkDeductibleInfo();
            }
          );
        } else {
          this.setState({
            servicecodevalues2: "No data",
          });
        }

        //Pharmacy plan
        let plan1 = res.data.eligibility[0].data.benefitsInformation.map(
          (data, index) => {
            if ("Pharmacy" === data.serviceTypes[0]) {
              return data.planCoverage;
            }
          }
        );
        let entityType = res.data.eligibility[0].data.benefitsInformation.map(
          (data, index) => {
            if ("Pharmacy" === data.serviceTypes[0]) {
              return data.benefitsRelatedEntity.entityType;
            }
          }
        );
        let telephone1 = res.data.eligibility[0].data.benefitsInformation.map(
          (data, index) => {
            if ("Pharmacy" === data.serviceTypes[0]) {
              return data.benefitsRelatedEntity.contactInformation.contacts[0]
                .communicationNumber;
            }
          }
        );
        let namepharmacy = res.data.eligibility[0].data.benefitsInformation.map(
          (data, index) => {
            if ("Pharmacy" === data.serviceTypes[0]) {
              return data.name;
            }
          }
        );
        this.setState({
          plan: plan1,
          entitytypeph: entityType,
          telephone: telephone1,
          name: namepharmacy,
        });
      })
      .catch((err) => {});
  };
  getfamdetails = async () => {
    // api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(
        `${ip}/neweligibility/all?ClaimID=${this.state.Eligibilityclaimid}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        //!=self patient firstname
        if (res.data.eligibility[0].data.dependents[0].firstName !== "") {
          this.setState({
            othersfirtsname:
              res.data.eligibility[0].data.dependents[0].firstName,
          });
        } else {
          this.setState({
            othersfirtsname: "No data",
          });
        }
        //!=self patient last name
        if (res.data.eligibility[0].data.dependents[0].lastName !== "") {
          this.setState({
            otherslastname: res.data.eligibility[0].data.dependents[0].lastName,
          });
        } else {
          this.setState({
            otherslastname: "No data",
          });
        }
        //!self patient entity type
        if (res.data.eligibility[0].data.dependents[0].entityType !== "") {
          this.setState({
            othersType: res.data.eligibility[0].data.dependents[0].entityType,
          });
        } else {
          this.setState({
            othersType: "No data",
          });
        }
        //!self patient dob
        if (res.data.eligibility[0].data.dependents[0].dateOfBirth !== "") {
          this.setState({
            othersdob: res.data.eligibility[0].data.dependents[0].dateOfBirth,
          });
        } else {
          this.setState({
            othersdob: "No data",
          });
        }
        //!self patient gender
        if (res.data.eligibility[0].data.dependents[0].gender !== "") {
          this.setState({
            othersgender: res.data.eligibility[0].data.dependents[0].gender,
          });
        } else {
          this.setState({
            othersgender: "No data",
          });
        }
        //!self patient relation to subscriber
        if (
          res.data.eligibility[0].data.dependents[0].relationToSubscriber !== ""
        ) {
          this.setState(
            {
              othersrelation:
                res.data.eligibility[0].data.dependents[0].relationToSubscriber,
            },
            () => {
              console.log("value relation", this.state.othersrelation);
            }
          );
        } else {
          this.setState({
            othersrelation: "No data",
          });
        }
        //!self patient service
        if (res.data.eligibility[0].data.tradingPartnerServiceId !== 0) {
          this.setState({
            othersservicename:
              res.data.eligibility[0].data.tradingPartnerServiceId,
          });
        } else {
          this.setState({
            othersservicename: "No data",
          });
        }
        // if (res.data.eligibility[0].data.errors.length !== 0) {
        //   this.setState({
        //     errornotify: true,
        //     errornotifyvalue: res.data.eligibility[0].data.errors[0].description
        //   });
        // }
        //patient details firstname
        if (res.data.eligibility[0].data.subscriber.firstName !== "") {
          this.setState({
            subscriberFirstname:
              res.data.eligibility[0].data.subscriber.firstName,
          });
        } else {
          this.setState({
            subscriberFirstname: "No data",
          });
        }
        if (res.data.eligibility[0].PatientFirstName !== "") {
          this.setState({
            patientfirstname: res.data.eligibility[0].PatientFirstName,
          });
        } else {
          this.setState({
            patientfirstname: "No Data",
          });
        }
        //patient details lastname
        if (res.data.eligibility[0].data.subscriber.lastName !== 0) {
          this.setState({
            subscriberLastname:
              res.data.eligibility[0].data.subscriber.lastName,
          });
        } else {
          this.setState({
            subscriberLastname: "No data",
          });
        }
        //patient details type
        if (res.data.eligibility[0].data.subscriber.entityIdentifier !== 0) {
          this.setState({
            subscriberType:
              res.data.eligibility[0].data.subscriber.entityIdentifier,
          });
        } else {
          this.setState({
            subscriberType: "No data",
          });
        }
        //patient details member id
        if (res.data.eligibility[0].data.subscriber.memberId !== 0) {
          this.setState({
            subscriberMemberId:
              res.data.eligibility[0].data.subscriber.memberId,
          });
        } else {
          this.setState({
            subscriberMemberId: "No data",
          });
        }
        //patient details group number
        if (res.data.eligibility[0].data.subscriber.groupNumber !== 0) {
          this.setState({
            subscribergroup:
              res.data.eligibility[0].data.subscriber.groupNumber,
          });
        } else {
          this.setState({
            subscribergroup: "No data",
          });
        }
        //patient details address
        if (res.data.eligibility[0].data.subscriber.address.length !== 0) {
          let postalcode1;
          let postalcode2;
          if (
            res.data.eligibility[0].data.subscriber.address.postalCode
              .length === 9
          ) {
            postalcode1 =
              res.data.eligibility[0].data.subscriber.address.postalCode.slice(
                0,
                5
              );
            postalcode2 =
              res.data.eligibility[0].data.subscriber.address.postalCode.slice(
                5
              );
          } else {
            postalcode1 =
              res.data.eligibility[0].data.subscriber.address.postalCode.slice(
                0,
                5
              );
          }
          this.setState({
            subscriberAddress:
              res.data.eligibility[0].data.subscriber.address.address1,
            subscriberCity:
              res.data.eligibility[0].data.subscriber.address.city,
            subscriberState:
              res.data.eligibility[0].data.subscriber.address.state,
            subscriberPostalcode: postalcode1
              ? postalcode1
              : "" + "-" + postalcode2
              ? postalcode2
              : "",
          });
        } else {
          this.setState({
            subscriberAddress: "-",
            subscriberCity: "-",
            subscriberState: "-",
            subscriberPostalcode: "-",
          });
        }
        //patient details dob
        if (res.data.eligibility[0].data.subscriber.dateOfBirth !== 0) {
          this.setState({
            subscriberdob: res.data.eligibility[0].data.subscriber.dateOfBirth,
          });
        } else {
          this.setState({
            subscriberdob: "No data",
          });
        }
        //patient details gender
        if (res.data.eligibility[0].data.subscriber.gender !== 0) {
          this.setState({
            subscribergender: res.data.eligibility[0].data.subscriber.gender,
          });
        } else {
          this.setState({
            subscribergender: "No data",
          });
        }
        //patient details relation to subscriber
        if (
          res.data.eligibility[0].data.dependents[0].relationToSubscriber !==
          undefined
        ) {
          this.setState(
            {
              subscriberrelation: res.data.eligibility[0].data.dependents[0]
                .relationToSubscriber
                ? res.data.eligibility[0].data.dependents[0]
                    .relationToSubscriber
                : "Self",
            },
            () => {
              this.getDeductibleself();
              this.getDeductibleothers();
              this.getCoInsself();
              this.getCoPaySelf();
              this.getLimitations();
              this.getLimitationsDetails();
              this.getCoPaySelf();
              // this.getInNetworkDeductibleInfo();
            }
          );
        } else if (
          res.data.eligibility[0].data.dependents[0].relationToSubscriber ===
          undefined
        ) {
          this.setState(
            {
              subscriberrelation: "Self",
            },
            () => {
              this.getDeductibleself();
              this.getDeductibleothers();
              this.getCoInsself();
              this.getCoPaySelf();
              this.getLimitations();
              this.getLimitationsDetails();
              this.getCoPaySelf();
              // this.getInNetworkDeductibleInfo();
            }
          );
        }
        //patient details service
        if (res.data.eligibility[0].data.tradingPartnerServiceId !== 0) {
          this.setState({
            Patientservicename:
              res.data.eligibility[0].data.tradingPartnerServiceId,
          });
        } else {
          this.setState({
            Patientservicename: "No data",
          });
        }
        //submitted submitter name
        if (res.data.eligibility[0].data.provider.providerName !== 0) {
          this.setState({
            submittername: res.data.eligibility[0].data.provider.providerName,
          });
        } else {
          this.setState({
            submittername: "No data",
          });
        }
        //submitted submitter type
        if (res.data.eligibility[0].data.provider.entityIdentifier !== 0) {
          this.setState({
            submittertype:
              res.data.eligibility[0].data.provider.entityIdentifier,
          });
        } else {
          this.setState({
            submittertype: "No data",
          });
        }
        //submitted service provider
        if (res.data.eligibility[0].data.provider.npi !== 0) {
          this.setState({
            submitterNpi: res.data.eligibility[0].data.provider.npi,
          });
        } else {
          this.setState({
            submitterNpi: "No data",
          });
        }

        //plan status array extract of service types
        res.data.eligibility[0].data.planStatus.map((data) => {
          data.serviceTypeCodes.map((ele) => {
            this.state.servicetypecodearrayvalues.push(ele);
          });
          //plan details req plan status
          if (res.data.eligibility[0].data.planStatus.length !== 0) {
            this.setState({
              planNumber: res.data.eligibility[0].data.planStatus[0].status,
            });
          } else {
            this.setState({
              planNumber: "No Data",
            });
          }
          //plan details others plan status
          if (res.data.eligibility[0].data.planStatus.length !== 0) {
            this.setState({
              planNumberothers:
                res.data.eligibility[0].data.planStatus[0].status,
            });
          } else {
            this.setState({
              planNumberothers: "No Data",
            });
          }

          //plan details others plan code
          let plandetailsvalue = res.data.eligibility[0].data.planStatus.map(
            (data) => {
              return data.planDetails;
            }
          );
          this.setState({
            planStatusplanDetails: plandetailsvalue,
          });

          //requested service type
          this.setState({
            reqServicecode: res.data.eligibility[0].serviceType,
          });
          //requeted service code
          this.setState({
            reqcodevalue: res.data.eligibility[0].serviceCode,
          });
        });
        //separate other service codes from the array
        for (let i = 0; i < this.state.servicetypecodearrayvalues.length; i++) {
          if (
            this.state.reqcodevalue !== this.state.servicetypecodearrayvalues[i]
          ) {
            this.state.otherservicecodearray.push(
              this.state.servicetypecodearrayvalues[i]
            );
          }
          console.log("other serice", this.state.otherservicecodearray);
        }

        //plan details auth value
        let authcb = res.data.eligibility[0].data.benefitsInformation.map(
          (data, index) => {
            if ("CB" === data.code) {
              return data.serviceTypeCodes;
            }
          }
        );
        if (this.state.reqcodevalue === authcb) {
          this.setState({
            Authvalue:
              res.data.eligibility[0].data.benefitsInformation
                .authOrCertIndicator,
          });
        } else {
          this.setState({
            Authvalue: "No",
          });
        }
        //plan date information plan begin
        if (res.data.eligibility[0].data.planDateInformation.planBegin !== "") {
          this.setState(
            {
              planDateInformationplan:
                res.data.eligibility[0].data.planDateInformation.planBegin,
            },
            () => {
              console.log("plan date", this.state.planDateInformationplan);
            }
          );
        } else {
          this.setState({
            planDateInformationplan: "No data",
          });
        }
        //plan date information plan end
        if (res.data.eligibility[0].data.planDateInformation.planEnd !== 0) {
          this.setState(
            {
              planDateInformationservice:
                res.data.eligibility[0].data.planDateInformation.planEnd,
            },
            () => {
              console.log("plan end", this.state.planDateInformationservice);
            }
          );
        } else {
          this.setState({
            planDateInformationservice: "No data",
          });
        }
        //plan dete information eligibility begin
        if (
          res.data.eligibility[0].data.planDateInformation.eligibilityBegin !==
          0
        ) {
          this.setState({
            planDateInformationeligibilityBegin:
              res.data.eligibility[0].data.planDateInformation.eligibilityBegin,
          });
        } else {
          this.setState({
            planDateInformationeligibilityBegin: "No data",
          });
        }
        //subscriber first name
        if (res.data.eligibility[0].data.subscriber.firstName !== "") {
          this.setState({
            firstName: res.data.eligibility[0].data.subscriber.firstName,
          });
        } else {
          this.setState({
            firstName: "No data",
          });
        }
        //subscriber last name
        if (res.data.eligibility[0].data.subscriber.lastName !== 0) {
          this.setState({
            lastName: res.data.eligibility[0].data.subscriber.lastName,
          });
        } else {
          this.setState({
            lastName: "No data",
          });
        }
        //subscriber gender
        if (res.data.eligibility[0].data.subscriber.lastName !== 0) {
          this.setState({
            lastName: res.data.eligibility[0].data.subscriber.lastName,
          });
        } else {
          this.setState({
            lastName: "No data",
          });
        }
        //subscriber EntityIdentifier
        if (res.data.eligibility[0].data.subscriber.entityIdentifier !== 0) {
          this.setState({
            entityIdentifier:
              res.data.eligibility[0].data.subscriber.entityIdentifier,
          });
        } else {
          this.setState({
            entityIdentifier: "No data",
          });
        }
        //subscriber entityType
        if (res.data.eligibility[0].data.subscriber.entityType !== 0) {
          this.setState({
            entityType: res.data.eligibility[0].data.subscriber.entityType,
          });
        } else {
          this.setState({
            entityType: "No data",
          });
        }
        //plan information group number
        if (res.data.eligibility[0].data.planInformation.groupNumber !== "") {
          this.setState({
            groupnumberplan:
              res.data.eligibility[0].data.planInformation.groupNumber,
          });
        } else {
          this.setState({
            groupnumberplan: "No data",
          });
        }
        //plan information group description
        if (
          res.data.eligibility[0].data.planInformation.groupDescription !== ""
        ) {
          this.setState(
            {
              groupnumberdesc:
                res.data.eligibility[0].data.planInformation.groupDescription,
            },
            () => {
              console.log("value", this.state.groupnumberdesc);
            }
          );
        } else {
          this.setState({
            groupnumberdesc: "No data",
          });
        }
        //get service types by other service codes
        if (this.state.otherservicecodearray.length !== 0) {
          let data;
          data = JSON.stringify(this.state.otherservicecodearray);
          data = data.split(",");
          console.log("service code", data);
          this.setState(
            {
              servicecodevalues1: data,
            },
            () => {
              this.getServiceTypes();
              console.log("state code values", this.state.servicecodevalues1);
            }
          );
        } else {
          this.setState({
            servicecodevalues1: "No data",
          });
        }

        //get service types by service codes
        if (
          this.state.otherservicecodearray.length !== 0 ||
          this.state.reqServicecode !== ""
        ) {
          let data;
          data = JSON.stringify(this.state.otherservicecodearray);
          data = data.split(",");
          console.log("data service code", data)
          this.setState(
            {
              servicecodevalues2: data,
              servicecodededuct1: this.state.reqServicecode,
            },
            () => {
              this.getServiceTypes1();
              this.getDeductibleself();
              this.getDeductibleothers();
              this.getCoInsself();
              this.getCoPaySelf();
              // this.getLimitations();
              // this.getLimitationsDetails();
              // this.getInNetworkDeductibleInfo();
            }
          );
        } else {
          this.setState({
            servicecodevalues2: "No data",
          });
        }

        //Pharmacy plan
        // let plan1 = res.data.eligibility[0].data.benefitsInformation.map(
        //   (data, index) => {
        //     if ("Pharmacy" === data.serviceTypes[0]) {
        //       return data.planCoverage;
        //     }
        //   }
        // );
        // let entityType = res.data.eligibility[0].data.benefitsInformation.map(
        //   (data, index) => {
        //     if ("Pharmacy" === data.serviceTypes[0]) {
        //       return data.benefitsRelatedEntity.entityType;
        //     }
        //   }
        // );
        // let telephone1 = res.data.eligibility[0].data.benefitsInformation.map(
        //   (data, index) => {
        //     if ("Pharmacy" === data.serviceTypes[0]) {
        //       return data.benefitsRelatedEntity.contactInformation.contacts[0]
        //         .communicationNumber;
        //     }
        //   }
        // );
        // let namepharmacy = res.data.eligibility[0].data.benefitsInformation.map(
        //   (data, index) => {
        //     if ("Pharmacy" === data.serviceTypes[0]) {
        //       return data.name;
        //     }
        //   }
        // );
        // this.setState({
        //   plan: plan1,
        //   entitytypeph: entityType,
        //   telephone: telephone1,
        //   name: namepharmacy,
        // });
      });
  };
  getServiceTypesname = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(
        `${ip}/neweligibility/getservicetypename?claimid=${this.state.Eligibilityclaimid}&servicetype=${this.state.typevalue}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.eligibility[0].details.inPlanNetworkIndicatorCode === "Y")
          this.setState({
            servicetypenames: res.data.eligibility[0].details.coverageLevel,
            viewcoverageleveldect:
              res.data.eligibility[0].details.coverageLevel,
            viewbenefitAmountdect:
              res.data.eligibility[0].details.benefitAmount,
            viewtimeQualifierdect:
              res.data.eligibility[0].details.timeQualifier,
            viewauthdect: res.data.eligibility[0].details.authOrCertIndicator,
          });
      })
      .catch((err) => {});
  };
  getServiceTypes = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(
        `${ip}/neweligibility/getallservicetypes?servicetypecodes=${this.state.servicecodevalues1}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        let data = res.data.eligibility.map((data) => {
          return [data.serviceType, data.code];
        });

        this.setState({
          codevalue1: data,
        });
      })
      .catch((err) => {});
  };
  getpasteliServiceTypes = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(
        `${ip}/neweligibility/getallservicetypes?servicetypecodes=${this.state.pasteliservicecodevalues1}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        let data1 = res.data.eligibility.map((data) => {
          return [data.serviceType, data.code];
        });

        this.setState({
          codevalue1: data1,
        });
      })
      .catch((err) => {});
  };
  getServiceTypes1 = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(
        `${ip}/neweligibility/getallservicetypes?servicetypecodes=${this.state.reqcodevalue}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          codevalue2: res.data.eligibility[0].serviceType,
        });
      })
      .catch((err) => {});
  };
  getpasteliServiceTypes1 = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(
        `${ip}/neweligibility/getallservicetypes?servicetypecodes=${this.state.pasteligibilityreqservicetype}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        this.setState({
          codevalue2: res.data.eligibility[0].serviceType,
        });
      })
      .catch((err) => {});
  };
  getDeductibleself = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (
      this.state.subscriberrelation === "Self" ||
      this.state.subscriberrelation === null
    ) {
      url = `${ip}/neweligibility/getdeductibledetailsfor30?claimid=${this.state.Eligibilityclaimid}&servicetypecodes=${this.state.reqServicecode}&relationToSubscriber=Self`;
    } else {
      url = `${ip}/neweligibility/getdeductibledetailsfor30?claimid=${this.state.Eligibilityclaimid}&servicetypecodes=${this.state.reqServicecode}&relationToSubscriber=Family`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          Deductiblevalue: res.data.eligibility[0].details[0].serviceTypes[0],
          Deductiblevaluecode:
            res.data.eligibility[0].details[0].serviceTypeCodes[0],
          timequal1: res.data.eligibility[0].details[0].timeQualifier,
          benefitamount: res.data.eligibility[0].details[0].benefitAmount,
        }, ()=> {console.log("deductible self", this.state.Deductiblevalue)});
        let timequal2 = res.data.eligibility[0].details[1].timeQualifier;
        if (this.state.timequal1 === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            timequal1: time1 + "~" + time2,
          });
        }
        if (timequal2 === "Remaining") {
          this.setState({
            remainingamt: res.data.eligibility[0].details[1].benefitAmount,
          });
        }
      })
      .catch((err) => {});
  };
  getDeductibleothers = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (
      this.state.subscriberrelation === "Self" ||
      this.state.subscriberrelation === null
    ) {
      url = `${ip}/neweligibility/getdeductibledetailsforothertype?claimid=${this.state.Eligibilityclaimid}&servicetypecodes=${this.state.servicecodevalues2}&relationToSubscriber=Self`;
    } else {
      url = `${ip}/neweligibility/getdeductibledetailsforothertype?claimid=${this.state.Eligibilityclaimid}&servicetypecodes=${this.state.servicecodevalues2}&relationToSubscriber=Family`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        res.data.eligibility.map((item1) =>
          this.state.valueothers.push(item1.serviceTypes)
        );
        let data2 = res.data.eligibility.map((item2) => {
          return item2.serviceTypeCodes;
        });

        let data3 = res.data.eligibility.map((item3) => {
          if (item3.timeQualifier === "Calendar Year") {
            let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
            let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
            return time1 + "~" + time2;
          }
          return item3.timeQualifier;
        });
        let dataremaining = res.data.eligibility.map((itemval) => {
          if (itemval.timeQualifier === "Remaining") {
            return itemval.benefitAmount;
          }
        });
        let data4 = res.data.eligibility.map((item4) => {
          return item4.benefitAmount;
        });

        this.setState({
          Deductiblevalueothers: this.state.valueothers,
          Deductiblevaluecodeothers: data2,
          timequal1others: data3,
          benefitamountothers: data4,
          remainingamountothers: dataremaining,
        });
      })
      .catch((err) => {});
  };
  getCoInsself = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (
      this.state.subscriberrelation === "Self" ||
      this.state.subscriberrelation === null
    ) {
      url = `${ip}/neweligibility/getcoinsurancedetailsforothertype?claimid=${this.state.Eligibilityclaimid}&servicetypecodes=${this.state.servicecodededuct1}&relationToSubscriber=Self`;
    } else {
      url = `${ip}/neweligibility/getcoinsurancedetailsforothertype?claimid=${this.state.Eligibilityclaimid}&servicetypecodes=${this.state.servicecodededuct1}&relationToSubscriber=Family`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.eligibility.length !== 0) {
          this.setState({
            coservicetype: res.data.eligibility[0].details[0].serviceTypes[0],
            coservicecode:
              res.data.eligibility[0].details[0].serviceTypeCodes[0],
            cotimequal: res.data.eligibility[0].details[0].timeQualifier,
            benefitamountco: res.data.eligibility[0].details[0].benefitPercent,
          });
        } else {
          this.setState({
            coservicetype: "-",
            coservicecode: "-",
            cotimequal: "-",
          });
        }
        if (this.state.cotimequal === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            cotimequal: time1 + "~" + time2,
          });
        } else if (this.state.cotimequal === undefined) {
          this.setState({ cotimequal: " " });
        }
      })
      .catch((err) => {});
  };
  getCoPaySelf = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (
      this.state.subscriberrelation === "Self" ||
      this.state.subscriberrelation === null
    ) {
      url = `${ip}/neweligibility/getcopaydetailsforothertype?claimid=${this.state.Eligibilityclaimid}&servicetypecodes=${this.state.servicecodededuct1}&relationToSubscriber=Self`;
    } else {
      url = `${ip}/neweligibility/getcopaydetailsforothertype?claimid=${this.state.Eligibilityclaimid}&servicetypecodes=${this.state.servicecodededuct1}&relationToSubscriber=Family`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.eligibility.length !== 0) {
          this.setState({
            coPaytype: res.data.eligibility[0].details[0].serviceTypes[0],
            coPaycode: res.data.eligibility[0].details[0].serviceTypeCodes[0],
            coPaytime: res.data.eligibility[0].details[0].timeQualifier
              ? res.data.eligibility[0].details[0].timeQualifier
              : "",
            benefitamountcoPay:
              res.data.eligibility[0].details[0].benefitAmount,
          });
        } else {
          this.setState({
            coPaytype: "-",
            coPaycode: "-",
            coPaytime: "-",
            benefitamountcoPay: "-",
          });
        }
        if (this.state.coPaytime === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            coPaytime: time1 + "~" + time2,
          });
        }
      })
      .catch((err) => {});
  };
  getLimitations = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (
      this.state.subscriberrelation === "Self" ||
      this.state.subscriberrelation === null
    ) {
      url = `${ip}/neweligibility/getlimitations?claimid=${this.state.Eligibilityclaimid}&relationToSubscriber=Self`;
    } else {
      url = `${ip}/neweligibility/getlimitations?claimid=${this.state.Eligibilityclaimid}&relationToSubscriber=Family`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          Limitservicetype: res.data.eligibility,
        });
      })
      .catch((err) => {});
  };
  getLimitationsDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (
      this.state.subscriberrelation === "Self" ||
      this.state.subscriberrelation === null
    ) {
      url = `${ip}/neweligibility/getlimitationsdetails?claimid=${this.state.Eligibilityclaimid}&relationToSubscriber=Self&limitation=${this.state.namelimit}`;
    } else {
      url = `${ip}/neweligibility/getlimitationsdetails?claimid=${this.state.Eligibilityclaimid}&relationToSubscriber=Family&limitation=${this.state.namelimit}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          totalvisit: res.data.eligibility[0].details[0].benefitQuantity,
          calyr: res.data.eligibility[0].details[0].timeQualifier,
          exceeded:
            res.data.eligibility[0].details[0].benefitsServiceDelivery[0]
              .timePeriodQualifierCode,
          notexc:
            res.data.eligibility[0].details[0].benefitsServiceDelivery[1]
              .timePeriodQualifierCode,
          desclimit:
            res.data.eligibility[0].details[0].additionalInformation[0]
              .description,
        });
        if (this.state.calyr === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            calyr: time1 + "~" + time2,
          });
        }
      })
      .catch((err) => {});
  };
  getInNetworkDeductibleInfo = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(
        `${ip}/neweligibility/getnetworkdetails?claimid=${this.state.Eligibilityclaimid}&serviceTypes=${this.state.typevalue}&network=inplannetwork&name=Deductible`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.eligibility.length !== 0) {
          this.setState({
            coverageLeveldeduct:
              res.data.eligibility[0].details[0].coverageLevelCode,
            benefitamtdeduct: res.data.eligibility[0].details[0].benefitAmount,
            codededuct: res.data.eligibility[0].details[0].serviceTypeCodes[0],
            timededuct: res.data.eligibility[0].details[0].timeQualifier,
            timededuct1 : res.data.eligibility[0].details[1].timeQualifier

          });
        } else {
          this.setState({
            coverageLeveldeduct: "-",
            benefitamtdeduct: 0,
            codededuct: "-",
            timededuct: "-",
            timededuct1:"-"
          });
        }
        if (this.state.timededuct === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            timededuct: time1 + "~" + time2,
          });
        }
        if(this.state.timededuct1 === "Remaining"){
          this.setState({
            remainingqual: res.data.eligibility[0].details[1].timeQualifier,
            remainingdeduct : res.data.eligibility[0].details[1].benefitAmount
          })
        }
        else{
          this.setState({
            remainingqual: "",
            remainingdeduct : ""
          })
        }
      })
      .catch((err) => {});
  };
  getInNetworkCoInsInfo = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(
        `${ip}/neweligibility/getnetworkdetails?claimid=${this.state.Eligibilityclaimid}&serviceTypes=${this.state.typevalue}&network=inplannetwork&name=Co-Insurance`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log("benefit perc", res.data.eligibility[0].details[0].benefitPercent)
        if (res.data.eligibility.length !== 0) {
          this.setState({
            coverageLevelcoins:
              res.data.eligibility[0].details[0].coverageLevelCode,
            benefitamtcoins: res.data.eligibility[0].details[0].benefitAmount,
            codecoins: res.data.eligibility[0].details[0].serviceTypeCodes[0],
            timecoins: res.data.eligibility[0].details[0].timeQualifier,
            benefitperccoins:(res.data.eligibility[0].details[0].benefitPercent)* 100
          });
        } else {
          this.setState({
            coverageLevelcoins: "-",
            benefitamtcoins: 0,
            codecoins: "-",
            timecoins: "-",
            benefitperccoins:"0"
          });
        }
        if (this.state.timecoins === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            timecoins: time1 + "~" + time2,
          });
        }
      })
      .catch((err) => {});
  };
  getInNetworkCoPayInfo = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(
        `${ip}/neweligibility/getnetworkdetails?claimid=${this.state.Eligibilityclaimid}&serviceTypes=${this.state.typevalue}&network=inplannetwork&name=Co-Payment`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log("co pay percent", res.data.eligibility[0].details[0].benefitPercent)
        if (res.data.eligibility.length !== 0) {
          this.setState({
            coverageLevelcopay:
              res.data.eligibility[0].details[0].coverageLevelCode,
            benefitamtcopay: res.data.eligibility[0].details[0].benefitAmount,
            codecopay: res.data.eligibility[0].details[0].serviceTypeCodes[0],
            timecopay: res.data.eligibility[0].details[0].timeQualifier,
            benefitperccopay:(res.data.eligibility[0].details[0].benefitPercent)*100
          });
        } else {
          this.setState({
            coverageLevelcopay: "-",
            benefitamtcopay: 0,
            codecopay: "-",
            timecopay: "-",
            benefitperccopay:"0"
          });
        }
        if (this.state.timecopay === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            timecopay: time1 + "~" + time2,
          });
        }
      })
      .catch((err) => {});
  };
  getOutNetworkDeductibleInfo = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(
        `${ip}/neweligibility/getnetworkdetails?claimid=${this.state.Eligibilityclaimid}&serviceTypes=${this.state.typevalue}&network=outplannetwork&name=Deductible`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.eligibility.length !== 0) {
          this.setState({
            coverageLeveldeductout:
              res.data.eligibility[0].details[0].coverageLevelCode,
            benefitamtdeductout:
              res.data.eligibility[0].details[0].benefitAmount,
            codedeductout:
              res.data.eligibility[0].details[0].serviceTypeCodes[0],
            timedeductout: res.data.eligibility[0].details[0].timeQualifier,
            timedeductout1 : res.data.eligibility[0].details[1].timeQualifier

          });
        } else {
          this.setState({
            coverageLeveldeductout: "-",
            benefitamtdeductout: 0,
            codedeductout: "-",
            timedeductout: "-",
            timedeductout1:"-"
          });
        }
        if (this.state.timedeductout === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            timedeductout: time1 + "~" + time2,
          });
        }
        if(this.state.timedeductout1 === "Remaining"){
          this.setState({
            remainingqual1: res.data.eligibility[0].details[1].timeQualifier,
            remainingdeduct1 : res.data.eligibility[0].details[1].benefitAmount
          })
        }
        else{
          this.setState({
            remainingqual1: "",
            remainingdeduct1 : ""
          })
        }
      })
      .catch((err) => {});
  };
  getOutNetworkCoInsInfo = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(
        `${ip}/neweligibility/getnetworkdetails?claimid=${this.state.Eligibilityclaimid}&serviceTypes=${this.state.typevalue}&network=outplannetwork&name=Co-Insurance`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log("co ins percent out", res.data.eligibility[0].details[0].benefitPercent)

        if (res.data.eligibility.length !== 0) {
          this.setState({
            coverageLevelcoinsout:
              res.data.eligibility[0].details[0].coverageLevelCode,
            benefitamtcoinsout:
              res.data.eligibility[0].details[0].benefitAmount,
            codecoinsout:
              res.data.eligibility[0].details[0].serviceTypeCodes[0],
            timecoinsout: res.data.eligibility[0].details[0].timeQualifier,
            benefitperccoins1: 
          (res.data.eligibility[0].details[0].benefitPercent)*100

          });
        } else {
          this.setState({
            coverageLevelcoinsout: "-",
            benefitamtcoinsout: 0,
            codecoinsout: "-",
            timecoinsout: "-",
            benefitperccoins1:"0"
          });
        }
        if (this.state.timecoinsout === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            timecoinsout: time1 + "~" + time2,
          });
        }
      })
      .catch((err) => {});
  };
  getOutNetworkCoPayInfo = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(
        `${ip}/neweligibility/getnetworkdetails?claimid=${this.state.Eligibilityclaimid}&serviceTypes=${this.state.typevalue}&network=outplannetwork&name=Co-Payment`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log("co pay percent out", res.data.eligibility[0].details[0].benefitPercent)

        if (res.data.eligibility.length !== 0) {
          this.setState({
            coverageLevelcopayout:
              res.data.eligibility[0].details[0].coverageLevelCode,
            benefitamtcopayout:
              res.data.eligibility[0].details[0].benefitAmount,
            codecopayout:
              res.data.eligibility[0].details[0].serviceTypeCodes[0],
            timecopayout: res.data.eligibility[0].details[0].timeQualifier,
            benefitperccopay1: 
            (res.data.eligibility[0].details[0].benefitPercent)*100
          });
        } else {
          this.setState({
            coverageLevelcopayout: "-",
            benefitamtcopayout: 0,
            codecopayout: "-",
            timecopayout: "-",
            benefitperccopay1:"0"
          });
        }
        if (this.state.timecopayout === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            timecopayout: time1 + "~" + time2,
          });
        }
      })
      .catch((err) => {});
  };
  getpasteliDeductibleself = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (
      this.state.pasteligibilityrelationtosubscriber === "Self" ||
      this.state.pasteligibilityrelationtosubscriber === null
    ) {
      url = `${ip}/neweligibility/getdeductibledetailsfor30?id=${this.state.objectID}&servicetypecodes=${this.state.pasteliservicecodededuct1}&relationToSubscriber=Self`;
    } else if (this.state.pasteligibilityrelationtosubscriber === "Family") {
      url = `${ip}/neweligibility/getdeductibledetailsfor30?id=${this.state.objectID}&servicetypecodes=${this.state.pasteliservicecodededuct1}&relationToSubscriber=Family`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pasteliDeductiblevalue:
            res.data.eligibility.details[0].serviceTypes[0],
          pasteliDeductiblevaluecode:
            res.data.eligibility.details[0].serviceTypeCodes[0],
          pastelitimequal1: res.data.eligibility.details[0].timeQualifier,
          pastelibenefitamount: res.data.eligibility.details[0].benefitAmount,
        });
        if (this.state.pastelitimequal1 === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            pastelitimequal1: time1 + "~" + time2,
          });
        }
        if (this.state.pastelitimequal1 === "Remaining") {
          this.setState({
            remainingamt: res.data.eligibility.details[0].benefitAmount,
          });
        }
      })
      .catch((err) => {});
  };
  getpasteliDeductibleothers = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (
      this.state.pasteligibilityrelationtosubscriber === "Self" ||
      this.state.pasteligibilityrelationtosubscriber === null
    ) {
      url = `${ip}/neweligibility/getdeductibledetailsforothertype?id=${this.state.objectID}&servicetypecodes=${this.state.pasteliservicecodevalues2}&relationToSubscriber=Self`;
    } else if (this.state.pasteligibilityrelationtosubscriber === "Family") {
      url = `${ip}/neweligibility/getdeductibledetailsforothertype?id=${this.state.objectID}&servicetypecodes=${this.state.pasteliservicecodevalues2}&relationToSubscriber=Family`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        res.data.eligibility.map((item1) =>
          this.state.pastelivalueothers.push(item1.serviceTypes)
        );
        let data2 = res.data.eligibility.map((item2) => {
          return item2.serviceTypeCodes;
        });

        let data3 = res.data.eligibility.map((item3) => {
          if (item3.timeQualifier === "Calendar Year") {
            let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
            let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
            return time1 + "~" + time2;
          }
          return item3.timeQualifier;
        });
        let dataremaining = res.data.eligibility.map((itemval) => {
          if (itemval.timeQualifier === "Remaining") {
            return itemval.benefitAmount;
          }
        });
        let data4 = res.data.eligibility.map((item4) => {
          return item4.benefitAmount;
        });

        this.setState({
          pasteliDeductiblevalueothers: this.state.pastelivalueothers,
          pasteliDeductiblevaluecodeothers: data2,
          pastelitimequal1others: data3,
          pastelibenefitamountothers: data4,
          pasteliremainingamountothers: dataremaining,
        });
      })
      .catch((err) => {});
  };
  getpasteliCoInsself = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (
      this.state.pasteligibilityrelationtosubscriber === "Self" ||
      this.state.pasteligibilityrelationtosubscriber === null
    ) {
      url = `${ip}/neweligibility/getcoinsurancedetailsforothertype?id=${this.state.objectID}&servicetypecodes=${this.state.pasteliservicecodededuct1}&relationToSubscriber=Self`;
    } else if (this.state.pasteligibilityrelationtosubscriber === "Family") {
      url = `${ip}/neweligibility/getcoinsurancedetailsforothertype?id=${this.state.objectID}&servicetypecodes=${this.state.pasteliservicecodededuct1}&relationToSubscriber=Family`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.eligibility.length !== 0) {
          this.setState({
            pastelicoservicetype:
              res.data.eligibility.details[0].serviceTypes[0],
            pastelicoservicecode:
              res.data.eligibility.details[0].serviceTypeCodes[0],
            pastelicotimequal: res.data.eligibility.details[0].timeQualifier,
            pastelibenefitamountco:
              res.data.eligibility.details[0].benefitPercent,
          });
        } else {
          this.setState({
            pastelicoservicetype: "-",
            pastelicoservicecode: "-",
            pastelicotimequal: "-",
          });
        }
        if (this.state.pastelicotimequal === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            pastelicotimequal: time1 + "~" + time2,
          });
        } else if (this.state.pastelicotimequal === undefined) {
          this.setState({ pastelicotimequal: " " });
        }
      })
      .catch((err) => {});
  };
  getpasteliCoPaySelf = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (
      this.state.pasteligibilityrelationtosubscriber === "Self" ||
      this.state.pasteligibilityrelationtosubscriber === null
    ) {
      url = `${ip}/neweligibility/getcopaydetailsforothertype?id=${this.state.objectID}&servicetypecodes=${this.state.pasteliservicecodededuct1}&relationToSubscriber=Self`;
    } else if (this.state.pasteligibilityrelationtosubscriber === "Family") {
      url = `${ip}/neweligibility/getcopaydetailsforothertype?id=${this.state.objectID}&servicetypecodes=${this.state.pasteliservicecodededuct1}&relationToSubscriber=Family`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.eligibility.length !== 0) {
          this.setState({
            pastelicoPaytype: res.data.eligibility.details[0].serviceTypes[0],
            pastelicoPaycode:
              res.data.eligibility.details[0].serviceTypeCodes[0],
            pastelicoPaytime: res.data.eligibility.details[0].timeQualifier
              ? res.data.eligibility.details[0].timeQualifier
              : "",
            pastelibenefitamountcoPay:
              res.data.eligibility.details[0].benefitAmount,
          });
        } else {
          this.setState({
            pastelicoPaytype: "-",
            pastelicoPaycode: "-",
            pastelicoPaytime: "-",
            pastelibenefitamountcoPay: "-",
          });
        }
        if (this.state.pastelicoPaytime === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            pastelicoPaytime: time1 + "~" + time2,
          });
        }
      })
      .catch((err) => {});
  };
  getpasteliLimitations = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (
      this.state.pasteligibilityrelationtosubscriber === "Self" ||
      this.state.pasteligibilityrelationtosubscriber === null
    ) {
      url = `${ip}/neweligibility/getlimitations?id=${this.state.objectID}&relationToSubscriber=Self`;
    } else  {
      url = `${ip}/neweligibility/getlimitations?id=${this.state.objectID}&relationToSubscriber=Family`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pasteliLimitservicetype: res.data.eligibility,
        });
      })
      .catch((err) => {});
  };
  getpasteliLimitationsDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (
      this.state.pasteligibilityrelationtosubscriber === "Self" ||
      this.state.pasteligibilityrelationtosubscriber === null
    ) {
      url = `${ip}/neweligibility/getlimitationsdetails?id=${this.state.objectID}&relationToSubscriber=Self&limitation=${this.state.namelimit1}`;
    } else {
      url = `${ip}/neweligibility/getlimitationsdetails?id=${this.state.objectID}&relationToSubscriber=Family&limitation=${this.state.namelimit1}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.eligibility[0].details[0].length !== 0) {
          this.setState({
            pastelitotalvisit:
              res.data.eligibility[0].details[0].benefitQuantity,
            calyr1: res.data.eligibility[0].details[0].timeQualifier,
            exceeded1:
              res.data.eligibility[0].details[0].benefitsServiceDelivery[0]
                .timePeriodQualifierCode,
            notexc1:
              res.data.eligibility[0].details[0].benefitsServiceDelivery[1]
                .timePeriodQualifierCode,
            desclimit1:
              res.data.eligibility[0].details[0].additionalInformation[0]
                .description,
          });
        } else {
          this.setState({
            pastelitotalvisit: "-",
            calyr1: "-",
            exceeded1: "-",
            notexc1: "-",
            desclimit1: "-",
          });
        }
        if (this.state.calyr1 === "Calendar Year") {
          let time1 = moment().startOf("year").format("MMM YYYY-MM-DD");
          let time2 = moment().endOf("year").format("MMM YYYY-MM-DD");
          this.setState({
            calyr1: time1 + "~" + time2,
          });
        }
      })
      .catch((err) => {});
  };
  getpastfamdetails = async () => {
    // api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/neweligibility/all?id=${this.state.objectID}`, {
        headers: headers,
      })
      .then((res) => {
        console.log("res fam", res.data);
        //!=self patient firstname
        if (res.data.eligibility.data.dependents[0].firstName !== "") {
          this.setState({
            pasteliothersfirtsname:
              res.data.eligibility.data.dependents[0].firstName,
          });
        } else {
          this.setState({
            pasteliothersfirtsname: "No data",
          });
        }
        //!=self patient last name
        if (res.data.eligibility.data.dependents[0].lastName !== 0) {
          this.setState({
            pasteliotherslastname:
              res.data.eligibility.data.dependents[0].lastName,
          });
        } else {
          this.setState({
            pasteliotherslastname: "No data",
          });
        }
        //!self patient entity type
        if (res.data.eligibility.data.dependents[0].entityType !== 0) {
          this.setState({
            pasteliothersType:
              res.data.eligibility.data.dependents[0].entityType,
          });
        } else {
          this.setState({
            pasteliothersType: "No data",
          });
        }
        //!self patient address
        if (res.data.eligibility.data.dependents[0].address.length !== 0) {
          let pastelipostalcode1 =
            res.data.eligibility.data.dependents[0].address.postalCode.slice(
              0,
              5
            );
          let pastelipostalcode2 =
            res.data.eligibility.data.dependents[0].address.postalCode.slice(5);
          this.setState({
            pasteliothersAddress:
              res.data.eligibility.data.dependents[0].address.address1,
            pasteliothersCity:
              res.data.eligibility.data.dependents[0].address.city,
            pasteliothersState:
              res.data.eligibility.data.dependents[0].address.state,
            pasteliothersPostalcode:
              pastelipostalcode1 + "-" + pastelipostalcode2,
          });
        } else {
          this.setState({
            pasteliothersAddress: "-",
            pasteliothersCity: "-",
            pasteliothersState: "-",
            pasteliothersPostalcode: "-",
          });
        }
        //!self patient dob
        if (res.data.eligibility.data.dependents[0].dateOfBirth !== 0) {
          this.setState({
            pasteliothersdob:
              res.data.eligibility.data.dependents[0].dateOfBirth,
          });
        } else {
          this.setState({
            pasteliothersdob: "No data",
          });
        }
        //!self patient gender
        if (res.data.eligibility.data.dependents[0].gender !== 0) {
          this.setState({
            pasteliothersgender: res.data.eligibility.data.dependents[0].gender,
          });
        } else {
          this.setState({
            pasteliothersgender: "No data",
          });
        }
        //!self patient relation to subscriber
        if (
          res.data.eligibility.data.dependents[0].relationToSubscriber !== 0
        ) {
          this.setState({
            pasteliothersrelation:
              res.data.eligibility.data.dependents[0].relationToSubscriber,
          });
        } else {
          this.setState({
            pasteliothersrelation: "No data",
          });
        }
        //!self patient service
        if (res.data.eligibility.data.tradingPartnerServiceId !== 0) {
          this.setState({
            pasteliothersservicename:
              res.data.eligibility.data.tradingPartnerServiceId,
          });
        } else {
          this.setState({
            pasteliothersservicename: "No data",
          });
        }
      })

      .catch((err) => {});
  };
  //validation for modal
  formValidate = () => {
    let hasErr = true;
    let payernamevalueErr = "";
    let typereqErr = "";

    if (this.state.inputValue === "") {
      payernamevalueErr = "This field is required";
      hasErr = false;
    }

    if (this.state.typereq === "") {
      typereqErr = "This field is required";
      hasErr = false;
    }

    this.setState({ payernamevalueErr, typereqErr });

    return hasErr;
  };

  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 5000);
    if (this.state.modulename === "Allocation")
      this.setState({ redirecturl: "/allocation-buckets" });
    if (this.state.modulename === "Eligibility Tracker")
      this.setState({ redirecturl: "/Eligibility/OutcomeData" });
    else if (this.state.modulename === "Denial Management")
      this.setState({ redirecturl: "/denial-management" });
    else if (this.state.modulename === "Claim Status Management")
      this.setState({ redirecturl: "/claim-management" });
    else if (this.state.modulename === "Web Claims")
      this.setState({ redirecturl: "/claims/Web" });
    else if (this.state.modulename === "IVR Claims")
      this.setState({ redirecturl: "/claims/IVR" });
    else if (this.state.modulename === "Manual Claims")
      this.setState({ redirecturl: "/claims/Manual" });
    else if (this.state.modulename === "Rejection")
      this.setState({ redirecturl: "/allocate/rejection" });
    else if (this.state.modulename === "UnAllocated")
      this.setState({ redirecturl: "./Allocation/UnAllocated" });
    else if (this.state.modulename === "Allocated")
      this.setState({ redirecturl: "./Allocation/Allocated" });
    this.getDetails();
    this.getCoInsself();
    this.getCoPaySelf();
    this.getDeductibleself();
    this.getDeductibleothers();
    this.getLimitations();
    this.getServiceTypesname();
    this.getLimitationsDetails();
    this.getserviceid();
    this.getpasteligibility();
    this.getmodalselfdetails();
    this.getpasteliCoInsself();
    this.getpasteliCoPaySelf();
    this.getpasteliDeductibleself();
    this.getpasteliDeductibleothers();
    this.getpasteliLimitations();
    this.getpasteliLimitationsDetails();
  }
  togglereport = () => {
    this.setState({ reportmodal: !this.state.reportmodal });
  };
  getserviceid = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = await axios
      .get(`${ip}/servicetype/all`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          ServiceTypearray: res.data.ServiceType,
          loadingservicetype: false,
        });
      })
      .catch((err) => {});
  };

  getmodalselfdetails = async () => {
    // api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/neweligibility/all?id=${this.state.objectID}`, {
        headers: headers,
      })
      .then((res) => {
        //patient details firstname
        if (res.data.eligibility.data.subscriber.firstName !== "") {
          this.setState({
            pasteligibilitySubspatientfirstname:
              res.data.eligibility.data.subscriber.firstName,
          });
        } else {
          this.setState({
            pasteligibilitySubspatientfirstname: "No Data",
          });
        }
        if (res.data.eligibility.PatientFirstName !== "") {
          this.setState({
            pasteligibilitypatientfirstname:
              res.data.eligibility.PatientFirstName,
          });
        } else {
          this.setState({
            pasteligibilitypatientfirstname: "No Data",
          });
        }
        //patient details lastname
        if (res.data.eligibility.data.subscriber.lastName !== 0) {
          this.setState({
            pasteligibilitySubspatientlastname:
              res.data.eligibility.data.subscriber.lastName,
          });
        } else {
          this.setState({
            pasteligibilitySubspatientlastname: "No data",
          });
        }
        //patient details type
        if (res.data.eligibility.data.subscriber.entityIdentifier !== 0) {
          this.setState({
            pasteligibilitypatienttype:
              res.data.eligibility.data.subscriber.entityIdentifier,
          });
        } else {
          this.setState({
            pasteligibilitypatienttype: "No data",
          });
        }
        //patient details member id
        if (res.data.eligibility.data.subscriber.memberId !== 0) {
          this.setState({
            pasteligibilitymemberid:
              res.data.eligibility.data.subscriber.memberId,
          });
        } else {
          this.setState({
            pasteligibilitymemberid: "No data",
          });
        }
        //patient details group number
        if (res.data.eligibility.data.subscriber.groupNumber !== 0) {
          this.setState({
            pasteligibilitygroup:
              res.data.eligibility.data.subscriber.groupNumber,
          });
        } else {
          this.setState({
            pasteligibilitygroup: "No data",
          });
        }
        //patient details address
        if (res.data.eligibility.data.subscriber.address.length !== 0) {
          let postalcode3;
          let postalcode4;
          if (
            res.data.eligibility.data.subscriber.address.postalCode.length === 9
          ) {
            postalcode3 =
              res.data.eligibility.data.subscriber.address.postalCode.slice(
                0,
                5
              );
            postalcode4 =
              res.data.eligibility.data.subscriber.address.postalCode.slice(5);
          } else {
            postalcode3 =
              res.data.eligibility.data.subscriber.address.postalCode.slice(
                0,
                5
              );
          }
          this.setState({
            pasteligibilitysubsaddress:
              res.data.eligibility.data.subscriber.address.address1,
            pasteligibilitycity:
              res.data.eligibility.data.subscriber.address.city,
            pasteligibilitystate:
              res.data.eligibility.data.subscriber.address.state,

            pasteligibilitypostalcode: postalcode3
              ? postalcode3
              : "" + "-" + postalcode4
              ? postalcode4
              : "",
          });
        } else {
          this.setState({
            pasteligibilitysubsaddress: "-",
            pasteligibilitycity: "-",
            pasteligibilitystate: "-",
            pasteligibilitypostalcode: "-",
          });
        }
        //patient details dob
        if (res.data.eligibility.data.subscriber.dateOfBirth !== 0) {
          this.setState({
            pasteligibilitydob:
              res.data.eligibility.data.subscriber.dateOfBirth,
          });
        } else {
          this.setState({
            pasteligibilitydob: "No data",
          });
        }
        //patient details gender
        if (res.data.eligibility.data.subscriber.gender !== 0) {
          this.setState({
            pasteligibilitygender: res.data.eligibility.data.subscriber.gender,
          });
        } else {
          this.setState({
            pasteligibilitygender: "No data",
          });
        }
        //patient details relation to subscriber
        if (
          res.data.eligibility.data.subscriber.relationToSubscriber !==
          undefined
        ) {
          this.setState({
            pasteligibilityrelationtosubscriber: res.data.eligibility.data
              .subscriber.relationToSubscriber
              ? res.data.eligibility.data.subscriber.relationToSubscriber
              : "Self",
          },
          () => {
            this.getpasteliDeductibleself();
            this.getpasteliDeductibleothers();
            this.getpasteliCoInsself();
            this.getpasteliCoPaySelf();
            this.getpasteliLimitations();
            this.getpasteliLimitationsDetails();
          }
        )
        } else if (
          res.data.eligibility.data.subscriber.relationToSubscriber ===
          undefined
        ) {
          this.setState(
            {
              pasteligibilityrelationtosubscriber: "Self",
            },
            () => {
              this.getpasteliDeductibleself();
              this.getpasteliDeductibleothers();
              this.getpasteliCoInsself();
              this.getpasteliCoPaySelf();
              this.getpasteliLimitations();
              this.getpasteliLimitationsDetails();
            }
          );
        }
        //patient details service
        if (res.data.eligibility.data.tradingPartnerServiceId !== 0) {
          this.setState({
            pasteligibilityservicecontroller:
              res.data.eligibility.data.tradingPartnerServiceId,
          });
        } else {
          this.setState({
            pasteligibilityservicecontroller: "No data",
          });
        }
        //submitted submitter name
        if (res.data.eligibility.data.provider.providerName !== "") {
          this.setState({
            pasteligibilitysubmitter:
              res.data.eligibility.data.provider.providerName,
          });
        } else {
          this.setState({
            pasteligibilitysubmitter: "No data",
          });
        }
        //submitted submitter type
        if (res.data.eligibility.data.provider.entityIdentifier !== "") {
          this.setState({
            pasteligibilityservicetype:
              res.data.eligibility.data.provider.entityIdentifier,
          });
        } else {
          this.setState({
            pasteligibilityservicetype: "No data",
          });
        }
        //req service type
        if (res.data.eligibility.serviceType !== "") {
          this.setState({
            pasteligibilityreqservicetype: res.data.eligibility.serviceType,
          });
        } else {
          this.setState({
            pasteligibilityreqservicetype: "No Data",
          });
        }
        //submitted service provider
        if (res.data.eligibility.data.provider.npi !== 0) {
          this.setState({
            pasteligibilityserviceprovidernpi:
              res.data.eligibility.data.provider.npi,
          });
        } else {
          this.setState({
            pasteligibilityserviceprovidernpi: "No data",
          });
        }

        //plan status array extract of service type codes
        res.data.eligibility.data.planStatus.map((data) => {
          data.serviceTypeCodes.map((ele) => {
            this.state.pasteliservicetypecodearrayvalues.push(ele);
          });
          //plan details req plan status
          if (res.data.eligibility.data.planStatus.length !== 0) {
            this.setState({
              pasteligibilityplanNumber:
                res.data.eligibility.data.planStatus[0].status,
            });
          } else {
            this.setState({
              pasteligibilityplanNumber: "No Data",
            });
          }
          //plan details others plan status
          if (res.data.eligibility.data.planStatus.length !== 0) {
            this.setState({
              pasteligibilityplanNumberothers:
                res.data.eligibility.data.planStatus[0].status,
            });
          } else {
            this.setState({
              pasteligibilityplanNumberothers: "No Data",
            });
          }

          //plan details others plan code
          let plandetailsvalue1 = res.data.eligibility.data.planStatus.map(
            (data) => {
              return data.planDetails;
            }
          );
          this.setState({
            pasteligibilityplanDetails: plandetailsvalue1,
          });
        });

        //req service code
        if (res.data.eligibility.serviceCode !== "") {
          this.setState({
            pasteligibilityreqservicecode: res.data.eligibility.serviceCode,
          });
        } else {
          this.setState({
            pasteligibilityreqservicecode: "No data",
          });
        }
        //separate other service codes from the array
        for (
          let i = 0;
          i < this.state.pasteliservicetypecodearrayvalues.length;
          i++
        ) {
          if (
            this.state.pasteligibilityreqservicecode !==
            this.state.pasteliservicetypecodearrayvalues[i]
          ) {
            this.state.pasteliotherservicecodearray.push(
              this.state.pasteliservicetypecodearrayvalues[i]
            );
          }
        }

        //plan details auth value
        let authcb1 = res.data.eligibility.data.benefitsInformation.map(
          (data, index) => {
            if ("CB" === data.code) {
              return data.serviceTypeCodes;
            }
          }
        );
        if (this.state.pasteligibilityreqservicecode === authcb1) {
          this.setState({
            pasteligibilityAuthvalue:
              res.data.eligibility.data.benefitsInformation.authOrCertIndicator,
          });
        } else {
          this.setState({
            pasteligibilityAuthvalue: "No",
          });
        }
        //plan date information plan begin
        if (res.data.eligibility.data.planDateInformation.planBegin !== "") {
          this.setState({
            pasteligibilityplanbegin:
              res.data.eligibility.data.planDateInformation.planBegin,
          });
        } else {
          this.setState({
            pasteligibilityplanbegin: "No data",
          });
        }
        //plan date information plan end
        if (res.data.eligibility.data.planDateInformation.planEnd !== 0) {
          this.setState({
            pasteligibilityplanend:
              res.data.eligibility.data.planDateInformation.planEnd,
          });
        } else {
          this.setState({
            pasteligibilityplanend: "No data",
          });
        }
        //plan dete information eligibility begin
        if (
          res.data.eligibility.data.planDateInformation.eligibilityBegin !== 0
        ) {
          this.setState({
            pasteligibilitybegin:
              res.data.eligibility.data.planDateInformation.eligibilityBegin,
          });
        } else {
          this.setState({
            pasteligibilitybegin: "No data",
          });
        }

        //subscriber EntityIdentifier
        if (res.data.eligibility.data.subscriber.entityIdentifier !== 0) {
          this.setState({
            entityIdentifier:
              res.data.eligibility.data.subscriber.entityIdentifier,
          });
        } else {
          this.setState({
            entityIdentifier: "No data",
          });
        }
        //subscriber entityType
        if (res.data.eligibility.data.subscriber.entityType !== 0) {
          this.setState({
            pasteligibilityentityType:
              res.data.eligibility.data.subscriber.entityType,
          });
        } else {
          this.setState({
            pasteligibilityentityType: "No data",
          });
        }
        //plan information group number
        if (res.data.eligibility.data.planInformation.groupNumber !== "") {
          this.setState({
            pasteligibilitygroupnumber:
              res.data.eligibility.data.planInformation.groupNumber,
          });
        } else {
          this.setState({
            pasteligibilitygroupnumber: "No data",
          });
        }
        //plan information group description
        if (res.data.eligibility.data.planInformation.groupDescription !== "") {
          this.setState({
            pasteligibilitygroupnumberdesc:
              res.data.eligibility.data.planInformation.groupDescription,
          });
        } else {
          this.setState({
            pasteligibilitygroupnumberdesc: "No data",
          });
        }
        //get service types by other service codes
        if (this.state.pasteliotherservicecodearray.length !== 0) {
          let data;
          data = JSON.stringify(this.state.pasteliotherservicecodearray);
          data = data.split(",");
          this.setState(
            {
              pasteliservicecodevalues1: data,
            },
            () => {
              this.getpasteliServiceTypes();
            }
          );
        } else {
          this.setState({
            pasteliservicecodevalues1: "No data",
          });
        }

        //get service types by service codes
        if (
          this.state.pasteliotherservicecodearray.length !== 0 ||
          this.state.pasteligibilityreqservicetype !== ""
        ) {
          let data;
          data = JSON.stringify(this.state.pasteliotherservicecodearray);
          data = data.split(",");
          this.setState(
            {
              pasteliservicecodevalues2: data,
              pasteliservicecodededuct1:
                this.state.pasteligibilityreqservicetype,
            },
            () => {
              this.getpasteliServiceTypes1();
              this.getpasteliDeductibleself();
              this.getpasteliDeductibleothers();
              this.getpasteliCoInsself();
              this.getpasteliCoPaySelf();
              // this.getpasteliLimitations();
              // this.getpasteliLimitationsDetails();
            }
          );
        } else {
          this.setState({
            pasteliservicecodevalues2: "No data",
          });
        }

        //Pharmacy plan
        let plan1 = res.data.eligibility.data.benefitsInformation.map(
          (data, index) => {
            if ("Pharmacy" === data.serviceTypes[0]) {
              return data.planCoverage;
            }
          }
        );
        let entityType = res.data.eligibility.data.benefitsInformation.map(
          (data, index) => {
            if ("Pharmacy" === data.serviceTypes[0]) {
              return data.benefitsRelatedEntity.entityType;
            }
          }
        );
        let telephone1 = res.data.eligibility.data.benefitsInformation.map(
          (data, index) => {
            if ("Pharmacy" === data.serviceTypes[0]) {
              return data.benefitsRelatedEntity.contactInformation.contacts[0]
                .communicationNumber;
            }
          }
        );
        let namepharmacy = res.data.eligibility.data.benefitsInformation.map(
          (data, index) => {
            if ("Pharmacy" === data.serviceTypes[0]) {
              return data.name;
            }
          }
        );
        this.setState({
          pasteligibilityplan: plan1,
          pasteligibilityentitytype: entityType,
          pasteligibilityaddress: telephone1,
          pasteligibilityname: namepharmacy,
        });
      })
      .catch((err) => {});
  };

  render() {
    const { tabindex } = this.state;
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
      },
    }));

    const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
        width: "50%",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
        width: "50%",
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:last-child th, &:last-child tr": {
        border: "1px solid rgba(224, 224, 224, 1)",
        //border:0,
      },
    }));
    const StyledTableRow1 = styled(TableRow)(({ theme }) => ({
      "&:nth-child(even) th, &:nth-child(even) td, &:nth-child(odd) th, &:nth-child(odd) td":
        {
          border: "1px solid rgba(224, 224, 224, 1)",
        },
    }));
    return (
      <div>
      <Newsidebar name="Eligibility" />
      <div
        className="align-row"
        style={{ overflow: "hidden", fontSize: "13px" }}
      >
        <div className="align-column">
          <br></br>
          <div
            style={{ textAlign: "left", marginLeft: "2%", fontSize: "13px" }}
          >
            <p>
              <Link
                to={this.state.redirecturl}
                style={{ textDecoration: "none", color: "black" }}
              >
                {this.state.modulename}
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>
              Eligibility Report
            </p>
          </div>
          <br></br>
          <ToastContainer></ToastContainer>
          <div>
            {" "}
            {this.state.errornotify === true ? (
              <p style={{ fontSize: "13px", color: "red" }}>
                Error claim ~ {this.state.errornotifyvalue}
              </p>
            ) : null}
            {this.state.planServiceType == 30 ? (
              <button
                className="e-button"
                style={{
                  float: "right",
                  marginRight: "5%",
                  backgroundColor: "whitesmoke",
                  color: "black",
                  borderRadius: "20px",
                  border: "2px solid green",
                  padding: "12px 14px",
                  marginTop: "-5%",
                }}
                onClick={(e) => {
                  this.toggle3();
                }}
              >
                Eligibility
              </button>
            ) : (
              <button
                style={{
                  float: "right",
                  marginRight: "5%",
                  border: "2px solid green",
                  backgroundColor: "whitesmoke",
                  color: "black",
                  borderRadius: "20px",
                  padding: "12px 14px",
                  marginTop: "-5%",
                }}
                onClick={(e) => {
                  this.toggle3();
                }}
              >
                Eligibility
              </button>
            )}
          </div>

          {/* <div>
            {" "}
            <p
              style={{
                float: "left",
                paddingLeft: "70%",
                fontWeight: "400",
                marginLeft: "10%",
              }}
            >
              Total Amount:
            </p>{" "}
            &nbsp; */}
          {/* <p style={{ float: "left", paddingLeft: "1%", fontWeight: "600" }}>
              $ 1,500.00
            </p> */}
          {/* </div> */}

          <div>
            <h3 align="left" style={{ marginLeft: "2%" }}>
              <b>Eligibility Report</b>
            </h3>{" "}
            <br />
            <br />
            <Row>
              <Col style={{ width: "50%", marginLeft: "4%" }}>
                {/* <Col> Timely Filling Limited (Fast Approaching)</Col>
              <Col> </Col> */}
                <Table className="table table-striped table-bordered">
                  <thead
                    rowSpan="1"
                    style={{
                      backgroundColor: "rgb(211,195,241,1)",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <tr
                      rowSpan="1"
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <th rowSpan="1">Patient Details</th>
                      <th></th>
                    </tr>
                  </thead>

                  {this.state.subscriberrelation === null ||
                  this.state.subscriberrelation === "Self" ? (
                    <tbody>
                      <tr>
                        <td>Patient First Name</td>

                        <td>{this.state.patientfirstname}</td>
                      </tr>

                      <tr>
                        <td>Patient Last Name</td>

                        <td>{this.state.subscriberLastname} </td>
                      </tr>
                      <tr>
                        <td>Type</td>

                        <td>{this.state.subscriberType}</td>
                      </tr>
                      <tr>
                        <td>Member ID</td>

                        <td>{this.state.subscriberMemberId}</td>
                      </tr>
                      <tr>
                        <td>Group</td>

                        <td>{this.state.subscribergroup}</td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        {this.state.subscriberAddress ||
                        this.state.subscriberCity ||
                        this.state.subscriberState ||
                        this.state.subscriberPostalcode ? (
                          <td>
                            {this.state.subscriberAddress +
                              ",  " +
                              this.state.subscriberCity +
                              ",  " +
                              this.state.subscriberState +
                              ",  " +
                              this.state.subscriberPostalcode}
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                      <tr>
                        <td>DOB</td>

                        <td>
                          {this.state.subscriberdob
                            ? moment(this.state.subscriberdob).format(
                                "MM/DD/YYYY"
                              )
                            : "-"}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>Gender</td>

                        <td>{this.state.subscribergender}</td>
                      </tr>
                      <tr>
                        <td>Relation To Subscriber</td>

                        <td>
                          {this.state.subscriberrelation
                            ? this.state.subscriberrelation
                            : "Self"}
                        </td>
                      </tr>
                      <tr>
                        <td>Service Enrollment</td>

                        <td>{this.state.Patientservicename}</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td>Patient First Name</td>

                        <td>{this.state.othersfirtsname}</td>
                      </tr>

                      <tr>
                        <td>Patient Last Name</td>

                        <td>{this.state.otherslastname} </td>
                      </tr>
                      <tr>
                        <td>Type</td>

                        <td>{this.state.othersType}</td>
                      </tr>
                      <tr>
                        <td>Member ID</td>

                        <td>{this.state.subscriberMemberId}</td>
                      </tr>
                      <tr>
                        <td>Group</td>

                        <td>{this.state.subscribergroup}</td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        {this.state.othersAddress ||
                        this.state.othersCity ||
                        this.state.othersState ||
                        this.state.othersPostalcode ? (
                          <td>
                            {this.state.othersAddress +
                              ",  " +
                              this.state.othersCity +
                              ",  " +
                              this.state.othersState +
                              ",  " +
                              this.state.othersPostalcode}
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                      <tr>
                        <td>DOB</td>

                        <td>
                          {this.state.othersdob
                            ? moment(this.state.othersdob).format("MM/DD/YYYY")
                            : "-"}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>Gender</td>

                        <td>{this.state.othersgender}</td>
                      </tr>
                      <tr>
                        <td>Relation To Subscriber</td>

                        <td>{this.state.othersrelation}</td>
                      </tr>
                      <tr>
                        <td>Service Enrollment</td>

                        <td>{this.state.othersservicename}</td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </Col>

              <Col
                style={{ width: "40%", marginLeft: "2%", marginRight: "2%" }}
              >
                {/* <Col> Timely Filling Limited (Fast Approaching)</Col>
              <Col> </Col> */}
                <Table className="table table-striped table-bordered">
                  <thead
                    rowSpan="1"
                    style={{
                      backgroundColor: "rgb(211,195,241,1)",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <tr
                      rowSpan="1"
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <th rowSpan="1">Submitter</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Submitter</td>

                      <td> {this.state.submittername}</td>
                    </tr>

                    <tr>
                      <td>Submitter Type</td>

                      <td>{this.state.submittertype}</td>
                    </tr>
                    <tr>
                      <td> Service Provider#</td>

                      <td>{this.state.submitterNpi}</td>
                    </tr>
                  </tbody>
                </Table>
                <Table className="table table-striped table-bordered">
                  <thead
                    rowSpan="1"
                    style={{
                      backgroundColor: "rgb(211,195,241,1)",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <tr
                      rowSpan="1"
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <th rowSpan="1">Service Details</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Plan Status</td>
                      {this.state.planNumber === "Active Coverage" ? (
                        <td style={{ color: "green", fontWeight: "bolder" }}>
                          {this.state.planNumber}
                        </td>
                      ) : this.state.planNumber === "InActive Coverage" ? (
                        <td style={{ color: "red", fontWeight: "bolder" }}>
                          {this.state.planNumber}
                        </td>
                      ) : (
                        <td>{this.state.planNumber}</td>
                      )}
                    </tr>

                    <tr>
                      <td>Coverage Level</td>

                      <td>{this.state.entityIdentifier}</td>
                    </tr>
                    <tr>
                      <td>Service Type</td>

                      <td>
                        {this.state.reqServicecode
                          ? this.state.reqServicecode
                          : "No Data"}
                      </td>
                    </tr>
                    <tr>
                      <td>Plan</td>

                      <td>
                        {this.state.groupnumberdesc
                          ? this.state.groupnumberdesc
                          : "No Data"}
                      </td>
                    </tr>
                    <tr>
                      <td>Group or Policy Number</td>

                      <td>{this.state.groupnumberplan}</td>
                    </tr>
                    <tr>
                      <td>Auth Required</td>

                      <td>{this.state.Authvalue}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col
                style={{ width: "50%", marginLeft: "4%", marginRight: "4%" }}
              >
                {/* <Col> Timely Filling Limited (Fast Approaching)</Col>
              <Col> </Col> */}
                <Table className="table table-striped table-bordered">
                  <thead
                    rowSpan="1"
                    style={{
                      backgroundColor: "rgb(211,195,241,1)",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <tr
                      rowSpan="1"
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <th rowSpan="1">Plan status</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Coverage Status</td>

                      <td>
                        {this.state.planNumberothers === "Active Coverage" ? (
                          <td style={{ color: "green", fontWeight: "bolder" }}>
                            {this.state.planNumberothers}
                          </td>
                        ) : this.state.planNumberothers ===
                          "InActive Coverage" ? (
                          <td style={{ color: "red", fontWeight: "bolder" }}>
                            {this.state.planNumberothers}
                          </td>
                        ) : (
                          <td>{this.state.planNumberothers}</td>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Service Types</td>
                      <td>
                        {this.state.codevalue1
                          .slice(0, this.state.itemsToShow)
                          .map((data, i) => (
                            <li key={i}>{data[0] + "(" + data[1] + ")"}</li>
                          ))}

                        <button
                          style={{
                            marginRight: "20%",
                            padding: "5px",
                            marginLeft: "1%",
                            border: "none",
                            background: "#041c3c",
                            color: "white",
                            borderRadius: "22px",
                            marginTop: "1%",
                          }}
                        >
                          <a onClick={this.showMore}>
                            {this.state.expanded ? (
                              <span>
                                Show less &nbsp;&nbsp;
                                <AiOutlineMinusCircle />
                              </span>
                            ) : (
                              <span>
                                Show more&nbsp;&nbsp;
                                <AiOutlinePlusCircle />
                              </span>
                            )}
                          </a>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>planDetails</td>

                      <td> {this.state.planStatusplanDetails}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col
                style={{ width: "50%", marginLeft: "4%", marginRight: "4%" }}
              >
                {/* <Col> Timely Filling Limited (Fast Approaching)</Col>
              <Col> </Col> */}
                <Table className="table table-striped table-bordered">
                  <thead
                    rowSpan="1"
                    style={{
                      backgroundColor: "rgb(211,195,241,1)",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <tr
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <th rowSpan="1"></th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Plan Effective Date</td>

                      <td>
                        {" "}
                        {this.state.planDateInformationplan
                          ? moment(this.state.planDateInformationplan).format(
                              "MM/DD/YYYY"
                            )
                          : this.state.planDateInformationplan}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span style={{ color: "blue" }}>Deductible</span>
                        <br />
                        {(this.state.Deductiblevalue ||
                          this.state.Deductiblevaluecode ||
                          this.state.timequal1) !== ""
                          ? this.state.Deductiblevalue +
                            "," +
                            "(" +
                            this.state.Deductiblevaluecode +
                            ")" +
                            "," +
                            this.state.timequal1
                          : this.state.Deductiblevalue +
                            this.state.Deductiblevaluecode +
                            this.state.timequal1}
                      </td>

                      <td>
                        {this.state.benefitamount
                          ?"$" + this.state.benefitamount
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td>Remaining</td>

                      <td>
                        {this.state.remainingamt
                          ?"$" + this.state.remainingamt
                          : "$" +"0"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col
                style={{ width: "50%", marginLeft: "4%", marginRight: "4%" }}
              >
                {/* <Col> Timely Filling Limited (Fast Approaching)</Col>
              <Col> </Col> */}
                <Table className="table table-striped table-bordered">
                  <thead
                    rowSpan="1"
                    style={{
                      backgroundColor: "rgb(211,195,241,1)",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <tr
                      rowSpan="1"
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <th rowSpan="1"></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <span style={{ color: "blue" }}>Deductible</span>
                        <br />
                        <td>
                          {this.state.Deductiblevalueothers.map(
                            (item1, index) => (
                              <li>{item1}</li>
                            )
                          )}
                        </td>
                        <td>
                          {this.state.Deductiblevaluecodeothers.map(
                            (item2, index) => (
                              <li style={{ listStyle: "none" }}>
                                {" "}
                                {"(" + item2 + ")"}{" "}
                              </li>
                            )
                          )}
                        </td>
                        <td>
                          {this.state.timequal1others.map((item3, index) => (
                            <li style={{ listStyle: "none" }}> {item3} </li>
                          ))}
                        </td>
                      </td>

                      <td>
                        <span style={{ color: "blue" }}>Benefit Amt</span>
                        <br />
                        {this.state.benefitamountothers.map((item4, index) => (
                          <li style={{ listStyle: "none" }}>{"$" +item4} </li>
                        ))}
                      </td>
                      <td>
                        <span style={{ color: "blue" }}>Remaining</span>
                        <br />
                        {this.state.remainingamountothers.map(
                          (item5, index) => (
                            <li style={{ listStyle: "none" }}>
                              {" "}
                              {item5 ? "$" + item5 : "$" +"0"}{" "}
                            </li>
                          )
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col
                style={{ width: "50%", marginLeft: "4%", marginRight: "4%" }}
              >
                {/* <Col> Timely Filling Limited (Fast Approaching)</Col>
              <Col> </Col> */}
                <Table className="table table-striped table-bordered">
                  <thead
                    rowSpan="1"
                    style={{
                      backgroundColor: "rgb(211,195,241,1)",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <tr
                      rowSpan="1"
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <th rowSpan="1"></th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <span style={{ color: "blue" }}>CO Insurance</span>
                        <br />
                        {this.state.coservicetype ||
                        this.state.coservicecode ||
                        this.state.cotimequal !== ""
                          ? this.state.coservicetype +
                            "(" +
                            this.state.coservicecode +
                            ")" +
                            "," +
                            this.state.cotimequal
                          : this.state.coservicetype +
                            this.state.coservicecode +
                            this.state.cotimequal}
                      </td>

                      <td>{this.state.benefitamountco ? "$" +this.state.benefitamountco : "$" +0}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col
                style={{ width: "50%", marginLeft: "4%", marginRight: "4%" }}
              >
                <Table className="table table-striped table-bordered">
                  <thead
                    rowSpan="1"
                    style={{
                      backgroundColor: "rgb(211,195,241,1)",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <tr
                      rowSpan="1"
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <th rowSpan="1"></th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <span style={{ color: "blue" }}>Co Pay</span>
                        <br />

                        {(this.state.coPaytype ||
                          this.state.coPaycode ||
                          this.state.coPaytime) !== ""
                          ? this.state.coPaytype
                            ? this.state.coPaytype
                            : "" + "," + this.state.coPaycode
                            ? this.state.coPaycode
                            : "" + "," + this.state.coPaytime
                            ? this.state.coPaytime
                            : ""
                          : this.state.coPaytype +
                            this.state.coPaycode +
                            this.state.coPaytime}
                      </td>

                      <td>
                        {this.state.benefitamountcoPay
                          ? (this.state.benefitamountcoPay * 100) + "%"
                          : "0%"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col
                style={{ width: "50%", marginLeft: "4%", marginRight: "4%" }}
              >
                {/* <Col> Timely Filling Limited (Fast Approaching)</Col>
              <Col> </Col> */}
                <Table className="table table-striped table-bordered">
                  <thead
                    rowSpan="1"
                    style={{
                      backgroundColor: "rgb(211,195,241,1)",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <tr
                      rowSpan="1"
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <th rowSpan="1"></th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <span style={{ color: "blue" }}>
                          Limitations Notcovered
                        </span>
                        <br />

                        {this.state.Limitservicetype.map((data, i) => (
                          <li
                            key={i}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.setState(
                                {
                                  limitmodal: !this.state.limitmodal,
                                  namelimit: data,
                                },
                                () => this.getLimitationsDetails()
                              );
                            }}
                          >
                            {data}
                          </li>
                        ))}
                      </td>

                      <td>{this.state.status}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col
                style={{ width: "40%", marginLeft: "4%", marginRight: "4%" }}
              >
                <Table className="table table-striped table-bordered">
                  <thead
                    rowSpan="1"
                    style={{
                      backgroundColor: "rgb(211,195,241,1)",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <tr
                      rowSpan="1"
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <th rowSpan="1">Provider</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>providerName</td>

                      <td> {this.state.submittername}</td>
                    </tr>

                    <tr>
                      <td>entityIdentifier</td>

                      <td>{this.state.entityIdentifier}</td>
                    </tr>
                    <tr>
                      <td> entityType</td>

                      <td>{this.state.entityType}</td>
                    </tr>
                    <tr>
                      <td> Npi</td>

                      <td>{this.state.submitterNpi}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col style={{ width: "45%", marginLeft: "4%" }}>
                <Table className="table table-striped table-bordered">
                  <thead
                    rowSpan="1"
                    style={{
                      backgroundColor: "rgb(211,195,241,1)",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <tr
                      rowSpan="1"
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <th rowSpan="1">Subscriber</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>MemberId</td>

                      <td>{this.state.subscriberMemberId}</td>
                    </tr>
                    <tr>
                      <td>FirstName</td>

                      <td>{this.state.subscriberFirstname ? this.state.subscriberFirstname : "" }</td>
                    </tr>
                    <tr>
                      <td>LastName</td>

                      <td>{this.state.subscriberLastname}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>

                      <td>{this.state.subscribergender}</td>
                    </tr>
                    <tr>
                      <td>EntityIdentifier</td>

                      <td>{this.state.entityIdentifier}</td>
                    </tr>
                    <tr>
                      <td>EntityType</td>

                      <td>{this.state.entityType}</td>
                    </tr>
                    <tr>
                      <td>DateOfBirth</td>

                      <td>
                        {this.state.subscriberdob
                          ? moment(this.state.subscriberdob).format(
                              "MM/DD/YYYY"
                            )
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>GroupNumber</td>

                      <td>{this.state.subscribergroup}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col
                style={{ width: "40%", marginLeft: "2%", marginRight: "2%" }}
              >
                <Table className="table table-striped table-bordered">
                  <thead
                    rowSpan="1"
                    style={{
                      backgroundColor: "rgb(211,195,241,1)",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <tr
                      rowSpan="1"
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <th rowSpan="1">Plan Information</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>groupNumber</td>

                      <td> {this.state.groupnumberplan}</td>
                    </tr>

                    <tr>
                      <td>group Description</td>

                      <td>{this.state.groupnumberdesc}</td>
                    </tr>
                    <tr>
                      <td> entityType</td>

                      <td>{this.state.entityType}</td>
                    </tr>
                    <tr>
                      <td> Npi</td>

                      <td>{this.state.submitterNpi}</td>
                    </tr>
                  </tbody>
                </Table>
                <Table className="table table-striped table-bordered">
                  <thead
                    rowSpan="1"
                    style={{
                      backgroundColor: "rgb(211,195,241,1)",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <tr
                      rowSpan="1"
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <th rowSpan="1">Plan Date Information</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>planBegin</td>

                      <td>
                        {" "}
                        {this.state.planDateInformationplan
                          ? moment(this.state.planDateInformationplan).format(
                              "MM/DD/YYYY"
                            )
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <td>planend</td>

                      <td>
                        {this.state.planDateInformationservice
                          ? moment(
                              this.state.planDateInformationservice
                            ).format("MM/DD/YYYY")
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td> eligibilityBegin</td>

                      <td>
                        {this.state.planDateInformationeligibilityBegin
                          ? moment(
                              this.state.planDateInformationeligibilityBegin
                            ).format("MM/DD/YYYY")
                          : "-"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            {this.state.servicecodevalues1 === "88" ||
            this.state.servicecodevalues2 === "88" ? (
              <Row>
                <Col
                  style={{ width: "50%", marginLeft: "4%", marginRight: "4%" }}
                >
                  {/* <Col> Timely Filling Limited (Fast Approaching)</Col>
            <Col> </Col> */}
                  <Table className="table table-striped table-bordered">
                    <thead
                      rowSpan="1"
                      style={{
                        backgroundColor: "rgb(211,195,241,1)",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <tr
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <th rowSpan="1">Pharmacy</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Insurance Type Code</td>

                        <td>{this.state.insurancetype}</td>
                      </tr>
                      <tr>
                        <td>Plan</td>

                        <td>{this.state.plan}</td>
                      </tr>
                      <tr>
                        <td>Name</td>

                        <td>{this.state.name}</td>
                      </tr>
                      <tr>
                        <td>Entity Type</td>

                        <td>{this.state.entitytypeph}</td>
                      </tr>
                      <tr>
                        <td>Identifier Code</td>

                        <td>{this.state.identifiercode}</td>
                      </tr>
                      <tr>
                        <td>Address</td>

                        <td>{this.state.subscriberAddress}</td>
                      </tr>
                      <tr>
                        <td>Telephone</td>

                        <td>{this.state.telephone}</td>
                      </tr>
                      <tr>
                        <td>Uniform Resource Locator (URL)</td>

                        <td></td>
                      </tr>
                      <tr>
                        <td>Benefit</td>

                        <td>{this.state.benefit}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : null}
            <br />
            <br />
          </div>
          <div>
            <button
              style={{
                border: "none",
                backgroundColor: "#041c3c",
                color: "white",
                marginBottom: "4%",
                borderRadius: "2px",
                padding: "6px 10px",
              }}
              onClick={() => {
                this.toggle1();
              }}
            >
              View More
            </button>
            <br />
            <br />
            {this.state.Viewmore && (
              <div>
                <Modal
                  isOpen={this.state.Viewmore}
                  onHide={this.toggle1}
                  onClose={this.handleClose}
                  size="xl"
                  centered
                >
                  <ModalHeader toggle={this.toggle1}>
                    BenefitsInformation
                  </ModalHeader>
                  <ModalBody
                    style={{
                      "max-height": "calc(110vh - 210px)",
                      "overflow-y": "auto",
                      fontSize: "13px",
                    }}
                  >
                    <div>
                      <Row>
                        <Col style={{ width: "10%", whiteSpace: "nowrap" }}>
                          <Table className="table table-striped table-bordered">
                            <thead style={{ backgroundColor: "#CEDDFF" }}>
                              <tr>
                                <th>ServiceTypes</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.codevalue1.map((data, index) => {
                                return (
                                  <tr
                                    onClick={() => {
                                      this.setState(
                                        {
                                          toggletable2: true,
                                          typevalue: data[0],
                                        },
                                        () => {
                                          this.getInNetworkDeductibleInfo();
                                          this.getInNetworkCoInsInfo();
                                          this.getInNetworkCoPayInfo();
                                          this.getOutNetworkDeductibleInfo();
                                          this.getOutNetworkCoInsInfo();
                                          this.getOutNetworkCoPayInfo();
                                        }
                                      );
                                    }}
                                  >
                                    <td style={{ whiteSpace: "break-spaces" }}>
                                      {data[0] + "(" + data[1] + ")"}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Col>
                        <Col style={{ marginRight: "7%" }}>
                          {this.state.toggletable2 === true ? (
                            <div>
                              <h6
                                style={{
                                  fontSize: "13px",
                                  marginLeft: "5%",
                                  marginTop: "5%",
                                }}
                              >
                                More Details of{" "}
                                <span style={{ color: "#1F51FF" }}>
                                  {this.state.typevalue}
                                </span>
                              </h6>
                              <hr />
                              <div style={{ width: "120%" }}>
                                <Table className="table table-bordered">
                                  <thead>
                                    <tr
                                      style={{
                                        borderCollapse: "collapse",
                                        border: "1px solid #CEDDFF",
                                        padding: "5px",
                                        textAlign: "left",
                                      }}
                                    >
                                      <th
                                        colspan="2"
                                        style={{
                                          textAlign: "center",
                                          backgroundColor: "#CEDDFF",
                                        }}
                                      >
                                        In Network
                                        {this.state.innetworkopen ? (
                                          <BsArrowsFullscreen
                                            className="closemenu-auth"
                                            onClick={() =>
                                              this.setState({
                                                innetworkopen: false,
                                              })
                                            }
                                          />
                                        ) : (
                                          <BsArrowsMove
                                            className="openmenu-auth"
                                            onClick={() =>
                                              this.setState({
                                                innetworkopen: true,
                                              })
                                            }
                                          />
                                        )}
                                      </th>
                                    </tr>
                                  </thead>
                                  {this.state.innetworkopen === true ? (
                                    <tbody>
                                      <tr>
                                        <th>Deductible</th>
                                        <td>
                                          <Card>
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Coverage Level
                                              </Col>
                                              <Col>
                                                {this.state.coverageLeveldeduct
                                                  ? this.state
                                                      .coverageLeveldeduct
                                                  : "Active"}
                                              </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Time Qualifier
                                              </Col>
                                              <Col
                                                style={{ whiteSpace: "normal" }}
                                              >
                                                {this.state.timededuct
                                                  ? this.state.timededuct
                                                  : "-"}, {
                                                    this.state.remainingqual
                                                  }
                                              </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Benefit Amt., Remaining
                                              </Col>
                                              <Col>
                                                {this.state.benefitamtdeduct
                                                  ?"$" + this.state.benefitamtdeduct
                                                  :"$" + 0} , {
                                                    this.state.remainingdeduct ?
                                                    "$" +this.state.remainingdeduct :"$" + 0
                                                  }
                                              </Col>
                                            </Row>
                                     
                                          </Card>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Co Insurance</th>
                                        <td>
                                          <Card>
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Benefit Amt.
                                              </Col>
                                              <Col>
                                                {this.state.benefitperccoins
                                                  ? "$" +this.state.benefitperccoins
                                                  :"$" + 0}
                                              </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Time Qualifier
                                              </Col>
                                              <Col
                                                style={{ whiteSpace: "normal" }}
                                              >
                                                {this.state.timecoins
                                                  ? this.state.timecoins
                                                  : "-"}
                                              </Col>
                                            </Row>
                                          </Card>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Co Pay</th>
                                        <td>
                                          <Card>
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Benefit %
                                              </Col>
                                              <Col>
                                                {this.state.benefitperccopay
                                                  ? (this.state.benefitperccopay*100)
                                                  : "0"}
                                                {"%"}
                                              </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Time Qualifier
                                              </Col>
                                              <Col
                                                style={{ whiteSpace: "normal" }}
                                              >
                                                {this.state.timecopay
                                                  ? this.state.timecopay
                                                  : "-"}
                                              </Col>
                                            </Row>
                                          </Card>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ) : null}
                                </Table>
                              </div>
                              <div style={{ width: "120%" }}>
                                <Table className="table table-bordered">
                                  <thead>
                                    <tr
                                      style={{
                                        borderCollapse: "collapse",
                                        border: "1px solid #CEDDFF",
                                        padding: "5px",
                                        textAlign: "left",
                                      }}
                                    >
                                      <th
                                        colspan="2"
                                        style={{
                                          textAlign: "center",
                                          backgroundColor: "#CEDDFF",
                                        }}
                                      >
                                        Out Network
                                        {this.state.outnetworkopen ? (
                                          <BsArrowsFullscreen
                                            className="closemenu-auth"
                                            onClick={() =>
                                              this.setState({
                                                outnetworkopen: false,
                                              })
                                            }
                                          />
                                        ) : (
                                          <BsArrowsMove
                                            className="openmenu-auth"
                                            onClick={() =>
                                              this.setState({
                                                outnetworkopen: true,
                                              })
                                            }
                                          />
                                        )}
                                      </th>
                                    </tr>
                                  </thead>
                                  {this.state.outnetworkopen === true ? (
                                    <tbody>
                                      <tr>
                                        <th>Deductible</th>
                                        <td>
                                          <Card>
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Coverage Level
                                              </Col>
                                              <Col>
                                                {this.state
                                                  .coverageLeveldeductout
                                                  ? this.state
                                                      .coverageLeveldeductout
                                                  : "-"}
                                              </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Time Qualifier
                                              </Col>
                                              <Col
                                                style={{ whiteSpace: "normal" }}
                                              >
                                                {this.state.timedeductout
                                                  ? this.state.timedeductout
                                                  : "-"}, {
                                                    this.state.remainingqual1
                                                  }
                                              </Col>
                                            </Row>
                                            <hr/>
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Benefit Amt.
                                              </Col>
                                              <Col>
                                                {this.state.benefitamtdeductout
                                                  ? this.state
                                                      .benefitamtdeductout
                                                  : 0}, {
                                                    this.state.remainingdeduct1
                                                  }
                                              </Col>
                                            </Row>                                     
                                          </Card>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Co Insurance</th>
                                        <td>
                                          <Card>
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Benefit Amt.
                                              </Col>
                                              <Col>
                                                {this.state.benefitperccoins1
                                                  ? "$" + this.state.benefitperccoins1
                                                  : "$" +0}
                                              </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Time Qualifier
                                              </Col>
                                              <Col
                                                style={{ whiteSpace: "normal" }}
                                              >
                                                {this.state.timecoinsout
                                                  ? this.state.timecoinsout
                                                  : "-"}
                                              </Col>
                                            </Row>
                                          </Card>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Co Pay</th>
                                        <td>
                                          <Card>
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Benefit %.
                                              </Col>
                                              <Col>
                                                {this.state.benefitperccopay1
                                                  ? (this.state.benefitperccopay1 * 100)
                                                  : 0}
                                                {"%"}
                                              </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                              <Col
                                                style={{ fontWeight: "500" }}
                                              >
                                                Time Qualifier
                                              </Col>
                                              <Col
                                                style={{ whiteSpace: "normal" }}
                                              >
                                                {this.state.timecopayout
                                                  ? this.state.timecopayout
                                                  : "-"}
                                              </Col>
                                            </Row>
                                          </Card>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ) : null}
                                </Table>
                              </div>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            )}
            {this.state.limitmodal && (
              <div>
                <Modal
                  isOpen={this.state.limitmodal}
                  onHide={this.togglelimit}
                  size="xs"
                  centered
                  style={{ fontSize: "13px" }}
                >
                  <ModalHeader toggle={this.togglelimit}>
                    Limitation Details :{" "}
                    <span style={{ color: "#1F51FF", fontSize: "20px" }}>
                      {this.state.namelimit}
                    </span>
                  </ModalHeader>
                  <ModalBody
                    style={{
                      "max-height": "calc(110vh - 210px)",
                      "overflow-y": "auto",
                    }}
                  >
                    <div>
                      <Row>
                        <Col>Total visit</Col>
                        <Col>
                          {this.state.totalvisit ? this.state.totalvisit : 0}{" "}
                          &nbsp; visits
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>Calendar Year</Col>
                        <Col>{this.state.calyr ? this.state.calyr : "-"}</Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>Exceeded</Col>
                        <Col>
                          {this.state.exceeded ? this.state.exceeded : "-"}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>Not Exceeded</Col>
                        <Col>{this.state.notexc ? this.state.notexc : "-"}</Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>Description</Col>
                        <Col>
                          {this.state.desclimit ? this.state.desclimit : "-"}
                        </Col>
                      </Row>
                      <hr />
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            )}
            {this.state.limitmodal1 && (
              <div>
                <Modal
                  style={{ fontSize: "13px" }}
                  isOpen={this.state.limitmodal1}
                  onHide={this.togglelimit1}
                  size="xs"
                  centered
                >
                  <ModalHeader toggle={this.togglelimit1}>
                    Limitation Details :{" "}
                    <span style={{ color: "#1F51FF", fontSize: "20px" }}>
                      {this.state.namelimit1}
                    </span>
                  </ModalHeader>
                  <ModalBody
                    style={{
                      "max-height": "calc(110vh - 210px)",
                      "overflow-y": "auto",
                    }}
                  >
                    <div>
                      <Row>
                        <Col>Total visit</Col>
                        <Col>
                          {this.state.pastelitotalvisit
                            ? this.state.pastelitotalvisit
                            : 0}{" "}
                          &nbsp; visits
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>Calendar Year</Col>
                        <Col>{this.state.calyr1 ? this.state.calyr1 : "-"}</Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>Exceeded</Col>
                        <Col>
                          {this.state.exceeded1 ? this.state.exceeded1 : "-"}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>Not Exceeded</Col>
                        <Col>
                          {this.state.notexc1 ? this.state.notexc1 : "-"}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>Description</Col>
                        <Col>
                          {this.state.desclimit1 ? this.state.desclimit1 : "-"}
                        </Col>
                      </Row>
                      <hr />
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            )}
            <Modal
              style={{ fontSize: "13px" }}
              isOpen={this.state.outcomedata}
              onHide={this.toggle3}
              onClose={this.handleClose}
              onExit={this.reset}
              size="xl"
              centered
            >
              <ModalHeader toggle={this.toggle3}>
                <div className="tabs">
                  <button
                    className={tabindex === 1 ? "active" : ""}
                    onClick={() => this.setState({ tabindex: 1 })}
                  >
                    New Eligibility
                  </button>

                  <button
                    className={tabindex === 2 ? "active" : ""}
                    onClick={() => this.setState({ tabindex: 2 })}
                  >
                    Past Eligibility
                  </button>

                  <button
                    className={tabindex === 3 ? "active" : ""}
                    onClick={() => this.setState({ tabindex: 3 })}
                  >
                    Authorization
                  </button>
                </div>
              </ModalHeader>

              {this.state.tabindex === 1 && (
                <div>
                  <ModalBody>
                    <Row style={{ margin: "0px" }}>
                      <div style={{ width: "100%", fontSize: "13px" }}>
                        <Row>
                          <Col> Service Date</Col>
                          <Col md="6">
                            {this.state.patientdobnew ? (
                              <Input
                                type="text"
                                defaultValue={moment(
                                  this.state.patientdobnew
                                ).format("MM-DD-YYYY")}
                                onChange={(e) => {
                                  this.setState({
                                    patientdobnew: e.target.value,
                                  });
                                }}
                              ></Input>
                            ) : (
                              <Input
                                type="date"
                                defaultValue={moment(
                                  this.state.patientdobnew
                                ).format("MM-DD-YYYY")}
                                onChange={(e) => {
                                  this.setState({
                                    patientdobnew: e.target.value,
                                  });
                                }}
                              ></Input>
                            )}
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>Payer</Col>
                          <Col md="6">
                            <Input
                              type="text"
                              value={this.state.insnamenewcheck}
                              onChange={(e) => {
                                this.setState({
                                  insnamenewcheck: e.target.value,
                                });
                              }}
                            ></Input>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>Service Type</Col>
                          <Col md="6">
                            <Typeahead
                              id="typeahed1"
                              isLoading={this.state.loadingservicetype}
                              clearButton={true}
                              options={this.state.ServiceTypearray}
                              defaultInputValue={this.state.servicetypevalue}
                              labelKey={(option) =>
                                (this.state.servicetypevalue = `${option.serviceType}`) +
                                "(" +
                                option.code +
                                ")"
                              }
                              placeholder="Select..."
                              onChange={(option) =>
                                this.setState({
                                  servicetypevalue: option.serviceType,
                                })
                              }
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>Billing Provider</Col>
                          <Col md="6">
                            <Input
                              type="text"
                              value={this.state.orgnamenewcheck}
                              onChange={(e) => {
                                this.setState({
                                  orgnamenewcheck: e.target.value,
                                });
                              }}
                            ></Input>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Col></Col>
                    <Col>
                      <button className="check" onClick={this.checkeligibility}>
                        Check
                      </button>
                    </Col>
                    <Col>
                      <button
                        className="closebtton"
                        onClick={() => {
                          this.toggle3();
                        }}
                      >
                        Close
                      </button>
                    </Col>
                  </ModalFooter>
                </div>
              )}

              {this.state.tabindex === 2 && (
                <div>
                  <ModalBody style={{ marginTop: "-3%", fontSize: "13px" }}>
                    {this.state.loadingpast ? (
                      <div>
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "10px",
                            color: "#000",
                          }}
                        >
                          <span>Loading...</span>
                          <span>Please Wait...</span>
                        </p>
                        <div class="boxes body__loader dribbble">
                          <div class="box">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <div class="box">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <div class="box">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <div class="box">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    ) : this.state.pasteligibility.length === 0 ? (
                      <div class="bot-ui">
                        <div class="bot-head">
                          <div class="eyes-container">
                            <div class="to-left eye"></div>
                            <div class="to-right eye"></div>
                          </div>
                        </div>
                        <div class="bot-body">
                          <div class="analyzer"></div>
                        </div>
                        <p style={{ fontSize: "13px", fontStyle: "italic" }}>
                          No Data Found
                        </p>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "92%",
                          marginTop: "4%",
                          marginRight: "1%",
                          fontSize: "13px",
                        }}
                      >
                        <Table
                          className="table table-striped table-bordered"
                          bordered
                        >
                          <TableHead style={{ fontSize: "13px" }}>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              Service date{" "}
                            </StyledTableCell>
                            <StyledTableCell>Payer name</StyledTableCell>
                            <StyledTableCell>Insurance Id</StyledTableCell>
                            <StyledTableCell>Service type</StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                              Coverage Status
                            </StyledTableCell>
                          </TableHead>
                          <TableBody style={{ fontSize: "13px" }}>
                            {this.state.pasteligibility &&
                              this.state.pasteligibility.map((data, index) => {
                                return (
                                  <tr>
                                    <StyledTableCell
                                      onClick={() => {
                                        this.setState(
                                          {
                                            objectID: data._id,
                                          },
                                          () => {
                                            this.togglepastmodal();
                                            this.getmodalselfdetails();
                                            this.getpasteliCoInsself();
                                            this.getpasteliCoPaySelf();
                                            this.getpasteliDeductibleself();
                                            this.getpasteliDeductibleothers();
                                            this.getpasteliLimitations();
                                            this.getpasteliLimitationsDetails();
                                          }
                                        );
                                      }}
                                    >
                                      {data.DateOfService
                                        ? data.DateOfService
                                        : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {data.InsuranceName
                                        ? data.InsuranceName
                                        : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {data.PrimaryPolicyNo
                                        ? data.PrimaryPolicyNo
                                        : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {this.state.pasteligibilityworked}&nbsp;-
                                      {this.state.pasteligistatus}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {data.status ? data.status : "-"}
                                    </StyledTableCell>
                                  </tr>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </div>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Col></Col>
                    <Col></Col>
                    <Col>
                      <button
                        style={{ fontSize: "13px" }}
                        className="closebtton"
                        onClick={() => {
                          this.toggle3();
                        }}
                      >
                        Close
                      </button>
                    </Col>
                  </ModalFooter>
                </div>
              )}

              {this.state.tabindex === 3 && (
                <div>
                  <ModalBody style={{ marginTop: "-3%", fontSize: "13px" }}>
                    <div style={{ paddingTop: "1%" }}>
                      <div>
                        <Row
                          style={{ paddingTop: "2%", justifyContent: "center" }}
                        >
                          <Col md="5">
                            <h5
                              className="label"
                              style={{ textAlign: "left", fontSize: "13px" }}
                            >
                              Payer Name
                            </h5>
                          </Col>
                          <Col md="6">
                            <div>
                              <Typeahead
                                style={{ fontSize: "13px" }}
                                id="autoSuggestionInput"
                                labelKey="name"
                                defaultInputValue={this.state.insnamenewcheck}
                                options={this.state.suggestions}
                                selected={
                                  this.state.inputValue
                                    ? [this.state.inputValue]
                                    : []
                                }
                                onChange={this.handleSuggestionClick}
                                onInputChange={this.handleInputChange}
                                placeholder="Search..."
                              />
                            </div>
                            <div style={{ fontSize: 13, color: "red" }}>
                              {this.state.payernamevalueErr ? (
                                <span>{this.state.payernamevalueErr}</span>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <Row
                          style={{ paddingTop: "4%", justifyContent: "center" }}
                        >
                          <Col md="5">
                            <h5
                              className="label"
                              style={{ textAlign: "left", fontSize: "13px" }}
                            >
                              Type of Request
                            </h5>
                          </Col>
                          <Col md="6">
                            <Input
                              style={{ fontSize: "13px" }}
                              type="select"
                              placeholder="Select Request"
                              onClick={(e) => {
                                this.setState({
                                  typereq: e.target.value,
                                  typereqErr: "",
                                });
                              }}
                            >
                              <option value={""} hidden>
                                Select Type of Request
                              </option>
                              <option value={"Referral"}>Referral</option>
                              <option value={"Admission for Surgery"}>
                                Admission for Surgery
                              </option>
                              <option
                                value={
                                  "Request for Behavioral Health Emergency Admission"
                                }
                              >
                                Request for Behavioral Health Emergency
                                Admission
                              </option>
                              <option value={"Request for Home Health Care"}>
                                Request for Home Health Care
                              </option>
                              <option
                                value={
                                  "Request for Non-emergency Transportation Service"
                                }
                              >
                                Request for Non-emergency Transportation Service
                              </option>
                              <option value={"Medical Services Reservation"}>
                                Medical Services Reservation
                              </option>
                            </Input>
                            <div style={{ fontSize: 13, color: "red" }}>
                              {this.state.typereqErr && (
                                <span>{this.state.typereqErr}</span>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <br />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                  <Row
                  style={{
                    paddingTop: "1%",
                    justifyContent: "center",
                    marginLeft: "70%",
                  }}
                >
                  <Col md="10" style={{ display: "flex" }}>
                    <Link
                      to={{
                        pathname: "/eligibility/EditForm",
                        state: {
                          claimvalue: this.state.Eligibilityclaimid,
                          classvalue: this.state.classvalue,
                          payername: this.state.inputValue,
                          typeofreq: this.state.typereq,
                        },
                      }}
                    >
                      <Button
                        style={{ background: "#07326c", color: "#fff" }}
                        onClick={() => (
                          <EditForm
                            claimvalue={this.state.Eligibilityclaimid}
                            payername={this.state.inputValue}
                            typeofreq={this.state.typereq}
                            value={
                              sessionStorage.setItem(
                                "modulename",
                                "ViewMoreEligibility"
                              )||
                              sessionStorage.setItem(
                                "payername",
                                this.state.inputValue
                              ) ||
                              sessionStorage.setItem(
                                "typeofreq",
                                this.state.typereq
                              )
                            }
                          />
                        )}
                      >
                        Next
                      </Button>
                    </Link>{" "}
                    &nbsp;
                    <Button
                      style={{ background: "#07326c", color: "#fff" }}
                      onClick={this.toggleEdit}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
                  </ModalFooter>
                </div>
              )}
            </Modal>
            <Modal
              style={{ fontSize: "13px" }}
              isOpen={this.state.pasteligibilitymodal}
              onHide={this.togglepastmodal}
              onClose={this.handleClose}
              onExit={this.reset}
              size="xl"
              centered
            >
              <ModalHeader toggle={this.togglepastmodal}>
                Past Eligibility Details
              </ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(110vh - 250px)",
                  "overflow-y": "auto",
                }}
              >
                <br />
                <Row>
                  <Col style={{ width: "50%", marginLeft: "4%" }}>
                    <Table className="table table-striped table-bordered">
                      <thead
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <tr
                          rowSpan="1"
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <th rowSpan="1">Patient Details</th>
                          <th></th>
                        </tr>
                      </thead>

                      {this.state.pasteligibilityrelationtosubscriber ===
                        null ||
                      this.state.pasteligibilityrelationtosubscriber ===
                        "Self" ? (
                        <tbody>
                          <tr>
                            <td>Patient First Name</td>

                            <td>
                              {this.state.pasteligibilitySubspatientfirstname}
                            </td>
                          </tr>

                          <tr>
                            <td>Patient Last Name</td>

                            <td>
                              {this.state.pasteligibilitySubspatientlastname}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>Type</td>

                            <td>{this.state.pasteligibilitypatienttype}</td>
                          </tr>
                          <tr>
                            <td>Member ID</td>

                            <td>{this.state.pasteligibilitymemberid}</td>
                          </tr>
                          <tr>
                            <td>Group</td>

                            <td>{this.state.pasteligibilitygroup}</td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            {this.state.pasteligibilitysubsaddress ||
                            this.state.pasteligibilitycity ||
                            this.state.pasteligibilitystate ||
                            this.state.pasteligibilitypostalcode ? (
                              <td>
                                {this.state.pasteligibilitysubsaddress +
                                  ",  " +
                                  this.state.pasteligibilitycity +
                                  ",  " +
                                  this.state.pasteligibilitystate +
                                  ",  " +
                                  this.state.pasteligibilitypostalcode}
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                          <tr>
                            <td>DOB</td>
                            <td>
                              {this.state.pasteligibilitydob
                                ? moment(this.state.pasteligibilitydob).format(
                                    "MM/DD/YYYY"
                                  )
                                : "-"}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>Gender</td>

                            <td>{this.state.pasteligibilitygender}</td>
                          </tr>
                          <tr>
                            <td>Relation To Subscriber</td>

                            <td>
                              {this.state.pasteligibilityrelationtosubscriber
                                ? this.state.pasteligibilityrelationtosubscriber
                                : "Self"}
                            </td>
                          </tr>
                          <tr>
                            <td>Service Enrollment</td>

                            <td>
                              {this.state.pasteligibilityservicecontroller}
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>Patient First Name</td>

                            <td>{this.state.pasteliothersfirtsname}</td>
                          </tr>

                          <tr>
                            <td>Patient Last Name</td>

                            <td>{this.state.pasteliotherslastname} </td>
                          </tr>
                          <tr>
                            <td>Type</td>

                            <td>{this.state.pasteliothersType}</td>
                          </tr>
                          <tr>
                            <td>Member ID</td>

                            <td>{this.state.pasteligibilitymemberid}</td>
                          </tr>
                          <tr>
                            <td>Group</td>

                            <td>{this.state.pasteligibilitygroup}</td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            {this.state.pasteliothersAddress ||
                            this.state.pasteliothersCity ||
                            this.state.pasteliothersState ||
                            this.state.pasteliothersPostalcode ? (
                              <td>
                                {this.state.pasteliothersAddress +
                                  ",  " +
                                  this.state.pasteliothersCity +
                                  ",  " +
                                  this.state.pasteliothersState +
                                  ",  " +
                                  this.state.pasteliothersPostalcode}
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                          <tr>
                            <td>DOB</td>

                            <td>
                              {this.state.pasteliothersdob
                                ? moment(this.state.pasteliothersdob).format(
                                    "MM/DD/YYYY"
                                  )
                                : "-"}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>Gender</td>

                            <td>{this.state.pasteliothersgender}</td>
                          </tr>
                          <tr>
                            <td>Relation To Subscriber</td>

                            <td>{this.state.pasteliothersrelation}</td>
                          </tr>
                          <tr>
                            <td>Service Enrollment</td>

                            <td>
                              {this.state.pasteliothersservicename
                                ? this.state.pasteliothersservicename
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </Col>

                  <Col
                    style={{
                      width: "40%",
                      marginLeft: "2%",
                      marginRight: "2%",
                    }}
                  >
                    <Table className="table table-striped table-bordered">
                      <thead
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <tr
                          rowSpan="1"
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <th rowSpan="1">Submitter</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>Submitter</td>

                          <td> {this.state.pasteligibilitysubmitter}</td>
                        </tr>

                        <tr>
                          <td>Submitter Type</td>

                          <td>{this.state.pasteligibilityservicetype}</td>
                        </tr>
                        <tr>
                          <td> Service Provider#</td>

                          <td>
                            {this.state.pasteligibilityserviceprovidernpi}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Table className="table table-striped table-bordered">
                      <thead
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <tr
                          rowSpan="1"
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <th rowSpan="1">Service Details</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Plan Status</td>
                          {this.state.pasteligibilityplanNumber ===
                          "Active Coverage" ? (
                            <td
                              style={{ color: "green", fontWeight: "bolder" }}
                            >
                              {this.state.pasteligibilityplanNumber}
                            </td>
                          ) : this.state.pasteligibilityplanNumber ===
                            "InActive Coverage" ? (
                            <td style={{ color: "red", fontWeight: "bolder" }}>
                              {this.state.pasteligibilityplanNumber}
                            </td>
                          ) : (
                            <td>{this.state.pasteligibilityplanNumber}</td>
                          )}
                        </tr>

                        <tr>
                          <td>Coverage Level</td>

                          <td>{this.state.pasteligibilitypatienttype}</td>
                        </tr>
                        <tr>
                          <td>Service Type</td>

                          <td>{this.state.pasteligibilityreqservicetype}</td>
                        </tr>
                        <tr>
                          <td>Plan</td>

                          <td>{this.state.pasteligibilitygroupnumberdesc}</td>
                        </tr>
                        <tr>
                          <td>Group or Policy Number</td>

                          <td>{this.state.pasteligibilitygroup}</td>
                        </tr>
                        <tr>
                          <td>Auth Required</td>

                          <td>{this.state.pasteligibilityAuthvalue}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      width: "50%",
                      marginLeft: "4%",
                      marginRight: "4%",
                    }}
                  >
                    <Table className="table table-striped table-bordered">
                      <thead
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <tr
                          rowSpan="1"
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <th rowSpan="1">Plan status</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>Coverage Status</td>

                          <td>
                            {this.state.pasteligibilityplanNumberothers ===
                            "Active Coverage" ? (
                              <td
                                style={{ color: "green", fontWeight: "bolder" }}
                              >
                                {this.state.pasteligibilityplanNumberothers}
                              </td>
                            ) : this.state.pasteligibilityplanNumberothers ===
                              "InActive Coverage" ? (
                              <td
                                style={{ color: "red", fontWeight: "bolder" }}
                              >
                                {this.state.pasteligibilityplanNumberothers}
                              </td>
                            ) : (
                              <td>
                                {this.state.pasteligibilityplanNumberothers}
                              </td>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Service Types</td>
                          <td>
                            {this.state.codevalue1
                              .slice(0, this.state.itemsToShow)
                              .map((data, i) => (
                                <li key={i}>{data[0] + "(" + data[1] + ")"}</li>
                              ))}

                            <button
                              style={{
                                marginRight: "20%",
                                padding: "5px",
                                marginLeft: "1%",
                                border: "none",
                                background: "#041c3c",
                                color: "white",
                                borderRadius: "22px",
                                marginTop: "1%",
                              }}
                            >
                              <a onClick={this.showMore}>
                                {this.state.expanded ? (
                                  <span>
                                    Show less &nbsp;&nbsp;
                                    <AiOutlineMinusCircle />
                                  </span>
                                ) : (
                                  <span>
                                    Show more&nbsp;&nbsp;
                                    <AiOutlinePlusCircle />
                                  </span>
                                )}
                              </a>
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>planDetails</td>

                          <td> {this.state.pasteligibilityplanDetails}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      width: "50%",
                      marginLeft: "4%",
                      marginRight: "4%",
                    }}
                  >
                    <Table className="table table-striped table-bordered">
                      <thead
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <tr
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <th rowSpan="1"></th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>Plan Effective Date</td>

                          <td>
                            {" "}
                            {this.state.pasteligibilityplanbegin
                              ? moment(
                                  this.state.pasteligibilityplanbegin
                                ).format("MM/DD/YYYY")
                              : this.state.pasteligibilityplanbegin}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <span style={{ color: "blue" }}>Deductible</span>
                            <br />
                            {this.state.pasteliDeductiblevalue ||
                            this.state.pasteliDeductiblevaluecode ||
                            this.state.pastelitimequal1 !== ""
                              ? this.state.pasteliDeductiblevalue +
                                "," +
                                this.state.pasteliDeductiblevaluecode +
                                "," +
                                this.state.pastelitimequal1
                              : this.state.pasteliDeductiblevalue +
                                this.state.pasteliDeductiblevaluecode +
                                this.state.pastelitimequal1}
                          </td>

                          <td>
                            {this.state.pastelibenefitamount
                              ? this.state.pastelibenefitamount
                              : 0}
                          </td>
                        </tr>
                        <tr>
                          <td>Remaining</td>

                          <td>
                            {this.state.pasteliremainingamt
                              ? this.state.pasteliremainingamt
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      width: "50%",
                      marginLeft: "4%",
                      marginRight: "4%",
                    }}
                  >
                    <Table className="table table-striped table-bordered">
                      <thead
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <tr
                          rowSpan="1"
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <th rowSpan="1"></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <span style={{ color: "blue" }}>Deductible</span>
                            <br />
                            <td>
                              {this.state.pasteliDeductiblevalueothers.map(
                                (item1, index) => (
                                  <li>{item1}</li>
                                )
                              )}
                            </td>
                            <td>
                              {this.state.pasteliDeductiblevaluecodeothers.map(
                                (item2, index) => (
                                  <li style={{ listStyle: "none" }}>
                                    {" "}
                                    {"(" + item2 + ")"}{" "}
                                  </li>
                                )
                              )}
                            </td>
                            <td>
                              {this.state.pastelitimequal1others.map(
                                (item3, index) => (
                                  <li style={{ listStyle: "none" }}>
                                    {" "}
                                    {item3}{" "}
                                  </li>
                                )
                              )}
                            </td>
                          </td>

                          <td>
                            <span style={{ color: "blue" }}>Benefit Amt</span>
                            <br />
                            {this.state.pastelibenefitamountothers.map(
                              (item4, index) => (
                                <li style={{ listStyle: "none" }}> {item4} </li>
                              )
                            )}
                          </td>
                          <td>
                            <span style={{ color: "blue" }}>Remaining</span>
                            <br />
                            {this.state.pasteliremainingamountothers.map(
                              (item5, index) => (
                                <li style={{ listStyle: "none" }}>
                                  {" "}
                                  {item5 ? item5 : "-"}{" "}
                                </li>
                              )
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      width: "50%",
                      marginLeft: "4%",
                      marginRight: "4%",
                    }}
                  >
                    <Table className="table table-striped table-bordered">
                      <thead
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <tr
                          rowSpan="1"
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <th rowSpan="1"></th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <span style={{ color: "blue" }}>CO Insurance</span>
                            <br />
                            {(this.state.pastelicoservicetype ||
                              this.state.pastelicoservicecode ||
                              this.state.pastelicotimequal) !== ""
                              ? this.state.pastelicoservicetype
                                ? this.state.pastelicoservicetype
                                : "" + "," + this.state.pastelicoservicecode
                                ? this.state.pastelicoservicecode
                                : "" + "," + this.state.pastelicotimequal
                                ? this.state.pastelicotimequal
                                : ""
                              : this.state.pastelicoservicetype +
                                this.state.pastelicoservicecode +
                                this.state.pastelicotimequal}
                          </td>

                          <td>{this.state.pastelibenefitamountco * 100} %</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      width: "50%",
                      marginLeft: "4%",
                      marginRight: "4%",
                    }}
                  >
                    <Table className="table table-striped table-bordered">
                      <thead
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <tr
                          rowSpan="1"
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <th rowSpan="1"></th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <span style={{ color: "blue" }}>Co Pay</span>
                            <br />

                            {(this.state.pastelicoPaytype ||
                              this.state.pastelicoPaycode ||
                              this.state.pastelicoPaytime) !== ""
                              ? this.state.pastelicoPaytype
                                ? this.state.pastelicoPaytype
                                : "" + "," + this.state.pastelicoPaycode
                                ? this.state.pastelicoPaycode
                                : "" + "," + this.state.pastelicoPaytime
                                ? this.state.pastelicoPaytime
                                : ""
                              : this.state.pastelicoPaytype +
                                this.state.pastelicoPaycode +
                                this.state.pastelicoPaytime}
                          </td>

                          <td>
                            {this.state.pastelibenefitamountcoPay
                              ? this.state.pastelibenefitamountcoPay
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      width: "50%",
                      marginLeft: "4%",
                      marginRight: "4%",
                    }}
                  >
                    <Table className="table table-striped table-bordered">
                      <thead
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <tr
                          rowSpan="1"
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <th rowSpan="1"></th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <span style={{ color: "blue" }}>
                              Limitations Notcovered
                            </span>
                            <br />

                            {this.state.pasteliLimitservicetype.map(
                              (data, i) => (
                                <li
                                  key={i}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.setState(
                                      {
                                        limitmodal1: !this.state.limitmodal1,
                                        namelimit1: data,
                                      },
                                      () => {
                                        this.getpasteliLimitationsDetails();
                                      }
                                    );
                                  }}
                                >
                                  {data}
                                </li>
                              )
                            )}
                          </td>

                          <td>{this.state.status}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      width: "40%",
                      marginLeft: "4%",
                      marginRight: "4%",
                    }}
                  >
                    <Table className="table table-striped table-bordered">
                      <thead
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <tr
                          rowSpan="1"
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <th rowSpan="1">Provider</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>providerName</td>

                          <td> {this.state.pasteligibilitysubmitter}</td>
                        </tr>

                        <tr>
                          <td>entityIdentifier</td>

                          <td>{this.state.pasteligibilitypatienttype}</td>
                        </tr>
                        <tr>
                          <td> entityType</td>

                          <td>{this.state.pasteligibilityentityType}</td>
                        </tr>
                        <tr>
                          <td> Npi</td>

                          <td>
                            {this.state.pasteligibilityserviceprovidernpi}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ width: "45%", marginLeft: "4%" }}>
                    <Table className="table table-striped table-bordered">
                      <thead
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <tr
                          rowSpan="1"
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <th rowSpan="1">Subscriber</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>MemberId</td>

                          <td>{this.state.pasteligibilitymemberid}</td>
                        </tr>
                        <tr>
                          <td>FirstName</td>

                          <td>
                            {this.state.pasteligibilitySubspatientfirstname}
                          </td>
                        </tr>
                        <tr>
                          <td>LastName</td>

                          <td>
                            {this.state.pasteligibilitySubspatientlastname}
                          </td>
                        </tr>
                        <tr>
                          <td>Gender</td>

                          <td>{this.state.pasteligibilitygender}</td>
                        </tr>
                        <tr>
                          <td>EntityIdentifier</td>

                          <td>{this.state.pasteligibilitypatienttype}</td>
                        </tr>
                        <tr>
                          <td>EntityType</td>

                          <td>{this.state.pasteligibilityentityType}</td>
                        </tr>
                        <tr>
                          <td>DateOfBirth</td>

                          <td>
                            {this.state.subscriberdob
                              ? moment(this.state.subscriberdob).format(
                                  "MM/DD/YYYY"
                                )
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>GroupNumber</td>

                          <td>{this.state.pasteligibilitygroup}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col
                    style={{
                      width: "40%",
                      marginLeft: "2%",
                      marginRight: "2%",
                    }}
                  >
                    <Table className="table table-striped table-bordered">
                      <thead
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <tr
                          rowSpan="1"
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <th rowSpan="1">Plan Information</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>groupNumber</td>
                          <td> {this.state.pasteligibilitygroup}</td>
                        </tr>
                        <tr>
                          <td>group Description</td>
                          <td>{this.state.pasteligibilitygroupnumberdesc}</td>
                        </tr>
                        <tr>
                          <td> entityType</td>
                          <td>{this.state.pasteligibilityentityType}</td>
                        </tr>
                        <tr>
                          <td> Npi</td>
                          <td>
                            {this.state.pasteligibilityserviceprovidernpi}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Table className="table table-striped table-bordered">
                      <thead
                        rowSpan="1"
                        style={{
                          backgroundColor: "rgb(211,195,241,1)",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <tr
                          rowSpan="1"
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <th rowSpan="1">Plan Date Information</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>planBegin</td>
                          <td>
                            {" "}
                            {this.state.pasteligibilityplanbegin
                              ? moment(
                                  this.state.pasteligibilityplanbegin
                                ).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                        </tr>

                        <tr>
                          <td>planend</td>

                          <td>
                            {this.state.pasteligibilityplanend
                              ? moment(
                                  this.state.pasteligibilityplanend
                                ).format("MM/DD/YYYY")
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>eligibilityBegin</td>

                          <td>
                            {this.state.pasteligibilitybegin
                              ? moment(this.state.pasteligibilitybegin).format(
                                  "MM/DD/YYYY"
                                )
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                {this.state.pasteliservicecodevalues1 === "88" ||
                this.state.pasteliservicecodevalues2 === "88" ? (
                  <Row>
                    <Col
                      style={{
                        width: "50%",
                        marginLeft: "4%",
                        marginRight: "4%",
                      }}
                    >
                      <Table className="table table-striped table-bordered">
                        <thead
                          rowSpan="1"
                          style={{
                            backgroundColor: "rgb(211,195,241,1)",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <tr
                            rowSpan="1"
                            style={{
                              backgroundColor: "rgb(211,195,241,1)",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            <th rowSpan="1">Pharmacy</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>Insurance Type Code</td>

                            <td>{this.state.insurancetype}</td>
                          </tr>
                          <tr>
                            <td>Plan</td>

                            <td>{this.state.pasteligibilityplan}</td>
                          </tr>
                          <tr>
                            <td>Name</td>

                            <td>{this.state.pasteligibilityname}</td>
                          </tr>
                          <tr>
                            <td>Entity Type</td>

                            <td>{this.state.pasteligibilityentitytype}</td>
                          </tr>
                          <tr>
                            <td>Identifier Code</td>

                            <td>{this.state.pasteligibilityidentifiercode}</td>
                          </tr>
                          <tr>
                            <td>Address</td>

                            <td>{this.state.pasteligibilityaddress}</td>
                          </tr>
                          <tr>
                            <td>Telephone</td>

                            <td>{this.state.pasteligibilityaddress}</td>
                          </tr>
                          <tr>
                            <td>Uniform Resource Locator (URL)</td>

                            <td>{this.state.pasteligibilityurl}</td>
                          </tr>
                          <tr>
                            <td>Benefit</td>

                            <td>{this.state.pasteligibilitybenefit}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                ) : null}
                <br />
                <br />
              </ModalBody>
              <ModalFooter>
                <button
                  className="closebtton"
                  onClick={() => {
                    this.togglepastmodal();
                  }}
                >
                  Close
                </button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
export default Viewmoreeligibility;
