import { Component } from "react";
import "antd/dist/antd.css";
import React from "react";
import axios from "axios";
import { Row, Col, Table } from "reactstrap";
import "react-best-tabs/dist/index.css";
import { ip } from "../../../Apis/Api";
import "../../../../Styles/charts.css";
import { IoMdNotificationsOutline, IoMdGitPullRequest } from "react-icons/io";
import { MdPendingActions } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { GiThrustBend } from "react-icons/gi";
import { RiSignalWifiErrorFill } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import { MdOutlinePaid } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";

class OutcomeCharts extends Component {
  state = {
    webamount: 0,
    webclaim: 0,
    ivramount: 0,
    ivrclaim: 0,
    manualamount: 0,
    manualclaim: 0,
    webcount: [],
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  getDetailsCard = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.testStart && this.props.testEnd) {
          url = `${ip}/outcome/claimstatus?start_date=${this.props.testStart}&end_date=${this.props.testEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/outcome/claimstatus?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.testStart && this.props.testEnd) {
          url = `${ip}/outcome/claimstatus?start_date=${this.props.testStart}&end_date=${this.props.testEnd}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/outcome/claimstatus?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.testStart && this.props.testEnd) {
        url = `${ip}/outcome/claimstatus?start_date=${this.props.testStart}&end_date=${this.props.testEnd}`;
      } else {
        url = `${ip}/outcome/claimstatus`;
      }
    }

    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        if (req.data.action_wise.denied.length != 0) {
          this.setState({
            denial: req.data.action_wise.denied[0].totalAmount.toLocaleString(),
          });
          this.setState({
            DenialClaims: req.data.action_wise.denied[0].totalClaims,
          });
        } else {
          this.setState({
            denial: 0,
            DenialClaims: 0,
          });
        }
        if (req.data.action_wise.paid.length == 0) {
          this.setState({ paid: 0, PaidClaims: 0 });
        } else {
          this.setState({
            paid: req.data.action_wise.paid[0].totalAmount.toLocaleString(),
          });
          this.setState({
            PaidClaims: req.data.action_wise.paid[0].totalClaims,
          });
        }
        if (req.data.action_wise.nis.length === 0) {
          this.setState({
            nisvalue: 0,
            NISClaims: 0,
          });
        } else {
          this.setState({
            nisvalue: req.data.action_wise.nis[0].totalAmount.toLocaleString(),
          });
          this.setState({ NISClaims: req.data.action_wise.nis[0].totalClaims });
        }
        if (req.data.action_wise.acknowledgement.length === 0) {
          this.setState({ ack: 0, ACKClaims: 0 });
        } else {
          this.setState({
            ack: req.data.action_wise.acknowledgement[0].totalAmount.toLocaleString(),
          });
          this.setState({
            ACKClaims: req.data.action_wise.acknowledgement[0].totalClaims,
          });
        }
        if (req.data.action_wise.pending.length === 0) {
          this.setState({ pending: 0, PendingClaims: 0 });
        } else {
          this.setState({
            pending:
              req.data.action_wise.pending[0].totalAmount.toLocaleString(),
          });
          this.setState({
            PendingClaims: req.data.action_wise.pending[0].totalClaims,
          });
        }
        if (req.data.action_wise.finalized.length === 0) {
          this.setState({ final: 0, finalizedClaims: 0 });
        } else {
          this.setState({
            final:
              req.data.action_wise.finalized[0].totalAmount.toLocaleString(),
          });
          this.setState({
            finalizedClaims: req.data.action_wise.finalized[0].totalClaims,
          });
        }
        if (req.data.action_wise.request.length === 0) {
          this.setState({ req: 0, RequestClaims: 0 });
        } else {
          this.setState({
            req: req.data.action_wise.request[0].totalAmount.toLocaleString(),
          });
          this.setState({
            RequestClaims: req.data.action_wise.request[0].totalClaims,
          });
        }
        if (req.data.action_wise.searches.length === 0) {
          this.setState({ search: 0, SearchesClaims: 0 });
        } else {
          this.setState({
            search:
              req.data.action_wise.searches[0].totalAmount.toLocaleString(),
          });
          this.setState({
            SearchesClaims: req.data.action_wise.searches[0].totalClaims,
          });
        }
        if (req.data.action_wise.error.length === 0) {
          this.setState({ error: 0, ErrorClaims: 0 });
        } else {
          this.setState({
            error: req.data.action_wise.error[0].totalAmount.toLocaleString(),
          });
          this.setState({
            ErrorClaims: req.data.action_wise.error[0].totalClaims,
          });
        }
        if (req.data.action_wise.datareportingack.length === 0) {
          this.setState({ datareportingack: 0 });
        } else {
          this.setState({
            datareportingack:
              req.data.action_wise.datareportingack[0].totalAmount.toLocaleString(),
          });
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);

    this.getDetailsCard();
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.testStart === prevProps.testStart &&
        this.props.testEnd === prevProps.testEnd
      )
    ) {
      this.getDetailsCard();
    } else if (this.props.client !== prevProps.client) {
      this.getDetailsCard();
    } else if (this.props.Location !== prevProps.Location) {
      this.getDetailsCard();
    }
  }
  //Owl Carousel Settings
  render() {
    return (
      <div>
        <div style={{ marginLeft: "7%" }}>
          <Row>
            <Col>
              <div className="card_outcomes">
                <Row>
                  <Col md="1">
                    <div
                      className="perCircle"
                      style={{ backgroundColor: "#FF3F59", float: "left" }}
                    >
                      <IoMdNotificationsOutline
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginTop: "18%",
                        }}
                      ></IoMdNotificationsOutline>
                    </div>
                  </Col>
                  <Col md="6" className="col_outcome">
                    Acknowledgement
                  </Col>
                  <Col className="col_outcome">${this.state.ack}</Col>
                </Row>
              </div>
              <br />
              <div className="card_outcomes">
                <Row>
                  <Col md="1">
                    <div
                      className="perCircle"
                      style={{ backgroundColor: "#6857DD", float: "left" }}
                    >
                      <HiOutlineDocumentReport
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginTop: "18%",
                        }}
                      ></HiOutlineDocumentReport>
                    </div>
                  </Col>
                  <Col md="6" className="col_outcome">
                    Data Reporting Ack
                  </Col>
                  <Col className="col_outcome">
                    ${this.state.datareportingack}
                  </Col>
                </Row>
              </div>
              <br />
              <div className="card_outcomes">
                <Row>
                  <Col md="1">
                    <div
                      className="perCircle"
                      style={{ backgroundColor: "#FFB830", float: "left" }}
                    >
                      <GiThrustBend
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginTop: "18%",
                        }}
                      ></GiThrustBend>
                    </div>
                  </Col>
                  <Col md="6" className="col_outcome">
                    Finalised
                  </Col>
                  <Col className="col_outcome">${this.state.final}</Col>
                </Row>
              </div>
              <br />
              <div className="card_outcomes">
                <Row>
                  <Col md="1">
                    <div
                      className="perCircle"
                      style={{ backgroundColor: "#29BB89", float: "left" }}
                    >
                      <IoMdGitPullRequest
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginTop: "18%",
                        }}
                      ></IoMdGitPullRequest>
                    </div>
                  </Col>
                  <Col md="6" className="col_outcome">
                    Req for Additional Information
                  </Col>
                  <Col className="col_outcome"> ${this.state.req}</Col>
                </Row>
              </div>
              <br />
              <div className="card_outcomes">
                <Row>
                  <Col md="1">
                    <div
                      className="perCircle"
                      style={{ backgroundColor: "#1E6F5C", float: "left" }}
                    >
                      <RiSignalWifiErrorFill
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginTop: "18%",
                        }}
                      ></RiSignalWifiErrorFill>
                    </div>
                  </Col>
                  <Col md="6" className="col_outcome">
                    {" "}
                    General Error
                  </Col>
                  <Col className="col_outcome"> ${this.state.error}</Col>
                </Row>
              </div>
            </Col>
            <Col>
              <div className="card_outcomes">
                <Row>
                  <Col md="1">
                    <div
                      className="perCircle"
                      style={{ backgroundColor: "#D69F57", float: "left" }}
                    >
                      <AiOutlineCloseCircle
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginTop: "18%",
                        }}
                      ></AiOutlineCloseCircle>
                    </div>
                  </Col>
                  <Col md="4" className="col_outcome">
                    Denial
                  </Col>
                  <Col md="7" className="col_outcome">
                    ${this.state.denial}
                  </Col>
                </Row>
              </div>
              <br />
              <div className="card_outcomes">
                <Row>
                  <Col md="1">
                    <div
                      className="perCircle"
                      style={{ backgroundColor: "#D65767", float: "left" }}
                    >
                      <MdOutlineDoNotDisturbAlt
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginTop: "18%",
                        }}
                      ></MdOutlineDoNotDisturbAlt>
                    </div>
                  </Col>
                  <Col md="4" className="col_outcome">
                    Not In System
                  </Col>
                  <Col md="7" className="col_outcome">
                    ${this.state.nisvalue}
                  </Col>
                </Row>
              </div>
              <br />
              <div className="card_outcomes">
                <Row>
                  <Col md="1">
                    <div
                      className="perCircle"
                      style={{ backgroundColor: "#FF8700", float: "left" }}
                    >
                      <BsSearch
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginTop: "18%",
                        }}
                      ></BsSearch>
                    </div>
                  </Col>
                  <Col md="4" className="col_outcome">
                    Searches
                  </Col>
                  <Col md="7" className="col_outcome">
                    {" "}
                    ${this.state.search}
                  </Col>
                </Row>
              </div>
              <br />
              <div className="card_outcomes">
                <Row>
                  <Col md="1">
                    <div
                      className="perCircle"
                      style={{ backgroundColor: "#B259EF", float: "left" }}
                    >
                      <MdOutlinePaid
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginTop: "18%",
                        }}
                      ></MdOutlinePaid>
                    </div>
                  </Col>
                  <Col md="4" className="col_outcome">
                    Paid
                  </Col>
                  <Col md="7" className="col_outcome">
                    ${this.state.paid}
                  </Col>
                </Row>
              </div>
              <br />
              <div className="card_outcomes">
                <Row>
                  <Col md="1">
                    <div
                      className="perCircle"
                      style={{ backgroundColor: "#3DB2FF", float: "left" }}
                    >
                      <MdPendingActions
                        style={{
                          fontSize: "1.5rem",
                          color: "white",
                          marginTop: "18%",
                        }}
                      ></MdPendingActions>
                    </div>
                  </Col>
                  <Col md="4" className="col_outcome">
                    Pending
                  </Col>
                  <Col md="7" className="col_outcome">
                    ${this.state.pending}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <br />
        <div className="claim__detail__wrapper">
          <div>
            <div className="card__container">
              <div className="card__inner">
                <div className="circle__card__one">
                  <h3>Denied</h3>
                </div>

                <div className="content__card1">
                  <p
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.DenialClaims}
                  </p>
                </div>
              </div>
              <div className="card__inner">
                <div className="circle__card__one">
                  <h3>Paid</h3>
                </div>

                <div className="content__card1">
                  <p
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.PaidClaims}
                  </p>
                </div>
              </div>
              <div className="card__inner">
                <div className="circle__card__one">
                  <h3>Not In System</h3>
                </div>

                <div className="content__card1">
                  <p
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.NISClaims}
                  </p>
                </div>
              </div>
              <div className="card__inner">
                <div className="circle__card__one">
                  <h3>Finalized</h3>
                </div>

                <div className="content__card1">
                  <p
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.finalizedClaims}
                  </p>
                </div>
              </div>
            </div>
            <br />
            <Row style={{ marginLeft: "5%" }}>
              <div
                className="p-2 col-md-2 offset-md-0"
                style={{ width: "26%" }}
              >
                <div className="bg-white shadow mb-5 bg-body rounded-3 border-start border-end border-0 border-3 border-info card">
                  <div className="card-body p-3 position-relative">
                    <div className="float-start ps-2  position-absolute top-0 start-0">
                      <span style={{ fontSize: "28px", fontWeight: "bold" }}>
                        {this.state.ACKClaims}
                      </span>
                    </div>
                    <div className="float-end text-center lh-1">
                      {/* <span
                                className="text-muted fs-7"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                Ack
                              </span> */}
                    </div>
                  </div>

                  <div className="card-footer text-muted bg-transparent border-0 text-end">
                    <span
                      // className="btn btn-dark btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="View"
                    >
                      {/* <i class="fa-solid fa-eye"></i> */}
                    </span>
                    <span
                      // className="text-muted fs-2"

                      style={{
                        color: "#041c3c",
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      Acknowledgement
                    </span>
                    <span
                      // className="btn btn-primary btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Add"
                    >
                      {/* <i class="fa-solid fa-plus"></i> */}
                    </span>
                  </div>
                </div>
              </div>

              <div class="p-2 col-md-2 offset-md-0">
                <div class="bg-white shadow mb-5 bg-body rounded-3 border-start  border-end border-0 border-3 border-info card">
                  <div class="card-body p-3 position-relative">
                    <div class="float-start ps-2  position-absolute top-0 start-0">
                      <span style={{ fontSize: "28px", fontWeight: "bold" }}>
                        {this.state.SearchesClaims}
                      </span>
                    </div>
                    <div class="float-end text-center lh-1">
                      {/* <span
                                class="text-muted fs-7"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                Searches
                              </span> */}
                    </div>
                  </div>
                  <div class="card-footer text-muted bg-transparent border-0 text-end">
                    <span
                      // class="btn btn-dark btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="View"
                    >
                      <span
                        // class="text-muted fs-7"
                        style={{
                          color: "#041c3c",
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        Searches
                      </span>
                      {/* <i class="fa-solid fa-eye"></i> */}
                    </span>
                    <span
                      // class="btn btn-primary btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Add"
                    >
                      {/* <i class="fa-solid fa-plus"></i> */}
                    </span>
                  </div>
                </div>
              </div>

              <div class="p-2 col-md-2 offset-md-0">
                <div class="bg-white shadow mb-5 bg-body rounded-3 border-start border-end border-0 border-3 border-info card">
                  <div class="card-body p-3 position-relative">
                    <div class="float-start ps-2  position-absolute top-0 start-0">
                      <span style={{ fontSize: "28px", fontWeight: "bold" }}>
                        {this.state.PendingClaims}
                      </span>
                    </div>
                    <div class="float-end text-center lh-1">
                      {/* <span
                                class="text-muted fs-7"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                Pending
                              </span> */}
                    </div>
                  </div>
                  <div class="card-footer text-muted bg-transparent border-0 text-end">
                    <span
                      // class="btn btn-dark btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="View"
                    >
                      {/* <i class="fa-solid fa-eye"></i> */}
                    </span>
                    <span
                      // class="text-muted fs-7"
                      style={{
                        color: "#041c3c",
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      Pending
                    </span>
                    <span
                      // class="btn btn-primary btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Add"
                    >
                      {/* <i class="fa-solid fa-plus"></i> */}
                    </span>
                  </div>
                </div>
              </div>

              <div class="p-2 col-md-2 offset-md-0">
                <div class="bg-white shadow mb-5 bg-body rounded-3 border-start border-end border-0 border-3 border-info card">
                  <div class="card-body p-3 position-relative">
                    <div class="float-start ps-2  position-absolute top-0 start-0">
                      <span style={{ fontSize: "28px", fontWeight: "bold" }}>
                        {this.state.RequestClaims}
                      </span>
                    </div>
                    <div class="float-end text-center lh-1">
                      {/* <span
                                class="text-muted fs-7"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                Request
                              </span> */}
                    </div>
                  </div>
                  <div class="card-footer text-muted bg-transparent border-0 text-end">
                    <span
                      // class="btn btn-dark btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="View"
                    >
                      {/* <i class="fa-solid fa-eye"></i> */}
                    </span>
                    <span
                      // class="text-muted fs-7"
                      style={{
                        color: "#041c3c",
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      Request
                    </span>
                    <span
                      // class="btn btn-primary btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Add"
                    >
                      {/* <i class="fa-solid fa-plus"></i> */}
                    </span>
                  </div>
                </div>
              </div>

              <div class="p-2 col-md-2 offset-md-0">
                <div class="bg-white shadow mb-5 bg-body rounded-3 border-start border-end border-0 border-3 border-info card">
                  <div class="card-body p-3 position-relative">
                    <div class="float-start ps-2  position-absolute top-0 start-0">
                      <span style={{ fontSize: "28px", fontWeight: "bold" }}>
                        {this.state.ErrorClaims}
                      </span>
                    </div>
                    <div class="float-end text-center lh-1">
                      {/* <span
                                class="text-muted fs-7"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                Error
                              </span> */}
                    </div>
                  </div>
                  <div class="card-footer text-muted bg-transparent border-0 text-end">
                    <span
                      // class="btn btn-dark btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="View"
                    >
                      {/* <i class="fa-solid fa-eye"></i> */}
                    </span>
                    <span
                      // class="text-muted fs-7"
                      style={{
                        color: "#041c3c",
                        fontSize: "24px",
                        fontWeight: "bold",
                      }}
                    >
                      Error
                    </span>
                    <span
                      // class="btn btn-primary btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Add"
                    >
                      {/* <i class="fa-solid fa-plus"></i> */}
                    </span>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default OutcomeCharts;
