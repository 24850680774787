import { Component } from "react";
import axios from "axios";
import Newsidebar from "../../Dashboard/Newsidebar";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { ip } from "../../Apis/Api";
const medicare = [
  {
    date: "12-03-2021",
    ssn: "1",
    eno: "--",
    mrn: "dummy",
    cpt: "medicare",
    claim: "enabled",
    era: "active",
    id: "111",
    billed: "--",
    ar: "dummy",
    payment: "none",
    coin: "34",
    deductible: "none",
    copay: "--",
    others: "none",
    payer: "0202",
  },
];

class IncorrectMedicare extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
  }

  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:last-child td, &:last-child th,": {
        border: 1,
      },
    }));
    return (
      <div>
      <Newsidebar />
      <div className="align-row">
        <div className="align-column">
          <div className="tophead">
          </div>
          <br></br>
          <p style={{ textAlign: "left", marginLeft: "3%", fontSize: "14px" }}>
            <Link
              to="/management-trends-patterns"
              style={{ textDecoration: "none", color: "black" }}
            >
              Trends & Patterns
            </Link>
            &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp; Incorrect
            Medicare Writeoff Billed To Patient{" "}
          </p>
          <div className="align-row items">
            <button className="addNew" onClick={this.toggle}>
              Process Document
            </button>
          </div>
          <br />
          {/* <Row style={{marginTop:"15px"}}>
              <Card  className="agedcard-head">
              <Col md="10" style={{textAlign:"left", fontFamily:'Poppins',fontWeight:"600", fontSize:"20px",paddingTop:"10px",}}>
              Incorrect Medicare Writeoff Billed To Patient
                  </Col>
              <hr style={{ width: "100%", marginLeft: "-12px", }}/> 
             */}
          <div style={{ marginLeft: "3px" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Claim Date</StyledTableCell>
                    <StyledTableCell align="center">SSN</StyledTableCell>
                    <StyledTableCell align="center">ENO</StyledTableCell>
                    <StyledTableCell align="center">MRN</StyledTableCell>
                    <StyledTableCell align="center">CPT Code</StyledTableCell>
                    <StyledTableCell align="center">
                      Claim Status
                    </StyledTableCell>
                    <StyledTableCell align="center">ERA Status</StyledTableCell>
                    <StyledTableCell align="center">Claim ID</StyledTableCell>
                    <StyledTableCell align="center">Billed</StyledTableCell>
                    <StyledTableCell align="center">AR</StyledTableCell>
                    <StyledTableCell align="center">
                      Payment Applied
                    </StyledTableCell>
                    <StyledTableCell align="center">Coins</StyledTableCell>
                    <StyledTableCell align="center">Deductible</StyledTableCell>
                    <StyledTableCell align="center">Copay</StyledTableCell>
                    <StyledTableCell align="center">Others</StyledTableCell>

                    <StyledTableCell align="center">PayerID</StyledTableCell>
                  </TableRow>
                </TableHead>
                {medicare.length > 0 &&
                  medicare.map((data, i) => {
                    return (
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell align="center">
                            {data.date}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.ssn}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.eno}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.mrn}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.cpt}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.claim}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.era}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.id}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.billed}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.ar}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.payment}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.coin}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.deductible}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.copay}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.others}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data.payer}
                          </StyledTableCell>
                          <br />
                        </StyledTableRow>
                      </TableBody>
                    );
                  })}
              </Table>
            </TableContainer>
          </div>

          <br />
          {/* </Card>
        </Row> */}
        </div>
      </div>
      </div>
    );
  }
}
export default IncorrectMedicare;
