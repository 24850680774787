import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ip } from "../../Apis/Api";
import axios from "axios";
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
export default class Agecharts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      age_wise: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      UserId: sessionStorage.getItem("user_id"),
      firstname: sessionStorage.getItem("firstname"),
      lastname: sessionStorage.getItem("lastname"),
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    let name = this.state.firstname + " " + this.state.lastname;
    if (this.props.start !== "" && this.props.end !== "") {
      url = `${ip}/userdashboard/aging?claimunder=${name}?startdate=${this.props.start} && enddate=${this.props.end}`;
    } else {
      url = `${ip}/userdashboard/aging?claimunder=${name}`;
    }
    const req = axios
      .get(url, {
        headers: headers,
      })
      .then((req) => {
        this.setState({ age_wise: req.data.aging });
      })
      .catch((err) => {});
  };
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getDetails();
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.start === prevProps.start && this.props.end === prevProps.end
      )
    ) {
      this.getDetails();
    }
  }
  render() {
    return (
      <div onMouseOver={() => (tooltip = "totalamount")}>
        <AreaChart
          width={1300}
          height={300}
          data={this.state.age_wise}
          margin={{
            top: 10,
            right: 27,
            left: 50,
            bottom: 0,
          }}
        >
          <XAxis dataKey={"_id"} />
          <YAxis dataKey={"totalamount"} />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey={"totalamount"}
            stroke="#F190B7"
            name="Total Amount"
            fill="#F190B7"
            onMouseOver={() => (tooltip = "totalamount")}
          />
        </AreaChart>
      </div>
    );
  }
}
