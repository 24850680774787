import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./TestiMonials.css";
import axios from "axios";
import { ip } from "../../../Apis/Api";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Card, Row, Col } from "react-bootstrap";

const testiMonials = [
  {
    _id: "WEB",
    totalClaims: 0,
    totalAmount: 0,
  },
  {
    _id: "IVR",
    totalClaims: 0,
    totalAmount: 0,
  },
  {
    _id: "Calling",
    totalClaims: 0,
    totalAmount: 0,
  },
];

const options = {
  // loop: true,
  center: true,
  items: 3,
  margin: 0,
  // autoplay: true,
  dots: true,
  // autoplayTimeout: 2500,
  // smartSpeed: 450,
  // navigation: true,
  navigationText: [
    "<i class='fa fa-chevron-left'></i>",
    "<i class='fa fa-chevron-right'></i>",
  ],
  responsive: {
    0: {
      items: 3,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  },
};
class TestiMonials extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
    webamount: "",
    webcount: "",
    ivramount: "",
    ivrcount: "",
    manualamount: "",
    manualcount: "",
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  gettotal = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.testStart && this.props.testEnd) {
          url = `${ip}/outcome/typewisecount?start_date=${this.props.testStart}&end_date=${this.props.testEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          url = `${ip}/outcome/typewisecount?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.testStart && this.props.testEnd) {
          url = `${ip}/outcome/typewisecount?start_date=${this.props.testStart}&end_date=${this.props.testEnd}&clientId=${this.props.client}`;
        } else {
          url = `${ip}/outcome/typewisecount?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.testStart && this.props.testEnd) {
        url = `${ip}/outcome/typewisecount?start_date=${this.props.testStart}&end_date=${this.props.testEnd}`;
      } else {
        url = `${ip}/outcome/typewisecount`;
      }
    }
    const req = axios
      .get(url, { headers: headers })
      .then((req) => {
        let web = req.data.type_wise.filter((data) => {
          return data._id === "Web";
        });
        let ivr = req.data.type_wise.filter((data) => {
          return data._id === "IVR";
        });
        let manual = req.data.type_wise.filter((data) => {
          return data._id === "Manual";
        });
        if (web.length !== 0) {
          this.setState({
            webcount: web[0].totalClaims,
            webamount: web[0].totalAmount,
          });
        } else {
          this.setState({ webcount: 0, webamount: 0 });
        }
        if (ivr.length !== 0) {
          this.setState({
            ivrcount: ivr[0].totalClaims,
            ivramount: ivr[0].totalAmount,
          });
        } else {
          this.setState({ ivrcount: 0, ivramount: 0 });
        }
        if (manual.length !== 0) {
          this.setState({
            manualcount: manual[0].totalClaims,
            manualamount: manual[0].totalAmount,
          });
        } else {
          this.setState({ manualcount: 0, manualamount: 0 });
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.gettotal();
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.testStart === prevProps.testStart &&
        this.props.testEnd === prevProps.testEnd
      )
    ) {
      this.gettotal();
    } else if (this.props.client !== prevProps.client) {
      this.gettotal();
    } else if (this.props.Location !== prevProps.Location) {
      this.gettotal();
    }
  }
  //Owl Carousel Settings
  render() {
    return (
      <section id="testimonial" className="testimonials pt-70 pb-70">
        <div className=" mt-5">
          <h4
            className="miniTitle text-center"
            style={{
              marginTop: "-2%",
              fontWeight: "bold",
              fontFamily: "sans-serif",
              marginBottom: "1%",
              color: "#fff",
            }}
          >
            Total Claims
          </h4>
          <div>
            <div className="outcomes_test">
              <div>
                <Link
                  to={{
                    pathname: `/claims/Web`,
                    startdate: this.props.testStart,
                    enddate: this.props.testEnd,
                    Location: this.props.Location,
                  }}
                  onClick={() =>
                    sessionStorage.setItem("queryclient", this.props.client)
                  }
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="amt_outcome">
                    <h6 className="name__outcome">Web</h6>
                    <span>
                      Total Amount: ${this.state.webamount.toLocaleString()}
                    </span>
                    <br />
                    <br />
                    <span>Total Claims:{this.state.webcount}</span>
                    <br />
                  </div>
                </Link>
              </div>
              <div>
                <Link
                  to={{
                    pathname: `/claims/IVR`,
                    startdate: this.props.testStart,
                    enddate: this.props.testEnd,
                    Location: this.props.Location,
                  }}
                  onClick={() =>
                    sessionStorage.setItem("queryclient", this.props.client)
                  }
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div>
                    <h6 className="name__outcome">IVR</h6>
                    <span className="amt_outcome">
                      Total Amount: ${this.state.ivramount.toLocaleString()}
                    </span>
                    <br />
                    <br />
                    <span>Total Claims:{this.state.ivrcount}</span>
                    <br />
                  </div>
                </Link>
              </div>
              <div>
                <Link
                  to={{
                    pathname: `/claims/Manual`,
                    startdate: this.props.testStart,
                    enddate: this.props.testEnd,
                    Location: this.props.Location,
                  }}
                  onClick={() =>
                    sessionStorage.setItem("queryclient", this.props.client)
                  }
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div>
                    <h6 className="name__outcome">Manual</h6>
                    <span className="amt_outcome">
                      Total Amount: ${this.state.manualamount.toLocaleString()}
                    </span>
                    <br />
                    <br />
                    <span className="out__data">
                      Total Claims:{this.state.manualcount}
                    </span>
                    <br />
                  </div>
                </Link>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default TestiMonials;
