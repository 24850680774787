import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../Styles/configure.css";
import Newsidebar from "../Dashboard/Newsidebar";
import Select from "react-dropdown-select";
import {
  Row,
  Col,
  Form,
  Label,
  Input,
  Button,
  FormGroup,
} from "reactstrap";
import {Table} from "react-bootstrap";
import { Modal, Spinner } from "react-bootstrap";
 
import { Card } from "react-bootstrap";
import { ip } from ".././Apis/Api";
import { faIcicles } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineFolder } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";
import ReactPaginate from "react-paginate";
import loading from "../../Images/loader.gif";

export class ClaimCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query1: [],
      status1: "",
      status2: "",
      searchtext1: "",
      searchdataresponse1: [],
      searchclaimdata1: [],
      addnewmodal: false,
      editmodal: false,
      deletemodal: false,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      status: "Active",
      selectedfile: "",
      uploading: false,
      statuslist: [],
      perPageweb: 7,
      pageCountweb: "",
      categorylist: [],
      offset: 0,
      currentpage: 0,
      perPage: 7,
      categoryname: "",
      id: "",
      updateCategory: "",
      updateStatus: "",
    };
    this.fileInput = React.createRef();
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  openmodal = () => {
    this.setState({ addnewmodal: !this.state.addnewmodal });
  };

  openeditmodal = () => {
    this.setState({ editmodal: !this.state.editmodal });
  };

  opendeletemodal = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getquery = () => {
    var query_o;
    let query = [];
    if (this.state.status1.length !== 0) {
      query_o = `claimCategory=${this.state.status1}`;
      query.push(query_o);
    }

    if (this.state.status2.length !== 0) {
      query_o = `status=${this.state.status2}`;
      query.push(query_o);
    }
    if (query.length === 1) {
      this.state.query1.push(`${query[0]}`);
    }
    if (query.length === 2) {
      this.state.query1.push(`${query[0]}&${query[1]}`);
    }
  };
  filtersearch1 = (e) => {
    // e.preventDefault();
    this.setState({ query1: [] });
    this.getquery();
    let filtereddata = [];
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.query1.length !== 0) {
      let url = `${ip}/claimcategory/advancedmultiplefilter?${this.state.query1}`;
       axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          filtereddata = res.data.claims;
          if (filtereddata.length !== 0) {
            this.setState({ searchtext1: 1 });
            const slice = filtereddata.slice(
              this.state.offsetweb,
              this.state.offsetweb + this.state.perPageweb
            );
            this.setState({
              searchdataresponse1: filtereddata,
              pageCountweb: Math.ceil(
                filtereddata.length / this.state.perPageweb
              ),
              searchclaimdata1: slice,
            });
          } else if (filtereddata.length === 0) {
            this.setState({
              searchtext1: 1,
              searchdataresponse1: [],
              searchclaimdata1: [],
            });
          }
        })
        .catch((err) => {});
    } else {
      alert("Select data to search");
    }
  };
  getClaimCategory = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

     await axios
      .get(`${ip}/claimcategory/all`, { headers: headers })
      .then((res) => {
        const slice = res.data.claimcategory.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(
            res.data.claimcategory.length / this.state.perPage
          ),
          categorylist: slice,
        });
      })
      .catch((err) => {});
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getClaimCategory();
      }
    );
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.filtersearch1();
      }
    );
  };
  addClaimCategory = async () => {
    this.setState({ createmodal: !this.state.createmodal });
    this.setState({ uploading: true });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      claimCategory: this.state.categoryname,
      status: this.state.status,
    };
     await axios
      .post(`${ip}/claimcategory/create`, value, { headers: headers })
      .then((res) => {
        if (res) {
          this.setState({ addnewmodal: !this.state.addnewmodal });
          this.getClaimCategory();
          this.setState({ createmodal: !this.state.createmodal });
          toast.success("Claim Category added", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.openmodal();
          this.getClaimCategory();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  updateClaimCategory = async () => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      claimCategory: this.state.updateCategory,
      status: this.state.updateStatus,
    };
     await axios
      .put(`${ip}/claimcategory/${this.state.id} `, value, { headers: headers })
      .then((res) => {
        if (res) {
          this.setState({ createmodal: !this.state.createmodal });
          this.openeditmodal();
          this.getClaimCategory();
          toast.info("Claim Category updated", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.openeditmodal();
          this.getClaimCategory();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  deleteClaimCategory = async () => {
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     await axios
      .delete(`${ip}/claimcategory/${this.state.id} `, { headers: headers })
      .then((res) => {
        if (res) {
          this.setState({ createmodal: !this.state.createmodal });
          this.opendeletemodal();
          this.getClaimCategory();
          toast.error("Claim Category deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.opendeletemodal();
          this.getClaimCategory();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };
  imageHandler = (e) => {
    this.setState({ selectedfile: e.target.files[0] });
  };
  reset = (e) => {
    e.preventDefault();
    this.setState({
      updateDescErr: "",
      DescErr: "",
      claimfield1: "",
      claimvalue1: "",
      status1: [],
      status2: [],
      payervalue1: [],
      cptvalue1: [],
      searchdataresponse1: [],
      searchclaimdata1: [],
      searchtext1: "",
    });
  };
  componentDidMount() {
    
    this.getClaimCategory();
  }
  render() {
    return (
      <div>
      <Newsidebar name="Claim Category" />
      <div className="align-row" style={{overflow:"hidden"}}>
        <div className="align-column">
        {this.state.role_type === "Admin" && (
          <button
            className="addNew"
            onClick={this.openmodal}
          >
            Add Claim Category
          </button>
        )}
          <Row style={{ height: "100%" }}>
            <Card
              style={{
                height: "97%",
                marginLeft: "2%",
                width: "97%",
                background: "#FBFBFB",
                border: "none",
              }}
            >
              <Form>
                <Row style={{ marginTop: "2%" }}>
                  <Col style={{ marginLeft: "0%" }}>
                    <FormGroup>
                      <Label>Claim Category</Label>
                      <Input
                        value={this.state.status1}
                        onChange={(e) => {
                          this.setState({ status1: e.target.value });
                        }}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col style={{ marginLeft: "0%" }}>
                    <FormGroup>
                      <Label>Claim Status</Label>
                      <Input
                        value={this.state.status2}
                        onChange={(e) => {
                          this.setState({ status2: e.target.value });
                        }}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                  <div class="btn-group" role="group">
                  <button
                  type="button"
                  class="btn"
                  style={{ backgroundColor: "#89CFF0", color: "#fff" }}
                  onClick={(e) => {
                    this.setState({ tabname: "web" }, () => {
                      this.filtersearch1(e);
                    });
                  }}
                >
                  Search
                </button>
                <button
                type="button"
                class="btn"
                style={{ backgroundColor: "#7DD657", color: "#fff" }}
                onClick={(e) => {
                  this.reset(e);
                }}
              >
                <IoIosRefresh
                  style={{
                    color: "blue",
                    fontSize: "25px",
                  }}
                ></IoIosRefresh>
              </button>
                </div>
              </Col>
                </Row>
              </Form>
            </Card>
          </Row>
          <br />
          <ToastContainer></ToastContainer>
  
          {this.state.categorylist.length === 0 ? (
            <div class="bot-ui">
              <div class="bot-head">
                <div class="eyes-container">
                  <div class="to-left eye"></div>
                  <div class="to-right eye"></div>
                </div>
              </div>
              <div class="bot-body">
                <div class="analyzer"></div>
              </div>
              <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                No Data Found
              </p>
            </div>
          ) : (
            <div>
              {this.state.searchtext1 === "" ? (
                  <div style={{marginLeft:"2%"}}>
                    <Table bordered className="StandardTable">
                      <thead>
                        <tr>
                          <th>Claim Category</th>
                          <th>Status</th>
                          {this.state.role_type === "Admin" && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody >
                        {this.state.categorylist.map((data, index) => (
                          <tr >
                            <td>{data.claimCategory}</td>
                            <td>{data.status}</td>
                            {this.state.role_type === "Admin" && (
                              <td>
                                <button
                                  className="Edit"
                                  onClick={() => {
                                    this.setState({
                                      id: data._id,
                                      updateStatus: data.status,
                                      updateCategory: data.claimCategory,
                                    });
                                    this.openeditmodal();
                                  }}
                                >
                                  Edit
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                  className="Delete"
                                  onClick={() => {
                                    this.setState({ id: data._id });
                                    this.opendeletemodal();
                                  }}
                                >
                                  Delete
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <br />
                    <div>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
              ) : (
                <div>
                  {this.state.searchdataresponse1.length === 0 ? (
                    <div class="bot-ui">
                      <div class="bot-head">
                        <div class="eyes-container">
                          <div class="to-left eye"></div>
                          <div class="to-right eye"></div>
                        </div>
                      </div>
                      <div class="bot-body">
                        <div class="analyzer"></div>
                      </div>
                      <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                        No Data Found
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div className="claimstatus-table">
                        <Table bordered className="StandardTable">
                          <thead>
                            <tr>
                              <th>Claim Category</th>
                              <th>Status</th>
                              {this.state.role_type === "Admin" && (
                                <th>Action</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.searchdataresponse1.map(
                              (data, index) => (
                                <tr>
                                  <td>{data.claimCategory}</td>
                                  <td>{data.status}</td>
                                  {this.state.role_type === "Admin" && (
                                    <td>
                                      <button
                                        className="Edit"
                                        onClick={() => {
                                          this.setState({
                                            id: data._id,
                                            updateStatus: data.status,
                                            updateCategory: data.claimCategory,
                                          });
                                          this.openeditmodal();
                                        }}
                                      >
                                        Edit
                                      </button>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <button
                                        className="Delete"
                                        onClick={() => {
                                          this.setState({ id: data._id });
                                          this.opendeletemodal();
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                        <br />
                        <div>
                          <ReactPaginate
                            previousLabel={<IoIosArrowBack />}
                            nextLabel={<IoIosArrowForward />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pageCountweb}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick1}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <Modal show={this.state.editmodal} onHide={this.openeditmodal} centered>
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Edit Claim Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Label>Claim Category</Label>
                  <Input
                    type="text"
                    value={this.state.updateCategory}
                    onChange={(e) =>
                      this.setState({ updateCategory: e.target.value })
                    }
                  ></Input>
                </Col>
                <Col>
                  <Label for="select">Status</Label>
                  <Input
                    type="select"
                    name="select"
                    className="form-control form-select"
                    value={this.state.updateStatus}
                    onChange={(e) =>
                      this.setState({ updateStatus: e.target.value })
                    }
                  >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Input>
                </Col>
              </Row>
              <br />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.openeditmodal();
                //   this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={this.updateClaimCategory}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.addnewmodal} onHide={this.openmodal} centered>
        <Modal.Header closeButton closeLabel="">
          <Modal.Title>Add Claim Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Label className="required">Claim Category</Label>
                <Input
                  type="text"
                  value={this.state.categoryname}
                  onChange={(e) =>
                    this.setState({ categoryname: e.target.value })
                  }
                ></Input>
              </Col>
              <Col>
                <Label for="select" className="required">
                  Status
                </Label>
                <Input
                  type="select"
                  name="select"
                  className="form-control form-select"
                  value={this.state.status}
                  onChange={(e) =>
                    this.setState({ status: e.target.value })
                  }
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Input>
              </Col>
            </Row>
            <br />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              border: "1px solid grey",
              color: "black",
              backgroundColor: "#fff",
            }}
            onClick={() => {
              this.openmodal();
              //   this.reset();
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "black", color: "white" }}
            onClick={this.addClaimCategory}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
        <Modal
          show={this.state.deletemodal}
          onHide={this.opendeletemodal}
          centered
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Delete Claim Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to delete this claim category?</Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.opendeletemodal();
                //   this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={this.deleteClaimCategory}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.createmodal} centered>
          <Modal.Body>
            <img
              src={loading}
              style={{ width: "200px", height: "200px", marginLeft: "30%" }}
            />
          </Modal.Body>
        </Modal>
      </div>
      </div>
    );
  }
}

export default ClaimCategory;
