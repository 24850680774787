import { React, Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import "../../Styles/claimmanagement.css";
import {  Col, Input } from "reactstrap";
import moment from "moment";
import axios from "axios";
import { ip } from "../Apis/Api";
import { ToastContainer, toast } from "react-toastify";
import {  DatePicker} from "antd";
import { IoIosRefresh } from "react-icons/io";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";

import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import ClaimBuckets from "./ClaimManagement/ClaimBuckets";

const { RangePicker } = DatePicker;

class ClaimManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      ClaimID: sessionStorage.getItem("ClaimID"),
      clientname: sessionStorage.getItem("clientname"),
      role: sessionStorage.getItem("role_type"),
      clientslist: [],
      opendropdown: false,
      categoryvalue: null,
      valueOfInput1: "",
      searchidkeyindicator: null,
      searchidkeyindicator1: "",
      valueOfInput2: "",
      machinetype: "",
      filterstatus: "custom",
      searchid: "",
      querysearch: "",
      Secondcard: "",
      Location: "",
      locationslist: [],
    };
  }
  toggle = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };
  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        if (res.data.clientonboard.length !== 0) {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        } else {
          this.setState({
            clientslist: res.data.clientonboard,
          });
        }
      })
      .catch((err) => {});
  };

  handleChange = (selectedOptions) => {
    let Location = selectedOptions.map((data) => {
      return data.Location;
    });
    this.setState(
      {
        Location: Location[0],
      },
      () => {
        this.getclients();
        this.getLocation();
      }
    );
  };
  getsearchid = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

    if (this.state.searchid === "") {
      toast.error("Please fill the search value!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("Show id");
    } else {
      const res = await axios
        .get(`${ip}/claim/viewclaim?claimid=${this.state.searchid}&feature=ClaimManagement`, {
          headers: headers,
        })

        .then((res) => {
          if(res.data.Claim.length !== 0){
            res.data.Claim.map((ele) => {
              this.setState({
                querysearch: ele.ClaimCategory,
                Secondcard: ele.statusCategoryCode,
                machinetype: ele.Machinetype,
              }, ()=> {console.log("claim bucket search", this.state.querysearch)});
            });
          }else{
            toast.error("Claim id not found", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        
        })

        .catch((err) => {
          if (err.response) {
            if (err.response.status === 500) {
              toast.error("Claim id not found", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }}

        });
    }
    console.log(this.state.Secondcard, "secondcardname");
  };

  getLocation = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      url = `${ip}/location/all/list?clientId=${this.state.client}`;
    } else {
      url = `${ip}/location/all/list`;
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        let data = res.data.location;
        data = data.filter((item) => {
          return item.clientId == this.state.client;
        });
        data.sort((a, b) => {
          return a.Location < b.Location ? -1 : 1;
        });

        this.setState({ locationslist: data });
      })

      .catch((err) => {});
  };
  reset = () => {
    this.setState({
      searchid: "",
      querysearch: "",
      Secondcard:"",
    });
  };
  valuedate = (option, e) => {
    if (option === "yesterday") {
      this.setState({
        valueOfInput1: e.target.value,
        valueOfInput2: moment().subtract(1, "days").format("MM-DD-YYYY"),
        filterstatus: option,
      });
    } else {
      this.setState({
        valueOfInput1: e.target.value,
        valueOfInput2: moment().format("MM-DD-YYYY"),
        filterstatus: option,
      });
    }
  };
  componentDidMount() {
    
    this.getclients();
    this.getLocation();    
    let searchid = localStorage.getItem("searchkeyidlocal")
    let val=JSON.parse(searchid)
    if(val !== "" && val !== null){
      this.setState({
        searchid: JSON.parse(searchid)
      }, ()=> {
        this.getsearchid()
      })
    }else{
      localStorage.setItem("searchkeyidlocal", null)
    }
    sessionStorage.removeItem("querystartdate");
    sessionStorage.removeItem("queryenddate");
    sessionStorage.removeItem("queryLocation");
  }
  componentWillUnmount() {
    let input = JSON.stringify(this.state.searchid)
    localStorage.setItem("searchkeyidlocal",input)
  }
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        localStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  render() {
    return (
      <div className="align-row" style={{ overflow: "hidden" }}>
        <Newsidebar name="Claim Status Management" />
          <ToastContainer></ToastContainer>
          <div className="claim-card-container">
      
            <div style={{ display: "flex", flexDirection: "row", gap:"20px" }}>
            <Col md="4">
            <form>
              <div class="input-group mb-4">
                <input
                  style={{ zIndex: 0.5 }}
                  type="search"
                  placeholder="Search by ID?"
                  aria-describedby="button-addon5"
                  class="form-control"
                  value={this.state.searchid}
                  onChange={(e) => {
                    this.setState({ searchid: e.target.value });
                  }}
                />
                <div class="input-group-append" style={{ zIndex: 1 }}>
                  <div class="btn-group">
                    <button
                      id="button-addon5"
                      type="submit"
                      class="btn"
                      style={{background:"#002755", color:"white"}}
                      onClick={(e) => {
                        e.preventDefault();
                        this.getsearchid();
                      }}
                    >
                      <i class="fa fa-search"></i>
                    </button>
                    <button
                    style={{background:"#EB1A23", color:"white"}}
                      id="button-addon5"
                      type="submit"
                      class="btn btn-dark"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default form submission
                        this.reset();
                      }}
                    >
                      <IoIosRefresh
                        style={{
                          color: "#fff",
                          fontSize: "20px",
                        }}
                      ></IoIosRefresh>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Col>
            <section>
            {this.state.role === "SuperAdmin" ? (
              <ButtonDropdown
                isOpen={this.state.opendropdown}
                toggle={this.toggle}
                style={{
                  width: "12%",
                  height:"5%",
                  position: "inherit",
                }}
              >
                <DropdownToggle caret>
                  {this.state.clientname ? this.state.clientname : "All"}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() =>
                      this.setState({ clientname: "", client: "" }, () =>
                        this.getLocation()
                      )
                    }
                  >
                    All
                  </DropdownItem>
                  {this.state.clientslist.map((e) => {
                    return (
                      <DropdownItem
                        id={e.clientId}
                        key={e.clientId}
                        onClick={(e) => {
                          this.setState(
                            {
                              client: e.target.id,
                              clientname: e.target.innerText,
                            },
                            () => this.getLocation()
                          );
                        }}
                      >
                        {e.client}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </ButtonDropdown>
            ) : null}
            {this.state.client && (
              // <Input
              //   type="select"
              //   className="form-control form-select"
              //   onChange={(e) =>
              //     this.setState({
              //       Location: e.target.value,
              //     })
              //   }
              //   style={{
              //     width: "12%",
              //     marginLeft: "4%",
              //     position: "inherit",
              //   }}
              // >
              //   <option value="">All Locations</option>
              //   {this.state.locationslist.map((e) => (
              //     <option value={e.Location}>{e.Location}</option>
              //   ))}
              // </Input>

              <Typeahead
                style={{height:"35px"}}
                id="typeahed1"
                clearButton={true}
                onChange={this.handleChange}
                options={this.state.locationslist}
                labelKey={(option) => `${option.Location}`}
                placeholder="All Location"
                selected={this.state.selected}
              />
            )}
            </section>
              <section>        
              <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize:"13px",
                gap:"20px"
              }}>
              <Input
                type="select"
                style={{  height:"5%" ,
                fontSize:"13px"
              }}
                className="form-control form-select"
                onChange={(e) => {
                  const index = e.target.selectedIndex;
                  const el = e.target.childNodes[index];
                  const option = el.getAttribute("title");
                  this.valuedate(option, e);
                  this.setState(() => {
                    sessionStorage.setItem(
                      "querystartdate",
                      this.state.valueOfInput1
                    );
                    sessionStorage.setItem(
                      "queryenddate",
                      this.state.valueOfInput2
                    );
                  });
                }}
              >
                <option value="" title="custom">
                  Custom
                </option>
                <option value={moment().format("MM-DD-YYYY")} title="">
                  Today
                </option>
                <option
                  value={moment().subtract(1, "days").format("MM-DD-YYYY")}
                  title="yesterday"
                >
                  Yesterday
                </option>
                <option
                  value={moment().subtract(3, "days").format("MM-DD-YYYY")}
                  title=""
                >
                  Last 3 days
                </option>
                <option
                  value={moment().subtract(7, "days").format("MM-DD-YYYY")}
                  title=""
                >
                  Last 7 days
                </option>
                <option
                  value={moment().subtract(30, "days").format("MM-DD-YYYY")}
                  title=""
                >
                  Last 30 days
                </option>
                <option
                  value={moment().subtract(365, "days").format("MM-DD-YYYY")}
                  title=""
                >
                  Last 365 days
                </option>
              </Input>
              {this.state.filterstatus === "custom" && (
                <RangePicker
                  format="MM-DD-YYYY"
                  onChange={(dates, datestring) => {
                    this.setState(
                      {
                        valueOfInput1: datestring[0],
                        valueOfInput2: datestring[1],
                      },
                      () => {
                        sessionStorage.setItem(
                          "querystartdate",
                          this.state.valueOfInput1
                        );
                        sessionStorage.setItem(
                          "queryenddate",
                          this.state.valueOfInput2
                        );
                      }
                    );
                  }}
                  height={"40%"}
                  style={{
                    borderColor: "gray",
                    fontSize:"13px",
                    width:"150%"
                  }}
                />
              )}
            </div>
              </section>
            </div>
            <ClaimBuckets
              search={this.state.querysearch}
              show2={this.state.Secondcard}
              client={this.state.client}
              startdate={this.state.valueOfInput1}
              enddate={this.state.valueOfInput2}
              Location={this.state.Location}
              machinetype={this.state.machinetype}
            />
          </div>
        </div>
    );
  }
}
export default ClaimManagement;
