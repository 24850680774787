import React, { Component } from "react";
import "../../Styles/datacleaning.css";
import "antd/dist/antd.css";
import { ToastContainer, toast } from "react-toastify";
import { Table, Input } from "reactstrap";
import { DatePicker, Card } from "antd";
import axios from "axios";
import { ip } from ".././Apis/Api";
import "../../Styles/data.css";
import swal from "sweetalert";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Button } from "reactstrap";
import { RiDeleteBin7Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import Tabs, { Tab } from "react-best-tabs";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import fileformat from "../../Files/fileformat.xlsx";
import "react-toastify/dist/ReactToastify.css";
import { FormControl, Row, Col, ProgressBar, Spinner } from "react-bootstrap";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import Newsidebar from "../Dashboard/Newsidebar";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";

const { RangePicker } = DatePicker;
// let allClaims = [];
class DataCleaning extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    this.state = {
      all: [],
      toss: false,
      next: [],
      close: [],
      status: [],
      deletemodal: false,
      allclaimdata: [],
      closedclaimdata: [],
      duplicateclaimdata: [],
      statusclaimdata: [],
      reopenclaimdata: [],
      newclaimdata: [],
      allClaims1: [],
      upload: [],
      newClaims1: [],
      ClosedClaims1: [],
      duplicateClaims1: [],
      tabindex: 1,
      duplicate: [],
      fileUploaded: false,
      fileUploading: false,
      fileUploading1: false,
      fileUploading2: false,
      fileUploading3: false,
      fileUploading4: false,
      fileUploading5: false,
      allbutton: true,
      closedbutton: true,
      duplicatebutton: true,
      statusbutton: true,
      reopenbutton: true,
      clearbutton: true,
      loading_file: false,
      reopen: [],
      Allclaims: [],
      new_claim: [],
      upload: [],
      start: start,
      end: end,
      A: false,
      startdate: "",
      claimwise: false,
      enddate: "",
      filterstatus: "custom",
      tabindex: 1,
      startdatetime: "",
      enddatetime: "",
      status: false,
      fetchingcpt: true,
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      cptwise: [],
      allclaims: [],
      new_claims: [],
      closed_claims: [],
      duplicate_claims: [],
      dailyreport: [],
      currentpage1: 0,
      currentpage2: 0,
      currentpage3: 0,
      currentpage4: 0,
      perPage: sessionStorage.getItem("Default_pagination"),
      offset1: 0,
      offset2: 0,
      offset3: 0,
      offset4: 0,
      A: false,
      N: false,
      C: false,
      S: false,
      D: false,
      R: false,
      cleardata: false,
      loading: true,
      loadingall: true,
      id: "",
      search: "",
      start: "",
      end: "",
      fetching: true,
      valueOfInput1: moment().format("MM-DD-YYYY"),
      valueOfInput2: "",
      range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
    };

    this.fileInput = React.createRef();
  }

  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage1: selectedPage,
        offset1: offset,
      },
      () => {
        this.getcptwisereports();
        // this.getnewclaimreports();
        // this.getclosedclaimreports();
        // this.getduplicateclaimreports();
      }
    );
  };

  handlePageClick2 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage2: selectedPage,
        offset2: offset,
      },
      () => {
        //this.getcptwisereports();
        this.getnewclaimreports();
        // this.getclosedclaimreports();
        // this.getduplicateclaimreports();
      }
    );
  };
  handlePageClick3 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage3: selectedPage,
        offset3: offset,
      },
      () => {
        //this.getcptwisereports();
        // this.getnewclaimreports();
        this.getclosedclaimreports();
        // this.getduplicateclaimreports();
      }
    );
  };
  handlePageClick4 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage4: selectedPage,
        offset4: offset,
      },
      () => {
        // this.getcptwisereports();
        // this.getnewclaimreports();
        // this.getclosedclaimreports();
        this.getduplicateclaimreports();
      }
    );
  };

  getcptwisereports = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;

    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}`;
    }

    const res = await axios

      .get(`${ip}/helper/datacleaning?clientId=${this.state.client}`, {
        headers: headers,
      })
      .then((res) => {
        let allClaims = [];
        let temp = "";
        res.data.Allclaims.forEach((claims) => {
          claims.claims.forEach((ele) => {
            if (!temp.includes(ele)) allClaims.push(ele);
            temp.concat(ele);
          });
        });
        console.log(allClaims, "Alldatas");
        const slice = allClaims.slice(
          this.state.offset1,
          this.state.offset1 + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(allClaims.length / this.state.perPage),
          allClaims1: slice,
          fetching: false,
        });

        //  console.log(res, "DataCleaning1");
        console.log(this.state.allClaims1, "Value of all claims");
        // allClaims=[],

        // this.setState({
        //   pageCount2: Math.ceil(res.data.count / this.state.perPage2),
        //   cptwise: res.data.data,
        //   fetchingcpt: false,
        // });
      });
  };
  getnewclaimreports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;

    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}`;
    }
    const res = axios

      .get(url, {
        headers: headers,
      })
      .then((res) => {
        let newClaims = [];
        let temp = "";
        res.data.new_claims.forEach((claims) => {
          claims.claims.forEach((ele) => {
            if (!temp.includes(ele)) newClaims.push(ele);
            temp.concat(ele);
          });
        });
        const slice = newClaims.slice(
          this.state.offset2,
          this.state.offset2 + this.state.perPage
        );
        //console.log(newClaims);
        // console.log(res, "DataCleaning");
        this.setState({
          pageCount: Math.ceil(newClaims.length / this.state.perPage),
          newClaims1: slice,
          fetching: false,
        });

        // console.log(res, "DataCleaning2");
        console.log(this.state.newClaims1, "Value of new claims");
        // console.log(this.state.new_claim, "Show");
        // this.setState({
        //   pageCount2: Math.ceil(res.data.count / this.state.perPage2),
        //   cptwise: res.data.data,
        //   fetchingcpt: false,
        // });
      });
  };

  getclosedclaimreports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;

    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}`;
    }
    const res = axios

      .get(url, {
        headers: headers,
      })
      .then((res) => {
        let closedClaims = [];
        let temp = "";
        res.data.closed_claims.forEach((claims) => {
          claims.claims.forEach((ele) => {
            if (!temp.includes(ele)) closedClaims.push(ele);
            temp.concat(ele);
          });
        });
        const slice = closedClaims.slice(
          this.state.offset3,
          this.state.offset3 + this.state.perPage
        );
        //console.log(closedClaims);
        // console.log(res, "DataCleaning");
        this.setState({
          pageCount: Math.ceil(closedClaims.length / this.state.perPage),
          ClosedClaims1: slice,
          fetching: false,
        });

        //console.log(res, "DataCleaning3");
        console.log(this.state.ClosedClaims1, "Value of closed claims");
        // console.log(this.state.new_claim, "Show");
        // this.setState({
        //   pageCount2: Math.ceil(res.data.count / this.state.perPage2),
        //   cptwise: res.data.data,
        //   fetchingcpt: false,
        // });
      });
  };

  getduplicateclaimreports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;

    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      url = `${ip}/helper/datacleaning?clientId=${this.state.client}`;
    }
    const res = axios

      .get(url, {
        headers: headers,
      })
      .then((res) => {
        let duplicateClaims = [];
        let temp = "";
        res.data.duplicate_claims.forEach((claims) => {
          claims.claims.forEach((ele) => {
            if (!temp.includes(ele)) duplicateClaims.push(ele);
            temp.concat(ele);
          });
        });
        const slice = duplicateClaims.slice(
          this.state.offset4,
          this.state.offset4 + this.state.perPage
        );
        //console.log(duplicateClaims);
        // console.log(res, "DataCleaning");
        this.setState({
          pageCount: Math.ceil(duplicateClaims.length / this.state.perPage),
          duplicateClaims1: slice,
          fetching: false,
        });

        // console.log(res, "DataCleaning4");
        console.log(this.state.duplicateClaims1, "Value of duplicate claims");
        // console.log(this.state.new_claim, "Show");
        // this.setState({
        //   pageCount2: Math.ceil(res.data.count / this.state.perPage2),
        //   cptwise: res.data.data,
        //   fetchingcpt: false,
        // });
      });
  };

  onFileUpload = async (e) => {
    if (!this.state.file) {
      // Display an error message or handle the validation as needed
      toast.error("Please select a file before uploading", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      return;
    }
    this.setState({ fileUploaded: !this.state.fileUploaded });
    e.preventDefault();
    const value = new FormData();
    value.append("file", this.fileInput.current.files[0]);
    value.append("clientids", JSON.stringify(this.state.client));
    const headers = {
      "Content-Type": `multipart/form-data`,
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    this.setState({
      A: true,
      C: true,
      S: true,
      D: true,
      N: false,
      R: true,
      cleardata: true,
    });
    await axios({
      method: "post",
      url: `${ip}/helper/newclaims`,
      data: value,
      headers: headers,
    })
      .then((response) => {
        //handle success
        console.log("Success", response);
        if (response.status === 200) {
          this.setState({
            fileUploaded: !this.state.fileUploaded,
          });
          toast.success("File uploaded successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            cleardata: false,
          });
        }
      })
      .catch((err) => {
        //handle error
        console.log("Failed", err);
        this.setState({ fileUploaded: !this.state.fileUploaded });

        if (err.response) {
          if (err.response.status === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.status === 400) {
            toast.error("Please include all the fields in excel data", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  onFileChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };
  deleterequests = async (DataID) => {
    //api call to delete requests
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/upload/${DataID}`, {
        headers: headers,
      })
      .then((req) => {
        alert("File Deleted Successfully");

        const requests = this.state.upload.filter(
          (data) => data._id !== DataID
        );
        this.getfiles();
      })
      .catch((err) => {});
  };

  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  togglemodal = () => {
    this.setState({
      fileUploading: !this.state.fileUploading,
    });
  };
  togglemodal1 = () => {
    this.setState({
      fileUploading1: !this.state.fileUploading1,
    });
  };
  togglemodal2 = () => {
    this.setState({
      fileUploading2: !this.state.fileUploading2,
    });
  };
  togglemodal3 = () => {
    this.setState({
      fileUploading3: !this.state.fileUploading3,
    });
  };
  togglemodal4 = () => {
    this.setState({
      fileUploading4: !this.state.fileUploading4,
    });
  };
  togglemodal5 = () => {
    this.setState({
      fileUploading5: !this.state.fileUploading5,
    });
  };
  DeleteModal() {
    swal({
      //title: "Are you sure?",
      text: "Are u sure do you want to delete?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleterequests(this.state.id);
      }
    });
  }

  allclaim = () => {
    this.setState({
      claimwise: false,
      A: false,
      C: true,
      S: true,
      D: true,
      N: true,
      R: true,
      cleardata: true,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/allclaims?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/helper/allclaims?clientId=${this.state.client}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          loading_file: false,
        });
        if (res.status === 200) {
          // alert("All claims Completed")
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            // fileUploading:false,
            allbutton: false,
            cleardata: false,
          });
          // toast.success("Completed", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   progress: undefined,
          // });
        }
      });
  };

  newclaim = () => {
    this.setState({
      // next: !this.state.next,

      claimwise: true,
    });
  };

  closedclaim = () => {
    // this.timerpopup();
    this.setState({
      A: true,
      C: false,
      S: true,
      D: true,
      N: true,
      R: true,
      cleardata: true,
      claimwise: false,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/closedclaims?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/helper/closedclaims?clientId=${this.state.client}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          loading_file: false,
        });
        if (res.status === 200) {
          // alert("Closed claims Completed")
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            cleardata: false,
            closedbutton: false,
          });

          // toast.success("Completed", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   progress: undefined,
          // });
        }
      });
  };

  duplicateclaim = () => {
    this.setState({
      A: true,
      C: true,
      S: true,
      D: false,
      N: true,
      R: true,
      cleardata: true,
      claimwise: false,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/duplicateclaims?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/helper/duplicateclaims?clientId=${this.state.client}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          loading_file: false,
        });
        if (res.status === 200) {
          // alert("Duplicate claims Completed")
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            cleardata: false,
            duplicatebutton: false,
            // fileUploading2:false,
          });
          // toast.success("Completed", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   progress: undefined,
          // });
        }
      });
  };

  reopenclaim = () => {
    this.setState({
      A: true,
      C: true,
      S: true,
      D: true,
      N: true,
      R: false,
      cleardata: true,
      claimwise: false,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/reopenclaims?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/helper/reopenclaims?clientId=${this.state.client}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          loading_file: false,
        });
        if (res.status === 200) {
          // alert("Reopen claims Completed")
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            cleardata: false,
            reopenbutton: false,
            // fileUploading4:false,
          });
          // toast.success("Completed", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   progress: undefined,
          // });
        }
      });
  };

  statusclaim = () => {
    this.setState({
      A: true,
      C: true,
      S: false,
      D: true,
      N: true,
      R: true,
      cleardata: true,
      claimwise: false,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/statuschange?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/helper/statuschange?clientId=${this.state.client}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          loading_file: false,
        });
        if (res.status === 200) {
          // alert("Status change completed")
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            cleardata: false,
            statusbutton: false,
            // fileUploading3:false,
          });
          // toast.success("Completed", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   progress: undefined,
          // });
        }
      });
  };
  clearclaim = () => {
    this.setState({
      A: true,
      C: true,
      S: true,
      D: true,
      N: true,
      R: true,
      cleardata: false,
      claimwise: false,
    });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/helper/cleardatacleaning?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/helper/cleardatacleaning?clientId=${this.state.client}`;
    }
    const res = axios
      .delete(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          loading_file: false,
        });
        if (res.status === 200) {
          // alert("Status change completed")
          this.setState({
            A: false,
            C: false,
            S: false,
            D: false,
            N: false,
            R: false,
            cleardata: false,
            clearbutton: false,
            // fileUploading3:false,
          });
          // toast.success("Completed", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   progress: undefined,
          // });
        }
      });
  };
  timerpopup = async () => {
    setTimeout(() => {
      this.setState({ fileUploading3: !this.state.fileUploading3 });
    }, 150000);
  };
  componentDidMount() {
    //this.getcptwisereports();
    // this.getnewclaimreports();
    // this.getclosedclaimreports();
    // this.getduplicateclaimreports();
    // this.onFileUpload();
  }
  render() {
    return (
      <div>
      <Newsidebar name="Data Cleaning" />
      <div className="align-row" style={{ overflow: "hidden" }}>
        <ToastContainer></ToastContainer>
        <div className="align-column">
          <div className="DataCleaning">
            <div>
              <Col className="col-1">
                <Button
                  size="md"
                  className="datauploadcss"
                  // onClick={this.allclaim}
                  onClick={() => {
                    this.togglemodal();
                  }}
                  disabled={this.state.A}
                >
                  All Claims
                </Button>
              </Col>
            </div>
            <div class="vertical-line"></div>
            <div id="dialog2" class="triangle_down1"></div>

            <div>
              <Col className="col-1">
                <Button
                  size="md"
                  className="datauploadcss"
                  onClick={this.newclaim}
                  disabled={this.state.N}
                >
                  New Claims
                </Button>
              </Col>
            </div>
            <div class="vertical-line"></div>
            <div id="dialog2" class="triangle_down1"></div>

            <div>
              <Col className="col-1">
                <Button
                  color="secondary"
                  size="md"
                  className="datauploadcss"
                  onClick={() => {
                    this.togglemodal1();
                  }}
                  disabled={this.state.C}
                >
                  Closed Claims
                </Button>
              </Col>
            </div>
            <div class="vertical-line"></div>
            <div id="dialog2" class="triangle_down1"></div>

            <div>
              <Col className="col-1">
                <Button
                  color="secondary"
                  size="md"
                  className="datauploadcss"
                  onClick={() => {
                    this.togglemodal2();
                  }}
                  disabled={this.state.D}
                >
                  Duplicate Claims
                </Button>
              </Col>
            </div>
            <div class="vertical-line"></div>
            <div id="dialog2" class="triangle_down1"></div>

            <div>
              <Col className="col-1">
                <Button
                  color="secondary"
                  size="md"
                  className="datauploadcss"
                  onClick={this.togglemodal3}
                  disabled={this.state.S}
                >
                  Status Change
                </Button>
              </Col>
            </div>
            <div class="vertical-line"></div>
            <div id="dialog2" class="triangle_down1"></div>

            <div>
              <Col className="col-1">
                <Button
                  color="secondary"
                  size="md"
                  className="datauploadcss"
                  onClick={this.togglemodal4}
                  disabled={this.state.R}
                >
                  Reopen Claims
                </Button>
              </Col>
            </div>
            <div class="vertical-line"></div>
            <div id="dialog2" class="triangle_down1"></div>

            <div>
              <Col className="col-1">
                <Button
                  color="secondary"
                  size="md"
                  className="datauploadcss"
                  onClick={this.togglemodal5}
                  disabled={this.state.cleardata}
                >
                  Clear Data Cleaning
                </Button>
              </Col>
            </div>
          </div>

          {this.state.claimwise && (
            <div>
              <div className="align-row items">
                <form method="post" style={{ width: "17%" }}>
                  <div class="files">
                    <input
                      type="file"
                      id="file"
                      required
                      ref={this.fileInput}
                      onChange={this.onFileChange}
                      style={{ display: "none" }}
                      accept=".xlsx"
                    />
                    <label for="file" className="filelabel">
                      {this.state.file ? (
                        <p align="center" style={{ color: "green" }}>
                          {this.state.file.name}
                        </p>
                      ) : (
                        <a className="dropfile">Drop files here or browse</a>
                      )}
                    </label>
                  </div>
                </form>
              </div>
              <div className="boxcss" style={{ marginLeft: "55px" }}>
                <Table className="tablewidth">
                  <thead>
                    <tr>
                      <div>
                        <Row>
                          <Col className="col-4"></Col>

                          <Col className="col-1">
                            <Button
                              outline
                              color="secondary"
                              size="sm"
                              className="exportcss"
                            >
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "#a8adad",
                                }}
                                to={fileformat}
                                download="claimdata.xlsx"
                                target="_blank"
                              >
                                {" "}
                                Sample
                              </Link>
                            </Button>
                          </Col>
                          <Col className="col-1">
                            <Button
                              outline
                              color="secondary"
                              size="sm"
                              onClick={this.onFileUpload}
                              className="uploadcss"
                            >
                              Upload
                            </Button>
                          </Col>
                        </Row>
                        <hr />
                      </div>
                    </tr>
                  </thead>
                </Table>
                {this.state.upload.length !== 0 ? (
                  <div className="data-paginate">
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                ) : (
                  <p align="center">
                    <br />
                    No uploads found!
                  </p>
                )}
              </div>
            </div>
          )}
          {this.state.deletemodal && (
            <Modal
              isOpen={this.state.deletemodal}
              onHide={this.toggledelete}
              // onExit={this.reloadpage}
              centered
            >
              <ModalHeader toggle={this.toggledelete}>Delete Data</ModalHeader>
              <ModalBody
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto",
                }}
              >
                <Row style={{ margin: "0px" }}>Do you want to delete this?</Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid #268da5",
                    color: "#268da5",
                    backgroundColor: "#fff",
                  }}
                  onClick={this.toggledelete}
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: "#268da5" }}
                  onClick={() => {
                    this.deleterequests(this.state.id);
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.fileUploaded && (
            <Modal isOpen={this.state.fileUploaded}>
              <ModalHeader>File Processing Data</ModalHeader>
              <ModalBody>
                <p align="left">
                  Please wait for sometime...&nbsp;&nbsp;Your file is being
                  processed!
                </p>
                <button className="">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Processing....
                </button>
              </ModalBody>
            </Modal>
          )}
          {this.state.fileUploading && (
            <Modal isOpen={this.togglemodal} centered>
              <ModalHeader>All claims</ModalHeader>
              <ModalBody>
                <p align="left">
                  Please wait for sometime...&nbsp;&nbsp;All claims files have been processed!
                  Click to start
                </p>
                {this.state.loading_file === true && (
                  <div class="lds-dual-ring"></div>
                )}
                {this.state.allbutton === false && (
                  <p
                    style={{
                      fontSize: "25px",
                      textAlign: "center",
                      color: "green",
                      fontFamily: "monospace",
                    }}
                  >
                    All claims Completed
                  </p>
                )}
              </ModalBody>
              <ModalFooter>
                <button
                  className="datauploadcss1"
                  onClick={() => {
                    this.setState({ loading_file: true });
                    this.allclaim();
                  }}
                >
                  Start
                </button>
                {this.state.allbutton === "true" ? (
                  <button disabled={this.state.allbutton}>Close</button>
                ) : (
                  <button
                    className="datauploadcss1"
                    disabled={this.state.allbutton}
                    onClick={this.togglemodal}
                  >
                    Close
                  </button>
                )}
              </ModalFooter>
            </Modal>
          )}
          {this.state.fileUploading1 && (
            <Modal isOpen={this.togglemodal1} centered>
              <ModalHeader>Closed Claims</ModalHeader>
              <ModalBody>
                <p align="left">
                  Please wait for sometime...&nbsp;&nbsp;Closed claims file has been
                  processed! Click to start
                </p>
                {this.state.loading_file === true && (
                  <div class="lds-dual-ring"></div>
                )}
                {this.state.closedbutton === false && (
                  <p
                    style={{
                      fontSize: "25px",
                      textAlign: "center",
                      color: "green",
                      fontFamily: "monospace",
                    }}
                  >
                    Closed claims Completed
                  </p>
                )}
              </ModalBody>
              <ModalFooter>
                <button
                  className="datauploadcss1"
                  onClick={() => {
                    this.setState({ loading_file: true });
                    this.closedclaim();
                  }}
                >
                  Start
                </button>
                {this.state.closedbutton === "true" ? (
                  <button disabled={this.state.closedbutton}>Close</button>
                ) : (
                  <button
                    className="datauploadcss1"
                    disabled={this.state.closedbutton}
                    onClick={this.togglemodal1}
                  >
                    Close
                  </button>
                )}
              </ModalFooter>
            </Modal>
          )}
          {this.state.fileUploading2 && (
            <Modal isOpen={this.togglemodal2} centered>
              <ModalHeader>Duplicate Claims</ModalHeader>
              <ModalBody>
                <p align="left">
                  Please wait for sometime...&nbsp;&nbsp;Duplicate claims file has been
                  processed! Click to start
                </p>
                {this.state.loading_file === true && (
                  <div class="lds-dual-ring"></div>
                )}
                {this.state.duplicatebutton === false && (
                  <p
                    style={{
                      fontSize: "25px",
                      textAlign: "center",
                      color: "green",
                      fontFamily: "monospace",
                    }}
                  >
                    Duplicate claims Completed
                  </p>
                )}
              </ModalBody>
              <ModalFooter>
                <button
                  className="datauploadcss1"
                  onClick={() => {
                    this.setState({ loading_file: true });
                    this.duplicateclaim();
                  }}
                >
                  Start
                </button>
                {this.state.duplicatebutton === "true" ? (
                  <button disabled={this.state.duplicatebutton}>Close</button>
                ) : (
                  <button
                    className="datauploadcss1"
                    disabled={this.state.duplicatebutton}
                    onClick={this.togglemodal2}
                  >
                    Close
                  </button>
                )}
              </ModalFooter>
            </Modal>
          )}
          {this.state.fileUploading3 && (
            <Modal
              isOpen={this.togglemodal3}
              // onChange={this.timerpopup}
              centered
            >
              <ModalHeader>Status Change</ModalHeader>
              <ModalBody>
                <p align="left">
                  Please wait for sometime...&nbsp;&nbsp;Status Change file has been
                  processed! Click to start
                </p>
                {this.state.loading_file === true && (
                  <div class="lds-dual-ring"></div>
                )}
                {this.state.statusbutton === false && (
                  <p
                    style={{
                      fontSize: "25px",
                      textAlign: "center",
                      color: "green",
                      fontFamily: "monospace",
                    }}
                  >
                    Status change Completed
                  </p>
                )}
              </ModalBody>
              <ModalFooter>
                <button
                  className="datauploadcss1"
                  onClick={() => {
                    this.setState({ loading_file: true });
                    this.statusclaim();
                  }}
                >
                  Start
                </button>
                {this.state.statusbutton === "true" ? (
                  <button disabled={this.state.statusbutton}>Close</button>
                ) : (
                  <button
                    className="datauploadcss1"
                    disabled={this.state.statusbutton}
                    onClick={this.togglemodal3}
                  >
                    Close
                  </button>
                )}
              </ModalFooter>
            </Modal>
          )}
          {this.state.fileUploading4 && (
            <Modal isOpen={this.togglemodal4} centered>
              <ModalHeader>Reopen Claims</ModalHeader>
              <ModalBody>
                <p align="left">
                  Please wait for sometime...&nbsp;&nbsp;Reopen claims file has been
                  processed! Click to start
                </p>
                {this.state.loading_file === true && (
                  <div class="lds-dual-ring"></div>
                )}
                {this.state.reopenbutton === false && (
                  <p
                    style={{
                      fontSize: "25px",
                      textAlign: "center",
                      color: "green",
                      fontFamily: "monospace",
                    }}
                  >
                    Reopen claims Completed
                  </p>
                )}
              </ModalBody>
              <ModalFooter>
                <button
                  className="datauploadcss1"
                  onClick={() => {
                    this.setState({ loading_file: true });
                    this.reopenclaim();
                  }}
                >
                  Start
                </button>
                {this.state.reopenbutton === "true" ? (
                  <button disabled={this.state.reopenbutton}>Close</button>
                ) : (
                  <button
                    className="datauploadcss1"
                    disabled={this.state.reopenbutton}
                    onClick={this.togglemodal4}
                  >
                    Close
                  </button>
                )}
              </ModalFooter>
            </Modal>
          )}
          {this.state.fileUploading5 && (
            <Modal isOpen={this.togglemodal5} centered>
              <ModalHeader>Clear Claims</ModalHeader>
              <ModalBody>
                <p align="left">
                  Please wait for sometime...&nbsp;&nbsp;Clear claims file has been
                  processed! Click to start
                </p>
                {this.state.loading_file === true && (
                  <div class="lds-dual-ring"></div>
                )}
                {this.state.clearbutton === false && (
                  <p
                    style={{
                      fontSize: "25px",
                      textAlign: "center",
                      color: "green",
                      fontFamily: "monospace",
                    }}
                  >
                    Clear Data Completed
                  </p>
                )}
              </ModalBody>
              <ModalFooter>
                <button
                  className="datauploadcss1"
                  onClick={() => {
                    this.setState({ loading_file: true });
                    this.clearclaim();
                  }}
                >
                  Start
                </button>
                {this.state.clearbutton === "true" ? (
                  <button disabled={this.state.clearbutton}>Close</button>
                ) : (
                  <button
                    className="datauploadcss1"
                    disabled={this.state.clearbutton}
                    onClick={this.togglemodal5}
                  >
                    Close
                  </button>
                )}
              </ModalFooter>
            </Modal>
          )}
        </div>
      </div>
      </div>
    );
  }
}
export default DataCleaning;
