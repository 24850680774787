import React, { Component } from "react";
import axios from "axios";
import "../../../Styles/admin.css";
import Newsidebar from "../../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button} from "reactstrap";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import "../../../Styles/allocation.css";
import "../../../Styles/loader.scss";
import Checkbox from "../../Allocation/Checkbox";
import { Label } from "reactstrap";
import "../../../Styles/loading.scss";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ip } from "../../Apis/Api";
import { BiUpArrowAlt } from "react-icons/bi";
import { BiDownArrowAlt } from "react-icons/bi";

class Claimsubcategoryunallocate1 extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.state = {
      modal: false,
      searchtext: "",
      PatientFirstName: "",
      PatientLastName: "",
      patientAccountNumber: "",
      searchdataresponse: [],
      searchclaimdata: [],
      claims: [],
      status: [],
      webunallocate: [],
      currentpage1: 0,
      perPage1: 10,
      currentpage2: 0,
      perPage2: 10,
      queryclient: sessionStorage.getItem("queryclient"),
      categoryname: sessionStorage.getItem("querycategory"),
      currentpage3: 0,
      perPage3: 10,
      allocate: [],
      reports: [],
      ar: [],
      loading: true,
      cptdata: [],
      payervalue: [],
      claimids: [],
      cptselect: [],
      UserAllocate:
      sessionStorage.getItem("firstname") +
      " " +
      sessionStorage.getItem("lastname"),
      webclaim: [],
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      location: sessionStorage.getItem("queryLocation"),
      offset: 0,
      currentpage: 0,
      perPage: 10,
      offsetsearch: 0,
      currentpagesearch: 0,
      perPagesearch: 10,
      lastname: "",
      firstname: "",
      role: "",
      claimfield: "",
      claimvalue: "",
      phno: "",
      email: "",
      code: "",
      selectedpayer: "",
      querycategory: sessionStorage.getItem("querycategory"),
      claimtype: sessionStorage.getItem("claimtype"),
      CPT: "",
      pripayer: "",
      agegrp: "",
      query: [],
      fetching1: true,
      fetching2: true,
      fetching3: true,
      payercategories: [],
      payerselect: [],
      notselect: true,
      ids: [],
      tabindex: 1,
      users: [],
      selecteduser: [],
      selectedage: "",
      checkedAll: false,
      alldataresponse: [],
      selectuserErr: "",
      claim_id: "",
      role_type: sessionStorage.getItem("role_type"),
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
      value: sessionStorage.getItem("claimurl"),
      clientslist: [],
      opendropdown: false,
      Location: "",
      doiagegrp: "",
      sortstate: "ASC",
      openicon: false,
      web: [],
      manual: [],
      ivr: [],
      currentpagesort:0,
      sorttable: false, 
      sortdata:[],
      perPagesort:10,     
      sortvalue:"",
      fieldvalue:"",
      offsetsort:0,
    };
  }

  toggle = () => {
    if (this.state.ids.length !== 0) {
      const { modal } = this.state;
      this.setState({ modal: !modal });
    } else {
      alert("Select Claims to allocate");
    }
  };

  toggledropdown = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
     await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  handlePageClickWeb = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage1;

    this.setState(
      {
        currentpage1: selectedPage,
        offset1: offset,
      },
      () => {
        this.getwebclaims();
      }
    );
  };
  getclaims = async () => {
    //api call to get all users

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.client) {
      if (this.state.Location) {
        url = `${ip}/allocation/all?clientId=${this.state.client}&pageno=${
          this.state.currentpage + 1
        }&Location=${this.state.Location}&count=${this.state.perPage}`;
      } else {
        url = `${ip}/allocation/all?clientId=${this.state.client}&pageno=${
          this.state.currentpage + 1
        }&count=${this.state.perPage}`;
      }
    } else {
      url = `${ip}/allocation/all?pageno=${this.state.currentpage + 1}&count=${
        this.state.perPage
      }`;
    }
     axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount: Math.ceil(res.data.count / this.state.perPage),
          claims: res.data.claims,
          fetching1: false,
          fetching2: false,
          fetching3: false,
        });
      })
      .catch((err) => {});
  };
  selectedpayer = async () => {
    //to search
    var query;
    if (
      this.state.payerselect.length === 1 &&
      this.state.cptselect.length === 0 &&
      this.state.selectedage === ""
    ) {
      query = `payercategory=${this.state.payerselect[0].category_name}`;
    } else if (
      this.state.cptselect.length === 1 &&
      this.state.payerselect.length === 0 &&
      this.state.selectedage === ""
    ) {
      query = `cptcode=${this.state.cptselect[0].cpt_code}`;
    } else if (
      this.state.cptselect.length === 0 &&
      this.state.payerselect.length === 0 &&
      this.state.selectedage !== ""
    ) {
      query = `agegrp=${this.state.selectedage}`;
    } else if (
      this.state.payerselect.length === 1 &&
      this.state.cptselect.length === 1 &&
      this.state.selectedage === ""
    ) {
      query = `payercategory=${this.state.payerselect[0].category_name}&cptcode=${this.state.cptselect[0].cpt_code}`;
    } else if (
      this.state.payerselect.length === 1 &&
      this.state.selectedage !== "" &&
      this.state.cptselect.length === 0
    ) {
      query = `payercategory=${this.state.payerselect[0].category_name}&agegrp=${this.state.selectedage}`;
    } else if (
      this.state.selectedage !== "" &&
      this.state.cptselect.length === 1 &&
      this.state.payerselect.length === 0
    ) {
      query = `agegrp=${this.state.selectedage}&cptcode=${this.state.cptselect[0].cpt_code}`;
    } else if (
      this.state.payerselect.length === 1 &&
      this.state.cptselect.length === 1 &&
      this.state.selectedage !== ""
    ) {
      query = `payercategory=${this.state.payerselect[0].category_name}&cptcode=${this.state.cptselect[0].cpt_code}&agegrp=${this.state.selectedage}`;
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };

     axios
      .get(`${ip}/allocation/advancedFilter?${query}`, {
        headers: headers,
      })
      .then((res) => {
        const slice = res.data.claims.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          pageCount: Math.ceil(res.data.claims.length / this.state.perPage),
          claims: slice,
        });
      })

      .catch((err) => {});
  };
  getusers = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/users/all?clientId=${this.state.client}`, { headers: headers })
      .then((res) => {
        let data = res.data.user;
        if (this.state.client) {
          data = data.filter((item) => {
            return (
              item.role === "Team Member" 
            );
          });
        } else {
          data = data.filter((item) => {
            return item.role === "Admin" || item.role === "SuperAdmin";
          });
        }
        this.setState({ users: data });
      })

      .catch((err) => {});
  };
  handlePagesort = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPagesort;

    this.setState(
      {
        currentpagesort: selectedPage,
        offsetsort: offset,
      },
      () => {
        this.getsorting();
      }
    );
  };
  getsorting = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.categoryname === "Not In System") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&unallocatedpageno=${this.state.currentpagesort}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&unallocatedpageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&unallocatedpageno=${this.state.currentpagesort}&Location=${this.state.location}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=NIS&unallocatedpageno=${this.state.currentpagesort}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=NIS&unallocatedpageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=NIS&unallocatedpageno=${this.state.currentpagesort}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
        }
      }
    } else if (this.state.categoryname === "Closed/Already Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&unallocatedpageno=${this.state.currentpagesort}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&unallocatedpageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&unallocatedpageno=${this.state.currentpagesort}&Location=${this.state.location}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&unallocatedpageno=${this.state.currentpagesort}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Closed/Already Paid&unallocatedpageno=${this.state.currentpagesort}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Closed/Already Paid&unallocatedpageno=${this.state.currentpagesort}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
        }
      }
    } else if (this.state.categoryname === "Recently submitted") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&unallocatedpageno=${this.state.currentpage1}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Submitted&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Submitted&unallocatedpageno=${this.state.currentpage1}&type=${this.state.claimtype}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}`;
        }
      }
    } else if (this.state.categoryname === "Recently worked") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&type=${this.state.claimtype}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&type=${this.state.claimtype}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Worked&unallocatedpageno=${this.state.currentpage1}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=Recently Worked&unallocatedpageno=${this.state.currentpage1}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&type=${this.state.claimtype}`;
        }
      }
    } else if (this.state.categoryname === "Partially Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&ClaimStatus=Partially Paid&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&ClaimStatus=Partially Paid&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&type=${this.state.claimtype}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&type=${this.state.claimtype}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?unallocatedpageno=${this.state.currentpage1}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
        } else {
          url = `${ip}/claimstatus/sorting?unallocatedpageno=${this.state.currentpage1}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&type=${this.state.claimtype}`;
        }
      }
    } else if (this.state.categoryname === "System error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&ClaimStatus=System erro&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&ClaimStatus=System erro&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&type=${this.state.claimtype}`;
          } else {
            url = `${ip}/claimstatus/sorting?clientId=${this.state.queryclient}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&type=${this.state.claimtype}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?unallocatedpageno=${this.state.currentpage1}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
        } else {
          url = `${ip}/claimstatus/sorting?unallocatedpageno=${this.state.currentpage1}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&type=${this.state.claimtype}`;
        }
      }
    } else if (this.state.categoryname === "Availity Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
          } else {
            url = `${ip}/claimstatus/sorting?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&type=${this.state.claimtype}`;
          } else {
            url = `${ip}/claimstatus/sorting?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&type=${this.state.claimtype}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
        } else {
          url = `${ip}/claimstatus/sorting?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&type=${this.state.claimtype}`;
        }
      }
    } else if (this.state.categoryname === "Availity Empty") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?ClaimStatus=AvailityEmpty&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&clientId=${this.state.queryclient}&unallocatedpageno=${this.state.currentpage2}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
          } else {
            url = `${ip}/claimstatus/sorting?ClaimStatus=AvailityEmpty&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&clientId=${this.state.queryclient}&unallocatedpageno=${this.state.currentpage2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?ClaimStatus=AvailityEmpty&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&clientId=${this.state.queryclient}&unallocatedpageno=${this.state.currentpage2}&Location=${this.state.location}&type=${this.state.claimtype}`;
          } else {
            url = `${ip}/claimstatus/sorting?ClaimStatus=AvailityEmpty&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&clientId=${this.state.queryclient}&unallocatedpageno=${this.state.currentpage2}&type=${this.state.claimtype}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?ClaimStatus=AvailityEmpty&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage2}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
        } else {
          url = `${ip}/claimstatus/sorting?ClaimStatus=AvailityEmpty&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage2}&type=${this.state.claimtype}`;
        }
      }
    } else if (this.state.categoryname === "Availity Searches") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
          } else {
            url = `${ip}/claimstatus/sorting?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/sorting?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&type=${this.state.claimtype}`;
          } else {
            url = `${ip}/claimstatus/sorting?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&type=${this.state.claimtype}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/sorting?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}&type=${this.state.claimtype}`;
        } else {
          url = `${ip}/claimstatus/sorting?${this.state.value}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&unallocatedpageno=${this.state.currentpage1}&type=${this.state.claimtype}`;
        }
      }
    }else{
      url = `${ip}/claimstatus/claimsubcategory/claims/sorting?unallocatedpageno=${this.state.currentpagesort}&field=${this.state.fieldvalue}&sorting=${this.state.sortvalue}&count=${this.state.perPagesort}&type=${this.state.claimtype}&${this.state.value}`;
    }
     axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          alldataresponse: res.data.claims,
        });
        if (this.state.categoryname === "Not In System") {
          this.setState({
            pageCountSort: Math.ceil(res.data.claimcount / this.state.perPage1),
            sortdata: res.data.claims,
          });
        } else if (this.state.categoryname === "Closed/Already Paid") {
          this.setState({
            pageCountSort: Math.ceil(res.data.claimcount / this.state.perPage1),
            sortdata: res.data.claims,
          });
        } else if (this.state.categoryname === "Recently submitted") {
          this.setState({
            pageCountSort: Math.ceil(res.data.claimcount / this.state.perPage1),
            sortdata: res.data.claims,
          });
        } else if (this.state.categoryname === "System error") {
          this.setState({
            pageCountSort: Math.ceil(res.data.claimcount / this.state.perPage1),
            sortdata: res.data.claims,
          });
        } else if (this.state.categoryname === "Recently worked") {
          this.setState({
            pageCountSort: Math.ceil(res.data.claimcount / this.state.perPage1),
            sortdata: res.data.claims,
          });
        } else if (this.state.categoryname === "Partially Paid") {
          this.setState({
            pageCountSort: Math.ceil(res.data.claimcount / this.state.perPage1),
            sortdata: res.data.claims,
          });
        } else {
          this.setState({
            pageCountSort: Math.ceil(res.data.claimcount / this.state.perPage1),
            sortdata: res.data.claimdetails,
          });
        }
      })
      .catch((err) => {});
  };

  onfirstselect = () => {
    this.setState({
      ids: [],
      offset: 0,
      currentpage: 0,
      perPage: 50,
    });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        if (this.state.notselect) {
          this.getclaims();
        } else {
          this.selectedpayer();
        }
      }
    );
  };
  handlePageClick1 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpagesearch: selectedPage,
        offset: offset,
      },
      () => {
        if (this.state.notselect) {
          this.filtersearch();
        } else {
          this.selectedpayer();
        }
      }
    );
  };

  allocateclaims = () => {
    const isValid = this.validate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        ids: this.state.ids,
        logdetails:{
          // newUser:this.state.username,
          admin:this.state.UserAllocate,
          module:"Claim Management Allocation",
          clientId:this.state.client,

          // ClaimID:["11751701/25/2022INS-206"],
          // admin:"demoImagnum",
          // module:"Allocation",
          // clientId":2004
          }
      };
       axios
        .put(
          `${ip}/allocation/allocate/${this.state.selecteduser[0]._id}`,
          value,
          {
            headers: headers,
          }
        )
        .then((res) => {
          alert(
            `claims allocated to user ${this.state.selecteduser[0].first_name}`
          );
          this.getwebclaims();
          this.resetuser();
          this.getclaims();
          // this.toggle();
          // window.location.reload();
        })

        .catch((err) => {});
    }
  };
  getwebclaims = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.categoryname === "Not In System") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=NIS&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=NIS&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=NIS&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=NIS&unallocatedpageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?ClaimStatus=NIS&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?ClaimStatus=NIS&unallocatedpageno=${this.state.currentpage1}`;
        }
      }
    } else if (this.state.categoryname === "Closed/Already Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Closed/Already Paid&unallocatedpageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?ClaimStatus=Closed/Already Paid&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?ClaimStatus=Closed/Already Paid&unallocatedpageno=${this.state.currentpage1}`;
        }
      }
    } else if (this.state.categoryname === "Recently submitted") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Recently Submitted&unallocatedpageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?ClaimStatus=Recently Submitted&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?ClaimStatus=Recently Submitted&unallocatedpageno=${this.state.currentpage1}`;
        }
      }
    } else if (this.state.categoryname === "Recently worked") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&aunllocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Recently Worked&unallocatedpageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?ClaimStatus=Recently Worked&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/claims/nis/${this.state.claimtype}?ClaimStatus=Recently Worked&unallocatedpageno=${this.state.currentpage1}`;
        }
      }
    } else if (this.state.categoryname === "System error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/systemerror/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=System error&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/systemerror/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=System error&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/systemerror/${this.state.claimtype}?clientId=${this.state.queryclient}&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/systemerror/${this.state.claimtype}?clientId=${this.state.queryclient}&unallocatedpageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/systemerror/${this.state.claimtype}?unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/systemerror/${this.state.claimtype}?unallocatedpageno=${this.state.currentpage1}`;
        }
      }
    } else if (this.state.categoryname === "Partially Paid") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Partially Paid&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/${this.state.claimtype}?clientId=${this.state.queryclient}&ClaimStatus=Partially Paid&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/partiallypaid/${this.state.claimtype}?clientId=${this.state.queryclient}&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/partiallypaid/${this.state.claimtype}?clientId=${this.state.queryclient}&unallocatedpageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/partiallypaid/${this.state.claimtype}?unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/partiallypaid/${this.state.claimtype}?unallocatedpageno=${this.state.currentpage1}`;
        }
      }
    } 
    else if (this.state.categoryname === "Upload Error") {
      if (this.state.queryclient) {
        if (this.state.querystartdate && this.state.queryenddate) {
          if (this.props.Location) {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=${this.state.claimtype}&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          } else {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=${this.state.claimtype}&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
          }
        } else {
          if (this.props.Location) {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=${this.state.claimtype}&unallocatedpageno=${this.state.currentpage1}&Location=${this.state.location}`;
          } else {
            url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=${this.state.claimtype}&unallocatedpageno=${this.state.currentpage1}`;
          }
        }
      } else {
        if (this.state.querystartdate && this.state.queryenddate) {
          url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=${this.state.claimtype}&unallocatedpageno=${this.state.currentpage1}&start_date=${this.state.querystartdate}&end_date=${this.state.queryenddate}`;
        } else {
          url = `${ip}/claimstatus/uploaderror?clientId=${this.state.queryclient}&type=${this.state.claimtype}&unallocatedpageno=${this.state.currentpage1}`;
        }
      }
    }
    else {
      url = `${ip}/claimstatus/claimsubcategory/claims/${this.state.claimtype}?${this.state.value}&unallocatedpageno=${this.state.currentpage1}`;
    }

     axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          alldataresponse: res.data.claims,
        });
        if (this.state.categoryname === "Not In System") {
          this.setState({
            web: res.data.claims,
            pageCount1: Math.ceil(res.data.claimcount / this.state.perPage1),
            webclaim: res.data.claims,
          });
        } else if (this.state.categoryname === "Closed/Already Paid") {
          this.setState({
            pageCount1: Math.ceil(res.data.claimcount / this.state.perPage1),
            webclaim: res.data.claims,
            web: false,
          });
        } else if (this.state.categoryname === "Recently submitted") {
          this.setState({
            pageCount1: Math.ceil(res.data.claimcount / this.state.perPage1),
            webclaim: res.data.claims,
            web: false,
          });
        } else if (this.state.categoryname === "System error") {
          this.setState({
            pageCount1: Math.ceil(res.data.claimcount / this.state.perPage1),
            webclaim: res.data.claims,
            web: false,
          });
        } else if (this.state.categoryname === "Recently worked") {
          this.setState({
            pageCount1: Math.ceil(res.data.claimcount / this.state.perPage1),
            webclaim: res.data.claims,
            web: false,
          });
        } else if (this.state.categoryname === "Partially Paid") {
          this.setState({
            pageCount1: Math.ceil(res.data.claimcount / this.state.perPage1),
            web: false,
            webclaim: res.data.claims,
          });
        }
        else if (this.state.categoryname === "Upload Error") {
          this.setState({
            pageCount1: Math.ceil(res.data.claimcount / this.state.perPage1),
            web: false,
            webclaim: res.data.claims,
          });
        }
        else {
          this.setState({
            pageCount1: Math.ceil(res.data.claimcount / this.state.perPage1),
            webclaim: res.data.claims,
            web: false,
          });
        }
      })

      .catch((err) => {});
  };

  getpayercategory = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
     axios
      .get(`${ip}/payercategory/all`, {
        headers: headers,
      })
      .then((res) => {
        let other = {
          category_name: "Others",
        };

        let data = res.data.payer;
        data.push(other);

        this.setState({ payercategories: data });
      })

      .catch((err) => {});
  };
  addsinglecheckboxid = (data, check) => {
    if (check) {
      this.setState(
        { ids: [...this.state.ids, data] }
        // this.toggle();
      );
    } else {
      let arr = this.state.ids.slice();
      let val = arr.filter((id) => {
        return id !== data;
      });
      this.setState(
        { ids: val }
        // this.toggle();
      );
    }
  };
  checkBoxAll = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.alldataresponse.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };
  checkboxsearchall = () => {
    if (this.state.checkedAll === false) {
      let idvalue = this.state.searchdataresponse.map((data) => data._id);
      this.setState(
        { checkedAll: !this.state.checkedAll, ids: idvalue },
        () => {
          this.toggle();
        }
      );
    } else {
      this.setState({ checkedAll: !this.state.checkedAll, ids: [] }, () => {});
    }
  };
  resetuser = () => {
    this.setState({
      ids: [],
    });
  };
  reset = () => {
    this.setState(
      {
        claimfield: "",
        claimvalue: "",
        agegrp: "",
        claimtype: "",
        status: [],
        payervalue: [],
        cptvalue: [],
        searchdataresponse: [],
        searchclaimdata: [],
        searchtext: "",
        currentpage: 0,
        currentpagesearch: 0,
        checkedAll: false,
        perPage: 50,
        doiagegrp: "",
        claimids: [],
      },
      () => {
        this.getclaims();
      }
    );
  };
  validate = () => {
    let hasErr = "true";
    let selectuserErr = "";
    if (this.state.selecteduser.length === 0) {
      selectuserErr = "This field is required";
      this.setState({ selectuserErr });
      hasErr = false;
    }
    return hasErr;
  };
  componentDidMount() {
    
    this.getclaims();
    this.getwebclaims();
    this.getusers();
    this.getpayercategory();
  }
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));
    const checkboxstyle = {
      marginTop: "27px",
    };
    return (
      <div className="align-row">
        <Newsidebar name="Unallocated claims" />
        <div className="align-column">
          <div
            style={{ textAlign: "left", marginLeft: "1%", fontSize: "16px" }}
          >
            <p>
              <Link
                to="/claim/sub-category-details"
                style={{
                  textDecoration: "none",
                  color: "black",
                  marginLeft: "1%",
                }}
              >
                {this.state.querycategory}
              </Link>
              {/* &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
               
                &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;
                {this.state.subcategoryname} */}
            </p>
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              {this.state.role_type === "Admin" && (
                <Button
                  style={{
                    borderRadius: "8px",
                    color: "white",
                    background: "#041c3c",
                    fontWeight: "bold",
                  }}
                  onClick={this.toggle}
                >
                  Allocate
                </Button>
              )}
            </div>
          </div>
          <br />
          <br />

          <div style={{ height: "100%", width: "100%" }}>
            {this.state.fetching1 ? (
              // <img src={loading} />
              <div>
                <p
                  style={{
                    fontSize: "23px",
                    marginTop: "10px",
                    color: "#000",
                  }}
                >
                  <span>Loading...</span>
                  <span>Please Wait...</span>
                </p>
                <div class="boxes body__loader dribbble">
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            ) : this.state.claims.length === 0 ? (
              /* <img src={nodata} alt="" className="nodata" /> */
              <div class="bot-ui">
                <div class="bot-head">
                  <div class="eyes-container">
                    <div class="to-left eye"></div>
                    <div class="to-right eye"></div>
                  </div>
                </div>
                <div class="bot-body">
                  <div class="analyzer"></div>
                </div>
                <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                  No Data Found
                </p>
              </div>
            ) : (
              <div>
                <div style={{ paddingLeft: "10px" }}>
                  <TableContainer>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          {this.state.role_type === "Admin" && (
                            <StyledTableCell>
                              <input
                                type="checkbox"
                                checked={this.state.checkedAll}
                                onChange={this.checkBoxAll}
                              />
                            </StyledTableCell>
                          )}
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"ClaimID",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"ClaimID",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim ID{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"ClaimID",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"ClaimID",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"Location",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"Location",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Location{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"Location",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"Location",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              {this.state.openicon ? (
                                <BiUpArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"patientAccountNumber",sorttable: true }, ()=> this.getsorting())
                                  }
                                />
                              ) : (
                                <BiDownArrowAlt
                                  onClick={() =>
                                    this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"patientAccountNumber",sorttable: true}, ()=> this.getsorting())
                                  }
                                />
                              )}
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Account No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"patientAccountNumber",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"patientAccountNumber",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"DateOfService",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"DateOfService",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOS{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"DateOfService",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"DateOfService",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"PatientFirstName",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"PatientFirstName",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"PatientFirstName",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"PatientFirstName",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"PatientDOB",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"PatientDOB",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Patient DOB{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"PatientDOB",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"PatientDOB",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"PlanCode",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"PlanCode",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Plan Code{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"PlanCode",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"PlanCode",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"Pripayer",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"Pripayer",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Insurance Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"Pripayer",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"Pripayer",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"PrimaryPolicyNo",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"PrimaryPolicyNo",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Policy No{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"PrimaryPolicyNo",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"PrimaryPolicyNo",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"Physician",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"Physician",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Physician Name{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"Physician",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"Physician",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"Age",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"Age",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"Age",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"Age",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"AgeGrp",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"AgeGrp",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Age Grp{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"AgeGrp",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"AgeGrp",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"DOIAge",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"DOIAge",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOI Age
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"DOIAge",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"DOIAge",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"DOIAgeGrp",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"DOIAgeGrp",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            DOI AgeGrp
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"DOIAgeGrp",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"DOIAgeGrp",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"type",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"type",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Type{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"type",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"type",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"ClaimStatus",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"ClaimStatus",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Claim Status{" "}
                            {this.state.openicon ? (
                              <BiUpArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: false, sortvalue:"ascending", fieldvalue:"ClaimStatus",sorttable: true }, ()=> this.getsorting())
                                }
                              />
                            ) : (
                              <BiDownArrowAlt
                                onClick={() =>
                                  this.setState({ openicon: true, sortvalue:"descending", fieldvalue:"ClaimStatus",sorttable: true}, ()=> this.getsorting())
                                }
                              />
                            )}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {this.state.sorttable === true ?
                        (
                        <TableBody>
                        {this.state.sortdata &&
                          this.state.sortdata.map((data, index) => {
                            return (
                              <TableRow key={data._id}>
                                {this.state.role_type === "Admin" &&
                                  (this.state.checkedAll === true ? (
                                    <div style={checkboxstyle}>
                                      <input
                                        type="checkbox"
                                        checked={this.state.checkedAll}
                                        onChange={this.checkBoxAll}
                                      />
                                    </div>
                                  ) : (
                                    <div style={checkboxstyle}>
                                      <Checkbox
                                        handleidpush={this.addsinglecheckboxid}
                                        number={data._id}
                                        key={index}
                                      />
                                    </div>
                                  ))}

                                <StyledTableCell>
                                  {data.ClaimID}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.Location}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.patientAccountNumber
                                    ? data.patientAccountNumber
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.DateOfService
                                    ? data.DateOfService
                                    : "-"}
                                </StyledTableCell>

                                <StyledTableCell>
                                  {data.PatientFirstName}&nbsp;
                                  {data.PatientLastName}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.PatientDOB ? data.PatientDOB : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.PlanCode ? data.PlanCode : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.InsuranceName
                                    ? data.InsuranceName
                                    : "-"}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.PrimaryPolicyNo}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.Physician}
                                </StyledTableCell>

                                <StyledTableCell>{data.Age}</StyledTableCell>
                                <StyledTableCell>{data.AgeGrp}</StyledTableCell>
                                <StyledTableCell>{data.DOIAge}</StyledTableCell>
                                <StyledTableCell>
                                  {data.DOIAgeGrp}
                                </StyledTableCell>
                                <StyledTableCell>{data.type}</StyledTableCell>
                                <StyledTableCell>
                                  {data.ClaimStatus}
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>)
                      :
                       (
                      <TableBody>
                      {this.state.webclaim &&
                        this.state.webclaim.map((data, index) => {
                          return (
                            <TableRow key={data._id}>
                              {this.state.role_type === "Admin" &&
                                (this.state.checkedAll === true ? (
                                  <div style={checkboxstyle}>
                                    <input
                                      type="checkbox"
                                      checked={this.state.checkedAll}
                                      onChange={this.checkBoxAll}
                                    />
                                  </div>
                                ) : (
                                  <div style={checkboxstyle}>
                                    <Checkbox
                                      handleidpush={this.addsinglecheckboxid}
                                      number={data._id}
                                      key={index}
                                    />
                                  </div>
                                ))}

                              <StyledTableCell>
                                {data.ClaimID}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.Location}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.patientAccountNumber
                                  ? data.patientAccountNumber
                                  : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.DateOfService
                                  ? data.DateOfService
                                  : "-"}
                              </StyledTableCell>

                              <StyledTableCell>
                                {data.PatientFirstName}&nbsp;
                                {data.PatientLastName}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PatientDOB ? data.PatientDOB : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PlanCode ? data.PlanCode : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.InsuranceName
                                  ? data.InsuranceName
                                  : "-"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.PrimaryPolicyNo}
                              </StyledTableCell>
                              <StyledTableCell>
                                {data.Physician}
                              </StyledTableCell>

                              <StyledTableCell>{data.Age}</StyledTableCell>
                              <StyledTableCell>{data.AgeGrp}</StyledTableCell>
                              <StyledTableCell>{data.DOIAge}</StyledTableCell>
                              <StyledTableCell>
                                {data.DOIAgeGrp}
                              </StyledTableCell>
                              <StyledTableCell>{data.type}</StyledTableCell>
                              <StyledTableCell>
                                {data.ClaimStatus}
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                         )
                      }
               
                    </Table>
                  </TableContainer>
                  <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount1}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClickWeb}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <br />

          <br />

          {this.state.ids.length !== 0 && this.state.modal && (
            <Modal
              style={{ width: "30%" }}
              isOpen={this.state.modal}
              onHide={this.toggle}
              size="md"
              centered
            >
              <ModalHeader toggle={this.toggle}>Create Allocation</ModalHeader>
              <ModalBody>
                <Row style={{ margin: "0px" }}>
                  <Col>
                    <Label>Agent/Team Member Name</Label>
                    <Select
                      options={this.state.users}
                      values={this.state.selecteduser}
                      searchable={true}
                      dropdownHandle={true}
                      searchBy="first_name"
                      labelField="first_name"
                      noDataLabel="No matches found"
                      onChange={(values) =>
                        this.setState({
                          selecteduser: values,
                          selectuserErr: "",
                        })
                      }
                      required
                    />
                  </Col>
                  <div style={{ fontSize: 16, color: "red" }}>
                    {this.state.selectuserErr}
                  </div>
                </Row>
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    this.allocateclaims();
                    this.resetuser();
                  }}
                  style={{ backgroundColor: "grey", color: "#fff" }}
                >
                  Allocate
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}
export default Claimsubcategoryunallocate1;
