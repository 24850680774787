import { Component } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Newsidebar from "./../../Dashboard/Newsidebar.js";
import { faChartBar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../Styles/timegap.css";
import axios from "axios";
import { ip } from "../../Apis/Api.js";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  AreaChart,
  Area,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
  const ClaimTooltip = ({ active, payload }) => {
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          {payload[0].payload._id}
          <br />
          <p style={{ color: payload[0].color }}>
            {payload[0].name}:&nbsp;{payload[0].value}
          </p >
        </div>
      );
    }
    return null;
  };

class TimeGapChart extends Component {
  state = {
    refreshtoken: sessionStorage.getItem("refreshtoken"),
    accesstoken: sessionStorage.getItem("accesstoken"),
    client:sessionStorage.getItem("clientid"),
    dataarray:[],
    locationslist: [],
   };
  //  componentDidUpdate(prevProps, prevState) {
  //   // Check if the `dataarray` has changed
  //   if (prevState.dataarray !== this.state.dataarray) {
  //     console.log("dataarray:", this.state.dataarray);
  //   }
  // }
  renewaccesstoken = async() => {
    const renewheaders = {
      "Content-Type" : "application/json",
      Authorization : `Bearer ${this.state.refreshtoken}`,
    };
    const renew = await axios 
    .get(`${ip}/auth/renewAccessToken`, {
      headers : renewheaders,
    })
    .then((renew) => {
      sessionStorage.setItem("accesstoken",renew.data.accessToken);
    })
    .catch((err)=>{})
  }
  getLocation = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    console.log("mouse",this.state);
    let url;
    if (this.state.client) {
      url = `${ip}/location/all/list?clientId=${this.state.client}`;
    } else {
      url = `${ip}/location/all/list`;
    }
    const res = axios
      .get(url, { headers: headers })
      .then((res) => {
        let data = res.data.location;
        // console.log("data",data,this.state.client);
        data = data.filter((item) => {
          return item.clientId == this.state.client;
        });
        // console.log("data1",data);
        data.sort((a, b) => {
          return a.Location < b.Location ? -1 : 1;
        });
        // console.log("data2",data);
        this.setState({ locationslist: data });
        // console.log("mouse5",this.state.locationslist);
        this.getData()
      })

      .catch((err) => {});
  };
  getData = async () => {
    try{
      console.log("hiii",this.state.locationslist)
      const headers = {
        "Content-Type" : "application/json",
        Authorization : `Bearer ${this.state.accesstoken}`
      };
      
      let url;
      this.state.locationslist.forEach(async(location)=>{
        url = `${ip}/trends/inbetweendays?clientId=${this.state.client}&Location=${location.Location}`
        const res = await axios 
      .get(url,{headers : headers}).then((res)=>{
        console.log(typeof res,"data5")
        this.setState({
          dataarray : [...this.state.dataarray,{data:res.data.data,avg:res.data.avg,location:location.Location}]
        });
        console.log("vvv",this.state.dataarray)
      })
      
      // this.setState({
      //   dataarray : [...this.state.dataarray,{...res.data.data,location:location.Location}]
      // });
        })
            
    }
    
    catch(err) {
      console.log("err",err)
    }
  }
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getLocation();
    // console.log("this.state.dataarray.length:",this.state.dataarray.length);
    //setTimeout(this.getData(), 100000);
    // if(this.state.locationslist.length>0){
    //   this.getData()
    // }
    // this.getData();
  }
  
  render() {
    return (
      <div>
      <Newsidebar />
      <div className="align-row">
        <div className="align-column">
          {/* <div className="tophead"> */}
          <br />

          <div
            style={{ textAlign: "left", marginLeft: "3%", fontSize: "14px" }}
          >
            <p>
              <Link
                to="/management-trends-patterns"
                style={{ textDecoration: "none", color: "black" }}
              >
                Trends & Patterns
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>&nbsp;Time Gap
              Between DOS & Claim Date
            </p>
          </div>
          <br />
            {this.state.dataarray.map((chartData,i)=>(
          <div class="time-page-wrapper">
            <div class="time-row">
              <div class="timedouble-column">
                <div class="time-column">
                  <div className="charticon" style={{ marginTop: "42%",height: "77px" }}>
                    <FontAwesomeIcon
                      icon={faChartBar}
                      size="4x"
                      color="white"
                    />
                    <p
                      style={{
                        marginTop: "-74%",
                        textAlign: "left",
                        marginLeft: "120%",
                        fontFamily: "Poppins",
                        fontSize: "17px",
                        fontWeight: "600",
                      }}  
                    >
                      {chartData.location}
                    </p>
                    <p
                      style={{
                        marginTop: "-22%",
                        textAlign: "left",
                        marginLeft: "120%",
                        fontFamily: "Poppins",
                        fontSize: "17px",
                        fontWeight: "700",
                      }}
                    >
                      <b>{chartData.avg}</b>
                    </p>
                    <hr
                      style={{
                        width: "270%",
                        marginTop: "-8%",
                        border: "2px ",
                      }}
                    />
                  </div>
                </div>
              </div>
              
                <div class="timeblue-column">
                <ResponsiveContainer width={"100%"} height={350}>
                  <AreaChart
                    width={500}
                    height={380}
                    data={chartData.data}
                    margin={{
                      top: 10,
                      right: 40,
                      left: 10,
                      bottom: 5,
                    }}
                  >
                    <XAxis
                      dataKey="_id"
                      height={80}
                      interval={0}
                      angle={"-45"}
                      minTickGap={6}
                      tick={{
                        strokeWidth: 0,
                        fontSize: "12",
                        top: 400,
                        textAnchor: "end",
                        dy: 6,
                      }}
                    />
                    <YAxis
                      domain={[0, 90]}
                      dataKey="datedifference"
                      interval={0}
                      tick={{ fontSize: "12" }}
                    />
                    <Tooltip cursor={true} content={<ClaimTooltip/>}/>
                    {/* <Legend wrapperStyle={{top: 420, left: 350,bottom:"300", width:"45%",border: "1px solid gray"}}/> */}

                    <Area
                      name="datedifference"
                      type="monotone"
                      dataKey="datedifference"
                      stroke="rgb(5, 101, 191)"
                      fill="rgb(5, 101, 191)"
                    />

                  </AreaChart>
                </ResponsiveContainer>
              </div>
              

             
            </div>
            
          </div>
          ))}
        </div>
        
      </div>
      </div>
    );
  }
}
export default TimeGapChart;
