import Reacr, { Component } from "react";
import { Table, Row, Col } from "reactstrap";
import { Spinner } from "react-bootstrap";
import CsvDownload from "react-json-to-csv";
import { ip } from "../../Apis/ReportApi";
import axios from "axios";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactPaginate from "react-paginate";
import moment, { calendarFormat } from "moment";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosRefresh,
} from "react-icons/io";

class DailyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingdailyreport: true,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      client: sessionStorage.getItem("clientid"),
      currentpage4: 0,
      perPage4: sessionStorage.getItem("Default_pagination"),
      dailyreport: [],
      reports: [],
      loading: true,
      download: false,
      buttonsize: 0,
      countofarlog: "",
    };
  }

  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getdownloadbutton = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    let filename;
    let date1 = moment().subtract(7, "days").format().substring(0, 16);
    let date2 = moment().format().substring(0, 16);
    if (this.props.startdate && this.props.enddate) {
      filename = `Daily_Report_${this.props.startdate}_${this.props.enddate}`;
      url = `${ip}/report/dailyreport?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}&button=${this.state.buttonsize}`;
    } else {
      filename = `Daily_Report_${moment(date1).format("MM/DD/YYYY")}_${moment(date2).format("MM/DD/YYYY")}`;
      url = `${ip}/report/dailyreport?clientId=${this.state.client}&start_date=${date1}&end_date=${date2}&button=${this.state.buttonsize}`;
    }
    fetch(url, { headers: headers }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", filename);
        a.click();
        URL.revokeObjectURL(a);
        this.setState({
          loading: false,
        });
      });
    });
  };
  getcount = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/report/getcounts?clientId=${this.state.client}&feature=dailyproductionreport&start_date=${this.props.startdate}&end_date=${this.props.enddate}`;
    } else {
      url = `${ip}/report/getcounts?clientId=${this.state.client}&feature=dailyproductionreport`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          countofarlog: Math.ceil(res.data.count / 500),
        });
        console.log(this.state.countofarlog, res.data.count, "count");
      });
  };
  downloaddata = () => {
    const { download } = this.state;
    this.setState({ download: !download });
  };
  getReports = () => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/report/dailyreport?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}`;
    } else {
      url = `${ip}/report/dailyreport?clientId=${this.state.client}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          reports: res.data.data,
          loading: false,
        });
      });
  };

  getDailyreports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    let date1 = moment().subtract(7, "days").format().substring(0, 16);
    let date2 = moment().format().substring(0, 16);
    if (this.props.startdate && this.props.enddate) {
      url = `${ip}/report/dailyreport?start_date=${this.props.startdate}&end_date=${this.props.enddate}&clientId=${this.state.client}&pageno=${this.state.currentpage4}`;
    } else {
      url = `${ip}/report/dailyreport?clientId=${this.state.client}&pageno=${this.state.currentpage4}&start_date=${date1}&end_date=${date2}`;
    }
    const res = axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount4: Math.ceil(res.data.count / this.state.perPage4),
          dailyreport: res.data.data,
          fetchingdailyreport: false,
        });
        this.getcount();
      });
  };
  handlePageClick4 = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage4;

    this.setState(
      {
        currentpage4: selectedPage,
        offset4: offset,
      },
      () => {
        this.getDailyreports();
      }
    );
  };
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getDailyreports();
    this.getReports();
    this.getcount();
  }
  componentDidUpdate(prevProps) {
    if (
      !(
        this.props.startdate === prevProps.startdate &&
        this.props.enddate === prevProps.enddate
      )
    ) {
      this.getDailyreports();
      this.getReports();
    }
  }
  render() {
    return this.state.fetchingdailyreport ? (
      <div>
        <p
          style={{
            fontSize: "23px",
            marginTop: "10px",
            color: "#000",
          }}
        >
          <span>Loading...</span>
          <span>Please Wait...</span>
        </p>
        <div class="boxes body__loader dribbble">
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    ) : this.state.dailyreport.length === 0 ? (
      <div class="bot-ui">
        <div class="bot-head">
          <div class="eyes-container">
            <div class="to-left eye"></div>
            <div class="to-right eye"></div>
          </div>
        </div>
        <div class="bot-body">
          <div class="analyzer"></div>
        </div>
        <p style={{ fontSize: "20px", fontStyle: "italic", marginTop: "30%" }}>
          No Data Found
        </p>
      </div>
    ) : (
      <div>
        <div>
          <button
            style={{
              boxShadow: "inset 0px 1px 0px 0px #E184F3",
              background: "black",
              backgroundColor: "black",
              borderRadius: "6px",
              border: "1px solid black",
              display: "inline-block",
              cursor: "pointer",
              color: "#FFFFFF",
              fontSize: "15px",
              fontWeight: "bold",
              padding: "6px 24px",
              textDecoration: "none",
              textShadow: "0px 1px 0px #9B14B3",
              float: "right",
            }}
            onClick={(e) => {
              this.downloaddata();
              this.getdownloadbutton();
            }}
          >
            Generate Report
          </button>
        </div>
        {this.state.download && (
          <Modal
            style={{ width: "30%" }}
            isOpen={this.state.download}
            onHide={this.downloaddata}
            onExit={this.reset}
            size="md"
            centered
          >
            <ModalHeader toggle={this.downloaddata}>Download</ModalHeader>
            <ModalBody
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
              }}
            >
              <Row style={{ margin: "0px" }}>
                <Col>
                  <div id="container">
                  {this.state.loading ? (
                    <button
                      style={{
                        // boxShadow: "inset 0px 1px 0px 0px #e184f3",
                        background: "green",
                        backgroundColor: "green",
                        borderRadius: "6px",
                        border: "3px solid green",
                        display: "inline-block",
                        cursor: "pointer",
                        color: "#ffffff",
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "-4px 24px",
                        textDecoration: "none",
                        width: "45%",
                        whiteSpace: "nowrap",
                        marginLeft: "20%",
                        textShadow: "0px 1px 0px #9b14b3",
                      }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;&nbsp;Loading data...{this.state.buttonsize + 1}
                    </button>
                  ) : (
                    <button
                      style={{
                        // boxShadow: "inset 0px 1px 0px 0px #e184f3",
                        background: "Black",
                        backgroundColor: "Black",
                        borderRadius: "6px",
                        border: "3px solid Black",
                        display: "inline-block",
                        cursor: "pointer",
                        color: "#ffffff",
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "-4px 24px",
                        textDecoration: "none",
                        width: "42%",
                        marginLeft: "20%",
                        textShadow: "0px 1px 0px #9b14b3",
                      }}
                      onClick={(e) => {
                        this.setState({
                          buttonsize : this.state.buttonsize + 1
                        }, ()=> {
                          this.getdownloadbutton()
                        });
                      }}
                    >
                      Report {this.state.buttonsize}
                    </button>
                  )}

                    
                  </div>
                </Col>
                <br />
                <br />
              </Row>

              <br />
            </ModalBody>
          </Modal>
        )}
        <br />
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Table
            className="table"
            bordered
            style={{
              // marginLeft: "-3%",
              width: "99%",
              marginTop: "4%",

              // marginRight: "12%",
            }}
          >
            <thead style={{ backgroundColor: "#041c3c", color: "white" }}>
              <tr>
                <th>ClaimID</th>
                <th>Client</th>
                <th>Location</th>
                <th>Account#</th>
                <th>Physician</th>
                <th>Claim Status</th>
                <th>DOS</th>
                <th>Insurance</th>
                <th>CPT</th>
                <th>Balance</th>
                <th>Status Code</th>
                <th>Action Code</th>
                <th>Worked Date</th>
                <th>Follow Up Date</th>
                <th>Touch</th>
                <th>Type</th>
                <th>Username</th>
                <th>AR Notes</th>
              </tr>
            </thead>
            <tbody>
              {this.state.dailyreport &&
                this.state.dailyreport.map((data, index) => {
                  return (
                    <tr>
                      <td>{data.ClaimID}</td>
                      <td>{data.clientId}</td>
                      <td>{data.Location}</td>
                      <td>{data.patientAccountNumber}</td>
                      <td>{data.Physician}</td>
                      <td>{data.ClaimStatus}</td>
                      <td>{data.DateOfService}</td>
                      <td>{data.InsuranceName}</td>
                      <td>{data.CPTCode}</td>
                      <td>{data.TotalBalanceAmount}</td>
                      <td>{data.statusCode}</td>
                      <td>{data.Actioncode}</td>
                      <td>{data.workedDate}</td>
                      <td>{data.FollowupDate}</td>
                      <td>{data.Touch}</td>
                      <td>{data.type}</td>
                      <td>{data.UserName}</td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {data.cptNotes}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <br />
        <div>
          <ReactPaginate
            previousLabel={<IoIosArrowBack />}
            nextLabel={<IoIosArrowForward />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount4}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick4}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    );
  }
}
export default DailyReport;
