import React, { Component } from "react";
import { Button, FormGroup, Input, Form, Label, ModalHeader } from "reactstrap";
import "./../Styles/login.css";
import axios from "axios";
import forgot from "../Images/forgot-pass.jpg";
import { Redirect } from "react-router";
import { ip } from "./Apis/Api";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

class Forgotpassword extends Component {
  state = {
    email: "",
    response: false,
    checked: false,
    username: "",
    quesmodal: false,
    ans1: "",
    ans2: "",
    ans3: "",
    ques1: "",
    ques2: "",
    ques3: "",
    passwordmodal: false,
    username: "",
    new_password: "",
    confirm_password: "",
    newpassword: false,
    confirmpassword: false,
    wrongmodal: false,
    passwordsMatch:false,
  };
  toggleNewPassword = () => {
    const { newpassword } = this.state;
    this.setState({ newpassword: !newpassword });
  };
  toggleConfirmPassword = () => {
    const { confirmpassword } = this.state;
    this.setState({ confirmpassword: !confirmpassword });
  };
  passValidation = () => {
    if(this.state.new_password !== this.state.confirm_password){
     this.setState({ passwordsMatch: true})
     return false
    }
    else{
     this.setState({ passwordsMatch: false})
    return true
    }
   }
   handleReset = () => {
    this.setState({
      passwordsMatch:false,
      new_password:"",
      confirm_password:""
    })
   }
  //password change post call
  handleNewpassword = async (data) => {
    const isValid = this.passValidation();
    if(isValid){
    let value;
    value = {
      newpassword: this.state.new_password,
      confirmpassword: this.state.confirm_password,
      username: this.state.username,
    };
    // eslint-disable-next-line
    const res = await axios
      .post(`${ip}/auth/resetpassword`, value)
      .then((res) => {
        alert("Password changed successfully");
        this.props.history.push("/");
      })
      .catch((err) => {});
    }
  };
  //password reset  questions popup
  toggleQues = () => {
    this.setState({
      quesmodal: !this.state.quesmodal,
      ans1:"",
      ans2:"",
      ans3:""
    });
  };
  // api postcall with email
  handleSubmit = async (e) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    e.preventDefault();
    let value;
    if (this.state.checked) {
      value = {
        username: this.state.username,
      };
    } else {
      value = {
        email: this.state.email,
      };
    }
    const res = await axios
      .post(`${ip}/auth/forgot_password`, value, { headers: headers })
      .then((res) => {
        if (!this.state.checked) {
          alert("Reset link has been sent to your mail!");
          this.setState({ response: true });
        }
        this.setState(
          {
            ques1: res.data.message.securityquestions[0].Q,
            ques2: res.data.message.securityquestions[1].Q,
            ques3: res.data.message.securityquestions[2].Q,
          },
          () => this.toggleQues()
        );
      })
      .catch((err) => {
        if (this.state.checked === true){
          alert("Please enter the correct user name")
        }
        else{
          alert("Please enter the valid email address")
        }
      });
  };
  submitAns = async (e) => {
    let value;
    let formattedQuestions;
    this.state.questions = [
      this.state.ques1,
      this.state.ques2,
      this.state.ques3,
    ];
    this.state.answers = [this.state.ans1, this.state.ans2, this.state.ans3];
     formattedQuestions = this.state.questions.map((question, index) => {
      return {
        Q: question,
        A: this.state.answers[index],
      };
    });
    value = {
      securityquestions: formattedQuestions,
      username: this.state.username,
    };
    // eslint-disable-next-line
    const res = await axios
      .post(`${ip}/auth/answerthesecurityquestions`, value)
      .then((res) => {
          this.changepassword();
      })
      .catch((err) => {
          this.setState({ quesmodal : false})
          this.wrongmodal();
      });
  };
  changepassword = () => {
    this.setState({
      passwordmodal: !this.state.passwordmodal,
      passwordsMatch:false,
      new_password:"",
      confirm_password:""
    });
  };
  wrongmodal = () => {
    this.setState({
      wrongmodal: !this.state.wrongmodal,
    });
  };
  render() {
    return this.state.response === false ? (
      <div className="login" style={{overflow:"hidden"}}>
        <Form className="forgot">
          {/* <img src={logo} className="image-forgot" alt="not found" /> */}
          {this.state.checked ? (
            <h4 align="center" style={{ fontWeight: "700" }}>
              Find your username
            </h4>
          ) : (
            <h4 align="center" style={{ fontWeight: "700" }}>
              Find your account
            </h4>
          )}

          {this.state.checked ? (
            <img src={forgot} alt="forgot-password" style={{width:"300px", height:"200px"}}></img>
          ) : (
            <div>
            <img src={forgot} alt="forgot-password" style={{width:"300px", height:"200px"}}></img>
            <h4 align="center" style={{ fontSize: "14px" }}>
              Enter the email address that is linked to your account
            </h4>
            </div>
          )}

          {this.state.err ? (
            <label
              align="center"
              style={{ color: "red", fontSize: 16, marginTop: -50 }}
            >
              Invalid Email ID
            </label>
          ) : null}
          <div
            style={{
              marginLeft: "54%",
              fontSize: "20px",
              fontStyle: "italic",
              marginTop: "2%",
            }}
          >
            <input
              type="checkbox"
              checked={this.state.checked}
              onChange={() => this.setState({ checked: !this.state.checked })}
              style={{ fontSize: "18px" }}
            />
            &nbsp;user name
          </div>
          {this.state.checked ? (
            <FormGroup className="input-group-lg">
              <label className="label3">USERNAME</label>
              <Input
                className="input-text-label3"
                type="text"
                placeholder="Enter your username"
                required
                value={this.state.username}
                onChange={(e) => this.setState({ username: e.target.value })}
              />
            </FormGroup>
          ) : (
            <FormGroup className="input-group-lg">
              <label className="label3">EMAIL ID</label>
              <br />
              <Input
                type="text"
                className="input-text-label3"
                required
                placeholder="Enter your email address"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </FormGroup>
          )}
          <br />
          {this.state.checked ? (
            <Button
              color="primary"
              className="reset-button"
              onClick={(e) => this.handleSubmit(e)}
            >
              Reset Password
            </Button>
          ) : (
            <Button
              type="submit"
              color="primary"
              className="reset-button"
              onClick={(e) => this.handleSubmit(e)}
            >
              SEND RESET LINK
            </Button>
          )}
          <br />
        </Form>
        <Modal show={this.state.quesmodal} onHide={this.toggleQues} centered>
          <Modal.Header closeButton>Security Questions</Modal.Header>
          <Modal.Body>
            <div>
              <FormGroup className="input-group-lg">
                <Label>{this.state.ques1}?</Label>
                <Input
                  className="input-text-label3"
                  type="text"
                  placeholder=""
                  value={this.state.ans1}
                  onChange={(e) =>
                    this.setState({
                      ans1: e.target.value,
                      ques1: this.state.ques1,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="input-group-lg">
                <Label>{this.state.ques2}?</Label>
                <Input
                  className="input-text-label3"
                  type={"text"}
                  placeholder=""
                  value={this.state.ans2}
                  onChange={(e) =>
                    this.setState({
                      ans2: e.target.value,
                      ques2: this.state.ques2,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="input-group-lg">
                <Label>{this.state.ques3}?</Label>
                <Input
                  className="input-text-label3"
                  type={"text"}
                  placeholder=""
                  value={this.state.ans3}
                  onChange={(e) =>
                    this.setState({
                      ans3: e.target.value,
                      ques3: this.state.ques3,
                    })
                  }
                />
              </FormGroup>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid gray",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={this.toggleQues}
            >
              Cancel
            </Button>
            <Button
              style={{
                border: "1px solid gray",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={this.submitAns}
              className="uploadcss"
            >
              Submit Your Answers
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.passwordmodal}
          onHide={this.changepassword}
          centered
        >
          <Modal.Header closeButton>Change Password</Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "auto",
            }}
          >
            <p style={{ color: "green", fontSize: "13.5px" }}>
              Your answers are right! Now you can change your password{" "}
            </p>
            { this.state.passwordsMatch === true ?
                <p style={{color:"red",fontSize:"15px", textAlign:"center"}}>Passwords does not match.</p>
                : null}
            <FormGroup className="input-group-lg">
              <Label>User Name</Label>
              <Input
                className="input-text-label3"
                type="text"
                placeholder="Enter your user name"
                value={this.state.username}
                onChange={(e) => this.setState({ username: e.target.value })}
              />
            </FormGroup>
            <FormGroup className="input-group-lg">
              <Label>New Password</Label>
              <Input
                className="input-text-label3"
                type={this.state.newpassword ? "text" : "password"}
                placeholder="Enter your password"
                value={this.state.new_password}
                onChange={(e) =>
                  this.setState({ new_password: e.target.value })
                }
              />
              {this.state.newpassword ? (
                <AiFillEyeInvisible
                  className="password-icon"
                  onClick={this.toggleNewPassword}
                />
              ) : (
                <AiFillEye
                  className="password-icon"
                  onClick={this.toggleNewPassword}
                />
              )}
            </FormGroup>
            <FormGroup className="input-group-lg">
              <Label>Confirm Password</Label>
              <Input
                className="input-text-label3"
                type={this.state.confirmpassword ? "text" : "password"}
                placeholder="Enter your password"
                value={this.state.confirm_password}
                onChange={(e) =>
                  this.setState({ confirm_password: e.target.value })
                }
              />
              {this.state.confirmpassword ? (
                <AiFillEyeInvisible
                  className="password-icon"
                  onClick={this.toggleConfirmPassword}
                />
              ) : (
                <AiFillEye
                  className="password-icon"
                  onClick={this.toggleConfirmPassword}
                />
              )}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid #268da5",
                color: "#268da5",
                backgroundColor: "#fff",
              }}
              onClick={this.changepassword}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "#268da5" }}
              onClick={this.handleNewpassword}
            >
              Change
            </Button>
            <Button
              style={{ backgroundColor: "#0000FF" }}
              onClick={this.handleReset}
            >
              Reset
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.wrongmodal} onHide={this.wrongmodal} centered>
          <Modal.Header closeButton>Error!</Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "auto",
            }}
          >
            <p style={{ color: "red", fontSize: "20px" }}>
              Your Answers are wrong. &nbsp; Kindly contact the Admin!
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                border: "1px solid #268da5",
                color: "#268da5",
                backgroundColor: "#fff",
              }}
              onClick={this.wrongmodal}
            >
              Cancel
            </Button>
            <Link to="/">
              <Button style={{ backgroundColor: "#268da5" }}>
                Redirect to Home Page
              </Button>
            </Link>
          </Modal.Footer>
        </Modal>
      </div>
    ) : (
      <Redirect to="/" />
    );
  }
}

export default Forgotpassword;
