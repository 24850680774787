import React, { Component } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  LabelList,
  Label,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Legend,
} from "recharts";
import { ip } from "../../Apis/Api";
const data1 = [
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },

  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
  {
    _id: "",
    totalamount: 0,
    totalcount: 0,
  },
];
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: payload[0].color }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};
class Top5Payers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agewise0_30amount: [],
      agewise0_30count: [],
      name: "",
      select: "$",
      name1: "",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      toppayerdata: [],
      Payer1: "",
      Payer2: "",
      Payer3: "",
      Payer4: "",
      Payer5: "",
      len: 0,
    };
  }

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios

      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getPayerList = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let link1 = null;
    if (this.props.client) {
      if (this.props.Location) {
        if (this.props.topStart !== "" && this.props.topEnd !== "") {
          link1 = `${ip}/inventory/pripayer?start_date=${this.props.topStart}&end_date=${this.props.topEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
        } else {
          link1 = `${ip}/inventory/pripayer?clientId=${this.props.client}&Location=${this.props.Location}`;
        }
      } else {
        if (this.props.topStart !== "" && this.props.topEnd !== "") {
          link1 = `${ip}/inventory/pripayer?start_date=${this.props.topStart}&end_date=${this.props.topEnd}&clientId=${this.props.client}`;
        } else {
          link1 = `${ip}/inventory/pripayer?clientId=${this.props.client}`;
        }
      }
    } else {
      if (this.props.topStart !== "" && this.props.topEnd !== "") {
        link1 = `${ip}/inventory/pripayer?start_date=${this.props.topStart}&end_date=${this.props.topEnd}`;
      } else {
        link1 = `${ip}/inventory/pripayer`;
      }
    }
    try {
      const res = await axios.get(link1, { headers: headers });
      this.setState({ len: res.data.touch });
      if (this.state.len.length !== 0) {
        if (res.data.touch[0]._id.length !== 0) {
          this.setState({
            name: res.data.touch[0]._id,
            Payer1: res.data.touch[0]._id,
          });
        } else {
          this.setState({ name: null, Payer1: "" });
        }
        if (res.data.touch[1]._id.length !== 0) {
          this.setState({
            Payer2: res.data.touch[1]._id,
          });
        } else {
          this.setState({ Payer2: "" });
        }
        if (res.data.touch[1]._id.length !== 0) {
          this.setState({
            Payer3: res.data.touch[2]._id,
          });
        } else {
          this.setState({ Payer3: "" });
        }
        if (res.data.touch[3]._id.length !== 0) {
          this.setState({
            Payer4: res.data.touch[3]._id,
          });
        } else {
          this.setState({ Payer4: "" });
        }
        if (res.data.touch[4]._id.length !== 0) {
          this.setState({
            Payer5: res.data.touch[4]._id,
          });
        } else {
          this.setState({ Payer5: "" });
        }
      } else {
        this.setState({
          name: "",
          Payer1: "",
          Payer2: "",
          Payer3: "",
          Payer4: "",
          Payer5: "",
          agewise0_30amount: data1,
          agewise0_30count: data1,
        });
      }

      await this.getPayers();
    } catch (err) {}
  };

  getPayers = async () => {
    if (this.state.len.length !== 0) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      let url = null;
      if (this.props.client) {
        if (this.props.Location) {
          if (this.props.topStart !== "" && this.props.topEnd !== "") {
            url = `${ip}/inventory/toppayer?pripayer=${this.state.name}&start_date=${this.props.topStart}&end_date=${this.props.topEnd}&clientId=${this.props.client}&Location=${this.props.Location}`;
          } else {
            url = `${ip}/inventory/toppayer?pripayer=${this.state.name}&clientId=${this.props.client}&Location=${this.props.Location}`;
          }
        } else {
          if (this.props.topStart !== "" && this.props.topEnd !== "") {
            url = `${ip}/inventory/toppayer?pripayer=${this.state.name}&start_date=${this.props.topStart}&end_date=${this.props.topEnd}&clientId=${this.props.client}`;
          } else {
            url = `${ip}/inventory/toppayer?pripayer=${this.state.name}&clientId=${this.props.client}`;
          }
        }
      } else {
        if (this.props.topStart !== "" && this.props.topEnd !== "") {
          url = `${ip}/inventory/toppayer?pripayer=${this.state.name}&start_date=${this.props.topStart}&end_date=${this.props.topEnd}`;
        } else {
          url = `${ip}/inventory/toppayer?pripayer=${this.state.name}`;
        }
      }

      try {
        const req = await axios.get(url, { headers: headers });

        if (req.data.topfivepayer.length !== 0) {
          this.setState({ agewise0_30amount: req.data.topfivepayer });

          this.setState({
            agewise0_30count: req.data.topfivepayer,
          });
        } else {
          this.setState({ agewise0_30amount: data1, agewise0_30count: data1 });
        }
        return req;
      } catch (e) {}
    } else {
      this.setState({ agewise0_30amount: data1, agewise0_30count: data1 });
    }
  };
  componentDidMount = async () => {
    setTimeout(this.renewaccesstoken(), 10000);
    await this.getPayerList();
    await this.getPayers();
  };

  componentDidUpdate = async (prevProps) => {
    if (
      !(
        this.props.topStart === prevProps.topStart &&
        this.props.topEnd === prevProps.topEnd
      )
    ) {
      await this.getPayerList();
      await this.getPayers();
    } else if (this.props.client !== prevProps.client) {
      await this.getPayerList();
      await this.getPayers();
    } else if (this.props.Location !== prevProps.Location) {
      await this.getPayerList();
      await this.getPayers();
    }
  };

  render() {
    return (
      <div>
        <Row style={{ marginLeft: "2%" }}>
          <div>
            <div
              style={{
                marginLeft: "45%",
                marginTop: "-5%",
                alignItems: "left",
              }}
            >
              <select
                value={this.state.select}
                onChange={(e) => this.setState({ select: e.target.value })}
                style={{ width: "28%", marginTop: "-5%", fontWeight: "500" }}
              >
                <option value="$">Charges</option>
                <option value="#">Claims</option>
              </select>
              &nbsp;
              <select
                value={this.state.name}
                onChange={(e) => {
                  this.setState({ name: e.target.value }, () =>
                    this.getPayers()
                  );
                }}
                style={{ width: "70%", fontWeight: "500" }}
              >
                {this.state.Payer1 ? (
                  <option value={this.state.Payer1}>{this.state.Payer1}</option>
                ) : null}
                {this.state.Payer2 ? (
                  <option value={this.state.Payer2}>{this.state.Payer2}</option>
                ) : null}
                {this.state.Payer3 ? (
                  <option value={this.state.Payer3}>{this.state.Payer3}</option>
                ) : null}
                {this.state.Payer4 ? (
                  <option value={this.state.Payer4}>{this.state.Payer4}</option>
                ) : null}
                {this.state.Payer5 ? (
                  <option value={this.state.Payer5}>{this.state.Payer5}</option>
                ) : null}
              </select>
            </div>

            <hr style={{ width: "108%", marginLeft: "-37px" }} />
          </div>
        </Row>
        <Row>
          {(() => {
            switch (this.state.name) {
              case this.state.Payer1:
                return this.state.select === "$" ? (
                  <div onMouseOver={() => (tooltip = "totalamount")}>
                    <ResponsiveContainer width={"95%"} height={400}>
                      <ComposedChart
                        width={500}
                        height={350}
                        data={this.state.agewise0_30amount}
                        margin={{
                          top: 28,
                          right: 30,
                          bottom: 10,
                          left: 5,
                        }}
                      >
                        <Tooltip content={<CustomTooltip />} />
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            spreadMethod="reflect"
                          >
                            <stop offset="0" stopColor="#f5234a" />
                            <stop offset="1" stopColor="#fa859a" />
                          </linearGradient>
                        </defs>

                        <XAxis
                          dataKey="_id"
                          height={120}
                          interval={0}
                          angle={"-45"}
                          minTickGap={6}
                          tick={{
                            strokeWidth: 0,
                            fontSize: "11",
                            top: 400,
                            textAnchor: "end",
                            dy: 6,
                          }}
                        />

                        <YAxis
                          dataKey="totalamount"
                          name="$"
                          interval={0}
                          height={100}
                          minTickGap="2"
                          tick={{ fontSize: "15", fontFamily: "Poppins" }}
                        >
                          {" "}
                          <Label></Label>
                        </YAxis>

                        <Tooltip content={<CustomTooltip />} />

                        <Bar
                          name="Total Amount"
                          dataKey="totalamount"
                          radius={[10, 10, 0, 0]}
                          barSize={30}
                          fill="#2a9df4"
                        >
                          {/* <LabelList dataKey="Payer1_amt" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                        </Bar>
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                ) : (
                  <div onMouseOver={() => (tooltip = "totalcount")}>
                    <ResponsiveContainer width={"95%"} height={400}>
                      <ComposedChart
                        width={800}
                        height={350}
                        data={this.state.agewise0_30count}
                        margin={{
                          top: 28,
                          right: 30,
                          bottom: 10,
                          left: 5,
                        }}
                      >
                        <Tooltip content={<ClaimTooltip />} />
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            spreadMethod="reflect"
                          >
                            <stop offset="0" stopColor="#f5234a" />
                            <stop offset="1" stopColor="#fa859a" />
                          </linearGradient>
                        </defs>

                        <XAxis
                          dataKey="_id"
                          height={120}
                          interval={0}
                          angle={"-45"}
                          minTickGap={6}
                          tick={{
                            strokeWidth: 0,
                            fontSize: "11",
                            top: 400,
                            textAnchor: "end",
                            dy: 6,
                          }}
                        />
                        <YAxis
                          dataKey="totalcount"
                          interval={0}
                          height={100}
                          minTickGap="2"
                          tick={{ fontSize: "15", fontFamily: "Poppins" }}
                        >
                          {" "}
                          <Label></Label>
                        </YAxis>
                        <Tooltip content={<ClaimTooltip />} />
                        <Bar
                          name="Total Claims"
                          dataKey="totalcount"
                          radius={[10, 10, 0, 0]}
                          barSize={30}
                          fill="#2a9df4"
                        >
                          {/* <LabelList dataKey="Payer1_claims" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                        </Bar>
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                );
              case this.state.Payer2:
                return this.state.select === "$" ? (
                  <div onMouseOver={() => (tooltip = "totalamount")}>
                    <ResponsiveContainer width={"95%"} height={400}>
                      <ComposedChart
                        width={800}
                        height={350}
                        data={this.state.agewise0_30amount}
                        margin={{
                          top: 28,
                          right: 30,
                          bottom: 10,
                          left: 5,
                        }}
                      >
                        <Tooltip content={<CustomTooltip />} />
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            spreadMethod="reflect"
                          >
                            <stop offset="0" stopColor="#f5234a" />
                            <stop offset="1" stopColor="#fa859a" />
                          </linearGradient>
                        </defs>

                        <XAxis
                          dataKey="_id"
                          height={120}
                          interval={0}
                          angle={"-45"}
                          minTickGap={6}
                          tick={{
                            strokeWidth: 0,
                            fontSize: "11",
                            top: 400,
                            textAnchor: "end",
                            dy: 6,
                          }}
                        />

                        <YAxis
                          dataKey="totalamount"
                          interval={0}
                          height={100}
                          minTickGap="2"
                          tick={{ fontSize: "15", fontFamily: "Poppins" }}
                        ></YAxis>

                        <Tooltip content={<CustomTooltip />} />

                        <Bar
                          dataKey="totalamount"
                          radius={[10, 10, 0, 0]}
                          barSize={30}
                          fill="#2a9df4"
                          name="Total Amount"
                        >
                          {/* <LabelList dataKey="totalamount" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                        </Bar>
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                ) : (
                  <div onMouseOver={() => (tooltip = "totalcount")}>
                    <ResponsiveContainer width={"95%"} height={400}>
                      <ComposedChart
                        width={800}
                        height={350}
                        data={this.state.agewise0_30count}
                        margin={{
                          top: 28,
                          right: 30,
                          bottom: 10,
                          left: 5,
                        }}
                      >
                        <Tooltip content={<ClaimTooltip />} />
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            spreadMethod="reflect"
                          >
                            <stop offset="0" stopColor="#f5234a" />
                            <stop offset="1" stopColor="#fa859a" />
                          </linearGradient>
                        </defs>

                        <XAxis
                          dataKey="_id"
                          height={120}
                          interval={0}
                          angle={"-45"}
                          minTickGap={6}
                          tick={{
                            strokeWidth: 0,
                            fontSize: "11",
                            top: 400,
                            textAnchor: "end",
                            dy: 6,
                          }}
                        />
                        <YAxis
                          dataKey="totalcount"
                          interval={0}
                          height={100}
                          minTickGap="2"
                          tick={{ fontSize: "15", fontFamily: "Poppins" }}
                        >
                          {" "}
                          <Label></Label>
                        </YAxis>
                        <Tooltip content={<ClaimTooltip />} />
                        <Bar
                          name="Total Claims"
                          dataKey="totalcount"
                          radius={[10, 10, 0, 0]}
                          barSize={30}
                          fill="#2a9df4"
                        >
                          {/* <LabelList dataKey="totalcount" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                        </Bar>
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                );
              case this.state.Payer3:
                return this.state.select === "$" ? (
                  <div onMouseOver={() => (tooltip = "totalamount")}>
                    <ResponsiveContainer width={"95%"} height={400}>
                      <ComposedChart
                        width={800}
                        height={350}
                        data={this.state.agewise0_30amount}
                        margin={{
                          top: 28,
                          right: 30,
                          bottom: 10,
                          left: 5,
                        }}
                      >
                        <Tooltip content={<CustomTooltip />} />
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            spreadMethod="reflect"
                          >
                            <stop offset="0" stopColor="#f5234a" />
                            <stop offset="1" stopColor="#fa859a" />
                          </linearGradient>
                        </defs>

                        <XAxis
                          dataKey="_id"
                          height={120}
                          interval={0}
                          angle={"-45"}
                          minTickGap={6}
                          tick={{
                            strokeWidth: 0,
                            fontSize: "11",
                            top: 400,
                            textAnchor: "end",
                            dy: 6,
                          }}
                        />

                        <YAxis
                          dataKey="totalamount"
                          interval={0}
                          height={100}
                          minTickGap="2"
                          tick={{ fontSize: "15", fontFamily: "Poppins" }}
                        >
                          {" "}
                          <Label></Label>
                        </YAxis>

                        <Tooltip content={<CustomTooltip />} />

                        <Bar
                          dataKey="totalamount"
                          radius={[10, 10, 0, 0]}
                          barSize={30}
                          name="Total Amount"
                          fill="#2a9df4"
                        >
                          {/* <LabelList dataKey="totalamount" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                        </Bar>
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                ) : (
                  <div onMouseOver={() => (tooltip = "totalcount")}>
                    <ResponsiveContainer width={"95%"} height={400}>
                      <ComposedChart
                        width={800}
                        height={350}
                        data={this.state.agewise0_30count}
                        margin={{
                          top: 28,
                          right: 30,
                          bottom: 10,
                          left: 5,
                        }}
                      >
                        <Tooltip content={<ClaimTooltip />} />
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            spreadMethod="reflect"
                          >
                            <stop offset="0" stopColor="#f5234a" />
                            <stop offset="1" stopColor="#fa859a" />
                          </linearGradient>
                        </defs>

                        <XAxis
                          dataKey="_id"
                          height={120}
                          interval={0}
                          angle={"-45"}
                          minTickGap={6}
                          tick={{
                            strokeWidth: 0,
                            fontSize: "11",
                            top: 400,
                            textAnchor: "end",
                            dy: 6,
                          }}
                        />
                        <YAxis
                          dataKey="totalcount"
                          interval={0}
                          height={100}
                          minTickGap="2"
                          tick={{ fontSize: "15", fontFamily: "Poppins" }}
                        >
                          {" "}
                          <Label></Label>
                        </YAxis>
                        <Tooltip content={<ClaimTooltip />} />
                        <Bar
                          dataKey="totalcount"
                          radius={[10, 10, 0, 0]}
                          barSize={30}
                          fill="#2a9df4"
                          name="Total Claims"
                        >
                          {/* <LabelList dataKey="totalcount" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                        </Bar>
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                );
              case this.state.Payer4:
                return this.state.select === "$" ? (
                  <div onMouseOver={() => (tooltip = "totalamount")}>
                    <ResponsiveContainer width={"95%"} height={400}>
                      <ComposedChart
                        width={800}
                        height={350}
                        data={this.state.agewise0_30amount}
                        margin={{
                          top: 28,
                          right: 30,
                          bottom: 10,
                          left: 5,
                        }}
                      >
                        <Tooltip content={<CustomTooltip />} />
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            spreadMethod="reflect"
                          >
                            <stop offset="0" stopColor="#f5234a" />
                            <stop offset="1" stopColor="#fa859a" />
                          </linearGradient>
                        </defs>

                        <XAxis
                          dataKey="_id"
                          height={120}
                          interval={0}
                          angle={"-45"}
                          minTickGap={6}
                          tick={{
                            strokeWidth: 0,
                            fontSize: "11",
                            top: 400,
                            textAnchor: "end",
                            dy: 6,
                          }}
                        />

                        <YAxis
                          dataKey="totalamount"
                          interval={0}
                          height={100}
                          minTickGap="2"
                          tick={{ fontSize: "15", fontFamily: "Poppins" }}
                        >
                          {" "}
                          <Label></Label>
                        </YAxis>

                        <Tooltip content={<CustomTooltip />} />

                        <Bar
                          dataKey="totalamount"
                          radius={[10, 10, 0, 0]}
                          barSize={30}
                          fill="#2a9df4"
                          name="Total Amount"
                        >
                          {/* <LabelList dataKey="totalamount" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                        </Bar>
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                ) : (
                  <div onMouseOver={() => (tooltip = "totalcount")}>
                    <ResponsiveContainer width={"95%"} height={400}>
                      <ComposedChart
                        width={800}
                        height={350}
                        data={this.state.agewise0_30count}
                        margin={{
                          top: 28,
                          right: 30,
                          bottom: 10,
                          left: 5,
                        }}
                      >
                        <Tooltip content={<ClaimTooltip />} />
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            spreadMethod="reflect"
                          >
                            <stop offset="0" stopColor="#f5234a" />
                            <stop offset="1" stopColor="#fa859a" />
                          </linearGradient>
                        </defs>

                        <XAxis
                          dataKey="_id"
                          height={120}
                          interval={0}
                          angle={"-45"}
                          minTickGap={6}
                          tick={{
                            strokeWidth: 0,
                            fontSize: "11",
                            top: 400,
                            textAnchor: "end",
                            dy: 6,
                          }}
                        />
                        <YAxis
                          dataKey="totalcount"
                          interval={0}
                          height={100}
                          minTickGap="2"
                          tick={{ fontSize: "15", fontFamily: "Poppins" }}
                        >
                          <Legend></Legend>
                        </YAxis>
                        <Tooltip content={<ClaimTooltip />} />
                        <Bar
                          dataKey="totalcount"
                          radius={[10, 10, 0, 0]}
                          barSize={30}
                          name="Total Claims"
                          fill="#2a9df4"
                        >
                          {/* <LabelList dataKey="totalcount" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                        </Bar>
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                );
              case this.state.Payer5:
                return this.state.select === "$" ? (
                  <div onMouseOver={() => (tooltip = "totalamount")}>
                    <ResponsiveContainer width={"95%"} height={400}>
                      <ComposedChart
                        width={800}
                        height={350}
                        data={this.state.agewise0_30amount}
                        margin={{
                          top: 28,
                          right: 30,
                          bottom: 10,
                          left: 5,
                        }}
                      >
                        <Tooltip content={<CustomTooltip />} />
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            spreadMethod="reflect"
                          >
                            <stop offset="0" stopColor="#f5234a" />
                            <stop offset="1" stopColor="#fa859a" />
                          </linearGradient>
                        </defs>

                        <XAxis
                          dataKey="_id"
                          height={120}
                          interval={0}
                          angle={"-45"}
                          minTickGap={6}
                          tick={{
                            strokeWidth: 0,
                            fontSize: "11",
                            top: 400,
                            textAnchor: "end",
                            dy: 6,
                          }}
                        />

                        <YAxis
                          dataKey="totalamount"
                          interval={0}
                          height={100}
                          minTickGap="2"
                          tick={{ fontSize: "15", fontFamily: "Poppins" }}
                        >
                          {" "}
                          <Label></Label>
                        </YAxis>

                        <Tooltip content={<CustomTooltip />} />

                        <Bar
                          dataKey="totalamount"
                          radius={[10, 10, 0, 0]}
                          barSize={30}
                          fill="#2a9df4"
                          name="Total Amount"
                          onMouseOver={() => (tooltip = "totalamount")}
                        >
                          {/* <LabelList dataKey="totalamount" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                        </Bar>
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                ) : (
                  <div onMouseOver={() => (tooltip = "totalcount")}>
                    <ResponsiveContainer width={"95%"} height={400}>
                      <ComposedChart
                        width={800}
                        height={350}
                        data={this.state.agewise0_30count}
                        margin={{
                          top: 28,
                          right: 30,
                          bottom: 10,
                          left: 5,
                        }}
                      >
                        <Tooltip content={<ClaimTooltip />} />
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            spreadMethod="reflect"
                          >
                            <stop offset="0" stopColor="#f5234a" />
                            <stop offset="1" stopColor="#fa859a" />
                          </linearGradient>
                        </defs>

                        <XAxis
                          dataKey="_id"
                          height={120}
                          interval={0}
                          angle={"-45"}
                          minTickGap={6}
                          tick={{
                            strokeWidth: 0,
                            fontSize: "11",
                            top: 400,
                            textAnchor: "end",
                            dy: 6,
                          }}
                        />
                        <YAxis
                          dataKey="totalcount"
                          interval={0}
                          height={100}
                          minTickGap="2"
                          tick={{
                            fontSize: "15",
                            fontFamily: "Poppins",
                            dy: 6,
                          }}
                        >
                          {" "}
                          <Label></Label>
                        </YAxis>
                        <Tooltip content={<ClaimTooltip />} />
                        <Bar
                          dataKey="totalcount"
                          radius={[10, 10, 0, 0]}
                          barSize={30}
                          fill="#2a9df4"
                          name="Total Claims"
                        >
                          {/* <LabelList dataKey="totalcount" type="number" position='top' fill='#7a051b' fontWeight='600' fontFamily='Poppins' /> */}
                        </Bar>
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                );
            }
          })()}
        </Row>
      </div>
    );
  }
}

export default Top5Payers;
