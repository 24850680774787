import React, { Component } from "react";

class Checkbox extends Component {
  state = {
    isChecked: false,
  };

  toggleCheckboxChange = () => {
    const { handleidpush, number } = this.props;

    this.setState(
      ({ isChecked }) => ({
        isChecked: !isChecked,
      }),
      () => {
        handleidpush(number,this.state.isChecked);
      }
    );

  };

  render() {
    const { isChecked } = this.state;

    return (
      
  <>
 
          <input
            type="checkbox"
            checked={isChecked}
            onChange={this.toggleCheckboxChange}
          />
  
    </>
    );
  }
}

export default Checkbox;