import React, { Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import "../../Styles/actions.css";
import { Row, Col, Container, Label } from "reactstrap";
import { Input } from "reactstrap";
import Button from "@material-ui/core/Button";
import { Card } from "react-bootstrap";
import { Space, Badge, DatePicker, Avatar } from "antd";
import "../../Styles/charts.css";
import { VscGithubAction } from "react-icons/vsc";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CgNotes } from "react-icons/cg";
import { MdRestartAlt } from "react-icons/md";
import { HiAdjustments } from "react-icons/hi";
import green from "./../../Images/green.png";
import blue from "./../../Images/blue.png";
import { Link } from "react-router-dom";
import purple from "./../../Images/purple.png";
import nodata from "./../../Images/no_result_found.png";
import Actionallocation from "../Management/Actionallocation";
import { ip } from "../Apis/Api";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  LabelList,
  Legend,
  Bar,
  Brush,
  ResponsiveContainer,
} from "recharts";
import ActionBucket from "./ActionBucket";
import KeyUnallocated from "../Transaction/AllocationCardComponents/KeyUnallocated";
let tooltip;
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: "#f5234a" }}>
          {payload[0].name}:&nbsp;${payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};
const ClaimTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload._id}
        <br />
        <p style={{ color: "#f5234a" }}>
          {payload[0].name}:&nbsp;{payload[0].value}
        </p>
      </div>
    );
  }
  return null;
};

class Actions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      select: "$",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      denialbyagewise: [],
      action_wise: [],
      actioncodelist: [],
      allocatemodal: false,
      actioncode1: "",
      allocatecount: [],
      needtocall: "Tasked For Adjustment-Tonya",
      fixedCardData: null,
      allocatecountaction: "",
      remainingCards: "",
      specificcard: [],
      unallocatecountaction: "",
      bucketId: "",
      action: [],
      allocate: ["25", "50", "75", "100", "125", "150"],
      offset: 0,
      offset2: 0,
      currentpage: 0,
      currentpage1: 0,
      currentpage2: 0,
      UserAllocate:
      sessionStorage.getItem("firstname") +
      " " +
      sessionStorage.getItem("lastname"),
      currentpageaction: 0,
      anchorEl: null,
      usersofaction: [],
      claimid: [],
      userid: [],
      reallocmodal: false,
      objectid: [],
      perPage: sessionStorage.getItem("Default_pagination"),
      perPage1: sessionStorage.getItem("Default_pagination"),
      agegrp1: "",
      agegrp2: "",
      actionid: [],
      agegrp3: "",
      agegrp4: "",
      agegrp5: "",
      count: [],
      actionid: [],
      fetched: true,
      clientslist: [],
      status1: [],
      status2: [],
      status3: [],
      status4: [],
      status5: [],

      role_type: sessionStorage.getItem("role_type"),
      opendropdown: false,
      client: sessionStorage.getItem("clientid"),
      clientname: sessionStorage.getItem("clientname"),
    };
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpageaction: selectedPage,
        offset: offset,
      },
      () => {
        this.getbucketclaims();
      }
    );
  };

  toggle = () => {
    this.setState({ opendropdown: !this.state.opendropdown });
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getbucketclaims = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = await axios
      .get(
        `${ip}/actioncode/getactioncodeclaims?actioncode=${this.state.actioncode1}&pageno=${this.state.currentpageaction}&clientId=${this.state.client}`,
        {
          headers: headers,
        }
      )
      .then(
        (res) => {
          this.setState({
            actionid: res.data.claimids,
            allocatecountaction: res.data.allocatedcount,
            unallocatecountaction: res.data.unallocatedcount,
          });
          console.log(
            this.state.allocatecountaction,
            this.state.unallocatecountaction,
            "count"
          );
        },
        () => this.allocateusers()
      )

      .catch((err) => {});
  };
  getusers = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = axios
      .get(`${ip}/users/all?clientId=${this.state.client}`, { headers: headers })
      .then((res) => {
        let dataofaction = res.data.user;
        if(this.state.client){
          dataofaction = dataofaction.filter((item) => {
            return (
              item.role === "Team Member" 
            );
          });
        }
        else{
          dataofaction = dataofaction.filter((item) => {
            return (
              item.role === "Admin" && item.role==="SuperAdmin"
            );
          });
        }
        this.setState({ usersofaction: dataofaction });
      })

      .catch((err) => {});
  };

  getDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    let url;
    if (this.state.client) {
      url = `${ip}/actioncode/topclaims?clientId=${this.state.client}`;
    } else {
      url = `${ip}/actioncode/topclaims`;
    }
    const req = axios
      .get(url, {
        headers: headers,
      })
      .then((req) => {
        let data = req.data.claims.map((item) => {
          return item._id;
        });

        this.setState({
          action_wise: req.data.claims,
        });
      })
      .catch((err) => {});
  };
  toggle3 = () => {
    this.setState(() => {
      this.setState({ reallocmodal: !this.state.reallocmodal });
    });
  };
  toggle4 = () => {
    this.setState(() => {
      this.setState({ allocatemodal: !this.state.allocatemodal });
    });
  };
  allocateclaims = () => {
    const isValid = this.validate();
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        ids: sessionStorage.getItem("ClaimDBID"),
        // logdetails:{
        //   // newUser:this.state.username,
        //   admin:this.state.UserAllocate,
        //   module:"Action Allocation",
        //   clientId:this.state.client,

        //   // ClaimID:["11751701/25/2022INS-206"],
        //   // admin:"demoImagnum",
        //   // module:"Allocation",
        //   // clientId":2004
        //   }
      };
      const res = axios
        .put(
          `${ip}/allocation/allocate/${this.state.selecteduser[0]._id}`,
          value,
          {
            headers: headers,
          }
        )
        .then((res) => {
          alert(
            `Claims allocated to user ${this.state.selecteduser[0].first_name}`
          );
          this.toggle4();
          window.location.reload();
        })

        .catch((err) => {});
    }
  };

  allocateusers = async () => {
    const isValid = true;
    let claimid = [];
    if (isValid) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };

      if (this.state.status1.length !== 0) {
        this.state.userid.push(this.state.status1);

        this.state.count.push(this.state.agegrp1);
      }
      if (this.state.status2.length !== 0) {
        this.state.userid.push(this.state.status2);

        this.state.count.push(this.state.agegrp2);
      }
      if (this.state.status3.length !== 0) {
        this.state.userid.push(this.state.status3);

        this.state.count.push(this.state.agegrp3);
      }
      if (this.state.status4.length !== 0) {
        this.state.userid.push(this.state.status4);

        this.state.count.push(this.state.agegrp4);
      }
      if (this.state.status5.length !== 0) {
        this.state.userid.push(this.state.status5);

        this.state.count.push(this.state.agegrp5);
      }

      const value = {
        claimid: this.state.actionid,
        userid: this.state.userid,
        allocatecount: this.state.count,
        // logdetails:{
        //   // newUser:this.state.username,
        //   admin:this.state.UserAllocate,
        //   module:"Action Allocation",
        //   clientId:this.state.client,

        //   // ClaimID:["11751701/25/2022INS-206"],
        //   // admin:"demoImagnum",
        //   // module:"Allocation",
        //   // clientId":2004
        //   }
      };
      if (this.state.actionid.length !== 0) {
        await axios({
          method: "PUT",
          url: `${ip}/claim/allocatemultipleuser`,
          data: JSON.stringify(value),
          headers: headers,
        })
          .then((res) => {
            alert("Claims allocated successfully!");

            this.toggle4();
          })

          .catch((err) => {});
      } else {
        alert("There are no claims to allocate!");
        this.toggle4();
      }
    }
  };

  reset = () => {
    this.setState({
      selecteduser: [],
      selectuserErr: "",
      status1: [],
      status2: [],
      status3: [],
      actioncode: "",
      status4: [],
      status5: [],
      agegrp1: "",
      agegrp2: "",
      agegrp3: "",
      agegrp4: "",
      agegrp5: "",
    });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentpage1: selectedPage,
        offset: offset,
      },
      () => this.getActionCodes()
    );
    console.log(this.state.currentpage1, "pgr4");
  };

  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });

  getActionCodes = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    let url;
    if (this.state.client) {
      url = `${ip}/actioncode/allclaims?clientId=${this.state.client}`;
    } else {
      url = `${ip}/actioncode/allclaims`;
    }
    const res = await axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        let data = res.data.claims.map((item) => {
          return item._id;
        });

        this.state.remainingCards = res.data.claims.filter((card) => {
          if (card._id !== "Need to Call") {
            return card;
          } // Skip rendering in the loop
        });
        this.state.specificcard = res.data.claims.filter((card) => {
          if (card._id === "Need to Call") {
            return card;
          } // Skip rendering in the loop
        });
        console.log(this.state.remainingCards, this.state.specificcard, "rsc");

        const slice = this.state.remainingCards
          .sort((a, b) => (a.totalcount < b.totalcount ? 1 : -1))
          .slice(this.state.offset, this.state.offset + this.state.perPage);
        const slice1 = this.state.specificcard
          .sort((a, b) => (a.totalcount < b.totalcount ? 1 : -1))
          .slice(this.state.offset, this.state.offset + this.state.perPage);

        this.setState({
          pageCount: Math.ceil(res.data.claims.length / this.state.perPage - 1),

          actioncodelist: slice,
          specificcard: slice1,
          fetched: false,
          action: data,
        });
        console.log(this.state.pageCount, "pc");
      })
      .catch((err) => {});
  };

  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getDetails();
    this.getActionCodes();
  }
  componentDidUpdate(prevProps) {
    if (this.props.client !== prevProps.client) {
      this.getusers();
    }
  }

  render() {
    const { anchorEl } = this.state;
    return (
      <div>
      <Newsidebar name="Actions" />    
      <div className="align-row" style={{ overflow: "hidden" }}>
        <div className="align-column">

          <br />
          {this.state.role_type === "SuperAdmin" ? (
            <ButtonDropdown
              isOpen={this.state.opendropdown}
              toggle={this.toggle}
              style={{ width: "12%", marginLeft: "4%", position: "inherit" }}
            >
              <DropdownToggle caret>
                {this.state.clientname ? this.state.clientname : "All"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() =>
                    this.setState({ clientname: "", client: "" }, () => {
                      {
                        this.getActionCodes();
                      }
                      {
                        this.getDetails();
                      }
                    })
                  }
                >
                  All
                </DropdownItem>
                {this.state.clientslist.map((e) => {
                  return (
                    <DropdownItem
                      id={e.clientId}
                      key={e.clientId}
                      onClick={(e) =>
                        this.setState(
                          {
                            client: e.target.id,
                            clientname: e.target.innerText,
                          },
                          () => {
                            {
                              this.getActionCodes();
                            }
                            {
                              this.getDetails();
                            }
                          }
                        )
                      }
                    >
                      {e.client}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </ButtonDropdown>
          ) : null}
          <div>
            <h4
              style={{
                fontWeight: "bold",
                marginBottom: "40px",
                marginLeft: "60px",
              }}
              align="left"
            >
              {" "}
            </h4>

            {this.state.fetched ? (
              <div>
                <p
                  style={{ fontSize: "23px", marginTop: "25px", color: "#000" }}
                >
                  <span>Loading...</span>
                  <span>Please Wait...</span>
                </p>
                <div class="boxes body__loader dribbble">
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            ) : this.state.actioncodelist.length === 0 ? (
              <div class="bot-ui">
                <div class="bot-head">
                  <div class="eyes-container">
                    <div class="to-left eye"></div>
                    <div class="to-right eye"></div>
                  </div>
                </div>
                <div class="bot-body">
                  <div class="analyzer"></div>
                </div>
                <p style={{ fontSize: "20px", fontStyle: "italic" }}>
                  No Data Found
                </p>
              </div>
            ) : (
              <div>
                <div></div>
                <div>
                  <Row style={{ margin: "0px" }}>
                    {this.state.specificcard.map((data, index) => (
                      <Col md="4">
                        <Card style={{ margin: "20px" }}>
                          <Card.Body>
                            <Row>
                              <Col md="">{data._id}</Col>

                              <Col md="2">
                                <div className="action-icon">
                                  <VscGithubAction
                                    style={{
                                      color: "purple",
                                      fontSize: "20px",
                                      marginTop: "20%",
                                    }}
                                  >
                                    {" "}
                                  </VscGithubAction>
                                </div>
                              </Col>
                              <Col>
                                <BsThreeDotsVertical
                                  style={{
                                    color: "#57534b",
                                    paddingTop: "1%",
                                    marginTop: "-2%",
                                    fontSize: "1.5rem",
                                    marginLeft: "102%",
                                  }}
                                  onClick={(e) => {
                                    this.setState(
                                      {
                                        actioncode1: data._id,
                                      },
                                      () => this.toggle3()
                                    );
                                  }}
                                ></BsThreeDotsVertical>
                              </Col>
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose}
                                aria-labelledby="composition-button"
                                style={{ marginTop: "2.5%" }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    this.toggle3();
                                    this.handleClose();
                                  }}
                                >
                                  Allocate
                                </MenuItem>
                              </Menu>
                            </Row>
                            <Link
                              to={{
                                pathname: "/action-bucket",
                              }}
                              onClick={() => (
                                <ActionBucket
                                  value={sessionStorage.setItem(
                                    "Bucketnameforaction",
                                    data._id
                                  )}
                                />
                              )}
                              style={{ textDecoration: "none" }}
                            >
                              <Row>
                                <Col className="alclaim">
                                  Total&nbsp;Claims:
                                  <span>
                                    <div
                                      className="to"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                      }}
                                    >
                                      {data.totalcount}
                                    </div>
                                  </span>
                                </Col>
                                <Col className="altotal">
                                  Total&nbsp;Amount:
                                  <span>
                                    {" "}
                                    <div
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                      }}
                                    >
                                      ${data.totalamount}
                                    </div>
                                  </span>
                                </Col>
                              </Row>
                            </Link>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                    {this.state.actioncodelist.map((data, index) => (
                      <Col md="4">
                        <Card style={{ margin: "20px" }}>
                          <Card.Body>
                            <Row>
                              <Col md="">{data._id}</Col>

                              <Col md="2">
                                <div className="action-icon">
                                  <VscGithubAction
                                    style={{
                                      color: "purple",
                                      fontSize: "20px",
                                      marginTop: "20%",
                                    }}
                                  >
                                    {" "}
                                  </VscGithubAction>
                                </div>
                              </Col>
                              <Col>
                                <BsThreeDotsVertical
                                  style={{
                                    color: "#57534b",
                                    paddingTop: "1%",
                                    marginTop: "-2%",
                                    fontSize: "1.5rem",
                                    marginLeft: "102%",
                                  }}
                                  onClick={(e) => {
                                    this.setState(
                                      {
                                        actioncode1: data._id,
                                      },
                                      () => this.toggle3()
                                    );
                                  }}
                                ></BsThreeDotsVertical>
                              </Col>
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose}
                                aria-labelledby="composition-button"
                                style={{ marginTop: "2.5%" }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    this.toggle3();
                                    this.handleClose();
                                  }}
                                >
                                  Allocate
                                </MenuItem>
                              </Menu>
                            </Row>
                            <Link
                              to={{
                                pathname: "/action-bucket",
                              }}
                              onClick={() => (
                                <ActionBucket
                                  value={sessionStorage.setItem(
                                    "Bucketnameforaction",
                                    data._id
                                  )}
                                />
                              )}
                              style={{ textDecoration: "none" }}
                            >
                              <Row>
                                <Col className="alclaim">
                                  Total&nbsp;Claims:
                                  <span>
                                    <div
                                      className="to"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                      }}
                                    >
                                      {data.totalcount}
                                    </div>
                                  </span>
                                </Col>
                                <Col className="altotal">
                                  Total&nbsp;Amount:
                                  <span>
                                    {" "}
                                    <div
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                      }}
                                    >
                                      ${data.totalamount}
                                    </div>
                                  </span>
                                </Col>
                              </Row>
                            </Link>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>

                <div style={{ marginLeft: "7%", marginTop: "20px" }}>
                  <ReactPaginate
                    previousLabel={<IoIosArrowBack />}
                    nextLabel={<IoIosArrowForward />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>

                <Container>
                  <div className="action-grid4">
                    <Row style={{ paddingTop: "2%" }}>
                      <p
                        align="centre"
                        style={{ fontWeight: "bold", fontSize: "18px" }}
                      >
                        Actions Taken
                      </p>
                      <div
                        style={{
                          marginLeft: "40%",
                          marginTop: "-3%",
                          alignItems: "left",
                        }}
                      >
                        <select
                          style={{
                            width: "10%",
                            height: "80%",
                            borderBlockColor: "gray",
                            borderRadius: "4px",
                          }}
                          onChange={(e) =>
                            this.setState({ select: e.target.value })
                          }
                          value={this.state.select}
                        >
                          <option value="$">Charges</option>
                          <option value="#">Claims</option>
                        </select>
                      </div>
                      {this.state.select === "$" ? (
                        <div onMouseOver={() => (tooltip = "totalamount")}>
                          <ResponsiveContainer width={"99%"} height={400}>
                            <ComposedChart
                              width={450}
                              height={350}
                              data={this.state.action_wise}
                              margin={{
                                top: 13,
                                right: 30,
                                left: 10,
                                bottom: 5,
                              }}
                            >
                              <XAxis
                                dataKey={"_id"}
                                height={120}
                                interval={0}
                                angle={"-45"}
                                minTickGap={6}
                                tick={{
                                  strokeWidth: 0,
                                  fontSize: "11",
                                  top: 400,
                                  textAnchor: "end",
                                  dy: 6,
                                }}
                              />
                              <YAxis hide dataKey={"totalamount"} />
                              <Tooltip content={<CustomTooltip />} />
                              <defs>
                                <linearGradient
                                  id="colorUv"
                                  x1="0"
                                  y1="0"
                                  x2="0"
                                  y2="100%"
                                  spreadMethod="reflect"
                                >
                                  <stop offset="0" stopColor="#f5234a" />
                                  <stop offset="1" stopColor="#fa859a" />
                                </linearGradient>
                              </defs>
                              <Bar
                                name="Total Amount"
                                dataKey={"totalamount"}
                                barSize={30}
                                fill="url(#colorUv)"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      ) : (
                        <div onMouseOver={() => (tooltip = "totalcount")}>
                          <ResponsiveContainer width={"99%"} height={400}>
                            <ComposedChart
                              width={450}
                              height={350}
                              data={this.state.action_wise}
                              margin={{
                                top: 13,
                                right: 30,
                                left: 10,
                                bottom: 5,
                              }}
                            >
                              <XAxis
                                dataKey={"_id"}
                                height={120}
                                interval={0}
                                angle={"-45"}
                                minTickGap={6}
                                tick={{
                                  strokeWidth: 0,
                                  fontSize: "11",
                                  top: 400,
                                  textAnchor: "end",
                                  dy: 6,
                                }}
                              />
                              <YAxis hide dataKey={"totalcount"} />
                              <Tooltip content={<ClaimTooltip />} />
                              <defs>
                                <linearGradient
                                  id="colorUv"
                                  x1="0"
                                  y1="0"
                                  x2="0"
                                  y2="100%"
                                  spreadMethod="reflect"
                                >
                                  <stop offset="0" stopColor="#f5234a" />
                                  <stop offset="1" stopColor="#fa859a" />
                                </linearGradient>
                              </defs>
                              <Bar
                                name="Total Claims"
                                dataKey={"totalcount"}
                                barSize={30}
                                fill="url(#colorUv)"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Row>
                    <br />
                  </div>
                </Container>
              </div>
            )}
          </div>
          {this.state.reallocmodal && (
            <Modal
              style={{ width: "30%" }}
              isOpen={this.state.reallocmodal}
              onHide={this.toggle3}
              // onExit={this.reset}
              size="md"
              centered
              styles={{ overlay: { background: "#FFFF00" } }}
              fade="true"
            >
              <ModalHeader toggle={this.toggle3}>Allocate</ModalHeader>
              <ModalBody
                style={
                  {
                    // "max-height": "calc(100vh - 210px)",
                    // "overflow-y": "auto",
                  }
                }
              >
                <Row style={{ margin: "0px" }}>
                  Do you want to Allocate this claim ?
                </Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle3();
                  }}
                >
                  NO
                </Button>
                &nbsp;&nbsp;
                <Button
                  onClick={() => {
                    this.toggle3();
                    this.toggle4();
                    this.getusers();
                    this.getbucketclaims();
                  }}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  YES
                </Button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.allocatemodal && (
            <Modal
              // style={{ width: "30%" }}
              isOpen={this.state.allocatemodal}
              onHide={this.toggle4}
              onExit={this.reset}
              size="lg"
              centered
            >
              <ModalHeader toggle={this.toggle4}>Create Allocation</ModalHeader>
              <ModalBody>
                <Row style={{ margin: "0px" }}>
                  <Col>
                    <Label>Agent/Team Member Name</Label>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Row
                    style={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <Col>
                      <Input
                        className="form-control form-select"
                        type="select"
                        value={this.state.status1}
                        onChange={(e) => {
                          this.setState({
                            status1: e.target.value,
                          });
                        }}
                      >
                        <option value="" hidden id="select-placeholder">
                          Select User
                        </option>
                        {this.state.usersofaction.map((data, index) => (
                          <option value={data._id}>
                            {data.first_name + data.last_name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col>
                      <Input
                        className="form-control form-select"
                        type="select"
                        value={this.state.agegrp1}
                        onChange={(e) => {
                          this.setState({
                            agegrp1: e.target.value,
                          });
                        }}
                      >
                        <option value="" hidden id="select-placeholder">
                          Select the value
                        </option>

                        {this.state.allocate.map((data, index) => (
                          <option value={data}>{data}</option>
                        ))}
                      </Input>
                    </Col>
                    <br />
                  </Row>

                  <Row
                    style={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <Col>
                      <Input
                        className="form-control form-select"
                        type="select"
                        value={this.state.status2}
                        onChange={(e) => {
                          this.setState({
                            status2: e.target.value,
                          });
                        }}
                      >
                        <option value="" hidden id="select-placeholder">
                          Select User
                        </option>
                        {this.state.usersofaction.map((data, index) => (
                          <option value={data._id}>
                            {data.first_name + data.last_name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col>
                      <Input
                        className="form-control form-select"
                        type="select"
                        value={this.state.agegrp2}
                        onChange={(e) => {
                          this.setState({
                            agegrp2: e.target.value,
                          });
                        }}
                      >
                        <option value="" hidden id="select-placeholder">
                          Select the Value
                        </option>

                        {this.state.allocate.map((data, index) => (
                          <option value={data}>{data}</option>
                        ))}
                      </Input>
                    </Col>
                  </Row>

                  <Row
                    style={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <Col>
                      <Input
                        className="form-control form-select"
                        type="select"
                        value={this.state.status3}
                        onChange={(e) => {
                          this.setState({
                            status3: e.target.value,
                          });
                        }}
                      >
                        <option value="" hidden id="select-placeholder">
                          Select User
                        </option>
                        {this.state.usersofaction.map((data, index) => (
                          <option value={data._id}>
                            {data.first_name + data.last_name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col>
                      <Input
                        className="form-control form-select"
                        type="select"
                        value={this.state.agegrp3}
                        onChange={(e) => {
                          this.setState({
                            agegrp3: e.target.value,
                          });
                        }}
                      >
                        <option value="" hidden id="select-placeholder">
                          Select the Value
                        </option>

                        {this.state.allocate.map((data, index) => (
                          <option value={data}>{data}</option>
                        ))}
                      </Input>
                    </Col>
                  </Row>

                  <Row
                    style={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <Col>
                      <Input
                        className="form-control form-select"
                        type="select"
                        value={this.state.status4}
                        onChange={(e) => {
                          this.setState({
                            status4: e.target.value,
                          });
                        }}
                      >
                        <option value="" hidden id="select-placeholder">
                          Select User
                        </option>
                        {this.state.usersofaction.map((data, index) => (
                          <option value={data._id}>
                            {data.first_name + data.last_name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col>
                      <Input
                        className="form-control form-select"
                        type="select"
                        value={this.state.agegrp4}
                        onChange={(e) => {
                          this.setState({
                            agegrp4: e.target.value,
                          });
                        }}
                      >
                        <option value="" hidden id="select-placeholder">
                          Select the Value
                        </option>

                        {this.state.allocate.map((data, index) => (
                          <option value={data}>{data}</option>
                        ))}
                      </Input>
                    </Col>
                  </Row>

                  <Row
                    style={{
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <Col>
                      <Input
                        className="form-control form-select"
                        type="select"
                        value={this.state.status5}
                        onChange={(e) => {
                          this.setState({
                            status5: e.target.value,
                          });
                        }}
                      >
                        <option value="" hidden id="select-placeholder">
                          Select User
                        </option>
                        {this.state.usersofaction.map((data, index) => (
                          <option value={data._id}>
                            {data.first_name + data.last_name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col>
                      <Input
                        className="form-control form-select"
                        type="select"
                        value={this.state.agegrp5}
                        onChange={(e) => {
                          this.setState({
                            agegrp5: e.target.value,
                          });
                        }}
                      >
                        <option value="" hidden id="select-placeholder">
                          Select the Value
                        </option>

                        {this.state.allocate.map((data, index) => (
                          <option value={data}>{data}</option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </Row>

                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{
                    border: "1px solid grey",
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  onClick={() => {
                    this.toggle4();
                  }}
                >
                  Cancel
                </Button>
                &nbsp;&nbsp;
                <Button
                  style={{
                    backgroundColor: "black",
                    color: "white",
                  }}
                  onClick={() => {
                    this.allocateusers();
                    this.reset();
                  }}
                >
                  Allocate
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      </div>
      </div>
    );
  }
}

export default Actions;
