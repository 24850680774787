import React, { Component } from "react";
import axios from "axios";
import "../../Styles/admin.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { ip } from ".././Apis/Api";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import nodata from "../../Images/listdata.png";
import { ToastContainer, toast } from "react-toastify";
import loading from "../../Images/loader.gif";
import "react-toastify/dist/ReactToastify.css";
export class ClientOnboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      clients: [],
      clientid: "",
      clientname: "",
      status: "Active",
      countrycode: "",
      phoneno: "",
      updateid: "",
      updatename: "",
      updatestatus: "",
      updatecountrycode: "",
      updatephoneno: "",
      addmodal: false,
      editmodal: false,
      deletemodal: false,
      id: "",
      createmodal: false,
      nameErr: "",
      idErr: "",
      phnoErr: "",
      codeErr: "",
    };
  }
  toggleadd = () => {
    this.setState({ addmodal: !this.state.addmodal });
    // this.reset();
  };
  toggleedit = () => {
    this.setState({ editmodal: !this.state.editmodal });
    // this.reset();
  };
  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
    // this.reset();
  };

  reset = () => {
    this.setState({
      idErr: "",
      nameErr: "",
      codeErr: "",
      phnoErr: "",
      clientid: "",
      clientname: "",
      countrycode: "",
      phoneno: "",
      updatename: "",
      updateid: "",
      updatecountrycode: "",
      updatephoneno: "",
      updatestatus: "Active",
      status: "Active",
    });
  };
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => {
        this.setState({
          clients: res.data.clientonboard,
        });
      })
      .catch((err) => {});
  };
  deleteclients = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = await axios
      .delete(`${ip}/clientonboard/${this.state.id}`, { headers: headers })
      .then((res) => {
        if (res) {
          toast.error("Client deleted successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          this.toggledelete();
          this.getclients();
        }
      })
      .catch((err) => {
        if (err.response) {
          this.toggledelete();
          this.getclients();
          //    this.setState({createmodal:!this.state.createmodal})
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
  };

  validate = () => {
    let hasErr = "true";
    let nameErr = "";
    let idErr = "";
    let phnoErr = "";
    let codeErr = "";

    if (this.state.clientid === "") {
      idErr = "This field is required";
      this.setState({ idErr });
      hasErr = false;
    } else if (this.state.clientid.length < 4) {
      idErr = "Minimum length of id should be 4";
      this.setState({ idErr });
      hasErr = false;
    }
    if (this.state.clientname === "") {
      nameErr = "This field is required";
      this.setState({ nameErr });
      hasErr = false;
    }

    if (this.state.countrycode === "") {
      codeErr = "This field is required";
      this.setState({ codeErr });
      hasErr = false;
    }

    if (this.state.phoneno === "") {
      phnoErr = "This field is required";
      this.setState({ phnoErr });
      hasErr = false;
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
        this.state.phoneno
      )
    ) {
      phnoErr = "Invalid phone number";
      this.setState({ phnoErr });
      hasErr = false;
    }

    return hasErr;
  };

  handleSubmit = async () => {
    const isValid = this.validate();

    if (isValid) {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        clientId: this.state.clientid,
        client: this.state.clientname,
        status: this.state.status,
        countryCode: this.state.countrycode,
        phoneNumber: this.state.phoneno,
      };

      const res = await axios
        .post(`${ip}/clientonboard/create`, value, { headers: headers })
        .then((res) => {
          if (res) {
            this.getclients();
            this.toggleadd();
            this.setState({ createmodal: !this.state.createmodal });
            toast.success("Client added successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            this.toggleadd();
            this.getclients();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };

  updatevalidate = () => {
    let hasErr = "true";
    let nameErr = "";
    let idErr = "";
    let phnoErr = "";
    let codeErr = "";

    if (this.state.updateid === "") {
      idErr = "This field is required";
      this.setState({ idErr });
      hasErr = false;
    } else if (this.state.updateid.length < 4) {
      idErr = "Minimum length of id should be 4";
      this.setState({ idErr });
      hasErr = false;
    }
    if (this.state.updatename === "") {
      nameErr = "This field is required";
      this.setState({ nameErr });
      hasErr = false;
    }

    if (this.state.updatecountrycode === "") {
      codeErr = "This field is required";
      this.setState({ codeErr });
      hasErr = false;
    }

    if (this.state.updatephoneno === "") {
      phnoErr = "This field is required";
      this.setState({ phnoErr });
      hasErr = false;
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
        this.state.updatephoneno
      )
    ) {
      phnoErr = "Invalid phone number";
      this.setState({ phnoErr });
      hasErr = false;
    }
    // if (this.state.updateemail === "") {
    //   emailErr = "This field is required";
    //   this.setState({ emailErr });
    //   hasErr = false;
    // } else if (
    //   // eslint-disable-next-line
    //   !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
    //     this.state.updateemail
    //   )
    // ) {
    //   //var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    //   emailErr = "Invalid email address";
    //   this.setState({ emailErr });
    //   hasErr = false;

    // }
    return hasErr;
  };

  updateclients = async () => {
    const isValid = this.updatevalidate();

    if (isValid) {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      const value = {
        clientId: this.state.updateid,
        client: this.state.updatename,
        status: this.state.updatestatus,
        countryCode: this.state.updatecountrycode,
        phoneNumber: this.state.updatephoneno,
      };
      // eslint-disable-next-line
      const res = await axios
        .put(`${ip}/clientonboard/${this.state.id}`, value, {
          headers: headers,
        })
        .then((res) => {
          if (res) {
            this.toggleedit();
            this.getclients();
            this.setState({ createmodal: !this.state.createmodal });
            toast.info("Client details updated", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            this.toggleedit();
            this.getclients();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 10000);
    this.getclients();
  }
  render() {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:last-child td, &:last-child th": {
        border: 1,
      },
    }));

    return (
      <div>
      <Newsidebar name="Client OnBoard" />
      <div className="align-row">
        <div className="align-column">
          <br></br>
          <div className="align-row items">
            <h5 className="userlist-head">Clients&nbsp;List</h5>
            <button
              className="adduserbtn"
              style={{ marginLeft: "75%" }}
              onClick={this.toggleadd}
            >
              Create New Client
            </button>
          </div>
          <br />
          <br />
          {this.state.clients.length === 0 ? (
            <div>
              <img src={nodata} className="nodata" />
            </div>
          ) : (
            <div className="table__card">
              <ToastContainer />
              <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Client ID</StyledTableCell>
                      <StyledTableCell>Client Name</StyledTableCell>
                      <StyledTableCell>Phone Number</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>

                      <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.clients &&
                      this.state.clients.map((data, index) => (
                        <StyledTableRow key={data._id}>
                          <StyledTableCell>{data.clientId}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {data.client}
                          </StyledTableCell>

                          <StyledTableCell>{data.phoneNumber}</StyledTableCell>
                          <StyledTableCell>{data.status}</StyledTableCell>
                          <StyledTableCell>
                            <button
                              className="Edit"
                              onClick={() => {
                                this.setState({
                                  id: data._id,
                                  updateid: data.clientId,
                                  updatename: data.name,
                                  updatephoneno: data.phoneNumber,
                                  updatecountrycode: data.countryCode,
                                });
                                data.status
                                  ? this.setState({ updatestatus: data.status })
                                  : this.setState({ updatestatus: "Active" });
                                this.toggleedit();
                              }}
                            >
                              Edit
                            </button>
                            &nbsp;
                            <button
                              className="Delete"
                              onClick={() => {
                                this.setState({ id: data._id });
                                this.toggledelete();
                              }}
                            >
                              Delete
                            </button>
                          </StyledTableCell>
                        </StyledTableRow>
                        // ))}
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
        <Modal isOpen={this.state.addmodal} centered>
          <ModalHeader toggle={this.toggleadd}>Create Client</ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Client ID</Label>
                    <Input
                      type="number"
                      value={this.state.clientid}
                      onChange={(e) =>
                        this.setState({ clientid: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div style={{ fontSize: 16, color: "red" }}>
                {this.state.idErr}
              </div>
              <br />
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Client Name</Label>
                    <Input
                      type="text"
                      value={this.state.clientname}
                      onChange={(e) =>
                        this.setState({ clientname: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div style={{ fontSize: 16, color: "red" }}>
                {this.state.nameErr}
              </div>
              <br />
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Status</Label>
                    <Input
                      type="select"
                      value={this.state.status}
                      onChange={(e) =>
                        this.setState({ status: e.target.value })
                      }
                      className="form-control form-select"
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Mobile Number</Label>
                    <Row>
                      <Col md="3">
                        <Input
                          type="alphanumeric"
                          required
                          value={this.state.countrycode}
                          onChange={(e) =>
                            this.setState({ countrycode: e.target.value })
                          }
                        />
                      </Col>
                      <Col>
                        <Input
                          type="number"
                          required
                          value={this.state.phoneno}
                          onChange={(e) =>
                            this.setState({ phoneno: e.target.value })
                          }
                        />
                      </Col>
                      <br />
                      <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.phnoErr
                          ? this.state.phnoErr
                          : this.state.codeErr
                          ? this.state.codeErr
                          : null}
                      </div>
                    </Row>
                    <br />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.toggleadd();
                //   this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={this.handleSubmit}
            >
              Create
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.editmodal} centered>
          <ModalHeader toggle={this.toggleedit}>Edit Client</ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Client ID</Label>
                    <Input
                      type="number"
                      value={this.state.updateid}
                      onChange={(e) =>
                        this.setState({ updateid: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div style={{ fontSize: 16, color: "red" }}>
                {this.state.idErr}
              </div>
              <br />
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Client Name</Label>
                    <Input
                      type="text"
                      value={this.state.updatename}
                      onChange={(e) =>
                        this.setState({ updatename: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div style={{ fontSize: 16, color: "red" }}>
                {this.state.nameErr}
              </div>{" "}
              <br />
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Status</Label>
                    <Input
                      type="select"
                      className="form-control form-select"
                      value={this.state.updatestatus}
                      onChange={(e) => {
                        this.state.updatestatus === ""
                          ? this.setState({ updatestatus: "Active" })
                          : this.setState({ updatestatus: e.target.value });
                      }}
                    >
                      <option
                        value="Active"
                        selected={this.state.updatestatus === "Active"}
                      >
                        Active
                      </option>
                      <option
                        value="Inactive"
                        selected={this.state.updatestatus === "Inactive"}
                      >
                        Inactive
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Mobile Number</Label>
                    <Row>
                      <Col md="3">
                        <Input
                          type="alphanumeric"
                          required
                          value={this.state.updatecountrycode}
                          onChange={(e) =>
                            this.setState({ updatecountrycode: e.target.value })
                          }
                        />
                      </Col>
                      <Col>
                        <Input
                          type="number"
                          required
                          value={this.state.updatephoneno}
                          onChange={(e) =>
                            this.setState({ updatephoneno: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <br />
                    <div style={{ fontSize: 16, color: "red" }}>
                      {this.state.phnoErr
                        ? this.state.phnoErr
                        : this.state.codeErr
                        ? this.state.codeErr
                        : null}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.toggleedit();
                //   this.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={this.updateclients}
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.deletemodal}
          onHide={this.toggledelete}
          centered
        >
          <ModalHeader toggle={this.toggledelete}>Delete Client</ModalHeader>
          <ModalBody
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "auto",
            }}
          >
            <Row style={{ margin: "0px" }}>
              Do you want to delete this client?
            </Row>

            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              style={{
                border: "1px solid grey",
                color: "black",
                backgroundColor: "#fff",
              }}
              onClick={this.toggledelete}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={this.deleteclients}
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.createmodal} centered>
          <ModalBody>
            <img
              src={loading}
              style={{ width: "200px", height: "200px", marginLeft: "30%" }}
            />
          </ModalBody>
        </Modal>
      </div>
      </div>
    );
  }
}

export default ClientOnboard;
