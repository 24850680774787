import React, { useEffect, useState } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import "../../Styles/UserSubscription.css";
import "../../Styles/user__subs.scss";
import { Button } from "react-bootstrap";
import { ImUserCheck } from "react-icons/im";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { TiTick } from "react-icons/ti";
export default function UserSubscription() {
  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState({
    InventoryAnalysis: false,
    Outcomes: false,
    Actions: false,
    IndustryStandards: false,
    UserPerformanceDashboard: false,
    Trendspatterns: false,
    KeyIndicators: false,
    ClaimDenialManagement: false,
    DataUploadFile: false,
    WorkQueue: false,
    Allocation: false,
    Configure: false,
  });
  const toggleCheck = (inputName) => {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const selectAll = (value) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };

  const [checkedAll__one, setCheckedAll__one] = useState(false);
  const [checked__one, setChecked__one] = useState({
    DatabaseSync: false,
    CollectionPrediction: false,
    DenialPrediction: false,
    OverAllPrediction: false,
    APISync: false,
  });
  const toggleCheck__one = (inputName) => {
    setChecked__one((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const selectAll__one = (value) => {
    setCheckedAll__one(value);
    setChecked__one((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };

  const [checkedAll__two, setCheckedAll__two] = useState(false);
  const [checked__two, setChecked__two] = useState({
    UserDashboard: false,
    MannualClaims: false,
    ViewClaim: false,
    ManagePayment: false,
    MakeBalance: false,
    ARFollowupLog: false,
    CheckEligibility: false,
    AutoCheck: false,
    Allocate: false,
  });
  const toggleCheck__two = (inputName) => {
    setChecked__two((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const selectAll__two = (value) => {
    setCheckedAll__two(value);
    setChecked__two((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };
  const [checkedAll__three, setCheckedAll__three] = useState(false);
  const [checked__three, setChecked__three] = useState({
    IvrToSpeech: false,
    VoiceCallAPI: false,
  });
  const toggleCheck__three = (inputName) => {
    setChecked__three((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  };
  const selectAll__three = (value) => {
    setCheckedAll__three(value);
    setChecked__three((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  };

  let [num, setNum] = useState(0);
  let incNum = () => {
    if (num < 50) {
      setNum(Number(num) + 1);
    }
  };
  let decNum = () => {
    if (num > 0) {
      setNum(num - 1);
    }
  };
  let handleChange = (e) => {
    setNum(e.target.value);
  };
  let [num__one, setNum__one] = useState(0);
  let incNum__one = () => {
    if (num__one < 50) {
      setNum__one(Number(num__one) + 1);
    }
  };
  let decNum__one = () => {
    if (num__one > 0) {
      setNum__one(num__one - 1);
    }
  };
  let handleChange__one = (e) => {
    setNum__one(e.target.value);
  };

  const [value1, onChange__one] = useState(1);
  useEffect(() => {
    const ele = document.querySelector(".buble");
    if (ele) {
      ele.style.left = `${Number(value1 / 4)}px`;
    }
  });
  const [value2, onChange__two] = useState(1);
  useEffect(() => {
    const ele = document.querySelector(".buble");
    if (ele) {
      ele.style.left = `${Number(value2 / 4)}px`;
    }
  });
  const [value3, onChange__three] = useState(1);
  useEffect(() => {
    const ele = document.querySelector(".buble");
    if (ele) {
      ele.style.left = `${Number(value3 / 4)}px`;
    }
  });
  return (
    <div>
    <Newsidebar name="User Subscription" />
    <div className="align-row">
      <div className="align-column">
        <br></br>
        <div className="user__subs__body">
          <div class="pricing-wrapper clearfix">
            <div class="pricing-table">
              <h3 class="pricing-title">Admin Feature Pricing</h3>
              <div className="col-xl-8 inc__dec">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      onClick={decNum}
                    >
                      -
                    </button>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    value={num}
                    onChange={handleChange}
                  />
                  <div className="input-group-prepend">
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      onClick={incNum}
                    >
                      +
                    </button>
                  </div>
                  <Button className="btn-success">
                    <ImUserCheck />
                  </Button>
                </div>
              </div>
              <div class="range-slider">
                <p class="range-slider__value">${value1}</p>
                <div class="range-slider__slider">
                  <input
                    type="range"
                    min="1000"
                    max="5000"
                    value1={value1}
                    onChange={({ target: { value: radius } }) => {
                      onChange__one(radius);
                    }}
                    class="slider"
                    id="rangeSlider"
                  />
                </div>
              </div>
              <ul class="table-list">
                <li>
                  <FormGroup>
                    <h1 style={{ marginRight: "9px" }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Basic features"
                        // onChange={(event) => selectAll(event.target.checked)}
                        // checked={checkedAll}
                      />
                    </h1>
                  </FormGroup>
                </li>
                <li>
                  <TiTick />
                  Inventory Analysis
                </li>
                <li>
                  <TiTick />
                  Outcomes
                </li>
                <li>
                  <TiTick />
                  Actions
                </li>
                <li>
                  <TiTick />
                  Industry Standards
                </li>
                <li>
                  <TiTick />
                  User Performance Dashboard
                </li>
                <li>
                  <TiTick />
                  Trends & Patterns
                </li>
                <li>
                  <TiTick />
                  Key Indicators
                </li>
                <li>
                  <TiTick />
                  Claim & Denial Management
                </li>
                <li>
                  <TiTick />
                  Data Upload File
                </li>
                <li>
                  <TiTick />
                  Work Queue
                </li>
                <li>
                  <TiTick />
                  Allocation
                </li>
                <li>
                  <TiTick />
                  configure
                </li>
                <li>
                  <TiTick />
                  OCR Integration
                </li>
              </ul>
              <ul class="table-list">
                <li>
                  {" "}
                  <FormGroup>
                    <h1 style={{ marginRight: "9px" }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Advanced features"
                        // onChange={(event) => selectAll(event.target.checked)}
                        // checked={checkedAll}
                      />
                    </h1>
                  </FormGroup>
                </li>
                <li>
                  <TiTick />
                  API Sync
                </li>
                <li>
                  <TiTick />
                  Data Base Sync
                </li>
                <li>
                  <TiTick />
                  Collection Prediction
                </li>
                <li>
                  <TiTick />
                  Denial Prediction
                </li>
                <li>
                  <TiTick />
                  Over All Prediction
                </li>
              </ul>
              <div class="table-buy">
                <p>$60</p>
                <a href="#" class="pricing-action">
                  Pay Now
                </a>
              </div>
            </div>

            <div class="pricing-table recommended">
              <h3 class="pricing-title">User level Pricing</h3>
              <div className="col-xl-8 inc__dec">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      onClick={decNum__one}
                    >
                      -
                    </button>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    value={num__one}
                    onChange={handleChange__one}
                  />
                  <div className="input-group-prepend">
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      onClick={incNum__one}
                    >
                      +
                    </button>
                  </div>
                  <Button className="btn-success">
                    <ImUserCheck />
                  </Button>
                </div>
              </div>

              <div class="range-slider">
                <p class="range-slider__value">${value2}</p>
                <div class="range-slider__slider">
                  <input
                    type="range"
                    min="1000"
                    max="5000"
                    value2={value2}
                    onChange={({ target: { value: radius } }) => {
                      onChange__two(radius);
                    }}
                    class="slider"
                    id="rangeSlider"
                  />
                </div>
              </div>
              <ul class="table-list">
                <li>
                  <FormGroup>
                    <h1>
                      {" "}
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Basic Features"
                        // onChange={(event) => selectAll__two(event.target.checked)}
                        // checked={checkedAll__two}
                      />
                    </h1>
                  </FormGroup>
                </li>
                <li>
                  <TiTick />
                  User Dashboard
                </li>
                <li>
                  <TiTick />
                  Manual Claims
                </li>
                <li>
                  <TiTick />
                  View Claim
                </li>
                <li>
                  <TiTick />
                  Manage Payment
                </li>
                <li>
                  <TiTick />
                  Make Balance
                </li>
                <li>
                  <TiTick />
                  AR Followup Log
                </li>
                <li>
                  <TiTick />
                  Auto Check
                </li>
                <li>
                  <TiTick />
                  Make Balance
                </li>
              </ul>
              <ul class="table-list">
                <li>
                  <FormGroup>
                    <h1>
                      {" "}
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Advanced  Features"
                        // onChange={(event) => selectAll__two(event.target.checked)}
                        // checked={checkedAll__two}
                      />
                    </h1>
                  </FormGroup>
                </li>
                <li>
                  <TiTick />
                  Voice Call API
                </li>
                <li>
                  <TiTick />
                  Ivr To Speech
                </li>
              </ul>
              <div class="table-buy">
                <p>$100</p>
                <a href="#" class="pricing-action">
                  Pay Now
                </a>
              </div>
            </div>

            <div class="pricing-table">
              <h3 class="pricing-title">Transaction Pricing</h3>
              <div class="range-slider">
                <p class="range-slider__value">${value3}</p>
                <div class="range-slider__slider">
                  <input
                    type="range"
                    min="5000"
                    max="1000000"
                    value3={value3}
                    onChange={({ target: { value: radius } }) => {
                      onChange__three(radius);
                    }}
                    class="slider"
                    id="rangeSlider"
                  />
                </div>
              </div>
              <ul class="table-list">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Claim Status"
                  />
                </FormGroup>

                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Eligibility"
                  />
                </FormGroup>

                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Web Automation"
                  />
                </FormGroup>

                {/* <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="OCR Integration"
                    />
                  </FormGroup> */}

                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Claim Status"
                  />
                </FormGroup>
              </ul>
              <h4>Vol.of.Transaction</h4>
              <hr></hr>
              <ul class="table-list">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="1000-5000"
                    // style={{color:"black"}}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="5000-100,000"
                    // style={{color:"black"}}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="100,000-500,000"
                    // style={{color:"black"}}
                  />
                </FormGroup>
              </ul>

              <div class="table-buy">
                <p>$200</p>
                <a href="#" class="pricing-action">
                  Pay Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
