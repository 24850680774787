import { Component } from "react";
import { Row, Col } from "reactstrap";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import { Modal } from "react-bootstrap";
import { Button, CardHeader, CardBody } from "reactstrap";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Newsidebar from "../../Dashboard/Newsidebar";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import "./../../../Styles/eligibility.css";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { ip } from "../../Apis/Api";
class ActionEligibility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ClaimID: sessionStorage.getItem("ClaimID"),
      ChartID: sessionStorage.getItem("ChartID"),
      MRN: sessionStorage.getItem("MRN"),
      Patient: sessionStorage.getItem("Patient"),
      Pripayer: sessionStorage.getItem("Pripayer"),
      DateOfService: sessionStorage.getItem("DateOfService"),
      ClaimDate: sessionStorage.getItem("ClaimDate"),
      PatientDOB: sessionStorage.getItem("PatientDOB"),
      reportmodal: false,
      Eligibilitydata: [],
      subscriberFirstname: "",
      subscriberLastname: "",
      subscriberGender: "",
      subscriberDOB: "",
      subscriberMemberId: "",
      subscriberAddress: "",
      subscriberGroup: "",
      subscriberType: "",
      submitterNpi: "",
      submittername: "",
      submittertype: "",
      planNumber: "",
      planStatus: "",
      planGroupNumber: "",
      planDate: "",
      PlanEligibility: "",
      planServiceType: "",
      planCoverageLevel: "",
      planService: "",
      planserviceTypecode: Number,
      checkmodal: false,
      updateEndDOS: "",
      updatePatientDOB: "",
      updateLastName: "",
      updateFirstName: "",
      updateBeginingDOS: "",
      updateControlNo: "",
      updateTrackingNumber: "",
      updateGender: "",
      updateNpi: "",
      updateType: "",
      updateServiceOrganizationName: "",
      tradingPartnerServiceId: "",
      updatePrimaryPolicyNo: "",
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
    };
  }

  //renew access token
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    const renew = await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };

  //check eligibility
  EligibilityPost = async () => {
    // alert('Enter')
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const value = {
      controlNumber: this.state.updateControlNo,
      tradingPartnerServiceId: this.state.tradingPartnerServiceId,
      organizationName: this.state.updateServiceOrganizationName,
      npi: this.state.updateNpi,
      memberId: this.state.updatePrimaryPolicyNo,
      firstName: this.state.updateFirstName,
      lastName: this.state.updateLastName,
      gender: this.state.updateGender,
      dateOfBirth: this.state.updatePatientDOB,
      beginningDateOfService: this.state.updateBeginingDOS,
      endDateOfService: this.state.updateEndDOS,
    };
    const res = await axios
      .post(`${ip}/eligibility/report?claimid=${this.state.ClaimID}`, value, {
        headers: headers,
      })
      .then((res) => {
        this.getclaims();
        this.setState({ checkmodal: !this.state.checkmodal });
      })
      .catch((err) => {});
  };

  //get claim details
  getClaimDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    const res = await axios
      .get(`${ip}/claim/viewclaim?claimid=${this.state.ClaimID}`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          updateEndDOS: res.data.Claim[0].EndDOS,

          updatePatientDOB: res.data.Claim[0].PatientDOB,
          updateLastName: res.data.Claim[0].PatientLastName,
          updateFirstName: res.data.Claim[0].PatientFirstName,

          updateGender: res.data.Claim[0].Gender,
          updateNpi: res.data.Claim[0].Npi,

          updateControlNo: res.data.Claim[0].controlNumber,

          updateBeginingDOS: res.data.Claim[0].BeginingDOS,
          updateType: res.data.Claim[0].type,
          updateServiceOrganizationName:
            res.data.Claim[0].ServiceOrganizationName,
          updatePrimaryPolicyNo: res.data.Claim[0].PrimaryPolicyNo,
          tradingPartnerServiceId: res.data.Claim[0].Pripayer,
        });
      })
      .catch((err) => {});
  };

  //get claim details based on eligibility
  getclaims = async () => {
    // api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/eligibility/all?claimid=${this.state.ClaimID}`, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.eligibility.length !== 0) {
          if (res.data.eligibility[0].data.planStatus) {
            if (
              res.data.eligibility[0].data.planStatus[0].serviceTypeCodes
                .length !== 0
            ) {
              res.data.eligibility[0].data.planStatus[0].serviceTypeCodes.map(
                (data, index) => this.setState({ planserviceTypecode: data })
              );
            }
          }
          if (res.data.eligibility[0].data.length !== 0) {
            if (res.data.eligibility[0].data.provider) {
              alert("Eligibility Report will be uploaded soon!");
              if (res.data.eligibility[0].data.provider.length !== 0) {
                this.setState({
                  subscriberFirstname:
                    res.data.eligibility[0].data.subscriber.firstName,
                  subscriberLastname:
                    res.data.eligibility[0].data.subscriber.lastName,
                  // subscriberGroup:
                  // res.data.eligibility[0].data.subscriber[0].groupNumber,
                  subscriberMemberId:
                    res.data.eligibility[0].data.subscriber.memberId,
                  subscriberType:
                    res.data.eligibility[0].data.subscriber.entityType,
                  // controlNumber: res.data.eligibility[0].data.controlNumber,
                  tradingPartnerServiceId:
                    res.data.eligibility[0].tradingPartnerServiceId,
                  // subscriberDOB: res.data.eligibility[0].data.subscriber[0].dateOfBirth,
                });

                this.setState({
                  submittername:
                    res.data.eligibility[0].data.provider.providerName,
                  submitterNpi: res.data.eligibility[0].data.provider.npi,
                  submittertype:
                    res.data.eligibility[0].data.provider.entityIdentifier,
                });
              } else {
                this.setState({
                  subscriberFirstname: null,
                  subscriberLastname: null, // subscriberGroup:
                  //nullmber,
                  subscriberMemberId: null,
                  subscriberType: null,
                  // controlNumber: res.data.eligibility[0].data.controlNumber,
                  tradingPartnerServiceId: null,
                  // subscriberDOB:nullirth,
                });

                this.setState({
                  submittername: null,
                  submitterNpi: null,
                  submittertype: null,
                });
              }
            } else {
              alert("Eligibility check is not possible for this payer");
            }
          } else {
            alert("Eligibility check is not possible for this payer");
          }
        } else {
          alert("Eligibility check is not possible for this payer");
        }
      })

      .catch((err) => {});
  };

  //initial rendering
  componentDidMount() {
    setTimeout(this.renewaccesstoken(), 5000);
    this.getClaimDetails();
    // this.getclaims();
  }

  //open/close check eligibiity modal
  togglereport = () => {
    this.setState({ reportmodal: !this.state.reportmodal });
  };

  render() {
    //table cell styles
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
      },
    }));

    const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.black,
        fontWeight: "bold",
        width: "50%",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        width: "50%",
      },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:last-child th, &:last-child tr": {
        border: "1px solid rgba(224, 224, 224, 1)",
        //border:0,
      },
    }));
    const StyledTableRow1 = styled(TableRow)(({ theme }) => ({
      "&:nth-child(even) th, &:nth-child(even) td, &:nth-child(odd) th, &:nth-child(odd) td":
        {
          border: "1px solid rgba(224, 224, 224, 1)",
        },
    }));
    return (
      <div className="align-row">
        {this.state.role_type === "Admin" ? (
          <Newsidebar name="Actions" />
        ) : null}
        <div className={this.state.css_name}>
          <br></br>
          <div
            style={{ textAlign: "left", marginLeft: "1%", fontSize: "14px" }}
          >
            <p>
              <Link
                to="/Actions"
                style={{ textDecoration: "none", color: "black" }}
              >
                Work Queue
              </Link>
              &nbsp;<MdKeyboardArrowRight></MdKeyboardArrowRight>
              &nbsp;Eligibility Report
            </p>
          </div>
          <br></br>
          <div>
            {" "}
            <h5
              style={{ textAlign: "left", marginLeft: "1%", fontWeight: "600" }}
            >
              View Claim
            </h5>
            {this.state.planserviceTypecode == 30 ? (
              <button
                className="e-button"
                style={{ float: "right", marginRight: "5%" }}
              >
                Eligibility
              </button>
            ) : (
              <button
                style={{
                  float: "right",
                  marginRight: "5%",
                  border: "1px solid red",
                }}
              >
                Eligibility
              </button>
            )}
          </div>
          <div
            style={{
              // height: "100%",
              width: "100%",
              marginTop: "2%",
              // marginLeft: "1%",
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ClaimID</StyledTableCell>
                    <StyledTableCell>ChartID</StyledTableCell>
                    <StyledTableCell>MRN/Account Number</StyledTableCell>
                    <StyledTableCell>PatientName</StyledTableCell>
                    <StyledTableCell>PatientDOB</StyledTableCell>
                    <StyledTableCell>Pripayer</StyledTableCell>
                    <StyledTableCell>Phone#</StyledTableCell>
                    {/* <StyledTableCell>Address</StyledTableCell> */}
                    <StyledTableCell>ClaimDate</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                    {/* <StyledTableCell component="th" scope="row">
               {this.state.ChartID}
             </StyledTableCell> */}
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>{this.state.MRN}</StyledTableCell>
                    <StyledTableCell>
                      {this.state.updateFirstName}
                      <br />
                      {this.state.updateLastName}
                    </StyledTableCell>
                    <StyledTableCell>{this.state.PatientDOB}</StyledTableCell>
                    <StyledTableCell>{this.state.Pripayer}</StyledTableCell>
                    {/* <StyledTableCell>{this.state.PatientDOB}</StyledTableCell> */}
                    <StyledTableCell>{this.state.ClaimID}</StyledTableCell>
                    {/* <StyledTableCell>{this.state.ClaimID}</StyledTableCell> */}
                    <StyledTableCell>{this.state.ClaimDate}</StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <br />
          <div>
            {" "}
            <p
              style={{
                float: "left",
                paddingLeft: "70%",
                fontWeight: "400",
                marginLeft: "10%",
              }}
            >
              Total Amount:
            </p>{" "}
            &nbsp;
            <p style={{ float: "left", paddingLeft: "1%", fontWeight: "600" }}>
              $ 1,500.00
            </p>
          </div>

          <br />
          <br />
          <div>
            <h3 align="left" style={{ marginLeft: "2%" }}>
              <b>Eligibility Report</b>
            </h3>{" "}
            <button
              style={{
                width: "100px",
                marginLeft: "80%",
                backgroundColor: "black",
                color: "white",
                borderRadius: "20px",
              }}
              onClick={() =>
                this.setState({ checkmodal: !this.state.checkmodal })
              }
            >
              Check
            </button>
            <br />
            <br />
            <Row>
              <Col md={6}>
                <Card style={{ boxShadow: "1px 3px 1px #9E9E9E" }}>
                  <CardHeader>
                    <p>Patient Details</p>
                  </CardHeader>
                  {/* <Row>
        <p>Patient Details</p>
      </Row> */}
                  <CardBody>
                    <Row>
                      <Col>
                        <p>Patient First Name</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscriberFirstname} </p>
                      </Col>
                    </Row>
                    <hr />

                    <Row>
                      <Col>
                        <p>Patient Last Name</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscriberLastname} </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p>Type</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscriberType} </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />
                    <Row>
                      <Col>
                        <p>Member ID</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscriberMemberId} </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p>Group</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscriberGroup} </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p>Address</p>
                      </Col>
                      <Col>
                        <p></p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p>DOB</p>
                      </Col>
                      <Col>
                        <p>{this.state.subscriberDOB} </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p>Gender</p>
                      </Col>
                      <Col>
                        <p> </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p>Adm. Communication</p>
                      </Col>
                      <Col>
                        <p></p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p> Service</p>
                      </Col>
                      <Col>
                        <p> </p>
                      </Col>
                    </Row>
                    <hr style={{ width: "100%", marginLeft: "-10px" }} />

                    <Row>
                      <Col>
                        <p>Enrollment</p>
                      </Col>
                      <Col>
                        <p> </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col md={6}>
                <div>
                  <Card style={{ boxShadow: "1px 3px 1px #9E9E9E" }}>
                    <CardHeader>Submitter</CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <p> Submitter</p>
                        </Col>
                        <Col>
                          <p> {this.state.submittername}</p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p>Submitter Type</p>
                        </Col>
                        <Col>
                          <p>{this.state.submittertype}</p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p> Service Provider#</p>
                        </Col>
                        <Col>
                          <p>{this.state.submitterNpi}</p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <br />
                <div>
                  <Card style={{ boxShadow: "1px 3px 1px #9E9E9E" }}>
                    <CardHeader>Plan Details</CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <p> Plan</p>
                        </Col>
                        <Col>
                          <p>{this.state.planNumber}</p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p>Status</p>
                        </Col>
                        <Col>
                          <p>{this.state.planStatus}</p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p>Coverage Level</p>
                        </Col>
                        <Col>
                          <p>{this.state.planCoverageLevel}</p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p>Service Type</p>
                        </Col>
                        <Col>
                          <p>{this.state.planServiceType}</p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p>Plan</p>
                        </Col>
                        <Col>
                          <p>
                            {this.state.planDate}-{this.state.planService}
                          </p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p>Group or Policy Number</p>
                        </Col>
                        <Col>
                          <p>{this.state.planGroupNumber}</p>
                        </Col>
                      </Row>
                      <hr style={{ width: "100%", marginLeft: "-10px" }} />

                      <Row>
                        <Col>
                          <p>Eligibility</p>
                        </Col>
                        <Col>
                          <p>
                            {this.state.PlanEligibility}-
                            {this.state.planService}
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>

          <Modal show={this.state.checkmodal} centered>
            <Modal.Header closeButton closeLabel="">
              <Modal.Title>Check Eligibility Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure want to check eligibility report?
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.EligibilityPost}>Yes</Button>
              <Button
                onClick={() =>
                  this.setState({ checkmodal: !this.state.checkmodal })
                }
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}
export default ActionEligibility;
