import { React, Component } from "react";
import Newsidebar from "../Dashboard/Newsidebar";
import "./../../Styles/Trends.css";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { SiAcclaim } from "react-icons/si";
import { MdOutlinePayments } from "react-icons/md";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { MdOutlinePaid } from "react-icons/md";
import { GiHospitalCross } from "react-icons/gi";
import { SiCodesandbox } from "react-icons/si";
import { MdPersonOutline } from "react-icons/md";
class TrendsPatterns extends Component {
  render() {
    return (
      <div>
      <Newsidebar name="Trends and Patterns" />
      <div className="align-row">
        <div
          // style={{ background: "white" }}
          className="align-column"
        >

        </div>
        <div className="t-card-container">
          <div className="a-trends">
            <div className="trends">
              <Link
                to="/trends/aged-payer-analysis"
                style={{ textDecoration: "none" }}
              >
                <Row>
                  <Col className="trends-text">Aged Payment Analysis</Col>
                  <Col className="trendsicon">
                    <div className="trendsicon-green">
                      <SiAcclaim
                        style={{
                          color: "green",
                          fontSize: "17px",
                          marginTop: "-30%",
                        }}
                      >
                        {" "}
                      </SiAcclaim>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
            <div className="trends">
              <Link
                to="/trends/aged-payment-charts"
                style={{ textDecoration: "none" }}
              >
                <Row>
                  <Col className="trends-text">
                    Aged Payment Analysis (Last 6 Months)
                  </Col>
                  <Col className="trendsicon">
                    <div className="trendsicon-blue">
                      <SiCodesandbox
                        style={{
                          color: "blue",
                          fontSize: "20px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </SiCodesandbox>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>

            <div className="trends">
              <Link
                to="/trends/billing-payment"
                style={{ textDecoration: "none" }}
              >
                <Row>
                  <Col className="trends-text">Billing Payment</Col>
                  <Col className="trendsicon">
                    <div className="trendsicon-purple">
                      <MdOutlinePayments
                        style={{
                          color: "purple",
                          fontSize: "23px",
                          marginTop: "19%",
                        }}
                      >
                        {" "}
                      </MdOutlinePayments>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
          </div>
          <div className="a-trends">
            <div className="trends">
              <Link
                to="/trends/primary-risk"
                style={{ textDecoration: "none" }}
              >
                <Row>
                  <Col className="trends-text">AR Risk (Primary)</Col>

                  <Col className="trendsicon">
                    <div className="trendsicon-pink">
                      <MdOutlinePaid
                        style={{
                          color: "#e60028",
                          fontSize: "22px",
                          marginTop: "18%",
                        }}
                      >
                        {" "}
                      </MdOutlinePaid>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>

            <div className="trends">
              <Link
                to="/trends/payer-analysis"
                style={{ textDecoration: "none" }}
              >
                <Row>
                  <Col className="trends-text">
                    Provider Payer Collection <br /> Analysis
                  </Col>
                  <Col className="trendsicon">
                    <div className="trendsicon-black">
                      <GiHospitalCross
                        style={{
                          color: "black",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </GiHospitalCross>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
            <div className="trends">
              <Link
                to="/trends/time-gap-dos"
                style={{ textDecoration: "none" }}
              >
                <Row>
                  <Col className="trends-text">
                    Time Gap Between <br /> DOS & Claim Date
                  </Col>
                  <Col className="trendsicon">
                    <div className="trendsicon-red">
                      <MdPersonOutline
                        style={{
                          color: "#800000",
                          fontSize: "24px",
                          marginTop: "19%",
                        }}
                      >
                        {" "}
                      </MdPersonOutline>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
          </div>
          <div className="a-trends">
            <div className="trends">
              <Link
                to="/trends/patient-primary-ar"
                style={{ textDecoration: "none" }}
              >
                <Row>
                  <Col className="trends-text">
                    Top 10 Patients With
                    <br /> Primary AR
                  </Col>
                  <Col className="trendsicon">
                    <div className="trendsicon-yellow">
                      <MdOutlineIntegrationInstructions
                        style={{
                          color: "#4c4d00",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </MdOutlineIntegrationInstructions>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>

            <div className="trends">
              <Link to="/trends/top10-cpt" style={{ textDecoration: "none" }}>
                <Row>
                  <Col className="trends-text">
                    Top 10 CPT With <br /> Primary AR
                  </Col>
                  <Col className="trendsicon">
                    <div className="trendsicon-green">
                      <SiAcclaim
                        style={{
                          color: "green",
                          fontSize: "17px",
                          marginTop: "-30%",
                        }}
                      >
                        {" "}
                      </SiAcclaim>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
            <div className="trends">
              <Link
                to="/trends/top10-payers"
                style={{ textDecoration: "none" }}
              >
                <Row>
                  <Col className="trends-text">
                    Top 10 Payers with <br /> Maximum AR
                  </Col>
                  <Col className="trendsicon">
                    <div className="trendsicon-blue">
                      <SiCodesandbox
                        style={{
                          color: "blue",
                          fontSize: "20px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </SiCodesandbox>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
          </div>

          {/* <div className="a-trends">
            <div className="trends">
              <Link
                to="/trends/non-medicare claims"
                style={{ textDecoration: "none" }}
              >
                <Row>
                  <Col className="trends-text">
                    Non Medicare Claims <br />
                    with Payment Possibility
                  </Col>

                  <Col className="trendsicon">
                    <div className="trendsicon-purple">
                      <MdOutlinePayments
                        style={{
                          color: "purple",
                          fontSize: "23px",
                          marginTop: "19%",
                        }}
                      >
                        {" "}
                      </MdOutlinePayments>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>

            <div className="trends">
              <Link
                to="/trends/incorrect medicare writeoff"
                style={{ textDecoration: "none" }}
              >
                <Row>
                  <Col className="trends-text">
                    Incorrect Medicare Writeoff <br />
                    Billed To Patient
                  </Col>
                  <Col className="trendsicon">
                    <div className="trendsicon-pink">
                      <MdOutlinePaid
                        style={{
                          color: "#e60028",
                          fontSize: "22px",
                          marginTop: "18%",
                        }}
                      >
                        {" "}
                      </MdOutlinePaid>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
            <div className="trends">
              <Link
                to="/trends/medicare secondary pending"
                style={{ textDecoration: "none" }}
              >
                <Row>
                  <Col className="trends-text">
                    Medicare Fwd to Secondary <br />
                    Pending
                  </Col>
                  <Col className="trendsicon">
                    <div className="trendsicon-black">
                      <GiHospitalCross
                        style={{
                          color: "black",
                          fontSize: "21px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </GiHospitalCross>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
          </div> */}
          <div className="a-trends">
            <div className="trends">
              <Link
                to="/trends/top-cpt-performance"
                style={{ textDecoration: "none" }}
              >
                <Row>
                  <Col className="trends-text">Top CPT Performance</Col>
                  <Col className="trendsicon">
                    <div className="trendsicon-blue">
                      <SiAcclaim
                        style={{
                          color: "blue",
                          fontSize: "17px",
                          marginTop: "20%",
                        }}
                      >
                        {" "}
                      </SiAcclaim>
                    </div>
                  </Col>
                </Row>
              </Link>
            </div>
            {/* <div className="trends">
               <Link to="/TrendsCharts/AgedPaymentCharts" style={{ textDecoration: 'none' }}> 
              <Row>
                <Col className="trends-text">
                  Patient with Multiple <br />
                  Claims Unpaid
                </Col>
                <Col className="trendsicon">
                  <div className="trendsicon-green">
                    <SiAcclaim
                      style={{
                        color: "green",
                        fontSize: "17px",
                        marginTop: "-30%",
                      }}
                    >
                      {" "}
                    </SiAcclaim>
                  </div>
                </Col>
              </Row>
              {/* </Link> 
            </div> */}
          </div>
        </div>
      </div>
      </div>
    );
  }
}
export default TrendsPatterns;
